import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import WhitelistedSitesGetModal from "./modals/WhitelistedSitesGetModal";
import WhitelistedSitesPutModal from "./modals/WhitelistedSitesPutModal";
import PromptCheckerPostModal from "./modals/PromptCheckerPostModal";

export default class promptConfigEffect {
  static async requestPrompts(params, callback) {
    const endpoint = environment.api.websweepXPrompts;
    let response = EffectUtility.getToModel(
      WhitelistedSitesGetModal,
      endpoint,
      params
    );
    return response;
  }

  static async requestPromptsFilter(params) {
    const endpoint = environment.api.websweepXPrompts;
    return EffectUtility.getToModel(WhitelistedSitesGetModal, endpoint, params);
  }

  static async requestPostPrompts(data, callBackFunction) {
    const endpoint = environment.api.websweepXPrompts;

    let response = EffectUtility.postToModel(
      WhitelistedSitesPutModal,
      endpoint,
      data
    );

    response.then((resp) => {
      if (resp.status === "success" && callBackFunction) {
        callBackFunction(resp);
      }
    });

    return response;
  }

  static async requestPostPromptsChecker(data, callBackFunction) {
    const endpoint = environment.api.websweepXPromptsChecker;

    let response = EffectUtility.postToModel(
      PromptCheckerPostModal,
      endpoint,
      data
    );

    response.then((resp) => {
      if (resp && callBackFunction) {
        callBackFunction(resp);
      }
    });

    return response;
  }

  static async requestPutPrompts(data, id) {
    const endpoint = environment.api.websweepXPrompts + "/" + id;
    return EffectUtility.putToModel(WhitelistedSitesPutModal, endpoint, data);
  }
}
