import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { endDate, monthNames, startDate } from "./dateFunctions/DateFunctions";
import { format } from "date-fns";
import Logo from "./logoContainer/Logo";
import Description from "./descriptionContainer/Description";
import { Box, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";

const PlatformWiseOverallSentiment_mentions = (props) => {
  // Chart.register(CategoryScale);
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [apiError, setApiError] = useState(null);
  // this month
  let current_month = new Date();
  let current_month_end_date = endDate(current_month);

  // last month
  let previous_month = new Date();
  previous_month.setMonth(previous_month.getMonth() - 1);

  //  before_previous_month
  let before_previous_month = new Date();
  before_previous_month.setMonth(before_previous_month.getMonth() - 2);
  let before_previous_month_start_date = startDate(before_previous_month);

  let monthList = [];
  reportData &&
    reportData.ticket_report &&
    reportData.ticket_report.platform &&
    reportData.ticket_report.platform.length > 0 &&
    reportData.ticket_report.platform[0].monthwise?.buckets.length > 0 &&
    reportData.ticket_report.platform[0].monthwise?.buckets.map((month) => {
      monthList.push(month.key_as_string);
    });

  const [today, setToday] = useState(new Date());

  function returnMonthName(monthString) {
    let dateObj = new Date(monthString);
    let options = { year: "numeric", month: "short" };
    let formattedDate = dateObj.toLocaleDateString("default", options);
    return formattedDate;
  }

  function convertDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to the month since it is zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastSecondFirstDay = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  ); // Set the date to the first day of the previous month

  const lastThirdFirstDay = new Date(
    today.getFullYear(),
    today.getMonth() - 2,
    1
  ); // Set the date to the first day of the previous month

  let currentMonth = convertDate(thisMonth);
  let lastSecond = convertDate(lastSecondFirstDay);
  let lastThird = convertDate(lastThirdFirstDay);

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);

      await fetchData(before_previous_month_start_date, current_month_end_date);

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setToday(new Date());

        setLoading(true);

        await fetchData(
          before_previous_month_start_date,
          current_month_end_date
        );

        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      // Assuming props.openDateFilter is provided and valid
      const date = new Date(props.openDateFilter);
      setToday(new Date(props.openDateFilter));

      const firstDateOfLastMonth = new Date(
        date.getFullYear(),
        date.getMonth() - 2,
        1
      );
      const firstDateString = format(firstDateOfLastMonth, "yyyy-MM-dd");

      const endOfCurrentMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      );
      const endDateString = format(endOfCurrentMonth, "yyyy-MM-dd");

      (async function CallApi() {
        setLoading(true);

        await fetchData(new Date(firstDateString), new Date(endDateString));

        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.filterClicked, props.openDateFilter]);

  async function fetchData(from, to) {
    let API = `${
      environment.api.mentionReports
    }?report_type=["count"]&reports=["platform"]&from=${format(
      from,
      "yyyy-MM-dd"
    )}&to=${format(to, "yyyy-MM-dd")}`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (response.hasOwnProperty("message")) {
          setReportData(null);
          setApiError(response.message);
        } else {
          setReportData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setReportData(null);
        setApiError(err.message);
        setLoading(false);
      });

    // return fetch(
    //   `${
    //     environment.api.ticketReports
    //   }?report_type=["count"]&reports=["platform"]&from=${format(
    //     from,
    //     "yyyy-MM-dd"
    //   )}&to=${format(to, "yyyy-MM-dd")}`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then(async (response) => {
    //     return response;
    //   })
    //   .catch((err) => {});
  }

  function returnMonthName(monthString) {
    let dateObj = new Date(monthString);
    let options = { year: "numeric", month: "short" };
    let formattedDate = dateObj.toLocaleDateString("default", options);
    return formattedDate;
  }

  function platformName(platformId) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === platformId)
        .map((item) => {
          return item.platform_alias;
        })
    );
  }

  const noDataFoundBool =
    (!loading && reportData?.status === "Failed") ||
    (!loading &&
      reportData &&
      reportData.ticket_report &&
      reportData.ticket_report.platform.length !== 0 &&
      Object.keys(reportData.ticket_report.platform[0]).length === 1);

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  let columns = [
    {
      field: "platforms",
      headerName: "Platforms",
      resizable: true,
      headerAlign: "center",
      minWidth: 150,
      flex: 2,
      sortable: false,
      disableColumnMenu: true,

      // width: 150,
    },
  ];

  // monthList.length > 0 &&
  //   monthList.map((month) => {
  //     return columns.push({
  //       field: returnMonthName(month),
  //       headerName: (
  //         <div
  //           style={{
  //             lineHeight: "23px",
  //             width: "100%",
  //             fontSize: "14px",
  //           }}
  //         >
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "center",
  //             }}
  //           >
  //             {returnMonthName(month)}
  //           </div>
  //           <div
  //             style={{
  //               display: "flex",
  //               fontSize: "14px",

  //               justifyContent: "space-around",
  //             }}
  //           >
  //             +ve | -ve | &empty; &nbsp; (-ve %)
  //           </div>
  //         </div>
  //       ),
  //       resizable: true,
  //       align: "center",
  //       headerAlign: "center",
  //       // flex: 2,
  //       minWidth: 150,
  //       flex: 2,

  //       renderCell: (params) =>
  //         params.field === `${returnMonthName(lastThird)}` ? (
  //           params.row.platforms !== "Total" ? (
  //             <p className=" p-0 m-0">
  //               <span>
  //                 {prefixZero_for_singleDigit(
  //                   params.row[`${returnMonthName(lastThird)}Pos`]
  //                 )}
  //               </span>{" "}
  //               |&nbsp;
  //               <span>
  //                 {prefixZero_for_singleDigit(
  //                   params.row[`${returnMonthName(lastThird)}Neg`]
  //                 )}
  //               </span>{" "}
  //               |&nbsp;
  //               <span>
  //                 {prefixZero_for_singleDigit(
  //                   params.row[`${returnMonthName(lastThird)}Neutral`]
  //                 )}
  //               </span>{" "}
  //               &nbsp;
  //               <span>
  //                 ( {params.row[`${returnMonthName(lastThird)}Neg-Perc`]} % )
  //               </span>
  //             </p>
  //           ) : (
  //             <p className=" p-0 m-0">
  //               <span>
  //                 {prefixZero_for_singleDigit(
  //                   params.row[`${returnMonthName(lastThird)}Pos`]
  //                 )}
  //               </span>{" "}
  //               |&nbsp;
  //               <span>
  //                 {prefixZero_for_singleDigit(
  //                   params.row[`${returnMonthName(lastThird)}Neg`]
  //                 )}
  //               </span>{" "}
  //               |&nbsp;
  //               <span>
  //                 {prefixZero_for_singleDigit(
  //                   params.row[`${returnMonthName(lastThird)}Neutral`]
  //                 )}
  //               </span>{" "}
  //               &nbsp;
  //               <span>
  //                 ( {params.row[`${returnMonthName(lastThird)}Neg-Perc`]} % )
  //               </span>
  //             </p>
  //           )
  //         ) : params.field === `${returnMonthName(lastSecond)}` ? (
  //           <p className=" p-0 m-0">
  //             <span>
  //               {prefixZero_for_singleDigit(
  //                 params.row[`${returnMonthName(lastSecond)}Pos`]
  //               )}
  //             </span>{" "}
  //             |&nbsp;
  //             <span>
  //               {prefixZero_for_singleDigit(
  //                 params.row[`${returnMonthName(lastSecond)}Neg`]
  //               )}
  //             </span>{" "}
  //             |&nbsp;
  //             <span>
  //               {prefixZero_for_singleDigit(
  //                 params.row[`${returnMonthName(lastSecond)}Neutral`]
  //               )}
  //             </span>{" "}
  //             &nbsp;
  //             <span>
  //               ( {params.row[`${returnMonthName(lastSecond)}Neg-Perc`]} % )
  //             </span>
  //           </p>
  //         ) : (
  //           <p className=" p-0 m-0">
  //             <span>
  //               {prefixZero_for_singleDigit(
  //                 params.row[`${returnMonthName(currentMonth)}Pos`]
  //               )}
  //             </span>{" "}
  //             |&nbsp;
  //             <span>
  //               {prefixZero_for_singleDigit(
  //                 params.row[`${returnMonthName(currentMonth)}Neg`]
  //               )}
  //             </span>{" "}
  //             |&nbsp;
  //             <span>
  //               {prefixZero_for_singleDigit(
  //                 params.row[`${returnMonthName(currentMonth)}Neutral`]
  //               )}
  //             </span>{" "}
  //             &nbsp;
  //             <span>
  //               ( {params.row[`${returnMonthName(currentMonth)}Neg-Perc`]} % )
  //             </span>
  //           </p>
  //         ),
  //     });
  //   });

  const columnGroupingModel = [];
  monthList.length > 0 &&
    monthList.forEach((month, index) => {
      columns.push(
        {
          field: `${month}_Pos`,
          headerName: (
            <div
              style={{ lineHeight: "23px", width: "100%", fontSize: "14px" }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                +ve
              </div>
            </div>
          ),
          resizable: true,
          align: "center",
          headerAlign: "center",
          // minWidth: 50,
          flex: 1,
          sortable: false,
          disableColumnMenu: true,
          renderCell: (params) => (
            <span>
              {prefixZero_for_singleDigit(
                params.row[`${returnMonthName(month)}Pos`]
              )}
            </span>
          ),
        },
        {
          field: `${month}_Neg`,
          headerName: (
            <div
              style={{ lineHeight: "23px", width: "100%", fontSize: "14px" }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                -ve
              </div>
            </div>
          ),
          resizable: true,
          align: "center",
          headerAlign: "center",
          sortable: false,
          disableColumnMenu: true,
          // minWidth: 150,
          flex: 1,
          renderCell: (params) => (
            <span>
              {prefixZero_for_singleDigit(
                params.row[`${returnMonthName(month)}Neg`]
              )}
            </span>
          ),
        },
        {
          field: `${month}_Neutral`,
          headerName: (
            <div
              style={{ lineHeight: "23px", width: "100%", fontSize: "14px" }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                &empty;
              </div>
            </div>
          ),
          resizable: true,
          align: "center",
          headerAlign: "center",
          sortable: false,
          disableColumnMenu: true,
          // minWidth: 150,
          flex: 1,
          renderCell: (params) => (
            <span>
              {prefixZero_for_singleDigit(
                params.row[`${returnMonthName(month)}Neutral`]
              )}
            </span>
          ),
        },
        {
          field: `${month}_NegPercent`,
          headerName: (
            <div
              style={{ lineHeight: "23px", width: "100%", fontSize: "14px" }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                (-ve %)
              </div>
            </div>
          ),
          resizable: true,
          align: "center",
          headerAlign: "center",
          minWidth: 80,
          sortable: false,
          disableColumnMenu: true,
          // flex: 1,
          renderCell: (params) => (
            <span>{params.row[`${returnMonthName(month)}Neg-Perc`]}%</span>
          ),
        }
      );
      columnGroupingModel.push({
        groupId: `last_${index}`, // Dynamically create unique groupId for each month
        headerName: returnMonthName(month), // Dynamic name like "Last Third" etc.
        headerAlign: "center",
        children: [
          { field: `${month}_Pos` },
          { field: `${month}_Neg` },
          { field: `${month}_Neutral` },
          { field: `${month}_NegPercent` },
        ],
      });
    });

  columns.push(
    {
      field: "Total_Pos",

      headerName: (
        <div style={{ lineHeight: "23px", width: "100%", fontSize: "14px" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>+ve</div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      resizable: true,
      // minWidth: 150,
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <p className=" p-0 m-0">
          <span>{params.row.cumulativePositive}</span>
        </p>
      ),
    },
    {
      field: "Total_Neg",

      headerName: (
        <div style={{ lineHeight: "23px", width: "100%", fontSize: "14px" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>-ve</div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      resizable: true,
      // minWidth: 150,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <p className=" p-0 m-0">
          <span>{params.row.cumulativeNegative}</span>
        </p>
      ),
    },
    {
      field: "Total_Neutral",

      headerName: (
        <div style={{ lineHeight: "23px", width: "100%", fontSize: "14px" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            &empty;
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      resizable: true,
      sortable: false,
      disableColumnMenu: true,
      // minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <p className=" p-0 m-0">
          <span>{params.row.cumulativeNeutral}</span>
        </p>
      ),
    }
  );
  columnGroupingModel.push({
    groupId: `Cumulative`, // Dynamically create unique groupId for each month
    headerName: "Total", // Dynamic name like "Last Third" etc.
    headerAlign: "center",
    children: [
      { field: "Total_Pos" },
      { field: "Total_Neg" },
      { field: "Total_Neutral" },
    ],
  });

  currentMonth &&
    columns.push({
      field: `${new Date(currentMonth).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      })}1`,

      headerName: (
        <div
          style={{
            lineHeight: "23px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "14px",
            }}
          >
            {new Date(currentMonth).toLocaleDateString("en-US", {
              month: "short",
              year: "numeric",
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              fontSize: "14px",
            }}
          >
            Avg Negative %
          </div>
        </div>
      ),

      resizable: true,
      minWidth: 150,
      flex: 2,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      renderCell: (params) => (
        <p className=" p-0 m-0">
          <span>{params.row.negative_percentage}</span>%
        </p>
      ),
    });

  let rows = [];

  if (reportData?.ticket_report?.platform) {
    reportData?.ticket_report.platform.map((item, index) => {
      if (item.platform) {
        const lastThirdKeyN = `${returnMonthName(lastThird)}Neg`; // Construct the dynamic key
        const lastThirdCountN =
          item.monthwise.buckets[0].sentiment.buckets[0].doc_count;
        const lastThirdNegativePercentage =
          item.monthwise?.buckets[0]?.sentiment?.buckets[0]
            ?.negative_percentage;

        const lastThirdKeyNeutral = `${returnMonthName(lastThird)}Neutral`; // Construct the dynamic key
        const lastThirdCountNeutral =
          item.monthwise.buckets[0]?.sentiment?.buckets[1].doc_count ?? 0;

        const lastSecondKeyN = `${returnMonthName(lastSecond)}Neg`; // Construct the dynamic key
        const lastSecondCountN =
          item.monthwise.buckets[1].sentiment.buckets[0].doc_count;
        const lastSecondNegativePercentage =
          item.monthwise.buckets[1]?.sentiment?.buckets[0]?.negative_percentage;

        const lastSecondKeyNeutral = `${returnMonthName(lastSecond)}Neutral`; // Construct the dynamic key
        const lastSecondCountNeutral =
          item.monthwise.buckets[1]?.sentiment?.buckets[1].doc_count ?? 0;

        const currentMonthKeyN = `${returnMonthName(currentMonth)}Neg`; // Construct the dynamic key
        const currentMonthCountN =
          item.monthwise.buckets[2]?.sentiment?.buckets[0]?.doc_count ?? 0;
        const currentMonthNegativePercentage =
          item.monthwise.buckets[2]?.sentiment?.buckets[0]?.negative_percentage;

        const currentMonthKeyNeutral = `${returnMonthName(
          currentMonth
        )}Neutral`; // Construct the dynamic key
        const currentMonthCountNeutral =
          item.monthwise.buckets[2]?.sentiment?.buckets[1].doc_count ?? 0;

        const lastThirdKeyP = `${returnMonthName(lastThird)}Pos`; // Construct the dynamic key

        const lastThirdCountP =
          item.monthwise.buckets[0].sentiment.buckets[2].doc_count;

        const lastSecondKeyP = `${returnMonthName(lastSecond)}Pos`; // Construct the dynamic key
        const lastSecondCountP =
          item.monthwise.buckets[1].sentiment.buckets[2].doc_count;

        const currentMonthKeyP = `${returnMonthName(currentMonth)}Pos`; // Construct the dynamic key
        const currentMonthCountP =
          item.monthwise.buckets[2].sentiment.buckets[2].doc_count;

        const row = {
          id: index + 1,
          platforms: Object.keys(item).includes("overall_total")
            ? "Total"
            : item.platform,
          cumulativeTotal: item?.doc_count,
          cumulativeNegative: prefixZero_for_singleDigit(item.negative_count),
          cumulativePositive: prefixZero_for_singleDigit(item.positive_counts),
          cumulativeNeutral: prefixZero_for_singleDigit(item.neutral_counts),
          negative_percentage: item.negative_percentage,
        };

        row[lastThirdKeyN] = lastThirdCountN;
        row[lastThirdKeyNeutral] = lastThirdCountNeutral;
        row[`${lastThirdKeyN}-Perc`] = lastThirdNegativePercentage
          ? lastThirdNegativePercentage
          : 0;

        row[lastSecondKeyN] = lastSecondCountN;
        row[currentMonthKeyN] = currentMonthCountN;
        row[`${lastSecondKeyN}-Perc`] = lastSecondNegativePercentage
          ? lastSecondNegativePercentage
          : 0;

        row[lastSecondKeyNeutral] = lastSecondCountNeutral;
        row[currentMonthKeyNeutral] = currentMonthCountNeutral;
        row[`${currentMonthKeyN}-Perc`] = currentMonthNegativePercentage
          ? currentMonthNegativePercentage
          : 0;

        row[lastThirdKeyP] = lastThirdCountP;
        row[lastSecondKeyP] = lastSecondCountP;
        row[currentMonthKeyP] = currentMonthCountP;

        rows.push(row);
      }
    });
  }

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      const emptySymbol = "\u2205"; // Unicode representation for ∅

      rows?.forEach((item, i) => {
        exportData?.push({
          Platforms: Array?.isArray(item?.platforms)
            ? item?.platforms[0]
            : item?.platforms,
          [`${returnMonthName(
            lastThird
          )} +ve | -ve | ${emptySymbol} | (-ve %)`]: `${prefixZero_for_singleDigit(
            item[`${returnMonthName(lastThird)}Pos`]
          )} | ${prefixZero_for_singleDigit(
            prefixZero_for_singleDigit(item[`${returnMonthName(lastThird)}Neg`])
          )} | ${prefixZero_for_singleDigit(
            item[`${returnMonthName(lastThird)}Neutral`]
          )} | (${prefixZero_for_singleDigit(
            item[`${returnMonthName(lastThird)}Neg-Perc`]
          )}%)`,

          [`${returnMonthName(
            lastSecond
          )} +ve | -ve | ${emptySymbol} | (-ve %)`]: `${prefixZero_for_singleDigit(
            item[`${returnMonthName(lastSecond)}Pos`]
          )} | ${prefixZero_for_singleDigit(
            prefixZero_for_singleDigit(
              item[`${returnMonthName(lastSecond)}Neg`]
            )
          )} | ${prefixZero_for_singleDigit(
            item[`${returnMonthName(lastSecond)}Neutral`]
          )} | (${prefixZero_for_singleDigit(
            item[`${returnMonthName(lastSecond)}Neg-Perc`]
          )}%)`,

          [`${returnMonthName(
            currentMonth
          )} +ve | -ve | ${emptySymbol} | (-ve %)`]: `${prefixZero_for_singleDigit(
            item[`${returnMonthName(currentMonth)}Pos`]
          )} | ${prefixZero_for_singleDigit(
            prefixZero_for_singleDigit(
              item[`${returnMonthName(currentMonth)}Neg`]
            )
          )} | ${prefixZero_for_singleDigit(
            item[`${returnMonthName(currentMonth)}Neutral`]
          )} | (${prefixZero_for_singleDigit(
            item[`${returnMonthName(currentMonth)}Neg-Perc`]
          )}%)`,

          [`Total +ve | -ve | ${emptySymbol}`]: `${prefixZero_for_singleDigit(
            item?.cumulativePositive
          )} | ${prefixZero_for_singleDigit(
            item?.cumulativeNegative
          )} | ${prefixZero_for_singleDigit(item?.cumulativeNeutral)}`,

          [`${returnMonthName(
            currentMonth
          )} Negative %`]: `${item?.negative_percentage}%`,
        });
      });

      props.setexcelData(exportData);
    }
  }, [props.DownloadClicked, rows]);

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <Box
          sx={{
            width: "100%",
            padding: "12px",
          }}
        >
          {apiError ? (
            <Box
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flex: "12",
              }}
            >
              {apiError}
            </Box>
          ) : !loading && !noDataFoundBool ? (
            <div
              style={{
                width: "100%",
                overflowX: "auto",
                borderRadius: theme.table.borderRadius,
              }}
            >
              {props.dataGridHeight?.current && rows && (
                <DataGridFunction
                  // rows={rows.splice(0, rows.length - 5)}
                  rows={rows}
                  columns={columns}
                  rowBorder={false}
                  columnGroupingModel={columnGroupingModel}
                  height={props.dataGridHeight.current}
                  rowHeight={50}
                  headerHeight={80}
                  onCellClick={onCellClick}
                  rowColor={true}
                  headerColor={true}
                />
              )}
            </div>
          ) : (
            !loading && <NoDataFound />
          )}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage />
        </Box>
      )}
    </LoadingIndicator>
  );
};

export default PlatformWiseOverallSentiment_mentions;
