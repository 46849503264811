import React, { useEffect, useRef, useState, useCallback } from "react";
import environment from "environment";
import "../cssFiles/OverAllMentionsPaltformBased.css";
import { endDate, monthNames, startDate } from "../dateFunctions/DateFunctions";
import { format } from "date-fns";
import Logo from "../logoContainer/Logo";
import { Box, Typography } from "@mui/material";
import { Line } from "react-chartjs-2";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import { theme } from "../../../../views/App";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../../utilities/FetchRequest";
import { NivoBarChart } from "../../../../components/nivoCharts/NivoBarChart";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../../components/ticketPartials";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import { height, width } from "@mui/system";

function TotalMentionsChart(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [today, setToday] = useState(new Date());
  const [apiError, setApiError] = useState(null);
  let current_month = new Date();
  let current_month_end_date = endDate(current_month);
  console.log("apiError", apiError);
  //  before_previous_month
  let before_previous_month = new Date();
  before_previous_month.setMonth(before_previous_month.getMonth() - 2);
  let before_previous_month_start_date = startDate(before_previous_month);

  function platformName(platformId) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === platformId)
        .map((item) => {
          return item.platform_alias;
        })
    );
  }

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);

      await fetchData(before_previous_month_start_date, current_month_end_date);

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setToday(new Date());

        setLoading(true);

        await fetchData(
          before_previous_month_start_date,
          current_month_end_date
        );

        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if ((props.openDateFilter, props.filterClicked)) {
      const date = new Date(props.openDateFilter);
      setToday(new Date(props.openDateFilter));

      const firstDateOfLastMonth = new Date(
        date.getFullYear(),
        date.getMonth() - 2,
        1
      );
      const firstDateString = format(firstDateOfLastMonth, "yyyy-MM-dd");

      const endOfCurrentMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      );
      const endDateString = format(endOfCurrentMonth, "yyyy-MM-dd");

      (async function CallApi() {
        setLoading(true);

        await fetchData(new Date(firstDateString), new Date(endDateString));

        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchData(from, to) {
    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["platform"]&from=${format(
      from,
      "yyyy-MM-dd"
    )}&to=${format(to, "yyyy-MM-dd")}`;

    await axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (response.hasOwnProperty("message")) {
          setReportData(null);
          setApiError(response.message);
        } else {
          setReportData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setReportData(null);
        setApiError(err.message);
        setLoading(false);
      });
  }

  const total_count = [];
  const negative_count = [];
  const labels = [];

  let data = [];

  reportData &&
    reportData.ticket_report &&
    reportData.ticket_report.platform &&
    reportData.ticket_report.platform.length > 0 &&
    reportData.ticket_report.platform
      .slice(0, reportData.ticket_report.platform.length - 6)
      .map((platformData) => {
        total_count.push(platformData.doc_count);
        negative_count.push(platformData.negative_count);
        labels.push(platformName(platformData.platform)[0]);
      });

  let TotalCountMaxValue = Math.max(...total_count);
  let NegativeCountMaxValue = Math.max(...negative_count);

  let MaxValue = TotalCountMaxValue + NegativeCountMaxValue;

  function getDigit(value) {
    let digit = 1;

    for (let i = 0; value >= 10; i++) {
      value /= 10;
      digit *= 10;
    }

    return digit;
  }

  const digit = getDigit(MaxValue);
  let num = parseInt(MaxValue / digit);

  let updatedDigit = num * digit;

  let stepSize = updatedDigit / 10;

  const remainder = MaxValue % stepSize;

  const difference = stepSize - remainder;

  MaxValue += difference;

  const state = {
    labels: labels,
    datasets: [
      {
        type: "bar",
        label: "Total Count",
        backgroundColor: `${theme.reports.total}`,
        data: total_count,
        pointStyle: "rect",
      },
      {
        type: "bar",
        label: "Negative Count",
        backgroundColor: `${theme.reports.negative}`,
        data: negative_count,
        pointStyle: "rect",
      },
    ],
  };

  const chartData = labels.map((label, index) => ({
    Platforms: label,
    "Total Count": prefixZero_for_singleDigit(total_count[index]),
    "Negative Count": -prefixZero_for_singleDigit(negative_count[index]),
  }));

  let legendOffset,
    maxYAxisLength = 0;
  if (chartData.length > 0) {
    maxYAxisLength =
      chartData &&
      Math.max(
        ...chartData?.map((item) => {
          return String(item.Platforms?.length);
        })
      );
    if (maxYAxisLength > 30) legendOffset = -120 - maxYAxisLength * 3;
    else if (maxYAxisLength > 20) legendOffset = -120 - maxYAxisLength * 2;
    else if (maxYAxisLength > 10) legendOffset = -120 - maxYAxisLength;
    else if (maxYAxisLength > 0) legendOffset = -120;
  }
  const getBarOption = () => {
    const formatValue = (value) => {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + "K";
      }
      return value;
    };
    let shouldShowLegend = false;

    if (
      (props.ChartContainerHeight?.current &&
        props.ChartContainerWidth?.current &&
        props.ChartContainerHeight.current >= 350 &&
        props.ChartContainerWidth.current > 350) ||
      (props.ChartContainerHeight?.current === undefined &&
        props.ChartContainerWidth?.current === undefined)
    ) {
      shouldShowLegend = true;
    }
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        confine: true,
        formatter: (params) => {
          let tooltipText = `${params[0].axisValue}<br/>`;
          params.forEach((item) => {
            const value = Math.abs(item.value);
            let formattedvalue = formatValue(value);
            tooltipText += `${item.marker} ${item.seriesName}: ${formattedvalue}<br/>`;
          });
          return tooltipText;
        },
      },

      legend: {
        icon: "circle",
        data: shouldShowLegend ? ["Total Count", "Negative Count"] : [],
      },
      grid: {
        top: props.ChartContainerHeight?.current >= 500 ? "5%" : "10%",
        left: "5%",
        right: "5%",
        bottom: "5%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "value",
          name:
            props.detailedReport || props.ChartContainerHeight?.current > 300
              ? "Count"
              : "",
          nameLocation: "bottom",
          nameTextStyle: {
            color: theme.palette.primary.main,
            fontWeight: "bold",
            fontFamily: "BookAntiqua",
            verticalAlign: "left",
            lineHeight: 25,
            padding: [20, 0, 0, 0],
          },
          axisLabel: {
            formatter: (value) => {
              let formattedValue = formatValue(value);
              return formattedValue;
            },
            hideOverlap: true,
          },
          splitLine: {
            show: true,
          },
        },
      ],
      dataZoom: [
        {
          type: "inside",
          yAxisIndex: 0,
        },
      ],
      yAxis: [
        {
          type: "category",
          name:
            props.detailedReport || props.ChartContainerWidth?.current > 600
              ? "Platforms"
              : "",
          nameLocation: "middle",
          nameTextStyle: {
            color: theme.palette.primary.main,
            fontWeight: "bold",
            fontFamily: "BookAntiqua",
            verticalAlign: "left",
            lineHeight: 0,
          },

          axisTick: {
            show: true,
            formatter: (params) => {
              return params.value === 0 ? "" : params.value;
            },
          },
          data: chartData.reverse().map((item) => item.Platforms),
          axisLabel: {
            show: props.detailedReport ? true : false,
            padding: [0, 30, 0, 0],
            hideOverlap: true,
          },
        },
      ],
      series: [
        {
          name: "Total Count",
          type: "bar",
          stack: "Total",
          label: {
            show: props.detailedReport ? true : false,
            position: "inside",
            formatter: (params) => {
              let val = params.value;
              let formattedvalue = formatValue(val);
              return val === 0 ? "" : formattedvalue;
            },
          },
          emphasis: {
            focus: "series",
            formatter: (params) => {
              return params.value === 0 ? "" : params.value;
            },
          },
          color: `${theme.reports.total}`,
          data: chartData.map((item) => parseInt(item["Total Count"], 10)),
        },
        {
          name: "Negative Count",
          type: "bar",
          stack: "Total",
          label: {
            show: props.detailedReport ? true : false,

            formatter: (params) => {
              let val = params.value;
              let formattedvalue = formatValue(val);
              return Math.abs(val) !== 0 ? Math.abs(formattedvalue) : "";
            },
          },
          emphasis: {
            focus: "series",
          },
          itemStyle: {
            color: `${theme.reports.negative}`,
          },
          data: chartData.map((item) => item["Negative Count"]),
        },
      ],
    };
  };
  const handleClick = (params) => {};

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      chartData?.map((item) => {
        exportData.push({
          Platforms: item?.Platforms,
          ["Total Count"]: prefixZero_for_singleDigit(item["Total Count"]),
          ["Negative Count"]: prefixZero_for_singleDigit(
            Math.abs(item["Negative Count"])
          ),
        });
      });

      props.setexcelData(exportData);
    }
  }, [chartData, props.DownloadClicked]);

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {apiError ? (
            <Box
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flex: "12",
              }}
            >
              {apiError}
            </Box>
          ) : !loading &&
            total_count?.length !== 0 &&
            negative_count?.length !== 0 ? (
            <ReactEcharts
              option={getBarOption()}
              style={{
                height: "100%",
                width: "100%",
              }}
              notMerge={true}
              lazyUpdate={true}
            />
          ) : (
            !loading && <NoDataFound />
          )}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage width={"50%"} />
        </Box>
      )}
    </LoadingIndicator>
  );
}

export default TotalMentionsChart;
