import React, { useEffect, useRef, useState } from "react";
import "./cssFiles/ResponseManagementPlatformCounts.css";
import environment from "environment";
import { Box, useMediaQuery } from "@mui/material";
import { theme } from "../../../views/App";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { format, isValid } from "date-fns";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";

function YoutubeViewsOvertime(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    setData(null);
    (async function CallApi() {
      setLoading(true);

      props.account_selected &&
        (await fetchData(props.account_selected, props.openDateFilter));

      setLoading(false);
    })();
  }, [props.openDateFilter, props.account_selected]);

  useEffect(() => {
    if (props.clearClicked) {
      setData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.account_selected, props.openDateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      setData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.account_selected, props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.account_selected, props.filterClicked, props.openDateFilter]);

  async function fetchData(account_id, dateRange) {
    let API = `${environment.api.youtubeReports}?account_id=${
      account_id?.account_id
    }&report_type=videos_over_time${
      props.openDateFilter || props.filterClicked
        ? `&start_date=${dateRange[0]}&end_date=${dateRange[1]}`
        : `&start_date=${format(
            new Date().setFullYear(new Date().getFullYear() - 1),
            "yyyy-MM-dd"
          )}&end_date=${format(new Date(), "yyyy-MM-dd")}`
    }`;
    await axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (
          response.hasOwnProperty("error") ||
          response.hasOwnProperty("error_id")
        ) {
          response.hasOwnProperty("error_id")
            ? setApiError(`${response?.message} - ${response?.error_id}`)
            : setApiError(response?.error);
          setData(null);
        } else {
          setData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setData(null);
        setApiError(err.message);
        setLoading(false);
      });
  }

  let NoDataFoundBool = !data && !props.account_selected;

  useEffect(() => {
    if (props.DownloadClicked) {
      let exportData = [];

      data &&
        Object.keys(data)?.length > 0 &&
        Object.entries(data).map(([date, dateObj]) => {
          exportData.push({
            Date: date === "totalviews" ? "Total" : date,
            "Views Count": date === "totalviews" ? dateObj : dateObj.views,
          });
        });

      props.setexcelData(exportData);
    }
  }, [props.DownloadClicked, data]);

  let responseData = (data && Object.entries(data)) || [];

  const itemsToRemove = ["totalviews"];

  // Filter out inner arrays containing any of the specified items
  responseData = responseData.filter(
    (innerArray) => !innerArray.some((item) => itemsToRemove.includes(item))
  );

  const chartData = [
    {
      id: "Views",
      color: `${theme.reports.positive}`,
      data: responseData
        ?.filter((innerArray) => !innerArray.includes("totalvalue"))
        ?.map(([date, viewsObj], index) => {
          return {
            x:
              date && isValid(new Date(date))
                ? format(new Date(date), "yyyy-MM-dd")
                : date,
            y: viewsObj?.views,
          };
        }),
    },
  ];

  const getOption = () => {
    let allYValues = [];
    chartData?.forEach((series) => {
      series.data.forEach((point) => {
        point.y && allYValues.push(point.y);
      });
    });

    // Calculate the minimum and maximum y values
    const minValue = Math.min(...allYValues);
    const maxValue = Math.max(...allYValues);

    let shouldShowLegend = false;
    if (
      (props.ChartContainerHeight?.current &&
        props.ChartContainerWidth?.current &&
        props.ChartContainerHeight.current >= 350 &&
        props.ChartContainerWidth.current > 350) ||
      (props.ChartContainerHeight?.current === undefined &&
        props.ChartContainerWidth?.current === undefined)
    ) {
      shouldShowLegend = true;
    }
    const formatValue = (value) => {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + "K";
      }
      return value;
    };
    return {
      tooltip: {
        trigger: "axis",
        position: function (pt) {
          return [pt[0], "10%"];
        },
        confine: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: chartData && chartData[0]?.data.map((point) => point.x),
        name:
          props.detailedReport || props.ChartContainerHeight?.current > 300
            ? "Date"
            : "",
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: 25,
        },
        axisLabel: {
          hideOverlap: true,
        },
      },
      yAxis: {
        type: "value",
        boundaryGap: [0, "100%"],
        name:
          props.detailedReport || props.ChartContainerWidth?.current > 600
            ? "Count"
            : "",
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: -20,
        },
        nameGap: 30,
        min: minValue,
        max: maxValue,
        axisLabel: {
          padding: props.detailedReport ? [0, 50, 0, 0] : [0, 0, 0, 0],
          formatter: (value) => {
            let formattedValue = formatValue(value);
            return formattedValue;
          },
          hideOverlap: true,
        },
      },
      legend: {
        show: true,
        data: shouldShowLegend ? "Views" : [],
        top: 0,
      },

      dataZoom: [
        {
          type: "inside",
          start: 0,
          end: 100,
        },
      ],
      grid: {
        top: props.ChartContainerHeight?.current >= 500 ? "15%" : "10%",
        left: "5%",
        right: "5%",
        bottom: "5%",
        containLabel: true,
      },
      series:
        chartData &&
        chartData.map((series) => ({
          name: series.id,
          type: "line",
          symbol: "none",
          sampling: "lttb",
          itemStyle: {
            color: series.color,
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: series.color,
              },
              {
                offset: 1,
                color: series.color,
              },
            ]),
          },
          data: series.data.map((point) => point.y),
        })),
    };
  };

  return (
    <LoadingIndicator isActive={loading}>
      <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flex: "12",
        }}
      >
        {!loading ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            {!NoDataFoundBool ? (
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                }}
              >
                {apiError ? (
                  <Box
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      flex: "12",
                    }}
                  >
                    {apiError}
                  </Box>
                ) : (
                  <Box
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      flex: "12",
                    }}
                  >
                    {/* {props.detailedReport && (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "5px",
                        fontSize: "14px",
                        gap: "10px",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "30%",
                        }}
                      >
                        <span
                          style={{
                            color: `${theme.reports.total}`,
                            fontWeight: "bold",
                          }}
                        >
                          Total Views &nbsp;&nbsp;
                          0
                        </span>
                      </div>
                      <div
                        style={{
                          width: "20%",
                        }}
                      >
                        <span style={{ color: `${theme.reports.total}` }}>
                          Average View Duration&nbsp;&nbsp;
                          0
                        </span>
                      </div>
                      <div
                        style={{
                          width: "20%",
                        }}
                      >
                        <span style={{ color: `${theme.reports.total}` }}>
                          Average View Percentage &nbsp;&nbsp;
                          0
                        </span>
                      </div>
                    </Box>
                  )} */}
                    <Box style={{ width: "100%", height: "100%" }}>
                      {data && Object.keys(data).length && (
                        <ReactEcharts
                          option={getOption()}
                          style={{
                            height: "100%",
                            width: "100%",
                          }}
                          notMerge={true}
                          lazyUpdate={true}
                        />
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            ) : (
              <NoDataFound />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage width={"50%"} />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  );
}

export default YoutubeViewsOvertime;
