import ActionUtility from "../../../../utilities/ActionUtility";
import TelegramDatasetsEffect from "./TelegramDatasetsEffect";

export default class TelegramDatasetsACtion {
  static REQUEST_GET_TELEGRAM_DATASETS =
    "TelegramDatasetsACtion.REQUEST_GET_TELEGRAM_DATASETS";
  static REQUEST_GET_TELEGRAM_DATASETS_FINISHED =
    "TelegramDatasetsACtion.REQUEST_GET_TELEGRAM_DATASETS_FINISHED";

  static REQUEST_DELETE_DATASETS =
    "TelegramDatasetsACtion.REQUEST_DELETE_DATASETS";
  static REQUEST_DELETE_DATASETS_FINISHED =
    "TelegramDatasetsACtion.REQUEST_DELETE_DATASETS_FINISHED";

  static REQUEST_GET_TELEGRAM_BOTS =
    "TelegramDatasetsACtion.REQUEST_GET_TELEGRAM_BOTS";
  static REQUEST_GET_TELEGRAM_BOTS_FINISHED =
    "TelegramDatasetsACtion.REQUEST_GET_TELEGRAM_BOTS_FINISHED";

  // METHODS
  static requestTelegramDatasets(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramDatasetsACtion.REQUEST_GET_TELEGRAM_DATASETS,
        TelegramDatasetsEffect.requestTelegramDatasets,
        params,
        callback
      );
    };
  }

  static requestTelegramBots(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramDatasetsACtion.REQUEST_GET_TELEGRAM_BOTS,
        TelegramDatasetsEffect.requestTelegramBots,
        params,
        callback
      );
    };
  }

  static requestDeleteDataSets(id, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramDatasetsACtion.REQUEST_DELETE_DATASETS,
        TelegramDatasetsEffect.requestDeleteDataSets,
        id,
        callback
      );
    };
  }
}
