import React from "react";
import AllmentionsMain from "../../ormTicketing/views/ticketsListView/allMentions/AllmentionsMain";
import { Box, Grid } from "@mui/material";
import SocialListeningMentions from "./SocialListeningMentions";

function Mentions() {
  return (
    <Box
      height={"calc(100% - 40px)"}
      width="100%"
      // className="bg-info"
      position={"relative"}
      sx={{
        flexGrow: 1,
      }}
      // overflow="hidden"
    >
      <Grid
        item
        container
        width={"100%"}
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="scrollable"
        style={{
          height: "100%",
          maxHeight: `${window.innerHeight}px`,
          overflow: "auto",
          position: "relative",
        }}
      >
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <SocialListeningMentions />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Mentions;
