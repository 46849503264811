import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import LoadingIndicator from "../loading-indicator/LoadingIndicator";
import { connect } from "react-redux";
import { newroutes } from "./routecoll";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import ToastsAction from "../../stores/toasts/ToastsAction";
import { useSnackbar } from "notistack";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, []),
  auth: state.auth,
  pagePermission: state.pagePermission.pagePermission || [],
  toasts: state.toasts || [],
});
const MyRoute = (props) => {
  const location = useLocation();
  const [prevlocation, setPrevlocation] = useState(null);
  const { closeSnackbar } = useSnackbar();

  const [routes, setRoutes] = useState({ data: [] });
  async function AppRoutes() {
    const data = await newroutes();
    setRoutes(data);
  }

  useEffect(() => {
    if (location.pathname) {
      setPrevlocation(location.pathname);
    }

    if (props.toasts?.items?.length > 0 && prevlocation !== location.pathname) {
      const toastId = props.toasts.items[0].id; // Assuming you want to remove the first toast in the array
      new Promise((resolve) => {
        props.dispatch(ToastsAction.removeById(toastId));
        resolve();
      }).then(() => {
        closeSnackbar(toastId);
      });
    }
  }, [location, props.toasts]);

  useEffect(() => {
    if (location.key === "default") {
      if (location.pathname === "/twittercallback") {
        sessionStorage.setItem(
          "twittercallback",
          JSON.stringify({ searchQuery: location.search })
        );
      }

      if (location.pathname === "/influencer_facebook_auth_login") {
        sessionStorage.setItem(
          "influencerFacebookCallBack",
          JSON.stringify({ searchQuery: location.search })
        );
      }

      if (location.pathname === "/influencer_youtube_auth_login") {
        sessionStorage.setItem(
          "influencerYoutubeCallBack",
          JSON.stringify({ searchQuery: location.search })
        );
      }

      if (location.pathname === "/reddit_oauth") {
        sessionStorage.setItem(
          "redditcallback",
          JSON.stringify({ searchQuery: location.search })
        );
      }

      if (location.pathname === "/google_auth_login") {
        sessionStorage.setItem(
          "googlecallback",
          JSON.stringify({ searchQuery: location.search })
        );
      }
      if (location.pathname === "/youtube_auth_login") {
        sessionStorage.setItem(
          "youtubecallback",
          JSON.stringify({ searchQuery: location.search })
        );
      }
      if (location.pathname === "/facebook_auth_login") {
        sessionStorage.setItem(
          "facebookcallback",
          JSON.stringify({ searchQuery: location.search })
        );
      }
      if (location.pathname === "/gmb_auth_login") {
        sessionStorage.setItem(
          "gmbcallback",
          JSON.stringify({ searchQuery: location.search })
        );
      }
      if (location.pathname === "/linkedIn_auth_login") {
        sessionStorage.setItem(
          "linkedIncallback",
          JSON.stringify({ searchQuery: location.search })
        );
      }
      if (location.pathname === "/discord_auth_login") {
        sessionStorage.setItem(
          "discordcallback",
          JSON.stringify({ searchQuery: location.search })
        );
      }
      if (location.pathname === "/pinterest_auth_login") {
        sessionStorage.setItem(
          "pinterestcallback",
          JSON.stringify({ searchQuery: location.search })
        );
      }
    } else {
      if (location.pathname === "/addAccount") {
        sessionStorage.removeItem("twittercallback");
        sessionStorage.removeItem("googlecallback");
        sessionStorage.removeItem("youtubecallback");
        sessionStorage.removeItem("facebookcallback");
        sessionStorage.removeItem("gmbcallback");
        sessionStorage.removeItem("linkedIncallback");
        sessionStorage.removeItem("discordcallback");
        sessionStorage.removeItem("pinterestcallback");
      }

      if (location.pathname === "/influencersPlatform") {
        sessionStorage.removeItem("influencerFacebookCallBack");
        sessionStorage.removeItem("influencerYoutubeCallBack");
        sessionStorage.removeItem("influencer_for_oauth");
      }
    }

    AppRoutes();
  }, [location]);

  // getting page_permission from localstorage
  const page_permission = localStorage.getItem("page_permission")
    ? [...JSON.parse(localStorage.getItem("page_permission"))]
    : [];

  return (
    <Suspense fallback={<LoadingIndicator isActive={true} />}>
      <Routes>
        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) => col.category === "Main" && col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                  //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })}

        {routes.data
          .filter((col) => col.category === "no_auth")
          .map((item) => {
            return (
              <Route
                //  exact="true"
                path={item.path}
                key={item.id}
                element={item.element}
              />
            );
          })}

        {/**********************Nested Influencer Items Mapping***************************************/}
        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) =>
                col.category === "Influencer" && col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                  //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })}
        {/**********************Nested MCN Items Mapping***************************************/}
        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) => col.category === "Youtube" && col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                  //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })}
        {/**********************Nested Survey Tools Items Mapping***************************************/}
        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) => col.category === "Survey" && col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                  //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })}
        {/**********************Nested Reports Items Mapping***************************************/}
        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) => col.category === "Reports" && col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                  //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })}

        {/**********************Nested Social_Posting Items Mapping***************************************/}
        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) =>
                col.category === "Social_Posting" && col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                  //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })}

        {/**********************Nested Menu Items Mapping***************************************/}
        {/* {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) => col.category === "Feeds" && col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                   //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })} */}
        {/**********************Nested Menu Items Mapping for settings***************************************/}
        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) => col.category === "Settings" && col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                  //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })}
        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) =>
                col.category === "Ticket Settings" && col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                  //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })}

        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) =>
                col.category === "Import Reports" && col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                  //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })}

        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) =>
                col.category === "Workflow Settings" &&
                col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                  //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })}

        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) =>
                col.category === "User Settings" && col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                  //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })}
        {/* Data extraction */}
        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) =>
                col.category === "DataExtraction" && col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                  //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })}
        {/* Cloud Mail */}
        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) =>
                col.category === "Cloud Mail" && col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                  //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })}
        {/* Site discovery */}
        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) =>
                col.category === "Site Discovery" && col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                  //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })}
        {/* link checking */}
        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) =>
                col.category === "Link Checking" && col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                  //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })}
        {/* A3Labels */}
        {routes.data
          .filter((col) => col.category === "A3 Labels")
          .map((item) => {
            return (
              <Route
                //  exact="true"
                path={item.path}
                key={item.id}
                element={item.element}
              />
            );
          })}
        {/**********************Nested Menu Items Mapping for Social Listening  ***************************************/}

        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) =>
                col.category === "Social Listening" &&
                col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                  //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })}

        {/**********************Nested Menu Items Mapping for Cloudfare  ***************************************/}

        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) =>
                col.category === "Cloudfare" && col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                  //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })}

        {/**********************Logout***************************************/}
        {routes.data
          .filter((col) => col.category === "Logout")
          .map((item) => {
            return (
              <Route
                //  exact="true"
                path={item.path}
                key={item.id}
                element={item.element}
              />
            );
          })}
        {/*CRM*/}
        {routes.data
          .filter((col) => col.category === "CRM Core")
          .map((item) => {
            return (
              <Route
                //  exact="true"
                path={item.path}
                key={item.id}
                element={item.element}
              />
            );
          })}
        {/* YouTube Official*/}
        {routes.data
          .filter((col) => col.category === "YoutubeOfficial")
          .map((item) => {
            return (
              <Route
                //  exact="true"
                path={item.path}
                key={item.id}
                element={item.element}
              />
            );
          })}

        {/* Revenue*/}
        {routes.data
          .filter((col) => col.category === "Revenue")
          .map((item) => {
            return (
              <Route
                //  exact="true"
                path={item.path}
                key={item.id}
                element={item.element}
              />
            );
          })}

        {/* Releases MetaData */}
        {routes.data
          .filter((col) => col.category === "Releases")
          .map((item) => {
            return (
              <Route
                //  exact="true"
                path={item.path}
                key={item.id}
                element={item.element}
              />
            );
          })}

        {/* Draft Releases MetaData */}
        {routes.data
          .filter((col) => col.category === "Draft Releases")
          .map((item) => {
            return (
              <Route
                //  exact="true"
                path={item.path}
                key={item.id}
                element={item.element}
              />
            );
          })}

        {/* Draft Releases MetaData */}
        {routes.data
          .filter((col) => col.category === "review Releases")
          .map((item) => {
            return (
              <Route
                //  exact="true"
                path={item.path}
                key={item.id}
                element={item.element}
              />
            );
          })}

        {/* Finger Printing */}
        {routes.data
          .filter((col) => col.category === "FingerPrinting")
          .map((item) => {
            return (
              <Route
                //  exact="true"
                path={item.path}
                key={item.id}
                element={item.element}
              />
            );
          })}

        {/**********************Nested DSR Tools Items Mapping***************************************/}
        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) => col.category === "DSR" && col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                  //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })}

        {/**********************Nested WebSweepx Tools Items Mapping***************************************/}
        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) =>
                col.category === "WebsweepX" && col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                  //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })}

        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) =>
                col.category === "WebsweepXTelegram" &&
                col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route path={item.path} key={item.id} element={item.element} />
              );
            });
        })}

        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) =>
                col.category === "WebsweepXGoogle" && col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                  //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })}

        {/**********************Nested Webform Tools Items Mapping***************************************/}
        {page_permission.map((allowed_page_Id) => {
          return routes.data
            .filter(
              (col) =>
                col.category === "Webform_Automation" &&
                col.id === allowed_page_Id
            )
            .map((item) => {
              return (
                <Route
                  //  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            });
        })}
      </Routes>
    </Suspense>
  );
};
export default connect(mapStateToProps)(MyRoute);
