import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const Version = () => {
  console.log("logcheck", process.env.ORM_VERSION);
  return (
    <Card
      sx={{
        maxWidth: 300,
        mx: "auto",
        mt: 2,
        p: 2,
        textAlign: "center",
        backgroundColor: "#f5f5f5",
        borderRadius: 2,
      }}
    >
      <CardContent>
        <Typography variant="h6" color="primary">
          App Version
        </Typography>
        <Typography variant="body1" fontWeight="bold">
          {process.env.REACT_APP_ORM_VERSION || "Unknown"}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Version;
