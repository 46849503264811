import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import Description from "./descriptionContainer/Description";
import Logo from "./logoContainer/Logo";
import { format } from "date-fns";
import { Box, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { NivoLineChart } from "../../../components/nivoCharts/NivoLineChart";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";

const SentimentOvertime = (props) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchData(props.DateFilter);
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.DateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  Chart.register(CategoryScale);
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  async function fetchData(dateRange) {
    let API = `${
      environment.api.sentimentalAnalysisReports
    }?report_type=["count"]&reports=["overall_sentiment_value_across_platform"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (response.hasOwnProperty("message")) {
          setData(null);
          setApiError(response.message);
        } else {
          setData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setData(null);
        setApiError(err.message);
        setLoading(false);
      });
  }

  let Total_list = [];

  let LabelList = [];
  let Positive_Sentiment = [];
  let Negative_Sentiment = [];
  let Neutral_Sentiment = [];
  let Total_Sentiment = [];

  data &&
    data.sentiment_analysis_report &&
    data.sentiment_analysis_report.overall_sentiment_value_across_platform &&
    Object.entries(
      data.sentiment_analysis_report.overall_sentiment_value_across_platform
    ).map(([key, value]) => {
      Total_list.push({
        [key]: value,
      });
    });

  let [
    date_wise,
    total_mentions,
    total_negative,
    total_neutral,
    total_positive,
  ] = Total_list;

  data &&
    data.sentiment_analysis_report &&
    data.sentiment_analysis_report.overall_sentiment_value_across_platform &&
    data.sentiment_analysis_report.overall_sentiment_value_across_platform.date_wise.map(
      (month) => {
        LabelList.push(returnMonthName(month[0]));

        month[1].map((e) => {
          if (e.key === "positive_sentiment") {
            Positive_Sentiment.push(e.count);
          } else if (e.key === "negative_sentiment") {
            Negative_Sentiment.push(e.count);
          } else if (e.key === "neutral_sentiment") {
            Neutral_Sentiment.push(e.count);
          } else if (e.key === "total_count") {
            Total_Sentiment.push(e.count);
          }
        });
      }
    );

  function returnMonthName(monthString) {
    const dateString = monthString;
    const [day, month, year] = dateString.split("-");
    const date = new Date(`${year}`, month - 1, day);
    const formattedDate = date.toLocaleString("en-US", {
      month: "short",
      year: "numeric",
    });

    return formattedDate;
  }

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      data?.sentiment_analysis_report?.overall_sentiment_value_across_platform
        ?.date_wise?.length &&
        data?.sentiment_analysis_report?.overall_sentiment_value_across_platform?.date_wise?.map(
          (month) => {
            let monthName = returnMonthName(month[0]);

            let PositiveSentiment =
              month[1]?.filter(
                (item) => item.key === "positive_sentiment"
              )?.[0] || [];
            let NegativeSentiment =
              month[1]?.filter(
                (item) => item.key === "negative_sentiment"
              )?.[0] || [];
            let NeutralSentiment =
              month[1]?.filter(
                (item) => item.key === "neutral_sentiment"
              )?.[0] || [];
            let Total =
              month[1]?.filter((item) => item.key === "total_count")?.[0] || [];

            exportData.push({
              Month: monthName,
              Total: prefixZero_for_singleDigit(Total?.count),
              Positive: prefixZero_for_singleDigit(PositiveSentiment?.count),
              Negative: prefixZero_for_singleDigit(NegativeSentiment?.count),
              Neutral: prefixZero_for_singleDigit(NeutralSentiment?.count),
            });
          }
        );

      exportData.push("");

      exportData.push({
        Month: "Total Mentions",
        Total: prefixZero_for_singleDigit(
          data?.sentiment_analysis_report
            ?.overall_sentiment_value_across_platform?.total_mentions
        ),
      });

      exportData.push({
        Month: "Total Negative",
        Total: prefixZero_for_singleDigit(
          data?.sentiment_analysis_report
            ?.overall_sentiment_value_across_platform?.total_negative
        ),
      });

      exportData.push({
        Month: "Total Neutral",
        Total: prefixZero_for_singleDigit(
          data?.sentiment_analysis_report
            ?.overall_sentiment_value_across_platform?.total_neutral
        ),
      });

      exportData.push({
        Month: "Total Positive",
        Total: prefixZero_for_singleDigit(
          data?.sentiment_analysis_report
            ?.overall_sentiment_value_across_platform?.total_positive
        ),
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked]);

  const state = {
    labels: LabelList.reverse(),
    datasets: [
      {
        label: "Positive(+ve)",
        backgroundColor: `${theme.reports.positive}`,
        borderWidth: 1.5,
        borderColor: `${theme.reports.positive}`,
        data: Positive_Sentiment.reverse(),
        lineTension: 0.3,
        pointStyle: "circle",
        pointRadius: 6,
      },
      {
        label: "Negative(-ve)",
        backgroundColor: `${theme.reports.negative}`,
        data: Negative_Sentiment.reverse(),
        borderWidth: 1.5,
        borderColor: `${theme.reports.negative}`,
        lineTension: 0.3,
        pointStyle: "triangle",
        pointRadius: 6,
      },
      {
        label: "Neutral(∅)",
        backgroundColor: `${theme.reports.neutral}`,
        data: Neutral_Sentiment.reverse(),
        borderWidth: 1.5,
        borderColor: `${theme.reports.neutral}`,
        lineTension: 0.3,
        pointStyle: "rect",
        pointRadius: 6,
      },
      {
        label: "Total Sentiment",
        backgroundColor: `${theme.reports.total}`,
        data: Total_Sentiment.reverse(),
        borderWidth: 1.5,
        borderColor: `${theme.reports.total}`,
        lineTension: 0.3,
        pointStyle: "rectRot",
        pointRadius: 6,
      },
    ],
  };
  let NoDataFoundBool =
    !loading && total_mentions && total_mentions.total_mentions === 0;

  const chartData = [
    {
      id: "Total",
      color: `${theme.reports.total}`,

      data: LabelList.map((Label, index) => ({
        x: new Date(Label).toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
        }),
        y: Total_Sentiment[index],
      })),
    },

    {
      id: "Positive(+ve)",
      color: `${theme.reports.positive}`,
      data: LabelList.map((Label, index) => ({
        x: new Date(Label).toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
        }),
        y: Positive_Sentiment[index],
      })),
    },
    {
      id: "Negetive(-ve)",
      color: `${theme.reports.negative}`,
      data: LabelList.map((Label, index) => ({
        x: new Date(Label).toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
        }),
        y: Negative_Sentiment[index],
      })),
    },
    {
      id: "Neutral(∅)",
      color: `${theme.reports.neutral}`,

      data: LabelList.map((Label, index) => ({
        x: new Date(Label).toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
        }),
        y: Neutral_Sentiment[index],
      })),
    },
  ];

  const formatTooltip = (value, id, data, point) => (
    <div
      style={{
        background: "white",
        padding: "10px",
        border: "1px solid #ccc",
      }}
    >
      <strong>
        {data.x}-{id}
      </strong>
      : {prefixZero_for_singleDigit(value)}
    </div>
  );

  const getLineOption = () => {
    const maxDataValue = Math.max(
      ...chartData.flatMap((series) => series.data.map((point) => point.y))
    );
    let shouldShowLegend = false;
    if (
      (props.ChartContainerHeight?.current &&
        props.ChartContainerWidth?.current &&
        props.ChartContainerHeight.current >= 350 &&
        props.ChartContainerWidth.current > 350) ||
      (props.ChartContainerHeight?.current === undefined &&
        props.ChartContainerWidth?.current === undefined)
    ) {
      shouldShowLegend = true;
    }
    const legendData = chartData.map((series) => series.id);
    const formatValue = (value) => {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + "K";
      }
      return value;
    };

    const option = {
      tooltip: {
        trigger: "axis",
        confine: true,
        formatter: (params) => {
          let tooltipContent = params
            .map((param, index) => {
              let val = param.value;
              let formattedValue = formatValue(val);
              const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${param.color};border-radius:50%;"></span>`;
              return `${colorSpan}${param.seriesName}: ${formattedValue}`;
            })
            .join("<br>");

          return tooltipContent;
        },
      },
      legend: {
        data: shouldShowLegend ? legendData : [],
      },
      xAxis: {
        type: "category",
        name:
          props.detailedReport || props.ChartContainerHeight?.current > 300
            ? "Month"
            : "",
        boundaryGap: [0, "100%"],
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: -20,
        },
        nameGap: 40,
        data: chartData[0].data.map((point) => point.x),
        axisLabel: {
          hideOverlap: true,
        },
      },
      grid: {
        top: props.ChartContainerHeight?.current >= 500 ? "5%" : "10%",
        left: "5%",
        right: "5%",
        bottom: "5%",
        containLabel: true,
      },
      yAxis: {
        type: "value",
        name:
          props.detailedReport || props.ChartContainerWidth?.current > 600
            ? "Sentiment"
            : "",
        boundaryGap: [0, "100%"],
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: -20,
        },
        max: "dataMax",
        nameGap: 45,
        axisLabel: {
          formatter: (value) => {
            let formattedValue = formatValue(value);
            return formattedValue;
          },
          hideOverlap: true,
        },
      },
      series: chartData.map((series) => ({
        name: series.id,
        type: "line",
        data: series.data.map((point) => point.y),
        itemStyle: {
          color: series.color || null,
        },
      })),
    };

    // Conditionally add dataZoom based on detailedReport prop
    if (!props.detailedReport) {
      option.dataZoom = [
        {
          type: "inside",
          start: 0,
          end: 100,
        },
      ];
    }

    return option;
  };

  return (
    <LoadingIndicator isActive={loading}>
      <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flex: "12",
        }}
      >
        {!loading ? (
          <Box height="100%">
            {apiError ? (
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  flex: "12",
                }}
              >
                {apiError}
              </Box>
            ) : !loading && !NoDataFoundBool ? (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  // paddingX: 5,
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    flex: "12",
                  }}
                >
                  {props.detailedReport && (
                    <Box>
                      <div
                        style={{
                          display: "flex",
                          // padding: "10px",
                          fontSize: "20px",
                          gap: "10px",
                          height: "60px",
                          maxWidth: "1200px",
                        }}
                      >
                        <div
                          style={{
                            width: "50%",
                            height: "40px",
                            color: "white",
                            padding: "5px",
                            backgroundColor: `${theme.reports.positive}`,
                          }}
                        >
                          <span>Positive</span> &nbsp;&nbsp;
                          <span>
                            {total_positive && total_positive.total_positive}
                          </span>
                        </div>
                        <div
                          style={{
                            backgroundColor: `${theme.reports.negative}`,
                            width: "50%",
                            color: "white",
                            padding: "5px",
                            height: "40px",
                          }}
                        >
                          <span>Negative</span> &nbsp;&nbsp;
                          <span>
                            {total_negative && total_negative.total_negative}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          // border: "1px solid grey",
                          display: "flex",
                          // padding: "10px",
                          fontSize: "20px",
                          gap: "10px",
                          marginTop: "10px",
                          marginBottom: "10px",
                          height: "60px",
                          maxWidth: "1200px",
                        }}
                      >
                        <div
                          style={{
                            width: "50%",
                            height: "40px",
                            color: "white",
                            padding: "5px",
                            backgroundColor: `${theme.reports.neutral}`,
                          }}
                        >
                          <span>Neutral</span> &nbsp;&nbsp;
                          <span>
                            {total_neutral && total_neutral.total_neutral}
                          </span>
                        </div>
                        <div
                          style={{
                            backgroundColor: `${theme.reports.total}`,
                            width: "50%",
                            color: "white",
                            padding: "5px",
                            height: "40px",
                          }}
                        >
                          <span>Total</span> &nbsp;&nbsp;
                          <span>
                            {total_mentions && total_mentions.total_mentions}
                          </span>
                        </div>
                      </div>
                    </Box>
                  )}
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box height={"100%"}>
                      <ReactEcharts
                        option={getLineOption()}
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                        notMerge={true}
                        lazyUpdate={true}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  );
};

export default SentimentOvertime;
