import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { endDate, monthNames, startDate } from "./dateFunctions/DateFunctions";
import { format } from "date-fns";
import Logo from "./logoContainer/Logo";
import Description from "./descriptionContainer/Description";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import PaginationUI from "../../../components/pagination/Pagination";
import { axiosRequest } from "../../../utilities/FetchRequest";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

function FRTEfficiencyAgentWise(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [loading, setLoading] = useState(false);

  const [current_month_data, set_current_month_data] = useState(null);
  const [apiError, setApiError] = useState(null);
  // this month
  let current_month = new Date();
  let current_month_end_date = endDate(current_month);

  // last month
  let previous_month = new Date();
  previous_month.setMonth(previous_month.getMonth() - 1);

  //  before_previous_month
  let before_previous_month = new Date();
  before_previous_month.setMonth(before_previous_month.getMonth() - 2);

  let before_previous_month_start_date = startDate(before_previous_month);

  // last three month names
  let current_month_name = monthNames[current_month.getMonth()];
  let previous_month_name = monthNames[previous_month.getMonth()];
  let before_previous_month_name = monthNames[before_previous_month.getMonth()];

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);

      // current month data fetching
      await fetchData(before_previous_month_start_date, current_month_end_date);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if ((props.openDateFilter, props.filterClicked)) {
      (async function CallApi() {
        setLoading(true);

        // current month data fetching
        await fetchData(
          new Date(props.openDateFilter[0]),
          new Date(props.openDateFilter[1])
        );
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchData(from, to) {
    let API = `${
      environment.api.agentReports
    }?report_type=["count"]&reports=["agent_based_first_response_time"]&from=${format(
      from,
      "yyyy-MM-dd"
    )}&to=${format(to, "yyyy-MM-dd")}`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (response.hasOwnProperty("message")) {
          set_current_month_data(null);
          setApiError(response.message);
        } else {
          set_current_month_data(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        set_current_month_data(null);
        setApiError(err.message);
        setLoading(false);
      });

    // return fetch(
    //   `${
    //     environment.api.agentReports
    //   }?report_type=["count"]&reports=["agent_based_first_response_time"]&from=${format(
    //     from,
    //     "yyyy-MM-dd"
    //   )}&to=${format(to, "yyyy-MM-dd")}`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((response) => {
    //     return response;
    //   });
  }

  let updatedRows = [];

  current_month_data &&
    current_month_data.agent_report &&
    current_month_data.agent_report.agent_based_first_response_time &&
    current_month_data.agent_report.agent_based_first_response_time.map(
      (item, index) => {
        const rows = {
          id: index + 1,
          user_name: Object.keys(item)[0],
          current_month_name: Object.values(item)[0][2],
          previous_month_name: Object.values(item)[0][1],
          before_previous_month_name: Object.values(item)[0][0],
          total_count: Object.values(item)[0][3],
        };

        Object.keys(item)[0] !== "Unassigned" && updatedRows.push(rows);
      }
    );

  const HoursAndMinutes = (decimal) => {
    let number = decimal ? decimal : 0;
    const hours = Math.floor(number); // Extract the whole number part (hours)
    const minutes = Math.floor((number - hours) * 60); // Convert the decimal part to minutes
    const seconds = Math.floor(((number - hours) * 60 - minutes) * 60); // Convert the decimal part to seconds

    return decimal
      ? `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      : "00:00:00";
  };

  let columns = [];

  columns.push(
    // { field: "id", headerName: "Sl No", resizable: false, width: 50 },
    {
      field: "user_name",
      headerName: "Bridge User",
      width: 130,
      resizable: true,
      flex: 2,
    },
    {
      field: "before_previous_month_name",
      headerName: (
        <div
          style={{
            lineHeight: "23px",
            minWidth: "140px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {new Date(before_previous_month).toLocaleDateString("en-US", {
              month: "short",
              year: "numeric",
            })}
            {/* {before_previous_month_name} */}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>{"FRT"}</span>&nbsp;&nbsp;
            <span>{"Count"}</span>
          </div>
        </div>
      ),
      flex: 2,
      width: 180,
      resizable: true,
      renderCell: (params) => (
        <div
          style={{
            width: "150px",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <span style={{ width: "30%" }}>
            {params.value.frt_value
              ? HoursAndMinutes(params.value.frt_value)
              : "00:00:00"}
          </span>

          <span style={{ width: "30%" }}>
            {params.value.count
              ? prefixZero_for_singleDigit(params.value.count)
              : "00"}
          </span>
        </div>
      ),
    },
    {
      field: "previous_month_name",
      headerName: (
        <div
          style={{
            lineHeight: "23px",
            minWidth: "140px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {new Date(previous_month).toLocaleDateString("en-US", {
              month: "short",
              year: "numeric",
            })}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>{"FRT"}</span>&nbsp;&nbsp;
            <span>{"Count"}</span>
          </div>
        </div>
      ),
      flex: 2,
      width: 180,
      resizable: true,
      renderCell: (params) => (
        <div
          style={{
            width: "150px",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <span style={{ width: "30%" }}>
            {params.value.frt_value
              ? HoursAndMinutes(params.value.frt_value)
              : "00:00:00"}
          </span>
          <span style={{ width: "30%" }}>
            {params.value.count
              ? prefixZero_for_singleDigit(params.value.count)
              : "00"}
          </span>
        </div>
      ),
    },
    {
      field: "current_month_name",
      headerName: (
        <div
          style={{
            lineHeight: "23px",
            minWidth: "140px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {new Date(current_month).toLocaleDateString("en-US", {
              month: "short",
              year: "numeric",
            })}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>{"FRT"}</span>&nbsp;&nbsp;
            <span>{"Count"}</span>
          </div>
        </div>
      ),
      flex: 2,
      width: 180,
      resizable: true,
      renderCell: (params) => (
        <div
          style={{
            width: "150px",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <span style={{ width: "30%" }}>
            {params.value?.frt_value
              ? HoursAndMinutes(params.value.frt_value)
              : "00:00:00"}
          </span>
          <span style={{ width: "30%" }}>
            {params.value?.count
              ? prefixZero_for_singleDigit(params.value?.count)
              : "00"}
          </span>
        </div>
      ),
    },

    {
      field: "total_count",
      headerName: (
        <div
          style={{
            lineHeight: "23px",
            minWidth: "140px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {"Avg FRT"}
          </div>
        </div>
      ),
      flex: 2,
      width: 180,
      resizable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div
          style={{
            // width: "150px",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <span>
            {params.value?.frt_value
              ? HoursAndMinutes(params.value.frt_value)
              : "00:00:00"}
          </span>
        </div>
      ),
    }
  );

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  const totalRows = updatedRows && updatedRows.length;
  const paginationSize = 10;
  const totalPages = Math.ceil(totalRows && totalRows / paginationSize);

  const [currentPage, setCurrentPage] = useState(0);

  const handleChange = (event, value) => {
    setCurrentPage(value - 1);
  };
  const startIndex = currentPage * paginationSize;
  const endIndex = startIndex + paginationSize;

  const finalRows = updatedRows && updatedRows.slice(startIndex, endIndex);
  // const summaryBoxRef = useRef(null);

  // let dataGridHeight = useRef(null);

  // useEffect(() => {
  //   const calculateHeights = () => {
  //     const outerBoxHeight = summaryBoxRef.current.clientHeight;

  //     if (
  //       summaryBoxRef.current &&
  //       summaryBoxRef.current.children[1] &&
  //       summaryBoxRef.current.children[0]
  //     ) {
  //       summaryBoxRef.current.children[0].style.height = 50;

  //       dataGridHeight.current = outerBoxHeight - 75;
  //     }
  //   };

  //   calculateHeights();

  //   // Use ResizeObserver to listen for changes in the size of summaryBoxRef
  //   const resizeObserver = new ResizeObserver(calculateHeights);
  //   resizeObserver.observe(summaryBoxRef.current);

  //   // Cleanup ResizeObserver on component unmount
  //   return () => {
  //     resizeObserver.disconnect();
  //   };
  // }, []); // Empty dependency array to ensure the effect runs only once on mount

  // const [renderKey, setRenderKey] = useState(0);

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      updatedRows?.map((item) => {
        exportData?.push({
          ["Bridge User"]: item?.user_name,
          [`${new Date(before_previous_month).toLocaleDateString("en-US", {
            month: "short",
            year: "numeric",
          })} FRT | Count`]: `${HoursAndMinutes(
            item?.before_previous_month_name?.frt_value
          )} | ${prefixZero_for_singleDigit(
            item?.before_previous_month_name?.count
          )}`,

          [`${new Date(previous_month).toLocaleDateString("en-US", {
            month: "short",
            year: "numeric",
          })} FRT | Count`]: `${HoursAndMinutes(
            item?.previous_month_name?.frt_value
          )} | ${prefixZero_for_singleDigit(item?.previous_month_name?.count)}`,

          [`${new Date(current_month).toLocaleDateString("en-US", {
            month: "short",
            year: "numeric",
          })} FRT | Count`]: `${HoursAndMinutes(
            item?.current_month_name?.frt_value
          )} | ${prefixZero_for_singleDigit(item?.current_month_name?.count)}`,

          ["Avg FRT"]: HoursAndMinutes(item?.total_count?.frt_value),
        });
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, updatedRows]);

  return props.dataGridHeight ? (
    // <Box
    //   style={{
    //     width: "100%",
    //     height: "100%",
    //   }}
    // >
    <LoadingIndicator
      isActive={loading}
      // style={{
      //   height: "100%",
      // }}
    >
      {/* <Logo leftLogo={props.leftLogo} rightLogo={props.rightLogo} /> */}

      {/* <Box
          ref={summaryBoxRef}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flex: "12",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          > */}
      {/* <Typography
              style={{
                fontSize: theme.reports.headerSize,
                padding: theme.reports.headerPadding,
              }}
            > */}
      {/* {firstLetterOrange(props.report && props.report)} */}
      {/*  </Typography> */}

      {/* <hr className="p-0 m-0 " />
          </Box> */}
      <Box height={"100%"}>
        {!loading ? (
          <Box height={"100%"}>
            {apiError ? (
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  flex: "12",
                }}
              >
                {apiError}
              </Box>
            ) : !loading &&
              updatedRows.length > 0 &&
              props.dataGridHeight.current ? (
              <Box
                sx={{
                  padding: "12px",
                  height: "100%",
                }}
              >
                <Grid container lg={12} md={12} sm={12}>
                  <DataGridFunction
                    rows={updatedRows}
                    columns={columns}
                    rowBorder={false}
                    height={props.dataGridHeight.current}
                    rowHeight={50}
                    headerHeight={80}
                    onCellClick={onCellClick}
                    rowColor={true}
                    headerColor={true}
                  />
                </Grid>
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}

        {window.location.pathname === "/ticketStatsReport" && (
          <Description
            description={props.description}
            callBack={props.callBack}
            report={props.report}
            index={0}
          />
        )}
      </Box>
    </LoadingIndicator>
  ) : null;
}

export default FRTEfficiencyAgentWise;
