import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import "./WelcomePage.css";
import { useLocation, useNavigate } from "react-router";
import jwtDecode from "jwt-decode";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import UserLastWorkingProjectAction from "../../ormTicketing/stores/userLastWorkingProject/UserLastWorkingProjectAction";
import { connect } from "react-redux";
import { replace } from "lodash";
import ProjectAction from "../../ormTicketing/stores/project/projectAction";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";

import SetUserAction from "../../stores/setUser/SetUserAction";

import PagePermissionAction from "../../stores/pagePermission/PagePermissionAction";

// mapState to props
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    UserLastWorkingProjectAction.REQUEST_LAST_WORKING_PROJECT_BY_ID,
    ProjectAction.REQUEST_PROJECT,
    UserLastWorkingProjectAction.REQUEST_POST_LAST_WORKING_PROJECT,
  ]),
  lastWorkingProject:
    state.userLastWorkingProjectById.userLastWorkingProjectById || {},
  projectfilter: state.projectfilter.projectfilter || [],
});

function WelcomePage(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchProject = () => {
    const params = {
      page_limit: "none",
    };

    props.dispatch(ProjectAction.requestProjectFilter(params));
  };

  function fetchLastWorkingProject(id) {
    props.dispatch(
      UserLastWorkingProjectAction.requestLastWorkingProjectById(id)
    );
  }

  useEffect(() => {
    fetchProject();
    // let ID = localStorage.getItem("userId");
    // fetchLastWorkingProject(ID);
  }, []);

  const ORMNavigationFunction = (module) => {
    let ID = localStorage.getItem("userId");
    fetchLastWorkingProject(ID);

    let project_object = [];
    if (props.projectfilter) {
      props.projectfilter.hits &&
        props.projectfilter.hits.hits &&
        props.projectfilter.hits.hits.length &&
        props.projectfilter.hits.hits.length === 1 &&
        props.projectfilter.hits.hits.map((item) => {
          project_object.push({
            project_id: item._id,
            project_name: item._source.project_name,
            organization_id: item._source?.organization_id?.organization_id,
          });
        });
    }

    let obj = {
      project: project_object.length > 0 && project_object[0],
      user_id: localStorage.getItem("userId") && localStorage.getItem("userId"),
    };

    if (props.projectfilter && props.projectfilter.hits) {
      console.log("Project_filter", props.projectfilter, location);
      if (
        props?.projectfilter?.hits?.hits?.length &&
        props.projectfilter.hits.hits.length === 1
      ) {
        if (props.lastWorkingProject && props.lastWorkingProject._source) {
          navigate("/dashboard", { state: { from: location.pathname } });
          replace = true;
          props.onClose();
        } else {
          props
            .dispatch(
              UserLastWorkingProjectAction.requestPostLastWorkingProject(
                JSON.stringify(obj)
              )
            )
            .then(() => {
              localStorage.setItem(
                "user_last_working_project",
                JSON.stringify(obj.project)
              );
              let param = {
                page_limit: "none",
              };

              props
                .dispatch(PagePermissionAction.requestPagePermission(param))
                .then(() => {
                  navigate("/dashboard", {
                    state: { from: location.pathname },
                  });
                  replace = true;
                  props.onClose();
                });
            });
        }
      } else if (props.projectfilter.hits.hits.length === 0) {
        navigate("/projectSelect", { state: { from: location.pathname } });
        replace = true;
        props.onClose();
      } else {
        if (module === "social") {
          navigate("/social_listen_dashboard", {
            state: { from: location.pathname },
          });
        } else if (module === "response") {
          navigate("/projectSelect", { state: { from: location.pathname } });
        } else {
        }
        replace = true;
        props.onClose();
      }
    }
  };

  const SettingsNavigationFunction = () => {
    let project_object = [];
    if (props.projectfilter) {
      props.projectfilter.hits &&
        props.projectfilter.hits.hits.length === 1 &&
        props.projectfilter.hits.hits.map((item) => {
          project_object.push({
            project_id: item._id,
            project_name: item._source.project_name,
          });
        });
    }
    let obj = {
      project: project_object.length > 0 && project_object[0],
      user_id: localStorage.getItem("userId") && localStorage.getItem("userId"),
    };
    if (props.projectfilter) {
      if (
        props.projectfilter.hits &&
        props.projectfilter.hits.hits.length === 1
      ) {
        if (props.lastWorkingProject && props.lastWorkingProject._source) {
          navigate("/dashboard", { state: { from: location.pathname } });
          replace = true;
          props.onClose();
        } else {
          props
            .dispatch(
              UserLastWorkingProjectAction.requestPostLastWorkingProject(
                JSON.stringify(obj)
              )
            )
            .then(() => {
              localStorage.setItem(
                "user_last_working_project",
                JSON.stringify(obj.project)
              );
              navigate("/dashboard", { state: { from: location.pathname } });
              replace = true;
              props.onClose();
            });
        }
      } else {
        navigate("/projectSelect", {
          state: { from: location.pathname, divert: "settings" },
        });
        const auth = localStorage.getItem("Auth");
        const authData = jwtDecode(auth);
        replace = true;
        props.onClose();
      }
    }
  };

  return (
    <Box height={"100%"}>
      <Grid
        sx={{
          backgroundColor: theme.palette.background.paper,
        }}
        fontFamily={theme.typography.fontFamily}
        width={"100%"}
        // minHeight={"100vh"}
        height={"100%"}
        textAlign="center"
        item
        lg={12}
        laptop={12}
        md={12}
        sm={12}
        // className="bg-warning"
      >
        <LoadingIndicator isActive={props.isRequesting}>
          <Grid item lg={12} mt={"4%"}>
            <Typography
              component={"span"}
              style={{ fontSize: "36px", color: "#777879" }}
            >
              Welcome to
            </Typography>
          </Grid>{" "}
          <Grid
            alignItems={"center"}
            sx={{
              textAlign: "center",
              position: "relative",
              marginY: 2,
            }}
            height="147px"
          >
            <Grid
              component={"img"}
              alt="WelcomeScreenImage"
              className="logo"
              height="147px"
              sx={{
                marginLeft: -19,
              }}
            />
          </Grid>{" "}
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-around"}
            container
            item
            mt={4}
            lg={12}
            md={12}
            sm={12}
          >
            <Grid container item lg={4} md={4} sm={4}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                justifyContent={"center"}
                display="flex"
              >
                <Tooltip title="Not Enabled">
                  <Grid className="disabledContainer">
                    <Grid className="brand_right" />
                  </Grid>
                </Tooltip>
              </Grid>{" "}
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                p={2}
                display={"flex"}
                justifyContent={"center"}
                alignSelf={"center"}
              >
                <Typography
                  className="title"
                  fontSize={theme.typography.subtitle1.fontSize}
                  color={theme.palette.primary.main}
                >
                  Brand Rights
                </Typography>
                &nbsp;&nbsp;
                <Typography
                  className="title"
                  fontSize={theme.typography.subtitle1.fontSize}
                  color={theme.palette.primary.main}
                >
                  Enforcement
                </Typography>
              </Grid>
            </Grid>{" "}
            <Grid
              container
              item
              lg={4}
              md={4}
              sm={4}
              onClick={() => {
                location.state === null && ORMNavigationFunction("social");
              }}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                justifyContent={"center"}
                display="flex"
              >
                <Tooltip title={location.state === null ? "" : "Not Enabled"}>
                  <Grid
                    className={
                      location.state === null
                        ? "imageContainer"
                        : "disabledContainer"
                    }
                    sx={{
                      cursor:
                        location.state === null ? "pointer" : "not-allowed",
                    }}
                  >
                    <Grid className="social" />
                  </Grid>
                </Tooltip>
              </Grid>{" "}
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                p={2}
                display={"flex"}
                justifyContent={"center"}
                alignSelf={"center"}
              >
                <Typography
                  className="title"
                  fontSize={theme.typography.subtitle1.fontSize}
                  color={theme.palette.primary.main}
                >
                  Social
                </Typography>
                &nbsp;&nbsp;
                <Typography
                  className="title"
                  fontSize={theme.typography.subtitle1.fontSize}
                  color={theme.palette.primary.main}
                >
                  Listening
                </Typography>
              </Grid>
            </Grid>{" "}
            <Grid
              container
              item
              lg={4}
              md={4}
              sm={4}
              onClick={() => {
                ORMNavigationFunction("response");
              }}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                justifyContent={"center"}
                display="flex"
              >
                <Grid
                  className="imageContainer"
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <Grid className="response" />
                </Grid>
              </Grid>{" "}
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                p={2}
                display={"flex"}
                justifyContent={"center"}
                alignSelf={"center"}
              >
                <Typography
                  className="title"
                  fontSize={theme.typography.subtitle1.fontSize}
                  color={theme.palette.primary.main}
                >
                  Response
                </Typography>
                &nbsp;&nbsp;
                <Typography
                  className="title"
                  fontSize={theme.typography.subtitle1.fontSize}
                  color={theme.palette.primary.main}
                >
                  Management
                </Typography>
              </Grid>
            </Grid>{" "}
            <Grid
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-around"}
              container
              item
              mt={6}
              lg={12}
              md={12}
              sm={12}
            >
              <Grid container item lg={4} md={4} sm={4}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  justifyContent={"center"}
                  display="flex"
                >
                  <Tooltip title="Not Enabled">
                    <Grid className="disabledContainer">
                      <Grid className="review" />
                    </Grid>
                  </Tooltip>
                </Grid>{" "}
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  p={2}
                  display={"flex"}
                  justifyContent={"center"}
                  alignSelf={"center"}
                >
                  <Typography
                    className="title"
                    fontSize={theme.typography.subtitle1.fontSize}
                    color={theme.palette.primary.main}
                  >
                    Review
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography
                    className="title"
                    fontSize={theme.typography.subtitle1.fontSize}
                    color={theme.palette.primary.main}
                  >
                    Management
                  </Typography>
                </Grid>
              </Grid>{" "}
              <Grid container item lg={4} md={4} sm={4}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  justifyContent={"center"}
                  display="flex"
                >
                  <Tooltip title="Not Enabled">
                    <Grid className="disabledContainer">
                      <Grid className="customer_experience" />
                    </Grid>
                  </Tooltip>
                </Grid>{" "}
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  p={2}
                  display={"flex"}
                  justifyContent={"center"}
                  alignSelf={"center"}
                >
                  <Typography
                    className="title"
                    fontSize={theme.typography.subtitle1.fontSize}
                    color={theme.palette.primary.main}
                  >
                    Customer
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography
                    className="title"
                    fontSize={theme.typography.subtitle1.fontSize}
                    color={theme.palette.primary.main}
                  >
                    Experience
                  </Typography>
                </Grid>
              </Grid>{" "}
              <Grid container item lg={4} md={4} sm={4}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  justifyContent={"center"}
                  display="flex"
                >
                  <Tooltip title="Not Enabled">
                    <Grid className="disabledContainer" onClick={() => {}}>
                      <Grid className="settings" />
                    </Grid>
                  </Tooltip>
                </Grid>{" "}
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  p={2}
                  display={"flex"}
                  justifyContent={"center"}
                  alignSelf={"center"}
                >
                  <Typography
                    className="title"
                    fontSize={theme.typography.subtitle1.fontSize}
                    color={theme.palette.primary.main}
                  >
                    Influencer
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography
                    className="title"
                    fontSize={theme.typography.subtitle1.fontSize}
                    color={theme.palette.primary.main}
                  >
                    Activities
                  </Typography>
                </Grid>
              </Grid>{" "}
            </Grid>
          </Grid>{" "}
        </LoadingIndicator>
      </Grid>
    </Box>
  );
}
export default connect(mapStateToProps)(WelcomePage);
