import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import RevenueThresoldModel from "./model/RevenueThresoldModel";

// RevenueThresoldEffect
export default class RevenueThresoldEffect {
  static async requestRevenueThresold(params, callBack) {
    const endpoint = environment.api.revenueThresoldBalance;

    let response = EffectUtility.getToModel(
      RevenueThresoldModel,
      endpoint,
      params
    );
    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }
}
