import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { format } from "date-fns";
import { theme } from "../../../../views/App";
import { endDate, startDate } from "../dateFunctions/DateFunctions";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import { Line } from "react-chartjs-2";
import Logo from "../logoContainer/Logo";
import { axiosRequest } from "../../../../utilities/FetchRequest";
import { NivoBarChart } from "../../../../components/nivoCharts/NivoBarChart";
import LoadingBgImage from "../../../../components/loadingBgImage/LoadingBgImage";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../../components/ticketPartials";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";

const SentimentAnalysisLast12monthsChart = (props) => {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  let current_month = new Date();

  let current_month_end_date = endDate(current_month);

  //  before_twelvth_month
  let before_twelvth_month = new Date();
  before_twelvth_month.setMonth(before_twelvth_month.getMonth() - 11);

  let before_twelvth_month_start_date = startDate(before_twelvth_month);
  const [today, setToday] = useState(new Date());

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);

      fetchData(before_twelvth_month_start_date, current_month_end_date);
    })();
  }, []);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);

        fetchData(before_twelvth_month_start_date, current_month_end_date);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  let rows = [];

  useEffect(() => {
    if (props.filterClicked) {
      // Assuming props.openDateFilter is provided and valid
      const date = new Date(props.openDateFilter);
      setToday(new Date(props.openDateFilter));

      const firstDateOfLastMonth = new Date(
        date.getFullYear(),
        date.getMonth() - 11,
        1
      );
      const firstDateString = format(firstDateOfLastMonth, "yyyy-MM-dd");

      const endOfCurrentMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      );
      const endDateString = format(endOfCurrentMonth, "yyyy-MM-dd");

      (async function CallApi() {
        setLoading(true);
        await fetchData(new Date(firstDateString), new Date(endDateString));
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  function returnNoOfDays(month, year) {
    return new Date(year, month, 0).getDate();
  }

  const [renderKey, setRenderKey] = useState(0);

  let responseData = [];

  async function fetchData(from, to) {
    let API = `${
      environment.api.sentimentalAnalysisReports
    }?report_type=["count"]&reports=["overall_sentiment_value_across_platform"]&from=${format(
      from,
      "yyyy-MM-dd"
    )}&to=${format(to, "yyyy-MM-dd")}`;
    try {
      const response = await axiosRequest(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      const data = await response;
      if (data.hasOwnProperty("message")) {
        setData(null);
        setApiError(data.message);
      } else {
        responseData.push(data);
        setData([...responseData]);
        setRenderKey(Math.random());
        setApiError(null);
      }

      setLoading(false);
    } catch (err) {
      setData(null);
      setApiError(err.message);
      setLoading(false);
    }
  }

  const dateWiseData =
    data &&
    data[0] &&
    data[0]?.sentiment_analysis_report?.overall_sentiment_value_across_platform
      ?.date_wise;

  const mappedData =
    dateWiseData &&
    dateWiseData.length !== 0 &&
    dateWiseData.map((date) => {
      return {
        date: date[0],
        values: date[1],
      };
    });

  if (mappedData && mappedData.length !== 0) {
    mappedData.map((item, index) => {
      rows.push({
        id: index + 1,
        months: convertToDateMonthYear(item.date),
        positive: item.values.filter((val) => {
          return val.key === "positive_sentiment";
        })[0].count,
        negative: item.values.filter((val) => {
          return val.key === "negative_sentiment";
        })[0].count,
        neutral: item.values.filter((val) => {
          return val.key === "neutral_sentiment";
        })[0].count,
        total: prefixZero_for_singleDigit(
          item.values.filter((val) => {
            return val.key === "total_count";
          })[0].count
        ),
      });
    });

    rows.push({
      id: mappedData.length + 1,
      months: "Total",
      positive: prefixZero_for_singleDigit(
        data &&
          data[0] &&
          data[0].sentiment_analysis_report
            .overall_sentiment_value_across_platform.total_positive
      ),
      negative: prefixZero_for_singleDigit(
        data &&
          data[0] &&
          data[0].sentiment_analysis_report
            .overall_sentiment_value_across_platform.total_negative
      ),
      neutral: prefixZero_for_singleDigit(
        data &&
          data[0] &&
          data[0].sentiment_analysis_report
            .overall_sentiment_value_across_platform.total_neutral
      ),
      total: prefixZero_for_singleDigit(
        data &&
          data[0] &&
          data[0].sentiment_analysis_report
            .overall_sentiment_value_across_platform.total_mentions
      ),
    });
  }

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      rows?.map((item) => {
        exportData?.push({
          Months: item?.months,
          Positive: prefixZero_for_singleDigit(item?.positive),
          Negative: prefixZero_for_singleDigit(item.negative),
          Neutral: prefixZero_for_singleDigit(item.neutral),
          Total: prefixZero_for_singleDigit(item.total),
        });
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, rows]);

  function convertToDateMonthYear(dateStr) {
    const dateParts = dateStr.split("-");

    const year = parseInt(dateParts[2]);
    const month = parseInt(dateParts[1]);

    const monthYearStr = new Date(year, month - 1).toLocaleString("en-US", {
      month: "short",
      year: "numeric",
    });

    return monthYearStr;
  }

  let months = [];
  let total_count = [];
  let positive_count = [];
  let negetive_count = [];
  let neutral_count = [];

  mappedData?.map((item) => {
    const dateParts = item.date.split("-");

    const year = parseInt(dateParts[2]);
    const month = parseInt(dateParts[1]);

    const monthYearStr = new Date(year, month - 1).toLocaleString("en-US", {
      month: "short",
      year: "numeric",
    });

    // Extract the last two characters of the year string
    const lastTwoDigitsOfYear = monthYearStr.slice(-2);

    const shortMonthYearStr =
      monthYearStr.slice(0, 3) + " " + lastTwoDigitsOfYear;

    months.push([shortMonthYearStr]);
  });

  total_count = mappedData?.map(
    (item) => item.values.find((v) => v.key === "total_count").count
  );

  positive_count = mappedData?.map(
    (item) => item.values.find((v) => v.key === "positive_sentiment").count
  );

  negetive_count = mappedData?.map(
    (item) => item.values.find((v) => v.key === "negative_sentiment").count
  );

  neutral_count = mappedData?.map(
    (item) => item.values.find((v) => v.key === "neutral_sentiment").count
  );

  let colors = [
    `${theme.reports.positive}`,
    `${theme.reports.neutral}`,
    `${theme.reports.negative}`,
    `${theme.reports.total}`,
    `${theme.reports.positive}`,
    `${theme.reports.neutral}`,
    `${theme.reports.negative}`,
    `${theme.reports.total}`,
  ];

  const state = {
    labels: months,
    datasets: [
      {
        type: "bar",
        labels: "Bar Chart",
        borderColor: Array.from(
          { length: total_count?.length },
          (_, index) => colors[index % colors.length]
        ),
        borderRadius: 10,
        backgroundColor: Array.from(
          { length: total_count?.length },
          (_, index) => colors[index % colors.length]
        ),

        data: total_count,
        // borderWidth: 2,
        // borderRadius: 5,
      },
    ],
  };

  let MaxValue = total_count && Math.max(...total_count);

  function getDigit(value) {
    let digit = 1;

    for (let i = 0; value >= 10; i++) {
      value /= 10;
      digit *= 10;
    }

    return digit;
  }

  const digit = getDigit(MaxValue);
  let num = parseInt(MaxValue / digit);

  let updatedDigit = num * digit;

  let stepSize = updatedDigit / 10;

  const remainder = MaxValue % stepSize;

  const difference = stepSize - remainder;

  MaxValue += difference;

  const chartData = months.map((month, index) => ({
    months: month[0],
    "Positive(+ve)": positive_count[index],
    "Negative(-ve)": negetive_count[index],
    "Neutral(∅)": neutral_count[index],
    Total: total_count[index],
  }));

  const getBarOption = () => {
    const formatValue = (value) => {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + "K";
      }
      return value;
    };
    let shouldShowLegend = false;
    if (
      (props.ChartContainerHeight?.current &&
        props.ChartContainerWidth?.current &&
        props.ChartContainerHeight.current >= 350 &&
        props.ChartContainerWidth.current > 350) ||
      (props.ChartContainerHeight?.current === undefined &&
        props.ChartContainerWidth?.current === undefined)
    ) {
      shouldShowLegend = true;
    }
    // Extracting the x-axis categories (months) and series data
    const months = chartData.map((entry) => entry.months);
    const positiveData = chartData.map((entry) => entry["Positive(+ve)"]);
    const negativeData = chartData.map((entry) => entry["Negative(-ve)"]);
    const neutralData = chartData.map((entry) => entry["Neutral(∅)"]);
    const totalData = chartData.map((entry) => entry["Total"]);

    return {
      tooltip: {
        trigger: "axis",
        confine: true,
        formatter: (params) => {
          let tooltipContent = params
            .map((param, index) => {
              let val = param.value;
              let formattedValue = formatValue(val);
              const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${param.color};border-radius:50%;"></span>`;
              return `${colorSpan}${param.seriesName}: ${formattedValue}`;
            })
            .join("<br>");

          return tooltipContent;
        },

        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        icon: "circle",
        type: "scroll",
        data: shouldShowLegend
          ? ["Positive", "Negative", "Neutral", "Total"]
          : [],
      },
      grid: {
        top: props.ChartContainerHeight?.current >= 500 ? "5%" : "10%",
        left: "5%",
        right: "3%",
        bottom: "5%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: months,
        name:
          props.detailedReport || props.ChartContainerHeight?.current > 300
            ? "Month"
            : "",
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: 25,
        },
        axisLabel: {
          hideOverlap: true,
        },
      },
      yAxis: {
        type: "value",
        // name: "Count",
        name:
          props.detailedReport || props.ChartContainerWidth?.current > 600
            ? "Count"
            : "",
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: -35,
        },
        nameGap: 30,
        axisLabel: {
          formatter: (value) => {
            let formattedValue = formatValue(value);
            return formattedValue;
          },
          hideOverlap: true,
        },
      },
      dataZoom: [
        {
          type: "inside",
          filterMode: "weakFilter",
        },
      ],
      series: [
        {
          name: "Positive",
          type: "bar",
          stack: "total",
          barWidth: "60%",
          data: positiveData,
          itemStyle: {
            color: `${theme.reports.positive}`,
          },
        },
        {
          name: "Negative",
          type: "bar",
          stack: "total",
          barWidth: "60%",
          data: negativeData,
          itemStyle: {
            color: `${theme.reports.negative}`,
          },
        },
        {
          name: "Neutral",
          type: "bar",
          stack: "total",
          barWidth: "60%",
          data: neutralData,
          itemStyle: {
            color: `${theme.reports.neutral}`,
          },
        },
      ],
      labelLayout: {
        hideOverlap: true,
      },
    };
  };

  return (
    <LoadingIndicator isActive={loading}>
      <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flex: "12",
        }}
      >
        {apiError ? (
          <Box
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              flex: "12",
            }}
          >
            {apiError}
          </Box>
        ) : !loading ? (
          total_count &&
          (total_count?.reduce((acc, currentValue) => acc + currentValue, 0) ? (
            <ReactEcharts
              //   option={getAreaOption()}
              option={getBarOption()}
              style={{ height: "100%", width: "100%" }}
              notMerge={true}
              lazyUpdate={true}
              theme={"theme_name"}
            />
          ) : (
            <NoDataFound />
          ))
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage width={"50%"} />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  );
};
export default SentimentAnalysisLast12monthsChart;
