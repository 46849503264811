import { Box, Grid, Tabs, Tab } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { format } from "date-fns";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";

import { theme } from "../../../views/App";
import DatePicker from "../../../components/dateRangePickerComponent/DateRangePicker";
import MuiButton from "../../../components/muiButton/MuiButton";
import MuiSnackbarAlert from "../../../components/muiSnackbarAlert/MuiSnackbarAlert";
import AutoresponseLogs from "./AutoresponseLogs";
import PlatformAction from "../../stores/platform/PlatformAction";
import NotificationLogs from "./NotificationLogs";
import MailLogs from "./MailLogs";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [PlatformAction.REQUEST_PLATFORM]),
  platform: state.platform.platform || [],
});

const AutomationLogs = (props) => {
  const subTabsUI = {
    fontWeight: "bold",
    textTransform: "none",
    fontSize: "16px",
    "&.Mui-selected": {
      boxShadow: "0px 0px 20px 4px rgba(0, 0, 0, 0.6)",
      backgroundColor: theme.palette.background.main,
      color: theme.palette.background.light,
    },
    "&:hover": {
      "& .MuiSvgIcon-root": {
        transition: "transform 0.9s",
        transform: "scale(1.3)",
      },
    },
  };

  const [page_limit] = useState(15);
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [filterLoader, setFilterLoader] = useState(false);
  const [filterClicked, setfilterClicked] = useState(false);
  const [clearClicked, setClearClicked] = useState(false);

  const [clearLoader, setClearLoader] = useState(false);
  const [noFiltersAlert, setNoFiltersAlert] = useState(false);
  const [date, setDate] = useState([
    format(new Date(), "yyyy-MM-dd"),
    format(new Date(), "yyyy-MM-dd"),
  ]);

  function fetchPlatform() {
    let param = {
      page_limit: "none",
      order_by: `[["platform_name.keyword","asc"]]`,
    };

    props.dispatch(PlatformAction.requestPlatform(param));
  }

  useEffect(() => {
    fetchPlatform();
  }, []);

  const boxRef = useRef(null);
  const filterBoxRef = useRef(null);

  function dateHandleChange(date) {
    setDate(date);
  }

  const applyFilter = () => {
    setfilterClicked(true);
  };

  const ClearState = () => {
    setClearClicked(true);
    setDate([
      format(new Date(), "yyyy-MM-dd"),
      format(new Date(), "yyyy-MM-dd"),
    ]);
  };

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  //activities list Assigning
  let activitiesList = [];
  const activitiesData = JSON.parse(localStorage.getItem("activities"));
  if (activitiesData !== null) {
    if (activitiesData.hits.hits) {
      activitiesData.hits.hits.map((item) => {
        activitiesList.push({
          activity_id: item._id,
          activity_name: item._source.activities,
        });
      });
    }
  }

  let platform_list = [];
  if (props.platform.hits) {
    props.platform.hits.hits &&
      props.platform.hits.hits.length > 0 &&
      props.platform.hits.hits.map((item) => {
        platform_list.push({
          platform_id: item._id,
          platform_name: item._source.platform_name,
          platform_alias: item._source.alias,
        });
      });
  }

  return (
    <React.Fragment>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @keyframes popIn {
            0% {
              opacity: 0;
              transform: scale(0.8);
              box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
            }
            50% {
              transform: scale(1.05);
              box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
            }
            100% {
              opacity: 1;
              transform: scale(1);
            }
          }
          @keyframes slideIn {
            0% {
              opacity: 0;
              transform: translateY(100%);
            }
            100% {
              opacity: 1;
              transform: translateY(0);
            }
          }
    `,
        }}
      />
      <Box ref={boxRef} paddingX={1}>
        <Box
          ref={filterBoxRef}
          alignItems={"center"}
          justifyContent="space-between"
          style={{
            position: "sticky",
            top: `${boxRef?.current?.offsetTop}px`,
            backgroundColor: theme.palette.background.default,
            zIndex: 10,
          }}
          mb={1}
        >
          {/* <Grid item container alignItems={"center"} justifyContent={"center"}>
            <Grid
              container
              item
              alignItems={"center"}
              justifyContent={"space-between"}
              xs={12}
            >
              <Grid
                item
                md={activeTab === 3 && open ? 3.5 : 6.5}
                sm={12}
                mt={1}
              ></Grid>
            </Grid>
          </Grid> */}

          <Box
          // margin={"auto"}
          >
            <Grid
              item
              container
              alignItems={"center"}
              justifyContent={"space-between"}
              mt={0.5}
              // height={"40px"}
              sx={{
                backgroundColor: `${theme.palette.background.default}`,
                zIndex: 999,
                position: "relative",
                boxShadow: `${theme.shadows}`,
              }}
            >
              <Grid
                item
                container
                xs={6}
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <Tabs
                  value={activeTab}
                  // scrollButtons
                  allowScrollButtonsMobile
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                  // centered
                  sx={{
                    "& .MuiTabs-indicator": { display: "none" },

                    "& .MuiTabs-flexContainer": {
                      position: "relative",
                      justifyContent: "flex-start",
                    },
                    "& .MuiTab-root": {
                      position: "relative",
                      zIndex: 1,
                      borderRadius: "0 0 10px 0",
                      color: "#2a5e6c",
                      border: `1px solid ${theme.palette.background.primary}`,
                      backgroundColor: "#d3e2e6",
                      "&.Mui-selected": {
                        zIndex: 2,
                        backgroundColor: "#003a4c",
                        color: "white",
                      },
                    },
                  }}
                >
                  <Tab
                    label="Auto-Response"
                    style={{
                      textTransform: "none",
                      border: `1px solid ${theme.palette.text.primary}`,

                      minHeight: 0, // set the minimum height to 0
                      padding: "10.8px 20px", // adjust the padding to decrease the height
                    }}
                  />
                  <Tab
                    label="Notification"
                    style={{
                      textTransform: "none",
                      border: `1px solid ${theme.palette.text.primary}`,

                      minHeight: 0, // set the minimum height to 0
                      padding: "10.8px 20px", // adjust the padding to decrease the height
                    }}
                  />
                  <Tab
                    label="Mail"
                    style={{
                      textTransform: "none",
                      border: `1px solid ${theme.palette.text.primary}`,

                      minHeight: 0, // set the minimum height to 0
                      padding: "10.8px 20px", // adjust the padding to decrease the height
                    }}
                  />
                </Tabs>
              </Grid>
              <Grid
                item
                container
                // className="bg-warning"
                xs={6}
                justifyContent={"flex-end"}
              >
                <Grid item>
                  <Grid
                    item
                    container
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    // spacing={1}
                    // className="bg-success"
                  >
                    <Grid item>
                      <DatePicker
                        value={date ? date : [null, null]}
                        onChange={dateHandleChange}
                        maxDate={new Date()}
                      />
                    </Grid>
                    <Grid item>
                      <MuiButton
                        name={"Filter"}
                        loading={filterLoader}
                        width={100}
                        onClick={() => {
                          applyFilter();
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <MuiButton
                        name={"Clear"}
                        loading={clearLoader}
                        width={100}
                        onClick={() => {
                          ClearState();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* {!open && ( */}
        <Grid item container alignItems={"center"} justifyContent={"center"}>
          {activeTab === 0 && (
            <Box width={"100%"}>
              <AutoresponseLogs
                date={date}
                filterClicked={filterClicked}
                setfilterClicked={setfilterClicked}
                clearClicked={clearClicked}
                setClearClicked={setClearClicked}
                open={open}
                setOpen={setOpen}
                platform_list={platform_list}
                activitiesList={activitiesList}
              />
            </Box>
          )}
          {activeTab === 1 && (
            <Box width={"100%"}>
              <NotificationLogs
                date={date}
                filterClicked={filterClicked}
                setfilterClicked={setfilterClicked}
                clearClicked={clearClicked}
                setClearClicked={setClearClicked}
                open={open}
                setOpen={setOpen}
                platform_list={platform_list}
                activitiesList={activitiesList}
              />
            </Box>
          )}
          {activeTab === 2 && (
            <Box width={"100%"}>
              <MailLogs
                date={date}
                filterClicked={filterClicked}
                setfilterClicked={setfilterClicked}
                clearClicked={clearClicked}
                setClearClicked={setClearClicked}
                open={open}
                setOpen={setOpen}
                platform_list={platform_list}
                activitiesList={activitiesList}
              />
            </Box>
          )}
        </Grid>
      </Box>

      <MuiSnackbarAlert
        open={noFiltersAlert}
        onClose={() => setNoFiltersAlert(false)}
        message={"No Filters Applied !!"}
      />
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(AutomationLogs);
