import React, { useEffect, useState } from "react";
import environment from "environment";
import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import { format } from "date-fns";
import { endDate, monthNames, startDate } from "./dateFunctions/DateFunctions";
import Logo from "./logoContainer/Logo";
import Description from "./descriptionContainer/Description";
import { Box } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { firstLetterOrange } from "../../../components/ticketPartials";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import NoDataFound from "../../../components/nodatafound/NoDataFound";

function TotalNegativeMentions(props) {
  Chart.register(CategoryScale);
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [loading, setLoading] = useState(false);

  const [current_month_data, set_current_month_data] = useState(null);
  const [previous_month_data, set_previous_month_data] = useState(null);
  const [before_previous_month_data, set_before_previous_month_data] =
    useState(null);
  const [last_3_months_data, set_last_3_months_data] = useState(null);
  const [apiError, setApiError] = useState(null);
  // this month
  let current_month = new Date();
  let current_month_start_date = startDate(current_month);
  let current_month_end_date = endDate(current_month);

  // last month
  let previous_month = new Date();
  previous_month.setMonth(previous_month.getMonth() - 1);

  let previous_month_start_date = startDate(previous_month);
  let previous_month_end_date = endDate(previous_month);

  //  before_previous_month
  let before_previous_month = new Date();
  before_previous_month.setMonth(before_previous_month.getMonth() - 2);

  let before_previous_month_start_date = startDate(before_previous_month);
  let before_previous_month_end_date = endDate(before_previous_month);

  // last three month names
  let current_month_name = monthNames[current_month.getMonth()];
  let previous_month_name = monthNames[previous_month.getMonth()];
  let before_previous_month_name = monthNames[before_previous_month.getMonth()];

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);

      // fetching platform data
      await fetchData(current_month_start_date, current_month_end_date);
      await fetchData(previous_month_start_date, previous_month_end_date);
      await fetchData(
        before_previous_month_start_date,
        before_previous_month_end_date
      );

      await fetchData(before_previous_month_start_date, current_month_end_date);
      setLoading(false);
    })();
  }, []);

  async function fetchData(from, to) {
    let API = `${
      environment.api.sentimentalAnalysisReports
    }?report_type=["count"]&reports=["sentiment_analysis_report_custom_value"]&from=${format(
      from,
      "yyyy-MM-dd"
    )}&to=${format(to, "yyyy-MM-dd")}`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (
          from === current_month_start_date &&
          to === current_month_end_date
        ) {
          if (response.hasOwnProperty("message")) {
            set_current_month_data(null);
            setApiError(response.message);
          } else {
            set_current_month_data(response);
            setApiError(null);
          }
        } else if (
          from === previous_month_start_date &&
          to === previous_month_end_date
        ) {
          if (response.hasOwnProperty("message")) {
            set_previous_month_data(null);
            setApiError(response.message);
          } else {
            set_previous_month_data(response);
            setApiError(null);
          }
        } else if (
          from === before_previous_month_start_date &&
          to === before_previous_month_end_date
        ) {
          if (response.hasOwnProperty("message")) {
            set_before_previous_month_data(null);
            setApiError(response.message);
          } else {
            set_before_previous_month_data(response);
            setApiError(null);
          }
        } else {
          if (response.hasOwnProperty("message")) {
            set_last_3_months_data(null);
            setApiError(response.message);
          } else {
            set_last_3_months_data(response);
            setApiError(null);
          }
        }
      })
      .catch((err) => {
        set_last_3_months_data(null);
        set_before_previous_month_data(null);
        set_previous_month_data(null);
        set_current_month_data(null);
        setApiError(err.message);
        setLoading(false);
      });

    // return fetch(
    //   `${
    //     environment.api.sentimentalAnalysisReports
    //   }?report_type=["count"]&reports=["sentiment_analysis_report_custom_value"]&from=${format(
    //     from,
    //     "yyyy-MM-dd"
    //   )}&to=${format(to, "yyyy-MM-dd")}`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((response) => {
    //     return response;
    //   });
  }

  let selected_report_data_current_month =
    current_month_data &&
    current_month_data.sentiment_analysis_report &&
    current_month_data.sentiment_analysis_report.sentiment_analysis_report_custom_value.filter(
      (item) => {
        return Object.keys(item)[0] === props.filterValue;
      }
    )[0];

  let selected_report_data_previous_month =
    previous_month_data &&
    previous_month_data.sentiment_analysis_report &&
    previous_month_data.sentiment_analysis_report.sentiment_analysis_report_custom_value.filter(
      (item) => {
        return Object.keys(item)[0] === props.filterValue;
      }
    )[0];

  let selected_report_data_before_previous_month =
    before_previous_month_data &&
    before_previous_month_data.sentiment_analysis_report &&
    before_previous_month_data.sentiment_analysis_report.sentiment_analysis_report_custom_value.filter(
      (item) => {
        return Object.keys(item)[0] === props.filterValue;
      }
    )[0];

  let selected_report_data_last3_month =
    last_3_months_data &&
    last_3_months_data.sentiment_analysis_report &&
    last_3_months_data.sentiment_analysis_report.sentiment_analysis_report_custom_value.filter(
      (item) => {
        return Object.keys(item)[0] === props.filterValue;
      }
    )[0];

  let chartList = [];

  selected_report_data_current_month &&
    Object.entries(selected_report_data_current_month).map(([key, value]) => {
      value.map((item) => {
        chartList.push({
          [Object.keys(item)[0]]: [
            {
              current_month_count: item[Object.keys(item)]
                .filter((value) => value.key === "negative_sentiment")
                .map((e) => {
                  return e.count;
                }),
            },
          ],
        });
      });
    });

  selected_report_data_previous_month &&
    Object.entries(selected_report_data_previous_month).map(([key, value]) => {
      value.map((item) => {
        chartList.push({
          [Object.keys(item)[0]]: [
            {
              previous_month_count: item[Object.keys(item)]
                .filter((value) => value.key === "negative_sentiment")
                .map((e) => {
                  return e.count;
                }),
            },
          ],
        });
      });
    });

  selected_report_data_before_previous_month &&
    Object.entries(selected_report_data_before_previous_month).map(
      ([key, value]) => {
        value.map((item) => {
          chartList.push({
            [Object.keys(item)[0]]: [
              {
                before_previous_month_count: item[Object.keys(item)]
                  .filter((value) => value.key === "negative_sentiment")
                  .map((e) => {
                    return e.count;
                  }),
              },
            ],
          });
        });
      }
    );

  selected_report_data_last3_month &&
    Object.entries(selected_report_data_last3_month).map(([key, value]) => {
      value.map((item) => {
        chartList.push({
          [Object.keys(item)[0]]: [
            {
              last3months_count: item[Object.keys(item)]
                .filter((value) => value.key === "negative_sentiment")
                .map((e) => {
                  return e.count;
                }),
            },
          ],
        });
      });
    });

  const result = [];

  chartList &&
    chartList.forEach((el) => {
      let [key, value] = Object.entries(el)[0];
      for (let el of result)
        if (key in el) {
          el[key].push(value);
          return;
        }

      result.push({ [key]: [value] });
    });

  let Total_of_before_previous_month = [];

  result &&
    result.map((value) => {
      value &&
        value[Object.keys(value)].map((item) => {
          item &&
            item.map((value) => {
              if (value.before_previous_month_count) {
                Total_of_before_previous_month.push(
                  value.before_previous_month_count[0]
                );
              }
            });
        });
    });

  let Total_of_previous_month = [];

  result &&
    result.map((value) => {
      value &&
        value[Object.keys(value)].map((item) => {
          item &&
            item.map((value) => {
              if (value.previous_month_count) {
                Total_of_previous_month.push(value.previous_month_count[0]);
              }
            });
        });
    });
  let Total_of_current_month = [];

  result &&
    result.map((value) => {
      value &&
        value[Object.keys(value)].map((item) => {
          item &&
            item.map((value) => {
              if (value.current_month_count) {
                Total_of_current_month.push(value.current_month_count[0]);
              }
            });
        });
    });

  let Total_of_3_months = [];

  result &&
    result.map((value) => {
      value &&
        value[Object.keys(value)].map((item) => {
          item &&
            item.map((value) => {
              if (value.last3months_count) {
                Total_of_3_months.push(value.last3months_count[0]);
              }
            });
        });
    });

  function TotalCount(item) {
    return item.length > 0 && item.reduce((prev, next) => prev + next);
  }

  Total_of_before_previous_month = TotalCount(Total_of_before_previous_month);
  Total_of_previous_month = TotalCount(Total_of_previous_month);
  Total_of_current_month = TotalCount(Total_of_current_month);
  Total_of_3_months = TotalCount(Total_of_3_months);

  let countData = [];
  let nameLabels = [];

  result &&
    result.map((item) => {
      return item[Object.keys(item)].map((value) => {
        return (
          value &&
          value.map((event) => {
            if (event.last3months_count) {
              return countData.push(event.last3months_count[0]);
            }
          })
        );
      });
    });

  let MaxValue = Math.max(...countData);

  let AvgOfMax = MaxValue / 10;
  MaxValue = MaxValue + AvgOfMax;

  AvgOfMax = Math.round(AvgOfMax);

  nameLabels =
    result &&
    result.map((item) => {
      return Object.keys(item)[0];
    });

  const state = {
    labels: nameLabels,
    datasets: [
      {
        type: "bar",
        labels: "Bar Chart",
        borderColor: `${theme.reports.negative}`,
        backgroundColor: `${theme.reports.negative}`,
        data: countData,
        borderWidth: 2,
        borderRadius: 5,
      },
    ],
  };

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <Box
          sx={{
            width: "100%",
            paddingX: 5,
          }}
        >
          {apiError ? (
            <Box
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flex: "12",
              }}
            >
              {apiError}
            </Box>
          ) : (
            <div style={{ width: "100%", overflowX: "auto" }}>
              {chartList.length > 0 ? (
                <table
                  style={{
                    width: "100%",
                    border: `1px solid ${theme.table.border}`,
                    textAlign: "left",
                  }}
                  border="2"
                >
                  <tbody style={{ width: "100%" }}>
                    <tr
                      style={{
                        border: `1px solid ${theme.table.border}`,
                        color: `${theme.palette.text.primary}`,
                        backgroundColor: `${theme.palette.background.primary}`,
                      }}
                    >
                      <th className="pl-3 pr-3">Custom Values</th>
                      <th className="pl-3 pr-3">
                        {before_previous_month_name}
                      </th>
                      <th className="pl-3 pr-3">{previous_month_name}</th>
                      <th className="pl-3 pr-3">{current_month_name}</th>
                      <th className="pl-3 pr-3">Total</th>
                      <th className="pl-3 pr-3">
                        Issues Breakup {"%"} ({current_month_name})
                      </th>
                    </tr>
                    {result &&
                      result.map((value, index) => {
                        return (
                          <tr
                            style={{
                              backgroundColor:
                                index % 2 === 0
                                  ? `${theme.table.rowColor}`
                                  : "white",
                              color: `${theme.table.rowText}`,
                              border: `1px solid ${theme.table.border}`,
                            }}
                          >
                            <td className="pl-3">
                              {Object.keys(value)[0]
                                ? Object.keys(value)[0]
                                : "-"}
                            </td>
                            <td className="pl-3">
                              {value[Object.keys(value)].map((item) => {
                                return (
                                  item &&
                                  item.map((value) => {
                                    if (value.before_previous_month_count) {
                                      return (
                                        <>{value.before_previous_month_count}</>
                                      );
                                    }
                                  })
                                );
                              })}
                            </td>
                            <td className="pl-3">
                              {value[Object.keys(value)].map((item) => {
                                return (
                                  item &&
                                  item.map((value) => {
                                    if (value.previous_month_count) {
                                      return <>{value.previous_month_count}</>;
                                    }
                                  })
                                );
                              })}
                            </td>
                            <td className="pl-3">
                              {value[Object.keys(value)].map((item) => {
                                return (
                                  item &&
                                  item.map((value) => {
                                    if (value.current_month_count) {
                                      return <>{value.current_month_count}</>;
                                    }
                                  })
                                );
                              })}
                            </td>
                            <td className="pl-3">
                              {value[Object.keys(value)].map((item) => {
                                return (
                                  item &&
                                  item.map((value) => {
                                    if (value.last3months_count) {
                                      return <>{value.last3months_count}</>;
                                    }
                                  })
                                );
                              })}
                            </td>

                            <td className="pl-3">
                              {value[Object.keys(value)].map((item) => {
                                return (
                                  item &&
                                  item.map((value) => {
                                    if (value.current_month_count) {
                                      return (
                                        <span>
                                          {(
                                            (value.current_month_count /
                                              Total_of_current_month) *
                                            100
                                          ).toFixed(2)}
                                          {"%"}
                                        </span>
                                      );
                                    }
                                  })
                                );
                              })}
                            </td>
                          </tr>
                        );
                      })}
                    <tr
                      style={{
                        color: `${theme.palette.text.primary}`,
                        backgroundColor: `${theme.palette.background.primary}`,
                      }}
                    >
                      <td className="pl-3">Total</td>
                      <td className="pl-3">{Total_of_before_previous_month}</td>
                      <td className="pl-3">{Total_of_previous_month}</td>
                      <td className="pl-3">{Total_of_current_month}</td>
                      <td className="pl-3">{Total_of_3_months}</td>
                      <td className="pl-3"></td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <NoDataFound />
              )}
            </div>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage />
        </Box>
      )}
    </LoadingIndicator>
  );
}

export default TotalNegativeMentions;
