import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  ListItem,
  Paper,
  Typography,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { connect } from "react-redux";
import { ShadowContainer } from "../../../../../components/componentLayout/ComponentLayout";
import { theme } from "../../../../../views/App";
import TicketsAction from "../../../../stores/tickets/TicketsAction";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

function DirectClosePopper(props) {
  const [open, setOpen] = React.useState(false);
  const directClosePopperRef = React.useRef(null);
  const prevOpen = React.useRef(open);

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      directClosePopperRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handlePopper = () => {
    if (props?.item?._source?.assigned_to.user_id !== "Unassigned") {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  const handleClose = (event) => {
    if (
      directClosePopperRef.current &&
      directClosePopperRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  let FinalStatusId = props.finalStatuses
    ? props.finalStatuses &&
      props.finalStatuses.length !== 0 &&
      props.finalStatuses[0]
    : "";

  // props.statusList.map((item) => {
  //   return item.final_status == true && (FinalStatusId = item.status_id);
  // });

  const finalStatuses_array = [];

  props.statusList.map((item) => {
    props.finalStatuses &&
      props.finalStatuses.length > 0 &&
      props.finalStatuses.map((status_id) => {
        if (status_id === item.status_id) {
          finalStatuses_array.push(item);
        }
      });
  });
  // props.statusList &&
  //   props.statusList.map((item) => {
  //     props.finalStatuses &&
  //       props.finalStatuses.map((status_id) => {
  //         if (status_id === item.status_id) {
  //           finalStatuses_array.push(item);
  //         }
  //       });
  //   });
  return (
    <Grid container justifyContent={"center"}>
      <Typography
        component={"div"}
        ref={directClosePopperRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={props?.returnVisibleReplyBtn ? handlePopper : null}
        style={{
          backgroundColor: "none",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {props.children}
      </Typography>

      <Popper
        open={open}
        anchorEl={directClosePopperRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        arrow
        modifiers={[
          {
            arrow: true,
          },
        ]}
        sx={{
          zIndex: 20,
          position: "relative",
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <ShadowContainer borderRadius={5}>
              <Paper
                style={{
                  width: "auto",
                  minWidth: 200,
                  height: "auto",
                  padding: 2,
                  //   boxShadow: theme.shadows,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <div
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                  >
                    <Grid item>
                      <Typography component={"span"} fontWeight="bold">
                        Are you sure you want to Ignore the ticket ?
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      container
                      // className="bg-warning"
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      justifyContent="center"
                      alignItems={"flex-start"}
                    >
                      <Grid
                        item
                        // className="bg-success"
                      >
                        <IconButton
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <CancelRoundedIcon
                            sx={{
                              color: theme.palette.secondary.main,
                            }}
                          />
                        </IconButton>
                      </Grid>

                      <Grid
                        item
                        // className="bg-info"
                      >
                        {props.finalStatuses &&
                        props.finalStatuses.length !== 0 &&
                        props.finalStatuses.length === 1 ? (
                          <IconButton
                            onClick={() => {
                              const put_obj = {
                                status: FinalStatusId,
                                direct_close: true,
                              };
                              // ticket PUT dispatch action
                              props
                                .dispatch(
                                  TicketsAction.requestPutTicket(
                                    JSON.stringify(put_obj),
                                    props.ticketID
                                  )
                                )
                                .then(() => {
                                  props.fetchFunction(FinalStatusId);
                                });
                              setOpen(false);
                            }}
                          >
                            <CheckCircleIcon
                              sx={{
                                color: theme.palette.primary.main,
                              }}
                            />
                          </IconButton>
                        ) : (
                          // <ShadowContainer borderRadius={5}>
                          <Paper
                            style={{
                              width: "auto",
                              // minWidth: 50,
                              height: "auto",
                              padding: 2,
                              //   boxShadow: theme.shadows,
                            }}
                          >
                            <ClickAwayListener onClickAway={handleClose}>
                              <div
                                autoFocusItem={open}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                style={{
                                  maxHeight: 250,
                                  overflowY: "scroll",
                                  scrollbarWidth: "none",
                                }}
                                className="scrollable"
                              >
                                {finalStatuses_array.length > 0 &&
                                  finalStatuses_array.map((status) => (
                                    <ListItem
                                      sx={{
                                        cursor: "pointer",

                                        "&:hover": {
                                          backgroundColor:
                                            theme.palette.background.default,
                                        },
                                      }}
                                      onClick={() => {
                                        props
                                          .dispatch(
                                            TicketsAction.requestPutTicket(
                                              JSON.stringify({
                                                status: status.status_id,
                                                direct_close: true,
                                              }),
                                              props.ticketID
                                            )
                                          )
                                          .then(() => {
                                            props.fetchFunction(
                                              status.status_id
                                            );
                                          });
                                      }}
                                    >
                                      {status.status_name}
                                    </ListItem>
                                  ))}
                              </div>
                            </ClickAwayListener>
                          </Paper>
                          // </ShadowContainer>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </ClickAwayListener>
              </Paper>
            </ShadowContainer>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
}

export default connect(mapDispatchToProps)(DirectClosePopper);
