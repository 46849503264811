import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import TelegramIcon from "@mui/icons-material/Telegram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import RedditIcon from "@mui/icons-material/Reddit";
import AppleIcon from "@mui/icons-material/Apple";
import CropPortraitIcon from "@mui/icons-material/CropPortrait";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CallIcon from "@mui/icons-material/Call";
import {
  faAmilia,
  faAppStore,
  faEarlybirds,
  faGoogle,
  faGooglePlay,
  faXTwitter,
  faQuora,
  faDiscord,
  faThreads,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCommentDots,
  faIdCard,
  faStar,
} from "@fortawesome/free-regular-svg-icons";
import SellIcon from "@mui/icons-material/Sell";
import { Box, Grid, Typography } from "@mui/material";
import {
  faAt,
  faCommentSms,
  faEnvelopeOpen,
  faRetweet,
  faTicket,
} from "@fortawesome/free-solid-svg-icons";
import ReplyIcon from "@mui/icons-material/Reply";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ThreePIcon from "@mui/icons-material/ThreeP";
import { theme } from "../views/App";
import PinterestIcon from "@mui/icons-material/Pinterest";
import youtubeLogo from "../Images/youtube_social_icon_red.png";
import twitterLogo from "../Images/twitter_logo.png";
import circledYoutubeLogo from "../Images/youtube_social_circle_red.png";
import TicketSummary from "../ormTicketing/components/reportPdfTemplates/TicketSummary";
import TicketCategoryDetailedReport from "../ormTicketing/components/reportPdfTemplates/TicketCategoryDetailedReport";
import TicketCategoryReport from "../ormTicketing/components/reportPdfTemplates/TicketCategoryReport";
import OverallSentimentMentionChart3months from "../ormTicketing/components/reportPdfTemplates/chartReports/OverallSentimentMentionChart3months";
import OverallSentimentMentions3months from "../ormTicketing/components/reportPdfTemplates/OverallSentimentMentions3months";
import RoleSummary from "../ormTicketing/components/reportPdfTemplates/RoleSummary";
import PlatformWiseReport from "../ormTicketing/components/reportPdfTemplates/PlatformWiseReport";
import SentimentAnalysisLast12monthsChart from "../ormTicketing/components/reportPdfTemplates/chartReports/SentimentAnalysisLast12monthsChart";
import OverallSentimentMentions from "../ormTicketing/components/reportPdfTemplates/OverallSentimentMentions";
import TatOverview from "../ormTicketing/components/reportPdfTemplates/TatOverview";
import OverallTicketStatusUserwise from "../ormTicketing/components/reportPdfTemplates/OverallTicketStatusUserwise";
import WordCloudReport from "../ormTicketing/components/reportPdfTemplates/WordCloudReport";
import InstagramUserActivity from "../ormTicketing/components/reportPdfTemplates/InstagramUserActivity";
import InstagramMessageSummary from "../ormTicketing/components/reportPdfTemplates/InstagramMessageSummary";
import MentionsTrendWithEngagement from "../ormTicketing/components/reportPdfTemplates/MentionsTrendWithEngagement";
import HourlyTicketsResolved from "../ormTicketing/components/reportPdfTemplates/HourlyTicketsResolved";
import BridgeUsersAvgTAT from "../ormTicketing/components/reportPdfTemplates/BridgeUsersAvgTAT";
import BridgeUsersAvgFRT from "../ormTicketing/components/reportPdfTemplates/BridgeUsersAvgFRT";
import OverallAverageTAT from "../ormTicketing/components/reportPdfTemplates/OverallAverageTAT";
import OverallAverageFRT from "../ormTicketing/components/reportPdfTemplates/OverallAverageFRT";
import SentimentOvertime from "../ormTicketing/components/reportPdfTemplates/SentimentOvertime";
import TicketClouserTatBreachChart from "../ormTicketing/components/reportPdfTemplates/chartReports/TicketClosureTatBreachChart";
import TicketClouserTatBreach from "../ormTicketing/components/reportPdfTemplates/TicketClouserTatBreach";
import ChannelWiseSentimentDistribution from "../ormTicketing/components/reportPdfTemplates/ChannelWiseSentimentDisrtibution";
import PlatformWiseOverallSentimentChart from "../ormTicketing/components/reportPdfTemplates/chartReports/PlatformWiseOverallSentimentChart";
import PlatformWiseOverallSentiment from "../ormTicketing/components/reportPdfTemplates/PlatformWiseOverallSentiment";
import ShareOfVoiceChart from "../ormTicketing/components/reportPdfTemplates/chartReports/ShareOfVoiceChart";
import OverallShareOfVoice from "../ormTicketing/components/reportPdfTemplates/OverallShareOfVoice";
import CategoryWiseSentiments from "../ormTicketing/components/reportPdfTemplates/CategoryWiseSentiments";
import CategoryWiseDistributionChart from "../ormTicketing/components/reportPdfTemplates/chartReports/CategoryWiseDistributionChart";
import CategoryWiseDistribution from "../ormTicketing/components/reportPdfTemplates/CategoryWiseDistribution";
import CategoryWiseTatDistribution from "../ormTicketing/components/reportPdfTemplates/CategoryWiseTatDistribution";
import CategoryWiseAvgFlr from "../ormTicketing/components/reportPdfTemplates/CategoryWiseAvgFlr";
import PlatformWiseSentimentOverview from "../ormTicketing/components/reportPdfTemplates/PlatformWiseSentimentOverview";
import AvgFRTPlatformWise from "../ormTicketing/components/reportPdfTemplates/AvgFRTPlatformWise";

import ResponseOverview from "../ormTicketing/components/reportPdfTemplates/ResponseOverview";
import MonthlyWisePlatformReviewCounts from "../ormTicketing/components/reportPdfTemplates/MonthlyWisePlatformReviewCounts";
import EscalationsReports from "../ormTicketing/components/reportPdfTemplates/EscalationsReports";
import TotalNegativeMentionsChart from "../ormTicketing/components/reportPdfTemplates/chartReports/TotalNeagativeMentionsChart";
import TotalNegativeMentions from "../ormTicketing/components/reportPdfTemplates/TotalNegativeMentions";
import NegativeMentionsChart from "../ormTicketing/components/reportPdfTemplates/chartReports/NegativeMentionsChart";
import NegativeMentions from "../ormTicketing/components/reportPdfTemplates/NegativeMentions";
import NegativeMentionsDispositionWiseChart from "../ormTicketing/components/reportPdfTemplates/chartReports/NegativeMentionDispositionwiseChart";
import NegativeMentionsDispositionWise from "../ormTicketing/components/reportPdfTemplates/NegativeMentionsDispositionWise";
import TotalMentionsChart from "../ormTicketing/components/reportPdfTemplates/chartReports/TotalMentionsChart";
import TotalMentions from "../ormTicketing/components/reportPdfTemplates/TotalMentions";
import TicketClosureFrtBreachChart from "../ormTicketing/components/reportPdfTemplates/chartReports/TicketClosureFrtBreachChart";
import ErrorBoundary from "./ErrorBoundary";
import TicketSummaryNew from "../ormTicketing/components/reportPdfTemplates/TicketSummaryNew";
import HourlylatformResolvedChart from "../ormTicketing/components/reportPdfTemplates/chartReports/HourlylatformResolvedChart";
import ResponseOverviewChart from "../ormTicketing/components/reportPdfTemplates/chartReports/ResponseOverviewChart";
import FRTEfficiencyAgentWiseChart from "../ormTicketing/components/reportPdfTemplates/FRTEfficiencyAgentWiseChart";
import BridgeUsersAvgFRTChart from "../ormTicketing/components/reportPdfTemplates/BridgeUsersAvgFRTChart";
// import MultilevelTicketCategoryReport from "../ormTicketing/components/reportPdfTemplates/MultilevelTicketCategoryReport";
// import OverallTicketStatusUserwiseChart from "../ormTicketing/components/reportPdfTemplates/OverallTicketStatusUserwiseChart";
import playstoreLogo from "../Images/platformLogos/playstore.webp";
import ambitionboxLogo from "../Images/platformLogos/ambitionBox.webp";
import appStoreLogo from "../Images/platformLogos/appStore.webp";
import emailLogo from "../Images/platformLogos/email.webp";
import facebookLogo from "../Images/platformLogos/facebook.webp";
import gmbLogo from "../Images/platformLogos/gmbLogo.webp";
import glassdoorLogo from "../Images/platformLogos/glassdoor.webp";
import indeedLogo from "../Images/platformLogos/indeed.webp";
import instagramLogo from "../Images/platformLogos/instagram.webp";
import linkedInLogo from "../Images/platformLogos/linkedIn.webp";
import pinterestLogo from "../Images/platformLogos/pinterest.webp";
import quoraLogo from "../Images/platformLogos/quora.webp";
import redditLogo from "../Images/platformLogos/reddit.webp";
import telegramLogo from "../Images/platformLogos/telegram.webp";
import threadsLogo from "../Images/platformLogos/threads.webp";
import whatsappLogo from "../Images/platformLogos/whatsapp.webp";
import trustPilotLogo from "../Images/platformLogos/trustPilotLogo.webp";
import discordLogo from "../Images/platformLogos/discordLogo.webp";
import consumerComplaintsLogo from "../Images/platformLogos/cc.png";
import noneLogo from "../Images/platformLogos/noneLogo.webp";
import twitterLogoWebp from "../Images/platformLogos/twitterLogo.webp";
import OverallTicketStatusUserwiseChart from "../ormTicketing/components/reportPdfTemplates/OverallTicketStatusUserwiseChart";
import BridgeUsersAvgTATChart from "../ormTicketing/components/reportPdfTemplates/BridgeUsersAvgTATChart";
import PlatformwiseStatus from "../ormTicketing/components/reportPdfTemplates/PlatformwiseStatus";
import LoadingIndicator from "./loading-indicator/LoadingIndicator";
import { useEffect, useRef, useState } from "react";
import TicketSummaryChartView from "../ormTicketing/components/reportPdfTemplates/TicketSummaryChartView";
import AnimationDelayChart from "../ormTicketing/views/Demo_static/AnimationDelayChart";
import BasicAreaChart from "../ormTicketing/views/Demo_static/BasicAreaChart";
import BasicSunburst from "../ormTicketing/views/Demo_static/BasicSunburst";
import BridgeSummary from "../ormTicketing/views/Demo_static/BridgeSummary";
import BubbleChart from "../ormTicketing/views/Demo_static/BubbleChart";
import ClickableColumnChart from "../ormTicketing/views/Demo_static/ClickableColumnChart";
import DynamicData from "../ormTicketing/views/Demo_static/DynamicData";
import LargescaleBarchart from "../ormTicketing/views/Demo_static/LargescaleBarchart";
import Nightingale from "../ormTicketing/views/Demo_static/Nightingale";
import StackedAreaChart from "../ormTicketing/views/Demo_static/StackedAreaChart";
import StackedHorizontalBar from "../ormTicketing/views/Demo_static/StackedHorizontalBar";
import StackedLineChart from "../ormTicketing/views/Demo_static/StackedLineChart";
import StaticDialyMentionsChart from "../ormTicketing/views/Demo_static/StaticDialyMentionsChart";
import StaticDialySentimentTrend from "../ormTicketing/views/Demo_static/StaticDialySentimentTrend";
import StaticPlatformSentimentChart from "../ormTicketing/views/Demo_static/StaticPlatformSentimentChart";
import StaticSubcategoryTrend from "../ormTicketing/views/Demo_static/StaticSubcategoryTrend";
import StaticTicketCategoryChart from "../ormTicketing/views/Demo_static/StaticTicketCategoryChart";
import StaticTicketSummaryChart from "../ormTicketing/views/Demo_static/StaticTicketSummaryChart";
import WordCloudStatic from "../ormTicketing/views/Demo_static/WordCloudStatic";
import StaticPlatformWiseSentiment from "../ormTicketing/views/Demo_static/PlatformWiseTicketTrend";
import NegativeMentionsStatic from "../ormTicketing/views/Demo_static/NegativeMentions";
import FRTEfficiencyAgentWise from "../ormTicketing/components/reportPdfTemplates/FRTEfficiencyAgentWise";
import BasicTreemap from "../ormTicketing/views/Demo_static/BasicTreemap";
import Platformwisetop10categories from "../ormTicketing/components/reportPdfTemplates/Platformwisetop10categories";
import PlatformwiseTicketsByCategory from "../ormTicketing/components/reportPdfTemplates/PlatformwiseTicketsByCategory";
import PlatformwiseNegativeTicketsByCategory from "../ormTicketing/components/reportPdfTemplates/PlatformwiseNegativeTicketsByCategory";
import FrtTatAveragesByCategory from "../ormTicketing/components/reportPdfTemplates/FrtTatAveragesByCategory";
import SenrimentsByTicketsCategory from "../ormTicketing/components/reportPdfTemplates/SenrimentsByTicketsCategory";
import TicketsByCategory from "../ormTicketing/components/reportPdfTemplates/TicketsByCategory";
import NegativeTicketsByCategory from "../ormTicketing/components/reportPdfTemplates/NegativeTicketsByCategory";
import SentimentByCustomFields from "../ormTicketing/components/reportPdfTemplates/SentimentByCustomFields";
import SentimentOnCustomValue from "../ormTicketing/components/reportPdfTemplates/SentimentOnCustomValue";
import NegativeCustomValueReport from "../ormTicketing/components/reportPdfTemplates/NegativeCustomValueReport";
import TicketsByCustomFields from "../ormTicketing/components/reportPdfTemplates/TicketsByCustomFields";
import FrtTatAveargesByCustomFields from "../ormTicketing/components/reportPdfTemplates/FrtTatAveargesByCustomFields";
import TicketsByCategoryChart from "../ormTicketing/components/reportPdfTemplates/TicketsByCategoryChart";
import NegativeTicketsByCategoryChart from "../ormTicketing/components/reportPdfTemplates/NegativeTicketsByCategoryChart";
import PlatformwiseTicketsByCategoryChart from "../ormTicketing/components/reportPdfTemplates/PlatformwiseTicketsByCategoryChart";
import PlatformwiseNegativeTicketsByCategoryChart from "../ormTicketing/components/reportPdfTemplates/PlatformwiseNegativeTicketsByCategoryChart";
import FrtTatAveragesByCategoryChart from "../ormTicketing/components/reportPdfTemplates/FrtTatAveragesByCategoryChart";
import SentimentbyTicketsCategoryChart from "../ormTicketing/components/reportPdfTemplates/SentimentbyTicketsCategoryChart";
import ShareOfVoiceTrend from "../ormTicketing/components/reportPdfTemplates/ShareOfVoiceTrend";
import SentimentwiseShareOfVoice from "../ormTicketing/components/reportPdfTemplates/SentimentwiseShareOfVoice";
import CompetitorSummaryReport from "../ormTicketing/components/reportPdfTemplates/CompetitorSummaryReport";
import InstagramProfileSummary from "../ormTicketing/components/reportPdfTemplates/InstagramProfileSummary";
import InstagramInsights from "../ormTicketing/components/reportPdfTemplates/chartReports/InstagramInsights";
import FollowerDemographicsCountry from "../ormTicketing/components/reportPdfTemplates/chartReports/FollowerDemographicsCountry";
import FollowersDemographicAge from "../ormTicketing/components/reportPdfTemplates/FollowersDemographicAge";
import FacebookProfileSummary from "../ormTicketing/components/reportPdfTemplates/FacebookProfileSummary";
import FollowerDemographicsCity from "../ormTicketing/components/reportPdfTemplates/chartReports/FollowerDemographicsCity";
import FacebookInsights from "../ormTicketing/components/reportPdfTemplates/FacebookInsights";
import DailyPageFans from "../ormTicketing/components/reportPdfTemplates/DailyPageFans";
import PageReachBreakdownOvertime from "../ormTicketing/components/reportPdfTemplates/PageReachBreakdownOvertime";
import PostReachByType from "../ormTicketing/components/reportPdfTemplates/PostReachByType";
import FollowersOverTime from "../ormTicketing/components/reportPdfTemplates/FollowersOverTime";
import YoutubeProfileSummary from "../ormTicketing/components/reportPdfTemplates/YoutubeProfileSummary";
import YoutubeViewsOvertime from "../ormTicketing/components/reportPdfTemplates/YoutubeViewsOvertime";
import YoutubeChannelWatchOvertime from "../ormTicketing/components/reportPdfTemplates/YoutubeChannelWatchOvertime";
import YoutubeVideoEnagementOvertime from "../ormTicketing/components/reportPdfTemplates/YoutubeVideoEnagementOvertime";
import YoutubeChannelViewsOvertime from "../ormTicketing/components/reportPdfTemplates/YoutubeChannelViewsOvertime";
import YoutubeSubscribersGainLostOvertime from "../ormTicketing/components/reportPdfTemplates/YoutubeSubscribersGainLostOvertime";
import PlatformWiseOverallSentiment_mentions from "../ormTicketing/components/reportPdfTemplates/PlatformWiseOverallSentiment_mentions";
import SentimentOvertime_mentions from "../ormTicketing/components/reportPdfTemplates/SentimentOvertime_mentions";
import ChannelWiseSentimentDistribution_mentions from "../ormTicketing/components/reportPdfTemplates/ChannelWiseSentimentDistribution_mentions";
import PlatformWiseSentimentOverview_mentions from "../ormTicketing/components/reportPdfTemplates/PlatformWiseSentimentOverview_mentions";
import OverallSentimentMentions_mentions from "../ormTicketing/components/reportPdfTemplates/OverallSentimentMentions_mentions";
import SentimentAnalysisLast12monthsChart_mentions from "../ormTicketing/components/reportPdfTemplates/chartReports/SentimentAnalysisLast12monthsChart_mentions";
import NegativeMentions_mentions from "../ormTicketing/components/reportPdfTemplates/NegativeMentions_mentions";
import TotalMentionsChart_mentions from "../ormTicketing/components/reportPdfTemplates/chartReports/TotalMentionsChart_mentions";
import ResponseOverview_mentions from "../ormTicketing/components/reportPdfTemplates/ResponseOverview_mentions";
import ResponseOverviewChart_mentions from "../ormTicketing/components/reportPdfTemplates/chartReports/ResponseOverviewChart_mentions";
import TotalMentions_mentions from "../ormTicketing/components/reportPdfTemplates/TotalMentions_mentions";
import NegativeMentionsChart_mentions from "../ormTicketing/components/reportPdfTemplates/chartReports/NegativeMentionsChart_mentions";
import OverallSentimentMentions3months_mentions from "../ormTicketing/components/reportPdfTemplates/OverallSentimentMentions3months_mentions";
import MentionsTrendWithEngagement_mentions from "../ormTicketing/components/reportPdfTemplates/MentionsTrendWithEngagement_mentions";
import WordCloudReport_mentions from "../ormTicketing/components/reportPdfTemplates/WordCloudReport_mentions";
import TicketWordCloudReport from "../ormTicketing/components/reportPdfTemplates/TicketWordCloudReport";

// import StaticsOverviewChart from "../ormTicketing/components/reportPdfTemplates/staticdashboard/reports/StaticsOverviewChart";
// import StaticSentimentAnalysisLast12MonthsChart from "../ormTicketing/components/reportPdfTemplates/staticdashboard/reports/StaticSentimentAnalysisLast12MonthsChart";

export const returnLogo = (item, additionalProp) => {
  if (item === "Twitter") {
    return (
      <>
        <span title="Twitter">
          <FontAwesomeIcon
            icon={faXTwitter}
            style={{ width: 15 }}
          ></FontAwesomeIcon>
        </span>
      </>
    );
  } else if (item === "Facebook") {
    return (
      <>
        <span title="Facebook">
          <FacebookIcon style={{ width: 15 }} />{" "}
        </span>
      </>
    );
  } else if (item === "Playstore") {
    return (
      <>
        <span title="Playstore">
          <FontAwesomeIcon
            icon={faGooglePlay}
            style={{ width: 15 }}
          ></FontAwesomeIcon>
        </span>
      </>
    );
  } else if (item === "Instagram") {
    return (
      <>
        <span title="Instagram">
          <InstagramIcon style={{ width: 15 }} />{" "}
        </span>
      </>
    );
  } else if (item === "Youtube") {
    return (
      <>
        <span title="Youtube">
          {additionalProp == "Influencer" ? (
            <YouTubeIcon style={{ color: "red" }} />
          ) : (
            <img src={youtubeLogo} style={{ height: "20px", margin: "10px" }} />
          )}
        </span>
      </>
    );
  } else if (item === "Whatsapp") {
    return (
      <>
        <span title="Whatsapp">
          <WhatsAppIcon style={{ width: 15 }} />{" "}
        </span>
      </>
    );
  } else if (item === "Email") {
    return (
      <>
        <span title="Email">
          <EmailIcon style={{ width: 15 }} />{" "}
        </span>
      </>
    );
  } else if (item === "Telegram") {
    return (
      <>
        <span title="Telegram">
          <TelegramIcon style={{ width: 15 }} />
        </span>
      </>
    );
  } else if (item === "LinkedIn") {
    return (
      <>
        <span title="Linked In">
          <LinkedInIcon style={{ width: 15 }} />
        </span>
      </>
    );
  } else if (item === "Appstore") {
    return (
      <>
        <span title="Appstore">
          <FontAwesomeIcon
            icon={faAppStore}
            style={{ width: 15 }}
          ></FontAwesomeIcon>
        </span>
      </>
    );
  } else if (item === "Quora") {
    return (
      <span title="Quora">
        <FontAwesomeIcon icon={faQuora} style={{ width: 15 }}></FontAwesomeIcon>
      </span>
    );
  } else if (item === "Glassdoor") {
    return (
      <>
        <span title="Glassdoor">
          <CropPortraitIcon style={{ width: 15 }} />{" "}
        </span>
      </>
    );
  } else if (item === "Ambition Box") {
    return (
      <span title="Ambition Box">
        <FontAwesomeIcon
          icon={faAmilia}
          style={{ width: 15 }}
        ></FontAwesomeIcon>
      </span>
    );
  } else if (item === "GMB") {
    return (
      <span title="GMB">
        <FontAwesomeIcon
          icon={faGoogle}
          style={{ width: 15 }}
        ></FontAwesomeIcon>
      </span>
    );
  } else if (item === "Threads") {
    return (
      <span title="Threads">
        <FontAwesomeIcon
          icon={faThreads}
          style={{ width: 15 }}
        ></FontAwesomeIcon>
      </span>
    );
  } else if (item === "Trust Pilot") {
    return (
      <span title="Trust Pilot">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="15px"
          height="15px"
          fill={theme.palette.primary.main} // Replace with the appropriate color value
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 16.55l-6.545 3.99 1.555-7.015L2.02 9.25l7.257-.63L12 2l2.723 6.62 7.257.63-5.99 4.26 1.556 7.015L12 16.55zm0 0" />
        </svg>
      </span>
    );
  } else if (item === "Pinterest") {
    return (
      <span title="Pinterest">
        <PinterestIcon style={{ width: 15 }} />{" "}
      </span>
    );
  } else if (item === "Reddit") {
    return (
      <span title="Reddit">
        <RedditIcon style={{ width: 15 }} />{" "}
      </span>
    );
  } else if (item === "Indeed") {
    return (
      <span title="Indeed">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          width="15px"
          height="15px"
          fill={theme.palette.primary.main}
        >
          <path d="M 18.5 2 C 10.75 2 6 11.25 6 16 C 6 16 9.25 4 19 4 C 22.625 4 25 6 25 6 C 25 6 24 2 18.5 2 z M 17.5 7 A 3.5 3.5 0 1 0 17.5 14 A 3.5 3.5 0 1 0 17.5 7 z M 20 16 C 20 16 19 17 15 17 L 15 27.5 C 15 28.881 16.119 30 17.5 30 C 18.881 30 20 28.881 20 27.5 L 20 16 z" />
        </svg>
      </span>
    );
  } else if (item === "None") {
    return (
      <span title="None">
        <FontAwesomeIcon
          icon={faIdCard}
          style={{ width: 15 }}
        ></FontAwesomeIcon>
      </span>
    );
  } else if (item === "Consumer Complaints") {
    return (
      <span title="Consumercomplaints">
        <CallIcon style={{ width: 15 }} />
      </span>
    );
  } else if (item === "Discord") {
    return (
      <span title="Discord">
        <FontAwesomeIcon
          icon={faDiscord}
          style={{ width: 15 }}
        ></FontAwesomeIcon>
      </span>
    );
  } else {
    return null;
  }
};

export const returnSmallLogo = (item) => {
  if (item === "Twitter") {
    return (
      <>
        <span title="Twitter">
          <FontAwesomeIcon
            icon={faXTwitter}
            style={{ width: 10, height: 10, padding: 0, margin: 0 }}
          ></FontAwesomeIcon>
        </span>
      </>
    );
  } else if (item === "Facebook") {
    return (
      <>
        <span title="Facebook">
          <FacebookIcon
            style={{ width: 10, height: 10, padding: 0, margin: 0 }}
          />{" "}
        </span>
      </>
    );
  } else if (item === "Playstore") {
    return (
      <>
        <span title="Playstore">
          <FontAwesomeIcon
            icon={faGooglePlay}
            style={{ width: 10, height: 10, padding: 0, margin: 0 }}
          ></FontAwesomeIcon>
        </span>
      </>
    );
  } else if (item === "Instagram") {
    return (
      <>
        <span title="Instagram">
          <InstagramIcon
            style={{ width: 10, height: 10, padding: 0, margin: 0 }}
          />{" "}
        </span>
      </>
    );
  } else if (item === "Youtube") {
    return (
      <>
        <span title="Youtube">
          {/* <YouTubeIcon
            style={{
              width: 10,
              height: 10,
              padding: 0,
              margin: 0,
              color: "red",
            }}
          /> */}

          {/* <img src={youtubeLogo} style={{ height: "20px", margin: "10px" }} /> */}
        </span>
      </>
    );
  } else if (item === "Whatsapp") {
    return (
      <>
        <span title="Whatsapp">
          <WhatsAppIcon
            style={{ width: 10, height: 10, padding: 0, margin: 0 }}
          />{" "}
        </span>
      </>
    );
  } else if (item === "Email") {
    return (
      <>
        <span title="Email">
          <EmailIcon style={{ width: 10, height: 10, padding: 0, margin: 0 }} />{" "}
        </span>
      </>
    );
  } else if (item === "Telegram") {
    return (
      <>
        <span title="Telegram">
          <TelegramIcon
            style={{ width: 10, height: 10, padding: 0, margin: 0 }}
          />
        </span>
      </>
    );
  } else if (item === "LinkedIn") {
    return (
      <>
        <span title="Linked In">
          <LinkedInIcon
            style={{ width: 10, height: 10, padding: 0, margin: 0 }}
          />
        </span>
      </>
    );
  } else if (item === "Appstore") {
    return (
      <>
        <span title="Appstore">
          <FontAwesomeIcon
            icon={faAppStore}
            style={{ width: 10, height: 10, padding: 0, margin: 0 }}
          ></FontAwesomeIcon>
        </span>
      </>
    );
  } else if (item === "Quora") {
    return (
      <span title="Quora">
        <FontAwesomeIcon
          icon={faQuora}
          style={{ width: 10, height: 10, padding: 0, margin: 0 }}
        ></FontAwesomeIcon>
      </span>
    );
  } else if (item === "Glassdoor") {
    return (
      <>
        <span title="Glassdoor">
          <CropPortraitIcon
            style={{ width: 10, height: 10, padding: 0, margin: 0 }}
          />{" "}
        </span>
      </>
    );
  } else if (item === "Ambition Box") {
    return (
      <span title="Ambition Box">
        <FontAwesomeIcon
          icon={faAmilia}
          style={{ width: 10, height: 10, padding: 0, margin: 0 }}
        ></FontAwesomeIcon>
      </span>
    );
  } else if (item === "GMB") {
    return (
      <span title="GMB">
        <FontAwesomeIcon
          icon={faGoogle}
          style={{ width: 10, height: 10, padding: 0, margin: 0 }}
        ></FontAwesomeIcon>
      </span>
    );
  } else if (item === "Threads") {
    return (
      <span title="Threads">
        <FontAwesomeIcon
          icon={faThreads}
          style={{ width: 10, height: 10, padding: 0, margin: 0 }}
        ></FontAwesomeIcon>
      </span>
    );
  } else if (item === "Trust Pilot") {
    return (
      <span title="Trustpilot">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="15px"
          height="15px"
          fill={"white"} // Replace with the appropriate color value
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 16.55l-6.545 3.99 1.555-7.015L2.02 9.25l7.257-.63L12 2l2.723 6.62 7.257.63-5.99 4.26 1.556 7.015L12 16.55zm0 0" />
        </svg>
      </span>
    );
  } else if (item === "Pinterest") {
    return (
      <span title="Pinterest">
        <PinterestIcon
          style={{ width: 10, height: 10, padding: 0, margin: 0 }}
        />{" "}
      </span>
    );
  } else if (item === "Reddit") {
    return (
      <span title="Reddit">
        <RedditIcon style={{ width: 10, height: 10, padding: 0, margin: 0 }} />{" "}
      </span>
    );
  } else if (item === "Indeed") {
    return (
      <span title="Indeed">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          width="10px"
          height="10px"
          fill="white"
        >
          <path d="M 18.5 2 C 10.75 2 6 11.25 6 16 C 6 16 9.25 4 19 4 C 22.625 4 25 6 25 6 C 25 6 24 2 18.5 2 z M 17.5 7 A 3.5 3.5 0 1 0 17.5 14 A 3.5 3.5 0 1 0 17.5 7 z M 20 16 C 20 16 19 17 15 17 L 15 27.5 C 15 28.881 16.119 30 17.5 30 C 18.881 30 20 28.881 20 27.5 L 20 16 z" />
        </svg>
      </span>
    );
  } else if (item === "None") {
    return (
      <span title="None">
        <FontAwesomeIcon
          icon={faIdCard}
          style={{ width: 10, height: 10, padding: 0, margin: 0 }}
        ></FontAwesomeIcon>
      </span>
    );
  } else if (item === "Consumer Complaints") {
    return (
      <span title="Consumercomplaints">
        <CallIcon style={{ width: 10, height: 10, padding: 0, margin: 0 }} />
      </span>
    );
  } else if (item === "Discord") {
    return (
      <span title="Discord">
        <FontAwesomeIcon
          icon={faDiscord}
          style={{ width: 10, height: 10, padding: 0, margin: 0 }}
        ></FontAwesomeIcon>
      </span>
    );
  } else {
    return null;
  }
};

export const RowIcons = (item) => {
  if (item.params.platform === "Twitter") {
    if (item.params.activities === "Tweet") {
      return (
        <>
          <FontAwesomeIcon
            icon={faXTwitter}
            style={{ width: "12px", marginRight: 5 }}
          ></FontAwesomeIcon>

          <FontAwesomeIcon
            icon={faEnvelopeOpen}
            style={{ width: "12px", marginRight: 5 }}
          ></FontAwesomeIcon>
          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    } else if (item.params.activities === "ReTweet") {
      return (
        <>
          <FontAwesomeIcon
            icon={faXTwitter}
            style={{ width: "12px", marginRight: 5 }}
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            icon={faRetweet}
            style={{ width: "12px", marginRight: 5 }}
          ></FontAwesomeIcon>
          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    } else if (item.params.activities === "Reply") {
      return (
        <>
          <FontAwesomeIcon
            icon={faXTwitter}
            style={{ width: "12px", marginRight: 5 }}
          ></FontAwesomeIcon>
          <ReplyIcon style={{ width: "12px", marginRight: 5 }} />
          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    } else if (item.params.activities === "Direct Message") {
      return (
        <>
          <FontAwesomeIcon
            icon={faXTwitter}
            style={{ width: "12px", marginRight: 5 }}
          ></FontAwesomeIcon>
          <ContactMailIcon style={{ width: "12px", marginRight: 5 }} />
          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    }
  } else if (item.params.platform === "Facebook") {
    if (item.params.activities === "Comments") {
      return (
        <>
          <FacebookIcon style={{ width: "12px", marginRight: 5 }} />
          <FontAwesomeIcon
            icon={faCommentDots}
            style={{ width: "12px", marginRight: 5 }}
          ></FontAwesomeIcon>
          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    } else if (item.params.activities === "Reply") {
      return (
        <>
          <FacebookIcon style={{ width: "12px", marginRight: 5 }} />
          <ReplyIcon style={{ width: "12px", marginRight: 5 }} />

          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    } else if (item.params.activities === "Direct Message") {
      return (
        <>
          <FacebookIcon style={{ width: "12px", marginRight: 5 }} />
          <ContactMailIcon style={{ width: "12px", marginRight: 5 }} />

          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    } else if (item.params.activities === "Review") {
      return (
        <>
          <FacebookIcon style={{ width: "12px", marginRight: 5 }} />
          <FontAwesomeIcon
            icon={faStar}
            style={{ width: "10px", marginRight: 5 }}
          ></FontAwesomeIcon>

          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    }
  } else if (item.params.platform === "Playstore") {
    if (item.params.activities === "Review") {
      return (
        <>
          <FontAwesomeIcon
            icon={faGooglePlay}
            style={{ width: "12px", marginRight: 5 }}
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            icon={faStar}
            style={{ width: "10px", marginRight: 5 }}
          ></FontAwesomeIcon>
          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    }
  } else if (item.params.platform === "Instagram") {
    if (item.params.activities === "Comments") {
      return (
        <>
          <InstagramIcon style={{ width: "12px", marginRight: 5 }} />
          <FontAwesomeIcon
            icon={faCommentDots}
            style={{ width: "12px", marginRight: 5 }}
          ></FontAwesomeIcon>
          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    } else if (item.params.activities === "Direct Message") {
      return (
        <>
          <InstagramIcon style={{ width: "12px", marginRight: 5 }} />
          <ContactMailIcon style={{ width: "12px", marginRight: 5 }} />

          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    } else if (item.params.activities === "Comment Mentions") {
      return (
        <>
          <InstagramIcon style={{ width: "12px", marginRight: 5 }} />
          <ThreePIcon style={{ width: "12px", marginRight: 5 }} />

          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    }
    if (item.params.activities === "Captions Mention") {
      return (
        <>
          <InstagramIcon style={{ width: "12px", marginRight: 5 }} />
          <FontAwesomeIcon
            icon={faAt}
            style={{ width: "12px", marginRight: 5 }}
          ></FontAwesomeIcon>
          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    }
  } else if (item.params.platform === "Youtube") {
    if (item.params.activities === "Comments") {
      return (
        <>
          <YouTubeIcon style={{ width: "12px", marginRight: 5 }} />
          <FontAwesomeIcon
            icon={faCommentDots}
            style={{ width: "12px", marginRight: 5 }}
          ></FontAwesomeIcon>

          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    }
  } else if (item.params.platform === "Whatsapp") {
    if (item.params.activities === "Messages") {
      return (
        <>
          <WhatsAppIcon style={{ width: "12px", marginRight: 5 }} />
          <MapsUgcIcon style={{ width: "12px", marginRight: 5 }} />
          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    }
  } else if (item.params.platform === "Email") {
    if (item.params.activities === "Mails") {
      return (
        <>
          <EmailIcon style={{ width: "12px", marginRight: 5 }} />
          <MarkEmailUnreadIcon style={{ width: "12px", marginRight: 5 }} />
          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    }
  } else if (item.params.platform === "Telegram") {
    return (
      <>
        <TelegramIcon style={{ width: "12px", marginRight: 5 }} />
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "LinkedIn") {
    if (item.params.activities === "Comment") {
      return (
        <>
          <LinkedInIcon style={{ width: "12px", marginRight: 5 }} />
          <FontAwesomeIcon
            icon={faCommentDots}
            style={{ width: "12px", marginRight: 5 }}
          ></FontAwesomeIcon>
          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    }
  } else if (item.params.platform === "Appstore") {
    return (
      <>
        <AppleIcon style={{ width: "12px", marginRight: 5 }} />
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Quora") {
    if (item.params.activities === "Questions Answers") {
      return (
        <>
          <FontAwesomeIcon
            icon={faQuora}
            style={{ width: "12x", marginRight: 5 }}
          ></FontAwesomeIcon>
          <QuestionAnswerIcon style={{ width: "12px", marginRight: 5 }} />

          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    }
  } else if (item.params.platform === "Glassdoor") {
    return (
      <>
        <CropPortraitIcon style={{ width: "12px", marginRight: 5 }} />
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Ambition Box") {
    if (item.params.activities === "Reviews") {
      return (
        <>
          <FontAwesomeIcon
            icon={faAmilia}
            style={{ width: "10px", marginRight: 5 }}
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            icon={faStar}
            style={{ width: "10px", marginRight: 5 }}
          ></FontAwesomeIcon>
          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    }
  } else if (item.params.platform === "GMB") {
    if (item.params.activities === "Reviews") {
      return (
        <>
          <FontAwesomeIcon
            icon={faGoogle}
            style={{ width: "10px", marginRight: 5 }}
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            icon={faStar}
            style={{ width: "10px", marginRight: 5 }}
          ></FontAwesomeIcon>
          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    } else if (item.params.activities === "Questions Answers") {
      return (
        <>
          <FontAwesomeIcon
            icon={faGoogle}
            style={{ width: "10px", marginRight: 5 }}
          ></FontAwesomeIcon>
          <QuestionAnswerIcon style={{ width: "12px", marginRight: 5 }} />

          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    }
  } else if (item.params.platform === "None") {
    if (item.params.activities === null) {
      return (
        <>
          <FontAwesomeIcon
            icon={faIdCard}
            style={{ width: "10px", marginRight: 5 }}
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            icon={faTicket}
            style={{ width: "10px", marginRight: 5 }}
          ></FontAwesomeIcon>

          <span className="text-wrap">{item.params.description[1]}</span>
        </>
      );
    }
  }
};

// for keyword configuration
export const RowIcons_key_conf = (item) => {
  if (item.params.platform === "Twitter") {
    return (
      <>
        <FontAwesomeIcon
          icon={faXTwitter}
          style={{ width: "12px", marginRight: 5 }}
        ></FontAwesomeIcon>
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Facebook") {
    return (
      <>
        <FacebookIcon style={{ width: "12px", marginRight: 5 }} />
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Playstore") {
    return (
      <>
        <FontAwesomeIcon
          icon={faGooglePlay}
          style={{ width: "12px", marginRight: 5 }}
        ></FontAwesomeIcon>
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Instagram") {
    return (
      <>
        <InstagramIcon style={{ width: "12px", marginRight: 5 }} />
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Youtube") {
    return (
      <>
        <YouTubeIcon style={{ width: "12px", marginRight: 5 }} />
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Whatsapp") {
    return (
      <>
        <WhatsAppIcon style={{ width: "12px", marginRight: 5 }} />
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Email") {
    return (
      <>
        <EmailIcon style={{ width: "12px", marginRight: 5 }} />
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Telegram") {
    return (
      <>
        <TelegramIcon style={{ width: "12px", marginRight: 5 }} />
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "LinkedIn") {
    return (
      <>
        <LinkedInIcon style={{ width: "12px", marginRight: 5 }} />
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Appstore") {
    return (
      <>
        <AppleIcon style={{ width: "12px", marginRight: 5 }} />
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Quora") {
    return (
      <>
        <FontAwesomeIcon
          icon={faQuora}
          style={{ width: "12x", marginRight: 5 }}
        ></FontAwesomeIcon>
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Glassdoor") {
    return (
      <>
        <CropPortraitIcon style={{ width: "12px", marginRight: 5 }} />
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Ambition Box") {
    return (
      <>
        <FontAwesomeIcon
          icon={faAmilia}
          style={{ width: "10px", marginRight: 5 }}
        ></FontAwesomeIcon>
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "GMB") {
    return (
      <>
        <FontAwesomeIcon
          icon={faGoogle}
          style={{ width: "10px", marginRight: 5 }}
        ></FontAwesomeIcon>
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "None") {
    return (
      <>
        <FontAwesomeIcon
          icon={faIdCard}
          style={{ width: "10px", marginRight: 5 }}
        ></FontAwesomeIcon>
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  }
};

export const returnColor = (color) => {
  return (
    <Box
      width={10}
      height={10}
      borderRadius="50%"
      sx={{
        backgroundColor: `${color}`,
      }}
    ></Box>
  );
};

export const returnAvailability = (color) => {
  return (
    <Box
      width={10}
      height={10}
      borderRadius="50%"
      sx={{
        backgroundColor: `${
          color === "Offline" ? "red" : color === "Online" && "green"
        }`,
      }}
    ></Box>
  );
};
const returnSentColor = (color) => {
  if (color === "Positive") {
    return "green";
  } else if (color === "Negative") {
    return "red";
  } else if (color === "Neutral") {
    return "gray";
  }
};
export const returnSentimentColor = (color) => {
  return (
    <Box
      width={10}
      height={10}
      borderRadius="50%"
      sx={{
        backgroundColor: `${returnSentColor(color)}`,
      }}
    ></Box>
  );
};

export const returnUsestateFunctionForAllMentions = (
  selected_platform,
  set_twitter_feed_open,
  set_facebook_feed_open,
  set_instagram_feed_open,
  set_playstore_feed_open,
  set_youtube_feed_open,
  set_email_feed_open,
  set_appstore_feed_open,
  set_glassdoor_feed_open,
  set_ambition_box_feed_open,
  set_whatsapp_feed_open,
  set_telegram_feed_open,
  set_GMB_feed_open,
  set_linkedIn_feed_open,
  set_quora_feed_open,
  set_indeed_feed_open,
  set_reddit_feed_open,
  set_discord_feed_open,
  set_trustpilot_feed_open,
  set_pinterest_feed_open,
  set_none_feed_open,
  set_consumer_feed_open
) => {
  if (selected_platform === "Twitter") {
    set_twitter_feed_open(true);
    set_facebook_feed_open(false);
    set_instagram_feed_open(false);
    set_playstore_feed_open(false);
    set_youtube_feed_open(false);
    set_email_feed_open(false);
    set_appstore_feed_open(false);
    set_glassdoor_feed_open(false);
    set_ambition_box_feed_open(false);
    set_whatsapp_feed_open(false);
    set_telegram_feed_open(false);
    set_GMB_feed_open(false);
    set_linkedIn_feed_open(false);
    set_reddit_feed_open(false);
    set_discord_feed_open(false);
    set_trustpilot_feed_open(false);
    set_pinterest_feed_open(false);
    set_consumer_feed_open(false);
    set_indeed_feed_open(false);
    set_quora_feed_open(false);
    set_none_feed_open(false);
  } else if (selected_platform === "Indeed") {
    set_twitter_feed_open(false);
    set_facebook_feed_open(false);
    set_instagram_feed_open(false);
    set_playstore_feed_open(false);
    set_youtube_feed_open(false);
    set_email_feed_open(false);
    set_appstore_feed_open(false);
    set_glassdoor_feed_open(false);
    set_ambition_box_feed_open(false);
    set_whatsapp_feed_open(false);
    set_telegram_feed_open(false);
    set_GMB_feed_open(false);
    set_reddit_feed_open(false);
    set_discord_feed_open(false);
    set_trustpilot_feed_open(false);
    set_pinterest_feed_open(false);

    set_linkedIn_feed_open(false);
    set_indeed_feed_open(false);
    set_quora_feed_open(false);
    set_indeed_feed_open(true);
    set_consumer_feed_open(false);

    set_none_feed_open(false);
  } else if (selected_platform === "Reddit") {
    set_twitter_feed_open(false);
    set_facebook_feed_open(false);
    set_instagram_feed_open(false);
    set_playstore_feed_open(false);
    set_reddit_feed_open(true);
    set_discord_feed_open(false);
    set_trustpilot_feed_open(false);
    set_pinterest_feed_open(false);

    set_youtube_feed_open(false);
    set_email_feed_open(false);
    set_appstore_feed_open(false);
    set_glassdoor_feed_open(false);
    set_ambition_box_feed_open(false);
    set_whatsapp_feed_open(false);
    set_telegram_feed_open(false);
    set_GMB_feed_open(false);
    set_linkedIn_feed_open(false);
    set_indeed_feed_open(false);
    set_quora_feed_open(false);
    set_indeed_feed_open(false);
    set_consumer_feed_open(false);

    set_none_feed_open(false);
  } else if (selected_platform === "Discord") {
    set_twitter_feed_open(false);
    set_facebook_feed_open(false);
    set_instagram_feed_open(false);
    set_playstore_feed_open(false);
    set_reddit_feed_open(false);
    set_discord_feed_open(true);
    set_trustpilot_feed_open(false);
    set_pinterest_feed_open(false);

    set_youtube_feed_open(false);
    set_email_feed_open(false);
    set_appstore_feed_open(false);
    set_glassdoor_feed_open(false);
    set_ambition_box_feed_open(false);
    set_whatsapp_feed_open(false);
    set_telegram_feed_open(false);
    set_GMB_feed_open(false);
    set_linkedIn_feed_open(false);
    set_indeed_feed_open(false);
    set_quora_feed_open(false);
    set_indeed_feed_open(false);
    set_consumer_feed_open(false);

    set_none_feed_open(false);
  } else if (selected_platform === "Consumer Complaints") {
    set_twitter_feed_open(false);
    set_facebook_feed_open(false);
    set_instagram_feed_open(false);
    set_playstore_feed_open(false);
    set_reddit_feed_open(false);
    set_discord_feed_open(false);
    set_trustpilot_feed_open(false);
    set_pinterest_feed_open(false);

    set_youtube_feed_open(false);
    set_email_feed_open(false);
    set_appstore_feed_open(false);
    set_glassdoor_feed_open(false);
    set_ambition_box_feed_open(false);
    set_whatsapp_feed_open(false);
    set_telegram_feed_open(false);
    set_GMB_feed_open(false);
    set_linkedIn_feed_open(false);
    set_indeed_feed_open(false);
    set_quora_feed_open(false);
    set_consumer_feed_open(true);
    set_none_feed_open(false);
  } else if (selected_platform === "Facebook") {
    set_twitter_feed_open(false);
    set_facebook_feed_open(true);
    set_instagram_feed_open(false);
    set_playstore_feed_open(false);
    set_youtube_feed_open(false);
    set_email_feed_open(false);
    set_appstore_feed_open(false);
    set_glassdoor_feed_open(false);
    set_ambition_box_feed_open(false);
    set_reddit_feed_open(false);
    set_discord_feed_open(false);
    set_trustpilot_feed_open(false);
    set_pinterest_feed_open(false);

    set_whatsapp_feed_open(false);
    set_telegram_feed_open(false);
    set_GMB_feed_open(false);
    set_indeed_feed_open(false);
    set_linkedIn_feed_open(false);
    set_quora_feed_open(false);
    set_none_feed_open(false);
    set_consumer_feed_open(false);
  } else if (selected_platform === "Instagram") {
    set_twitter_feed_open(false);
    set_facebook_feed_open(false);
    set_instagram_feed_open(true);
    set_playstore_feed_open(false);
    set_youtube_feed_open(false);
    set_email_feed_open(false);
    set_appstore_feed_open(false);
    set_reddit_feed_open(false);
    set_discord_feed_open(false);
    set_trustpilot_feed_open(false);
    set_pinterest_feed_open(false);

    set_glassdoor_feed_open(false);
    set_ambition_box_feed_open(false);
    set_whatsapp_feed_open(false);
    set_indeed_feed_open(false);
    set_telegram_feed_open(false);
    set_GMB_feed_open(false);
    set_linkedIn_feed_open(false);
    set_quora_feed_open(false);
    set_none_feed_open(false);
    set_consumer_feed_open(false);
  } else if (selected_platform === "Playstore") {
    set_twitter_feed_open(false);
    set_facebook_feed_open(false);
    set_instagram_feed_open(false);
    set_playstore_feed_open(true);
    set_youtube_feed_open(false);
    set_email_feed_open(false);
    set_reddit_feed_open(false);
    set_appstore_feed_open(false);
    set_discord_feed_open(false);
    set_trustpilot_feed_open(false);
    set_pinterest_feed_open(false);

    set_glassdoor_feed_open(false);
    set_ambition_box_feed_open(false);
    set_whatsapp_feed_open(false);
    set_indeed_feed_open(false);
    set_telegram_feed_open(false);
    set_GMB_feed_open(false);
    set_linkedIn_feed_open(false);
    set_quora_feed_open(false);
    set_none_feed_open(false);
    set_consumer_feed_open(false);
  } else if (selected_platform === "Youtube") {
    set_twitter_feed_open(false);
    set_facebook_feed_open(false);
    set_instagram_feed_open(false);
    set_playstore_feed_open(false);
    set_youtube_feed_open(true);
    set_email_feed_open(false);
    set_appstore_feed_open(false);
    set_glassdoor_feed_open(false);
    set_reddit_feed_open(false);
    set_discord_feed_open(false);
    set_trustpilot_feed_open(false);
    set_pinterest_feed_open(false);

    set_ambition_box_feed_open(false);
    set_whatsapp_feed_open(false);
    set_indeed_feed_open(false);
    set_telegram_feed_open(false);
    set_GMB_feed_open(false);
    set_linkedIn_feed_open(false);
    set_quora_feed_open(false);
    set_none_feed_open(false);
    set_consumer_feed_open(false);
  } else if (selected_platform === "Email") {
    set_twitter_feed_open(false);
    set_facebook_feed_open(false);
    set_instagram_feed_open(false);
    set_playstore_feed_open(false);
    set_youtube_feed_open(false);
    set_email_feed_open(true);
    set_appstore_feed_open(false);
    set_reddit_feed_open(false);
    set_discord_feed_open(false);
    set_trustpilot_feed_open(false);
    set_pinterest_feed_open(false);

    set_glassdoor_feed_open(false);
    set_ambition_box_feed_open(false);
    set_indeed_feed_open(false);
    set_whatsapp_feed_open(false);
    set_telegram_feed_open(false);
    set_GMB_feed_open(false);
    set_linkedIn_feed_open(false);
    set_quora_feed_open(false);
    set_none_feed_open(false);
    set_consumer_feed_open(false);
  } else if (selected_platform === "Appstore") {
    set_twitter_feed_open(false);
    set_facebook_feed_open(false);
    set_instagram_feed_open(false);
    set_playstore_feed_open(false);
    set_youtube_feed_open(false);
    set_email_feed_open(false);
    set_appstore_feed_open(true);
    set_glassdoor_feed_open(false);
    set_reddit_feed_open(false);
    set_discord_feed_open(false);
    set_trustpilot_feed_open(false);
    set_pinterest_feed_open(false);

    set_ambition_box_feed_open(false);
    set_whatsapp_feed_open(false);
    set_indeed_feed_open(false);
    set_telegram_feed_open(false);
    set_GMB_feed_open(false);
    set_linkedIn_feed_open(false);
    set_quora_feed_open(false);
    set_none_feed_open(false);
    set_consumer_feed_open(false);
  } else if (selected_platform === "Glassdoor") {
    set_twitter_feed_open(false);
    set_facebook_feed_open(false);
    set_instagram_feed_open(false);
    set_playstore_feed_open(false);
    set_youtube_feed_open(false);
    set_email_feed_open(false);
    set_appstore_feed_open(false);
    set_glassdoor_feed_open(true);
    set_reddit_feed_open(false);
    set_discord_feed_open(false);
    set_trustpilot_feed_open(false);
    set_pinterest_feed_open(false);

    set_ambition_box_feed_open(false);
    set_indeed_feed_open(false);
    set_whatsapp_feed_open(false);
    set_telegram_feed_open(false);
    set_GMB_feed_open(false);
    set_linkedIn_feed_open(false);
    set_quora_feed_open(false);
    set_none_feed_open(false);
    set_consumer_feed_open(false);
  } else if (selected_platform === "Ambition Box") {
    set_twitter_feed_open(false);
    set_facebook_feed_open(false);
    set_instagram_feed_open(false);
    set_playstore_feed_open(false);
    set_reddit_feed_open(false);
    set_discord_feed_open(false);
    set_trustpilot_feed_open(false);
    set_pinterest_feed_open(false);

    set_youtube_feed_open(false);
    set_email_feed_open(false);
    set_appstore_feed_open(false);
    set_glassdoor_feed_open(false);
    set_ambition_box_feed_open(true);
    set_whatsapp_feed_open(false);
    set_telegram_feed_open(false);
    set_indeed_feed_open(false);
    set_GMB_feed_open(false);
    set_linkedIn_feed_open(false);
    set_quora_feed_open(false);
    set_none_feed_open(false);
    set_consumer_feed_open(false);
  } else if (selected_platform === "Whatsapp") {
    set_twitter_feed_open(false);
    set_facebook_feed_open(false);
    set_instagram_feed_open(false);
    set_playstore_feed_open(false);
    set_youtube_feed_open(false);
    set_email_feed_open(false);
    set_appstore_feed_open(false);
    set_reddit_feed_open(false);
    set_discord_feed_open(false);
    set_trustpilot_feed_open(false);
    set_pinterest_feed_open(false);

    set_glassdoor_feed_open(false);
    set_ambition_box_feed_open(false);
    set_whatsapp_feed_open(true);
    set_indeed_feed_open(false);
    set_telegram_feed_open(false);
    set_GMB_feed_open(false);
    set_linkedIn_feed_open(false);
    set_quora_feed_open(false);
    set_none_feed_open(false);
    set_consumer_feed_open(false);
  } else if (selected_platform === "Telegram") {
    set_twitter_feed_open(false);
    set_facebook_feed_open(false);
    set_instagram_feed_open(false);
    set_playstore_feed_open(false);
    set_youtube_feed_open(false);
    set_email_feed_open(false);
    set_appstore_feed_open(false);
    set_glassdoor_feed_open(false);
    set_ambition_box_feed_open(false);
    set_reddit_feed_open(false);
    set_discord_feed_open(false);
    set_trustpilot_feed_open(false);
    set_pinterest_feed_open(false);

    set_whatsapp_feed_open(false);
    set_indeed_feed_open(false);
    set_telegram_feed_open(true);
    set_GMB_feed_open(false);
    set_linkedIn_feed_open(false);
    set_quora_feed_open(false);
    set_consumer_feed_open(false);
    set_none_feed_open(false);
  } else if (selected_platform === "GMB") {
    set_twitter_feed_open(false);
    set_facebook_feed_open(false);
    set_instagram_feed_open(false);
    set_playstore_feed_open(false);
    set_youtube_feed_open(false);
    set_email_feed_open(false);
    set_appstore_feed_open(false);
    set_reddit_feed_open(false);
    set_discord_feed_open(false);
    set_trustpilot_feed_open(false);
    set_pinterest_feed_open(false);

    set_glassdoor_feed_open(false);
    set_ambition_box_feed_open(false);
    set_indeed_feed_open(false);
    set_whatsapp_feed_open(false);
    set_telegram_feed_open(false);
    set_GMB_feed_open(true);
    set_linkedIn_feed_open(false);
    set_quora_feed_open(false);
    set_consumer_feed_open(false);
    set_none_feed_open(false);
  } else if (selected_platform === "LinkedIn") {
    set_twitter_feed_open(false);
    set_facebook_feed_open(false);
    set_instagram_feed_open(false);
    set_playstore_feed_open(false);
    set_youtube_feed_open(false);
    set_email_feed_open(false);
    set_appstore_feed_open(false);
    set_reddit_feed_open(false);
    set_discord_feed_open(false);
    set_trustpilot_feed_open(false);
    set_pinterest_feed_open(false);

    set_glassdoor_feed_open(false);
    set_ambition_box_feed_open(false);
    set_whatsapp_feed_open(false);
    set_indeed_feed_open(false);
    set_telegram_feed_open(false);
    set_GMB_feed_open(false);
    set_linkedIn_feed_open(true);
    set_quora_feed_open(false);
    set_consumer_feed_open(false);
    set_none_feed_open(false);
  } else if (selected_platform === "Consumer Complaints") {
    set_twitter_feed_open(false);
    set_facebook_feed_open(false);
    set_instagram_feed_open(false);
    set_playstore_feed_open(false);
    set_youtube_feed_open(false);
    set_email_feed_open(false);
    set_appstore_feed_open(false);
    set_reddit_feed_open(false);
    set_discord_feed_open(false);
    set_trustpilot_feed_open(false);
    set_pinterest_feed_open(false);

    set_glassdoor_feed_open(false);
    set_ambition_box_feed_open(false);
    set_whatsapp_feed_open(false);
    set_indeed_feed_open(false);
    set_telegram_feed_open(false);
    set_GMB_feed_open(false);
    set_linkedIn_feed_open(false);
    set_quora_feed_open(false);
    set_consumer_feed_open(true);
    set_none_feed_open(false);
  } else if (selected_platform === "Quora") {
    set_twitter_feed_open(false);
    set_facebook_feed_open(false);
    set_instagram_feed_open(false);
    set_playstore_feed_open(false);
    set_youtube_feed_open(false);
    set_reddit_feed_open(false);
    set_discord_feed_open(false);
    set_trustpilot_feed_open(false);
    set_pinterest_feed_open(false);

    set_email_feed_open(false);
    set_appstore_feed_open(false);
    set_indeed_feed_open(false);
    set_glassdoor_feed_open(false);
    set_ambition_box_feed_open(false);
    set_whatsapp_feed_open(false);
    set_telegram_feed_open(false);
    set_GMB_feed_open(false);
    set_linkedIn_feed_open(false);
    set_quora_feed_open(true);
    set_consumer_feed_open(false);
    set_none_feed_open(false);
  } else if (selected_platform === "Trust Pilot") {
    set_twitter_feed_open(false);
    set_facebook_feed_open(false);
    set_instagram_feed_open(false);
    set_playstore_feed_open(false);
    set_youtube_feed_open(false);
    set_reddit_feed_open(false);
    set_discord_feed_open(false);
    set_trustpilot_feed_open(true);
    set_pinterest_feed_open(false);

    set_email_feed_open(false);
    set_appstore_feed_open(false);
    set_indeed_feed_open(false);
    set_glassdoor_feed_open(false);
    set_ambition_box_feed_open(false);
    set_whatsapp_feed_open(false);
    set_telegram_feed_open(false);
    set_GMB_feed_open(false);
    set_linkedIn_feed_open(false);
    set_quora_feed_open(false);
    set_consumer_feed_open(false);
    set_none_feed_open(false);
  } else if (selected_platform === "Pinterest") {
    set_twitter_feed_open(false);
    set_facebook_feed_open(false);
    set_instagram_feed_open(false);
    set_playstore_feed_open(false);
    set_youtube_feed_open(false);
    set_reddit_feed_open(false);
    set_discord_feed_open(false);
    set_trustpilot_feed_open(false);
    set_pinterest_feed_open(true);

    set_email_feed_open(false);
    set_appstore_feed_open(false);
    set_indeed_feed_open(false);
    set_glassdoor_feed_open(false);
    set_ambition_box_feed_open(false);
    set_whatsapp_feed_open(false);
    set_telegram_feed_open(false);
    set_GMB_feed_open(false);
    set_linkedIn_feed_open(false);
    set_quora_feed_open(false);
    set_consumer_feed_open(false);
    set_none_feed_open(false);
  } else if (selected_platform === "None" || selected_platform === "Telegram") {
    set_twitter_feed_open(false);
    set_facebook_feed_open(false);
    set_instagram_feed_open(false);
    set_playstore_feed_open(false);
    set_youtube_feed_open(false);
    set_email_feed_open(false);
    set_appstore_feed_open(false);
    set_indeed_feed_open(false);
    set_glassdoor_feed_open(false);
    set_ambition_box_feed_open(false);
    set_reddit_feed_open(false);
    set_discord_feed_open(false);
    set_trustpilot_feed_open(false);
    set_pinterest_feed_open(false);

    set_whatsapp_feed_open(false);
    set_telegram_feed_open(false);
    set_GMB_feed_open(false);
    set_linkedIn_feed_open(false);
    set_quora_feed_open(false);
    set_consumer_feed_open(false);
    set_none_feed_open(true);
  }
};

export const ReturnReport = (
  report,
  platform_list,
  descriptionCallback,
  activity_list,
  statusList,
  userList,
  detailedReport,
  dateFilter,
  filterClicked,
  setFilterClicked,
  DownloadClicked,
  setDownloadClicked,
  setexcelData,
  platform_selected,
  sentiment_selected,
  clearClicked,
  setclearClicked,
  setDetailReportOpen,
  setDetailReportId,
  detailReportOpen,
  type_value,
  set_type_value,
  boxHeight,
  activity_value,
  report_type_value,
  account_selected
) => {
  let report_id = report.report_id
    ? report.report_id
    : report.report_name
    ? JSON.parse(report.report_name).report_id
    : null;

  let dataGridHeight = { current: boxHeight };

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <ErrorBoundary message="Error in loading report">
        {report_id === "rBPdGowBpodn5_f5jeD00" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <AnimationDelayChart />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rBPdGowBpodn5_f5jeD01" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <BasicAreaChart />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rBPdGowBpodn5_f5jeD02" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <BasicSunburst />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rBPdGowBpodn5_f5jeD03" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <BridgeSummary dataGridHeight={dataGridHeight} />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rBPdGowBpodn5_f5jeD04" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <BridgeSummary />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rBPdGowBpodn5_f5jeD05" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <BubbleChart />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rBPdGowBpodn5_f5jeD06" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <ClickableColumnChart />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rBPdGowBpodn5_f5jeD07" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <DynamicData />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rBPdGowBpodn5_f5jeD08" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <LargescaleBarchart />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rBPdGowBpodn5_f5jeD09" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <NegativeMentionsStatic />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rBPdGowBpodn5_f5jeD10" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <Nightingale />

            <div className="pagebreak" />
          </Grid>
        )}
        {/* {report_id === "rBPdGowBpodn5_f5jeD11" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <PlatformWiseTicketTrend />

            <div className="pagebreak" />
          </Grid>
        )} */}
        {report_id === "rBPdGowBpodn5_f5jeD12" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <StaticPlatformWiseSentiment />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rBPdGowBpodn5_f5jeD13" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <StackedHorizontalBar />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rBPdGowBpodn5_f5jeD14" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <StaticTicketCategoryChart />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rBPdGowBpodn5_f5jeD15" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <StaticDialyMentionsChart />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rBPdGowBpodn5_f5jeD16" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <StaticDialySentimentTrend />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rBPdGowBpodn5_f5jeD17" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <StaticPlatformSentimentChart />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rBPdGowBpodn5_f5jeD18" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <StaticSubcategoryTrend />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rBPdGowBpodn5_f5jeD19" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <StaticTicketCategoryChart />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rBPdGowBpodn5_f5jeD20" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <StaticTicketSummaryChart />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rBPdGowBpodn5_f5jeD21" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <WordCloudStatic />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rBPdGowBpodn5_f5jeOH" && (
          <Grid
            lg={12}
            p={1}
            // sx={{ border: "1px solid grey" }}
            sx={{
              height: "100%",
            }}
          >
            <TicketSummary
              description={report.description}
              platformList={platform_list}
              setDetailReportOpen={setDetailReportOpen}
              setDetailReportId={setDetailReportId}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              detailReportOpen={detailReportOpen}

              //  detailedReport={detailedReport}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {/* {report_id === "rBPdGowBpodn5_f5jeOH" && (
          <Grid
            lg={12}
            p={1}
            // sx={{ border: "1px solid grey" }}
            sx={{
              height: "100%",
            }}
          >
            <TicketSummary
              description={report.description}
              platformList={platform_list}
              //  detailedReport={detailedReport}
            />

            <div className="pagebreak" />
          </Grid>
        )} */}
        {report_id === "rBPdGowBpodn5_f5jeOD" && (
          <Grid
            lg={12}
            p={1}
            // sx={{ border: "1px solid grey" }}
            sx={{
              height: "100%",
            }}
          >
            <TicketSummaryChartView
              description={report.description}
              platformList={platform_list}
              statusList={statusList}
              detailedReport={detailedReport}
              setDetailReportOpen={setDetailReportOpen}
              setDetailReportId={setDetailReportId}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              detailReportOpen={detailReportOpen}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "uxPdGowBpodn5_f5kuPX" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <TotalMentions
              platformList={platform_list}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />
          </Grid>
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M11" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <TotalMentions_mentions
              platformList={platform_list}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />
          </Grid>
        )}
        {report_id === "vBPdGowBpodn5_f5k-Mo" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <TotalMentionsChart
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              //description={report.description}
              platformList={platform_list}
              detailedReport={detailedReport}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M08" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <TotalMentionsChart_mentions
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              //description={report.description}
              platformList={platform_list}
              detailedReport={detailedReport}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "0hPgGowBpodn5_f5vuOU" && (
          <Grid
            lg={12}
            p={1}
            // sx={{ border: "1px solid grey" }}
          >
            <NegativeMentionsDispositionWise
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              filterValue={report.filter}
              platformList={platform_list}
              detailedReport={detailedReport}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "0xPgGowBpodn5_f5v-Of" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <NegativeMentionsDispositionWiseChart
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              filterValue={report.filter}
              detailedReport={detailedReport}
              platformList={platform_list}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "wRPdGowBpodn5_f5leNX" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <NegativeMentions
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M07" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <NegativeMentions_mentions
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "whPdGowBpodn5_f5leOg" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <NegativeMentionsChart
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M12" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <NegativeMentionsChart_mentions
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "1BPgGowBpodn5_f5weMf" && (
          <Grid
            lg={12}
            p={1}
            // sx={{ border: "1px solid grey" }}
          >
            <TotalNegativeMentions
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "1BPgGowBpodn5_f5weGH" && (
          <Grid
            lg={12}
            p={1}
            // sx={{ border: "1px solid grey" }}
          >
            <SentimentByCustomFields
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              dataGridHeight={dataGridHeight}
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              type_value={type_value}
              set_type_value={set_type_value}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "1RPgGowBpodn5_f5wuMn" && (
          <Grid
            lg={12}
            p={1}
            // sx={{ border: "1px solid grey" }}
          >
            <TotalNegativeMentionsChart
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "zBPgGowBpodn5_f5uONx" && (
          <Grid
            lg={12}
            p={1}
            // sx={{ border: "1px solid grey" }}
          >
            <EscalationsReports
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "Monthly Wise Platform Review Counts" && (
          <Grid
            lg={12}
            p={1}
            // sx={{ border: "1px solid grey" }}
          >
            <MonthlyWisePlatformReviewCounts
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "sBPdGowBpodn5_f5juPF" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // className="bg-success"
            // sx={{ border: "1px solid grey" }}
          >
            <ResponseOverview
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              activity_list={activity_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M09" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // className="bg-success"
            // sx={{ border: "1px solid grey" }}
          >
            <ResponseOverview_mentions
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              activity_list={activity_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "sBPdGowBpodn5_f5juPG" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // className="bg-success"
            // sx={{ border: "1px solid grey" }}
          >
            <ResponseOverviewChart
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              activity_list={activity_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M10" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // className="bg-success"
            // sx={{ border: "1px solid grey" }}
          >
            <ResponseOverviewChart_mentions
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              activity_list={activity_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "wBPdGowBpodn5_f5lONv" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <FRTEfficiencyAgentWise
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {/* {report_id === "wBPdGowBpodn5_f5lONW" && (
        <Grid
          lg={12}
          p={1}
          sx={{
            height: "100%",
          }}
          // sx={{ border: "1px solid grey" }}
        >
          
            <FRTEfficiencyAgentWiseChart
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              //callBack={props.descriptionCallback}
              //description={report.description}
              platformList={platform_list}
            />
         

          <div className="pagebreak" />
        </Grid>
      )} */}
        {report_id === "tRPdGowBpodn5_f5kOPx" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <AvgFRTPlatformWise
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "uRPdGowBpodn5_f5kuMT" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <PlatformWiseSentimentOverview
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M04" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <PlatformWiseSentimentOverview_mentions
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "1xPgGowBpodn5_f5xONj" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <CategoryWiseAvgFlr
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "zxPgGowBpodn5_f5u-OE" && (
          <Grid
            lg={12}
            p={1}
            // sx={{ border: "1px solid grey" }}
          >
            <CategoryWiseTatDistribution
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "zRPgGowBpodn5_f5ueN5" && (
          <Grid
            lg={12}
            p={1}
            // sx={{ border: "1px solid grey" }}
          >
            <CategoryWiseDistribution
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "zhPgGowBpodn5_f5uuNw" && (
          <Grid
            lg={12}
            p={1}
            // sx={{ border: "1px solid grey" }}
          >
            <CategoryWiseDistributionChart
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "1hPgGowBpodn5_f5w-Me" && (
          <Grid
            lg={12}
            p={1}
            // sx={{ border: "1px solid grey" }}
          >
            <CategoryWiseSentiments
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "0BPgGowBpodn5_f5vON5" && (
          <Grid
            lg={12}
            p={1}
            // sx={{ border: "1px solid grey" }}
          >
            <OverallShareOfVoice
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "0RPgGowBpodn5_f5veOg" && (
          <Grid
            lg={12}
            p={1}
            // sx={{ border: "1px solid grey" }}
          >
            <ShareOfVoiceChart
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "xRPdGowBpodn5_f5luOh" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <PlatformWiseOverallSentiment
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M01" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <PlatformWiseOverallSentiment_mentions
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {/* {report_id === "xhPdGowBpodn5_f5l-ME" && (
        <Grid
          lg={12}
          p={1}
          sx={{
            height: "100%",
          }}
          // sx={{ border: "1px solid grey" }}
        >
          
            <PlatformWiseOverallSentimentChart
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              //callBack={props.descriptionCallback}
              //description={report.description}
              platformList={platform_list}
            />
         

          <div className="pagebreak" />
        </Grid>
      )} */}
        {report_id === "vxPdGowBpodn5_f5lOMO" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}

            // sx={{ border: "1px solid grey" }}
          >
            <ChannelWiseSentimentDistribution
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "jgMSJSDnyodn5_f5k-M03" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}

            // sx={{ border: "1px solid grey" }}
          >
            <ChannelWiseSentimentDistribution_mentions
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "Ticket Closure TAT Breach" && (
          <Grid
            lg={12}
            p={1}
            // sx={{ border: "1px solid grey" }}
          >
            <TicketClouserTatBreach
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "shPdGowBpodn5_f5j-Os" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <TicketClouserTatBreachChart
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "vhPdGowBpodn5_f5k-I1" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <InstagramInsights
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              account_selected={account_selected}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "vhPdGowBpodn5_f5k-I4" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <FollowerDemographicsCountry
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              account_selected={account_selected}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "vhPdGowBpodn5_f5k-I5" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <FollowerDemographicsCity
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              account_selected={account_selected}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {/* {report_id === "qhPdGowBpodn5_f5jOPq" && (
        <Grid
          lg={12}
          p={1}
          sx={{
            height: "100%",
          }}
        >
          
            <TicketClosureFrtBreachChart
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              //callBack={props.descriptionCallback}
              //description={report.description}
              platformList={platform_list}
            />
         

          <div className="pagebreak" />
        </Grid>
      )} */}
        {report_id === "uhPdGowBpodn5_f5kuNa" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <SentimentOvertime
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "jgMSJSDnyodn5_f5k-M02" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <SentimentOvertime_mentions
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {/* done-------> */}
        {report_id === "txPdGowBpodn5_f5keN0" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <OverallAverageFRT
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "uBPdGowBpodn5_f5keOw" && (
          <Grid
            lg={12}
            p={1}
            sx={{ height: "100%" }}
            // sx={{ border: "1px solid grey" }}
          >
            <OverallAverageTAT
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "vRPdGowBpodn5_f5k-Ns" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <BridgeUsersAvgFRT
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "vRPdGowBpodn5_f5k-NT" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <BridgeUsersAvgFRTChart
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "vhPdGowBpodn5_f5k-Oq" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <BridgeUsersAvgTAT
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "vhPdGowBpodn5_f5k-I2" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <InstagramProfileSummary
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              account_selected={account_selected}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "vhPdGowBpodn5_f5k-I6" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <FollowersDemographicAge
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              account_selected={account_selected}
            />
          </Grid>
        )}
        {report_id === "vhPdGowBpodn5_f5k-OR" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <BridgeUsersAvgTATChart
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "vhPdBSDnyodn5_f5k-F1" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <FacebookProfileSummary
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              account_selected={account_selected}
              detailReportOpen={detailReportOpen}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "vhPdGowBpodn5_f5k-F3" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <FacebookInsights
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              account_selected={account_selected}
              detailReportOpen={detailReportOpen}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "vhPdGowBpodn5_f5k-F5" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <DailyPageFans
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              account_selected={account_selected}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "vhPdGowBpodn5_f5k-F7" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <PageReachBreakdownOvertime
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              account_selected={account_selected}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "vhPdGowBpodn5_f5k-F6" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <FollowersOverTime
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              account_selected={account_selected}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "vhPdGowBpodn5_f5k-F4" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <PostReachByType
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              account_selected={account_selected}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "vhPdGowBpodn5_f5k-O1" && (
          <Grid
            lg={12}
            p={1}
            // sx={{ border: "1px solid grey" }}
          >
            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "jgMSJSDnyodn5_f5k-Y1" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <YoutubeProfileSummary
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              account_selected={account_selected}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "jgMSJSDnyodn5_f5k-Y2" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <YoutubeViewsOvertime
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              account_selected={account_selected}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "jgMSJSDnyodn5_f5k-Y3" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <YoutubeChannelWatchOvertime
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              account_selected={account_selected}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "jgMSJSDnyodn5_f5k-Y4" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <YoutubeVideoEnagementOvertime
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              account_selected={account_selected}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "jgMSJSDnyodn5_f5k-Y5" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <YoutubeChannelViewsOvertime
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              account_selected={account_selected}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "jgMSJSDnyodn5_f5k-Y6" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <YoutubeSubscribersGainLostOvertime
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              account_selected={account_selected}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rRPdGowBpodn5_f5jePU" && (
          <Grid lg={12} p={1} style={{ height: "100%" }}>
            <HourlyTicketsResolved
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rRPdGowBpodn5_f5jePV" && (
          <Grid lg={12} p={1} style={{ height: "100%" }}>
            <PlatformwiseStatus
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              platformList={platform_list}
              statusList={statusList}
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rRPdGowBpodn5_f5jePW" && (
          <Grid lg={12} p={1} style={{ height: "100%" }}>
            <Platformwisetop10categories
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              platformList={platform_list}
              statusList={statusList}
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              platform_selected={platform_selected}
              sentiment_selected={sentiment_selected}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rRPdGowBpodn5_f5jePx" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <HourlylatformResolvedChart
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "thPdGowBpodn5_f5keM7" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <MentionsTrendWithEngagement
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M14" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <MentionsTrendWithEngagement_mentions
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "Instagram Message Summary" && (
          <Grid
            lg={12}
            p={1}
            // sx={{ border: "1px solid grey" }}
          >
            <InstagramMessageSummary
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "Instagram User Activity" && (
          <Grid
            lg={12}
            p={1}
            // sx={{ border: "1px solid grey" }}
          >
            <InstagramUserActivity
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "tBPdGowBpodn5_f5kOOZ" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <WordCloudReport
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              activity_value={activity_value}
              platform_selected={platform_selected}
              sentiment_selected={sentiment_selected}
              report_type_value={report_type_value}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "tBPdGowBpodn5_f5kOOZ2" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <TicketWordCloudReport
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              activity_value={activity_value}
              platform_selected={platform_selected}
              sentiment_selected={sentiment_selected}
              report_type_value={report_type_value}
            />

            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M15" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <WordCloudReport_mentions
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              activity_value={activity_value}
              platform_selected={platform_selected}
              sentiment_selected={sentiment_selected}
              report_type_value={report_type_value}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rxPdGowBpodn5_f5juNd" && (
          <Grid lg={12} p={1} sx={{ height: "100%" }}>
            <OverallTicketStatusUserwise
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              statusList={statusList}
              detailedReport={detailedReport}
              platformList={platform_list}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />
            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "rxPdGowBpodn5_f5juNE" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <OverallTicketStatusUserwiseChart
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              statusList={statusList}
              detailedReport={detailedReport}
              platformList={platform_list}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "rxPdGowBpodn5_f5TKC" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <TicketsByCategoryChart
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              statusList={statusList}
              userList={userList}
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              platform_list={platform_list}
            />

            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "rxPdGowBpodn5_f5STC" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <SentimentbyTicketsCategoryChart
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              statusList={statusList}
              userList={userList}
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              platform_list={platform_list}
            />

            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "rxPdGowBpodn5_f5NTC" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            <NegativeTicketsByCategoryChart
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              statusList={statusList}
              userList={userList}
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              platform_list={platform_list}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "yxPdGowBpodn5_f5mOPQ" && (
          <Grid lg={12} p={1} sx={{ height: "100%" }}>
            <TatOverview
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "yRPdGowBpodn5_f5mOMQ" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <OverallSentimentMentions
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />
            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M05" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <OverallSentimentMentions_mentions
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />
            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "yhPdGowBpodn5_f5mON9" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <SentimentAnalysisLast12monthsChart
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />
            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M06" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <SentimentAnalysisLast12monthsChart_mentions
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />
            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "xxPdGowBpodn5_f5l-NJ" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <PlatformWiseReport
              platformList={platform_list}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />
            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "sRPdGowBpodn5_f5j-Ma" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <RoleSummary
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              statusList={statusList}
              userList={userList}
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />
            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "sRPdGowBpodn5_f5j-MB" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <PlatformwiseTicketsByCategory
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              statusList={statusList}
              userList={userList}
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              platform_list={platform_list}
            />
            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "sRPdGowBpodn5_f5j-BM" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <PlatformwiseTicketsByCategoryChart
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              statusList={statusList}
              userList={userList}
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              platform_list={platform_list}
            />
            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "sRPdGowBpodn5_f5j-CM" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <PlatformwiseNegativeTicketsByCategoryChart
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              statusList={statusList}
              userList={userList}
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              platform_list={platform_list}
            />
            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "sRPdGowBpodn5_f5j-MC" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <PlatformwiseNegativeTicketsByCategory
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              statusList={statusList}
              userList={userList}
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              platform_list={platform_list}
            />
            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "sRPdGowBpodn5_f5j-MF" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <TicketsByCategory
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              statusList={statusList}
              userList={userList}
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              platform_list={platform_list}
            />
            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "sRPdGowBpodn5_f5j-MG" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <NegativeTicketsByCategory
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              statusList={statusList}
              userList={userList}
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              platform_list={platform_list}
            />
            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "sRPdGowBpodn5_f5j-MD" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <FrtTatAveragesByCategory
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              statusList={statusList}
              userList={userList}
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              platform_list={platform_list}
            />
            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "sRPdGowBpodn5_f5j-DM" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <FrtTatAveragesByCategoryChart
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              statusList={statusList}
              userList={userList}
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              platform_list={platform_list}
            />
            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "sRPdGowBpodn5_f5j-ME" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <SenrimentsByTicketsCategory
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              statusList={statusList}
              userList={userList}
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              platform_list={platform_list}
            />
            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "sRPdGowBpodn5_f5j-MH" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <SentimentOnCustomValue
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              statusList={statusList}
              userList={userList}
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              platform_list={platform_list}
              type_value={type_value}
              set_type_value={set_type_value}
            />
            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "sRPdGowBpodn5_f5j-My" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <FrtTatAveargesByCustomFields
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              statusList={statusList}
              userList={userList}
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              platform_list={platform_list}
              type_value={type_value}
              set_type_value={set_type_value}
            />
            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "sRPdGowBpodn5_f5j-Mz" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <TicketsByCustomFields
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              statusList={statusList}
              userList={userList}
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              platform_list={platform_list}
              type_value={type_value}
              set_type_value={set_type_value}
            />
            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "sRPdGowBpodn5_f5j-MI" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <NegativeCustomValueReport
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              statusList={statusList}
              userList={userList}
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
              platform_list={platform_list}
              type_value={type_value}
              set_type_value={set_type_value}
            />
            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "wxPdGowBpodn5_f5leP9" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <OverallSentimentMentions3months
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />
            <div className="pagebreak" />
          </Grid>
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M13" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <OverallSentimentMentions3months_mentions
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />
            <div className="pagebreak" />
          </Grid>
        )}
        {/* {report_id === "xBPdGowBpodn5_f5luNU" && (
        <Grid
          lg={12}
          p={1}
          sx={{
            height: "100%",
          }}
          // sx={{ border: "1px solid grey" }}
        >
          {" "}
          
            <OverallSentimentMentionChart3months
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
            />
         
          <div className="pagebreak" />
        </Grid>
      )} */}
        {report_id === "yBPdGowBpodn5_f5l-Or" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <TicketCategoryReport
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />
            <div className="pagebreak" />
          </Grid>
        )}
        {/* {report_id === "yBPdGowBpodn5_f5l-OS" && (
        <Grid
          lg={12}
          p={1}
          sx={{
            height: "100%",
          }}
          // sx={{ border: "1px solid grey" }}
        >
          {" "}
          
            <MultilevelTicketCategoryReport
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
            />
         
          <div className="pagebreak" />
        </Grid>
      )} */}
        {report_id === "smhWkclkeGjn5_ewk_01" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <ShareOfVoiceTrend
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              platformList={platform_list}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "smhWkclkeGjn5_ewk_02" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <SentimentwiseShareOfVoice
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "smhWkclkeGjn5_ewk_03" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <CompetitorSummaryReport
              filterValue={report.filter}
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
              dataGridHeight={dataGridHeight}
              openDateFilter={dateFilter}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              DownloadClicked={DownloadClicked}
              setDownloadClicked={setDownloadClicked}
              setexcelData={setexcelData}
              clearClicked={clearClicked}
              setclearClicked={setclearClicked}
            />

            <div className="pagebreak" />
          </Grid>
        )}
        {report_id === "Ticket Category - Last 3 Months Detailed Overview" && (
          <Grid
            lg={12}
            p={1}
            // sx={{ border: "1px solid grey" }}
          >
            {" "}
            <TicketCategoryDetailedReport
              report={
                report && report.report_name
                  ? JSON.parse(report.report_name).report_name
                  : report.report_id
                  ? getItemsById(report.report_id)[0]?.report_name
                  : ""
              }
              detailedReport={detailedReport}
            />
            <div className="pagebreak" />
          </Grid>
        )}
      </ErrorBoundary>
    </div>
  );
};

export let templateReports = {
  "Ticket Reports": [
    { report_id: "rBPdGowBpodn5_f5jeOH", report_name: "Ticket Summary" },
    { report_id: "rBPdGowBpodn5_f5jeOD", report_name: "Ticket Summary Chart" },
    {
      report_id: "uxPdGowBpodn5_f5kuPX",
      report_name: "Platform Mentions vs Negative Mentions (Last 3 Months)",
    },
    {
      report_id: "vBPdGowBpodn5_f5k-Mo",
      report_name:
        "Platform Mentions vs Negative Mentions (Last 3 Months) Chart",
    },

    { report_id: "zBPgGowBpodn5_f5uONx", report_name: "Escalation" },
    {
      report_id: "sBPdGowBpodn5_f5juPF",
      report_name: "Platform Interaction Overview",
    },
    {
      report_id: "sBPdGowBpodn5_f5juPG",
      report_name: "Platform Interaction Overview Chart",
    },
    {
      report_id: "tRPdGowBpodn5_f5kOPx",
      report_name: "Platformwise Avg FRT (Last 3 Months)",
    },
    {
      report_id: "yxPdGowBpodn5_f5mOPQ",
      report_name: "TAT Overview (Last 12 Months)",
    },
    {
      report_id: "zRPgGowBpodn5_f5ueN5",
      report_name: "Category Wise Distribution - Custom",
    },
    {
      report_id: "zhPgGowBpodn5_f5uuNw",
      report_name: "Category Wise Distribution - Custom - Chart View",
    },
    {
      report_id: "zxPgGowBpodn5_f5u-OE",
      report_name: "Category Wise TAT Distribution - Custom",
    },
    {
      report_id: "0BPgGowBpodn5_f5vON5",
      report_name: "Share of Voice - Brand vs Compititors Brand",
    },

    {
      report_id: "shPdGowBpodn5_f5j-Os",
      report_name: "Breach Status",
    },
    // {
    //   report_id: "qhPdGowBpodn5_f5jOPq",
    //   report_name: "FRT breach",
    // },

    {
      report_id: "0RPgGowBpodn5_f5veOg",
      report_name: "Share of Voice - Brand vs Compititors Brand (Chart view)",
    },
    {
      report_id: "rRPdGowBpodn5_f5jePU",
      report_name: "Hourly Platformwise Tickets Resolved",
    },

    {
      report_id: "rRPdGowBpodn5_f5jePV",
      report_name: "Platformwise Ticket Status",
    },

    {
      report_id: "rRPdGowBpodn5_f5jePW",
      report_name: "Platformwise Top 10 Categories",
    },

    {
      report_id: "rRPdGowBpodn5_f5jePx",
      report_name: "Hourly Platformwise Tickets Resolved Chart",
    },
    // { report_id: "tBPdGowBpodn5_f5kOOZ", report_name: "Word Cloud" },
    { report_id: "tBPdGowBpodn5_f5kOOZ2", report_name: "Word Cloud" },

    {
      report_id: "rxPdGowBpodn5_f5juNd",
      report_name: "Bridge User Ticket Status",
    },
    {
      report_id: "rxPdGowBpodn5_f5juNE",
      report_name: "Bridge User Ticket Status Chart",
    },

    {
      report_id: "xxPdGowBpodn5_f5l-NJ",
      report_name: "Platformwise Tickets (Last 3 Months)",
    },
    { report_id: "sRPdGowBpodn5_f5j-Ma", report_name: "Bridge User Summary" },
    {
      report_id: "yBPdGowBpodn5_f5l-Or",
      report_name: "Ticket Category (Last 3 Months)",
    },
    {
      report_id: "sRPdGowBpodn5_f5j-MB",
      report_name: "Platformwise Tickets by Category",
    },
    {
      report_id: "sRPdGowBpodn5_f5j-BM",
      report_name: "Platformwise Tickets by Category Chart",
    },

    {
      report_id: "sRPdGowBpodn5_f5j-MD",
      report_name: "FRT/TAT Averages by Category",
    },

    {
      report_id: "sRPdGowBpodn5_f5j-DM",
      report_name: "FRT/TAT Averages by Category Chart",
    },

    {
      report_id: "sRPdGowBpodn5_f5j-MF",
      report_name: "Tickets by Category",
    },
    {
      report_id: "rxPdGowBpodn5_f5TKC",
      report_name: "Tickets By Category Chart",
    },

    {
      report_id: "sRPdGowBpodn5_f5j-Mz",
      report_name: "Tickets by Custom Fields",
    },

    {
      report_id: "sRPdGowBpodn5_f5j-MH",
      report_name: "Platformwise Tickets by Custom Fields",
    },

    {
      report_id: "sRPdGowBpodn5_f5j-My",
      report_name: "FRT/TAT Averages by Custom Fields",
    },

    // {
    //   report_id: "yBPdGowBpodn5_f5l-OS",
    //   report_name: "Multilevel Ticket Category (Last 3 Months)",
    // },

    // comment
    // "Ticket Category - Last 3 Months Detailed Overview",
    // "Ticket Closure TAT Breach",
    // "Sentiment Analysis",
    // "Monthly Wise Platform Review Counts",
  ],
  "Sentimental Analysis Reports": [
    {
      report_id: "0hPgGowBpodn5_f5vuOU",
      report_name: "Negative Mentions Disposition Wise",
    },
    {
      report_id: "0xPgGowBpodn5_f5v-Of",
      report_name: "Negative Mentions Disposition Wise (Chart view)",
    },
    {
      report_id: "wRPdGowBpodn5_f5leNX",
      report_name: "Negative Mentions (Last 3 Months)",
    },
    {
      report_id: "whPdGowBpodn5_f5leOg",
      report_name: "Negative Mentions (Last 3 Months) Chart",
    },

    {
      report_id: "1BPgGowBpodn5_f5weMf",
      report_name: "Total Negative Mentions",
    },
    {
      report_id: "1BPgGowBpodn5_f5weGH",
      report_name: "Sentiment By Custom Fields",
    },
    {
      report_id: "1RPgGowBpodn5_f5wuMn",
      report_name: "Total Negative Mentions - Chart",
    },
    {
      report_id: "yRPdGowBpodn5_f5mOMQ",
      report_name: "Sentiment Analysis (Last 12 Months)",
    },

    {
      report_id: "yhPdGowBpodn5_f5mON9",
      report_name: "Sentiment Analysis (Last 12 Months) Chart",
    },
    {
      report_id: "uRPdGowBpodn5_f5kuMT",
      report_name: "Sentiment Overview by Platform Chart",
    },
    {
      report_id: "uhPdGowBpodn5_f5kuNa",
      report_name: "Monthly Sentiment Trend",
    },
    {
      report_id: "1hPgGowBpodn5_f5w-Me",
      report_name: "Category Wise Sentiments",
    },
    {
      report_id: "xRPdGowBpodn5_f5luOh",
      report_name: "Platform Sentiment Summary (Last 3 Months)",
    },
    // {
    //   report_id: "xhPdGowBpodn5_f5l-ME",
    //   report_name: "Platform Sentiment Summary (Last 3 Months) Chart",
    // },
    {
      report_id: "vxPdGowBpodn5_f5lOMO",
      report_name: "Sentiment Overview by Platforms",
    },
    {
      report_id: "thPdGowBpodn5_f5keM7",
      report_name: "Daily Sentiment Trend",
    },
    {
      report_id: "wxPdGowBpodn5_f5leP9",
      report_name: "Sentiment Analysis (Last 3 Months)",
    },

    {
      report_id: "sRPdGowBpodn5_f5j-MC",
      report_name: "Negative Tickets Platformwise by Category",
    },

    {
      report_id: "sRPdGowBpodn5_f5j-CM",
      report_name: "Negative Tickets Platformwise by Category Chart",
    },

    {
      report_id: "sRPdGowBpodn5_f5j-ME",
      report_name: "Sentiment by Tickets Category",
    },
    {
      report_id: "rxPdGowBpodn5_f5STC",
      report_name: "Sentiment By Tickets Category Chart",
    },
    {
      report_id: "sRPdGowBpodn5_f5j-MG",
      report_name: "Negative Tickets by Category",
    },
    {
      report_id: "rxPdGowBpodn5_f5NTC",
      report_name: "Negative Tickets By Category Chart",
    },

    {
      report_id: "sRPdGowBpodn5_f5j-MI",
      report_name: "Negative Tickets Platformwise by Custom Fields",
    },

    // {
    //   report_id: "xBPdGowBpodn5_f5luNU",
    //   report_name: "Sentiment Analysis (Last 3 Months) Chart",
    // },

    // comment
    // "Channel Wise Sentiment Distribution (Chart view)",
  ],
  "Agent Reports": [
    {
      report_id: "wBPdGowBpodn5_f5lONv",
      report_name: "FRT Efficiency (Last 3 Months)",
    },
    // {
    //   report_id: "wBPdGowBpodn5_f5lONW",
    //   report_name: "FRT Efficiency (Last 3 Months) Chart",
    // },
    {
      report_id: "1xPgGowBpodn5_f5xONj",
      report_name: "Category Wise Avg FRT & Avg Resolution Time",
    },
    {
      report_id: "txPdGowBpodn5_f5keN0",
      report_name: "Platforms Cumulative Avg FRT Overview",
    },
    {
      report_id: "uBPdGowBpodn5_f5keOw",
      report_name: "Platforms Cumulative Avg TAT Overview",
    },
    {
      report_id: "vRPdGowBpodn5_f5k-Ns",
      report_name: "Bridge User FRT Avg by Platform",
    },
    {
      report_id: "vRPdGowBpodn5_f5k-NT",
      report_name: "Bridge User FRT Avg by Platform Chart",
    },

    {
      report_id: "vhPdGowBpodn5_f5k-Oq",
      report_name: "Bridge User TAT Avg by Platform",
    },
    {
      report_id: "vhPdGowBpodn5_f5k-OR",
      report_name: "Bridge User TAT Avg by Platform Chart",
    },
    // {
    //   report_id: "vhPdGowBpodn5_f5k-O1",
    //   report_name: "Empty Field",
    // },
  ],
  // "Platform Insights Reports": [
  //   {
  //     report_id: "vhPdGowBpodn5_f5k-I1",
  //     report_name: "Instagram - Insights",
  //   },
  //   {
  //     report_id: "vhPdGowBpodn5_f5k-I2",
  //     report_name: "Instagram - Profile Summary",
  //   },
  //   {
  //     report_id: "vhPdGowBpodn5_f5k-I4",
  //     report_name: "Instagram - Follower Demographics Country",
  //   },
  //   {
  //     report_id: "vhPdGowBpodn5_f5k-I5",
  //     report_name: "Instagram - Follower Demographics City",
  //   },
  //   {
  //     report_id: "vhPdGowBpodn5_f5k-I6",
  //     report_name: "Instagram - Follower Demographics Age",
  //   },
  //   {
  //     report_id: "vhPdBSDnyodn5_f5k-F1",
  //     report_name: "Facebook - Profile Summary",
  //   },
  //   {
  //     report_id: "vhPdGowBpodn5_f5k-F3",
  //     report_name: "Facebook - Insights",
  //   },
  //   {
  //     report_id: "vhPdGowBpodn5_f5k-F4",
  //     report_name: "Facebook - Post Reach By Type",
  //   },
  //   {
  //     report_id: "vhPdGowBpodn5_f5k-F5",
  //     report_name: "Facebook - Daily Page Fans",
  //   },
  //   {
  //     report_id: "vhPdGowBpodn5_f5k-F6",
  //     report_name: "Facebook - Followers Overtime",
  //   },
  //   {
  //     report_id: "vhPdGowBpodn5_f5k-F7",
  //     report_name: "Facebook - Page Reach Breakdown Overtime",
  //   },
  //   {
  //     report_id: "jgMSJSDnyodn5_f5k-Y1",
  //     report_name: "Youtube - Profile Summary",
  //   },
  //   {
  //     report_id: "jgMSJSDnyodn5_f5k-Y2",
  //     report_name: "Youtube - Videos views Overtime",
  //   },
  //   {
  //     report_id: "jgMSJSDnyodn5_f5k-Y3",
  //     report_name: "Youtube - Channel Watchtime Overtime",
  //   },
  //   {
  //     report_id: "jgMSJSDnyodn5_f5k-Y4",
  //     report_name: "Youtube - Video Engagement Overtime",
  //   },
  //   {
  //     report_id: "jgMSJSDnyodn5_f5k-Y5",
  //     report_name: "Youtube - Channel Views Overtime",
  //   },
  //   {
  //     report_id: "jgMSJSDnyodn5_f5k-Y6",
  //     report_name: "Youtube - Subscribers Overtime",
  //   },
  // ],
  // "Competitor Analysis Reports": [
  //   {
  //     report_id: "smhWkclkeGjn5_ewk_01",
  //     report_name: "Share of Voice Trend",
  //   },
  //   {
  //     report_id: "smhWkclkeGjn5_ewk_02",
  //     report_name: "Share of Voice - Sentiment Wise",
  //   },
  //   {
  //     report_id: "smhWkclkeGjn5_ewk_03",
  //     report_name: "Competitor Summary Report",
  //   },
  // ],
};

export let templateReportsForSocialListeing = {
  // "Competitor Analysis Reports": [
  //   {
  //     report_id: "smhWkclkeGjn5_ewk_01",
  //     report_name: "Share of Voice Trend",
  //   },
  //   {
  //     report_id: "smhWkclkeGjn5_ewk_02",
  //     report_name: "Share of Voice - Sentiment Wise",
  //   },
  //   {
  //     report_id: "smhWkclkeGjn5_ewk_03",
  //     report_name: "Competitor Summary Report",
  //   },
  // ],

  "Mentions Report": [
    {
      report_id: "jgMSJSDnyodn5_f5k-M01",
      report_name: "Platform Sentiment Summary (Last 3 Months)",
    },
    {
      report_id: "jgMSJSDnyodn5_f5k-M02",
      report_name: "Monthly Sentiment Trend",
    },
    {
      report_id: "jgMSJSDnyodn5_f5k-M03",
      report_name: "Sentiment Overview by Platforms",
    },

    {
      report_id: "jgMSJSDnyodn5_f5k-M04",
      report_name: "Sentiment Overview by Platform Chart",
    },
    {
      report_id: "jgMSJSDnyodn5_f5k-M05",
      report_name: "Sentiment Analysis (Last 12 Months)",
    },
    {
      report_id: "jgMSJSDnyodn5_f5k-M06",
      report_name: "Sentiment Analysis (Last 12 Months) Chart",
    },
    {
      report_id: "jgMSJSDnyodn5_f5k-M07",
      report_name: "Negative Mentions (Last 3 Months)",
    },

    {
      report_id: "jgMSJSDnyodn5_f5k-M12",
      report_name: "Negative Mentions (Last 3 Months) Chart",
    },
    {
      report_id: "jgMSJSDnyodn5_f5k-M09",
      report_name: "Platform Interaction Overview",
    },
    {
      report_id: "jgMSJSDnyodn5_f5k-M10",
      report_name: "Platform Interaction Overview Chart",
    },
    {
      report_id: "jgMSJSDnyodn5_f5k-M11",
      report_name: "Platform Mentions vs Negative Mentions (Last 3 Months)",
    },
    {
      report_id: "jgMSJSDnyodn5_f5k-M08",
      report_name:
        "Platform Mentions vs Negative Mentions (Last 3 Months) Chart",
    },
    {
      report_id: "jgMSJSDnyodn5_f5k-M13",
      report_name: "Sentiment Analysis (Last 3 Months)",
    },
    {
      report_id: "jgMSJSDnyodn5_f5k-M14",
      report_name: "Daily Sentiment Trend",
    },
    {
      report_id: "jgMSJSDnyodn5_f5k-M15",
      report_name: "Mentions Word Cloud",
    },
  ],
};

// instagram_post_summary
// get_follower_demographics_country
// get_follower_demographics_city;
// get_follower_demographics_age

export let staticTemplateReports = {
  "Static Reports": [
    {
      report_id: "rBPdGowBpodn5_f5jeD00",
      report_name: "Cumulative Overview - FRT and TAT",
    },
    { report_id: "rBPdGowBpodn5_f5jeD01", report_name: "Weekly Mentions" },
    {
      report_id: "rBPdGowBpodn5_f5jeD02",
      report_name: "Platform Activity",
    },

    {
      report_id: "rBPdGowBpodn5_f5jeD03",
      report_name: "Bridge Summary",
    },

    {
      report_id: "rBPdGowBpodn5_f5jeD04",
      report_name: "Bridge Summary",
    },

    // {
    //   report_id: "rBPdGowBpodn5_f5jeD04",
    //   report_name: "Bridge Summary",
    // },

    {
      report_id: "rBPdGowBpodn5_f5jeD05",
      report_name: "Cumulative Tickets",
    },

    {
      report_id: "rBPdGowBpodn5_f5jeD06",
      report_name: "Bridge User - Tickets Resolved",
    },

    {
      report_id: "rBPdGowBpodn5_f5jeD07",
      report_name: "Ticket Status",
    },

    {
      report_id: "rBPdGowBpodn5_f5jeD08",
      report_name: "Negative Tickets",
    },

    {
      report_id: "rBPdGowBpodn5_f5jeD09",
      report_name: "Negative Mentions",
    },

    {
      report_id: "rBPdGowBpodn5_f5jeD10",
      report_name: "Breach Status",
    },
    {
      report_id: "rBPdGowBpodn5_f5jeD11",
      report_name: "PlatformWise Ticket Trend",
    },
    {
      report_id: "rBPdGowBpodn5_f5jeD12",
      report_name: "Platform-wise Tickets Trend",
    },
    {
      report_id: "rBPdGowBpodn5_f5jeD13",
      report_name: "Platform Interaction Overview",
    },
    {
      report_id: "rBPdGowBpodn5_f5jeD14",
      report_name: "Ticket Category",
    },
    {
      report_id: "rBPdGowBpodn5_f5jeD15",
      report_name: "Daily Mentions",
    },
    {
      report_id: "rBPdGowBpodn5_f5jeD16",
      report_name: "Dialy Sentiment Trend",
    },

    {
      report_id: "rBPdGowBpodn5_f5jeD17",
      report_name: "Platform Sentiment",
    },
    {
      report_id: "rBPdGowBpodn5_f5jeD18",
      report_name: "Subcategory Trend",
    },
    {
      report_id: "rBPdGowBpodn5_f5jeD189",
      report_name: "Static Ticket Category Chart",
    },
    {
      report_id: "rBPdGowBpodn5_f5jeD20",
      report_name: "Ticket Summary Chart",
    },
    {
      report_id: "rBPdGowBpodn5_f5jeD21",
      report_name: "WordCloudStatic",
    },
  ],
};

export const getItemsById = (reportId) => {
  // Flatten the arrays of items into a single array
  const allItems = [].concat(
    ...Object.values(
      window?.location?.pathname === "/socialListeningDiyReport" ||
        window?.location?.pathname === "/social_listen_dashboard"
        ? templateReportsForSocialListeing
        : templateReports
    )
  );

  // Find the items with the specified report_id
  const itemsWithId = allItems.filter((item) => item.report_id === reportId);

  return itemsWithId;
};

export const getStaticItemsById = (reportId) => {
  // Flatten the arrays of items into a single array
  const allItems = [].concat(...Object.values(staticTemplateReports));

  // Find the items with the specified report_id
  const itemsWithId = allItems.filter((item) => item.report_id === reportId);

  return itemsWithId;
};

export const selectedStaticReport = {
  _id: "demo_static_report_id",
  _index: "new_report_template",
  _score: null,
  _source: {
    created_by: {
      user_id: "25a7a0d2-4d85-4c9d-bfff-41fbcad8831f",
      user_name: "abhiram",
    },
    created_on: "2024-08-10T12:19:35.589971",
    default_report_template: false,
    modified_by: {},
    modified_on: "2024-08-10T12:19:35.589975",
    organization_id: {
      organization_id: "1",
      organization_name: "Default Organization",
    },
    report_template_name: "only ticket charts",
    template: {
      layout: {
        lg: [
          {
            w: 3,
            h: 12,
            x: 6,
            y: 48,
            i: "0",
            moved: false,
            static: false,
          },
          {
            w: 3,
            h: 12,
            x: 9,
            y: 24,
            i: "1",
            moved: false,
            static: false,
          },
          {
            w: 3,
            h: 12,
            x: 3,
            y: 24,
            i: "2",
            moved: false,
            static: false,
          },
          {
            w: 6,
            h: 15,
            x: 6,
            y: 60,
            i: "3",
            moved: false,
            static: false,
          },
          {
            w: 6,
            h: 12,
            x: 0,
            y: 0,
            i: "4",
            moved: false,
            static: false,
          },
          {
            w: 3,
            h: 12,
            x: 3,
            y: 36,
            i: "5",
            moved: false,
            static: false,
          },
          {
            w: 3,
            h: 12,
            x: 9,
            y: 48,
            i: "6",
            moved: false,
            static: false,
          },
          {
            w: 3,
            h: 12,
            x: 6,
            y: 24,
            i: "7",
            moved: false,
            static: false,
          },
          {
            w: 3,
            h: 12,
            x: 0,
            y: 48,
            i: "8",
            moved: false,
            static: false,
          },
          {
            w: 3,
            h: 12,
            x: 9,
            y: 0,
            i: "9",
            moved: false,
            static: false,
          },
          {
            w: 3,
            h: 12,
            x: 9,
            y: 36,
            i: "10",
            moved: false,
            static: false,
          },
          {
            w: 6,
            h: 15,
            x: 0,
            y: 60,
            i: "11",
            moved: false,
            static: false,
          },
          {
            w: 3,
            h: 12,
            x: 0,
            y: 36,
            i: "12",
            moved: false,
            static: false,
          },
          {
            w: 3,
            h: 12,
            x: 6,
            y: 0,
            i: "13",
            moved: false,
            static: false,
          },
          {
            w: 3,
            h: 12,
            x: 3,
            y: 48,
            i: "14",
            moved: false,
            static: false,
          },
          {
            w: 6,
            h: 12,
            x: 0,
            y: 12,
            i: "15",
            moved: false,
            static: false,
          },
          {
            w: 6,
            h: 12,
            x: 6,
            y: 12,
            i: "16",
            moved: false,
            static: false,
          },
          {
            w: 3,
            h: 12,
            x: 0,
            y: 24,
            i: "17",
            moved: false,
            static: false,
          },
          {
            w: 3,
            h: 12,
            x: 6,
            y: 36,
            i: "18",
            moved: false,
            static: false,
          },
        ],
        md: [
          {
            w: 5,
            h: 14,
            x: 0,
            y: 0,
            i: "0",
            moved: false,
            static: false,
          },
          {
            w: 5,
            h: 14,
            x: 5,
            y: 0,
            i: "1",
            moved: false,
            static: false,
          },
          {
            w: 5,
            h: 14,
            x: 5,
            y: 14,
            i: "2",
            moved: false,
            static: false,
          },
          {
            w: 5,
            h: 9,
            x: 0,
            y: 14,
            i: "3",
            moved: false,
            static: false,
          },
          {
            w: 5,
            h: 9,
            x: 0,
            y: 23,
            i: "4",
            moved: false,
            static: false,
          },
          {
            w: 5,
            h: 10,
            x: 5,
            y: 28,
            i: "5",
            moved: false,
            static: false,
          },
          {
            w: 5,
            h: 10,
            x: 0,
            y: 32,
            i: "6",
            moved: false,
            static: false,
          },
          {
            w: 5,
            h: 9,
            x: 5,
            y: 38,
            i: "7",
            moved: false,
            static: false,
          },
          {
            w: 5,
            h: 9,
            x: 0,
            y: 42,
            i: "8",
            moved: false,
            static: false,
          },
          {
            w: 5,
            h: 7,
            x: 5,
            y: 47,
            i: "9",
            moved: false,
            static: false,
          },
          {
            w: 5,
            h: 11,
            x: 0,
            y: 51,
            i: "10",
            moved: false,
            static: false,
          },
          {
            w: 5,
            h: 8,
            x: 5,
            y: 54,
            i: "11",
            moved: false,
            static: false,
          },
          {
            w: 5,
            h: 12,
            x: 0,
            y: 62,
            i: "12",
            moved: false,
            static: false,
          },
          {
            w: 5,
            h: 14,
            x: 5,
            y: 62,
            i: "13",
            moved: false,
            static: false,
          },
          {
            w: 5,
            h: 7,
            x: 0,
            y: 74,
            i: "14",
            moved: false,
            static: false,
          },
          {
            w: 5,
            h: 16,
            x: 5,
            y: 76,
            i: "15",
            moved: false,
            static: false,
          },
          {
            w: 5,
            h: 6,
            x: 0,
            y: 81,
            i: "16",
            moved: false,
            static: false,
          },
          {
            w: 5,
            h: 5,
            x: 0,
            y: 87,
            i: "17",
            moved: false,
            static: false,
          },
          {
            w: 5,
            h: 1,
            x: 0,
            y: 92,
            i: "18",
            moved: false,
            static: false,
          },
        ],
        sm: [
          {
            h: 12,
            i: "0",
            w: 3,
            x: 0,
            y: 6,
          },
          {
            h: 12,
            i: "1",
            w: 3,
            x: 0,
            y: 18,
          },
          {
            h: 12,
            i: "2",
            w: 3,
            x: 0,
            y: 30,
          },
          {
            h: 12,
            i: "3",
            w: 3,
            x: 0,
            y: 42,
          },
          {
            h: 12,
            i: "4",
            w: 3,
            x: 0,
            y: 54,
          },
          {
            h: 12,
            i: "5",
            w: 3,
            x: 0,
            y: 66,
          },
          {
            h: 12,
            i: "6",
            w: 3,
            x: 0,
            y: 78,
          },
          {
            h: 12,
            i: "7",
            w: 3,
            x: 0,
            y: 90,
          },
          {
            h: 12,
            i: "8",
            w: 3,
            x: 0,
            y: 102,
          },
          {
            h: 12,
            i: "9",
            w: 3,
            x: 0,
            y: 114,
          },
          {
            h: 12,
            i: "10",
            w: 3,
            x: 0,
            y: 126,
          },
          {
            h: 12,
            i: "11",
            w: 3,
            x: 0,
            y: 138,
          },
          {
            h: 12,
            i: "12",
            w: 3,
            x: 0,
            y: 150,
          },
          {
            h: 12,
            i: "13",
            w: 3,
            x: 0,
            y: 162,
          },
          {
            h: 12,
            i: "14",
            w: 3,
            x: 0,
            y: 174,
          },
          {
            h: 12,
            i: "15",
            w: 3,
            x: 0,
            y: 186,
          },
          {
            h: 12,
            i: "16",
            w: 3,
            x: 0,
            y: 198,
          },
          {
            h: 12,
            i: "17",
            w: 3,
            x: 0,
            y: 210,
          },
          {
            h: 12,
            i: "18",
            w: 3,
            x: 0,
            y: 234,
          },
        ],
        xs: [],
        xxs: [],
      },
      reports: [
        {
          id: 0,
          reportID: "rBPdGowBpodn5_f5jeD00",
        },
        {
          id: 1,
          reportID: "rBPdGowBpodn5_f5jeD01",
        },
        {
          id: 2,
          reportID: "rBPdGowBpodn5_f5jeD02",
        },
        {
          id: 3,
          reportID: "rBPdGowBpodn5_f5jeD03",
        },
        {
          id: 4,
          reportID: "rBPdGowBpodn5_f5jeD20",
        },
        {
          id: 5,
          reportID: "rBPdGowBpodn5_f5jeD05",
        },
        {
          id: 6,
          reportID: "rBPdGowBpodn5_f5jeD06",
        },
        {
          id: 7,
          reportID: "rBPdGowBpodn5_f5jeD07",
        },
        {
          id: 8,
          reportID: "rBPdGowBpodn5_f5jeD08",
        },
        {
          id: 9,
          reportID: "rBPdGowBpodn5_f5jeD09",
        },
        {
          id: 10,
          reportID: "rBPdGowBpodn5_f5jeD10",
        },
        {
          id: 11,
          reportID: "rBPdGowBpodn5_f5jeD21",
        },
        {
          id: 12,
          reportID: "rBPdGowBpodn5_f5jeD12",
        },
        {
          id: 13,
          reportID: "rBPdGowBpodn5_f5jeD13",
        },
        {
          id: 14,
          reportID: "rBPdGowBpodn5_f5jeD14",
        },
        {
          id: 15,
          reportID: "rBPdGowBpodn5_f5jeD15",
        },
        {
          id: 16,
          reportID: "rBPdGowBpodn5_f5jeD16",
        },
        {
          id: 17,
          reportID: "rBPdGowBpodn5_f5jeD17",
        },
        {
          id: 18,
          reportID: "rBPdGowBpodn5_f5jeD18",
        },
        {
          id: 19,
          reportID: "rBPdGowBpodn5_f5jeD19",
        },
      ],
    },
  },
  sort: [1723292375589],
};

export const returnPlatformDropdownLogos = (platform_name) => {
  let logo;

  switch (platform_name) {
    case "Ambition Box":
      logo = ambitionboxLogo;
      break;
    case "Appstore":
      logo = appStoreLogo;
      break;
    case "Consumer Complaints":
      logo = consumerComplaintsLogo;
      break;
    case "Discord":
      logo = discordLogo;
      break;
    case "Facebook":
      logo = facebookLogo;
      break;
    case "Glassdoor":
      logo = glassdoorLogo;
      break;
    case "GMB":
      logo = gmbLogo;
      break;
    case "Playstore":
      logo = playstoreLogo;
      break;
    case "Indeed":
      logo = indeedLogo;
      break;
    case "Instagram":
      logo = instagramLogo;
      break;
    case "LinkedIn":
      logo = linkedInLogo;
      break;
    case "Email":
      logo = emailLogo;
      break;
    case "Pinterest":
      logo = pinterestLogo;
      break;
    case "Quora":
      logo = quoraLogo;
      break;
    case "Reddit":
      logo = redditLogo;
      break;
    case "Telegram":
      logo = telegramLogo;
      break;
    case "Threads":
      logo = threadsLogo;
      break;
    case "Trust Pilot":
      logo = trustPilotLogo;
      break;
    case "Whatsapp":
      logo = whatsappLogo;
      break;
    case "Youtube":
      logo = youtubeLogo;
      break;
    case "Twitter":
      logo = twitterLogoWebp;
      break;
    case "None":
      logo = noneLogo;
      break;

    default:
      break;
  }

  return (
    <span title={platform_name}>
      {platform_name != "Youtube" ? (
        <img src={logo} style={{ margin: "10px", height: "20px" }} />
      ) : (
        // Reduce YouTube Logo Size
        <img
          src={logo}
          alt="YouTube Logo"
          style={{
            margin: "10px",
            maxWidth: "20px",
            maxHeight: "20px",
          }}
        />
      )}
    </span>
  );
};

// function for returning platform logos in the Platform onBoarding page
export const returnPlatformLogo = (platform_name) => {
  let logo;

  switch (platform_name) {
    case "Ambition Box":
      logo = ambitionboxLogo;
      break;
    case "Appstore":
      logo = appStoreLogo;
      break;
    case "Consumer Complaints":
      logo = consumerComplaintsLogo;
      break;
    case "Discord":
      logo = discordLogo;
      break;
    case "Facebook":
      logo = facebookLogo;
      break;
    case "Glassdoor":
      logo = glassdoorLogo;
      break;
    case "GMB":
      logo = gmbLogo;
      break;
    case "Playstore":
      logo = playstoreLogo;
      break;
    case "Indeed":
      logo = indeedLogo;
      break;
    case "Instagram":
      logo = instagramLogo;
      break;
    case "LinkedIn":
      logo = linkedInLogo;
      break;
    case "Email":
      logo = emailLogo;
      break;
    case "Pinterest":
      logo = pinterestLogo;
      break;
    case "Quora":
      logo = quoraLogo;
      break;
    case "Reddit":
      logo = redditLogo;
      break;
    case "Telegram":
      logo = telegramLogo;
      break;
    case "Threads":
      logo = threadsLogo;
      break;
    case "Trust Pilot":
      logo = trustPilotLogo;
      break;
    case "Whatsapp":
      logo = whatsappLogo;
      break;
    case "Youtube":
      logo = youtubeLogo;
      break;
    case "Twitter":
      logo = twitterLogoWebp;
      break;
    case "None":
      logo = noneLogo;
      break;

    default:
      break;
  }

  return (
    <span title={platform_name}>
      {platform_name != "Threads" ? (
        <img src={logo} style={{ margin: "10px", height: "45px" }} />
      ) : (
        <img
          src={logo}
          alt="YouTube Logo"
          style={{
            margin: "10px",
            maxWidth: "50px",
            maxHeight: "50px",
          }}
        />
      )}
    </span>
  );
};

// function for returning platform logos in the Ticket list page (Open details)
export const returnSmallPlatformLogos = (platform_name) => {
  let logo;

  switch (platform_name) {
    case "Ambition Box":
      logo = ambitionboxLogo;
      break;
    case "Appstore":
      logo = appStoreLogo;
      break;
    case "Consumer Complaints":
      logo = consumerComplaintsLogo;
      break;
    case "Discord":
      logo = discordLogo;
      break;
    case "Facebook":
      logo = facebookLogo;
      break;
    case "Glassdoor":
      logo = glassdoorLogo;
      break;
    case "GMB":
      logo = gmbLogo;
      break;
    case "Playstore":
      logo = playstoreLogo;
      break;
    case "Indeed":
      logo = indeedLogo;
      break;
    case "Instagram":
      logo = instagramLogo;
      break;
    case "LinkedIn":
      logo = linkedInLogo;
      break;
    case "Email":
      logo = emailLogo;
      break;
    case "Pinterest":
      logo = pinterestLogo;
      break;
    case "Quora":
      logo = quoraLogo;
      break;
    case "Reddit":
      logo = redditLogo;
      break;
    case "Telegram":
      logo = telegramLogo;
      break;
    case "Threads":
      logo = threadsLogo;
      break;
    case "Trust Pilot":
      logo = trustPilotLogo;
      break;
    case "Whatsapp":
      logo = whatsappLogo;
      break;
    case "Youtube":
      logo = youtubeLogo;
      break;
    case "Twitter":
      logo = twitterLogoWebp;
      break;
    case "None":
      logo = noneLogo;
      break;

    default:
      break;
  }

  return (
    <span title={platform_name}>
      <img src={logo} style={{ height: "15px" }} />
    </span>
  );
};

export const formatName = (name, fontWeight) => {
  const words = name.split(" ");

  const formattedWords = words.map((word, index) => (
    <span
      key={index}
      style={{
        fontWeight: fontWeight ? fontWeight : "normal",
        color: "#386b78",
      }}
    >
      <span style={{ color: theme.palette.sentimentIconColor.negative }}>
        {word.charAt(0)}
      </span>
      {word.slice(1)}{" "}
    </span>
  ));
  return formattedWords;
};

export const firstLetterOrange = (text) => {
  const words = text?.split(" ");
  const highlightedWords = words?.map((word, index) => {
    if (/^[A-Z]/.test(word)) {
      return (
        <>
          <span className="firstLetter">{word.charAt(0)}</span>
          {word.slice(1)}
        </>
      );
    } else if (word.charAt(0) === "(" && /^[A-Z]/.test(word.slice(1))) {
      return (
        <>
          {"("}
          <span className="firstLetter">{word.charAt(1)}</span>
          {word.slice(2, word.length)}
        </>
      );
    } else {
      return word;
    }
  });
  return (
    <Box>
      {highlightedWords?.map((element, index) => (
        <span key={index}>{element} </span>
      ))}
    </Box>
  );
};

export const prefixZero_for_singleDigit = (input) => {
  // Check if input is a string that starts with "0" and its length is 2
  if (
    typeof input === "string" &&
    input.length === 2 &&
    input.startsWith("0")
  ) {
    return input;
  }

  // Convert input to number
  const numberInput = Number(input);

  // Check if the number is 0
  if (numberInput === 0) {
    return "00";
  }

  // Check if the number is less than 10
  if (numberInput < 10) {
    return "0" + numberInput;
  }

  // Return the input as a string
  return input?.toString();
};
