import BaseReducer from "../../../../utilities/BaseReducer";
import RevenueReportErrorsAction from "./RevenueReportErrorsAction";

export default class RevenueReportErrorLogsReducer extends BaseReducer {
  initialState = {
    revenueReportErrorLogs: [],
  };
  [RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERROR_LOGS_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      revenueReportErrorLogs: action.payload,
    };
  }
}
