import {
  Box,
  Checkbox,
  Chip,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  ListItem,
  Slide,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, FieldArray, Form, Formik, getIn } from "formik";
import React, { useEffect, useState } from "react";
import CollectionsIcon from "@mui/icons-material/Collections";
import * as yup from "yup";
import { connect } from "react-redux";
import ReplyMessages from "../../../../components/replyMessages/ReplyMessages";
import TextArea from "antd/lib/input/TextArea";
import environment from "environment";
import jwtDecode from "jwt-decode";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { selectRequesting } from "../../../../../selectors/requesting/RequestingSelector";
import FacebookAction from "../../../../stores/facebook/FacebookAction";
import TicketsAction from "../../../../stores/tickets/TicketsAction";
import TwitterAction from "../../../../stores/twitterFeeds/TwitterAction";
import TwitterDirectMessageAction from "../../../../stores/linkedIn_account/twitterDirectMessage/TwitterDirectMessageAction";
import ReplyActions from "../../../../stores/platformAccounts/reply/ReplyActions";
import FacebookDmAction from "../../../../stores/facebook_dm/FacebookDmAction";
import YoutubeAction from "../../../../stores/youtube/YoutubeAction";
import InstagramFeedsAction from "../../../../stores/instagramFeeds/InstagramFeedsAction";
import InstagramDirectMessageAction from "../../../../stores/instagramDirectMessage/InstagramDirectMessageAction";
import EmailFeedsAction from "../../../../stores/emailFeeds/EmailFeedsAction";
import WhatsAppFeedAction from "../../../../stores/whatsappFeed/WhatsAppFeedAction";
import PlayStoreAction from "../../../../stores/playstore/PlayStoreAction";
import LinkedInFeedAction from "../../../../stores/linkedInFeed/LinkedInFeedAction";
import GmbReviewAction from "../../../../stores/gmbReviews/GmbReviewAction";
import MuiButton from "../../../../../components/muiButton/MuiButton";
import PlayStoreReplyAction from "../../../../stores/playStoreReply/PlayStoreReplyAction";
import { theme } from "../../../../../views/App";
import AutoCompleteMultiSelect from "../../../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import MuiTextField from "../../../../../components/muiTextField/MuiTextField";
import { axiosRequest } from "../../../../../utilities/FetchRequest";
import GmbQuestionFeedsAction from "../../../../stores/gmbQuestions/GmbQuestionFeedsAction";
import AppStoreAction from "../../../../stores/linkedInFeed/appstore/AppStoreAction";
// mapstateto props function fetching
let mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    FacebookAction.REQUEST_FACEBOOK_INNER_FILTER,
  ]),
  fbInnerFilter: state.fbInnerFilter.fbInnerFilter.data || {},
});
const ReplyComponent = (props) => {
  const [openMessages, setOpenMessages] = useState(false);
  const [message, setMessage] = useState(null);
  const [ResponseType, setResponseType] = useState(null);

  const [loading, setLoading] = useState(false);
  const [dm_loading, set_dm_loading] = useState(false);

  const [maxLength, setMaxLength] = useState(undefined);

  const returnVisibilityOfSendAsDM = () => {
    let boolean = true;
    if (props?.ticketData?._source?.platform?.platform_name === "Twitter") {
      if (props?.feedData?._index === "twitter_direct_messages") {
        boolean = false;
      }
      if (!props.feedData) {
        boolean = false;
      }
      if (
        props.directMessageDate === undefined ||
        (props.directMessageDate &&
          props.directMessageDate[0] &&
          !props.directMessageDate[0]._source)
      ) {
        boolean = false;
      }
    } else {
      boolean = false;
    }

    return boolean;
  };

  let initialMessage = {};

  if (
    (openMessages &&
      message &&
      props.feedData &&
      props.feedData._source &&
      !props.feedData._source.tweet_type) ||
    (openMessages && message && !props.feedData)
  ) {
    Object.assign(
      initialMessage,

      {
        message: message.content,
      },
      {
        responseType: ResponseType ? ResponseType : "",
      },

      {
        comment_image: "",
      },

      {
        boolean: false,
      },

      {
        account_name: props.ticketData?._source
          ? props.ticketData._source.account_name &&
            props.ticketData._source.account_name.account_name
          : "",
      },
      {
        send_dm_link: false,
      },
      {
        send_as_dm: false,
      },
      {
        taggedUsers:
          props.feedData &&
          props.feedData._source &&
          props.feedData._source.mentions &&
          props.feedData._source.mentions.length > 0
            ? props.feedData._source.mentions.map((item) => item.id)
            : [],
      }
    );
  } else {
    Object.assign(
      initialMessage,
      { message: "" },
      { subject: "" },
      { messages: [""] },
      {
        to:
          props.ticketData?._source &&
          props.ticketData._source.platform === "Email"
            ? props.ticketData._source.to
            : [],
      },
      {
        account_name: props.ticketData?._source
          ? props.ticketData._source.account_name &&
            props.ticketData._source.account_name.account_name
          : "",
      },
      { comment_image: "" },
      { boolean: false },
      { send_dm_link: false },
      { send_as_dm: false },
      { responseType: "" },
      {
        taggedUsers:
          props.feedData &&
          props.feedData._source &&
          props.feedData._source.mentions &&
          props.feedData._source.mentions.length > 0
            ? props.feedData._source.mentions.map((item) => item.id)
            : [],
      }
    );
  }

  function handleUserTagChange(event, setFieldValue, selected) {
    const value = event.target.value;

    if (selected.indexOf(value) !== -1) {
      // if value already present
      const newSelected = selected.filter((s) => s !== value);
      setFieldValue("taggedUsers", newSelected);
    } else {
      // if value not present
      setFieldValue("taggedUsers", [...selected, value]);
    }
  }

  useEffect(() => {
    setOpenMessages(false);
    setMessage(null);

    if (
      props.ticketData &&
      props.ticketData._source &&
      props.ticketData._source.platform &&
      props.ticketData._source.platform.platform_name === "Twitter"
    ) {
      if (props?.feedData?._index === "twitter") {
        setMaxLength(280);
      }
      if (props?.feedData?._index === "twitter_direct_messages") {
        setMaxLength(10000);
      }
    }
  }, []);

  const cancelFunction = (index, setFieldValue, values, resetForm) => {
    if (!props.ticketData) {
      resetForm();
      props.setShowReplyBox && props.setShowReplyBox(false);
    } else {
      resetForm();
      // props.setShowReplyBox(false);

      const fileInput = document.querySelector("input[type='file']");
      if (fileInput) {
        const newInput = fileInput.cloneNode(true); // Clone the input
        fileInput.replaceWith(newInput); // Replace old input with new one
      }

      // values.feed[index].emailOpen === true &&
      // setFieldValue(`feed[${index}].replyOpen`, !values.feed[index]?.replyOpen);

      props?.setReplyOpenStates((prev) => ({
        ...Object.keys(prev).reduce((acc, key) => {
          acc[key] = false; // Close all previous ones
          return acc;
        }, {}),
        [index]: false, // Open only the last clicked index
      }));
    }
  };

  const handleCannedMessages = (response) => {
    setOpenMessages(!response);

    response && setMessage(null);
  };

  const handlFileUpload = (value, setFieldValue, event) => {
    setFieldValue("boolean", true);

    // event.preventDefault();
    let formData = new FormData();
    formData.append("file", value);

    let access_tocken = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    fetch(`${environment.api.baseApi}/file_upload/add`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + access_tocken,
        // Accept: "application/json",
        // "Content-Type": "application/json",
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        setFieldValue("comment_image", response.url);
        setFieldValue("boolean", false);
      });
  };

  let statuses = [];
  statuses =
    props.statusList &&
    props.statusList.length > 0 &&
    props.statusList.filter((status) => status.initial_status !== true);

  const returnVisibleAttachment = () => {
    let boolean = true;

    if (
      props.ticketData?._source &&
      props.ticketData._source.platform.platform_name == "Instagram"
    ) {
      if (
        (props.feedData?._source?.activities &&
          props.feedData._source.activities === "Tagged Posts") ||
        (props.feedData?._source?.activities &&
          props.feedData._source.activities === "Comments") ||
        (props.feedData?._source?.activities &&
          props.feedData._source.activities === "Comment Mention") ||
        (props.feedData?._source?.activities &&
          props.feedData._source.activities === "Captions Mention")
      ) {
        boolean = false;
      }
    }

    return boolean;
  };

  return (
    <Box
      width={"100%"}
      // height={100}
      // className="bg-warning"
      sx={{ backgroundColor: "white", borderRadius: "4px" }}
    >
      <Box
        width={"100%"}
        // sx={{ padding: 2 }}
      >
        <Box width={"100%"}>
          <Formik
            initialValues={initialMessage}
            enableReinitialize={false}
            validate={(values) => {
              var errors = {};

              if (!values.responseType) {
                errors.responseType = "Response Type is Required";
              }

              if (
                (!values.message || !values.message.trim()) &&
                props.ticketData?._source?.platform?.platform_name !== "Twitter"
              ) {
                errors.message = "Please Enter a valid Response";
              }

              if (
                (!values.message || !values.message.trim()) &&
                props?.feedData?._index === "twitter_direct_messages" &&
                props.ticketData?._source?.platform?.platform_name === "Twitter"
              ) {
                errors.message = "Please Enter a valid Response";
              }

              if (
                props.ticketData?._source.platform.platform_name ===
                  "Twitter" &&
                values.messages &&
                ((props.feedData &&
                  props.feedData._source &&
                  props.feedData._source.tweet_type &&
                  props.feedData._source.tweet_type === "Tweet") ||
                  (props.feedData &&
                    props.feedData._source &&
                    props.feedData._source.tweet_type &&
                    props.feedData._source.tweet_type === "Reply") ||
                  (props.feedData &&
                    props.feedData._source &&
                    props.feedData._source.tweet_type &&
                    props.feedData._source.tweet_type === "Quote Tweet") ||
                  (props.feedData &&
                    props.feedData._source &&
                    props.feedData._source.tweet_type &&
                    props.feedData._source.tweet_type === "ReTweet"))
              ) {
                values.messages.map((item, index) => {
                  if (!item.length) {
                    errors.messages = "Please Enter a valid Response";
                  }
                });
              }

              // if (values.message.length === 0 || !values.message.trim()) {
              //   errors.message =
              //     "Response should be minimum of 1 character length";
              // }

              // if (
              //   props.ticketData._source.platform.platform_name === "Twitter"
              // ) {
              //   if (values.message.length > maxLength) {
              //     errors.message = `Response should not exceed ${maxLength} characters`;
              //   }
              // }

              return errors;
            }}
            onSubmit={(values, formikHelpers) => {
              const UpdateStatusCallback = (res) => {
                const auth = localStorage.getItem("Auth");

                let authData = jwtDecode(auth);

                const logsUpdater = () => {
                  const auth = localStorage.getItem("Auth");

                  let authData = jwtDecode(auth);

                  let post_obj_for_logs = {
                    ticket_id: props.ticketData._id,
                    description: `New reply is made by ${authData.preferred_username}`,
                  };
                };

                const statusUpdater = async () => {
                  let put_obj = {};

                  function formatDateToCustomFormat(date) {
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, "0");
                    const day = String(date.getDate()).padStart(2, "0");
                    const hours = String(date.getHours()).padStart(2, "0");
                    const minutes = String(date.getMinutes()).padStart(2, "0");
                    const seconds = String(date.getSeconds()).padStart(2, "0");
                    const milliseconds = String(
                      date.getMilliseconds()
                    ).padStart(6, "0");

                    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
                  }

                  // The below api call is to get the accurate time, not the system like new Date()
                  // const response = await fetch(
                  //   "https://worldtimeapi.org/api/timezone/Etc/UTC"
                  // );
                  // const data = await response.json();
                  // const accurateTime =
                  //   "error" in data ? new Date() : new Date(data.utc_datetime);

                  put_obj =
                    values.responseType.status_id ===
                    props.ticketData._source.status.status_id
                      ? {
                          first_response_message:
                            props.feedData &&
                            props.feedData._source &&
                            props.feedData._source.tweet_type
                              ? values.messages[0]
                              : values.message,
                          first_response_message_by: {
                            user_id: authData.sub,
                            user_name: authData.preferred_username,
                          },
                          first_response_message_date_time:
                            formatDateToCustomFormat(new Date()),
                          latest_response_to_user:
                            props.feedData &&
                            props.feedData._source &&
                            props.feedData._source.tweet_type
                              ? values.messages[0]
                              : values.message,
                        }
                      : {
                          status: values.responseType.status_id,
                          first_response_message:
                            props.feedData &&
                            props.feedData._source &&
                            props.feedData._source.tweet_type
                              ? values.messages[0]
                              : values.message,
                          first_response_message_by: {
                            user_id: authData.sub,
                            user_name: authData.preferred_username,
                          },
                          first_response_message_date_time:
                            formatDateToCustomFormat(new Date()),
                          latest_response_to_user:
                            props.feedData &&
                            props.feedData._source &&
                            props.feedData._source.tweet_type
                              ? values.messages[0]
                              : values.message,
                        };

                  let last_response_put_obj = {};

                  last_response_put_obj =
                    values.responseType.status_id ===
                    props.ticketData._source.status.status_id
                      ? {
                          latest_response_to_user:
                            props.feedData &&
                            props.feedData._source &&
                            props.feedData._source.tweet_type
                              ? values.messages[0]
                              : values.message,
                        }
                      : {
                          status: values.responseType.status_id,
                          latest_response_to_user:
                            props.feedData &&
                            props.feedData._source &&
                            props.feedData._source.tweet_type
                              ? values.messages[0]
                              : values.message,
                        };

                  let post_obj_for_logs = {
                    ticket_id: props.ticketData._id,
                    description: `New reply is made by ${authData.preferred_username}`,
                  };

                  const update_ticket_response_parameters = () => {
                    if (props.ticketData._source.first_response_message) {
                      props
                        .dispatch(
                          TicketsAction.requestPutTicket(
                            JSON.stringify(last_response_put_obj),
                            props.ticketData._id
                          )
                        )
                        .then(() => {
                          if (
                            values.responseType.status_id ===
                            props.ticketData._source.status.status_id
                          ) {
                            props.dispatch(
                              TicketsAction.requestTicketsById(
                                props.ticketData._id
                              )
                            );
                          } else {
                            props.fetchFunction();
                          }
                        });
                    } else {
                      props
                        .dispatch(
                          TicketsAction.requestPutTicket(
                            JSON.stringify(put_obj),
                            props.ticketData._id
                          )
                        )
                        .then(() => {
                          if (
                            values.responseType.status_id ===
                            props.ticketData._source.status.status_id
                          ) {
                            props.dispatch(
                              TicketsAction.requestTicketsById(
                                props.ticketData._id
                              )
                            );
                          } else {
                            props.fetchFunction();
                          }
                        });
                    }
                  };

                  update_ticket_response_parameters();
                };

                props?.setShowReplyBox && props?.setShowReplyBox(false);
                props?.setShowEmailBox && props.setShowEmailBox(false);

                setLoading(false);

                values.responseType !== undefined
                  ? statusUpdater()
                  : logsUpdater();

                props.setReplyClicked && props.setReplyClicked(false);
                props?.setReplyOpenStates((prev) => ({
                  ...Object.keys(prev).reduce((acc, key) => {
                    acc[key] = false; // Close all previous ones
                    return acc;
                  }, {}),
                  [props?.index]: false, // Open only the last clicked index
                }));
              };

              if (!props.ticketData) {
                setLoading(true);

                const allPlaystore = props.selectedRows.every(
                  (item) =>
                    item._source &&
                    item._source.platform &&
                    item._source.platform.platform_name === "Playstore"
                );

                const allGMB = props.selectedRows.every(
                  (item) =>
                    item._source &&
                    item._source.platform &&
                    item._source.platform.platform_name === "GMB"
                );

                if (allPlaystore) {
                  // console.log("All items are from Playstore.");

                  let post_obj = {
                    reply_message: values.message,
                    ticket_ids: props?.selectedRows?.map((rows) => {
                      return rows._id;
                    }),
                    ticket_status: values.responseType.status_id,
                  };

                  const replyCallBack = (callBackResponse) => {
                    // console.log("callBackResponse", callBackResponse);

                    if (callBackResponse) {
                      props.setOpenReplyErrorsDialog(true);
                      props.SetReplyLogs(callBackResponse);
                      props?.setShowReplyBox && props?.setShowReplyBox(false);
                    }
                  };

                  props
                    .dispatch(
                      PlayStoreReplyAction.requestPostPlayStoreBulkReply(
                        JSON.stringify(post_obj),
                        replyCallBack
                      )
                    )
                    .then(() => {
                      props.fetchFunction();
                      setLoading(false);
                      props.setSelectedRows([]);
                      props.set_Selected_tickets([]);
                      props.returnNoTicketsSelectedFunction &&
                        props.returnNoTicketsSelectedFunction();
                    });
                } else if (allGMB) {
                  let post_obj = {
                    reply_message: values.message,
                    ticket_ids: props?.selectedRows?.map((rows) => {
                      return rows._id;
                    }),
                    ticket_status: values.responseType.status_id,
                  };

                  const replyCallBack = (callBackResponse) => {
                    // console.log("callBackResponse", callBackResponse);

                    if (callBackResponse) {
                      props.setOpenReplyErrorsDialog(true);
                      props.SetReplyLogs(callBackResponse);
                      props.fetchFunction();
                      setLoading(false);
                      props.setSelectedRows([]);
                      props.set_Selected_tickets([]);
                      props.returnNoTicketsSelectedFunction &&
                        props.returnNoTicketsSelectedFunction();
                    }
                  };

                  props
                    .dispatch(
                      GmbReviewAction.requestGmbReviewBulkReplyPost(
                        JSON.stringify(post_obj),
                        replyCallBack
                      )
                    )
                    .then(() => {});
                }
              } else if (values.send_as_dm) {
                if (
                  props.ticketData &&
                  props.ticketData._source.platform.platform_name ===
                    "Twitter" &&
                  values.send_as_dm
                ) {
                  set_dm_loading(true);
                  let post_obj = {
                    message: values.messages[0],
                  };

                  let params = {
                    account_name:
                      props.ticketData && props.ticketData._source
                        ? props.ticketData._source.account_name
                        : null,
                    message_id:
                      props.directMessageDate &&
                      props.directMessageDate[0] &&
                      props.directMessageDate[0]._source.message_id,
                  };

                  let formData = new FormData();
                  formData.append("comment_text", values.message);

                  let account_id =
                    props.directMessageDate &&
                    props.directMessageDate[0] &&
                    props.directMessageDate[0]._source.sender_id;

                  props
                    .dispatch(
                      TwitterDirectMessageAction.requestTwitterDirectMessagePost(
                        account_id,
                        params,
                        JSON.stringify(post_obj),
                        UpdateStatusCallback
                      )
                    )
                    .then(() => {
                      let tweet_filter = [];

                      tweet_filter.push(
                        `[["tweet_id.keyword","must","","terms",${JSON.stringify(
                          props.ticketData._source.feed_id
                            ? props.ticketData._source.feed_id
                            : []
                        )}]]`
                      );
                      let param = {
                        filters: `${tweet_filter}`,
                        order_by: '[["created_on", "asc"]]',
                        page_limit: "none",
                      };
                      props.dispatch(TwitterAction.requestTwitter(param));

                      let dm_filter = [];

                      dm_filter.push(
                        `["feed_id.keyword","must","","match","${
                          props.ticketData._source.customer_id &&
                          props.ticketData._source.customer_id.platform &&
                          props.ticketData._source.customer_id.platform[0] &&
                          props.ticketData._source.customer_id.platform[0]
                            .account_id
                        }"],["account_name.keyword","must","","match","${
                          props.ticketData._source.account_name.account_name
                        }"],["project.project_id.keyword","must","","match","${
                          props.ticketData._source.project.project_id
                        }"]`
                      );

                      const params = {
                        filters: `[${dm_filter}]`,
                        page_limit: "none",
                        order_by: `[["created_on","asc"]]`,
                      };

                      props.dispatch(
                        TwitterDirectMessageAction.requestTwitterDirectMessage(
                          params
                        )
                      );
                      props?.setShowReplyBox && props?.setShowReplyBox(false);
                      props?.setShowEmailBox && props.setShowEmailBox(false);

                      set_dm_loading(false);
                    });
                }
              } else {
                if (
                  props.ticketData &&
                  props.ticketData._source.platform.platform_name ===
                    "Twitter" &&
                  ((props.feedData &&
                    props.feedData._source &&
                    props.feedData._source.tweet_type &&
                    props.feedData._source.tweet_type === "Tweet") ||
                    (props.feedData &&
                      props.feedData._source &&
                      props.feedData._source.tweet_type &&
                      props.feedData._source.tweet_type === "Reply") ||
                    (props.feedData &&
                      props.feedData._source &&
                      props.feedData._source.tweet_type &&
                      props.feedData._source.tweet_type === "Quote Tweet") ||
                    (props.feedData &&
                      props.feedData._source &&
                      props.feedData._source.tweet_type &&
                      props.feedData._source.tweet_type === "ReTweet"))
                ) {
                  setLoading(true);

                  async function handleDispatch(values) {
                    for (let [index, loop] of values.messages.entries()) {
                      let params = {
                        account_name: props.ticketData._source
                          ? props.ticketData._source.account_name.account_name
                          : null,
                      };

                      let excluded_users =
                        props.feedData &&
                        props.feedData._source &&
                        props.feedData._source.mentions &&
                        props.feedData._source.mentions.length > 0 &&
                        props.feedData._source.mentions
                          .filter((item) =>
                            values.taggedUsers.includes(item.id)
                          )
                          .map((item) => item.id);

                      let formData = new FormData();
                      formData.append("comment_text", loop);
                      index === values.messages.length - 1 &&
                        values.comment_image &&
                        formData.append("comment_image", values.comment_image);
                      excluded_users &&
                        excluded_users.length > 0 &&
                        formData.append(
                          "exclude_users",
                          excluded_users.join(",")
                        );

                      try {
                        await new Promise((resolve, reject) => {
                          props
                            .dispatch(
                              ReplyActions.requestPostReply(
                                props.feedData._id,
                                params,
                                formData,
                                index === values.messages.length - 1
                                  ? UpdateStatusCallback
                                  : null,
                                resolve,
                                setLoading
                              )
                            )
                            .then(() => {});
                        });
                      } catch (error) {
                        // Handle error if necessary
                        console.error(error);
                      }
                    }
                  }

                  handleDispatch(values).then(() => {
                    let tweet_filter = [];

                    tweet_filter.push(
                      `[["tweet_id.keyword","must","","terms",${JSON.stringify(
                        props.ticketData._source.feed_id
                          ? props.ticketData._source.feed_id
                          : []
                      )}]]`
                    );
                    let param = {
                      filters: `${tweet_filter}`,
                      order_by: '[["created_on", "asc"]]',
                      page_limit: "none",
                    };
                    props.dispatch(TwitterAction.requestTwitter(param));

                    let dm_filter = [];

                    dm_filter.push(
                      `["feed_id.keyword","must","","match","${
                        props.ticketData._source.customer_id &&
                        props.ticketData._source.customer_id.platform &&
                        props.ticketData._source.customer_id.platform[0] &&
                        props.ticketData._source.customer_id.platform[0]
                          .account_id
                      }"],["account_name.keyword","must","","match","${
                        props.ticketData._source.account_name.account_name
                      }"],["project.project_id.keyword","must","","match","${
                        props.ticketData._source.project.project_id
                      }"]`
                    );

                    const params = {
                      filters: `[${dm_filter}]`,
                      page_limit: "none",
                      order_by: `[["created_on","asc"]]`,
                    };

                    props.dispatch(
                      TwitterDirectMessageAction.requestTwitterDirectMessage(
                        params
                      )
                    );
                    props?.setShowReplyBox && props?.setShowReplyBox(false);
                    props?.setShowEmailBox && props.setShowEmailBox(false);
                    setLoading(false);
                  });
                }

                if (
                  props.ticketData &&
                  props.ticketData._source.platform.platform_name ===
                    "Twitter" &&
                  props.feedData?._index === "twitter_direct_messages"
                ) {
                  setLoading(true);

                  // let params = {
                  //   account_name: props.ticketData._source
                  //     ? props.ticketData._source.account_name.account_name
                  //     : null,
                  // };

                  let post_obj = {
                    message: values.message,
                  };

                  let params = {
                    account_name:
                      props.ticketData && props.ticketData._source
                        ? props.ticketData._source.account_name
                        : null,
                    message_id: props.feedData?._source?.message_id,
                  };

                  let formData = new FormData();
                  formData.append("comment_text", values.message);

                  let account_id = props.feedData?._source?.sender_id;

                  props
                    .dispatch(
                      TwitterDirectMessageAction.requestTwitterDirectMessagePost(
                        account_id,
                        params,
                        JSON.stringify(post_obj),
                        UpdateStatusCallback
                      )
                    )
                    .then(() => {
                      let tweet_filter = [];

                      tweet_filter.push(
                        `[["tweet_id.keyword","must","","terms",${JSON.stringify(
                          props.ticketData._source.feed_id
                            ? props.ticketData._source.feed_id
                            : []
                        )}]]`
                      );
                      let param = {
                        filters: `${tweet_filter}`,
                        order_by: '[["created_on", "asc"]]',
                        page_limit: "none",
                      };
                      props.dispatch(TwitterAction.requestTwitter(param));

                      let dm_filter = [];

                      dm_filter.push(
                        `["feed_id.keyword","must","","match","${
                          props.ticketData._source.customer_id &&
                          props.ticketData._source.customer_id.platform &&
                          props.ticketData._source.customer_id.platform[0] &&
                          props.ticketData._source.customer_id.platform[0]
                            .account_id
                        }"],["account_name.keyword","must","","match","${
                          props.ticketData._source.account_name.account_name
                        }"],["project.project_id.keyword","must","","match","${
                          props.ticketData._source.project.project_id
                        }"]`
                      );

                      const params = {
                        filters: `[${dm_filter}]`,
                        page_limit: "none",
                        order_by: `[["created_on","asc"]]`,
                      };

                      props.dispatch(
                        TwitterDirectMessageAction.requestTwitterDirectMessage(
                          params
                        )
                      );
                      props?.setShowReplyBox && props?.setShowReplyBox(false);
                      props?.setShowEmailBox && props.setShowEmailBox(false);
                      setLoading(false);
                    });
                }

                // Facebook Comments Reply method
                if (
                  props.ticketData._source &&
                  props.ticketData._source.platform.platform_name === "Facebook"
                ) {
                  if (props?.feedData?._index === "facebook_webhook") {
                    if (
                      props?.feedData?._source?.activities === "Post Mentions"
                    ) {
                      setLoading(true);

                      let id = props.feedData._source && props.feedData._id;

                      let post_obj = {
                        comment_text: values.message,
                      };

                      let formData = new FormData();
                      formData.append("comment_text", values.message);
                      values.comment_image &&
                        formData.append("comment_image", values.comment_image);

                      props
                        .dispatch(
                          FacebookAction.requestFacebookReplyPostMentions(
                            id,
                            formData,
                            UpdateStatusCallback
                          )
                        )
                        .then(() => {
                          let filter = [];

                          // Add _id filter
                          filter.push([
                            "_id",
                            "must",
                            "",
                            "terms",
                            props.ticketData._source.feed_id
                              ? props.ticketData._source.feed_id
                              : [],
                          ]);

                          // Add customer_id filter
                          filter.push([
                            "user_id.keyword",
                            "must",
                            "",
                            "term",
                            props.ticketData._source.customer_id
                              ? props.ticketData._source.customer_id
                                  .platform?.[0]?.account_id
                              : [],
                          ]);

                          // Add activities filter
                          filter.push([
                            "activities.keyword",
                            "must",
                            "",
                            "match",
                            "Post Mentions",
                          ]);

                          let params = {
                            filters: filter,
                            order_by: '[["created_on", "desc"]]',
                            page_limit: "none",
                          };
                          props.dispatch(
                            FacebookAction.requestFacebookPostMentions(params)
                          );

                          setLoading(false);
                        });
                    } else {
                      setLoading(true);

                      let id =
                        props.feedData._source &&
                        props.feedData._source.comment_id;

                      let post_obj = {
                        message: values.message,
                      };

                      let formData = new FormData();
                      formData.append("comment_text", values.message);
                      values.comment_image &&
                        formData.append("comment_image", values.comment_image);

                      props
                        .dispatch(
                          FacebookAction.requestFacebookReplyPost(
                            id,
                            formData,
                            UpdateStatusCallback
                          )
                        )
                        .then(() => {
                          let filter = [];

                          // Add comment_id filter
                          filter.push([
                            "comment_id.keyword",
                            "must",
                            "",
                            "terms",
                            props.ticketData._source.feed_id
                              ? props.ticketData._source.feed_id
                              : [],
                          ]);

                          // Add customer_id filter
                          filter.push([
                            "commented_user_id.keyword",
                            "must",
                            "",
                            "term",
                            props.ticketData._source.customer_id
                              ? props.ticketData._source.customer_id
                                  .platform?.[0]?.account_id
                              : [],
                          ]);

                          // Add activities filter
                          filter.push([
                            "activities.keyword",
                            "must_not",
                            "",
                            "match",
                            "Post Mentions",
                          ]);

                          let param = {
                            // comments: props.ticketData._source && props.ticketData._source.feed_id,
                            filters: filter,
                            page_limit: "none",
                          };
                          props.dispatch(FacebookAction.requestFacebook(param));
                          props?.setShowReplyBox &&
                            props?.setShowReplyBox(false);
                          props?.setShowEmailBox &&
                            props.setShowEmailBox(false);
                          setLoading(false);
                        });
                    }
                  }
                  if (props?.feedData?._index === "facebook_direct_messages") {
                    setLoading(true);

                    let account_id = props.feedData?._source?.sender_id;

                    let post_obj = {
                      message: values.message,
                      recipient: account_id,
                    };

                    let feedId = props.feedData?._id;

                    let formData = new FormData();
                    formData.append("comment_text", values.message);
                    formData.append("comment_image", values.comment_image);

                    props
                      .dispatch(
                        FacebookDmAction.requestFacebookMessagePost(
                          JSON.stringify(post_obj),
                          feedId,
                          UpdateStatusCallback
                        )
                      )
                      .then(() => {
                        function Callbackfunc(resp) {
                          let filter = [];

                          filter.push(
                            `[["conversation_id.keyword","must","","match",${JSON.stringify(
                              resp?.hits?.hits?.[0]?._source?.conversation_id
                                ? resp.hits.hits[0]._source.conversation_id
                                : ""
                            )}]]`
                          );

                          const params = {
                            filters: `${filter}`,
                            page_limit: "none",
                          };
                          props.dispatch(
                            FacebookDmAction.requestFacebookMessage(params)
                          );
                        }
                        let accessToken = JSON.parse(
                          localStorage.getItem("Auth")
                        ).access_token;

                        let API = `${
                          environment.api.facebook_dm
                        }?filters=[["message_id.keyword","must","","terms",${JSON.stringify(
                          props.ticketData._source.feed_id
                            ? props.ticketData._source.feed_id
                            : []
                        )}]]&page_limit=none`;

                        axiosRequest(API, {
                          method: "GET",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + accessToken,
                          },
                        }).then((response) => {
                          Callbackfunc(response);
                        });
                        props?.setShowReplyBox && props?.setShowReplyBox(false);
                        props?.setShowEmailBox && props.setShowEmailBox(false);
                        setLoading(false);
                      });
                  }
                }

                // Threads Reply method
                if (
                  props.ticketData._source &&
                  props.ticketData._source.platform.platform_name === "Threads"
                ) {
                  setLoading(true);

                  let id =
                    props.feedData._source && props.feedData._source.thread_id;

                  let formData = new FormData();
                  formData.append("content", values.message);
                  values.comment_image &&
                    formData.append("media", values.comment_image);

                  props
                    .dispatch(
                      InstagramFeedsAction.requestThreadsReplyPost(
                        id,
                        formData,
                        UpdateStatusCallback
                      )
                    )
                    .then(() => {
                      let filter = [];

                      filter.push(
                        `[["thread_id.keyword","must","","terms",${JSON.stringify(
                          props.ticketData._source.feed_id
                            ? props.ticketData._source.feed_id
                            : []
                        )}]]`
                      );

                      let param = {
                        filters: `${filter}`,
                        order_by: '[["created_on", "asc"]]',
                        page_limit: "none",
                      };

                      props.dispatch(
                        InstagramFeedsAction.requestThreads(
                          JSON.stringify(param)
                        )
                      );
                      props?.setShowReplyBox && props?.setShowReplyBox(false);
                      props?.setShowEmailBox && props.setShowEmailBox(false);
                      setLoading(false);
                    });
                }

                if (
                  props.ticketData &&
                  props.ticketData._source.platform.platform_name === "Youtube"
                ) {
                  setLoading(true);

                  let post_obj = {
                    message: values.message,
                  };

                  props
                    .dispatch(
                      YoutubeAction.requestYoutubePostReply(
                        props.feedData._id,
                        props.ticketData,
                        JSON.stringify(post_obj),
                        UpdateStatusCallback
                      )
                    )
                    .then(() => {
                      let filter = [];

                      filter.push(
                        `[["feed_id.keyword","must","","terms",${JSON.stringify(
                          props.ticketData._source.feed_id
                            ? props.ticketData._source.feed_id
                            : []
                        )}]]`
                      );
                      let params = { filters: `${filter}` };
                      props.dispatch(YoutubeAction.requestYoutube(params));
                      // props.setShowEmailBox(false);
                      setLoading(false);
                    });
                }

                if (
                  props.ticketData &&
                  props.ticketData._source.platform.platform_name ===
                    "Instagram"
                ) {
                  if (
                    props.feedData &&
                    props.feedData._source.activities === "Captions Mention"
                  ) {
                    setLoading(true);

                    let id = props.feedData._id;

                    props
                      .dispatch(
                        InstagramFeedsAction.requestInstagramCommentPost(
                          id,
                          JSON.stringify(values),
                          UpdateStatusCallback
                        )
                      )
                      .then(() => {
                        let filter = [];
                        filter.push(
                          `[["feed_id.keyword","must","","terms",${JSON.stringify(
                            props.ticketData._source.feed_id
                              ? props.ticketData._source.feed_id
                              : []
                          )}]]`
                        );
                        let params = { filters: `${filter}` };
                        props.dispatch(
                          InstagramFeedsAction.requestInstagramFeeds(params)
                        );
                        props.setShowReplyBox && props.setShowReplyBox(false);
                        props.setShowEmailBox && props.setShowEmailBox(false);
                        setLoading(false);
                      });
                  }

                  if (
                    (props.feedData &&
                      props.feedData._source.activities === "Comments") ||
                    (props.feedData &&
                      props.feedData._source.activities === "Comment Mentions")
                  ) {
                    setLoading(true);

                    let id = props.feedData._id;

                    let post_obj = {
                      message: values.message,
                    };

                    props
                      .dispatch(
                        InstagramFeedsAction.requestInstagramReplyPost(
                          id,
                          JSON.stringify(post_obj),
                          UpdateStatusCallback
                        )
                      )
                      .then(() => {
                        let filter = [];
                        filter.push(
                          `[["feed_id.keyword","must","","terms",${JSON.stringify(
                            props.ticketData._source.feed_id
                              ? props.ticketData._source.feed_id
                              : []
                          )}]]`
                        );
                        let params = { filters: `${filter}` };
                        props.dispatch(
                          InstagramFeedsAction.requestInstagramFeeds(params)
                        );
                        props?.setShowReplyBox && props?.setShowReplyBox(false);
                        props?.setShowEmailBox && props.setShowEmailBox(false);

                        setLoading(false);
                      });
                  }
                }

                if (
                  props.ticketData &&
                  props.ticketData._source.platform.platform_name ===
                    "Instagram" &&
                  props.feedData?._index === "instagram_direct"
                ) {
                  setLoading(true);

                  let feedId = props.feedData._id;

                  let post_obj = {
                    message: values.message,
                  };

                  props
                    .dispatch(
                      InstagramDirectMessageAction.requestInstagramDirectMessagePost(
                        JSON.stringify(post_obj),
                        feedId,
                        UpdateStatusCallback
                      )
                    )
                    .then(() => {
                      let filter = [];

                      filter.push(
                        `[["customer_username.keyword","must","","match","${
                          props.ticketData._source.customer_id &&
                          props.ticketData._source.customer_id.platform &&
                          props.ticketData._source.customer_id.platform[0] &&
                          props.ticketData._source.customer_id.platform[0]
                            .account_id
                        }"],["account_id.keyword","must","","match","${
                          props.ticketData._source &&
                          props.ticketData._source.account_name &&
                          props.ticketData._source.account_name.account_id
                        }"],["project.project_id.keyword","must","","match","${
                          props.ticketData._source.project.project_id
                        }"],["is_deleted","must_not","","match","true"]]`
                      );

                      const params = {
                        filters: `${filter}`,
                        page_limit: "none",
                        order_by: '[["created_on", "asc"]]',
                      };
                      props.dispatch(
                        InstagramDirectMessageAction.requestInstagramDirectMessage(
                          params
                        )
                      );
                    });
                }

                if (
                  props.ticketData._source &&
                  props.ticketData._source.platform.platform_name === "Email"
                ) {
                  setLoading(true);

                  let id = props.feedData && props.feedData._id;

                  let post_obj = {
                    body: values.message,
                    subject: values.subject,
                  };
                  props
                    .dispatch(
                      EmailFeedsAction.requestEmailFeedsPost(
                        id,
                        JSON.stringify(post_obj),
                        UpdateStatusCallback
                      )
                    )
                    .then(() => {
                      let filter = [];

                      filter.push(
                        `[["feed_id.keyword","must","","terms",${JSON.stringify(
                          props.ticketData._source.feed_id
                            ? props.ticketData._source.feed_id
                            : []
                        )}]]`
                      );
                      let params = {
                        filters: `${filter}`,
                        page_limit: "none",
                      };
                      props.dispatch(
                        EmailFeedsAction.requestEmailFeeds(params)
                      );
                      props?.setShowReplyBox && props?.setShowReplyBox(false);
                      props?.setShowEmailBox && props.setShowEmailBox(false);

                      setLoading(false);
                    });
                }

                if (
                  props.ticketData?._source?.platform?.platform_name ===
                    "Whatsapp" &&
                  props.feedData
                ) {
                  setLoading(true);

                  let feedId = props.feedData._id;

                  let post_obj = {
                    message: values.message,
                    type: "text",
                  };

                  props
                    .dispatch(
                      WhatsAppFeedAction.requestWhatsappPostMessage(
                        JSON.stringify(post_obj),
                        feedId,
                        UpdateStatusCallback
                      )
                    )
                    .then(() => {
                      let filter = [];

                      filter.push(
                        `[["customer_number.keyword","must","","match","${props.ticketData._source.customer_id.platform[0].account_id}"]]`
                      );

                      const params = {
                        filters: `${filter}`,
                        page_limit: "none",
                        order_by: '[["created_on", "asc"]]',
                      };
                      props.dispatch(
                        WhatsAppFeedAction.requestWhatsAppFeed(params)
                      );
                    });
                }

                if (
                  props.ticketData._source &&
                  props.ticketData._source.platform.platform_name ===
                    "Playstore"
                ) {
                  setLoading(true);

                  let post_obj = {
                    review_id: props.feedData?._source?.reviewId,
                    message: values.message,
                    account_id: props.feedData?._source?.account_id,
                    app_name: props.feedData?._source?.app_name,
                  };

                  props
                    .dispatch(
                      PlayStoreReplyAction.requestPostPlayStoreReply(
                        JSON.stringify(post_obj),
                        UpdateStatusCallback
                      )
                    )
                    .then(() => {
                      let filter = [];

                      filter.push(
                        `[["reviewId.keyword","must","","match","${
                          props.ticketData._source.feed_id
                            ? props.ticketData._source.feed_id
                            : null
                        }"]]`
                      );
                      let params = {
                        filters: `${filter}`,
                        page_limit: "none",
                        order_by: '[["review_created_at", "asc"]]',
                      };
                      props.dispatch(PlayStoreAction.requestPlayStore(params));
                      setLoading(false);
                    });
                }

                if (
                  props.ticketData._source &&
                  props.ticketData._source.platform.platform_name ===
                    "LinkedIn" &&
                  (props.feedData?._source?.activities === "Comment" ||
                    props.feedData?._source?.activities ===
                      "Post Mention Comments")
                ) {
                  setLoading(true);

                  let formData = new FormData();
                  formData.append("comment_text", values.message);
                  formData.append("comment_image", values.comment_image);

                  props
                    .dispatch(
                      LinkedInFeedAction.requestLinkedInReplyPost(
                        props.feedData._id,
                        formData,
                        UpdateStatusCallback
                      )
                    )
                    .then(() => {
                      let filter = [];

                      filter.push(
                        `[["customer_id.keyword","must","","term",${JSON.stringify(
                          props.ticketData._source.customer_id
                            ? props.ticketData._source.customer_id
                                ?.platform?.[0]?.account_id
                            : []
                        )}]]`
                      );

                      let params = { filters: `${filter}` };
                      props.dispatch(
                        LinkedInFeedAction.requestLinkedInFeed(params)
                      );

                      props?.setShowReplyBox && props?.setShowReplyBox(false);
                      props?.setShowEmailBox && props.setShowEmailBox(false);

                      setLoading(false);
                    });
                } else if (
                  props.ticketData._source &&
                  props.ticketData._source.platform.platform_name ===
                    "LinkedIn" &&
                  props.feedData._source.activities === "Post Mention"
                ) {
                  setLoading(true);

                  let formData = new FormData();
                  formData.append("comment_text", values.message);
                  formData.append("comment_image", values.comment_image);

                  props
                    .dispatch(
                      LinkedInFeedAction.requestLinkedInPostMentionReply(
                        props.feedData._id,
                        formData,
                        UpdateStatusCallback
                      )
                    )
                    .then(() => {
                      let filter = [];

                      filter.push(
                        `[["customer_id.keyword","must","","term",${JSON.stringify(
                          props.ticketData._source.customer_id
                            ? props.ticketData._source.customer_id
                                ?.platform?.[0]?.account_id
                            : []
                        )}]]`
                      );

                      let params = { filters: `${filter}` };
                      props.dispatch(
                        LinkedInFeedAction.requestLinkedInFeed(params)
                      );
                      props?.setShowReplyBox && props?.setShowReplyBox(false);
                      props?.setShowEmailBox && props.setShowEmailBox(false);

                      setLoading(false);
                    });
                }

                // gmb reply
                if (
                  props.ticketData._source &&
                  props.ticketData._source.platform.platform_name === "GMB"
                ) {
                  if (props.feedData._index === "gmb_review") {
                    setLoading(true);

                    let post_obj = {
                      message: values.message,
                    };
                    props
                      .dispatch(
                        GmbReviewAction.requestGmbReviewReplyPost(
                          props.feedData._source?.review_id,
                          JSON.stringify(post_obj),
                          UpdateStatusCallback
                        )
                      )
                      .then(() => {
                        let filter = [];

                        filter.push(
                          `[["review_id.keyword","must","","terms",${JSON.stringify(
                            props.ticketData._source
                              ? props.ticketData._source.feed_id
                              : []
                          )}]]`
                        );
                        let params = {
                          filters: `${filter}`,
                          order_by: '[["created_on", "asc"]]',
                          page_limit: "none",
                        };
                        props.dispatch(
                          GmbReviewAction.requestGmbReviewFeed(params)
                        );

                        let question_filter = [];
                        question_filter.push(
                          `[["_id","must","","terms",${JSON.stringify(
                            props.ticketData._source.feed_id
                              ? props.ticketData._source.feed_id
                              : []
                          )}]]`
                        );

                        let question_params = {
                          filters: `${question_filter}`,
                          order_by: '[["created_on", "asc"]]',
                          page_limit: "none",
                        };

                        props.dispatch(
                          GmbQuestionFeedsAction.requestGmbQuestionFeed(
                            question_params
                          )
                        );

                        props?.setShowReplyBox && props?.setShowReplyBox(false);
                        props?.setShowEmailBox && props.setShowEmailBox(false);

                        setLoading(false);
                      });
                  } else if (
                    props.feedData._index === "gmb_questions_answers"
                  ) {
                    setLoading(true);

                    let post_obj = {
                      answer: values.message,
                    };
                    props
                      .dispatch(
                        GmbReviewAction.requestGmbAnswerReplyPost(
                          props.feedData._source?.question_id,
                          JSON.stringify(post_obj),
                          UpdateStatusCallback
                        )
                      )
                      .then(() => {
                        let filter = [];

                        filter.push(
                          `[["review_id.keyword","must","","terms",${JSON.stringify(
                            props.ticketData._source
                              ? props.ticketData._source.feed_id
                              : []
                          )}]]`
                        );
                        let params = {
                          filters: `${filter}`,
                          order_by: '[["created_on", "asc"]]',
                        };
                        props.dispatch(
                          GmbReviewAction.requestGmbReviewFeed(params)
                        );

                        let question_filter = [];
                        question_filter.push(
                          `[["_id","must","","terms",${JSON.stringify(
                            props.ticketData._source.feed_id
                              ? props.ticketData._source.feed_id
                              : []
                          )}]]`
                        );

                        let question_params = {
                          filters: `${question_filter}`,
                          order_by: '[["created_on", "asc"]]',
                          page_limit: "none",
                        };

                        props.dispatch(
                          GmbQuestionFeedsAction.requestGmbQuestionFeed(
                            question_params
                          )
                        );
                        props?.setShowReplyBox && props?.setShowReplyBox(false);
                        props?.setShowEmailBox && props.setShowEmailBox(false);

                        setLoading(false);
                      });
                  }
                }

                // Telegram reply
                if (
                  props.ticketData._source &&
                  props.ticketData._source.platform.platform_name === "Telegram"
                ) {
                  setLoading(true);

                  let post_obj = {
                    message_text: values.message,
                    user_id: props.feedData?._source?.account_number,
                  };
                  let access_tocken = localStorage.getItem("Auth");

                  // Define the timeout duration in milliseconds
                  const timeoutDuration = 5000; // 5 seconds

                  // Start the fetch request
                  const fetchPromise = fetch(
                    `${environment.api.telegramApi}/send_message/${props.feedData?._id}`,
                    {
                      method: "POST",
                      headers: {
                        Authorization: "Bearer " + access_tocken,
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(post_obj),
                    }
                  );

                  // Create a promise that resolves after the timeout duration
                  const timeoutPromise = new Promise((resolve, reject) => {
                    setTimeout(() => {
                      reject(new Error("Request timed out"));
                    }, timeoutDuration);
                  });

                  // Execute both promises and handle the results
                  Promise.race([fetchPromise, timeoutPromise])
                    .then((response) => {
                      if (response) {
                        // Response received, proceed with the next function call
                        return response.json(); // or return any other data you need
                      } else {
                        // Handle the situation when there is no response from the server
                        throw new Error("No response from the server");
                      }
                    })
                    .then((data) => {
                      // Call the next function here using the received data (if needed)
                      // ...
                    })
                    .catch((error) => {
                      // Handle any errors that occurred during the fetch request or timeout
                      if (error) {
                        let filter = [];

                        filter.push(
                          `[["feed_id","must","","terms",${JSON.stringify(
                            props.ticketData._source.feed_id
                              ? props.ticketData._source.feed_id
                              : []
                          )}]]`
                        );

                        UpdateStatusCallback();
                        props?.setShowReplyBox && props?.setShowReplyBox(false);
                        props?.setShowEmailBox && props.setShowEmailBox(false);
                        setLoading(false);
                      }
                    });
                }

                // App store
                if (
                  props.ticketData &&
                  props.ticketData._source.platform.platform_name === "Appstore"
                ) {
                  setLoading(true);

                  let id = props.feedData._id;

                  let post_obj = {
                    message: values.message,
                    app_id: props?.feedData._source?.app_id,
                  };

                  props
                    .dispatch(
                      AppStoreAction.requestAppStoreReply(
                        post_obj,
                        UpdateStatusCallback,
                        id
                      )
                    )
                    .then(() => {
                      let filter = [];

                      filter.push(
                        `[["review_id.keyword","must","","terms",${JSON.stringify(
                          props.ticketData._source.feed_id
                            ? props.ticketData._source.feed_id
                            : []
                        )}]]`
                      );

                      let params = {
                        filters: `${filter}`,
                        order_by: '[["created_on", "desc"]]',
                        page_limit: "none",
                      };
                      props.dispatch(AppStoreAction.requestAppStore(params));
                    });
                }
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
              submitForm,
              resetForm,
            }) => (
              <Form onSubmit={handleSubmit}>
                <DialogContent
                  sx={{
                    // backgroundColor: "#f3f9fb",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    borderTop: `0.5px solid ${theme.palette.background.primary}`,
                    borderTopRightRadius: "4px",
                    borderTopLeftRadius: "4px",
                  }}
                  className="p-3 m-0"
                >
                  <Box width="100%">
                    <Box width={"100%"}>
                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        justifyContent="space-between"
                        alignItems={"flex-start"}
                      >
                        <Grid
                          item
                          xl={5.8}
                          container
                          lg={5.8}
                          md={5.8}
                          sm={5.8}
                          xs={5.8}
                          justifyContent="flex-start"
                          alignItems={"flex-start"}
                        >
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            // className="bg-success"
                          >
                            <span
                              style={{
                                color: `${theme.palette.text.primary}`,
                                fontSize: "13px",
                              }}
                            >
                              Response Type*
                            </span>
                          </Grid>

                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={6}
                            sm={6}
                            xs={6}
                            justifyContent="center"
                            alignItems="center"
                            mt={-2}
                            // className="bg-warning"
                          >
                            <Field
                              name="responseType"
                              component={AutoCompleteMultiSelect}
                              disableClearable={true}
                              label="responseType"
                              size="small"
                              value={
                                values.responseType ? values.responseType : null
                              }
                              options={statuses ? statuses : []}
                              // isOptionEqualToValue={(option, value) =>
                              //   option.status_name === value.status_name
                              // }
                              getOptionLabel={(option) =>
                                "Respond & " + option.status_name
                              }
                              textFieldProps={{
                                fullWidth: true,
                                margin: "normal",
                                variant: "outlined",
                                placeholder: "Response Type",
                              }}
                              dropDownFontColor={
                                theme.palette.ticketListView.iconColor
                              }
                            />
                          </Grid>
                        </Grid>

                        {props.ticketData && (
                          <Grid
                            item
                            xl={5.8}
                            container
                            lg={5.8}
                            md={5.8}
                            sm={5.8}
                            xs={5.8}
                            justifyContent="flex-start"
                            alignItems={"flex-start"}
                          >
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              // className="bg-success"
                            >
                              <span
                                style={{
                                  color: `${theme.palette.text.primary}`,
                                  fontSize: "13px",
                                }}
                              >
                                Response Handle
                              </span>
                            </Grid>

                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={6}
                              sm={6}
                              xs={6}
                              justifyContent="center"
                              alignItems="center"
                              mt={-2}
                              // className="bg-warning"
                            >
                              <Field
                                name="account_name"
                                component={AutoCompleteMultiSelect}
                                disableClearable={true}
                                label="account_name"
                                size="small"
                                options={[]}
                                readOnly={true}
                                diasbleSvg={"none"}
                                // isOptionEqualToValue={(option, value) =>
                                //   option.fullname === value.fullname
                                // }
                                getOptionLabel={(option) => option}
                                textFieldProps={{
                                  fullWidth: true,
                                  margin: "normal",
                                  variant: "outlined",
                                  placeholder: "Response Handle",
                                }}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Box>

                    <Box width="100%">
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        container
                        justifyContent={"flex-start"}
                        alignItems="center"
                        mb={0.5}
                        // className="bg-warning"
                      >
                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={8}
                          container
                          justifyContent={"flex-start"}
                          alignItems="center"
                        >
                          {openMessages && (
                            <ReplyMessages
                              onSetMessage={(value) => {
                                if (value != null) {
                                  setMessage(value);
                                  setResponseType(values.responseType);
                                  setFieldValue("message", value.content);
                                } else {
                                  setFieldValue(
                                    "responseType",
                                    values.responseType
                                  );
                                  // setResponseType(values.responseType);
                                  // setFieldValue("message", "");
                                }
                              }}
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Box>

                    {props.ticketData?._source &&
                      props.ticketData._source.platform === "Email" && (
                        <Grid
                          container
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          justifyContent="center"
                          alignItems="center"
                          my={0.5}
                          // className='bg-warning'
                        >
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={10}
                            sm={10}
                            xs={10}
                            // className='bg-info'
                            textAlign="right"
                          >
                            <Field
                              variant="outlined"
                              as={MuiTextField}
                              id="subject"
                              size="small"
                              name="subject"
                              placeholder="Subject"
                              error={
                                Boolean(errors.subject) &&
                                Boolean(touched.subject)
                              }
                              helperText={
                                Boolean(touched.subject) && errors.subject
                              }
                            />
                          </Grid>
                        </Grid>
                      )}

                    <Grid
                      item
                      container
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      justifyContent="space-between"
                      alignItems={"flex-start"}
                    >
                      {props.feedData &&
                      props.feedData._source &&
                      props.feedData._source.mentions &&
                      props.feedData._source.mentions.length > 0
                        ? props.feedData._source.mentions.map((option) => {
                            return (
                              <Grid
                                item
                                xl={4}
                                lg={6}
                                md={8}
                                sm={12}
                                container
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FormControlLabel
                                  sx={{
                                    color: theme.palette.primary.main,
                                    padding: 0,
                                    margin: 0,
                                  }}
                                  control={
                                    <Checkbox
                                      value={option.id}
                                      label={option.username}
                                      onChange={(e) => {
                                        handleUserTagChange(
                                          e,
                                          setFieldValue,
                                          values.taggedUsers
                                        );
                                      }}
                                      checked={
                                        !values.taggedUsers.includes(option.id)
                                      }
                                    />
                                  }
                                  label={option.username}
                                />{" "}
                              </Grid>
                            );
                          })
                        : null}

                      <Box
                        textAlign="left"
                        sx={{ color: theme.palette.secondary.main }}
                        px={2}
                      >
                        <ErrorMessage
                          style={{
                            textAlign: "left",
                          }}
                          name="taggedUsers"
                        />
                      </Box>
                    </Grid>

                    <Grid
                      container
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      justifyContent="center"
                      alignItems="center"
                      // className='bg-warning'
                      mt={1.5}
                    >
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={10}
                        sm={10}
                        xs={10}
                        // className='bg-info'
                        textAlign="center"
                      >
                        {((props.ticketData &&
                          props.ticketData._source.platform.platform_name !==
                            "Twitter") ||
                          !props.ticketData) && (
                          <Field
                            name="message"
                            as={TextArea}
                            placeholder="Write Reply"
                            className="textAreaFiled"
                            style={{
                              width: "99%",
                              height: 100,
                              borderRadius: "4px",
                              padding: 1,
                            }}
                            maxLength={maxLength}
                          />
                        )}

                        {props?.feedData?._index ===
                          "twitter_direct_messages" && (
                          <div>
                            <Field
                              name="message"
                              as={TextArea}
                              placeholder="Write Reply..."
                              className="textAreaFiled"
                              style={{
                                width: "99%",
                                height: 100,
                                borderRadius: "4px",
                                padding: 1,
                              }}
                              maxLength={maxLength}
                            />

                            {props.ticketData &&
                              props.ticketData._source &&
                              props.ticketData._source.platform &&
                              props.ticketData._source.platform
                                .platform_name === "Twitter" && (
                                <Box textAlign="left" px={2}>
                                  {maxLength - values.message.length} characters
                                  left
                                </Box>
                              )}
                          </div>
                        )}

                        {props.ticketData &&
                          props.ticketData._source.platform.platform_name ===
                            "Twitter" &&
                          ((props.feedData &&
                            props.feedData._source &&
                            props.feedData._source.tweet_type &&
                            props.feedData._source.tweet_type === "Tweet") ||
                            (props.feedData &&
                              props.feedData._source &&
                              props.feedData._source.tweet_type &&
                              props.feedData._source.tweet_type === "Reply") ||
                            (props.feedData &&
                              props.feedData._source &&
                              props.feedData._source.tweet_type &&
                              props.feedData._source.tweet_type ===
                                "Quote Tweet") ||
                            (props.feedData &&
                              props.feedData._source &&
                              props.feedData._source.tweet_type &&
                              props.feedData._source.tweet_type ===
                                "ReTweet")) && (
                            <div>
                              <FieldArray name="messages">
                                {({ push, remove }) => (
                                  <div>
                                    {values.messages.map((message, index) => (
                                      <div key={index}>
                                        <Field
                                          name={`messages.${index}`}
                                          render={({ field }) => (
                                            <TextArea
                                              style={{
                                                width: "99%",
                                                height: 100,
                                                borderRadius: "4px",
                                                padding: 1,
                                              }}
                                              placeholder="Write Reply..."
                                              {...field}
                                              className="textAreaFiled"
                                              maxLength={maxLength}
                                              onChange={(event) => {
                                                const inputValue =
                                                  event.target.value;
                                                // Check if the character limit is reached
                                                if (inputValue.length === 280) {
                                                  // Push a new empty message to the array
                                                  if (
                                                    index ===
                                                    values.messages.length - 1
                                                  ) {
                                                    push("");
                                                  }
                                                } else if (
                                                  inputValue.length < 280 &&
                                                  index <
                                                    values.messages.length - 1
                                                ) {
                                                  // Remove the next message if it's not the last one and the current message is below the character limit
                                                  remove(index + 1);
                                                }
                                                // Update the field value
                                                field.onChange(event);
                                              }}
                                            />
                                          )}
                                        />

                                        {props.ticketData &&
                                          props.ticketData._source &&
                                          props.ticketData._source.platform &&
                                          props.ticketData._source.platform
                                            .platform_name === "Twitter" && (
                                            <Box textAlign="left" px={2}>
                                              {maxLength -
                                                values.messages[index]
                                                  .length}{" "}
                                              characters left
                                            </Box>
                                          )}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </FieldArray>

                              <Box
                                textAlign="left"
                                sx={{ color: theme.palette.secondary.main }}
                                px={2}
                              >
                                <ErrorMessage
                                  style={{
                                    textAlign: "left",
                                  }}
                                  name="messages"
                                />
                              </Box>
                            </div>
                          )}

                        <Box
                          textAlign="left"
                          sx={{ color: theme.palette.secondary.main }}
                          px={2}
                        >
                          <ErrorMessage
                            style={{
                              textAlign: "left",
                            }}
                            name="message"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box width={"100%"} mt={1.5}>
                    <Grid
                      item
                      container
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      justifyContent="space-between"
                      alignItems={"flex-start"}
                      // className="bg-warning"
                    >
                      {
                        <Grid
                          item
                          // xl={2}
                          // lg={2}
                          // md={2}
                          // sm={2}
                          // xs={2}
                          // className="bg-success"
                        >
                          {returnVisibleAttachment() && (
                            <>
                              <ListItem className="m-0 p-0">
                                <CollectionsIcon
                                  style={{
                                    width: "13px",
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                    marginRight: 2,
                                  }}
                                />

                                <p
                                  className="m-0 p-0"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "13px",
                                  }}
                                  //   onClick={handleButtonClick}
                                >
                                  Attach Media
                                </p>
                              </ListItem>
                              <input
                                disabled={values.boolean}
                                type="file"
                                id="attachments"
                                accept="image/*"
                                // name="attachments"
                                // onChange={(e) => {
                                //   setFieldValue(
                                //     "attachments",
                                //     e.target.files[0]
                                //   );
                                // }}
                                // onChange={(e) => {
                                //   handlFileUpload(
                                //     e.target.files[0],
                                //     setFieldValue,
                                //     e
                                //   );
                                // }}
                                onChange={(e) => {
                                  setFieldValue(
                                    "comment_image",
                                    e.target.files[0]
                                  );
                                }}
                              />
                            </>
                          )}

                          {/* {values.comment_image && (
                          <Chip
                            label={
                              values.comment_image &&
                              values.comment_image.slice(-20)
                            }
                            onDelete={() => {
                              setFieldValue("comment_image", null);
                              document.querySelector(
                                "input[type='file']"
                              ).value = "";
                            }}
                            variant="outlined"
                            size="small"
                          />
                        )} */}
                        </Grid>
                      }

                      {props.ticketData?._source &&
                        props.ticketData._source.platform.platform_name ===
                          "Twitter" &&
                        props.feedData?._index !==
                          "twitter_direct_messages" && (
                          <Grid
                            item
                            // container
                            xl={3}
                            lg={4}
                            md={5.5}
                            sm={5.5}
                            xs={5.5}
                            justifyContent="center"
                            textAlign="center"
                            className="px-1"
                          >
                            <Grid item>
                              <FormControlLabel
                                sx={{
                                  color: theme.palette.primary.main,
                                  padding: 0,
                                  margin: 0,
                                }}
                                control={
                                  <Checkbox
                                    checked={values.send_dm_link}
                                    onChange={(e, val) => {
                                      val
                                        ? setFieldValue(
                                            `messages[${
                                              values.messages.length - 1
                                            }]`,
                                            values.messages[
                                              values.messages.length - 1
                                            ] +
                                              `\nhttps://twitter.com/messages/compose?recipient_id=${
                                                props.ticketData._source
                                                  .account_name &&
                                                props.ticketData._source
                                                  .account_name.account_id &&
                                                props.ticketData._source
                                                  .account_name.account_id
                                              }`
                                          )
                                        : setFieldValue(
                                            `messages[${
                                              values.messages.length - 1
                                            }]`,
                                            values.messages[
                                              values.messages.length - 1
                                            ].replace(
                                              `\nhttps://twitter.com/messages/compose?recipient_id=${
                                                props.ticketData._source
                                                  .account_name &&
                                                props.ticketData._source
                                                  .account_name.account_id &&
                                                props.ticketData._source
                                                  .account_name.account_id
                                              }`,
                                              ""
                                            )
                                          );

                                      setFieldValue("send_dm_link", val);
                                    }}
                                    sx={{ padding: 0, margin: 0 }}
                                  />
                                }
                                label={"Send DM Link"}
                              />
                            </Grid>
                          </Grid>
                        )}

                      {values.responseType && (
                        <Grid
                          item
                          // container
                          xl={2.8}
                          lg={3.8}
                          md={5.5}
                          sm={5.5}
                          xs={5.5}
                          justifyContent="center"
                          textAlign="center"
                          className="px-1"
                          sx={{
                            paddingY: "5px",
                            border: "solid 1px #9dc0c9;",
                            cursor: "pointer",
                            textAlign: "center",
                            backgroundColor: "white",
                            borderRadius: "5px",
                          }}
                          onClick={() => {
                            handleCannedMessages(openMessages);
                          }}
                        >
                          <p
                            className="m-0 p-0 text-center"
                            //   onClick={handleButtonClick}
                            align="center"
                            style={{
                              fontSize: "13px",
                            }}
                          >
                            {/* {props.ticketData._source.ticket_desc} */}
                            Canned Response
                          </p>
                        </Grid>
                      )}
                    </Grid>
                  </Box>

                  {
                    <Box width={"100%"} mt={2}>
                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        justifyContent="flex-end"
                        alignItems={"center"}
                        // rowSpacing={1}
                      >
                        <Grid
                          item
                          container
                          xl={6}
                          lg={9.5}
                          md={9}
                          sm={11}
                          xs={12}
                          justifyContent="flex-end"
                          textAlign="center"
                          // className="bg-success"
                          gap={2}
                        >
                          {props.ticketData?._source.platform.platform_name ===
                            "Twitter" &&
                            returnVisibilityOfSendAsDM() === false &&
                            props.feedData?._index !==
                              "twitter_direct_messages" && (
                              <Grid
                                item
                                xl={1}
                                lg={1}
                                md={1}
                                sm={1}
                                xs={1}
                                // justifyContent="center"
                                // alignItems="center"
                                // className="bg-warning"
                              >
                                <Typography fontSize={{ xl: 15, md: 10 }}>
                                  <IconButton
                                    className="p-0 m-0"
                                    title="You will get a Send as Direct Message Feature only if the ticket is having DM feed "
                                  >
                                    <ReportGmailerrorredIcon />
                                  </IconButton>
                                  {/* <span className="font-weight-bold">
                                Message Will be Disappears after 7 Days
                              </span> */}
                                </Typography>
                              </Grid>
                            )}

                          <Grid
                            item
                            xl={2.5}
                            lg={2.5}
                            md={2.5}
                            sm={3.5}
                            xs={3.5}
                            textAlign="center"
                            sx={{
                              border: "solid 1px #9dc0c9;",
                              cursor: "pointer",
                              backgroundColor: "white",
                              borderRadius: "4px",
                              width: "100%",
                            }}
                          >
                            <span
                              className="px-2"
                              style={{
                                color: "#39717c",
                                fontSize: "13px",
                              }}
                              onClick={() => {
                                cancelFunction(
                                  props.index,
                                  props.setFieldValue,
                                  props.values,
                                  resetForm
                                );
                              }}
                            >
                              Cancel
                            </span>
                          </Grid>

                          <Grid
                            item
                            xl={2.5}
                            lg={2.5}
                            md={2.5}
                            sm={3.5}
                            xs={3.5}
                            // justifyContent="center"
                            // alignItems="center"
                            // className="bg-warning"
                          >
                            <MuiButton
                              fontSize="13px"
                              variant="contained"
                              // width={10}
                              minWidth="100%"
                              height={"25px"}
                              name={"Send"}
                              loading={loading}
                              noTextOnLoading={true}
                              onClick={() => {
                                submitForm();
                              }}
                            />
                          </Grid>

                          {props.ticketData && returnVisibilityOfSendAsDM() && (
                            <Grid
                              item
                              xl={4}
                              lg={4}
                              md={5}
                              sm={5}
                              xs={5}
                              // justifyContent="center"
                              // alignItems="center"
                              // className="bg-warning"
                            >
                              <MuiButton
                                fontSize="13px"
                                variant="contained"
                                // width={10}
                                minWidth="100%"
                                height={"25px"}
                                name={"Send as DM"}
                                loading={dm_loading}
                                noTextOnLoading={true}
                                onClick={() => {
                                  submitForm();
                                  setFieldValue("send_as_dm", true);
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  }
                </DialogContent>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export default connect(mapStateToProps)(ReplyComponent);
