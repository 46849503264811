import React, { useEffect, useState } from "react";
import environment from "environment";
import { Box, Grid, ListItem, Tooltip, Typography } from "@mui/material";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { axiosRequest } from "../../../utilities/FetchRequest";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import ReactEcharts from "echarts-for-react";
import { theme } from "../../../views/App";
import { getRandomColor } from "../../../a3label_org_intranet/a3labels/views/cumulativeReports/ReportsReusedFunctions";
function NegativeTicketsByCategoryChart(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [negTickets, setNegTickets] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchPlatformwiseTicketsByCategory(props.DateFilter);
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.DateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchPlatformwiseTicketsByCategory(dateRange) {
    let API = `${
      environment.api.ticketReports
    }?filters=[["sentiment_value","must","lt","range","-0.0001"]]&report_type=["count"]&reports=["multilevel_category"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    try {
      const res = axiosRequest(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      const response = await res;
      if (response.hasOwnProperty("message")) {
        setNegTickets(null);
        setApiError(response.message);
      } else {
        setNegTickets(response);
        setApiError(null);
      }
    } catch (err) {
      setNegTickets(null);
      setApiError(err.message);
      setLoading(false);
    }
  }

  function flattenCategories(item, level = 0, parentRow = {}) {
    const currentRow = { ...parentRow };
    // currentRow[`CategoryLevel${level + 1}`] = `${item.value} (${item.count})`;
    currentRow[`CategoryLevel${level + 1}`] = `${item.value} (${item.count})`;

    if (!item.children || item.children.length === 0) {
      // If no children, push the row to updatedRows
      updatedRows.push({ id: updatedRows.length + 1, ...currentRow });
    } else {
      // If there are children, continue to flatten the structure recursively
      item.children.forEach((child) => {
        flattenCategories(child, level + 1, currentRow);
      });
    }
  }

  // Flatten the nested structure starting from the root categories
  const updatedRows = [];
  negTickets?.ticket_report?.multilevel_category[0]?.children?.forEach(
    (item) => {
      return flattenCategories(item);
    }
  );

  useEffect(() => {
    if (props.DownloadClicked) {
      function processDataForExport(data) {
        const allColumns = [
          ...new Set(data.flatMap((item) => Object.keys(item))),
        ]; // Collect all column names
        const lastSeenValues = {}; // Track last seen value for each column

        return data.map((item) => {
          const newItem = {};

          // Loop through each column name
          allColumns.forEach((key) => {
            if (key === "id") {
              newItem[key] = item[key]; // Always include 'id' if it exists
            } else {
              // If the value is the same as last seen, replace it with an empty string
              if (lastSeenValues[key] === item[key]) {
                newItem[key] = "";
              } else {
                // New or unique value: keep it and update last seen
                newItem[key] = item[key] || ""; // Fill missing columns with empty strings
                lastSeenValues[key] = item[key];
              }
            }
          });

          delete newItem.id;

          return newItem;
        });
      }

      props.setexcelData(processDataForExport(updatedRows));

      // props.setDownloadClicked(false); // Uncomment if you want to reset after download
    }
  }, [props.DownloadClicked, updatedRows]);
  function transformData(node) {
    // Create a new object for the transformed node
    const transformedNode = {};

    // Change value to name and count to value
    if (node.value !== undefined) {
      transformedNode.name = node.value;
    }
    if (node.count !== undefined) {
      transformedNode.value = node.count;
    }

    // Recursively transform children if they exist
    if (node.children && node.children.length) {
      transformedNode.children = node.children.map(transformData);
    } else {
      transformedNode.children = [];
    }

    // Preserve the key in the transformed node
    transformedNode.key = node.key;

    return transformedNode;
  }

  // Transform the data and store it in a new constant
  const transformedNegTickets = {
    ...negTickets,
    ticket_report: {
      ...negTickets?.ticket_report,
      multilevel_category:
        negTickets?.ticket_report?.multilevel_category.map(transformData),
    },
  };

  // Start transforming from the top level
  const getOption = () => {
    let shouldShowLegend = false;
    if (
      (props.ChartContainerHeight?.current &&
        props.ChartContainerWidth?.current &&
        props.ChartContainerHeight.current >= 350 &&
        props.ChartContainerWidth.current > 350) ||
      (props.ChartContainerHeight?.current === undefined &&
        props.ChartContainerWidth?.current === undefined)
    ) {
      shouldShowLegend = true;
    }
    function getLevelOption() {
      return [
        {
          itemStyle: {
            borderWidth: 0,
            gapWidth: 5,
          },
        },
        {
          itemStyle: {
            gapWidth: 1,
          },
        },
        {
          colorSaturation: [0.35, 0.5],
          itemStyle: {
            gapWidth: 1,
            borderColorSaturation: 0.6,
          },
        },
      ];
    }

    const colors = Object.values(theme.platform);

    const dataWithColors =
      transformedNegTickets?.ticket_report?.multilevel_category[0]?.children.map(
        (item, index) => {
          if (index >= colors.length) {
            colors.push(getRandomColor());
          }

          const color = colors[index];

          return {
            ...item,
            itemStyle: {
              color: color,
            },
          };
        }
      );

    return {
      tooltip: {
        trigger: "item",
      },

      series: [
        {
          name: transformedNegTickets?.ticket_report?.multilevel_category?.[0]
            ?.key,
          type: "treemap",

          label: {
            show: true,
            formatter: "{b}",
          },
          itemStyle: {
            borderColor: "#fff",
          },
          levels: getLevelOption(),
          data: dataWithColors,
        },
      ],
    };
  };

  return (
    <LoadingIndicator isActive={loading}>
      <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flex: "12",
        }}
      >
        {!loading ? (
          <>
            {apiError ? (
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  flex: "12",
                }}
              >
                {apiError}
              </Box>
            ) : !loading &&
              negTickets?.ticket_report?.multilevel_category?.length ? (
              <Box height="100%" width={"100%"}>
                <ReactEcharts
                  option={getOption()}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  notMerge={true}
                  lazyUpdate={true}
                />
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage width={"50%"} />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  );
}

export default NegativeTicketsByCategoryChart;
