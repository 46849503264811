import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import GoogleAlertConfigModel from "./model/GoogleAlertConfigModel";
import GoogleAlertConfigPutModel from "./GoogleAlertConfigPutModel";
import GoogleAlertConfigPostModel from "./GoogleAlertConfigPostModel";

// GoogleAlertConfigEffect
export default class GoogleAlertConfigEffect {
  // calling an API according to the Organization model
  // Get method for the
  static async requestGoogleAlertConfig(params) {
    const endpoint = environment.api.google_alerts_config;
    return EffectUtility.getToModel(GoogleAlertConfigModel, endpoint, params);
  }
  static async requestGoogleAlerts(params) {
    const endpoint = environment.api.google_alerts;
    return EffectUtility.getToModel(GoogleAlertConfigModel, endpoint, params);
  }
  // Get method for the Organization filter
  static async requestGoogleAlertConfigFilter(params) {
    const endpoint = environment.api.google_alerts_config;
    return EffectUtility.getToModel(GoogleAlertConfigModel, endpoint, params);
  }

  // put method for the Organization
  static async requestPutGoogleAlertConfig(data, id) {
    const endpoint = environment.api.google_alerts_config + "/" + id;
    return EffectUtility.putToModel(GoogleAlertConfigPutModel, endpoint, data);
  }

  // post method for the Organization
  static async requestPostGoogleAlertConfig(data) {
    const endpoint = environment.api.google_alerts_config;

    return EffectUtility.postToModel(
      GoogleAlertConfigPostModel,
      endpoint,
      data
    );
  }
}
