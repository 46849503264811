import React, { useEffect, useRef, useState } from "react";
import "./cssFiles/ResponseManagementPlatformCounts.css";
import environment from "environment";
import Logo from "./logoContainer/Logo";
import Description from "./descriptionContainer/Description";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { theme } from "../../../views/App";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { format, isValid } from "date-fns";
import ReactEcharts from "echarts-for-react";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";

function PostReachByType(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [insightsData, setInsightsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    setInsightsData(null);
    (async function CallApi() {
      setLoading(true);

      props.account_selected &&
        (await fetchData(props.account_selected, props.DateFilter));

      setLoading(false);
    })();
  }, [props.account_selected]);

  useEffect(() => {
    if (props.clearClicked) {
      setInsightsData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.account_selected, props.DateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      setInsightsData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.account_selected, props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.account_selected, props.filterClicked, props.openDateFilter]);

  async function fetchData(account_id, dateRange) {
    let postsummaryAPI = `${environment.api.facebookReportsummary}?account_id=${
      account_id?.account_id
    }&reports=["post_reach_by_type"]${
      props.DateFilter || props.filterClicked
        ? `&from_date=${dateRange[0]}&to_date=${dateRange[1]}`
        : `&from_date=${
            isValid(
              new Date(new Date().getFullYear(), new Date().getMonth(), 1)
            ) &&
            format(
              new Date(new Date().getFullYear(), new Date().getMonth(), 1),
              "yyyy-MM-dd"
            )
          }&to_date=${isValid(new Date()) && format(new Date(), "yyyy-MM-dd")}`
    }`;
    await axiosRequest(postsummaryAPI, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (
          response.hasOwnProperty("error") ||
          response.hasOwnProperty("error_id")
        ) {
          response.hasOwnProperty("error_id")
            ? setApiError(`${response?.message} - ${response?.error_id}`)
            : setApiError(response?.error);
          setInsightsData(null);
        } else {
          setInsightsData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setInsightsData(null);
        setApiError(err.message);
        setLoading(false);
      });
  }

  let NoDataFoundBool = !insightsData && !props.account_selected;

  // Calculate the height of each box based on the maximum length

  const isXl = useMediaQuery(() => theme.breakpoints.up("xl"));
  const isLg = useMediaQuery(() => theme.breakpoints.up("lg"));
  const isLaptop = useMediaQuery(() => theme.breakpoints.up("laptop"));
  const isMd = useMediaQuery(() => theme.breakpoints.up("md"));

  useEffect(() => {
    if (props.DownloadClicked) {
      const transformedArray = [
        {
          Organic:
            insightsData &&
            insightsData[0]?.post_reach_by_type
              ?.post_impressions_organic_unique,
          Paid:
            insightsData &&
            insightsData[0]?.post_reach_by_type?.post_impressions_paid_unique,
          Viral:
            insightsData &&
            insightsData[0]?.post_reach_by_type?.post_impressions_viral_unique,
        },
      ];

      props.setexcelData(transformedArray);
    }
  }, [props.DownloadClicked, insightsData]);

  // Define the option with the extracted data

  const formatValue = (value) => {
    if (value >= 1e6) {
      return (value / 1e6).toFixed(2) + "M";
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(2) + "K";
    }
    return value;
  };

  const getOption = () => {
    let shouldShowLegend = false;

    if (
      (props.ChartContainerHeight?.current &&
        props.ChartContainerWidth?.current &&
        props.ChartContainerHeight.current >= 350 &&
        props.ChartContainerWidth.current > 350) ||
      (props.ChartContainerHeight?.current === undefined &&
        props.ChartContainerWidth?.current === undefined)
    ) {
      shouldShowLegend = true;
    }

    const seriesData = [
      {
        name: "Organic",
        value:
          insightsData &&
          insightsData[0]?.post_reach_by_type?.post_impressions_organic_unique,
      },
      {
        name: "Viral",
        value:
          insightsData &&
          insightsData[0]?.post_reach_by_type?.post_impressions_viral_unique,
      },

      {
        name: "Paid",
        value:
          insightsData &&
          insightsData[0]?.post_reach_by_type?.post_impressions_paid_unique,
      },
    ];

    return {
      tooltip: {
        trigger: "item",
        confine: true,
        formatter: (params) => {
          let val = params.value;
          let formattedvalue = formatValue(val); // Apply your formatValue function
          const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${params.color};border-radius:50%;"></span>`;
          return `${colorSpan}${params.name}: ${formattedvalue}`;
        },
      },
      legend: {
        icon: "circle",
        show: shouldShowLegend,
        left: 0,
        orient: "vertical",
      },
      series: [
        {
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          padAngle: 5,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            focus: "series",
          },
          labelLine: {
            show: false,
          },
          data: seriesData,
          color: [
            `${theme.pieChart.color1}`,
            `${theme.pieChart.color2}`,
            `${theme.pieChart.color3}`,
          ], // You can adjust these colors based on the type of data
          itemStyle: {
            borderRadius: 10,
          },
        },
      ],
    };
  };

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <Box
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {!NoDataFoundBool ? (
            <Box height="100%" width={"100%"}>
              {apiError ? (
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    flex: "12",
                  }}
                >
                  {apiError}
                </Box>
              ) : (
                <Grid
                  height={"100%"}
                  width="100%"
                  container
                  justifyContent="space-between"
                  alignItems={"center"}
                  spacing={2}
                  textAlign={"center"}
                >
                  <Grid item height={"100%"} width="100%" md={12}>
                    <Box height="100%" width={"100%"}>
                      <ReactEcharts
                        option={getOption()}
                        notMerge={true}
                        lazyUpdate={true}
                        style={{
                          width: "100%",
                          height: "85%",
                        }}
                      />
                      <p className="font-weight-bold"></p>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Box>
          ) : (
            <NoDataFound />
          )}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage />
        </Box>
      )}
    </LoadingIndicator>
  );
}

export default PostReachByType;
