import { BaseModel } from "sjs-base-model";

export default class TelegramWsxReportsModal extends BaseModel {
  wsx_report = {};
  status = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
