import BaseReducer from "../../../utilities/BaseReducer";
import TicketsAction from "./TicketsAction";

export default class RelatedTicketsReduser extends BaseReducer {
  //initial state of ticket list
  initialState = {
    related_tickets: [],
  };

  //ticket request action finish
  [TicketsAction.REQUEST_RELATED_TICKETS_FINISHED](state, action) {
    return {
      ...state,
      related_tickets: action.payload,
    };
  }

  [TicketsAction.REQUEST_RELATED_TICKETS_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      related_tickets: action.payload,
    };
  }
}
