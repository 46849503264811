import { BaseModel } from "sjs-base-model";

// PromptCheckerPostModal
export default class PromptCheckerPostModal extends BaseModel {
  status = "";
  data = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
