import BaseReducer from "../../../../utilities/BaseReducer";
import TelegramDatasetsACtion from "./TelegramDatasetsACtion";

export default class TelegramDatasetsReduser extends BaseReducer {
  initialState = {
    telegram_datasets: [],
  };
  [TelegramDatasetsACtion.REQUEST_GET_TELEGRAM_DATASETS_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      telegram_datasets: action.payload,
    };
  }
}
