import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as yup from "yup";

import { connect } from "react-redux";
import PlatformAccountsAction from "../../stores/platformAccounts/PlatformAccountsAction";
import MuiButton from "../../../components/muiButton/MuiButton";
import AppStoreAddAccountAction from "../../stores/appstoreAddAccount/AppStoreAddAccountAction";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import AutoCompleteMultiSelect from "../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import { theme } from "../../../views/App";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const validationSchema = yup.object({
  app_name: yup
    .string("Enter App Name")
    .trim()
    .min(1, "App Name should be minimum 1 character in length")
    .required("App Name is required"),

  app_id: yup
    .string("Enter App Id")
    .trim()
    .min(1, "App Id should be minimum 1 character in length")
    .required("App Id is required"),

  method: yup.object().shape({}).required("Method Name is Required").nullable(),
});

function AddAppStoreAccount(props) {
  let initial = {};
  initial = {
    app_name: "",
    app_id: "",
    key_id: "",
    issuer_id: "",
    method: "",
    p8_file: "",
  };

  const [loading, setLoading] = useState(false);

  const RequiredText = () => {
    return (
      <span style={{ fontSize: "15px", color: theme.palette.text.error }}>
        P8 file is required
      </span>
    );
  };

  const methodArray = [
    { method_id: "RSS", method_name: "RSS" },
    { method_id: "API", method_name: "API" },
  ];

  const handleFileChange = (e, setFieldValue) => {
    const selectedFile = e.target.files[0];
    setFieldValue("p8_file", selectedFile);
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        validate={(values) => {
          var errors = {};
          if (
            values?.method &&
            values?.method?.method_id === "API" &&
            !values.p8_file
          ) {
            errors.issuer_id = <RequiredText />;
            errors.issuer_id = "Issuer Id is required";
            errors.key_id = "Key Id is required";
          }
          return errors;
        }}
        enableReinitialize
        validateOnBlur
        validateOnChange
        onSubmit={(values) => {
          setLoading(true);

          let formData = new FormData();

          formData.append("app_name", values?.app_name);
          formData.append("app_id", values?.app_id);
          formData.append("method", values?.method?.method_id);

          if (values?.method?.method_id === "API") {
            formData.append("p8_file", values?.p8_file);
            formData.append("issuer_id", values?.issuer_id);
            formData.append("key_id", values?.app_id);
          }

          props
            .dispatch(
              AppStoreAddAccountAction.requestPostAppstoreAccount(formData)
            )
            .then(() => {
              setLoading(false);

              let params = {
                page_limit: "none",
              };
              let account_params = {
                page_limit: "none",
                order_by: '[["created_on", "desc"]]',
              };
              props.onClose();
              props.setAdd_account("");
              props.dispatch(
                PlatformAccountsAction.requestPlatformAccount(account_params)
              );
              props.dispatch(
                PlatformAccountsAction.requestPlatformAccountFilter(params)
              );
            });
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
          setFieldValue,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ marginLeft: "15%", width: "75%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-10">
                      <h3 className="dailogtext">
                        {!props.edit ? (
                          <b>
                            <span className="span_first_letter">A</span>
                            dd &nbsp;
                            <span className="span_first_letter">A</span>ppStore
                            &nbsp;
                            <span className="span_first_letter">A</span>ccount
                            &nbsp;
                          </b>
                        ) : (
                          <b>
                            <span className="span_first_letter">E</span>
                            dit &nbsp;
                            <span className="span_first_letter">P</span>roject
                            &nbsp;
                          </b>
                        )}
                      </h3>
                    </div>
                    <div
                      className="col-2 closebtn"
                      onClick={() => {
                        props.onClose();
                        props.setAdd_account("");
                      }}
                    >
                      <i
                        title="Close"
                        className="fa fa-times-circle-o fa-2x"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>

              <Grid container>
                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    App Name :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      id="app_name"
                      size="small"
                      name="app_name"
                      placeholder="App Name"
                      error={
                        Boolean(errors.app_name) && Boolean(touched.app_name)
                      }
                      helperText={Boolean(touched.app_name) && errors.app_name}
                    />
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} mt={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    App ID :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      id="app_id"
                      size="small"
                      name="app_id"
                      placeholder="App ID"
                      error={Boolean(errors.app_id) && Boolean(touched.app_id)}
                      helperText={Boolean(touched.app_id) && errors.app_id}
                    />
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} container mt={0}>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={3}>
                    Method :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      name="method"
                      size="small"
                      // Calling AutoCompleteMultiSelect Component
                      value={values.method ? values.method : null}
                      component={AutoCompleteMultiSelect}
                      options={methodArray ? methodArray : []}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.method_name === value.method_name
                      }
                      getOptionLabel={(option) => option.method_name}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Method",
                      }}
                    />
                  </Grid>
                </Grid>{" "}
                {values?.method?.method_id === "API" ? (
                  <Grid item lg={12} md={12} sm={12} mt={1} container>
                    <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                      Issuer ID :
                    </Grid>
                    <Grid item lg={5} md={5} sm={5}>
                      <Field
                        variant="outlined"
                        as={MuiTextField}
                        id="issuer_id"
                        size="small"
                        name="issuer_id"
                        placeholder="Issuer ID"
                        error={
                          Boolean(errors.issuer_id) &&
                          Boolean(touched.issuer_id)
                        }
                        helperText={
                          Boolean(touched.issuer_id) && errors.issuer_id
                        }
                      />
                    </Grid>
                  </Grid>
                ) : null}
                {values?.method?.method_id === "API" ? (
                  <Grid item lg={12} md={12} sm={12} mt={1} container>
                    <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                      Key ID :
                    </Grid>
                    <Grid item lg={5} md={5} sm={5}>
                      <Field
                        variant="outlined"
                        as={MuiTextField}
                        id="key_id"
                        size="small"
                        name="key_id"
                        placeholder="Key ID"
                        error={
                          Boolean(errors.key_id) && Boolean(touched.key_id)
                        }
                        helperText={Boolean(touched.key_id) && errors.key_id}
                      />
                    </Grid>
                  </Grid>
                ) : null}
                {values?.method?.method_id === "API" ? (
                  <Grid item lg={12} md={12} sm={12} container mt={1}>
                    <Grid item lg={3} md={3} sm={3} ml={10} mt={0}>
                      P8 File :
                    </Grid>
                    <Grid item lg={5} md={5} sm={5}>
                      <Box>
                        <input
                          type="file"
                          accept=".p8"
                          onChange={(e, v) => {
                            handleFileChange(e, setFieldValue);
                          }}
                          style={{ marginBottom: "10px" }}
                        />

                        <Box>
                          <ErrorMessage name="p8_file" />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Add Account "}
                      type="submit"
                      loading={loading}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Edit Project "}
                      type="submit"
                      width="100%"
                    />
                  )}
                </Grid>

                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Reset"}
                      type="reset"
                      onClick={Formik.resetForm}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                        Formik.resetForm();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default connect(mapDispatchToProps)(AddAppStoreAccount);
