import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import FacebookDmDataModel from "./models/FacebookDmDataModel";
import FacebookDmPostModel from "./models/FacebookDmPostModel";

//Category effect
export default class FacebookDmEffect {
  // calling an API according to the TwitterDirectmessage
  static async requestFacebookMessage(data, Callbackfunc) {
    const endpoint = environment.api.facebook_dm;
    let response = EffectUtility.postToModel(
      FacebookDmDataModel,
      endpoint,
      JSON.stringify(data)
    );
    if (response && Callbackfunc) {
      response.then((resp) => {
        Callbackfunc(resp);
      });
    }

    return response;
  }

  // TwitterDirectmessage Post API
  static async requestFacebookMessagePost(data, id, callback) {
    const endpoint = environment.api.facebook_dm + "/" + id;

    let response = EffectUtility.postToModel(
      FacebookDmPostModel,
      endpoint,
      data
    );

    response.then((resp) => {
      if (resp.status === "success" && callback) {
        callback(resp);
      }
    });

    return response;
  }

  static async requestFacebookDmClear() {
    return {};
  }
}
