import React, { useEffect, useRef, useState } from "react";
import "./cssFiles/ResponseManagementPlatformCounts.css";
import environment from "environment";
import Logo from "./logoContainer/Logo";
import Description from "./descriptionContainer/Description";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { theme } from "../../../views/App";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { format, isValid } from "date-fns";

function InstagramProfileSummary(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [postSummaryData, setPostSummaryData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  useEffect(() => {
    console.log("DateFilter", props, props.account_selected);

    setData(null);
    (async function CallApi() {
      setLoading(true);

      props.account_selected &&
        (await fetchData(props.account_selected, props.DateFilter));

      setLoading(false);
    })();
  }, [props.DateFilter, props.account_selected]);

  useEffect(() => {
    if (props.clearClicked) {
      setData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.account_selected, props.DateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      setData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.account_selected, props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.account_selected, props.filterClicked, props.openDateFilter]);

  async function fetchData(account_id, dateRange) {
    let API = `${environment.api.instagramReports}?account_id=${account_id?.account_id}&reports=["instagram_profile_summary"]`;
    await axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      if (
        response.hasOwnProperty("error") ||
        response.hasOwnProperty("error_id")
      ) {
        response.hasOwnProperty("error_id")
          ? setApiError(`${response?.message} - ${response?.error_id}`)
          : setApiError(response?.error);
        setData(null);
      } else {
        setData(response);
        setApiError(null);
      }
    });

    let postsummaryAPI = `${environment.api.instagramReports}?account_id=${
      account_id?.account_id
    }&reports=["instagram_post_summary"]${
      props.DateFilter || props.filterClicked
        ? `&from_date=${dateRange[0]}&to_date=${dateRange[1]}`
        : `&from_date=${format(
            new Date().setDate(new Date().getDate() - 30),
            "yyyy-MM-dd"
          )}&to_date=${format(new Date(), "yyyy-MM-dd")}`
    }`;
    await axiosRequest(postsummaryAPI, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      if (
        response.hasOwnProperty("error") ||
        response.hasOwnProperty("error_id")
      ) {
        response.hasOwnProperty("error_id")
          ? setApiError(`${response?.message} - ${response?.error_id}`)
          : setApiError(response?.error);
        setPostSummaryData(null);
      } else {
        setPostSummaryData(response);
        setApiError(null);
      }
    });
  }

  let NoDataFoundBool = !data || !props.account_selected;

  // Calculate the height of each box based on the maximum length

  const isXl = useMediaQuery(() => theme.breakpoints.up("xl"));
  const isLg = useMediaQuery(() => theme.breakpoints.up("lg"));
  const isLaptop = useMediaQuery(() => theme.breakpoints.up("laptop"));
  const isMd = useMediaQuery(() => theme.breakpoints.up("md"));

  useEffect(() => {
    if (props.DownloadClicked) {
      props.setexcelData([data && data[0].instagram_profile_summary]);
    }
  }, [props.DownloadClicked, data]);

  console.log("postSummaryData", postSummaryData);

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: "12",
      }}
    >
      <Box
        className="scrollable"
        sx={{
          overflowY: "auto",
          "& .MuiDataGrid-virtualScroller": {
            "&::-webkit-scrollbar": {
              width: "3px",
              height: "3px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#b0d2da",
              borderRadius: "3px",
              "&:hover": {
                backgroundColor: "#85bac6",
              },
            },
          },
        }}
      >
        {!loading ? (
          <>
            {!loading && !NoDataFoundBool ? (
              <Box
                style={{
                  width: "100%",
                  overflowX: "auto",
                  padding: "12px",
                }}
              >
                {apiError ? (
                  <Box
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      flex: "12",
                    }}
                  >
                    {apiError}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      padding: 2,
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    <Grid container xs={12}>
                      <Grid item xs={12} md={12}>
                        <Card
                          sx={{ borderRadius: 2, boxShadow: 2, padding: 1 }}
                        >
                          <CardMedia
                            component="img"
                            image={
                              data &&
                              data[0] &&
                              data[0]?.instagram_profile_summary
                                ?.profile_picture_url
                            }
                            alt="Most Engaging Post"
                            sx={{
                              height: isXl
                                ? 150 // Adjust as needed for xl
                                : isLg
                                ? 100 // Adjust as needed for lg
                                : isLaptop
                                ? 100 // Adjust as needed for md
                                : isMd
                                ? 75 // Adjust as needed for md
                                : 75, // Default for smaller size
                              objectFit: "contain",
                            }}
                          />
                          <Typography
                            variant="body2"
                            textAlign={"center"}
                            sx={{ color: "#777" }}
                          >
                            {data &&
                              data[0] &&
                              data[0]?.instagram_profile_summary?.biography}
                          </Typography>
                        </Card>
                      </Grid>
                    </Grid>

                    <Grid item container xs={12} gap={1} mt={2}>
                      {/* Top Overview Section */}
                      <Grid
                        container
                        xs={12}
                        gap={1}
                        justifyContent={"space-between"}
                      >
                        {[
                          {
                            label: "Posts",
                            value:
                              data &&
                              data[0] &&
                              data[0]?.instagram_profile_summary?.media_count,
                          },
                          {
                            label: "Followers",
                            value:
                              data &&
                              data[0] &&
                              data[0]?.instagram_profile_summary
                                ?.followers_count,
                          },
                          {
                            label: "Following",
                            value:
                              data &&
                              data[0] &&
                              data[0]?.instagram_profile_summary?.follows_count,
                          },
                          {
                            label: "name",
                            value:
                              data &&
                              data[0] &&
                              data[0]?.instagram_profile_summary?.name,
                          },
                          {
                            label: "username",
                            value:
                              data &&
                              data[0] &&
                              data[0]?.instagram_profile_summary?.username,
                          },
                        ].map((item, index) => (
                          <Grid item xs={12} md={2.2} key={index}>
                            <Card
                              sx={{
                                backgroundColor: "#fff",
                                borderRadius: 2,
                                boxShadow: 2,
                                textAlign: "center",
                                padding: 2,
                              }}
                            >
                              <CardContent>
                                <Typography
                                  variant="h6"
                                  sx={{ fontWeight: "bold", color: "#333" }}
                                >
                                  {item.value}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{ color: "#777" }}
                                >
                                  {item.label}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>

                      {/* Bottom Section */}
                    </Grid>

                    <Typography
                      component={"p"}
                      sx={{ fontSize: 18, marginY: 1, fontWeight: "bold" }}
                    >
                      Post Summary
                    </Typography>
                    <Grid container xs={12} columnSpacing={1}>
                      <Grid item xs={12} md={6}>
                        <Card
                          sx={{ borderRadius: 2, boxShadow: 2, padding: 1 }}
                        >
                          {[
                            {
                              label: "Views",
                              value:
                                postSummaryData?.[0]?.instagram_post_summary
                                  ?.views,
                            },
                            {
                              label: "Total Interactions",
                              value:
                                postSummaryData?.[0]?.instagram_post_summary
                                  ?.total_interactions,
                            },
                            {
                              label: "Shared",
                              value:
                                postSummaryData?.[0]?.instagram_post_summary
                                  ?.shares,
                            },
                            {
                              label: "Saved",
                              value:
                                postSummaryData?.[0]?.instagram_post_summary
                                  ?.saved,
                            },
                            {
                              label: "Reach",
                              value:
                                postSummaryData?.[0]?.instagram_post_summary
                                  ?.reach,
                            },
                            {
                              label: "Profile Visits",
                              value:
                                postSummaryData?.[0]?.instagram_post_summary
                                  ?.profile_visits,
                            },
                            {
                              label: "Profile Activity",
                              value:
                                postSummaryData?.[0]?.instagram_post_summary
                                  ?.profile_activity,
                            },
                            {
                              label: "Plays",
                              value:
                                postSummaryData?.[0]?.instagram_post_summary
                                  ?.plays,
                            },
                          ]?.map((item) => (
                            <Grid
                              item
                              xs={12}
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <Typography
                                component={"span"}
                                sx={{ fontWeight: "bold", fontSize: 12 }}
                              >
                                {item.label}
                              </Typography>
                              <Typography component={"span"}>
                                {item.value}
                              </Typography>
                            </Grid>
                          ))}
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Card
                          sx={{ borderRadius: 2, boxShadow: 2, padding: 1 }}
                        >
                          {[
                            {
                              label: "Likes",
                              value:
                                postSummaryData?.[0]?.instagram_post_summary
                                  ?.likes,
                            },
                            {
                              label: "Impressions",
                              value:
                                postSummaryData?.[0]?.instagram_post_summary
                                  ?.impressions,
                            },
                            {
                              label: "Reels View Time",
                              value:
                                postSummaryData?.[0]?.instagram_post_summary
                                  ?.ig_reels_video_view_total_time,
                            },
                            {
                              label: "Reels Avg. watch Time",
                              value:
                                postSummaryData?.[0]?.instagram_post_summary
                                  ?.ig_reels_avg_watch_time,
                            },
                            {
                              label: "Reels Play Count",
                              value:
                                postSummaryData?.[0]?.instagram_post_summary
                                  ?.ig_reels_aggregated_all_plays_count,
                            },
                            {
                              label: "Follows",
                              value:
                                postSummaryData?.[0]?.instagram_post_summary
                                  ?.follows,
                            },
                            {
                              label: "Comments",
                              value:
                                postSummaryData?.[0]?.instagram_post_summary
                                  ?.comments,
                            },
                            {
                              label: "Clips replay count",
                              value:
                                postSummaryData?.[0]?.instagram_post_summary
                                  ?.clips_replays_count,
                            },
                          ]?.map((item) => (
                            <Grid
                              item
                              xs={12}
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <Typography
                                component={"span"}
                                sx={{ fontWeight: "bold", fontSize: 12 }}
                              >
                                {item.label}
                              </Typography>
                              <Typography component={"span"}>
                                {item.value}
                              </Typography>
                            </Grid>
                          ))}
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default InstagramProfileSummary;
