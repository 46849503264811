import BaseReducer from "../../../utilities/BaseReducer";
import TicketSettingsAction from "./TicketSettingsAction";

export default class KpiConfigReducer extends BaseReducer {
  //initial state of ticketType
  initialState = {
    kpi_report_config: [],
  };

  //tickettype request action finish
  [TicketSettingsAction.REQUEST_KPI_REPORT_CONFIG_FINISHED](state, action) {
    return {
      ...state,
      kpi_report_config: action.payload,
    };
  }
}
