import BaseReducer from "../../../utilities/BaseReducer";
import AutoresponseAction from "./AutoresponseAction";

export default class NotificationLogsReduser extends BaseReducer {
  initialState = {
    notifications_logs: [],
  };

  [AutoresponseAction.REQUEST_NOTIFICATION_LOGS_FINISHED](state, action) {
    return {
      ...state,
      notifications_logs: action.payload,
    };
  }
}
