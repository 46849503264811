import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import ReleaseHistoryModel from "./models/ReleaseHistoryModel";

export default class ReleaseHistoryEffect {
  static async requestMusicLabelsReleaseShadowStatusWise(params) {
    const endpoint = environment.api.musiclabelsReleaseShadowStatusWise;
    return EffectUtility.getToModel(ReleaseHistoryModel, endpoint, params);
  }
}
