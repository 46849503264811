import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import Description from "./descriptionContainer/Description";
import Logo from "./logoContainer/Logo";
import { Box, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { WidthFull } from "@mui/icons-material";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { NivoLineChart } from "../../../components/nivoCharts/NivoLineChart";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
//import ChartsRe from "./ChartsRe";

const MentionsTrendWithEngagement = (props) => {
  Chart.register(CategoryScale);
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  useEffect(() => {
    (async function CallApi() {
      setLoading(true);

      await fetchData(props.DateFilter);
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);

        await fetchData(props.DateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchData(dateRange) {
    let API = `${
      environment.api.sentimentalAnalysisReports
    }?report_type=["count"]&reports=["overall_mention_trend"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (response.hasOwnProperty("message")) {
          setData(null);
          setApiError(response.message);
        } else {
          setData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setData(null);
        setApiError(err.message);
        setLoading(false);
      });

    // return fetch(
    //   `${
    //     environment.api.sentimentalAnalysisReports
    //   }?report_type=["count"]&reports=["overall_mention_trend"]${
    //     props.DateFilter ? `&from=${dateRange[0]}&to=${dateRange[1]}` : ""
    //   }`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((response) => {
    //     return response;
    //   });
  }

  let LabelList = [];
  let Positive_Sentiment = [];
  let Negative_Sentiment = [];
  let Neutral_Sentiment = [];
  let Total_Sentiment = [];

  function date_format(val) {
    return val;
  }

  function TotalCalculator(val) {
    return val.map((item) => item.count).reduce((prev, next) => prev + next);
  }

  let Total_list = [];

  data &&
    data.sentiment_analysis_report &&
    data.sentiment_analysis_report.overall_mention_trend &&
    Object.entries(data.sentiment_analysis_report.overall_mention_trend).map(
      ([key, value]) => {
        Total_list.push({
          [key]: value,
        });
      }
    );

  let date_wise = "";
  let total_mentions = "";
  let total_negative = "";
  let total_neutral = "";
  let total_positive = "";

  [date_wise, total_mentions, total_negative, total_neutral, total_positive] =
    Total_list;

  // data &&
  //   data.sentiment_analysis_report &&
  //   data.sentiment_analysis_report.overall_mention_trend &&
  //   Object.entries(
  //     data.sentiment_analysis_report.overall_mention_trend.date_wise
  //   ).map(([key, value]) => {
  //     LabelList.push(date_format(key));

  //     Total_Sentiment.push(TotalCalculator(value));

  //     value.map((e) => {
  //       if (e.key === "positive_sentiment") {
  //         Positive_Sentiment.push(e.count);
  //       } else if (e.key === "negative_sentiment") {
  //         Negative_Sentiment.push(e.count);
  //       } else if (e.key === "neutral") {
  //         Neutral_Sentiment.push(e.count);
  //       }
  //     });
  //   });

  data &&
    data.sentiment_analysis_report &&
    data.sentiment_analysis_report.overall_mention_trend &&
    data.sentiment_analysis_report.overall_mention_trend.date_wise.map(
      (month) => {
        LabelList.push(returnMonthName(month[0]));

        month[1].map((e) => {
          if (e.key === "positive_sentiment") {
            Positive_Sentiment.push(e.count);
          } else if (e.key === "negative_sentiment") {
            Negative_Sentiment.push(e.count);
          } else if (e.key === "neutral") {
            Neutral_Sentiment.push(e.count);
          } else if (e.key === "total_count") {
            Total_Sentiment.push(e.count);
          }
        });
      }
    );

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      // Correct map with destructuring
      Total_list.map((item) => {
        let key = Object.keys(item)?.[0];
        if (key === "date_wise") {
          item[key].map((dateData) => {
            exportData.push({
              Date: dateData[0],
              Positive:
                prefixZero_for_singleDigit(
                  dateData[1].find((item) => item.key === "positive_sentiment")
                    ?.count
                ) || "00",
              Negative:
                prefixZero_for_singleDigit(
                  dateData[1].find((item) => item.key === "negative_sentiment")
                    ?.count
                ) || "00",
              Neutral:
                prefixZero_for_singleDigit(
                  dateData[1].find((item) => item.key === "neutral")?.count
                ) || "00",
            });
          });
          exportData.push("");
        } else {
          exportData.push({
            Date: key
              .split("_") // Split the key by underscores
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
              .join(" "),
            Positive: prefixZero_for_singleDigit(item[key]),
          });
        }
      });

      props.setexcelData(exportData);

      // Optionally reset DownloadClicked if needed
      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked]);

  function returnMonthName(monthString) {
    const dateString = monthString;
    const [day, month, year] = dateString.split("-");
    const date = new Date(`${year}`, month - 1, day);
    const formattedDate = date.toLocaleString("en-US", {
      month: "short",
      year: "numeric",
      day: "numeric",
    });

    return formattedDate;
  }

  const state = {
    labels: LabelList.reverse(),
    datasets: [
      {
        label: "Positive Sentiment",
        backgroundColor: `${theme.reports.positive}`,
        borderWidth: 1.5,
        borderColor: `${theme.reports.positive}`,
        data: Positive_Sentiment.reverse(),
        lineTension: 0.3,
        pointStyle: "circle",
        pointRadius: 6,
      },
      {
        label: "Negative Sentiment",
        backgroundColor: `${theme.reports.negative}`,
        data: Negative_Sentiment.reverse(),
        borderWidth: 1.5,
        borderColor: `${theme.reports.negative}`,
        lineTension: 0.3,
        pointStyle: "triangle",
        pointRadius: 6,
      },
      {
        label: "Neutral",
        backgroundColor: `${theme.reports.neutral}`,
        data: Neutral_Sentiment.reverse(),
        borderWidth: 1.5,
        borderColor: `${theme.reports.neutral}`,
        lineTension: 0.3,
        pointStyle: "rect",
        pointRadius: 6,
      },
      // {
      //   label: "Total",
      //   backgroundColor: `${theme.reports.total}`,
      //   data: Total_Sentiment,
      //   borderWidth: 1.5,
      //   borderColor: `${theme.reports.total}`,
      //   lineTension: 0.3,
      //   pointStyle: "rect",
      //   pointRadius: 6,
      // },
    ],
  };

  let NoDataFoundBool =
    !loading && total_mentions && total_mentions.total_mentions === 0;

  // LabelList = LabelList.reverse();

  const formatTooltip = (value, id, data) => (
    <div
      style={{
        background: "white",
        padding: "10px",
        border: "1px solid #ccc",
      }}
    >
      <strong>
        {data.x}-{id}
      </strong>
      : {prefixZero_for_singleDigit(value)}
    </div>
  );

  const chartData = [
    {
      id: "Positive(+ve)",
      color: `${theme.reports.positive}`,
      data: LabelList.map((Label, index) => ({
        x: new Date(Label).toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
        }),
        y: Positive_Sentiment[index],
      })),
    },
    {
      id: "Negative(-ve)",
      color: `${theme.reports.negative}`,

      data: LabelList.map((Label, index) => ({
        x: new Date(Label).toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
        }),
        y: Negative_Sentiment[index],
      })),
    },
    {
      id: "Neutral(∅)",
      color: `${theme.reports.neutral}`,

      data: LabelList.map((Label, index) => ({
        x: new Date(Label).toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
        }),
        y: Neutral_Sentiment[index],
      })),
    },
  ];
  //600 width 330 height
  const getOption = () => {
    const legendData = chartData.map((series) => series.id);

    let allYValues = [];
    chartData?.forEach((series) => {
      series.data.forEach((point) => {
        allYValues.push(point.y);
      });
    });

    // Calculate the minimum and maximum y values
    const minValue = Math.min(...allYValues);
    const maxValue = Math.max(...allYValues);

    let shouldShowLegend = false;
    if (
      (props.ChartContainerHeight?.current &&
        props.ChartContainerWidth?.current &&
        props.ChartContainerHeight.current >= 350 &&
        props.ChartContainerWidth.current > 350) ||
      (props.ChartContainerHeight?.current === undefined &&
        props.ChartContainerWidth?.current === undefined)
    ) {
      shouldShowLegend = true;
    }
    const formatValue = (value) => {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + "K";
      }
      return value;
    };
    return {
      tooltip: {
        trigger: "axis",
        position: function (pt) {
          return [pt[0], "10%"];
        },
        confine: true,

        formatter: (params) => {
          // Extract the date from the axis value
          let date = params[0].axisValue;
          const tooltipColor = theme.palette.primary.main; // Example: Use primary color from the theme

          // Format the tooltip content
          let tooltipContent = params
            .map((param) => {
              let val = param.value;
              let formattedValue = formatValue(val);
              const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${param.color};border-radius:50%;"></span>`;
              return `${colorSpan}${param.seriesName}: ${formattedValue}`;
            })
            .join("<br>");

          // Include the date at the top of the tooltip
          return `<b style="color:${tooltipColor};">${date}</b><br>${tooltipContent}`;
        },
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: chartData && chartData[0]?.data.map((point) => point.x),
        name:
          props.detailedReport || props.ChartContainerHeight?.current > 300
            ? "Date"
            : "",
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: 25,
        },
        axisLabel: {
          hideOverlap: true,
        },
      },
      yAxis: {
        type: "value",
        boundaryGap: [0, "100%"],
        name:
          props.detailedReport || props.ChartContainerWidth?.current > 600
            ? "Count"
            : "",
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: -20,
        },
        nameGap: 30,
        min: minValue,
        max: maxValue,
        axisLabel: {
          padding: props.detailedReport ? [0, 50, 0, 0] : [0, 0, 0, 0],
          formatter: (value) => {
            let formattedValue = formatValue(value);
            return formattedValue;
          },
          hideOverlap: true,
        },
      },
      legend: {
        show: true,
        data: shouldShowLegend ? legendData : [],
        top: 0,
      },

      dataZoom: [
        {
          type: "inside",
          start: 0,
          end: 100,
        },
      ],
      grid: {
        top: props.ChartContainerHeight?.current >= 500 ? "15%" : "10%",
        //top: "15%",
        left: "5%",
        right: "5%",
        bottom: "5%",
        containLabel: true,
      },
      series:
        chartData &&
        chartData.map((series) => ({
          name: series.id,
          type: "line",
          symbol: "none",
          sampling: "lttb",
          itemStyle: {
            color: series.color,
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: series.color,
              },
              {
                offset: 1,
                color: series.color,
              },
            ]),
          },
          data: series.data.map((point) => point.y),
        })),
    };
  };

  return (
    <LoadingIndicator isActive={loading}>
      <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flex: "12",
        }}
      >
        {!loading ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            {apiError ? (
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  flex: "12",
                }}
              >
                {apiError}
              </Box>
            ) : !loading && !NoDataFoundBool ? (
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    flex: "12",
                  }}
                >
                  {props.detailedReport && (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "5px",
                        fontSize: "14px",
                        gap: "10px",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "30%",
                        }}
                      >
                        <span
                          style={{
                            color: `${theme.reports.total}`,
                            fontWeight: "bold",
                          }}
                        >
                          Total Tickets &nbsp;&nbsp;
                          {total_mentions && total_mentions.total_mentions}
                        </span>
                      </div>
                      <div
                        style={{
                          width: "20%",
                        }}
                      >
                        <span style={{ color: `${theme.reports.positive}` }}>
                          Positive&nbsp;&nbsp;
                          {total_positive && total_positive.total_positive}
                        </span>
                      </div>
                      <div
                        style={{
                          width: "20%",
                        }}
                      >
                        <span style={{ color: `${theme.reports.negative}` }}>
                          Negative &nbsp;&nbsp;
                          {total_negative && total_negative.total_negative}
                        </span>
                      </div>
                      <div style={{ width: "20%" }}>
                        <span style={{ color: `${theme.reports.neutral}` }}>
                          Neutral &nbsp;&nbsp;
                          {total_neutral && total_neutral.total_neutral}
                        </span>
                      </div>
                    </Box>
                  )}
                  <Box style={{ width: "100%", height: "100%" }}>
                    {chartData?.length && (
                      <ReactEcharts
                        option={getOption()}
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                        notMerge={true}
                        lazyUpdate={true}
                      />
                    )}

                    {/* <ChartsRe option={getOption()} /> */}
                  </Box>
                </Box>
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage width={"50%"} />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  );
};

export default MentionsTrendWithEngagement;
