import ActionUtility from "../../../utilities/ActionUtility";
import TelegramAccountEffect from "./TelegramAccountEffect";

export default class TelegramAccountAction {
  // TelegramAccountAction action with an function declaration
  static REQUEST_TELEGRAM_ACCOUNT =
    "TelegramAccountAction.REQUEST_TELEGRAM_ACCOUNT";
  static REQUEST_TELEGRAM_ACCOUNT_FINISHED =
    "TelegramAccountAction.REQUEST_TELEGRAM_ACCOUNT_FINISHED";

  static REQUEST_PUT_TELEGRAM_ACCOUNT =
    "TelegramAccountAction.REQUEST_PUT_TELEGRAM_ACCOUNT";
  static REQUEST_PUT_TELEGRAM_ACCOUNT_FINISHED =
    "TelegramAccountAction.REQUEST_PUT_TELEGRAM_ACCOUNT_FINISHED";

  static REQUEST_POST_TELEGRAM_ACCOUNT =
    "TelegramAccountAction.REQUEST_POST_TELEGRAM_ACCOUNT";
  static REQUEST_POST_TELEGRAM_ACCOUNT_FINISHED =
    "TelegramAccountAction.REQUEST_POST_TELEGRAM_ACCOUNT_FINISHED";

  static REQUEST_DELETE_TELEGRAM_ACCOUNT =
    "TelegramAccountAction.REQUEST_DELETE_TELEGRAM_ACCOUNT";
  static REQUEST_DELETE_TELEGRAM_ACCOUNT_FINISHED =
    "TelegramAccountAction.REQUEST_DELETE_TELEGRAM_ACCOUNT_FINISHED";

  static REQUEST_POST_TELEGRAM_AUTH_POST =
    "TelegramAccountAction.REQUEST_POST_TELEGRAM_AUTH_POST";
  static REQUEST_POST_TELEGRAM_AUTH_POST_FINISHED =
    "TelegramAccountAction.REQUEST_POST_TELEGRAM_AUTH_POST_FINISHED";

  static REQUEST_POST_TELEGRAM_GET_OTP =
    "TelegramAccountAction.REQUEST_POST_TELEGRAM_GET_OTP";
  static REQUEST_POST_TELEGRAM_GET_OTP_FINISHED =
    "TelegramAccountAction.REQUEST_POST_TELEGRAM_GET_OTP_FINISHED";

  // METHODS
  // telegram GET function
  static requestTelegramAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramAccountAction.REQUEST_TELEGRAM_ACCOUNT,
        TelegramAccountEffect.requestTelegramAccount,
        params
      );
    };
  }

  static requestPutTelegramAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramAccountAction.REQUEST_PUT_TELEGRAM_ACCOUNT,
        TelegramAccountEffect.requestPutTelegramAccount,
        data,
        id
      );
    };
  }

  // whatsapp post function
  static requestPostTelegramAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramAccountAction.REQUEST_POST_TELEGRAM_ACCOUNT,
        TelegramAccountEffect.requestPostTelegramAccount,
        data
      );
    };
  }

  // telegram AUth post function
  static requestPostTelegramAuth(data, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramAccountAction.REQUEST_POST_TELEGRAM_AUTH_POST,
        TelegramAccountEffect.requestPostTelegramAuth,
        data,
        callBack
      );
    };
  }

  static requestPostTelegramGetOTP(data, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramAccountAction.REQUEST_POST_TELEGRAM_GET_OTP,
        TelegramAccountEffect.requestPostTelegramGetOTP,
        data,
        callBackFunction
      );
    };
  }

  // whatsapp delete account
  static requestDeleteTelegramAccount(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramAccountAction.REQUEST_DELETE_TELEGRAM_ACCOUNT,
        TelegramAccountEffect.requestDeleteTelegramAccount,
        id
      );
    };
  }
}
