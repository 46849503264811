import React, { useEffect, useRef, useState } from "react";
import "./cssFiles/ResponseManagementPlatformCounts.css";
import environment from "environment";
import Logo from "./logoContainer/Logo";
import Description from "./descriptionContainer/Description";
import { Box, Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";

function ResponseOverview(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [activitiesData, setActivitiesData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  useEffect(() => {
    setData(null);
    setActivitiesData(null);
    (async function CallApi() {
      setLoading(true);
      await fetchData(props.DateFilter);
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      setData(null);
      setActivitiesData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.DateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      setData(null);
      setActivitiesData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchData(dateRange) {
    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["platform"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }
      `;
    await axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (response.hasOwnProperty("message")) {
          setData(null);
          setApiError(response.message);
        } else {
          setData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setData(null);
        setApiError(err.message);
        setLoading(false);
      });

    let API2 = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["activities"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    await axiosRequest(API2, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (response.hasOwnProperty("message")) {
          setActivitiesData(null);
          setApiError(response.message);
        } else {
          setActivitiesData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setActivitiesData(null);
        setApiError(err.message);
        setLoading(false);
      });
  }

  function platformName(val) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === val)
        .map((item) => {
          return item.platform_alias;
        })
    );
  }

  function ReturnActivityName(platformId, activityId) {
    return (
      props.activity_list &&
      props.activity_list.length > 0 &&
      props.activity_list.filter(
        (item) => item.platform.platform_id === platformId
      ).length > 0 &&
      props.activity_list
        .filter((item) => item.platform.platform_id === platformId)
        .filter((item) => item.activity_id === activityId).length > 0 &&
      props.activity_list
        .filter((item) => item.platform.platform_id === platformId)
        .filter((item) => item.activity_id === activityId)[0].activity_name
    );
  }

  let totalResponse = 0;
  data &&
    data.ticket_report &&
    data.ticket_report.platform
      .slice(
        data && data.ticket_report && data.ticket_report.platform.length - 6,
        data && data.ticket_report && data.ticket_report.platform.length - 5
      )
      .map((item, index) => {
        return (totalResponse = totalResponse + item.overall_total);
      });

  let NoDataFoundBool =
    (activitiesData && activitiesData?.status === "Failed") ||
    (activitiesData &&
      activitiesData.ticket_report &&
      activitiesData.ticket_report.activities.every(
        (item) => Object.values(item)[0].length === 0
      ));

  let maxDataLength = 0;
  if (activitiesData?.ticket_report?.activities) {
    activitiesData.ticket_report.activities.forEach((platform) => {
      const platformLength = Object.values(
        Object.values(Object.keys(platform)[0])
      ).length;
      if (platformLength > maxDataLength) {
        maxDataLength = platformLength;
      }
    });
  }

  // Calculate the height of each box based on the maximum length
  const boxHeight = maxDataLength * 22; // Assuming each unit of data has a height of 20px

  useEffect(() => {
    if (props.DownloadClicked) {
      const allActivities = activitiesData?.ticket_report?.activities.reduce(
        (acc, curr) => {
          const platformKeys = Object.keys(curr);
          platformKeys.forEach((platformKey) => {
            curr[platformKey].forEach(({ key }) => {
              const activityName = ReturnActivityName(platformKey, key);
              if (!acc.includes(activityName)) {
                acc.push(activityName);
              }
            });
          });
          return acc;
        },
        []
      );

      const exportData = data?.ticket_report?.platform
        ?.slice(0, data?.ticket_report?.platform?.length - 6)
        .map(({ platform, doc_count, monthwise }) => {
          // Find the activities for the current platform
          const activitiesObj = activitiesData?.ticket_report?.activities.find(
            (activity) => activity[platform]
          );

          // If activities are found, transform them into an object with key-value pairs
          const activities = activitiesObj
            ? activitiesObj[platform].reduce((acc, { key, count }) => {
                // Use the ReturnActivityName function to generate a new key name
                const activityName = ReturnActivityName(platform, key);
                acc[activityName] = prefixZero_for_singleDigit(count);
                return acc;
              }, {})
            : {};

          // Make sure all activity names are present in the object with default "00" values
          allActivities.forEach((activity) => {
            if (!activities.hasOwnProperty(activity)) {
              activities[activity] = "00";
            }
          });
          // Calculate the total
          const total = Object.values(activities).reduce(
            (sum, count) => sum + parseInt(count, 10),
            0
          );

          // Return the transformed object with platform as the key and total at the end
          return {
            platform: platformName(platform)[0],
            ...activities,
            total: prefixZero_for_singleDigit(total),
          };
        });

      props.setexcelData(exportData);
    }
  }, [props.DownloadClicked, activitiesData, data]);

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: "12",
      }}
    >
      <Box
        className="scrollable"
        sx={{
          // flex: "11",
          // display: "flex",
          // flexDirection: "column",
          overflowY: "auto",
          "& .MuiDataGrid-virtualScroller": {
            "&::-webkit-scrollbar": {
              width: "3px",
              height: "3px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#b0d2da",
              borderRadius: "3px",
              "&:hover": {
                backgroundColor: "#85bac6",
              },
            },
          },
        }}
      >
        {!loading ? (
          <>
            {!loading && !NoDataFoundBool ? (
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {apiError ? (
                  <Box
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      flex: "12",
                    }}
                  >
                    {apiError}
                  </Box>
                ) : (
                  <Box
                    style={{
                      width: "100%",
                      overflowX: "auto",
                      padding: "12px",
                    }}
                  >
                    <span style={{ fontSize: "16px", padding: "10px 10px" }}>
                      Total Responses :{" "}
                      <span className="font-weight-bold">
                        {prefixZero_for_singleDigit(totalResponse)}
                      </span>
                    </span>

                    <Grid
                      container
                      item
                      lg={12}
                      justifyContent="space-between"
                      alignItems="center"
                      height="100%"
                      sx={{
                        flexWrap: "wrap",
                        paddingY: "12px",
                      }}
                      // className="bg-warning"
                    >
                      {data &&
                        maxDataLength &&
                        data.ticket_report &&
                        data.ticket_report.platform
                          .slice(
                            0,
                            data &&
                              data.ticket_report &&
                              data.ticket_report.platform.length - 6
                          )
                          .map((item, index) => (
                            <Grid
                              item
                              container
                              justifyContent={"center"}
                              alignItems="center"
                              xl={4}
                              lg={4}
                              md={4}
                              xs={4}
                              sm={4}
                              position="relative"
                              sx={{
                                borderRadius: "4px",
                                border: `solid 1px #e5e2e2`,
                                height: 200,
                                backgroundColor: "#ffffff",
                                overflow: "hidden",
                                whiteSpace: "normal",
                              }}
                            >
                              <Box
                                className="py-4"
                                width={"100%"}
                                sx={{
                                  overflow: "hidden",
                                  paddingX: "15%",
                                }}
                              >
                                <Grid
                                  item
                                  container
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  justifyContent="space-between"
                                  alignItems={"center"}
                                >
                                  <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                                    <Typography
                                      component={"span"}
                                      sx={{ fontSize: 14, fontWeight: 600 }}
                                    >
                                      {platformName(item.platform) &&
                                      platformName(item.platform).length
                                        ? platformName(item.platform)[0]
                                        : ""}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    textAlign={"left"}
                                    xl={2}
                                    lg={2}
                                    md={2}
                                    sm={2}
                                    xs={2}
                                  >
                                    <Typography
                                      component={"span"}
                                      sx={{ fontSize: 14, fontWeight: 600 }}
                                    >
                                      :&nbsp;
                                      {item &&
                                        item.doc_count &&
                                        prefixZero_for_singleDigit(
                                          item.doc_count
                                        )}{" "}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                {activitiesData &&
                                activitiesData.ticket_report &&
                                activitiesData.ticket_report.activities &&
                                Object.values(
                                  activitiesData.ticket_report.activities.filter(
                                    (val) =>
                                      item.platform === Object.keys(val)[0]
                                  )[0]
                                )[0].length > 0
                                  ? Object.values(
                                      activitiesData.ticket_report.activities.filter(
                                        (val) =>
                                          item.platform === Object.keys(val)[0]
                                      )[0]
                                    )[0].map((i) => (
                                      <Grid
                                        item
                                        container
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        justifyContent="space-between"
                                        alignItems={"center"}
                                      >
                                        <Grid
                                          item
                                          xl={8}
                                          lg={8}
                                          md={8}
                                          sm={8}
                                          xs={8}
                                        >
                                          {ReturnActivityName(
                                            item.platform,
                                            i.key
                                          )}
                                        </Grid>
                                        <Grid
                                          item
                                          textAlign={"left"}
                                          xl={2}
                                          lg={2}
                                          md={2}
                                          sm={2}
                                          xs={2}
                                        >
                                          {ReturnActivityName(
                                            item.platform,
                                            i.key
                                          )?.length
                                            ? ":"
                                            : null}{" "}
                                          {prefixZero_for_singleDigit(i.count)}
                                        </Grid>
                                      </Grid>
                                    ))
                                  : null}
                              </Box>
                            </Grid>
                          ))}
                    </Grid>
                  </Box>
                )}
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ResponseOverview;
