import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import bgImage from "../../Images/a3_label_web_welvome_bg.webp";
import rightLogo from "../../Images/a3_label_web_music_right.webp";
import leftLogo from "../../Images/a3_label_web_music_left.webp";
import middleLogo from "../../../../components/logo/logo.webp";
import "./A3WelcomePage.css";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import UserLastWorkingProjectAction from "../../../../ormTicketing/stores/userLastWorkingProject/UserLastWorkingProjectAction";
import ProjectAction from "../../../../ormTicketing/stores/project/projectAction";
import { connect } from "react-redux";
import { replace } from "lodash";
import PagePermissionAction from "../../../../stores/pagePermission/PagePermissionAction";

const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    UserLastWorkingProjectAction.REQUEST_LAST_WORKING_PROJECT_BY_ID,
    ProjectAction.REQUEST_PROJECT,
    UserLastWorkingProjectAction.REQUEST_POST_LAST_WORKING_PROJECT,
  ]),
  lastWorkingProject:
    state.userLastWorkingProjectById.userLastWorkingProjectById || {},
  projectfilter: state.projectfilter.projectfilter || [],
});
function A3WelcomePage(props) {
  const navigate = useNavigate();
  const location = useLocation();

  function fetchLastWorkingProject(id) {
    props.dispatch(
      UserLastWorkingProjectAction.requestLastWorkingProjectById(id)
    );
  }

  useEffect(() => {
    setTimeout(() => {
      const params = {
        page_limit: "none",
      };

      const projectCallBack = (resp) => {
        ORMNavigationFunction(resp);
      };

      props.dispatch(
        ProjectAction.requestProjectFilter(params, projectCallBack)
      );
    }, 3000);
  }, [props.projectfilter]);

  const ORMNavigationFunction = (projectResponse) => {
    let ID = localStorage.getItem("userId");
    fetchLastWorkingProject(ID);
    let project_object = [];
    if (projectResponse) {
      projectResponse?.hits?.hits?.length === 1 &&
        projectResponse.hits.hits.map((item) => {
          project_object.push({
            project_id: item._id,
            project_name: item._source.project_name,
            organization_id: item._source?.organization_id?.organization_id,
          });
        });
    }
    let obj = {
      project: project_object.length > 0 && project_object[0],
      user_id: localStorage.getItem("userId") && localStorage.getItem("userId"),
    };
    if (projectResponse && projectResponse.hits) {
      if (projectResponse?.hits?.hits?.length === 1) {
        props
          .dispatch(
            UserLastWorkingProjectAction.requestPostLastWorkingProject(
              JSON.stringify(obj)
            )
          )
          .then(() => {
            localStorage.setItem(
              "user_last_working_project",
              JSON.stringify(obj.project)
            );
            let param = {
              page_limit: "none",
            };
            props
              .dispatch(PagePermissionAction.requestPagePermission(param))
              .then(() => {
                if (location.state && location.state.from) {
                  if (location.state.from === "/") {
                    navigate(`/A3dashboard`, {
                      state: { from: location.pathname },
                    });
                    replace = true;
                  } else {
                    navigate(`${location.state.from}`);
                  }
                } else if (location.pathname) {
                  if (location.pathname === "/") {
                    navigate("/A3dashboard", {
                      state: { from: location.pathname },
                    });
                  } else {
                    navigate(`${location.pathname}`);
                  }
                }

                replace = true;
                props.onClose();
              });
          });
      } else if (projectResponse.hits.hits.length === 0) {
        navigate("/projectSelect", { state: { from: location.pathname } });
        replace = true;
        props.onClose();
      } else {
        navigate("/projectSelect", { state: { from: location.pathname } });
        replace = true;
        props.onClose();
      }
    }
  };

  return (
    <div style={{ display: "flex", width: "100%", backgroundColor: "#fff" }}>
      <div style={{ position: "relative", width: "100%" }}>
        <img src={bgImage} alt="" style={{ width: "100%", height: "90vh" }} />
        <div className="left_music a3logo">
          <img src={leftLogo} alt="Left Logo" style={{ width: "100%" }} />
        </div>
        <div className="center_logo">
          <p className="welcome_text">WELCOME TO</p>
          <img className="center_image" src={middleLogo} alt="Middle a3logo" />
        </div>
        <div className="right_music a3logo">
          <img src={rightLogo} alt="Right Logo" style={{ width: "100%" }} />
        </div>
        <div className="bottom_container" style={{ width: "100%" }}>
          <p className="bottom_text">MAKE MUSIC HAPPEN</p>
        </div>
      </div>
    </div>
  );
}
export default connect(mapStateToProps)(A3WelcomePage);
