import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AutoresponseAction from "../../stores/autoResponse/AutoresponseAction";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import {
  Box,
  Chip,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import MuiButton from "../../../components/muiButton/MuiButton";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import { theme } from "../../../views/App";
import TicketSettingsAction from "../../stores/ticketSettings/TicketSettingsAction";
import AutoCompleteMultiSelect from "../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import CancelIcon from "@mui/icons-material/Cancel";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TicketSettingsAction.REQUEST_KPI_REPORT_CONFIG,
  ]),
  kpi_report_config: state.kpi_report_config.kpi_report_config || [],
});

const KpiConfig = (props) => {
  const fetchKpiReportConfig = () => {
    props.dispatch(TicketSettingsAction.requestKpiReportConfig());
  };

  useEffect(() => {
    fetchKpiReportConfig();
  }, []);

  const [Edit, setEdit] = useState(false);

  const [initial_values, setInitialValues] = useState({});

  const triggerList = [
    {
      _id: "daily",
      _name: "Daily",
    },
    {
      _id: "weekly",
      _name: "Weekly",
    },
    {
      _id: "monthly",
      _name: "Monthly",
    },
  ];

  const returnTriggerObj = (triggerid) => {
    return triggerList?.find((item) => {
      return item._id === triggerid;
    });
  };

  useEffect(() => {
    let initial = {};

    if (props?.kpi_report_config?.hits) {
      if (props.kpi_report_config.hits?.hits?.length) {
        setEdit(true);
        const { _source } = props.kpi_report_config.hits?.hits[0];
        initial = {
          email_trigger: _source?.email_trigger
            ? returnTriggerObj(_source?.email_trigger)
            : "",
          recipient_email: _source?.email_config?.recipient_email ?? "",
          cc: _source?.email_config?.cc ?? "",
          bcc: _source?.email_config?.bcc ?? "",
        };
      } else {
        setEdit(false);
        initial = {
          email_trigger: "",
          recipient_email: "",
          cc: "",
          bcc: "",
        };
      }

      setInitialValues(initial);
    }
  }, [props?.kpi_report_config?.hits?.hits]);

  const [updateLoading, setUpdateLoading] = useState(false);
  const [sendLoading, setsendLoading] = useState(false);

  const sendadmin_kpiFunction = () => {
    setsendLoading(true);
    const params = {
      kpi_report_config_id: localStorage.getItem("user_last_working_project")
        ? JSON.parse(localStorage.getItem("user_last_working_project"))
            .project_id
        : "",
      project_id: localStorage.getItem("user_last_working_project")
        ? JSON.parse(localStorage.getItem("user_last_working_project"))
            .project_id
        : "",
    };
    props.dispatch(TicketSettingsAction.requestGetAdminKpi(params)).then(() => {
      setsendLoading(false);
    });
  };

  const [fieldtypeValues, setFieldtypeValues] = useState("");
  const [bccfieldtypeValues, setbccfieldtypeValues] = useState("");

  const [theArray, setTheArray] = useState([]);
  const [theBCCArray, settheBCCArray] = useState([]);

  const addEntryClick = () => {
    if (fieldtypeValues > 1 || fieldtypeValues.trim()) {
      setTheArray([...theArray, fieldtypeValues]);
    }

    setFieldtypeValues("");
  };

  const addBccEntryClick = () => {
    if (bccfieldtypeValues > 1 || bccfieldtypeValues.trim()) {
      settheBCCArray([...theBCCArray, bccfieldtypeValues]);
    }

    setbccfieldtypeValues("");
  };

  const handleChangeFor = (event) => {
    if (
      event.target.value.length > 1 ||
      event.target.value !== null ||
      event.target.value.trim()
    ) {
      setFieldtypeValues(event.target.value);
    }
  };

  const handleChangeForBcc = (event) => {
    if (
      event.target.value.length > 1 ||
      event.target.value !== null ||
      event.target.value.trim()
    ) {
      setbccfieldtypeValues(event.target.value);
    }
  };

  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (props?.kpi_report_config?.hits?.hits?.length) {
      setUpdate(true);
    }
  }, [props?.kpi_report_config?.hits]);

  useEffect(() => {
    if (props?.kpi_report_config?.hits?.hits && update) {
      if (props?.kpi_report_config?.hits?.hits && update) {
        const emailConfig =
          props?.kpi_report_config?.hits?.hits[0]?._source?.email_config;

        if (emailConfig) {
          const { cc = [] } = emailConfig;

          // Add items to arrays
          theArray.push(...cc);
        } else {
        }
      }
    } else {
      const InitialArray = [];
      setTheArray(InitialArray);
    }
  }, [update]);

  useEffect(() => {
    if (props?.kpi_report_config?.hits?.hits && update) {
      if (props?.kpi_report_config?.hits?.hits && update) {
        const emailConfig =
          props?.kpi_report_config?.hits?.hits[0]?._source?.email_config;

        if (emailConfig) {
          const { bcc = [] } = emailConfig;

          theBCCArray.length = 0; // Clear theBCCArray

          // Add items to arrays
          theBCCArray.push(...bcc);
        } else {
        }
      }
    } else {
      const InitialArray = [];
      settheBCCArray(InitialArray);
    }
  }, [update]);

  const deleteEntry = (index) => {
    const updatedArray = [...theArray];
    updatedArray.splice(index, 1);
    setTheArray(updatedArray);
  };

  const deleteBccEntry = (index) => {
    const updatedArray = [...theBCCArray];
    updatedArray.splice(index, 1);
    settheBCCArray(updatedArray);
  };

  return (
    <Box>
      {!props.isRequesting ? (
        <Grid
          item
          container
          justifyContent={"center"}
          alignItems={"flex-start"}
          //   className="bg-warning"
        >
          <Grid
            item
            xs={8}
            sx={{
              margin: "20px auto",
              padding: "20px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              fontFamily: "Arial, sans-serif",
            }}
          >
            <Box>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold", // Makes the text bold
                  textAlign: "center", // Aligns text to the center
                  color: theme.palette.primary.main, // Custom color (e.g., Material-UI primary color)
                  textTransform: "uppercase", // Converts text to uppercase
                  padding: "8px 0", // Adds vertical padding
                  borderBottom: `2px solid ${theme.palette.primary.main}`, // Adds a bottom border
                }}
              >
                KPI Config
              </Typography>
              <Formik
                initialValues={initial_values}
                validate={(values) => {
                  var errors = {};
                  //   if (!values.platform.length) {
                  //     errors.platform = "Platform Name is Required";
                  //   }

                  //   return errors;
                }}
                onSubmit={(values, formikHelpers) => {
                  setUpdateLoading(true);

                  const postObj = {};

                  Object.assign(
                    postObj,
                    { email_trigger: values?.email_trigger?._id },
                    {
                      email_config: {
                        recipient_email: values?.recipient_email,
                        cc: theArray.length > 0 ? theArray : [],
                        bcc: theBCCArray.length > 0 ? theBCCArray : [],
                      },
                    }
                  );

                  if (props.kpi_report_config.hits?.hits?.length) {
                    props
                      .dispatch(
                        TicketSettingsAction.requestPutKpiConfig(
                          JSON.stringify(postObj),
                          props.kpi_report_config.hits?.hits[0]._id
                        )
                      )
                      .then(() => {
                        props.dispatch(
                          TicketSettingsAction.requestKpiReportConfig()
                        );
                        setUpdateLoading(false);
                      });
                  } else {
                    props
                      .dispatch(
                        TicketSettingsAction.requestPostKpiConfig(
                          JSON.stringify(postObj)
                        )
                      )
                      .then(() => {
                        props.dispatch(
                          TicketSettingsAction.requestKpiReportConfig()
                        );
                        setUpdateLoading(false);
                      });
                  }
                }}
                enableReinitialize
              >
                {({
                  errors,
                  isValid,
                  helperText,
                  touched,
                  dirty,
                  handleChange,
                  values,
                }) => (
                  <Form>
                    <DialogContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {/* <Box sx={{ width: "300px", margin: "auto", marginLeft: "140px" }}> */}
                      <Grid container>
                        <Grid item lg={12} md={12} sm={12} container my={1}>
                          <Grid item lg={3} md={3} sm={3} mt={3}>
                            Email Trigger * :
                          </Grid>
                          <Grid item lg={9} md={9} sm={9}>
                            <Field
                              name="email_trigger"
                              size="small"
                              // Calling AutoCompleteMultiSelect Component
                              value={
                                values.email_trigger
                                  ? values.email_trigger
                                  : null
                              }
                              component={AutoCompleteMultiSelect}
                              disableClearable={true}
                              options={triggerList ? triggerList : []}
                              isOptionEqualToValue={(option, value) =>
                                option._name === value._name
                              }
                              getOptionLabel={(option) => option._name}
                              textFieldProps={{
                                margin: "normal",
                                variant: "outlined",
                                placeholder: "Email Trigger",
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} my={1} container>
                          <Grid item lg={3} md={3} sm={3} mt={1}>
                            Recipient Email * :
                          </Grid>
                          <Grid item lg={9} md={9} sm={9}>
                            <Field
                              as={MuiTextField}
                              autoFocus
                              variant="outlined"
                              placeholder="Recipient Email"
                              id="recipient_email"
                              size="small"
                              name="recipient_email"
                              disabled={props.edit ? true : false}
                              error={
                                Boolean(errors.recipient_email) &&
                                Boolean(touched.recipient_email)
                              }
                              helperText={
                                Boolean(touched.recipient_email) &&
                                errors.recipient_email
                              }
                            />
                          </Grid>
                        </Grid>

                        {theArray?.length ? (
                          <Grid item lg={12} md={12} sm={12} my={1} container>
                            <Grid item lg={3} md={3} sm={3} mt={1}>
                              CC :
                            </Grid>
                            <Grid
                              item
                              container
                              lg={9}
                              md={9}
                              sm={9}
                              sx={{
                                border: `1px solid ${theme.palette.text.gray}`,
                                backgroundColor: "#fff",
                              }}
                              gap={1}
                              p={1}
                            >
                              {theArray &&
                                theArray.map((entry, index) => (
                                  <Chip
                                    label={entry}
                                    onDelete={() => {
                                      deleteEntry(index);
                                    }}
                                    deleteIcon={<CancelIcon />}
                                  />
                                ))}
                            </Grid>
                          </Grid>
                        ) : null}
                        <Grid item lg={12} md={12} sm={12} my={1} container>
                          <Grid item lg={3} md={3} sm={3} mt={1}>
                            Add CC :
                          </Grid>
                          <Grid item lg={9} md={9} sm={9} container gap={1}>
                            <Grid item xs={6}>
                              <Field
                                as={MuiTextField}
                                width="100%%"
                                variant="outlined"
                                id="keywords"
                                name="keywords"
                                placeholder="Add Mail for cc"
                                onChange={handleChangeFor}
                                size="small"
                                value={fieldtypeValues}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <MuiButton
                                size="small"
                                name={"Add"}
                                onClick={addEntryClick}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        {theBCCArray?.length ? (
                          <Grid item lg={12} md={12} sm={12} my={1} container>
                            <Grid item lg={3} md={3} sm={3} mt={1}>
                              BCC :
                            </Grid>
                            <Grid
                              item
                              container
                              lg={9}
                              md={9}
                              sm={9}
                              sx={{
                                border: `1px solid ${theme.palette.text.gray}`,
                                backgroundColor: "#fff",
                              }}
                              gap={1}
                              p={1}
                            >
                              {theBCCArray &&
                                theBCCArray.map((entry, index) => (
                                  <Chip
                                    label={entry}
                                    onDelete={() => {
                                      deleteBccEntry(index);
                                    }}
                                    deleteIcon={<CancelIcon />}
                                  />
                                ))}
                            </Grid>
                          </Grid>
                        ) : null}
                        <Grid item lg={12} md={12} sm={12} my={1} container>
                          <Grid item lg={3} md={3} sm={3} mt={1}>
                            Add BCC :
                          </Grid>
                          <Grid item lg={9} md={9} sm={9} container gap={1}>
                            <Grid item xs={6}>
                              <Field
                                as={MuiTextField}
                                width="100%%"
                                variant="outlined"
                                id="keywords"
                                name="keywords"
                                placeholder="Add Mail for bcc"
                                onChange={handleChangeForBcc}
                                size="small"
                                value={bccfieldtypeValues}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <MuiButton
                                size="small"
                                name={"Add"}
                                onClick={addBccEntryClick}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* </Box> */}
                    </DialogContent>

                    <DialogActions className="m-1 my-4">
                      <Grid
                        container
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        justifyContent="flex-end"
                        gap={1}
                      >
                        <Grid item xs={2}>
                          {!Edit ? (
                            <MuiButton
                              type="submit"
                              name="Add"
                              width="100%"
                              loading={updateLoading}
                            />
                          ) : (
                            <MuiButton
                              type="submit"
                              name="Update"
                              width="100%"
                              loading={updateLoading}
                            />
                          )}
                        </Grid>
                        {Edit && (
                          <Grid item xs={2}>
                            <MuiButton
                              name={"Send"}
                              width="100%"
                              loading={sendLoading}
                              onClick={() => {
                                sendadmin_kpiFunction();
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <LoadingBgImage />
        </Grid>
      )}
    </Box>
  );
};

export default connect(mapStateToProps)(KpiConfig);
