import {
  Box,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  Tooltip,
  styled,
  tooltipClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import { theme } from "../../../views/App";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import PaginationUI from "../../../components/pagination/Pagination";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import AutoresponseAction from "../../stores/autoResponse/AutoresponseAction";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import copy from "copy-to-clipboard";

import { format } from "date-fns";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    AutoresponseAction.REQUEST_MAILTRIGGER_LOGS,
  ]),
  mail_triggers_logs: state.mail_triggers_logs.mail_triggers_logs?.data || [],
  pagination: state.mail_triggers_logs.mail_triggers_logs?.data || {},
});

const MailLogs = (props) => {
  const fetchMailLogs = (params) => {
    props.dispatch(AutoresponseAction.requestMailTriggerLogs(params));
  };

  const page_limit = 15;
  const [copied, setCopied] = useState(false);

  const CustomToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme, minHeight }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "5px",
      minWidth: "350px",
      minHeight: minHeight,
      boxShadow: "8px 10px 10px rgba(0, 0, 0, 0.4)",
      padding: "15px",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.primary.main,
    },
  }));

  useEffect(() => {
    let params = {};
    const filter = [];

    if (props.date[0] && props.date[1]) {
      filter.push(
        `["created_on","must","lte","range","${format(
          new Date(props.date[1]),
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          new Date(props.date[0]),
          "yyyy-MM-dd"
        )}"]`
      );
    }
    params = {
      filters: `[${filter}]`,
      page_limit: `${page_limit}`,
    };

    fetchMailLogs(params);
  }, []);

  useEffect(() => {
    const filter = [];
    let params = {};

    if (props.date[0] && props.date[1] && props.filterClicked) {
      filter.push(
        `["created_on","must","lte","range","${format(
          new Date(props.date[1]),
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          new Date(props.date[0]),
          "yyyy-MM-dd"
        )}"]`
      );

      params = {
        filters: `[${filter}]`,
        page_limit: `${page_limit}`,
      };

      fetchMailLogs(params);
      props.setfilterClicked(false);
    }
  }, [props.date, props.filterClicked]);

  useEffect(() => {
    let params = {};
    const filter = [];

    filter.push(
      `["created_on","must","lte","range","${format(
        new Date(),
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        new Date(),
        "yyyy-MM-dd"
      )}"]`
    );

    params = {
      filters: `[${filter}]`,
      page_limit: `${page_limit}`,
    };
    if (props.clearClicked) {
      fetchMailLogs(params);
      props.setClearClicked(false);
    }
  }, [props.clearClicked]);

  const copyToClipboard = (copyText) => {
    copy(copyText);
    setCopied(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "SL NO",
      flex: 1,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "ticket_id",
      headerName: "Ticket ID",
      flex: 1,
      resizable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params, index) => {
        return (
          <>
            <IconButton
              title="Ticket ID Copied"
              color="primary"
              onClick={(event) => {
                event.stopPropagation();
                copyToClipboard(params.row.ticket_id);
              }}
            >
              <ContentCopyRoundedIcon fontSize="small" />
            </IconButton>
            <Snackbar
              message="Ticket Id Copied"
              sx={{ height: "70%" }}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={500}
              onClose={() => setCopied(false)}
              open={copied}
            />
          </>
        );
      },
    },

    // {
    //   field: "feed_id",
    //   headerName: "Feed ID",
    //   flex: 1,
    //   resizable: false,
    //   align: "center",
    //   headerAlign: "center",
    // },

    {
      field: "platform",
      headerName: "Platform",
      flex: 2,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "activities",
      headerName: "Activities",
      flex: 2,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "created_on",
      headerName: "Created On",
      flex: 2,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "recipient_email",
      headerName: "Recipient Email",
      flex: 3,
      resizable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <CustomToolTip
            title={params.row.recipient_email}
            arrow
            placement="right"
            minHeight="100px"
          >
            {params.row.recipient_email}
          </CustomToolTip>
        );
      },
    },
  ];
  let updatedRows = [];

  const returnPlatformName = (plat_id) => {
    return props.platform_list?.find((item) => {
      return item?.platform_id === plat_id;
    })?.platform_alias;
  };

  const returnActivityName = (act_id) => {
    return props.activitiesList?.find((item) => {
      return item?.activity_id === act_id;
    })?.activity_name;
  };
  if (props?.mail_triggers_logs?.hits?.hits?.length) {
    props?.mail_triggers_logs?.hits?.hits?.map((item, index) => {
      updatedRows?.push({
        id: item._id
          ? props.pagination.current_page_no * page_limit + ++index
          : "",
        ticket_id: item._source.ticket_id,
        feed_id: item._source.created_by.feed_id,
        platform: item._source.platform
          ? returnPlatformName(item._source.platform)
          : "",
        created_on: item._source.created_on
          ? format(new Date(item._source.created_on), "dd-MM-yyyy")
          : "",
        activities: item._source.activities
          ? returnActivityName(item._source.activities)
          : "",
        recipient_email: item._source.recipient_email
          ? item._source.recipient_email
          : "",
      });
    });
  }

  // Sort by count in descending order

  // pagination
  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let filter_params = {};
    const filterArray = [];

    if (props.date[0] && props.date[1]) {
      filterArray.push(
        `["created_on","must","lte","range","${format(
          new Date(props.date[1]),
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          new Date(props.date[0]),
          "yyyy-MM-dd"
        )}"]`
      );

      filter_params = {
        filters: `[${filterArray}]`,
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    } else {
      filter_params = {
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchMailLogs(filter_params);
    }
  };

  return (
    <Box
      width={"100%"}
      //   className="bg-warning"
    >
      <LoadingIndicator isActive={props.isRequesting}>
        {props.isRequesting ? (
          <Box sx={{ p: 30 }}>
            <LoadingBgImage />
          </Box>
        ) : (
          <div>
            <DataGridFunction
              columns={columns}
              rows={updatedRows}
              rowHeight={60}
              pagelength={props.pagination.total_page_no}
              page={props.pagination.current_page_no}
              handleChange={handleChange}
              // initialState={{
              //   columns: {
              //     columnVisibilityModel: {
              //       project_unique_id: false,
              //       mapped_organization: false,
              //     },
              //   },
              // }}
            />
            <Box
              sx={{
                paddingTop: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            ></Box>
          </div>
        )}
      </LoadingIndicator>
    </Box>
  );
};

export default connect(mapStateToProps)(MailLogs);
