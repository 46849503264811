import ActionUtility from "../../../utilities/ActionUtility";
import TicketSettingsEffect from "./TicketSettingsEffect";

export default class TicketSettingsAction {
  //ticket settings requests
  static REQUEST_TICKET_SETTINGS =
    "TicketSettingsAction.REQUEST_TICKET_SETTINGS";
  static REQUEST_TICKET_SETTINGS_FINISHED =
    "TicketSettingsAction.REQUEST_TICKET_SETTINGS_FINISHED";

  //ticket settings post
  static REQUEST_POST_TICKET_SETTINGS =
    "TicketSettingsAction.REQUEST_POST_TICKET_SETTINGS";
  static REQUEST_POST_TICKET_SETTINGS_FINISHED =
    "TicketSettingsAction.REQUEST_POST_TICKET_SETTINGS_FINISHED";

  static REQUEST_POST_KPI_CONFIG =
    "TicketSettingsAction.REQUEST_POST_KPI_CONFIG";
  static REQUEST_POST_KPI_CONFIG_FINISHED =
    "TicketSettingsAction.REQUEST_POST_KPI_CONFIG_FINISHED";

  //ticket settings put
  static REQUEST_PUT_TICKET_SETTINGS =
    "TicketSettingsAction.REQUEST_PUT_TICKET_SETTINGS";
  static REQUEST_PUT_TICKET_SETTINGS_FINISHED =
    "TicketSettingsAction.REQUEST_PUT_TICKET_SETTINGS_FINISHED";

  static REQUEST_PUT_KPI_CONFIG = "TicketSettingsAction.REQUEST_PUT_KPI_CONFIG";
  static REQUEST_PUT_KPI_CONFIG_FINISHED =
    "TicketSettingsAction.REQUEST_PUT_KPI_CONFIG_FINISHED";

  static REQUEST_KPI_REPORT_CONFIG =
    "TicketSettingsAction.REQUEST_KPI_REPORT_CONFIG";
  static REQUEST_KPI_REPORT_CONFIG_FINISHED =
    "TicketSettingsAction.REQUEST_KPI_REPORT_CONFIG_FINISHED";

  static REQUEST_GETADMIN_KPI = "TicketSettingsAction.REQUEST_GETADMIN_KPI";
  static REQUEST_GETADMIN_KPI_FINISHED =
    "TicketSettingsAction.REQUEST_GETADMIN_KPI_FINISHED";

  //METHODS

  //Ticket settings get method
  static requestTicketSettings(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketSettingsAction.REQUEST_TICKET_SETTINGS,
        TicketSettingsEffect.requestTicketSettings,
        params
      );
    };
  }

  static requestKpiReportConfig(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketSettingsAction.REQUEST_KPI_REPORT_CONFIG,
        TicketSettingsEffect.requestKpiReportConfig,
        params
      );
    };
  }

  static requestGetAdminKpi(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketSettingsAction.REQUEST_GETADMIN_KPI,
        TicketSettingsEffect.requestGetAdminKpi,
        params
      );
    };
  }

  //Ticket Settings POST method
  static requestPostTicketSettings(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketSettingsAction.REQUEST_POST_TICKET_SETTINGS,
        TicketSettingsEffect.requestPostTicketSettings,
        data
      );
    };
  }

  static requestPostKpiConfig(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketSettingsAction.REQUEST_POST_KPI_CONFIG,
        TicketSettingsEffect.requestPostKpiConfig,
        data
      );
    };
  }

  // Ticket Settings put function
  static requestPutTicketSettings(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketSettingsAction.REQUEST_PUT_TICKET_SETTINGS,
        TicketSettingsEffect.requestPutTicketSettings,
        data,
        id
      );
    };
  }

  static requestPutKpiConfig(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketSettingsAction.REQUEST_PUT_KPI_CONFIG_FINISHED,
        TicketSettingsEffect.requestPutKpiConfig,
        data,
        id
      );
    };
  }
}
