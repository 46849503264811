import ActionUtility from "../../../../utilities/ActionUtility";
import TelegramIntermediateEffect from "./TelegramIntermediateEffect";

export default class TelegramIntermediateAction {
  static REQUEST_GET_TELEGRAM_INTERMEDIATE =
    "TelegramIntermediateAction.REQUEST_GET_TELEGRAM_INTERMEDIATE";
  static REQUEST_GET_TELEGRAM_INTERMEDIATE_FINISHED =
    "TelegramIntermediateAction.REQUEST_GET_TELEGRAM_INTERMEDIATE_FINISHED";

  static REQUEST_GET_PROPERTYWISE_CHANNELS =
    "TelegramIntermediateAction.REQUEST_GET_PROPERTYWISE_CHANNELS";
  static REQUEST_GET_PROPERTYWISE_CHANNELS_FINISHED =
    "TelegramIntermediateAction.REQUEST_GET_PROPERTYWISE_CHANNELS_FINISHED";

  static REQUEST_GET_SEARCH_TELEGRAM_BOTS =
    "TelegramIntermediateAction.REQUEST_GET_SEARCH_TELEGRAM_BOTS";
  static REQUEST_GET_SEARCH_TELEGRAM_BOTS_FINISHED =
    "TelegramIntermediateAction.REQUEST_GET_SEARCH_TELEGRAM_BOTS_FINISHED";

  static REQUEST_GET_TELEGRAM_INTERMEDIATE_EXPORT =
    "TelegramIntermediateAction.REQUEST_GET_TELEGRAM_INTERMEDIATE_EXPORT";
  static REQUEST_GET_TELEGRAM_INTERMEDIATE_EXPORT_FINISHED =
    "TelegramIntermediateAction.REQUEST_GET_TELEGRAM_INTERMEDIATE_EXPORT_FINISHED";

  static REQUEST_POST_TELEGRAM_INTERMEDIATE =
    "TelegramIntermediateAction.REQUEST_POST_TELEGRAM_INTERMEDIATE";
  static REQUEST_POST_TELEGRAM_INTERMEDIATE_FINISHED =
    "TelegramIntermediateAction.REQUEST_POST_TELEGRAM_INTERMEDIATE_FINISHED";

  static REQUEST_POST_EXTRACT_MESSAGES =
    "TelegramIntermediateAction.REQUEST_POST_EXTRACT_MESSAGES";
  static REQUEST_POST_EXTRACT_MESSAGES_FINISHED =
    "TelegramIntermediateAction.REQUEST_POST_EXTRACT_MESSAGES_FINISHED";

  static REQUEST_PUT_TELEGRAM_INTERMEDIATE =
    "TelegramIntermediateAction.REQUEST_PUT_TELEGRAM_INTERMEDIATE";
  static REQUEST_PUT_TELEGRAM_INTERMEDIATE_FINISHED =
    "TelegramIntermediateAction.REQUEST_PUT_TELEGRAM_INTERMEDIATE_FINISHED";

  static REQUEST_PUT_TELEGRAM_INTERMEDIATE_BULK_UPDATE =
    "TelegramIntermediateAction.REQUEST_PUT_TELEGRAM_INTERMEDIATE_BULK_UPDATE";
  static REQUEST_PUT_TELEGRAM_INTERMEDIATE_BULK_UPDATE_FINISHED =
    "TelegramIntermediateAction.REQUEST_PUT_TELEGRAM_INTERMEDIATE_BULK_UPDATE_FINISHED";

  static REQUEST_DEL_TELEGRAM_INTERMEDIATE =
    "TelegramIntermediateAction.REQUEST_DEL_TELEGRAM_INTERMEDIATE";
  static REQUEST_DEL_TELEGRAM_INTERMEDIATE_FINISHED =
    "TelegramIntermediateAction.REQUEST_DEL_TELEGRAM_INTERMEDIATE_FINISHED";

  static REQUEST_TELEGRAM_SEARCH =
    "TelegramIntermediateAction.REQUEST_TELEGRAM_SEARCH";
  static REQUEST_TELEGRAM_SEARCH_FINISHED =
    "TelegramIntermediateAction.REQUEST_TELEGRAM_SEARCH_FINISHED";

  static REQUEST_TELEGRAM_SUBSCRIBE =
    "TelegramIntermediateAction.REQUEST_TELEGRAM_SUBSCRIBE";
  static REQUEST_TELEGRAM_SUBSCRIBE_FINISHED =
    "TelegramIntermediateAction.REQUEST_TELEGRAM_SUBSCRIBE_FINISHED";

  static REQUEST_POST_TO_DICOVERED_DATA =
    "TelegramIntermediateAction.REQUEST_POST_TO_DICOVERED_DATA";
  static REQUEST_POST_TO_DICOVERED_DATA_FINISHED =
    "TelegramIntermediateAction.REQUEST_POST_TO_DICOVERED_DATA_FINISHED";

  static REQUEST_TELEGRAM_SEARCH_HISTORY =
    "TelegramIntermediateAction.REQUEST_TELEGRAM_SEARCH_HISTORY";
  static REQUEST_TELEGRAM_SEARCH_HISTORY_FINISHED =
    "TelegramIntermediateAction.REQUEST_TELEGRAM_SEARCH_HISTORY_FINISHED";

  static requestGetTelegramIntermediate(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramIntermediateAction.REQUEST_GET_TELEGRAM_INTERMEDIATE,
        TelegramIntermediateEffect.requestGetTelegramIntermediate,
        params,
        callback
      );
    };
  }

  static requestGetPropertywiseChannels(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramIntermediateAction.REQUEST_GET_PROPERTYWISE_CHANNELS,
        TelegramIntermediateEffect.requestGetPropertywiseChannels,
        params,
        callback
      );
    };
  }

  static requestGetSearchTelegramBots(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramIntermediateAction.REQUEST_GET_SEARCH_TELEGRAM_BOTS,
        TelegramIntermediateEffect.requestGetSearchTelegramBots,
        params,
        callback
      );
    };
  }

  static requestGetTelegramIntermediateExport(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramIntermediateAction.REQUEST_GET_TELEGRAM_INTERMEDIATE_EXPORT,
        TelegramIntermediateEffect.requestGetTelegramIntermediateExport,
        params,
        callback
      );
    };
  }

  static requestTelegramSearch(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramIntermediateAction.REQUEST_TELEGRAM_SEARCH,
        TelegramIntermediateEffect.requestTelegramSearch,
        params,
        callBack
      );
    };
  }

  static requestTelegramSearchHistory(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramIntermediateAction.REQUEST_TELEGRAM_SEARCH_HISTORY,
        TelegramIntermediateEffect.requestTelegramSearchHistory,
        params,
        callBack
      );
    };
  }

  static requestTelegramSubscribe(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramIntermediateAction.REQUEST_TELEGRAM_SUBSCRIBE,
        TelegramIntermediateEffect.requestTelegramSubscribe,
        params,
        callBack
      );
    };
  }

  static requestPostTelegramIntermediate(data, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramIntermediateAction.REQUEST_POST_TELEGRAM_INTERMEDIATE,
        TelegramIntermediateEffect.requestPostTelegramIntermediate,
        data,
        callBackFunction
      );
    };
  }

  static requestPostExtractMessages(data, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramIntermediateAction.REQUEST_POST_EXTRACT_MESSAGES,
        TelegramIntermediateEffect.requestPostExtractMessages,
        data,
        callBackFunction
      );
    };
  }

  static requestPutTelegramIntermediate(data, id, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramIntermediateAction.REQUEST_PUT_TELEGRAM_INTERMEDIATE,
        TelegramIntermediateEffect.requestPutTelegramIntermediate,
        data,
        id
      );
    };
  }

  static requestPutTelegramIntermediateBulkUpdate(
    data,
    params,
    callBackFunction
  ) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramIntermediateAction.REQUEST_PUT_TELEGRAM_INTERMEDIATE_BULK_UPDATE,
        TelegramIntermediateEffect.requestPutTelegramIntermediateBulkUpdate,
        data,
        params,
        callBackFunction
      );
    };
  }

  static requestPostToDiscoveredData(data, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramIntermediateAction.REQUEST_POST_TO_DICOVERED_DATA,
        TelegramIntermediateEffect.requestPostToDiscoveredData,
        data,
        callBackFunction
      );
    };
  }
}
