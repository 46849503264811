import BaseReducer from "../../../utilities/BaseReducer";
import AutoresponseAction from "./AutoresponseAction";

export default class MailTriggerLogsReduser extends BaseReducer {
  initialState = {
    mail_triggers_logs: [],
  };

  [AutoresponseAction.REQUEST_MAILTRIGGER_LOGS_FINISHED](state, action) {
    return {
      ...state,
      mail_triggers_logs: action.payload,
    };
  }
}
