import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import { connect } from "react-redux";
import {
  BorderContainer,
  ModelScreenContainer,
  ShadowContainer,
} from "../../../../../components/componentLayout/ComponentLayout";
import CircularLoadingIndicator from "../../../../../components/loading-indicator/CircularLoadingIndicator";
import AutoCompleteComponent from "../../../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../../../components/muiButton/MuiButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MuiSelectField from "../../../../../components/muiSelectField/MuiSelectField";
import {
  returnLogo,
  returnPlatformDropdownLogos,
} from "../../../../../components/ticketPartials";
import MuiAutocomplete from "../../../../../components/muiSelectField/MuiAutocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function PlatformFilterPopper(props) {
  let { isRequesting } = props;

  const [platform, setPlatform] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).platform;
    } else {
      return null;
    }
  });

  const [ratings_Value, set_ratings_Value] = useState(null);

  const [activities, setActivities] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).activities;
    } else {
      return [];
    }
  });

  //platform callback function
  const PlatformHandleChange = (event, platform) => {
    setPlatform(platform);
    setActivities([]);
  };

  //Activities callback function
  // const ActivitiesHandleChange = (event, newValue) => {
  //   const selectedOption = newValue[newValue.length - 1];
  //   const isPresent = activities.some(
  //     (obj) => obj.activity_id === selectedOption.activity_id
  //   );
  //   let updatedValue = [];
  //   if (isPresent) {
  //     updatedValue = activities.filter(
  //       (obj) => obj.activity_id !== selectedOption.activity_id
  //     );
  //   } else {
  //     updatedValue = [...activities, selectedOption];
  //   }
  //   setActivities(updatedValue);
  // };

  const ActivitiesHandleChange = (event, newValue) => {
    if (newValue.length === 0) {
      setActivities([]);
      return;
    }

    const selectedOption = newValue[newValue.length - 1];
    const isPresent = activities.some(
      (obj) => obj.activity_id === selectedOption.activity_id
    );
    let updatedValue = [];
    if (isPresent) {
      updatedValue = activities.filter(
        (obj) => obj.activity_id !== selectedOption.activity_id
      );
    } else {
      updatedValue = [...activities, selectedOption];
    }
    setActivities(updatedValue);
  };

  const handleDeleteActivity = (activity) => {
    const updatedActivities = activities.filter(
      (obj) => obj.activity_id !== activity.activity_id
    );
    setActivities(updatedActivities);
  };

  useEffect(() => {
    let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
    if (filters) {
      setPlatform(filters.platform && filters.platform);
      setActivities(filters.activities && filters.activities);
    }
  }, [props.open]);

  //activities list Assigning
  let activitiesList = [];
  let activitiesData = JSON.parse(localStorage.getItem("activities"));

  // 8 = Facebook Reply
  // 22 = GMB Question Answers
  // Post Mention Comment Replies Linked id = 40
  // youtube videos = 28

  const activities_to_hide = ["8", "22", "40", "28"];

  if (activitiesData !== null) {
    if (activitiesData.hits.hits) {
      activitiesData.hits.hits
        ?.filter((item) => !activities_to_hide.includes(item._id))
        ?.map((item) => {
          if (platform !== null) {
            if (
              item._source.platform.platform_name === platform.platform_name
            ) {
              activitiesList.push({
                activity_id: item._id,
                activity_name: item._source.activities,
              });
            }
          }
        });
    }
  }

  activitiesList.sort((a, b) =>
    a?.activity_name.localeCompare(b.activity_name)
  );
  activitiesList = [...new Set(activitiesList)];

  let [platformError, setplatformError] = useState(false);

  //******************SAVE FILTERS FUNCTION************************
  async function handleSaveFilters() {
    if (!platform) {
      setplatformError(true);

      setTimeout(() => {
        setplatformError(false);
      }, 1000);
    } else {
      let filters = JSON.parse(localStorage.getItem("mentionsFilters"));

      if (filters) {
        filters.platform = platform;
        filters.activities = activities;
        filters.ratings_Value = null;
        filters.pageNumber = `0`;

        localStorage.setItem("mentionsFilters", JSON.stringify(filters));
      } else {
        localStorage.setItem(
          "mentionsFilters",
          JSON.stringify({
            platform,
            activities,
            ratings_Value,
          })
        );
      }

      props.saveFilter(filters);
      props.fetchTicketCount();
    }
  }

  function handleClearFilters() {
    if (platform !== null || (activities && activities.length !== 0)) {
      setPlatform(null);
      setActivities([]);
      props.clearFilter("platform");

      localStorage.removeItem("mentionsFilters");
    }
  }

  let uniqueList = [];

  props.filteredPlatformList.sort((a, b) =>
    a?.platform_alias.localeCompare(b.platform_alias)
  );
  uniqueList = [...new Set(props.filteredPlatformList)];

  return (
    <BorderContainer>
      <ClickAwayListener onClickAway={props.handleClose}>
        <div
          autoFocusItem={props.popopen}
          id="composition-menu"
          aria-labelledby="composition-button"
        >
          <Grid
            container
            // className="bg-warning"
            sx={{
              height: 250,
            }}
          >
            <Typography
              component={"p"}
              className="dashboard-title"
              sx={{ fontSize: 18 }}
            >
              Platforms
            </Typography>

            <Grid
              item
              lg={12}
              md={12}
              laptop={12}
              columnGap={2}
              rowGap={1}
              // className="bg-warning"
              sx={{ marginTop: 2, display: "flex", flexWrap: "wrap" }}
            >
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}
                sx={{
                  animation: platformError && "bounce 0.5s linear",
                  "@keyframes bounce": {
                    "0%, 20%, 50%, 80%, 100%": {
                      transform: "translateY(0)",
                    },
                    "40%": {
                      transform: "translateY(-30px)",
                    },
                    "60%": {
                      transform: "translateY(-15px)",
                    },
                  },
                }}
              >
                <AutoCompleteComponent
                  width="100%"
                  list={uniqueList ? uniqueList : []}
                  getOptionLabel={(option) =>
                    option.platform_alias ? option.platform_alias : ""
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <Box width={"auto"} sx={{ mr: "5px" }}>
                        {returnPlatformDropdownLogos(option.platform_logo)}
                      </Box>
                      {option.platform_alias}
                    </Box>
                  )}
                  value={platform}
                  handleChange={PlatformHandleChange}
                  textFieldProps={{
                    placeholder: "Platform",
                  }}
                  size="small"
                />

                {/* <MuiAutocomplete
                  value={platform}
                  list={
                    props.filteredPlatformList ? props.filteredPlatformList : []
                  }
                  textFieldProps={{
                    placeholder: "Platform",
                  }}
                  renderOption={(props, option, { selected }) => (
                    <RadioGroup>
                      <FormControlLabel
                        {...props}
                        control={<Radio checked={selected} size="small" />}
                        label={option.platform_name}
                      />
                    </RadioGroup>
                  )}
                  disableClearable={true}
                  getOptionLabel={(option) =>
                    option.platform_name ? option.platform_name : ""
                  }
                  handleChange={PlatformHandleChange}
                /> */}
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}

                // className="bg-success"
              >
                <AutoCompleteComponent
                  width="100%"
                  value={activities}
                  name="activities"
                  textFieldProps={{
                    placeholder:
                      activities && activities.length !== 0
                        ? null
                        : "Activities",
                  }}
                  disabled={!platform}
                  limitTags={1}
                  list={activitiesList}
                  handleChange={ActivitiesHandleChange}
                  multiple
                  disableCloseOnSelect={true}
                  getOptionLabel={(option) =>
                    option.activity_name ? option.activity_name : ""
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option.activity_name}
                        variant="outlined"
                        size="small"
                        className="mx-1 "
                        onDelete={() => handleDeleteActivity(option)}
                      />
                    ))
                  }
                  renderOption={(props, option) => {
                    const isPresent = activities.some(
                      (obj) => obj.activity_id === option.activity_id
                    );

                    return (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={isPresent}
                        />
                        {option.activity_name}
                      </li>
                    );
                  }}
                  size="small"
                />

                {/* <MuiAutocomplete
                  value={activities}
                  list={activitiesList}
                  disabled={!platform}
                  textFieldProps={{
                    placeholder:
                      activities && activities.length !== 0
                        ? null
                        : "Activities",
                  }}
                  multiple
                  limitTags={1}
                  renderOption={(props, option, { selected }) => (
                    <RadioGroup>
                      <FormControlLabel
                        {...props}
                        control={<Radio checked={selected} size="small" />}
                        label={option.activity_name}
                      />
                    </RadioGroup>
                  )}
                  disableClearable={true}
                  getOptionLabel={(option) =>
                    option.activity_name ? option.activity_name : ""
                  }
                  handleChange={ActivitiesHandleChange}
                /> */}
              </Grid>
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              laptop={12}
              columnGap={2}
              rowGap={1}
              sx={{
                marginTop: 2,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Grid item>
                <MuiButton
                  variant="outlined"
                  width={"103px"}
                  height={"35px"}
                  type="submit"
                  name={"Reset"}
                  loading={false}
                  backgroundColor={"white"}
                  noTextOnLoading={true}
                  onClick={() => {
                    handleClearFilters();
                  }}
                />
              </Grid>
              <Grid item>
                <MuiButton
                  variant="contained"
                  width={"103px"}
                  height={"35px"}
                  type="submit"
                  name={"Apply"}
                  loading={false}
                  noTextOnLoading={true}
                  onClick={() => {
                    handleSaveFilters();
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </ClickAwayListener>
    </BorderContainer>
  );
}

export default PlatformFilterPopper;
