import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import ReactWordcloud from "react-wordcloud";
import Description from "./descriptionContainer/Description";
import Logo from "./logoContainer/Logo";
import { Box, Grid, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import "tippy.js/dist/tippy.css";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import PaginationUI from "../../../components/pagination/Pagination";
import { axiosRequest } from "../../../utilities/FetchRequest";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const WordCloudReport = (props) => {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchData(props.DateFilter);
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.DateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchData(dateRange) {
    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["reports_on_word_cloud"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (response.hasOwnProperty("message")) {
          setData(null);
          setApiError(response.message);
        } else {
          setData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setData(null);
        setApiError(err.message);
        setLoading(false);
      });
  }

  let words = [];

  data &&
    data.ticket_report &&
    data.ticket_report.reports_on_word_cloud &&
    data.ticket_report.reports_on_word_cloud.length > 0 &&
    data.ticket_report.reports_on_word_cloud.map((item) => {
      words.push({
        text: item.key,
        value: item.count,
        percentage: item.percentage,
      });
    });

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      data?.ticket_report?.reports_on_word_cloud?.map((item) => {
        exportData.push({
          Keywords: item.key,
          Mention: prefixZero_for_singleDigit(item.count),
          percentage: `${item.percentage}%`,
        });
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, data]);

  const positive_callbacks = {
    getWordColor: (word) => {
      if (word.value > 0 && word.value < 20) {
        return "#48b91b";
      } else if (word.value > 20 && word.value < 60) {
        return "#53a545";
      } else {
        return "#2e6a0f";
      }
    },
  };

  const negative_callbacks = {
    getWordColor: (word) => {
      if (word.value > 0 && word.value < 20) {
        return "#f50707";
      } else if (word.value > 20 && word.value < 60) {
        return "#e05b4c";
      } else {
        return "#f08069";
      }
    },
  };

  const options = {
    // fontSize: "100%",
    // rotations: 2,
    // rotationAngles: [0, 0],
    // fontSizes: [20, 45, 65],

    colors: [
      `${theme.platform.color1}`,
      `${theme.platform.color2}`,
      `${theme.platform.color3}`,
      `${theme.platform.color4}`,
      `${theme.platform.color5}`,
      `${theme.platform.color6}`,
      `${theme.platform.color7}`,
      `${theme.platform.color8}`,
      `${theme.platform.color9}`,
      `${theme.platform.color10}`,
      `${theme.platform.color11}`,
      `${theme.platform.color12}`,
      `${theme.platform.color13}`,

      `${theme.platform.color17}`,
      `${theme.platform.color18}`,
      `${theme.platform.color19}`,
      `${theme.platform.color20}`,
    ],
    enableTooltip: true,
    deterministic: false,
    fontFamily: "impact",
    fontSizes: [12, 70],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 2,
    rotations: 3,
    rotationAngles: [0],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000,
  };

  let columns = [
    {
      field: "id",
      headerName: "Sl No",
      resizable: true,
      align: "left",
      headerAlign: "left",
      hide: true,
    },
    {
      field: "keyword",
      headerName: "Keywords",
      flex: 1,
      resizable: true,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "count",
      headerName: "Mention",
      flex: 1,
      resizable: true,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "percentage",
      headerName: "Percentage",
      flex: 1,
      resizable: true,
      align: "left",
      headerAlign: "left",
    },
  ];

  let rows = [];

  words &&
    words.map((item, index) => {
      rows.push({
        id: index + 1,
        keyword: item.text,
        count: item.value,
        percentage: prefixZero_for_singleDigit(item.percentage),
      });
    });

  const totalRows = rows && rows.length;
  const paginationSize = 10;
  const totalPages = Math.ceil(totalRows && totalRows / paginationSize);

  const [currentPage, setCurrentPage] = useState(0);

  const handleChange = (event, value) => {
    setCurrentPage(value - 1);
  };
  const startIndex = currentPage * paginationSize;
  const endIndex = startIndex + paginationSize;

  const updatedRows = rows.slice(startIndex, endIndex);

  const callbacks = {
    getWordTooltip: (word) =>
      `${word.text}  ${word.value}(${word.percentage}%)`,
  };

  return (
    <LoadingIndicator isActive={loading}>
      <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flex: "12",
        }}
      >
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {apiError ? (
            <Box
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flex: "12",
              }}
            >
              {apiError}
            </Box>
          ) : data?.ticket_report?.reports_on_word_cloud?.length > 0 ? (
            <Box
              className="scrollable"
              sx={{
                padding: "12px",
                overflowY: "auto",
                "& .MuiDataGrid-virtualScroller": {
                  "&::-webkit-scrollbar": {
                    width: "3px",
                    height: "3px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#b0d2da",
                    borderRadius: "3px",
                    "&:hover": {
                      backgroundColor: "#85bac6",
                    },
                  },
                },
              }}
            >
              <Grid container lg={12} md={12} sm={12} gap={1}>
                <Grid
                  item
                  lg={7.9}
                  md={12}
                  sm={12}
                  sx={{
                    p: 1,
                    height: 400,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "solid 1px #ddd",
                    background: "#fff",
                  }}
                >
                  <ReactWordcloud
                    callbacks={callbacks}
                    words={words}
                    options={options}
                  />
                </Grid>
                <Grid item lg={3.9} md={12} sm={12} sx={{ height: 400 }}>
                  <DataGridFunction
                    rows={updatedRows}
                    columns={columns}
                    height={"100%"}
                    rowHeight={50}
                    rowBorder={false}
                    rowColor={true}
                    headerColor={true}
                    headerHeight={50}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  paddingTop: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <PaginationUI
                  pagelength={totalPages}
                  page={currentPage}
                  handleChange={handleChange}
                  variant={"outlined"}
                />
              </Box>
            </Box>
          ) : loading ? (
            <Box
              sx={{
                width: "100%",
                height: "30vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingBgImage />
            </Box>
          ) : (
            <NoDataFound />
          )}
        </Box>
      </Box>
    </LoadingIndicator>
  );
};

export default WordCloudReport;
