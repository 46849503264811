import BaseReducer from "../../../utilities/BaseReducer";
import GoogleAlertConfigAction from "./GoogleAlertConfigAction";

// GoogleAlertConfigReduser
export default class GoogleAlertConfigReduser extends BaseReducer {
  initialState = {
    google_alerts_config: [],
  };
  [GoogleAlertConfigAction.REQUEST_GOOGLE_ALERT_CONFIG_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      google_alerts_config: action.payload,
    };
  }
}
