import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import "./cssFiles/OverAllMentionsPaltformBased.css";
import { Box, Typography, Grid } from "@mui/material";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { prefixZero_for_singleDigit } from "../../../components/ticketPartials";

function ChannelWiseSentimentDistribution_mentions(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  useEffect(() => {
    (async function CallApi() {
      setLoading(true);

      await fetchData(props.DateFilter);
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);

        await fetchData(props.DateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchData(dateRange) {
    let API = `${
      environment.api.mentionReports
    }?report_type=["count"]&reports=["platform_wise_mentions_sentiment"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (response.hasOwnProperty("message")) {
          setData(null);
          setApiError(response.message);
        } else {
          setData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setData(null);
        setApiError(err.message);
        setLoading(false);
      });

    // return fetch(
    //   `${
    //     environment.api.sentimentalAnalysisReports
    //   }?report_type=["count"]&reports=["platform_wise_mentions_sentiment"]${
    //     props.DateFilter ? `&from=${dateRange[0]}&to=${dateRange[1]}` : ""
    //   }`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((response) => {
    //     setData(response);
    //   });
  }

  let total_negative, total_neutral, total_positive, total_volume;

  if (
    data &&
    data.ticket_report &&
    data.ticket_report.platform_wise_mentions_sentiment
  ) {
    ({ total_negative, total_neutral, total_positive, total_volume } =
      data.ticket_report.platform_wise_mentions_sentiment);
  }

  let NoDataFoundBool =
    !data?.ticket_report?.platform_wise_mentions_sentiment ||
    (data &&
      data.ticket_report &&
      data.ticket_report?.platform_wise_mentions_sentiment?.buckets &&
      data.ticket_report.platform_wise_mentions_sentiment.buckets.length === 0);

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  let columns = [
    {
      field: "platforms",
      headerName: "Platforms",
      resizable: true,
      flex: 2,
      minWidth: 150,
    },
    {
      field: "positive",
      headerName: "Positive",
      resizable: true,
      flex: 2,
      // minWidth: 150,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "negative",
      headerName: "Negative",
      resizable: true,
      flex: 2,
      // minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "neutral",
      headerName: "Neutral",
      resizable: true,
      flex: 2,
      // minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "total",
      headerName: "Total",
      resizable: true,
      flex: 2,
      // minWidth: 150,
      headerAlign: "center",
      align: "center",
      cellClassName: "boldRow",
    },
  ];
  let rows = [];

  if (props?.platformList?.length > 0) {
    let counter = 0;
    props.platformList.map((platform, index) => {
      data?.ticket_report?.platform_wise_mentions_sentiment?.buckets?.map(
        (item, i) => {
          if (platform.platform_name === item.key) {
            rows.push({
              id: ++counter,
              platforms: platform.platform_alias,
              positive:
                data?.ticket_report?.platform_wise_mentions_sentiment?.buckets
                  ?.length > 0 &&
                data.ticket_report.platform_wise_mentions_sentiment.buckets.filter(
                  (platformData) => platformData.key === platform.platform_name
                ).length > 0
                  ? prefixZero_for_singleDigit(
                      data.ticket_report.platform_wise_mentions_sentiment.buckets
                        .filter(
                          (platformData) =>
                            platformData.key === platform.platform_name
                        )[0]
                        .postive_negative_neutral_sentiment.buckets.filter(
                          (sentimentData) =>
                            sentimentData.key === "positive_sentiment"
                        )[0].doc_count
                    )
                  : "0",
              negative:
                data?.ticket_report?.platform_wise_mentions_sentiment?.buckets
                  ?.length > 0 &&
                data.ticket_report.platform_wise_mentions_sentiment.buckets.filter(
                  (platformData) => platformData.key === platform.platform_name
                ).length > 0
                  ? prefixZero_for_singleDigit(
                      data.ticket_report.platform_wise_mentions_sentiment.buckets
                        .filter(
                          (platformData) =>
                            platformData.key === platform.platform_name
                        )[0]
                        .postive_negative_neutral_sentiment.buckets.filter(
                          (sentimentData) =>
                            sentimentData.key === "negative_sentiment"
                        )[0].doc_count
                    )
                  : "0",
              neutral:
                data?.ticket_report?.platform_wise_mentions_sentiment?.buckets
                  ?.length > 0 &&
                data.ticket_report.platform_wise_mentions_sentiment.buckets.filter(
                  (platformData) => platformData.key === platform.platform_name
                ).length > 0
                  ? prefixZero_for_singleDigit(
                      data.ticket_report.platform_wise_mentions_sentiment.buckets
                        .filter(
                          (platformData) =>
                            platformData.key === platform.platform_name
                        )[0]
                        .postive_negative_neutral_sentiment.buckets.filter(
                          (sentimentData) =>
                            sentimentData.key === "neutral_sentiment"
                        )[0].doc_count
                    )
                  : "0",
              neutral:
                data?.ticket_report?.platform_wise_mentions_sentiment?.buckets
                  ?.length > 0 &&
                data.ticket_report.platform_wise_mentions_sentiment.buckets.filter(
                  (platformData) => platformData.key === platform.platform_name
                ).length > 0
                  ? prefixZero_for_singleDigit(
                      data.ticket_report.platform_wise_mentions_sentiment.buckets
                        .filter(
                          (platformData) =>
                            platformData.key === platform.platform_name
                        )[0]
                        .postive_negative_neutral_sentiment.buckets.filter(
                          (sentimentData) =>
                            sentimentData.key === "neutral_sentiment"
                        )[0].doc_count
                    )
                  : "0",
              total:
                data &&
                data.ticket_report &&
                data.ticket_report.platform_wise_mentions_sentiment &&
                data.ticket_report.platform_wise_mentions_sentiment.buckets &&
                data.ticket_report.platform_wise_mentions_sentiment.buckets
                  .length > 0 &&
                data.ticket_report.platform_wise_mentions_sentiment.buckets.filter(
                  (platformData) => platformData.key === platform.platform_name
                ).length > 0
                  ? prefixZero_for_singleDigit(
                      data.ticket_report.platform_wise_mentions_sentiment.buckets.filter(
                        (platformData) =>
                          platformData.key === platform.platform_name
                      )[0].doc_count
                    )
                  : "0",
            });
          }
        }
      );
    });

    rows.push({
      id: rows.length + 1,
      platforms: "Total",
      positive: total_positive ? prefixZero_for_singleDigit(total_positive) : 0,
      negative: total_negative ? prefixZero_for_singleDigit(total_negative) : 0,
      neutral: total_neutral ? prefixZero_for_singleDigit(total_neutral) : 0,
      total: total_volume ? prefixZero_for_singleDigit(total_volume) : 0,
    });
  }

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      rows?.map((item) => {
        exportData?.push({
          Platform: item?.platforms,
          Positive: item?.positive,
          Negative: item.negative,
          Neutral: item.neutral,
          Total: item.total,
        });
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, rows]);

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <Box
          sx={{
            height: "100%",
          }}
        >
          {apiError ? (
            <Box
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flex: "12",
              }}
            >
              {apiError}
            </Box>
          ) : !loading && !NoDataFoundBool && props.dataGridHeight.current ? (
            <Box
              sx={{
                padding: "12px",
                height: "100%",
              }}
            >
              <Grid container lg={12} md={12} sm={12}>
                <DataGridFunction
                  rows={rows}
                  columns={columns}
                  onCellClick={onCellClick}
                  height={props.dataGridHeight.current}
                  rowHeight={50}
                  rowBorder={false}
                  rowColor={true}
                  headerColor={true}
                  headerHeight={80}
                  getRowClassName={(params) =>
                    (params.row.platforms == "Total" ? "boldRow" : "") +
                    (params.id % 2 === 0 ? " oddRow" : "")
                  }
                />
              </Grid>
            </Box>
          ) : (
            !loading && <NoDataFound />
          )}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage />
        </Box>
      )}
    </LoadingIndicator>
  );
}

export default ChannelWiseSentimentDistribution_mentions;
