import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import "./competitors.css";
import { connect } from "react-redux";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import BrandCompitatorAction from "../../stores/brandCompitator/BrandCompitatorAction";
import environment from "environment";
import { theme } from "../../../views/App";
import { axiosRequest } from "../../../utilities/FetchRequest";
import CommentIcon from "@mui/icons-material/Comment";
import CompetatorAnalysisDataMain from "./allMentions/CompetatorAnalysisDataMain";
import BrandCompitator from "../brandCompitator/BrandCompitator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    BrandCompitatorAction.REQUEST_BRAND_COMPITATOR,
    BrandCompitatorAction.REQUEST_BRAND_COMPITATOR_FILTER,
    // PlatformAction.REQUEST_PLATFORM,
  ]),
  brandCompitator: state.brandCompitator.brandCompitator.hits || [],
  brandCompitatorFilter:
    state.brandCompitatorFilter.brandCompitatorFilter.hits || [],
  pagination: state.brandCompitator.brandCompitator || [],
  platform: state.platform.platform || [],
});
const CompetatorsListview = (props) => {
  function fetchBrandCompitator(params) {
    props.dispatch(BrandCompitatorAction.requestBrandCompitator(params));
  }

  function fetchBrandCompitatorFilter(param) {
    props.dispatch(BrandCompitatorAction.requestBrandCompitatorFilter(param));
  }
  useEffect(() => {
    const params = {
      page_number: 0,
      page_limit: `none`,
    };

    fetchBrandCompitator(params);

    const param = {
      page_limit: "none",
    };
    fetchBrandCompitatorFilter(param);
    // fetchOrgPlatforms();
  }, []);
  let brandCompitatorList = [];

  if (props.brandCompitatorFilter.hits) {
    props.brandCompitatorFilter.hits.forEach((value) => {
      if (value._source.platform === "Twitter") {
        brandCompitatorList.push(value._source.competitor_account_name);
      } else {
        brandCompitatorList.push(value._source.brand_competitor_account_name);
      }
    });
  }
  brandCompitatorList.sort((a, b) => a.localeCompare(b));
  brandCompitatorList = [...new Set(brandCompitatorList)];
  let brandAccountList = [];
  if (props.brandCompitatorFilter.hits) {
    props.brandCompitatorFilter.hits.forEach((value) => {
      brandAccountList.push(value._source?.account?.account_name);
    });
  }
  brandAccountList.sort((a, b) => a.localeCompare(b));
  brandAccountList = [...new Set(brandAccountList)];
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("activeCompetitorsTab", newValue);
  };

  useEffect(() => {
    // Retrieve active tab from local storage on component mount
    const storedActiveTab = localStorage.getItem("activeCompetitorsTab");
    if (storedActiveTab) {
      setValue(parseInt(storedActiveTab));
    } else {
      setValue(0);
    }
  }, []);

  useEffect(() => {
    if (value == 0) {
      let ticketFilters = JSON.parse(localStorage.getItem("mentionsFilters"));

      if (ticketFilters?.searchQuery) setSearchQuery(ticketFilters.searchQuery);
      if (ticketFilters?.searchTypeSelected)
        setSearchTypeSelected(ticketFilters.searchTypeSelected);
    } else {
      let mentionFilters = JSON.parse(
        localStorage.getItem("allMentionsParameters")
      );

      setSearchMensionTypeSelected("Mention-ID");
      setSearchMensionQuery("");
    }
  }, [value]);

  // 3 dots opening
  const [popperAnchor, setPopperAnchor] = React.useState(null);

  const handleClick = (event) => {
    setPopperAnchor(popperAnchor ? null : event.currentTarget);
    setMenuAnchor(null);
  };

  const openPopper = Boolean(popperAnchor);

  // menu opening
  const [menuAnchor, setMenuAnchor] = React.useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchor(menuAnchor ? null : event.currentTarget);
    setPopperAnchor(null);
  };

  const openMenu = Boolean(menuAnchor);

  const [filterOpen, setFilterOpen] = useState(false);

  // post plus button close model
  const openCloseModal = useCallback((open) => {
    setFilterOpen(open);
  }, []);

  const [searchTypeSelected, setSearchTypeSelected] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters"))
        .searchTypeSelected
        ? JSON.parse(localStorage.getItem("mentionsFilters")).searchTypeSelected
        : "Content";
    } else {
      return "Content";
    }
  });

  const [searchMensionTypeSelected, setSearchMensionTypeSelected] = useState(
    () => {
      if (localStorage.getItem("allMentionsParameters")) {
        return JSON.parse(localStorage.getItem("allMentionsParameters"))
          .searchTypeSelected
          ? JSON.parse(localStorage.getItem("allMentionsParameters"))
              .searchTypeSelected
          : "Mention-ID";
      } else {
        return "Mention-ID";
      }
    }
  );

  const [searchQuery, setSearchQuery] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).searchQuery;
    } else {
      return "";
    }
  });

  const [searchMensionQuery, setSearchMensionQuery] = useState(() => {
    if (localStorage.getItem("allMentionsParameters")) {
      return JSON.parse(localStorage.getItem("allMentionsParameters"))
        .searchQuery;
    } else {
      return "";
    }
  });

  const handleSearchOptionChange = (event) => {
    setSearchTypeSelected(event.target.value);
  };

  const handleMensionSearchOptionChange = (event) => {
    setSearchMensionTypeSelected(event.target.value);
  };

  const onInputHandleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const onMensionInputHandleChange = (e) => {
    setSearchMensionQuery(e.target.value);
  };

  // for refreshing all tickets pages
  const [searchChanges, setSearchChanges] = useState(false);
  const [searchMensionChanges, setSearchMensionChanges] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      applyFilter();
    }
  };

  const handleMensionsKeyDown = (event) => {
    if (event.key === "Enter") {
      applyMensionsFilter();
    }
  };

  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [filterClicked, setFilterClicked] = useState(false);

  async function applyFilter() {
    setFilterClicked(true);
    var search_bar = document.getElementById("search_bar").value;

    if (searchQuery !== "") {
      let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
      if (filters) {
        if (searchTypeSelected === "Content") {
          async function fetchData() {
            let API = `${environment.api.ticketFeedFilters}?phrase=${searchQuery}`;
            try {
              const response = await axiosRequest(API, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + accessToken,
                },
              });

              const responseText = await response;

              return responseText;
            } catch (error) {
              console.error(error);
              return null;
            }
          }

          const result = await fetchData();
          let Ids = [];
          Ids = result && result.ticket_ids && [...new Set(result.ticket_ids)];

          filters.ticket_ids = Ids ? Ids : [];
          filters.searchQuery = searchQuery;
          filters.searchTypeSelected = searchTypeSelected;
          filters.pageNumber = `0`;
          localStorage.setItem("mentionsFilters", JSON.stringify(filters));
        } else {
          filters.searchQuery = searchQuery;
          filters.searchTypeSelected = searchTypeSelected;
          filters.pageNumber = `0`;

          localStorage.setItem("mentionsFilters", JSON.stringify(filters));
        }
      } else {
        localStorage.setItem(
          "mentionsFilters",
          JSON.stringify({
            searchQuery,
            searchTypeSelected,
          })
        );
      }

      setSearchChanges(true);
    }
  }

  async function applyMensionsFilter() {
    var search_bar = document.getElementById("search_bar").value;
    if (searchMensionQuery !== "") {
      let filters = JSON.parse(localStorage.getItem("allMentionsParameters"));
      if (filters) {
        if (searchMensionTypeSelected === "Content") {
          async function fetchData() {
            try {
              const response = await fetch(
                `${environment.api.mentionFeedFilters}?phrase=${searchMensionQuery}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                  },
                }
              );
              const responseText = await response.json();
              return responseText;
            } catch (error) {
              console.error(error);
              return null;
            }
          }
          const result = await fetchData();
          let Ids = [];

          Ids = result && result.feed_ids && [...new Set(result.feed_ids)];
          filters.mention_ids = Ids ? Ids : [];
          filters.searchQuery = searchMensionQuery;
          filters.searchTypeSelected = searchMensionTypeSelected;
          filters.pageNumber = `0`;
          localStorage.setItem(
            "allMentionsParameters",
            JSON.stringify(filters)
          );
        } else {
          filters.searchQuery = searchMensionQuery;
          filters.searchTypeSelected = searchMensionTypeSelected;
          filters.pageNumber = `0`;

          localStorage.setItem(
            "allMentionsParameters",
            JSON.stringify(filters)
          );
        }
      } else {
        localStorage.setItem(
          "allMentionsParameters",
          JSON.stringify({
            searchQuery,
            searchTypeSelected,
          })
        );
      }

      setSearchMensionChanges(true);
    }
  }

  const [sort_by, setSort_By] = useState("modified_on");
  const [sort_order, set_sort_order] = useState("desc");

  const [mentions_sort_by, setmentions_sort_by] = useState("created_on");
  const [mentions_sort_order, setmentions_sort_order] = useState("asc");

  const [selectionModel, setSelectionModel] = useState(() => {
    if (localStorage.getItem("selected_tickets_for_bulk_update")) {
      return JSON.parse(
        localStorage.getItem("selected_tickets_for_bulk_update")
      );
    } else {
      return [];
    }
  });
  const [mentionsSelectionModel, setmentionsSelectionModel] = useState(() => {
    if (localStorage.getItem("selected_mentions_for_bulk_update")) {
      return JSON.parse(
        localStorage.getItem("selected_mentions_for_bulk_update")
      );
    } else {
      return [];
    }
  });

  const [openBulkAssignDialog, setOpenBulkAssignDialog] = useState(false);
  const [openReplyDialog, setOpenReplyDialog] = useState(false);
  const [openReplyErrorsDialog, setOpenReplyErrorsDialog] = useState(false);

  return (
    <Box
      // pr={1}
      height="inherit"
    >
      <Grid
        item
        container
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justifyContent="space-between"
        alignItems={"center"}
        sx={{
          backgroundColor: `${theme.palette.background.default}`,
          zIndex: 999,
          position: "relative",
          boxShadow: `${theme.shadows}`,
          // borderRadius: 10,
          // boxShadow: `0 3px 10px 1px ${theme.palette.background.primary}`,
          // boxShadow: `0 3px 10px 1px black`,
        }}
        // className="bg-warning"
      >
        <Grid
          item
          container
          xl={7}
          lg={6}
          md={5}
          sm={5}
          xs={5}
          // className="bg-success"
          alignItems={"center"}
          justifyContent="flex-start"
          height={"40px"}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            sx={{
              "& .MuiTabs-indicator": { display: "none" },

              "& .MuiTabs-flexContainer": {
                position: "relative",
                justifyContent: "flex-start",
              },
              "& .MuiTab-root": {
                position: "relative",
                zIndex: 1,
                borderRadius: "0 0 10px 0",
                color: "#2a5e6c",
                border: `1px solid ${theme.palette.background.primary}`,
                backgroundColor: "#d3e2e6",
                "&.Mui-selected": {
                  zIndex: 2,
                  backgroundColor: "#003a4c",
                  color: "white",
                },
              },
            }}
          >
            <Tab
              label={
                <span>
                  {/* <FontAwesomeIcon
                    fontSize={"10px"}
                    //   className="bg-warning"
                    icon={faMessage}
                  ></FontAwesomeIcon> */}
                  <CommentIcon style={{ fontSize: "12px" }} />
                  &nbsp; &nbsp;
                  {"Analysis Data"}
                </span>
              }
              style={{
                textTransform: "none",
                marginLeft: -12,
                minHeight: 0, // set the minimum height to 0
                padding: "10.8px 20px", // adjust the padding to decrease the height
              }}
            />

            <Tab
              label={
                <span>
                  <FontAwesomeIcon
                    fontSize={"10px"}
                    //   className="bg-warning"
                    icon={faAdd}
                  ></FontAwesomeIcon>
                  &nbsp; &nbsp;
                  {"Accounts"}
                </span>
              }
              style={{
                textTransform: "none",
                minHeight: 0, // set the minimum height to 0
                padding: "10.8px 20px", // adjust the padding to decrease the height
              }}
            />
          </Tabs>
        </Grid>
        <Grid
          item
          container
          xl={5}
          lg={6}
          md={7}
          sm={7}
          xs={7}
          height={"40px"}
          // className="bg-info"
          justifyContent={"flex-end"}
          alignItems="center"
        >
          {/* {value === 0 && (
            <Grid item alignItems="center" textAlign={"center"}>
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    borderRadius: "4px",
                    outline: `2px ${theme.palette.borderColor.textField}`,
                    backgroundColor: theme.palette.background.paper,
                    padding: theme.typography.padding,
                    height: "25px",
                    width: "100%",
                  }}
                  id="search_bar"
                  autoComplete={"off"}
                  value={searchMensionQuery}
                  onKeyDown={handleMensionsKeyDown}
                  placeholder={
                    searchMensionTypeSelected === "Mention-ID"
                      ? " Search by Mention-ID"
                      : searchMensionTypeSelected === "Content"
                      ? "Search by Content"
                      : "Search"
                  }
                  onChange={(e) => {
                    onMensionInputHandleChange(e);
                  }}
                  startAdornment={
                    <InputAdornment>
                      <Select
                        // size="small"
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                          "& svg": {
                            color: "white",
                          },
                          borderRadius: "5px",
                          color: "white",
                          outline: `2px ${theme.palette.borderColor.textField}`,
                          backgroundColor: theme.palette.primary.main,
                          padding: theme.typography.padding,
                          boxShadow: theme.shadows,
                          height: "25px",
                          width: "100%",
                        }}
                        value={searchMensionTypeSelected}
                        onChange={handleMensionSearchOptionChange}
                      >
                        <MenuItem value="Mention-ID">Mention-ID</MenuItem>
                        <MenuItem value="Content">Content</MenuItem>
                      </Select>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon
                          sx={{
                            "&.MuiSvgIcon-root": { fontSize: "1.2rem" },
                            color: `${theme.palette.ticketListView.iconColor}`,
                          }}
                          onClick={applyMensionsFilter}
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          )} */}

          {/* <Grid item alignItems="center" textAlign={"center"}>
            <IconButton
              onClick={() => {
                openCloseModal(!filterOpen);
              }}
              title="Filters"
            >
              <FilterAltOutlinedIcon
                sx={{ color: `${theme.palette.ticketListView.headerIcon}` }}
              />
            </IconButton>
          </Grid> */}
        </Grid>
      </Grid>

      <Box
        height={"calc(100% - 40px)"}
        width="100%"
        // className="bg-info"
        position={"relative"}
        sx={{
          flexGrow: 1,
        }}
        // overflow="hidden"
      >
        <Grid
          item
          container
          width={"100%"}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className="scrollable"
          style={{
            height: "100%",
            maxHeight: `${window.innerHeight}px`,
            overflow: "auto",
            position: "relative",
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {value === 0 && (
              <CompetatorAnalysisDataMain
                sort_by={mentions_sort_by}
                sort_order={mentions_sort_order}
                setValue={setValue}
                searchChanges={searchMensionChanges}
                setSearchChanges={setSearchMensionChanges}
                setSearchTypeSelected={setSearchMensionTypeSelected}
                setSearchQuery={setSearchMensionQuery}
                searchQuery={searchMensionQuery}
                open={filterOpen}
                onClose={() => {
                  setFilterOpen(false);
                }}
                selectionModel={mentionsSelectionModel}
                setSelectionModel={setmentionsSelectionModel}
                brandCompitatorList={brandCompitatorList}
                brandAccountList={brandAccountList}
              />
            )}

            {value === 1 && <BrandCompitator />}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default connect(mapStateToProps)(CompetatorsListview);
