import ReleaseHistoryEffect from "./ReleaseHistoryEffect";
import ActionUtility from "../../../../utilities/ActionUtility";

export default class ReleaseHistoryAction {
  static REQUEST_MUSICLABELS_RELEASE_SHADOW_STATUS_WISE =
    "ReleaseHistoryAction.REQUEST_MUSICLABELS_RELEASE_SHADOW_STATUS_WISE";
  static REQUEST_MUSICLABELS_RELEASE_SHADOW_STATUS_WISE_FINISHED =
    "ReleaseHistoryAction.REQUEST_MUSICLABELS_RELEASE_SHADOW_STATUS_WISE_FINISHED";

  static requestMusicLabelsReleaseShadowStatusWise(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleaseHistoryAction.REQUEST_MUSICLABELS_RELEASE_SHADOW_STATUS_WISE,
        ReleaseHistoryEffect.requestMusicLabelsReleaseShadowStatusWise,
        params
      );
    };
  }
}
