import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import BrandModel from "./models/BrandModel";
import BrandPostModel from "./models/BrandPostModel";
import BrandPutModel from "./models/BrandPutModel";
import AnalysisDataModel from "./models/AnalysisDataModel";

export default class BrandCompitatorEffect {
  // calling an API according to the BrandModel
  static async requestBrandCompitator(params) {
    const endpoint = environment.api.brandCompitator;

    return EffectUtility.getToModel(BrandModel, endpoint, params);
  }

  static async requestBrandCompitatorAnalysisData(params) {
    const endpoint = environment.api.competitor_analysis_data;

    return EffectUtility.getToModel(AnalysisDataModel, endpoint, params);
  }

  static async requestBrandCompitatorActivitiesList(params) {
    const endpoint = environment.api.competitor_activities_list;

    return EffectUtility.getToModel(AnalysisDataModel, endpoint, params);
  }

  // Brand filter with page_limit none
  static async requestBrandCompitatorFilter(params) {
    const endpoint = environment.api.brandCompitatorFilter;
    return EffectUtility.getToModel(BrandModel, endpoint, params);
  }

  // Post API
  static async requestPostBrandCompitator(data, callBackFunction) {
    const endpoint = environment.api.brandCompitator + "/add";
    let response = EffectUtility.postToModel(BrandPostModel, endpoint, data);
    response.then((resp) => {
      if (resp.status === "success" && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  // Put API
  static async requestPutBrandCompitator(data, id) {
    const endpoint = environment.api.brandCompitator + "/" + id;

    return EffectUtility.putToModel(BrandPutModel, endpoint, data);
  }
}
