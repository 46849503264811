import React, { useEffect, useRef, useState } from "react";
import "./cssFiles/ResponseManagementPlatformCounts.css";
import environment from "environment";
import Logo from "./logoContainer/Logo";
import Description from "./descriptionContainer/Description";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { theme } from "../../../views/App";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { format, isValid } from "date-fns";
import ReactEcharts from "echarts-for-react";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";

function PageReachBreakdownOvertime(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [insightsData, setInsightsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    setInsightsData(null);
    (async function CallApi() {
      setLoading(true);

      props.account_selected &&
        (await fetchData(props.account_selected, props.DateFilter));

      setLoading(false);
    })();
  }, [props.account_selected]);

  useEffect(() => {
    if (props.clearClicked) {
      setInsightsData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.account_selected, props.DateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      setInsightsData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.account_selected, props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.account_selected, props.filterClicked, props.openDateFilter]);

  async function fetchData(account_id, dateRange) {
    let postsummaryAPI = `${environment.api.facebookReportsummary}?account_id=${
      account_id?.account_id
    }&reports=["page_reach_breakdown_over_time"]${
      props.DateFilter || props.filterClicked
        ? `&from_date=${dateRange[0]}&to_date=${dateRange[1]}`
        : `&from_date=${
            isValid(
              new Date(new Date().getFullYear(), new Date().getMonth(), 1)
            ) &&
            format(
              new Date(new Date().getFullYear(), new Date().getMonth(), 1),
              "yyyy-MM-dd"
            )
          }&to_date=${isValid(new Date()) && format(new Date(), "yyyy-MM-dd")}`
    }`;
    await axiosRequest(postsummaryAPI, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (
          response.hasOwnProperty("error") ||
          response.hasOwnProperty("error_id")
        ) {
          response.hasOwnProperty("error_id")
            ? setApiError(`${response?.message} - ${response?.error_id}`)
            : setApiError(response?.error);
          setInsightsData(null);
        } else {
          setInsightsData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setInsightsData(null);
        setApiError(err.message);
        setLoading(false);
      });
  }

  let NoDataFoundBool = !insightsData && !props.account_selected;

  // Calculate the height of each box based on the maximum length

  const isXl = useMediaQuery(() => theme.breakpoints.up("xl"));
  const isLg = useMediaQuery(() => theme.breakpoints.up("lg"));
  const isLaptop = useMediaQuery(() => theme.breakpoints.up("laptop"));
  const isMd = useMediaQuery(() => theme.breakpoints.up("md"));

  useEffect(() => {
    if (props.DownloadClicked) {
      const transformedArray = Object.entries(
        insightsData[0]?.page_reach_breakdown_over_time.daily_data
      ).map(([date, values]) => ({
        Date: date,
        Viral: values.page_impressions_viral_unique,
        "Non-Viral": values.page_impressions_nonviral_unique,
        Paid: values.page_impressions_paid_unique,
      }));

      props.setexcelData(transformedArray);
    }
  }, [props.DownloadClicked, insightsData]);

  // Define the option with the extracted data

  const categories =
    insightsData &&
    Object.keys(insightsData[0]?.page_reach_breakdown_over_time.daily_data);

  const Viral = categories?.map(
    (date) =>
      insightsData[0]?.page_reach_breakdown_over_time?.daily_data[date]
        ?.page_impressions_viral_unique
  );
  const nonViral = categories?.map(
    (date) =>
      insightsData[0]?.page_reach_breakdown_over_time?.daily_data[date]
        ?.page_impressions_nonviral_unique
  );
  const paidData = categories?.map(
    (date) =>
      insightsData[0]?.page_reach_breakdown_over_time?.daily_data[date]
        ?.page_impressions_paid_unique
  );

  const option = {
    title: {
      text: "",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#283b56",
        },
      },
    },
    legend: {
      data: ["Viral", "Non-Viral", "Paid"],
    },
    toolbox: {
      show: false,
      feature: {
        dataView: { readOnly: false },
        restore: {},
        saveAsImage: {},
      },
    },
    dataZoom: {
      show: false,
      start: 0,
      end: 100,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: true,
        data: categories,
      },
    ],
    yAxis: [
      {
        type: "value",
        scale: true,
        name: "Fans Count",
        max: 30,
        min: 0,
        boundaryGap: [0.2, 0.2],
      },
    ],
    series: [
      {
        name: "Viral",
        type: "bar",
        data: Viral,
        smooth: true,
        itemStyle: {
          color: theme.platform.color1, // Example color for fans bar
        },
      },
      {
        name: "Non-Viral",
        type: "bar",
        smooth: true,
        data: nonViral,
        itemStyle: {
          color: theme.platform.color2, // Example color for fans bar
        },
      },
      {
        name: "Paid",
        type: "bar",
        smooth: true,
        data: paidData,
        itemStyle: {
          color: theme.platform.color3, // Example color for fans bar
        },
      },
    ],
  };

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <>
          {!NoDataFoundBool ? (
            <Box
              style={{
                width: "100%",
                paddingY: "12px",
                paddingRight: "12px",
                height: "100%",
              }}
            >
              {apiError ? (
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    flex: "12",
                  }}
                >
                  {apiError}
                </Box>
              ) : (
                <ReactEcharts
                  onEvents={{
                    click: () => null,
                  }}
                  option={option}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  notMerge={true}
                  lazyUpdate={true}
                />
              )}
            </Box>
          ) : (
            <NoDataFound />
          )}
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage width={"50%"} />
        </Box>
      )}
    </LoadingIndicator>
  );
}

export default PageReachBreakdownOvertime;
