import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { Box, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { axiosRequest } from "../../../utilities/FetchRequest";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { format } from "date-fns";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
function TicketCategoryReport(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [categoryData, setCategoryData] = useState(null);
  const [customfieldCategory, setCustomFieldCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  function getLast3monthStartAndThismonthEndDate() {
    let date = new Date();
    const last3monthStartDate = new Date(
      date.getFullYear(),
      date.getMonth() - 2,
      2
    );
    const last3monthstartDatestring = last3monthStartDate
      .toISOString()
      .slice(0, 10);

    const currentMonthEnddate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      1
    );
    const currentMonthendDatestring = currentMonthEnddate
      .toISOString()
      .slice(0, 10);

    return {
      last3monthStartDate: last3monthstartDatestring,
      currentMonthEnddate: currentMonthendDatestring,
    };
  }

  let { last3monthStartDate, currentMonthEnddate } =
    getLast3monthStartAndThismonthEndDate();

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchCategoryData(last3monthStartDate, currentMonthEnddate);

      await fetchCustomFiledCategory();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setToday(new Date());
        setLoading(true);
        await fetchCategoryData(last3monthStartDate, currentMonthEnddate);

        await fetchCustomFiledCategory();
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      // Assuming props.openDateFilter is provided and valid
      const date = new Date(props.openDateFilter);
      setToday(new Date(props.openDateFilter));

      const firstDateOfLastMonth = new Date(
        date.getFullYear(),
        date.getMonth() - 2,
        1
      );
      const firstDateString = format(firstDateOfLastMonth, "yyyy-MM-dd");

      const endOfCurrentMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      );
      const endDateString = format(endOfCurrentMonth, "yyyy-MM-dd");

      (async function CallApi() {
        setLoading(true);
        await fetchCategoryData(firstDateString, endDateString);

        await fetchCustomFiledCategory();
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchCategoryData(from, to) {
    let API = `${environment.api.ticketReports}?report_type=["count"]&reports=["category_monthwise"]&from=${from}&to=${to}`;
    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (response.hasOwnProperty("message")) {
          setCategoryData(null);
          setApiError(response.message);
        } else {
          setCategoryData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setCategoryData(null);
        setApiError(err.message);
        setLoading(false);
      });
  }

  async function fetchCustomFiledCategory() {
    let API = `${environment.api.baseApi}/custom_ticket_fields?filters=[["field_type","must","","match","category"]]`;
    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (response.hasOwnProperty("message")) {
          setCustomFieldCategory(null);
          setApiError(response.message);
        } else {
          setCustomFieldCategory(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setCustomFieldCategory(null);
        setApiError(err.message);
        setLoading(false);
      });
  }

  let categoriesList = [];
  categoriesList =
    customfieldCategory &&
    customfieldCategory.hits &&
    customfieldCategory.hits.hits &&
    customfieldCategory.hits.hits.length > 0 &&
    customfieldCategory.hits.hits[0]._source.field_values;

  let categoryValuesList = [];

  function AppendCategoryNames(valuesArray) {
    valuesArray &&
      valuesArray.length > 0 &&
      valuesArray.map((item) => {
        categoryValuesList.push(item);
        if (item.sub_value.length > 0) {
          AppendCategoryNames(item.sub_value);
        }
      });
  }

  categoriesList &&
    categoriesList.length > 0 &&
    AppendCategoryNames(categoriesList);

  function convertDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to the month since it is zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const [today, setToday] = useState(new Date());

  const thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastSecondFirstDay = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  ); // Set the date to the first day of the previous month

  const lastThirdFirstDay = new Date(
    today.getFullYear(),
    today.getMonth() - 2,
    1
  ); // Set the date to the first day of the previous month

  let currentMonth = convertDate(thisMonth);
  let lastSecond = convertDate(lastSecondFirstDay);
  let lastThird = convertDate(lastThirdFirstDay);

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };
  let columns = [
    {
      field: "category",
      headerName: "Main category",
      resizable: true,
      flex: 2,
      minWidth: 150,
      renderCell: (params) => (
        <p
          className={`${
            params.row.category == "Total"
              ? "font-weight-bold p-0 m-0"
              : "font-weight-normal p-0 m-0"
          }`}
        >
          {params.row.category}
        </p>
      ),
    },

    {
      field: new Date(lastThird).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
      }),
      headerName: new Date(lastThird).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
      }),
      resizable: true,
      flex: 2,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <p
          className={`${
            params.row.category == "Total"
              ? "font-weight-bold p-0 m-0"
              : "font-weight-normal p-0 m-0"
          }`}
        >
          {
            params.row[
              `${new Date(lastThird).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
              })}`
            ]
          }
        </p>
      ),
    },

    {
      field: new Date(lastSecond).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
      }),
      headerName: new Date(lastSecond).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
      }),
      resizable: true,
      flex: 2,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <p
          className={`${
            params.row.category == "Total"
              ? "font-weight-bold p-0 m-0"
              : "font-weight-normal p-0 m-0"
          }`}
        >
          {
            params.row[
              `${new Date(lastSecond).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
              })}`
            ]
          }
        </p>
      ),
    },

    {
      field: new Date(currentMonth).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
      }),
      headerName: new Date(currentMonth).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
      }),
      resizable: true,
      flex: 2,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <p
          className={`${
            params.row.category == "Total"
              ? "font-weight-bold p-0 m-0"
              : "font-weight-normal p-0 m-0"
          }`}
        >
          {
            params.row[
              `${new Date(currentMonth).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
              })}`
            ]
          }
        </p>
      ),
    },
    {
      field: "total",
      headerName: "Total",
      resizable: true,
      flex: 2,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <p className={"font-weight-bold p-0 m-0"}>{params.row.total}</p>
      ),
    },
    {
      field: `${new Date(currentMonth).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
      })}avg`,
      headerName: `${new Date(currentMonth).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
      })}Avg %`,
      resizable: true,
      flex: 2,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <p
          className={`${
            params.row.category == "Total"
              ? "font-weight-bold p-0 m-0"
              : "font-weight-normal p-0 m-0"
          }`}
        >
          {
            params.row[
              `${new Date(currentMonth).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
              })}avg`
            ]
          }
        </p>
      ),
    },
  ];

  let rows = [];

  if (categoriesList?.length > 0) {
    categoriesList.map((mainCategory, index) => {
      if (categoriesList?.length <= categoriesList.length) {
        const row = {
          id: index + 1,
          category: `${mainCategory.value}(L1)`,
          total:
            categoryData?.ticket_report?.category_monthwise &&
            Object.entries(categoryData?.ticket_report?.category_monthwise)
              ?.slice(4, 5)
              ?.map(([month, monthData], index) => {
                let filteredCategoryData = 0;

                filteredCategoryData =
                  monthData &&
                  monthData.length > 0 &&
                  monthData[0] &&
                  Object.entries(monthData[0]).filter(
                    ([id, count]) => id === mainCategory.id
                  ).length > 0 &&
                  Object.entries(monthData[0]).filter(
                    ([id, count]) => id === mainCategory.id
                  )[0][1] &&
                  Object.entries(monthData[0]).filter(
                    ([id, count]) => id === mainCategory.id
                  )[0][1];

                return filteredCategoryData
                  ? prefixZero_for_singleDigit(filteredCategoryData)
                  : "00";
              }),
        };

        const lastThirdKey = `${new Date(lastThird).toLocaleDateString(
          "en-US",
          {
            month: "short",
            year: "numeric",
          }
        )}`; // Construct the dynamic key

        const lastsecondKey = `${new Date(lastSecond).toLocaleDateString(
          "en-US",
          {
            month: "short",
            year: "numeric",
          }
        )}`; // Construct the dynamic key

        const currentMonthKey = `${new Date(currentMonth).toLocaleDateString(
          "en-US",
          {
            month: "short",
            year: "numeric",
          }
        )}`; // Construct the dynamic key

        const avgKey = `${new Date(currentMonth).toLocaleDateString("en-US", {
          month: "short",
          year: "numeric",
        })}avg`; // Construct the dynamic key
        let lastThirdCount =
          categoryData.ticket_report?.category_monthwise &&
          Object.entries(categoryData.ticket_report.category_monthwise)
            ?.slice(0, 1)
            ?.map(([month, monthData], index) => {
              let filteredCategoryData = 0;

              filteredCategoryData =
                monthData &&
                monthData.length > 0 &&
                monthData[0] &&
                Object.entries(monthData[0]).filter(
                  ([id, count]) => id === mainCategory.id
                ).length > 0 &&
                Object.entries(monthData[0]).filter(
                  ([id, count]) => id === mainCategory.id
                )[0][1] &&
                Object.entries(monthData[0]).filter(
                  ([id, count]) => id === mainCategory.id
                )[0][1];

              return filteredCategoryData ? filteredCategoryData : "0";
            });

        let lastSecondCount =
          categoryData.ticket_report?.category_monthwise &&
          Object.entries(categoryData.ticket_report.category_monthwise)
            ?.slice(1, 2)
            ?.map(([month, monthData], index) => {
              let filteredCategoryData = 0;

              filteredCategoryData =
                monthData &&
                monthData.length > 0 &&
                monthData[0] &&
                Object.entries(monthData[0]).filter(
                  ([id, count]) => id === mainCategory.id
                ).length > 0 &&
                Object.entries(monthData[0]).filter(
                  ([id, count]) => id === mainCategory.id
                )[0][1] &&
                Object.entries(monthData[0]).filter(
                  ([id, count]) => id === mainCategory.id
                )[0][1];

              return filteredCategoryData ? filteredCategoryData : "0";
            });

        let currentMonthCount =
          categoryData.ticket_report?.category_monthwise &&
          Object.entries(categoryData.ticket_report.category_monthwise)
            ?.slice(2, 3)
            ?.map(([month, monthData], index) => {
              let filteredCategoryData = 0;

              filteredCategoryData =
                monthData &&
                monthData.length > 0 &&
                monthData[0] &&
                Object.entries(monthData[0]).filter(
                  ([id, count]) => id === mainCategory.id
                ).length > 0 &&
                Object.entries(monthData[0]).filter(
                  ([id, count]) => id === mainCategory.id
                )[0][1] &&
                Object.entries(monthData[0]).filter(
                  ([id, count]) => id === mainCategory.id
                )[0][1];

              return filteredCategoryData ? filteredCategoryData : "0";
            });

        let avgCount =
          categoryData.ticket_report?.category_monthwise &&
          Object.entries(categoryData.ticket_report.category_monthwise)
            ?.slice(3, 4)
            ?.map(([month, monthData], index) => {
              let filteredCategoryData = 0;

              filteredCategoryData =
                monthData &&
                Object.values(monthData).length > 0 &&
                Object.entries(Object.values(monthData)[0]).filter(
                  ([id, count]) => id === mainCategory.id
                )[0] &&
                Object.values(monthData).length > 0 &&
                Object.entries(Object.values(monthData)[0]).filter(
                  ([id, count]) => id === mainCategory.id
                )[0][1]
                  ? monthData &&
                    Object.values(monthData).length > 0 &&
                    Object.entries(Object.values(monthData)[0]).filter(
                      ([id, count]) => id === mainCategory.id
                    )[0][1]
                  : null;

              return `${
                filteredCategoryData ? filteredCategoryData.toFixed(2) : "0"
              }%`;
            });

        row[lastThirdKey] = prefixZero_for_singleDigit(lastThirdCount);
        row[lastsecondKey] = prefixZero_for_singleDigit(lastSecondCount);
        row[currentMonthKey] = prefixZero_for_singleDigit(currentMonthCount);
        row[avgKey] = prefixZero_for_singleDigit(avgCount);

        rows.push(row);
      }

      if (index + 1 === categoriesList.length) {
        let row = {
          id: categoriesList?.length + 1,
          category: "Total",
          total:
            categoryData.ticket_report?.category_monthwise &&
            categoryData.ticket_report.category_monthwise["total_count"][0]
              ? prefixZero_for_singleDigit(
                  categoryData.ticket_report.category_monthwise[
                    "total_count"
                  ][0].total
                )
              : "00",
        };

        const lastThirdKey = `${new Date(lastThird).toLocaleDateString(
          "en-US",
          {
            month: "short",
            year: "numeric",
          }
        )}`; // Construct the dynamic key

        const lastsecondKey = `${new Date(lastSecond).toLocaleDateString(
          "en-US",
          {
            month: "short",
            year: "numeric",
          }
        )}`; // Construct the dynamic key

        const currentMonthKey = `${new Date(currentMonth).toLocaleDateString(
          "en-US",
          {
            month: "short",
            year: "numeric",
          }
        )}`; // Construct the dynamic key

        const avgKey = `${new Date(currentMonth).toLocaleDateString("en-US", {
          month: "short",
          year: "numeric",
        })}avg`; // Construct the dynamic key

        let lastThirdCount =
          categoryData?.ticket_report?.category_monthwise?.[lastThird] &&
          categoryData.ticket_report.category_monthwise[lastThird][0]
            ? categoryData.ticket_report.category_monthwise[lastThird][0].total
            : 0;

        let lastSecondCount =
          categoryData?.ticket_report?.category_monthwise?.[lastSecond] &&
          categoryData.ticket_report.category_monthwise[lastSecond][0]
            ? categoryData.ticket_report.category_monthwise[lastSecond][0].total
            : 0;

        let currentMonthCount =
          categoryData?.ticket_report?.category_monthwise?.[currentMonth] &&
          categoryData.ticket_report.category_monthwise[currentMonth][0]
            ? categoryData.ticket_report.category_monthwise[currentMonth][0]
                .total
            : 0;

        let avgCount =
          categoryData.ticket_report?.category_monthwise["total_count"][0] &&
          categoryData.ticket_report?.category_monthwise["total_count"] &&
          categoryData.ticket_report.category_monthwise[currentMonth] &&
          categoryData.ticket_report.category_monthwise[currentMonth][0]
            ? (
                (categoryData.ticket_report.category_monthwise[currentMonth][0]
                  .total /
                  categoryData.ticket_report.category_monthwise[
                    "total_count"
                  ][0].total) *
                100
              ).toFixed(2) + "%"
            : 0;

        row[lastThirdKey] = prefixZero_for_singleDigit(lastThirdCount);
        row[lastsecondKey] = prefixZero_for_singleDigit(lastSecondCount);
        row[currentMonthKey] = prefixZero_for_singleDigit(currentMonthCount);
        row[avgKey] = prefixZero_for_singleDigit(avgCount);

        rows.push(row);
      }
    });
  }

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      rows?.map((item) => {
        exportData?.push({
          [" Main Category"]: item.category,
          [`${new Date(lastThird).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
          })}`]:
            item[
              `${new Date(lastThird).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
              })}`
            ],

          [`${new Date(lastSecond).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
          })}`]:
            item[
              `${new Date(lastSecond).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
              })}`
            ],
          [`${new Date(currentMonth).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
          })}`]:
            item[
              `${new Date(currentMonth).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
              })}`
            ],
          Total: Array.isArray(item.total) ? item.total[0] : item.total,
          [`${new Date(currentMonth).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
          })}Avg%`]:
            item[
              `${new Date(currentMonth).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
              })}avg`
            ],
        });
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, rows]);

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          {apiError ? (
            <Box
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flex: "12",
              }}
            >
              {apiError}
            </Box>
          ) : categoryData &&
            categoryData.ticket_report &&
            categoryData.ticket_report.category_monthwise ? (
            <Box
              sx={{
                width: "100%",
                padding: "12px",
              }}
            >
              <div
                className="scrollable"
                style={{
                  width: "100%",
                  overflowX: "auto",
                  overflowY: "auto",
                  maxHeight: "800px",
                  borderRadius: theme.table.borderRadius,
                }}
              >
                {Object.entries(categoryData.ticket_report.category_monthwise)
                  .length > 0 ? (
                  <>
                    {props.dataGridHeight.current && (
                      <DataGridFunction
                        rows={rows}
                        columns={columns}
                        rowBorder={false}
                        height={props.dataGridHeight.current}
                        rowHeight={50}
                        headerHeight={80}
                        onCellClick={onCellClick}
                        rowColor={true}
                        headerColor={true}
                      />
                    )}
                  </>
                ) : (
                  <NoDataFound />
                )}
              </div>
            </Box>
          ) : (
            <NoDataFound />
          )}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage width={"50%"} />
        </Box>
      )}
    </LoadingIndicator>
  );
}

export default TicketCategoryReport;
