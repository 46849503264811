import React, { useEffect, useState, useCallback } from "react";
import Grid from "@mui/material/Grid";
import {
  Button,
  createFilterOptions,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { connect } from "react-redux";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import GoogleAlertConfigAction from "../../stores/googleAlertConfig/GoogleAlertConfigAction";
import { styled } from "@mui/material/styles";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { theme } from "../../../views/App";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    GoogleAlertConfigAction.REQUEST_GOOGLE_ALERTS,
  ]),
  google_alerts: state.google_alerts.google_alerts.hits || [],
  google_alerts_config_filter:
    state.google_alerts_config_filter.google_alerts_config_filter || {},
  pagination: state.google_alerts.google_alerts || {},
});

function GoogleAlerts(props) {
  const { isRequesting } = props;
  const [config, setConfig] = useState(null);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [page_limit] = useState(15);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  function fetchData() {
    const param = {
      order_by: '[["created_on", "desc"]]',
      page_limit: `${page_limit}`,
    };
    props.dispatch(GoogleAlertConfigAction.requestGoogleAlerts(param));
  }

  function fetchDataFilter() {
    const param = {
      order_by: `[["alert_name.keyword", "asc"]]`,
      page_limit: "none",
    };
    props.dispatch(
      GoogleAlertConfigAction.requestGoogleAlertConfigFilter(param)
    );
  }

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  useEffect(() => {
    fetchData();
    // fetchDataFilter();
  }, []);

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let filter_params = {};
    const filter = [];

    if (config !== null) {
      filter.push(`["alert_name.keyword","must","","match","${config}"]`);
    }

    if (config !== null && FilterClicked) {
      filter_params = {
        order_by: '[["created_on", "desc"]]',
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    } else {
      filter_params = {
        order_by: '[["created_on", "desc"]]',
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      props.dispatch(
        GoogleAlertConfigAction.requestGoogleAlerts(filter_params)
      );
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "SL NO",
      width: 150,
      flex: 1,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "config_unique_id",
      headerName: "Project ID",
      width: 200,
      hide: true,
    },

    {
      field: "title",
      headerName: "Title",
      width: 250,
      flex: 5,
    },

    {
      field: "summary",
      headerName: "Summary",
      width: 250,
      flex: 3,
      resizable: false,
      renderCell: (params) => {
        return (
          <CustomToolTip
            title={params.row.summary}
            arrow
            placement="right"
            minHeight="100px"
          >
            {params.row.summary}
          </CustomToolTip>
        );
      },
    },

    {
      field: "published_date",
      headerName: "Published Date",
      width: 250,
      flex: 2,
      renderCell: (params) =>
        new Date(params.row.published_date).toLocaleString(),
    },

    {
      field: "link",
      headerName: "Link",
      width: 250,
      flex: 1,
      resizable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <OpenInNewIcon
            onClick={(event) => {
              event.stopPropagation();
              window.open(params?.row.link);
            }}
            style={{
              width: "16px",
              marginRight: 2,
              color: `${theme.palette.ticketListView.iconColor}`,
              cursor: "pointer",
            }}
          />
        );
      },
    },
  ];

  const rows = [];
  if (props.google_alerts.hits) {
    props.google_alerts.hits.map((item, index) => {
      return rows.push({
        id: item._id
          ? props.pagination.current_page_no * page_limit + ++index
          : "",
        config_unique_id: item._id,
        title: item._source.entries?.title,
        published_date: item._source.entries?.published_date,
        summary: item._source.entries?.summary,
        link: item._source.entries?.link,
        feed: item._source?.feed,
      });
    });
  }

  const alertName_handlechange = (event, value) => {
    if (value != null) {
      setConfig(value);
    } else {
      setConfig(null);
    }
  };

  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  // Filter function for Client
  const applyFilter = () => {
    const filter = [];
    if (config !== null) {
      filter.push(`["alert_name.keyword","must","","match","${config}"]`);
    }

    if (config !== null) {
      const params = {
        order_by: '[["created_on", "desc"]]',
        filters: `[${filter}]`,
      };
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);

      props.dispatch(GoogleAlertConfigAction.requestGoogleAlerts(params));
    } else {
      alert("Please add filters");
      setClearLoader(false);
    }
  };

  const ClearState = () => {
    if (config !== null) {
      setClearLoader(true);
      setConfig(null);
      !FilterClicked && setClearLoader(false);
    }

    setFilterClicked(false);

    const params = {
      order_by: '[["created_on", "desc"]]',
      page_limit: 15,
    };
    FilterClicked &&
      props.dispatch(GoogleAlertConfigAction.requestGoogleAlerts(params));
  };

  let alertNameList = [];
  if (props.google_alerts_config_filter.hits) {
    props.google_alerts_config_filter.hits.hits.map((item) => {
      return alertNameList.push(item._source.alert_name);
    });
  }

  alertNameList.sort((a, b) => a.localeCompare(b));
  alertNameList = [...new Set(alertNameList)];

  //  datagrid rows

  const getDetailPanelHeight = React.useCallback(() => "auto", []);

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    []
  );

  function DetailPanelContent({ row: rowProp }) {
    const rows = [];

    rows.push({
      sl_no: 1,
      id: 1, // The platform name (e.g., 'deezer', 'spotify')
      title: rowProp.feed.title,
      updated_date: rowProp.feed.updated_date,
      link: rowProp.feed.link,
    });

    const columns = [
      {
        field: "sl_no",
        headerName: "SL NO",
        resizable: false,
        align: "center",
        headerAlign: "center",
        flex: 2,
      },
      {
        field: "title",
        headerName: "Title",
        resizable: false,
        align: "center",
        headerAlign: "center",
        flex: 5,
      },

      {
        field: "updated_date",
        headerName: "Updated Date",
        resizable: false,
        align: "center",
        headerAlign: "center",
        flex: 2,
        renderCell: (params) =>
          new Date(params.row.updated_date).toLocaleString(),
      },

      {
        field: "link",
        headerName: "Link",
        resizable: false,
        align: "center",
        headerAlign: "center",
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          return (
            <OpenInNewIcon
              onClick={(event) => {
                event.stopPropagation();
                window.open(params?.row.link);
              }}
              style={{
                width: "16px",
                marginRight: 2,
                color: `${theme.palette.ticketListView.iconColor}`,
                cursor: "pointer",
              }}
            />
          );
        },
      },
    ];

    return (
      <Stack
        sx={{ py: 2, height: "100%", boxSizing: "border-box" }}
        direction="column"
      >
        <Paper
          sx={{
            flex: 1,
            mx: "auto",
            width: "95%",
            p: 2,
            boxShadow: theme.shadows,
          }}
        >
          <Grid
            item
            container
            alignItems={"center"}
            justifyContent="space-between"
          >
            <DataGridFunction
              rows={rows}
              columns={columns}
              rowHeight={60}
              height={rows.length * 60 + 30}
              getRowId={(row) => row.id}
            />
          </Grid>
        </Paper>
      </Stack>
    );
  }

  return (
    <React.Fragment>
      {/* Filter */}

      {/* <Grid
        container
        lg={12}
        md={12}
        sm={12}
        justifyContent="space-between"
        pr={2}
      >
        <Grid item lg={2} md={5} sm={5} mt={1}>
          <AutoCompleteComponent
            value={config}
            textFieldProps={{
              placeholder: "Alert Name",
            }}
            filterOptions={createFilterOptions({
              matchFrom: "start",
              stringify: (option) => option,
            })}
            list={alertNameList}
            handleChange={alertName_handlechange}
            size="small"
            width="100%"
          />
        </Grid>
        <Grid item lg={6} md={7} sm={7} container>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            container
            spacing={1}
            justifyContent="flex-end"
            mt={0}
          >
            <Grid
              lg={8}
              sm={8}
              md={8}
              display={"flex"}
              justifyContent={"end"}
              mt={1}
              mr={2}
              sx={{ gap: 2 }}
            >
              <Grid lg={3} md={3} sm={3}>
                <MuiButton
                  name={"Filter"}
                  width="100%"
                  onClick={applyFilter}
                  loading={filterLoader && isRequesting}
                />
              </Grid>
              <Grid lg={3} md={3} sm={3}>
                <MuiButton
                  name={"Clear"}
                  width="100%"
                  onClick={ClearState}
                  loading={clearLoader && isRequesting}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}

      {/* ***************************************************************  user filter */}

      {/* data grid */}

      <Grid item lg={12} md={12} sm={12} xs={12}>
        {/* loading icon */}
        <LoadingIndicator isActive={isRequesting}>
          {!isRequesting && (
            <DataGridFunction
              columns={columns}
              rows={rows}
              rowHeight={60}
              pagelength={props.pagination.total_page_no}
              page={props.pagination.current_page_no}
              handleChange={handleChange}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    config_unique_id: false,
                    platform: false,
                  },
                },
              }}
              getDetailPanelContent={getDetailPanelContent}
              getDetailPanelHeight={getDetailPanelHeight}
              getRowId={(row) => row.id}
            />
          )}
        </LoadingIndicator>
      </Grid>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(GoogleAlerts);

const CustomToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, minHeight }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "5px",
    minWidth: "350px",
    minHeight: minHeight,
    boxShadow: "8px 10px 10px rgba(0, 0, 0, 0.4)",
    padding: "15px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.main,
  },
}));
