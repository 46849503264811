import { Box, Grid, useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import StarIcon from "@mui/icons-material/Star";
import TicketPlatformMessagesContainer from "./TicketPlatformMessagesContainer";
import copy from "copy-to-clipboard";
import { selectRequesting } from "../../../../../selectors/requesting/RequestingSelector";
import TwitterAction from "../../../../stores/twitterFeeds/TwitterAction";
import RetweetAction from "../../../../stores/retweet/RetweetAction";
import UnRetweetAction from "../../../../stores/unRetweet/UnRetweetAction";
import InstagramFeedsAction from "../../../../stores/instagramFeeds/InstagramFeedsAction";
import GmbQuestionFeedsAction from "../../../../stores/gmbQuestions/GmbQuestionFeedsAction";
import LinkedInFeedAction from "../../../../stores/linkedInFeed/LinkedInFeedAction";
import YoutubeAction from "../../../../stores/youtube/YoutubeAction";
import GmbReviewAction from "../../../../stores/gmbReviews/GmbReviewAction";
import PlayStoreAction from "../../../../stores/playstore/PlayStoreAction";
import TelegramAction from "../../../../stores/telegram/TelegramAction";
import GlassdoorFeedsAction from "../../../../stores/glassdoorFeeds/GlassdoorFeedsAction";
import EmailFeedsAction from "../../../../stores/emailFeeds/EmailFeedsAction";
import AmbitionBoxFeedsAction from "../../../../../stores/ambitionBoxFeeds/AmbitionBoxFeedsAction";
import FacebookDmAction from "../../../../stores/facebook_dm/FacebookDmAction";
import InstagramDirectMessageAction from "../../../../stores/instagramDirectMessage/InstagramDirectMessageAction";
import QuoraFeedsAction from "../../../../stores/quoraFeeds/QuoraFeedsAction";
import TwitterDirectMessageAction from "../../../../stores/linkedIn_account/twitterDirectMessage/TwitterDirectMessageAction";
import TicketsAction from "../../../../stores/tickets/TicketsAction";
import FacebookAction from "../../../../stores/facebook/FacebookAction";
import WhatsAppFeedAction from "../../../../stores/whatsappFeed/WhatsAppFeedAction";
import AppStoreAction from "../../../../stores/linkedInFeed/appstore/AppStoreAction";
import IndeedFeedAction from "../../../../stores/indeed/IndeedFeedAction";
import RedditFeedAction from "../../../../stores/reddit/RedditFeedAction";
import ConsumerAction from "../../../../stores/consumerFeeds/ConsumerAction";
import DiscordFeedsAction from "../../../../stores/discord/DiscordFeedsAction";
import TrustpilotFeedsAction from "../../../../stores/trustpilot/TrustpilotFeedsAction";
import PinterestFeedsAction from "../../../../stores/pinterest/PinterestFeedsAction";
import ReturnFeeds from "./ReturnFeeds";
import { theme } from "../../../../../views/App";
import LoadingIndicator from "../../../../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../../../../components/loadingBgImage/LoadingBgImage";
import { Form, Formik } from "formik";
import NotesUI from "./NotesUI";
import Details from "../../../../../views/manualTicketDetails/ticketDetailTabs/Details";

TimeAgo.addDefaultLocale(en);
// mapstateto props function fetching
let mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TwitterAction.REQUEST_TWITTER,
    RetweetAction.REQUEST_RETWEET,
    UnRetweetAction.REQUEST_UNRETWEET,
    InstagramFeedsAction.REQUEST_INSTAGRAM_FEEDS,
    GmbQuestionFeedsAction.REQUEST_GMB_QUESTION_FEED,
    LinkedInFeedAction.REQUEST_LINKEDIN_FEED,
    YoutubeAction.REQUEST_YOUTUBE,
    GmbReviewAction.REQUEST_GMB_REVIEW_FEED,
    PlayStoreAction.REQUEST_PLAYSTORE,
    TelegramAction.REQUEST_TELEGRAM_FEEDS,
    GlassdoorFeedsAction.REQUEST_GLASSDOOR_FEED,
    EmailFeedsAction.REQUEST_EMAIL_FEEDS,
    AmbitionBoxFeedsAction.REQUEST_AMBITIONBOX_FEED,
    FacebookDmAction.REQUEST_FACEBOOK_DM,
    InstagramDirectMessageAction.REQUEST_INSTAGRAM_DIRECT_MESSAGE,
    QuoraFeedsAction.REQUEST_QUORA_FEED,
    TwitterDirectMessageAction.REQUEST_TWITTERDIRECTMESSAGE,
    TicketsAction.REQUEST_TICKETS_BY_ID,
    FacebookAction.REQUEST_FACEBOOK,
    FacebookAction.REQUEST_FACEBOOK_POST_MENTIONS,
    WhatsAppFeedAction.REQUEST_WHATSAPP_FEED,
    AppStoreAction.REQUEST_APPSTORE,
    IndeedFeedAction.REQUEST_INDEED_FEEDS,
    RedditFeedAction.REQUEST_REDDIT_COMMENTS,
    RedditFeedAction.REQUEST_REDDIT_POSTS,
    ConsumerAction.REQUEST_CONSUMER,
    DiscordFeedsAction.REQUEST_DISCORD_FEEDS,
    TrustpilotFeedsAction.REQUEST_TRUSTPILOT_FEEDS,
    PinterestFeedsAction.REQUEST_PINTEREST_FEEDS,
    // TicketsAction.REQUEST_PUT_TICKETS,
  ]),
  twitter: state.twitter.twitter.hits || {},
  threadFeeds: state.threadFeeds.threadFeeds.hits || {},
  retweet: state.retweet.retweet || [],
  unretweet: state.unretweet.unretweet || [],
  instagramFeeds: state.instagramFeeds.instagramFeeds.hits || {},
  facebook: state.facebook.facebook.hits || {},
  facebook_postMentions:
    state.facebook_postMentions.facebook_postMentions.hits || {},
  GMBQuestionFeeds: state.GMBQuestionFeeds.GMBQuestionFeeds.hits || [],
  linkedInFeeds: state.linkedInFeeds.linkedInFeeds.hits || [],
  youtubeFeed: state.youtubeFeed.youtubeFeed.hits || [],
  GMBReviewFeeds: state.GMBReviewFeeds.GMBReviewFeeds.hits || [],
  playstore: state.playstore.playstore.hits || [],
  telegramFeeds: state.telegramFeeds.telegramFeeds.hits || [],
  glassdoorFeeds: state.glassdoorFeeds.glassdoorFeeds.hits || {},
  emailFeeds: state.emailFeeds.emailFeeds.hits || {},
  ambitionBoxFeeds: state.ambitionBoxFeeds.ambitionBoxFeeds.hits || {},
  facebook_dm: state.facebook_dm.facebook_dm.hits || [],
  instagramDirectMessage:
    state.instagramDirectMessage.instagramDirectMessage.hits || [],
  twitterDirectMeassage:
    state.twitterDirectMeassage.twitterDirectMeassage.hits || [],
  quoraFeed: state.quoraFeed.quoraFeed.hits || {},
  whatsAppFeed: state.whatsAppFeed.whatsAppFeed.hits || [],
  appStore: state.appstore.appstore.hits || {},
  indeedFeeds: state.indeedFeeds.indeedFeeds.hits || {},
  trustPilotFeeds: state.trustPilotFeeds.trustPilotFeeds.hits || {},
  pinterestFeeds: state.pinterestFeeds.pinterestFeeds.hits || {},
  redditFeeds: state.redditFeeds.redditFeeds.hits || {},
  redditPosts: state.redditPosts.redditPosts.hits || {},
  redditComments: state.redditComments.redditComments.hits || {},
  consumer_feed: state.consumer_feed.consumer_feed.hits || {},
  discordFeeds: state.discordFeeds.discordFeeds.hits || {},
});
const TicketFeedComponent = (props) => {
  let initial_val_for_TicketPlatformMessagesContainer = {};

  const {
    openAIsuggestionTab,
    setOpenAIsuggestionTab,
    aiSuggestionTab,
    setAiSuggestionTab,
  } = props;

  let ActivityName = "";
  ActivityName =
    props &&
    props.ticketData._source &&
    props.ticketData._source.activities &&
    props.ticketData._source.activities.activities_name;

  !props.isRequesting &&
    Object.assign(
      initial_val_for_TicketPlatformMessagesContainer,

      props &&
        props.ticketData._source &&
        props.ticketData._source.platform &&
        props.ticketData._source.platform.platform_name === "Instagram" &&
        props.instagramDirectMessage.hits &&
        props.instagramDirectMessage.hits.length && {
          feed: props.instagramDirectMessage.hits.map((item, index, array) => {
            let lastReceivedIndex = -1;

            array.forEach((item, i) => {
              if (item._source.flag === "received") {
                lastReceivedIndex = i;
              }
            });

            return {
              replyOpen:
                props.replyClicked &&
                index === lastReceivedIndex &&
                ActivityName === "Direct Message",
              emailOpen:
                props.emailClicked &&
                index === lastReceivedIndex &&
                ActivityName === "Direct Message",
              translateClicked: false,
            };
          }),
        },

      props &&
        props.ticketData._source &&
        props.ticketData._source.platform &&
        props.ticketData._source.platform.platform_name === "Twitter" &&
        props.twitterDirectMeassage.hits &&
        props.twitterDirectMeassage.hits.length && {
          feed: props.twitterDirectMeassage.hits.map((item, index, array) => {
            let lastReceivedIndex = -1;

            array.forEach((item, i) => {
              if (item._source.flag === "received") {
                lastReceivedIndex = i;
              }
            });

            return {
              replyOpen:
                props.replyClicked &&
                index === lastReceivedIndex &&
                ActivityName === "Direct Message",
              emailOpen:
                props.emailClicked &&
                index === lastReceivedIndex &&
                ActivityName === "Direct Message",
              translateClicked: false,
            };
          }),
        },

      props &&
        props.ticketData._source &&
        props.ticketData._source.platform &&
        props.ticketData._source.platform.platform_name === "Facebook" &&
        props.facebook_dm.hits &&
        props.facebook_dm.hits.length && {
          feed: props.facebook_dm.hits.map((item, index, array) => {
            let lastReceivedIndex = -1;

            array.forEach((item, i) => {
              if (item._source.flag === "received") {
                lastReceivedIndex = i;
              }
            });

            return {
              replyOpen:
                props.replyClicked &&
                index === lastReceivedIndex &&
                ActivityName === "Direct Message",
              emailOpen:
                props.emailClicked &&
                index === lastReceivedIndex &&
                ActivityName === "Direct Message",
              translateClicked: false,
            };
          }),
        },

      props &&
        props.ticketData._source &&
        props.ticketData._source.platform &&
        props.ticketData._source.platform.platform_name === "Telegram" &&
        props.telegramFeeds.hits &&
        props.telegramFeeds.hits.length && {
          feed: props.telegramFeeds.hits.map((item, index, array) => {
            let lastReceivedIndex = -1;

            array.forEach((item, i) => {
              if (item._source.flag === "received") {
                lastReceivedIndex = i;
              }
            });

            return {
              replyOpen: props.replyClicked && index === lastReceivedIndex,
              emailOpen: props.emailClicked && index === lastReceivedIndex,
              translateClicked: false,
            };
          }),
        },

      props &&
        props.ticketData._source &&
        props.ticketData._source.platform &&
        props.ticketData._source.platform.platform_name === "Whatsapp" &&
        props.whatsAppFeed.hits &&
        props.whatsAppFeed.hits.length && {
          feed: props.whatsAppFeed.hits.map((item, index, array) => {
            let lastReceivedIndex = -1;

            array.forEach((item, i) => {
              if (item._source.flag === "received") {
                lastReceivedIndex = i;
              }
            });

            return {
              replyOpen: props.replyClicked && index === lastReceivedIndex,
              emailOpen: props.emailClicked && index === lastReceivedIndex,
              translateClicked: false,
            };
          }),
        },

      props &&
        props.ticketData._source &&
        props.ticketData._source.platform &&
        props.ticketData._source.platform.platform_name === "Discord" &&
        props.discordFeeds.hits &&
        props.discordFeeds.hits.length && {
          feed: props.discordFeeds.hits.map((item, index, array) => {
            let lastReceivedIndex = -1;

            array.forEach((item, i) => {
              if (item._source.flag === "recieved") {
                lastReceivedIndex = i;
              }
            });

            return {
              replyOpen: props.replyClicked && index === lastReceivedIndex,
              emailOpen: props.emailClicked && index === lastReceivedIndex,
              translateClicked: false,
            };
          }),
        }
    );

  const [showBoxReplyBox, setShowReplyBox] = useState(
    props.replyClicked ? true : false
  );

  const [showEmailBox, setShowEmailBox] = useState(
    props.emailClicked ? true : false
  );

  const timeAgo = new TimeAgo("en-US");

  const handleReplyButtonClick = (index, setFieldValue, values) => {
    // setShowReplyBox((prev) => !prev);
    // props.setReplyClicked((prev) => !prev);

    // showEmailBox === true && setShowEmailBox(false);
    values.feed[index].emailOpen === true &&
      setFieldValue(`feed[${index}].emailOpen`, !values.feed[index].emailOpen);
  };

  const handleEmailButtonClick = (index, setFieldValue, values) => {
    // setShowEmailBox((prev) => !prev);
    // props.setEmailClicked((prev) => !prev);
    // showBoxReplyBox === true && setShowReplyBox(false);

    values.feed[index].replyOpen === true &&
      setFieldValue(`feed[${index}].replyOpen`, !values.feed[index].replyOpen);
  };

  const [copied, setCopied] = useState(false);

  const copyToClipboard = (copyText) => {
    copy(copyText);
    setCopied(true);
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChangeExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const icon = (
    <CheckBoxOutlineBlankIcon
      className="p-0 m-0"
      fontSize="small"
      style={{
        color: `${theme.palette.ticketListView.iconColor}`,
        width: "13px",
      }}
    />
  );
  const checkedIcon = (
    <CheckBoxIcon
      fontSize="small"
      className="p-0 m-0"
      style={{
        // color: `${theme.palette.ticketListView.iconColor}`,
        width: "13px",
      }}
    />
  );

  const { isRequesting } = props;

  // fetch function for Instagram Platform
  function fetchInstaFeeds() {
    let filter = [];

    filter.push(
      `[["feed_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let params = {
      filters: `${filter}`,
      order_by: '[["created_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(InstagramFeedsAction.requestInstagramFeeds(params));
  }

  // fetch fn for instagram dm
  function fetchClosedTicketInstaDm() {
    let filter = [];

    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}],["is_deleted","must_not","","match","true"]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
      order_by: '[["created_on", "asc"]]',
    };
    props.dispatch(
      InstagramDirectMessageAction.requestInstagramDirectMessage(params)
    );
  }

  function fetchOpenTicketInstaDm() {
    let filter = [];

    filter.push(
      `["customer_username.keyword","must","","match","${
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id.platform &&
        props.ticketData._source.customer_id.platform[0] &&
        props.ticketData._source.customer_id.platform[0].account_id
      }"],["account_id.keyword","must","","match","${
        props.ticketData._source &&
        props.ticketData._source.account_name &&
        props.ticketData._source.account_name.account_id
      }"],["project.project_id.keyword","must","","match","${
        props.ticketData._source.project.project_id
      }"],["is_deleted","must_not","","match","true"]`
    );

    const params = {
      filters: `[${filter}]`,
      page_limit: "none",
      order_by: `[["created_on","asc"]]`,
    };

    props.dispatch(
      InstagramDirectMessageAction.requestInstagramDirectMessage(params)
    );
  }

  // gmd feeds fetch function
  function fetchQuestionsFeed() {
    let filter = [];
    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let params = {
      filters: `${filter}`,
      order_by: '[["created_on", "asc"]]',
      page_limit: "none",
    };
    props.dispatch(GmbQuestionFeedsAction.requestGmbQuestionFeed(params));
  }

  // fetch function for playstore platform
  function fetchAppStore() {
    let filter = [];

    filter.push(
      `[["review_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      order_by: '[["created_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(AppStoreAction.requestAppStore(param));
  }

  // fetch function for linkedIn Platform

  function fetchOpenLinkedInFeed() {
    let filter = [];

    filter.push(
      `[["customer_id.keyword","must","","term",${JSON.stringify(
        props.ticketData._source.customer_id
          ? props.ticketData._source.customer_id?.platform[0]?.account_id
          : []
      )}]]`
    );
    let params = {
      filters: `${filter}`,
      order_by: '[["created_at", "asc"]]',
      page_limit: "none",
    };
    props.dispatch(LinkedInFeedAction.requestLinkedInFeed(params));
  }

  function fetchClosedLinkedInFeed() {
    let filter = [];

    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let params = {
      filters: `${filter}`,
      order_by: '[["created_at", "asc"]]',
      page_limit: "none",
    };
    props.dispatch(LinkedInFeedAction.requestLinkedInFeed(params));
  }

  //Youtube feed fetch Function
  function fetchYoutubeFeeds() {
    let filter = [];

    filter.push(
      `[["feed_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let params = {
      filters: `${filter}`,
      order_by: '[["created_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(YoutubeAction.requestYoutube(params));
  }

  // fetch fn for gmb reviews
  function fetchReviewsFeed() {
    let filter = [];
    filter.push(
      `[["review_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let params = {
      filters: `${filter}`,
      order_by: '[["created_on", "asc"]]',
      page_limit: "none",
    };
    props.dispatch(GmbReviewAction.requestGmbReviewFeed(params));
  }
  // fetch function for playstore platform
  function fetchPlayStore() {
    let filter = [];
    filter.push(
      `[["reviewId.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      order_by: '[["review_created_at", "asc"]]',
      page_limit: "none",
    };
    props.dispatch(PlayStoreAction.requestPlayStore(param));
  }

  // telegram feed fetch function
  function fetchTelegramMessage() {
    let filter = [];

    filter.push(
      `[["feed_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      order_by: '[["created_on", "asc"]]',
      page_limit: "none",
    };

    props.dispatch(TelegramAction.requestTelegramFeeds(params));
  }
  //fetch function for Glassdoor platform
  function fetchGlassDoorReview() {
    let filter = [];

    filter.push(
      `[["feed_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      order_by: '[["created_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(GlassdoorFeedsAction.requestGlassdoorFeed(param));
  }

  // fetch fn of ambition  box
  function fetchAmbitionBoxReview() {
    let filter = [];

    filter.push(
      `[["feed_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      page_limit: "none",
    };
    props.dispatch(AmbitionBoxFeedsAction.requestAmbitionBoxFeed(param));
  }

  function fetchClosedEmailFeeds() {
    let filter = [];

    filter.push(
      `[["feed_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      page_limit: "none",
      order_by: `[["created_on","asc"]]`,
    };
    props.dispatch(EmailFeedsAction.requestEmailFeeds(param));
  }

  function fetchOpenEmailFeeds() {
    let filter = [];

    filter.push(
      `[["customer_name.keyword","must","","match",${JSON.stringify(
        props.ticketData._source.customer_id &&
          props.ticketData._source.customer_id.customer_name
          ? props.ticketData._source.customer_id.customer_name
          : null
      )}]]`
    );

    let param = {
      filters: `${filter}`,
      page_limit: "none",
      order_by: `[["created_on","asc"]]`,
    };
    props.dispatch(EmailFeedsAction.requestEmailFeeds(param));
  }

  //fetch function for Email platform
  function fetchEmailFeeds() {
    props.finalStatuses &&
    props.finalStatuses.length > 0 &&
    props.finalStatuses.includes(
      props.ticketData &&
        props.ticketData._source &&
        props.ticketData._source.status &&
        props.ticketData._source.status.status_id
    )
      ? fetchClosedEmailFeeds()
      : fetchOpenEmailFeeds();
  }

  function returnStars(rating) {
    let i;
    switch (rating) {
      case "ONE":
        i = 1;
        break;
      case "TWO":
        i = 2;
        break;
      case "THREE":
        i = 3;
        break;
      case "FOUR":
        i = 4;
        break;
      case "FIVE":
        i = 5;
        break;
      case "1.0":
        i = 1;
        break;
      case "2.0":
        i = 2;
        break;
      case "3.0":
        i = 3;
        break;
      case "4.0":
        i = 4;
        break;
      case "5.0":
        i = 5;
        break;
    }

    let displayStars = [];

    for (let k = 1; k <= i; k++) {
      displayStars.push(<StarIcon sx={{ color: "gold" }} />);
    }

    return displayStars;
  }

  const returnStarsIcon = (stars) => {
    let i = stars;
    let displayStars = [];
    if (stars === i) {
      for (let k = 1; k <= i; k++) {
        displayStars.push(<StarIcon sx={{ color: "gold" }} />);
      }
    }
    return displayStars;
  };

  //fetch fn for Quora Platform
  function fetchQuorafeed() {
    let filter = [];
    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      page_limit: "none",
    };
    props.dispatch(QuoraFeedsAction.requestQuoraFeed(param));
  }

  // fetch function for Twitter Platform
  function fetchTwitter() {
    let filter = [];

    filter.push(
      `[["tweet_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      order_by: '[["created_on", "asc"]]',
      page_limit: "none",
    };
    props.dispatch(TwitterAction.requestTwitter(param));
  }

  // fetch fn for twitter direct mesage
  function fetchClosedTicketTwitterDm() {
    let filter = [];
    filter.push(
      `[["message_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      order_by: `[["created_on","asc"]]`,
      page_limit: "none",
    };
    props.dispatch(
      TwitterDirectMessageAction.requestTwitterDirectMessage(params)
    );
  }

  const fetchTwitterPlatform = () => {
    props.finalStatuses &&
    props.finalStatuses.length > 0 &&
    props.finalStatuses.includes(
      props.ticketData &&
        props.ticketData._source &&
        props.ticketData._source.status &&
        props.ticketData._source.status.status_id
    )
      ? fetchClosedTicketTwitterDm()
      : fetchOpenTicketTwitterDm();
    fetchTwitter();
  };

  const fetchThreads = () => {
    let filter = [];

    filter.push(
      `[["thread_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      order_by: '[["created_on", "asc"]]',
      page_limit: "none",
    };
    props.dispatch(InstagramFeedsAction.requestThreads(param));
  };

  function fetchOpenTicketTwitterDm() {
    let filter = [];

    filter.push(
      `["feed_id.keyword","must","","match","${
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id.platform &&
        props.ticketData._source.customer_id.platform[0] &&
        props.ticketData._source.customer_id.platform[0].account_id
      }"],["account_name.keyword","must","","match","${
        props.ticketData._source.account_name &&
        props.ticketData._source.account_name.account_name
      }"],["project.project_id.keyword","must","","match","${
        props.ticketData._source.project.project_id
      }"]`
    );

    const params = {
      filters: `[${filter}]`,
      page_limit: "none",
      order_by: `[["created_on","asc"]]`,
    };

    props.dispatch(
      TwitterDirectMessageAction.requestTwitterDirectMessage(params)
    );
  }

  function Callbackfunc(resp) {
    let filter = [];

    filter.push(
      `[["conversation_id.keyword","must","","match",${JSON.stringify(
        resp &&
          resp.hits &&
          resp.hits.hits &&
          resp.hits.hits[0] &&
          resp.hits.hits[0]._source.conversation_id
          ? resp.hits.hits[0]._source.conversation_id
          : ""
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
      order_by: '[["created_on", "asc"]]',
    };
    props.dispatch(FacebookDmAction.requestFacebookMessage(params));
  }

  function fetchFbDm_message() {
    // let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

    // fetch(
    //   `${
    //     environment.api.facebook_dm
    //   }?filters=[["message_id.keyword","must","","terms",${JSON.stringify(
    //     props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
    //   )}]]&page_limit=none`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((response) => {
    //     Callbackfunc(response);
    //   });

    let filter = [];

    filter.push(
      `[["message_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
      order_by: '[["created_on", "asc"]]',
    };
    props.dispatch(
      FacebookDmAction.requestFacebookMessage(params, Callbackfunc)
    );
  }

  // fetch function for Facebook Platform
  function fetchFacebook() {
    let filter = [];
    filter.push(
      `["comment_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]`
    );

    filter.push(`["activities.keyword","must_not","","match","Post Mentions"]`);

    let param = {
      // comments: props.ticketData._source && props.ticketData._source.feed_id,
      filters: `[${filter}]`,
      page_limit: "none",
    };
    props.dispatch(FacebookAction.requestFacebook(param));
  }

  // fetch function for Instagram Platform
  function fetchFbPostMentions() {
    let filter = [];

    filter.push(
      `["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]`
    );

    filter.push(`["activities.keyword","must","","match","Post Mentions"]`);

    let params = {
      filters: `[${filter}]`,
      order_by: '[["created_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(FacebookAction.requestFacebookPostMentions(params));
  }

  const fetchClosedTicketDm = () => {
    let filter = [];

    filter.push(
      `[["message_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
    };

    props.dispatch(FacebookDmAction.requestFacebookMessage(params));
  };

  const fetchFacebookPlatform = () => {
    props.finalStatuses &&
    props.finalStatuses.length > 0 &&
    props.finalStatuses.includes(
      props.ticketData &&
        props.ticketData._source &&
        props.ticketData._source.status &&
        props.ticketData._source.status.status_id
    )
      ? fetchClosedTicketDm()
      : fetchFbDm_message();
    // fetchFbDm_message();
    fetchFacebook();
    fetchFbPostMentions();
  };

  const fetchInstagramPlatform = () => {
    props.finalStatuses &&
    props.finalStatuses.length > 0 &&
    props.finalStatuses.includes(
      props.ticketData &&
        props.ticketData._source &&
        props.ticketData._source.status &&
        props.ticketData._source.status.status_id
    )
      ? fetchClosedTicketInstaDm()
      : fetchOpenTicketInstaDm();

    fetchInstaFeeds();
  };

  const fetchLinkedINPlatform = () => {
    props.finalStatuses &&
    props.finalStatuses.length > 0 &&
    props.finalStatuses.includes(
      props.ticketData &&
        props.ticketData._source &&
        props.ticketData._source.status &&
        props.ticketData._source.status.status_id
    )
      ? fetchClosedLinkedInFeed()
      : fetchOpenLinkedInFeed();
  };

  // fetch fn for whatsapp
  function fetchOpenWhatsAppFeed() {
    let filter = [];

    filter.push(
      `[["customer_number.keyword","must","","match","${
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id.platform &&
        props.ticketData._source.customer_id.platform[0].account_id
      }"]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
      order_by: '[["created_on", "asc"]]',
    };
    props.dispatch(WhatsAppFeedAction.requestWhatsAppFeed(params));
  }

  function fetchClosedWhatsAppFeed() {
    let filter = [];

    filter.push(
      `[["feed_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
      order_by: '[["created_on", "asc"]]',
    };
    props.dispatch(WhatsAppFeedAction.requestWhatsAppFeed(params));
  }

  const fetchWhatsAppPlatform = () => {
    props.finalStatuses &&
    props.finalStatuses.length > 0 &&
    props.finalStatuses.includes(
      props.ticketData &&
        props.ticketData._source &&
        props.ticketData._source.status &&
        props.ticketData._source.status.status_id
    )
      ? fetchClosedWhatsAppFeed()
      : fetchOpenWhatsAppFeed();
  };

  function GMBplatformFeed() {
    fetchQuestionsFeed();
    fetchReviewsFeed();
  }

  function fetchIndeed() {
    let filter = [];

    filter.push(
      `[["review_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
    };

    props.dispatch(IndeedFeedAction.requestIndeedFeed(params));
  }

  function fetchTrustpilotPlatform() {
    let filter = [];

    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
    };

    props.dispatch(TrustpilotFeedsAction.requestTrustpilotFeeds(params));
  }

  function fetchPinterestPlatform() {
    let filter = [];

    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
    };

    props.dispatch(PinterestFeedsAction.requestPinterestFeeds(params));
  }

  function fetchRedditFeed() {
    let filter = [];

    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
    };
    if (props.ticketData._source.activities.activities_name === "Comments") {
      props.dispatch(RedditFeedAction.requestRedditComments(params));
    } else if (
      props.ticketData._source.activities.activities_name === "Posts"
    ) {
      props.dispatch(RedditFeedAction.requestRedditPosts(params));
    }
  }

  const isSmallScreen = useMediaQuery("(max-width:1200px)");

  function fetchConsumerFeeds() {
    let filter = [];

    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
    };

    props.dispatch(ConsumerAction.requestConsumer(params));
  }

  function fetchDiscordFeeds() {
    let filter = [];

    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
    };

    props.dispatch(DiscordFeedsAction.requestDiscordFeeds(params));
  }

  let allowed_platforms = ["LinkedIn", "Playstore", "GMB"];

  useEffect(() => {
    if (
      props.ticketData &&
      props.ticketData._source &&
      props.ticketData._source.platform
    ) {
      props.ticketData._source.platform.platform_name === "Twitter" &&
        fetchTwitterPlatform();

      props.ticketData._source.platform.platform_name === "Threads" &&
        fetchThreads();

      props.ticketData._source.platform.platform_name === "Facebook" &&
        fetchFacebookPlatform();

      props.ticketData._source.platform.platform_name === "Instagram" &&
        fetchInstagramPlatform();

      props.ticketData._source.platform.platform_name === "Whatsapp" &&
        fetchWhatsAppPlatform();

      props.ticketData._source.platform.platform_name === "GMB" &&
        GMBplatformFeed();

      props.ticketData._source.platform.platform_name === "LinkedIn" &&
        fetchLinkedINPlatform();
      props.ticketData._source.platform.platform_name === "Youtube" &&
        fetchYoutubeFeeds();
      props.ticketData._source.platform.platform_name === "Ambition Box" &&
        fetchAmbitionBoxReview();

      props.ticketData._source.platform.platform_name === "Glassdoor" &&
        fetchGlassDoorReview();

      props.ticketData._source.platform.platform_name === "Email" &&
        fetchEmailFeeds();

      props.ticketData._source.platform.platform_name === "Telegram" &&
        fetchTelegramMessage();

      props.ticketData._source.platform.platform_name === "Playstore" &&
        fetchPlayStore();

      props.ticketData._source.platform.platform_name === "Quora" &&
        fetchQuorafeed();

      props.ticketData._source.platform.platform_name === "Appstore" &&
        fetchAppStore();

      props.ticketData._source.platform.platform_name === "Indeed" &&
        fetchIndeed();

      props.ticketData._source.platform.platform_name === "Trust Pilot" &&
        fetchTrustpilotPlatform();

      props.ticketData._source.platform.platform_name === "Pinterest" &&
        fetchPinterestPlatform();

      props.ticketData._source.platform.platform_name === "Reddit" &&
        fetchRedditFeed();

      props.ticketData._source.platform.platform_name ===
        "Consumer Complaints" && fetchConsumerFeeds();

      props.ticketData._source.platform.platform_name === "Discord" &&
        fetchDiscordFeeds();
    }
  }, [props.ticketData]);

  const returntags = (tag_array) => {
    let tag_string = "";
    tag_array.length !== 0 &&
      tag_array.map((item) => {
        tag_string =
          tag_string !== ""
            ? tag_string + "/" + item.tag_name
            : tag_string + item.tag_name;
      });

    return tag_string.length > 10
      ? tag_string.substring(0, 10 - 2) + ".."
      : tag_string;
  };

  // TWITTER RELATED ACTIVITIES
  function fetchRetweet(id, params) {
    props.dispatch(RetweetAction.requestRetweet(id, params)).then(() => {
      fetchTwitter();
    });
  }

  function fetchUnRetweet(id, params) {
    props.dispatch(UnRetweetAction.requestUnRetweet(id, params)).then(() => {
      fetchTwitter();
    });
  }

  function fullName_function(id) {
    let user_name = props.userlist.filter((val) => {
      return val.user_id === id ? id : "";
    });
    return user_name[0] ? user_name[0].fullname : "";
  }

  let [DividerId, setDividerId] = useState(null);

  const boxRef = useRef(null);
  const [scrollToIndex, setScrollToIndex] = useState(null);

  useEffect(() => {
    if (props.replyClicked === true || props.emailClicked === true) {
      if (
        props &&
        props.ticketData._source &&
        props.ticketData._source.activities &&
        props.ticketData._source.activities.activities_name === "Direct Message"
      ) {
        boxRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
      }

      if (props?.ticketData?._source?.platform?.platform_name === "Whatsapp") {
        boxRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    } else {
      if (boxRef.current && scrollToIndex !== null) {
        const container = boxRef.current.querySelector(
          `[data-index="${scrollToIndex}"]`
        );
        if (container) {
          container.scrollIntoView({ behavior: "smooth" });
          setScrollToIndex(null); // Reset scrollToIndex after scrolling
        }
      }
    }
    if (props.replyClicked === false && props.emailClicked === false) {
      boxRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [isRequesting, scrollToIndex]);

  return (
    <Box
      width={"100%"}
      sx={{
        paddingY: 0,
        paddingLeft: 1,
        height: "100%",
        position: "relative",
      }}
    >
      <LoadingIndicator isActive={isRequesting}>
        {isRequesting && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              // display: "flex",
              justifyContent: "center",
              display: isRequesting ? "block" : "none",
              alignItems: "center",
              zIndex: 1,
            }}
          >
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
              }}
            >
              <LoadingBgImage />
            </Grid>
          </div>
        )}
        <Box
          sx={{ height: "100%", overflowY: "auto" }}
          ref={boxRef}
          className="scrollable"
        >
          {!isRequesting &&
            props.ticketData._source &&
            props.ticketData._source.platform && (
              <>
                {props.ticketData._source.platform.platform_name ===
                  "Facebook" &&
                  props.facebook.hits &&
                  props.facebook.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.facebook.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.comment_content ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.comment_content ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.facebook.hits.map((item, index) => {
                            return (
                              <ReturnFeeds
                                post_mentions={false}
                                mass_ticket_history={props?.mass_ticket_history}
                                index={index}
                                setFieldValue={setFieldValue}
                                values={values}
                                item={item}
                                setScrollToIndex={setScrollToIndex}
                                setDividerId={setDividerId}
                                isRequesting={isRequesting}
                                setCopied={setCopied}
                                copied={copied}
                                isSmallScreen={isSmallScreen}
                                openAIsuggestionTab={openAIsuggestionTab}
                                setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                                aiSuggestionTab={aiSuggestionTab}
                                setAiSuggestionTab={setAiSuggestionTab}
                                setShowReplyBox={setShowReplyBox}
                                DividerId={DividerId}
                                setShowEmailBox={setShowEmailBox}
                                ticketData={props?.ticketData}
                                finalStatuses={props?.finalStatuses}
                                statusList={props.statusList}
                                fetchFunction={() => {
                                  props.fetchFunction();
                                }}
                                showFeedDetails={props.showFeedDetails}
                                setReplyClicked={props.setReplyClicked}
                                pageNumber={props.pageNumber}
                                page_limit={props.page_limit}
                                page_length={props.page_length}
                                ticket_count_filters={
                                  props.ticket_count_filters
                                }
                                setEmailClicked={props.setEmailClicked}
                                openCloseModal={props.openCloseModal}
                                category_custom_value={
                                  props.category_custom_value
                                }
                              />
                            );
                          })}
                        </Form>
                      )}
                    </Formik>
                  )}

                {props.ticketData._source.platform.platform_name ===
                  "Facebook" &&
                  props.facebook_postMentions.hits &&
                  props.facebook_postMentions.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.facebook_postMentions.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.comment_content ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.comment_content ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.facebook_postMentions.hits.map(
                            (item, index) => {
                              return (
                                <ReturnFeeds
                                  post_mentions={true}
                                  mass_ticket_history={
                                    props?.mass_ticket_history
                                  }
                                  index={index}
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  item={item}
                                  setScrollToIndex={setScrollToIndex}
                                  setDividerId={setDividerId}
                                  isRequesting={isRequesting}
                                  setCopied={setCopied}
                                  copied={copied}
                                  isSmallScreen={isSmallScreen}
                                  openAIsuggestionTab={openAIsuggestionTab}
                                  setOpenAIsuggestionTab={
                                    setOpenAIsuggestionTab
                                  }
                                  aiSuggestionTab={aiSuggestionTab}
                                  setAiSuggestionTab={setAiSuggestionTab}
                                  setShowReplyBox={setShowReplyBox}
                                  DividerId={DividerId}
                                  setShowEmailBox={setShowEmailBox}
                                  ticketData={props?.ticketData}
                                  finalStatuses={props?.finalStatuses}
                                  statusList={props.statusList}
                                  fetchFunction={() => {
                                    props.fetchFunction();
                                  }}
                                  showFeedDetails={props.showFeedDetails}
                                  setReplyClicked={props.setReplyClicked}
                                  pageNumber={props.pageNumber}
                                  page_limit={props.page_limit}
                                  page_length={props.page_length}
                                  ticket_count_filters={
                                    props.ticket_count_filters
                                  }
                                  setEmailClicked={props.setEmailClicked}
                                  openCloseModal={props.openCloseModal}
                                  category_custom_value={
                                    props.category_custom_value
                                  }
                                />
                              );
                            }
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}

                {props.ticketData._source.platform.platform_name ===
                  "Twitter" &&
                  props.twitter &&
                  props.twitter.hits &&
                  props.twitter.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.twitter.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.content_of_the_tweet ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.content_of_the_tweet ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.twitter.hits.map((item, index) => (
                            <ReturnFeeds
                              mass_ticket_history={props?.mass_ticket_history}
                              index={index}
                              setFieldValue={setFieldValue}
                              values={values}
                              item={item}
                              setScrollToIndex={setScrollToIndex}
                              setDividerId={setDividerId}
                              isRequesting={isRequesting}
                              setCopied={setCopied}
                              copied={copied}
                              isSmallScreen={isSmallScreen}
                              openAIsuggestionTab={openAIsuggestionTab}
                              setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                              aiSuggestionTab={aiSuggestionTab}
                              setAiSuggestionTab={setAiSuggestionTab}
                              setShowReplyBox={setShowReplyBox}
                              DividerId={DividerId}
                              setShowEmailBox={setShowEmailBox}
                              ticketData={props?.ticketData}
                              finalStatuses={props?.finalStatuses}
                              statusList={props.statusList}
                              fetchFunction={() => {
                                props.fetchFunction();
                              }}
                              showFeedDetails={props.showFeedDetails}
                              setReplyClicked={props.setReplyClicked}
                              pageNumber={props.pageNumber}
                              page_limit={props.page_limit}
                              page_length={props.page_length}
                              ticket_count_filters={props.ticket_count_filters}
                              setEmailClicked={props.setEmailClicked}
                              openCloseModal={props.openCloseModal}
                              category_custom_value={
                                props.category_custom_value
                              }
                            />
                          ))}
                        </Form>
                      )}
                    </Formik>
                  )}

                {props.ticketData._source.platform.platform_name ===
                  "Threads" &&
                  props.threadFeeds &&
                  props.threadFeeds.hits &&
                  props.threadFeeds.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.threadFeeds.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.thread_content ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.thread_content ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.threadFeeds.hits.map((item, index) => (
                            <ReturnFeeds
                              mass_ticket_history={props?.mass_ticket_history}
                              index={index}
                              setFieldValue={setFieldValue}
                              values={values}
                              item={item}
                              setScrollToIndex={setScrollToIndex}
                              setDividerId={setDividerId}
                              isRequesting={isRequesting}
                              setCopied={setCopied}
                              copied={copied}
                              isSmallScreen={isSmallScreen}
                              openAIsuggestionTab={openAIsuggestionTab}
                              setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                              aiSuggestionTab={aiSuggestionTab}
                              setAiSuggestionTab={setAiSuggestionTab}
                              setShowReplyBox={setShowReplyBox}
                              DividerId={DividerId}
                              setShowEmailBox={setShowEmailBox}
                              ticketData={props?.ticketData}
                              finalStatuses={props?.finalStatuses}
                              statusList={props.statusList}
                              fetchFunction={() => {
                                props.fetchFunction();
                              }}
                              showFeedDetails={props.showFeedDetails}
                              setReplyClicked={props.setReplyClicked}
                              pageNumber={props.pageNumber}
                              page_limit={props.page_limit}
                              page_length={props.page_length}
                              ticket_count_filters={props.ticket_count_filters}
                              setEmailClicked={props.setEmailClicked}
                              openCloseModal={props.openCloseModal}
                              category_custom_value={
                                props.category_custom_value
                              }
                            />
                          ))}
                        </Form>
                      )}
                    </Formik>
                  )}

                {props.ticketData._source.platform.platform_name ===
                  "Instagram" &&
                  props.instagramFeeds.hits &&
                  props.instagramFeeds.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.instagramFeeds.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.feed_id ===
                                props.ticketData._source.ticket_name
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.feed_id ===
                                props.ticketData._source.ticket_name
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.instagramFeeds.hits.map((item, index) => (
                            <ReturnFeeds
                              mass_ticket_history={props?.mass_ticket_history}
                              index={index}
                              setFieldValue={setFieldValue}
                              values={values}
                              item={item}
                              setScrollToIndex={setScrollToIndex}
                              setDividerId={setDividerId}
                              isRequesting={isRequesting}
                              setCopied={setCopied}
                              copied={copied}
                              isSmallScreen={isSmallScreen}
                              openAIsuggestionTab={openAIsuggestionTab}
                              setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                              aiSuggestionTab={aiSuggestionTab}
                              setAiSuggestionTab={setAiSuggestionTab}
                              setShowReplyBox={setShowReplyBox}
                              DividerId={DividerId}
                              setShowEmailBox={setShowEmailBox}
                              ticketData={props?.ticketData}
                              finalStatuses={props?.finalStatuses}
                              statusList={props.statusList}
                              fetchFunction={() => {
                                props.fetchFunction();
                              }}
                              showFeedDetails={props.showFeedDetails}
                              setReplyClicked={props.setReplyClicked}
                              pageNumber={props.pageNumber}
                              page_limit={props.page_limit}
                              page_length={props.page_length}
                              ticket_count_filters={props.ticket_count_filters}
                              setEmailClicked={props.setEmailClicked}
                              openCloseModal={props.openCloseModal}
                              category_custom_value={
                                props.category_custom_value
                              }
                            />
                          ))}
                        </Form>
                      )}
                    </Formik>
                  )}
                {props.ticketData._source.platform.platform_name === "GMB" && (
                  <>
                    {props.GMBQuestionFeeds.hits &&
                      props.GMBQuestionFeeds.hits.length > 0 && (
                        <Formik
                          initialValues={{
                            feed: props.GMBQuestionFeeds.hits.map((item) => {
                              return {
                                replyOpen:
                                  props.replyClicked &&
                                  item._source.question ===
                                    props.ticketData._source.ticket_desc
                                    ? true
                                    : false,
                                emailOpen:
                                  props.emailClicked &&
                                  item._source.question ===
                                    props.ticketData._source.ticket_desc
                                    ? true
                                    : false,
                                translateClicked: false,
                              };
                            }),
                          }}
                        >
                          {({ values, setFieldValue }) => (
                            <Form>
                              {props.GMBQuestionFeeds.hits.map(
                                (item, index) => (
                                  <ReturnFeeds
                                    mass_ticket_history={
                                      props?.mass_ticket_history
                                    }
                                    index={index}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    item={item}
                                    setScrollToIndex={setScrollToIndex}
                                    setDividerId={setDividerId}
                                    isRequesting={isRequesting}
                                    setCopied={setCopied}
                                    copied={copied}
                                    isSmallScreen={isSmallScreen}
                                    openAIsuggestionTab={openAIsuggestionTab}
                                    setOpenAIsuggestionTab={
                                      setOpenAIsuggestionTab
                                    }
                                    aiSuggestionTab={aiSuggestionTab}
                                    setAiSuggestionTab={setAiSuggestionTab}
                                    setShowReplyBox={setShowReplyBox}
                                    DividerId={DividerId}
                                    setShowEmailBox={setShowEmailBox}
                                    ticketData={props?.ticketData}
                                    finalStatuses={props?.finalStatuses}
                                    statusList={props.statusList}
                                    fetchFunction={() => {
                                      props.fetchFunction();
                                    }}
                                    showFeedDetails={props.showFeedDetails}
                                    setReplyClicked={props.setReplyClicked}
                                    pageNumber={props.pageNumber}
                                    page_limit={props.page_limit}
                                    page_length={props.page_length}
                                    ticket_count_filters={
                                      props.ticket_count_filters
                                    }
                                    setEmailClicked={props.setEmailClicked}
                                    openCloseModal={props.openCloseModal}
                                    category_custom_value={
                                      props.category_custom_value
                                    }
                                  />
                                )
                              )}
                            </Form>
                          )}
                        </Formik>
                      )}
                    {props.GMBReviewFeeds.hits &&
                      props.GMBReviewFeeds.hits.length > 0 && (
                        <Formik
                          initialValues={{
                            feed: props.GMBReviewFeeds.hits.map((item) => {
                              return {
                                replyOpen:
                                  props.replyClicked &&
                                  item._source.review_comment ===
                                    props.ticketData._source.ticket_desc.split(
                                      "/ratings/"
                                    )[0]
                                    ? true
                                    : false,
                                emailOpen:
                                  props.emailClicked &&
                                  item._source.review_comment ===
                                    props.ticketData._source.ticket_desc.split(
                                      "/ratings/"
                                    )[0]
                                    ? true
                                    : false,
                                translateClicked: false,
                              };
                            }),
                          }}
                        >
                          {({ values, setFieldValue }) => (
                            <Form>
                              {props.GMBReviewFeeds.hits.map((item, index) => (
                                <ReturnFeeds
                                  mass_ticket_history={
                                    props?.mass_ticket_history
                                  }
                                  index={index}
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  item={item}
                                  setScrollToIndex={setScrollToIndex}
                                  setDividerId={setDividerId}
                                  isRequesting={isRequesting}
                                  setCopied={setCopied}
                                  copied={copied}
                                  isSmallScreen={isSmallScreen}
                                  openAIsuggestionTab={openAIsuggestionTab}
                                  setOpenAIsuggestionTab={
                                    setOpenAIsuggestionTab
                                  }
                                  aiSuggestionTab={aiSuggestionTab}
                                  setAiSuggestionTab={setAiSuggestionTab}
                                  setShowReplyBox={setShowReplyBox}
                                  DividerId={DividerId}
                                  setShowEmailBox={setShowEmailBox}
                                  ticketData={props?.ticketData}
                                  finalStatuses={props?.finalStatuses}
                                  statusList={props.statusList}
                                  fetchFunction={() => {
                                    props.fetchFunction();
                                  }}
                                  showFeedDetails={props.showFeedDetails}
                                  setReplyClicked={props.setReplyClicked}
                                  pageNumber={props.pageNumber}
                                  page_limit={props.page_limit}
                                  page_length={props.page_length}
                                  ticket_count_filters={
                                    props.ticket_count_filters
                                  }
                                  setEmailClicked={props.setEmailClicked}
                                  openCloseModal={props.openCloseModal}
                                  category_custom_value={
                                    props.category_custom_value
                                  }
                                />
                              ))}
                            </Form>
                          )}
                        </Formik>
                      )}
                  </>
                )}
                {props.ticketData._source.platform.platform_name ===
                  "LinkedIn" &&
                  props.linkedInFeeds.hits &&
                  props.linkedInFeeds.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.linkedInFeeds.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.feed_id ===
                                props.ticketData._source.ticket_name
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.feed_id ===
                                props.ticketData._source.ticket_name
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.linkedInFeeds.hits.map((item, index) => (
                            <ReturnFeeds
                              mass_ticket_history={props?.mass_ticket_history}
                              index={index}
                              setFieldValue={setFieldValue}
                              values={values}
                              item={item}
                              setScrollToIndex={setScrollToIndex}
                              setDividerId={setDividerId}
                              isRequesting={isRequesting}
                              setCopied={setCopied}
                              copied={copied}
                              isSmallScreen={isSmallScreen}
                              openAIsuggestionTab={openAIsuggestionTab}
                              setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                              aiSuggestionTab={aiSuggestionTab}
                              setAiSuggestionTab={setAiSuggestionTab}
                              setShowReplyBox={setShowReplyBox}
                              DividerId={DividerId}
                              setShowEmailBox={setShowEmailBox}
                              ticketData={props?.ticketData}
                              finalStatuses={props?.finalStatuses}
                              statusList={props.statusList}
                              fetchFunction={() => {
                                props.fetchFunction();
                              }}
                              showFeedDetails={props.showFeedDetails}
                              setReplyClicked={props.setReplyClicked}
                              pageNumber={props.pageNumber}
                              page_limit={props.page_limit}
                              page_length={props.page_length}
                              ticket_count_filters={props.ticket_count_filters}
                              setEmailClicked={props.setEmailClicked}
                              openCloseModal={props.openCloseModal}
                              category_custom_value={
                                props.category_custom_value
                              }
                            />
                          ))}
                        </Form>
                      )}
                    </Formik>
                  )}
                {props.ticketData._source.platform.platform_name ===
                  "Youtube" &&
                  props.youtubeFeed.hits &&
                  props.youtubeFeed.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.youtubeFeed.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              (item._source.video_title ===
                                props.ticketData._source.ticket_desc ||
                                item._source.comment_content ===
                                  props.ticketData._source.ticket_desc)
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              (item._source.video_title ===
                                props.ticketData._source.ticket_desc ||
                                item._source.comment_content ===
                                  props.ticketData._source.ticket_desc)
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.youtubeFeed.hits.map((item, index) => (
                            <ReturnFeeds
                              mass_ticket_history={props?.mass_ticket_history}
                              index={index}
                              setFieldValue={setFieldValue}
                              values={values}
                              item={item}
                              setScrollToIndex={setScrollToIndex}
                              setDividerId={setDividerId}
                              isRequesting={isRequesting}
                              setCopied={setCopied}
                              copied={copied}
                              isSmallScreen={isSmallScreen}
                              openAIsuggestionTab={openAIsuggestionTab}
                              setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                              aiSuggestionTab={aiSuggestionTab}
                              setAiSuggestionTab={setAiSuggestionTab}
                              setShowReplyBox={setShowReplyBox}
                              DividerId={DividerId}
                              setShowEmailBox={setShowEmailBox}
                              ticketData={props?.ticketData}
                              finalStatuses={props?.finalStatuses}
                              statusList={props.statusList}
                              fetchFunction={() => {
                                props.fetchFunction();
                              }}
                              showFeedDetails={props.showFeedDetails}
                              setReplyClicked={props.setReplyClicked}
                              pageNumber={props.pageNumber}
                              page_limit={props.page_limit}
                              page_length={props.page_length}
                              ticket_count_filters={props.ticket_count_filters}
                              setEmailClicked={props.setEmailClicked}
                              openCloseModal={props.openCloseModal}
                              category_custom_value={
                                props.category_custom_value
                              }
                            />
                          ))}
                        </Form>
                      )}
                    </Formik>
                  )}
                {props.ticketData._source.platform.platform_name ===
                  "Playstore" &&
                  props.playstore.hits &&
                  props.playstore.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.playstore.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.review_content ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.review_content ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.playstore.hits.map((item, index) => (
                            <ReturnFeeds
                              mass_ticket_history={props?.mass_ticket_history}
                              index={index}
                              setFieldValue={setFieldValue}
                              values={values}
                              item={item}
                              setScrollToIndex={setScrollToIndex}
                              setDividerId={setDividerId}
                              isRequesting={isRequesting}
                              setCopied={setCopied}
                              copied={copied}
                              isSmallScreen={isSmallScreen}
                              openAIsuggestionTab={openAIsuggestionTab}
                              setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                              aiSuggestionTab={aiSuggestionTab}
                              setAiSuggestionTab={setAiSuggestionTab}
                              setShowReplyBox={setShowReplyBox}
                              DividerId={DividerId}
                              setShowEmailBox={setShowEmailBox}
                              ticketData={props?.ticketData}
                              finalStatuses={props?.finalStatuses}
                              statusList={props.statusList}
                              fetchFunction={() => {
                                props.fetchFunction();
                              }}
                              showFeedDetails={props.showFeedDetails}
                              setReplyClicked={props.setReplyClicked}
                              pageNumber={props.pageNumber}
                              page_limit={props.page_limit}
                              page_length={props.page_length}
                              ticket_count_filters={props.ticket_count_filters}
                              setEmailClicked={props.setEmailClicked}
                              openCloseModal={props.openCloseModal}
                              category_custom_value={
                                props.category_custom_value
                              }
                            />
                          ))}
                        </Form>
                      )}
                    </Formik>
                  )}
                {props.ticketData._source.platform.platform_name ===
                  "Appstore" &&
                  props.appStore.hits &&
                  props.appStore.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.appStore.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.review_heading ===
                                props.ticketData._source.ticket_desc.split(
                                  "/ratings/"
                                )[0]
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.review_heading ===
                                props.ticketData._source.ticket_desc.split(
                                  "/ratings/"
                                )[0]
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.appStore.hits.map((item, index) => (
                            <ReturnFeeds
                              mass_ticket_history={props?.mass_ticket_history}
                              index={index}
                              setFieldValue={setFieldValue}
                              values={values}
                              item={item}
                              setScrollToIndex={setScrollToIndex}
                              setDividerId={setDividerId}
                              isRequesting={isRequesting}
                              setCopied={setCopied}
                              copied={copied}
                              isSmallScreen={isSmallScreen}
                              openAIsuggestionTab={openAIsuggestionTab}
                              setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                              aiSuggestionTab={aiSuggestionTab}
                              setAiSuggestionTab={setAiSuggestionTab}
                              setShowReplyBox={setShowReplyBox}
                              DividerId={DividerId}
                              setShowEmailBox={setShowEmailBox}
                              ticketData={props?.ticketData}
                              finalStatuses={props?.finalStatuses}
                              statusList={props.statusList}
                              fetchFunction={() => {
                                props.fetchFunction();
                              }}
                              showFeedDetails={props.showFeedDetails}
                              setReplyClicked={props.setReplyClicked}
                              pageNumber={props.pageNumber}
                              page_limit={props.page_limit}
                              page_length={props.page_length}
                              ticket_count_filters={props.ticket_count_filters}
                              setEmailClicked={props.setEmailClicked}
                              openCloseModal={props.openCloseModal}
                              category_custom_value={
                                props.category_custom_value
                              }
                            />
                          ))}
                        </Form>
                      )}
                    </Formik>
                  )}
                {props.ticketData._source.platform.platform_name ===
                  "Ambition Box" &&
                  props.ambitionBoxFeeds.hits &&
                  props.ambitionBoxFeeds.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: Array(props.ambitionBoxFeeds.hits.length).fill({
                          replyOpen: false,
                          emailOpen: false,
                          translateClicked: false,
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.ambitionBoxFeeds.hits.map((item, index) => (
                            <ReturnFeeds
                              mass_ticket_history={props?.mass_ticket_history}
                              index={index}
                              setFieldValue={setFieldValue}
                              values={values}
                              item={item}
                              setScrollToIndex={setScrollToIndex}
                              setDividerId={setDividerId}
                              isRequesting={isRequesting}
                              setCopied={setCopied}
                              copied={copied}
                              isSmallScreen={isSmallScreen}
                              openAIsuggestionTab={openAIsuggestionTab}
                              setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                              aiSuggestionTab={aiSuggestionTab}
                              setAiSuggestionTab={setAiSuggestionTab}
                              setShowReplyBox={setShowReplyBox}
                              DividerId={DividerId}
                              setShowEmailBox={setShowEmailBox}
                              ticketData={props?.ticketData}
                              finalStatuses={props?.finalStatuses}
                              statusList={props.statusList}
                              fetchFunction={() => {
                                props.fetchFunction();
                              }}
                              showFeedDetails={props.showFeedDetails}
                              setReplyClicked={props.setReplyClicked}
                              pageNumber={props.pageNumber}
                              page_limit={props.page_limit}
                              page_length={props.page_length}
                              ticket_count_filters={props.ticket_count_filters}
                              setEmailClicked={props.setEmailClicked}
                              openCloseModal={props.openCloseModal}
                              category_custom_value={
                                props.category_custom_value
                              }
                            />
                          ))}
                        </Form>
                      )}
                    </Formik>
                  )}
                {props.ticketData._source.platform.platform_name ===
                  "Glassdoor" &&
                  props.glassdoorFeeds.hits &&
                  props.glassdoorFeeds.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.glassdoorFeeds.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.reviews ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.reviews ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.glassdoorFeeds.hits.map((item, index) => (
                            <ReturnFeeds
                              mass_ticket_history={props?.mass_ticket_history}
                              index={index}
                              setFieldValue={setFieldValue}
                              values={values}
                              item={item}
                              setScrollToIndex={setScrollToIndex}
                              setDividerId={setDividerId}
                              isRequesting={isRequesting}
                              setCopied={setCopied}
                              copied={copied}
                              isSmallScreen={isSmallScreen}
                              openAIsuggestionTab={openAIsuggestionTab}
                              setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                              aiSuggestionTab={aiSuggestionTab}
                              setAiSuggestionTab={setAiSuggestionTab}
                              setShowReplyBox={setShowReplyBox}
                              DividerId={DividerId}
                              setShowEmailBox={setShowEmailBox}
                              ticketData={props?.ticketData}
                              finalStatuses={props?.finalStatuses}
                              statusList={props.statusList}
                              fetchFunction={() => {
                                props.fetchFunction();
                              }}
                              showFeedDetails={props.showFeedDetails}
                              setReplyClicked={props.setReplyClicked}
                              pageNumber={props.pageNumber}
                              page_limit={props.page_limit}
                              page_length={props.page_length}
                              ticket_count_filters={props.ticket_count_filters}
                              setEmailClicked={props.setEmailClicked}
                              openCloseModal={props.openCloseModal}
                              category_custom_value={
                                props.category_custom_value
                              }
                            />
                          ))}
                        </Form>
                      )}
                    </Formik>
                  )}
                {props.ticketData._source.platform.platform_name === "Email" &&
                  props.emailFeeds.hits &&
                  props.emailFeeds.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.emailFeeds.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.subject ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.subject ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.emailFeeds.hits.map((item, index) => (
                            <ReturnFeeds
                              mass_ticket_history={props?.mass_ticket_history}
                              index={index}
                              setFieldValue={setFieldValue}
                              values={values}
                              item={item}
                              setScrollToIndex={setScrollToIndex}
                              setDividerId={setDividerId}
                              isRequesting={isRequesting}
                              setCopied={setCopied}
                              copied={copied}
                              isSmallScreen={isSmallScreen}
                              openAIsuggestionTab={openAIsuggestionTab}
                              setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                              aiSuggestionTab={aiSuggestionTab}
                              setAiSuggestionTab={setAiSuggestionTab}
                              setShowReplyBox={setShowReplyBox}
                              DividerId={DividerId}
                              setShowEmailBox={setShowEmailBox}
                              ticketData={props?.ticketData}
                              finalStatuses={props?.finalStatuses}
                              statusList={props.statusList}
                              fetchFunction={() => {
                                props.fetchFunction();
                              }}
                              showFeedDetails={props.showFeedDetails}
                              setReplyClicked={props.setReplyClicked}
                              pageNumber={props.pageNumber}
                              page_limit={props.page_limit}
                              page_length={props.page_length}
                              ticket_count_filters={props.ticket_count_filters}
                              setEmailClicked={props.setEmailClicked}
                              openCloseModal={props.openCloseModal}
                              category_custom_value={
                                props.category_custom_value
                              }
                            />
                          ))}
                        </Form>
                      )}
                    </Formik>
                  )}
                {props.ticketData._source.platform.platform_name === "Quora" &&
                  props.quoraFeed.hits &&
                  props.quoraFeed.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.quoraFeed.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.answer ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.answer ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.quoraFeed.hits.map((item, index) => (
                            <ReturnFeeds
                              mass_ticket_history={props?.mass_ticket_history}
                              index={index}
                              setFieldValue={setFieldValue}
                              values={values}
                              item={item}
                              setScrollToIndex={setScrollToIndex}
                              setDividerId={setDividerId}
                              isRequesting={isRequesting}
                              setCopied={setCopied}
                              copied={copied}
                              isSmallScreen={isSmallScreen}
                              openAIsuggestionTab={openAIsuggestionTab}
                              setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                              aiSuggestionTab={aiSuggestionTab}
                              setAiSuggestionTab={setAiSuggestionTab}
                              setShowReplyBox={setShowReplyBox}
                              DividerId={DividerId}
                              setShowEmailBox={setShowEmailBox}
                              ticketData={props?.ticketData}
                              finalStatuses={props?.finalStatuses}
                              statusList={props.statusList}
                              fetchFunction={() => {
                                props.fetchFunction();
                              }}
                              showFeedDetails={props.showFeedDetails}
                              setReplyClicked={props.setReplyClicked}
                              pageNumber={props.pageNumber}
                              page_limit={props.page_limit}
                              page_length={props.page_length}
                              ticket_count_filters={props.ticket_count_filters}
                              setEmailClicked={props.setEmailClicked}
                              openCloseModal={props.openCloseModal}
                              category_custom_value={
                                props.category_custom_value
                              }
                            />
                          ))}
                        </Form>
                      )}
                    </Formik>
                  )}

                {props.ticketData._source.platform.platform_name === "Indeed" &&
                  props.indeedFeeds.hits &&
                  props.indeedFeeds.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.indeedFeeds.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.rating_description ===
                                props.ticketData._source.ticket_desc.split(
                                  "/ratings/"
                                )[0]
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.rating_description ===
                                props.ticketData._source.ticket_desc.split(
                                  "/ratings/"
                                )[0]
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.indeedFeeds.hits.map((item, index) => (
                            <ReturnFeeds
                              mass_ticket_history={props?.mass_ticket_history}
                              index={index}
                              setFieldValue={setFieldValue}
                              values={values}
                              item={item}
                              setScrollToIndex={setScrollToIndex}
                              setDividerId={setDividerId}
                              isRequesting={isRequesting}
                              setCopied={setCopied}
                              copied={copied}
                              isSmallScreen={isSmallScreen}
                              openAIsuggestionTab={openAIsuggestionTab}
                              setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                              aiSuggestionTab={aiSuggestionTab}
                              setAiSuggestionTab={setAiSuggestionTab}
                              setShowReplyBox={setShowReplyBox}
                              DividerId={DividerId}
                              setShowEmailBox={setShowEmailBox}
                              ticketData={props?.ticketData}
                              finalStatuses={props?.finalStatuses}
                              statusList={props.statusList}
                              fetchFunction={() => {
                                props.fetchFunction();
                              }}
                              showFeedDetails={props.showFeedDetails}
                              setReplyClicked={props.setReplyClicked}
                              pageNumber={props.pageNumber}
                              page_limit={props.page_limit}
                              page_length={props.page_length}
                              ticket_count_filters={props.ticket_count_filters}
                              setEmailClicked={props.setEmailClicked}
                              openCloseModal={props.openCloseModal}
                              category_custom_value={
                                props.category_custom_value
                              }
                            />
                          ))}
                        </Form>
                      )}
                    </Formik>
                  )}

                {props.ticketData._source.platform.platform_name ===
                  "Trust Pilot" &&
                  props.trustPilotFeeds.hits &&
                  props.trustPilotFeeds.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.trustPilotFeeds.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.rating_description ===
                                props.ticketData._source.ticket_desc
                                ? // .split(
                                  //   "/ratings/"
                                  // )[0]
                                  true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.description ===
                                props.ticketData._source.ticket_desc
                                ? // .split(
                                  //   "/ratings/"
                                  // )[0]
                                  true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.trustPilotFeeds.hits.map((item, index) => (
                            <ReturnFeeds
                              mass_ticket_history={props?.mass_ticket_history}
                              index={index}
                              setFieldValue={setFieldValue}
                              values={values}
                              item={item}
                              setScrollToIndex={setScrollToIndex}
                              setDividerId={setDividerId}
                              isRequesting={isRequesting}
                              setCopied={setCopied}
                              copied={copied}
                              isSmallScreen={isSmallScreen}
                              openAIsuggestionTab={openAIsuggestionTab}
                              setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                              aiSuggestionTab={aiSuggestionTab}
                              setAiSuggestionTab={setAiSuggestionTab}
                              setShowReplyBox={setShowReplyBox}
                              DividerId={DividerId}
                              setShowEmailBox={setShowEmailBox}
                              ticketData={props?.ticketData}
                              finalStatuses={props?.finalStatuses}
                              statusList={props.statusList}
                              fetchFunction={() => {
                                props.fetchFunction();
                              }}
                              showFeedDetails={props.showFeedDetails}
                              setReplyClicked={props.setReplyClicked}
                              pageNumber={props.pageNumber}
                              page_limit={props.page_limit}
                              page_length={props.page_length}
                              ticket_count_filters={props.ticket_count_filters}
                              setEmailClicked={props.setEmailClicked}
                              openCloseModal={props.openCloseModal}
                              category_custom_value={
                                props.category_custom_value
                              }
                            />
                          ))}
                        </Form>
                      )}
                    </Formik>
                  )}

                {props.ticketData._source.platform.platform_name ===
                  "Pinterest" &&
                  props.pinterestFeeds.hits &&
                  props.pinterestFeeds.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.pinterestFeeds.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.rating_description ===
                                props.ticketData._source.ticket_desc
                                ? // .split(
                                  //   "/ratings/"
                                  // )[0]
                                  true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.description ===
                                props.ticketData._source.ticket_desc
                                ? // .split(
                                  //   "/ratings/"
                                  // )[0]
                                  true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.pinterestFeeds.hits.map((item, index) => (
                            <ReturnFeeds
                              mass_ticket_history={props?.mass_ticket_history}
                              index={index}
                              setFieldValue={setFieldValue}
                              values={values}
                              item={item}
                              setScrollToIndex={setScrollToIndex}
                              setDividerId={setDividerId}
                              isRequesting={isRequesting}
                              setCopied={setCopied}
                              copied={copied}
                              isSmallScreen={isSmallScreen}
                              openAIsuggestionTab={openAIsuggestionTab}
                              setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                              aiSuggestionTab={aiSuggestionTab}
                              setAiSuggestionTab={setAiSuggestionTab}
                              setShowReplyBox={setShowReplyBox}
                              DividerId={DividerId}
                              setShowEmailBox={setShowEmailBox}
                              ticketData={props?.ticketData}
                              finalStatuses={props?.finalStatuses}
                              statusList={props.statusList}
                              fetchFunction={() => {
                                props.fetchFunction();
                              }}
                              showFeedDetails={props.showFeedDetails}
                              setReplyClicked={props.setReplyClicked}
                              pageNumber={props.pageNumber}
                              page_limit={props.page_limit}
                              page_length={props.page_length}
                              ticket_count_filters={props.ticket_count_filters}
                              setEmailClicked={props.setEmailClicked}
                              openCloseModal={props.openCloseModal}
                              category_custom_value={
                                props.category_custom_value
                              }
                            />
                          ))}
                        </Form>
                      )}
                    </Formik>
                  )}

                {/* {props.ticketData._source.platform.platform_name === "Reddit" &&
                  props.redditPosts.hits &&
                  props.redditPosts.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.redditFeeds.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.description ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.description ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.redditFeeds.hits.map((item, index) =>
                            ReturnFeeds(
                              index,
                              setFieldValue,
                              values,
                              item,
                              setScrollToIndex
                            )
                          )}
                        </Form>
                      )}
                    </Formik>
                  )} */}

                {props.ticketData._source.platform.platform_name === "Reddit" &&
                  props.ticketData._source.activities.activities_name ===
                    "Posts" &&
                  props.redditPosts.hits &&
                  props.redditPosts.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.redditPosts.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.description ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.description ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.redditPosts.hits.map((item, index) => (
                            <ReturnFeeds
                              mass_ticket_history={props?.mass_ticket_history}
                              index={index}
                              setFieldValue={setFieldValue}
                              values={values}
                              item={item}
                              setScrollToIndex={setScrollToIndex}
                              setDividerId={setDividerId}
                              isRequesting={isRequesting}
                              setCopied={setCopied}
                              copied={copied}
                              isSmallScreen={isSmallScreen}
                              openAIsuggestionTab={openAIsuggestionTab}
                              setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                              aiSuggestionTab={aiSuggestionTab}
                              setAiSuggestionTab={setAiSuggestionTab}
                              setShowReplyBox={setShowReplyBox}
                              DividerId={DividerId}
                              setShowEmailBox={setShowEmailBox}
                              ticketData={props?.ticketData}
                              finalStatuses={props?.finalStatuses}
                              statusList={props.statusList}
                              fetchFunction={() => {
                                props.fetchFunction();
                              }}
                              showFeedDetails={props.showFeedDetails}
                              setReplyClicked={props.setReplyClicked}
                              pageNumber={props.pageNumber}
                              page_limit={props.page_limit}
                              page_length={props.page_length}
                              ticket_count_filters={props.ticket_count_filters}
                              setEmailClicked={props.setEmailClicked}
                              openCloseModal={props.openCloseModal}
                              category_custom_value={
                                props.category_custom_value
                              }
                            />
                          ))}
                        </Form>
                      )}
                    </Formik>
                  )}

                {props.ticketData._source.platform.platform_name === "Reddit" &&
                  props.ticketData._source.activities.activities_name ===
                    "Comments" &&
                  props.redditComments.hits &&
                  props.redditComments.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.redditComments.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.description ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.description ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.redditComments.hits.map((item, index) => (
                            <ReturnFeeds
                              mass_ticket_history={props?.mass_ticket_history}
                              index={index}
                              setFieldValue={setFieldValue}
                              values={values}
                              item={item}
                              setScrollToIndex={setScrollToIndex}
                              setDividerId={setDividerId}
                              isRequesting={isRequesting}
                              setCopied={setCopied}
                              copied={copied}
                              isSmallScreen={isSmallScreen}
                              openAIsuggestionTab={openAIsuggestionTab}
                              setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                              aiSuggestionTab={aiSuggestionTab}
                              setAiSuggestionTab={setAiSuggestionTab}
                              setShowReplyBox={setShowReplyBox}
                              DividerId={DividerId}
                              setShowEmailBox={setShowEmailBox}
                              ticketData={props?.ticketData}
                              finalStatuses={props?.finalStatuses}
                              statusList={props.statusList}
                              fetchFunction={() => {
                                props.fetchFunction();
                              }}
                              showFeedDetails={props.showFeedDetails}
                              setReplyClicked={props.setReplyClicked}
                              pageNumber={props.pageNumber}
                              page_limit={props.page_limit}
                              page_length={props.page_length}
                              ticket_count_filters={props.ticket_count_filters}
                              setEmailClicked={props.setEmailClicked}
                              openCloseModal={props.openCloseModal}
                              category_custom_value={
                                props.category_custom_value
                              }
                            />
                          ))}
                        </Form>
                      )}
                    </Formik>
                  )}

                {props.ticketData._source.platform.platform_name ===
                  "Consumer Complaints" &&
                  props.consumer_feed.hits &&
                  props.consumer_feed.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.consumer_feed.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.description ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.description ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.consumer_feed.hits.map((item, index) => (
                            <ReturnFeeds
                              mass_ticket_history={props?.mass_ticket_history}
                              index={index}
                              setFieldValue={setFieldValue}
                              values={values}
                              item={item}
                              setScrollToIndex={setScrollToIndex}
                              setDividerId={setDividerId}
                              isRequesting={isRequesting}
                              setCopied={setCopied}
                              copied={copied}
                              isSmallScreen={isSmallScreen}
                              openAIsuggestionTab={openAIsuggestionTab}
                              setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                              aiSuggestionTab={aiSuggestionTab}
                              setAiSuggestionTab={setAiSuggestionTab}
                              setShowReplyBox={setShowReplyBox}
                              DividerId={DividerId}
                              setShowEmailBox={setShowEmailBox}
                              ticketData={props?.ticketData}
                              finalStatuses={props?.finalStatuses}
                              statusList={props.statusList}
                              fetchFunction={() => {
                                props.fetchFunction();
                              }}
                              showFeedDetails={props.showFeedDetails}
                              setReplyClicked={props.setReplyClicked}
                              pageNumber={props.pageNumber}
                              page_limit={props.page_limit}
                              page_length={props.page_length}
                              ticket_count_filters={props.ticket_count_filters}
                              setEmailClicked={props.setEmailClicked}
                              openCloseModal={props.openCloseModal}
                              category_custom_value={
                                props.category_custom_value
                              }
                            />
                          ))}
                        </Form>
                      )}
                    </Formik>
                  )}

                {props.ticketData._source.platform.platform_id === "14" && (
                  <Box
                    sx={{
                      paddingX: 2,
                      paddingTop: 2,
                      paddingBottom: 3,
                    }}
                  >
                    <Details
                      ticketData={props.ticketData && props.ticketData}
                      filters={props.filters}
                      fetchFunction={props.fetchFunction}
                    />
                  </Box>
                )}
              </>
            )}

          {!isRequesting &&
            props.ticketData._source &&
            props.ticketData._source.platform &&
            ((props.ticketData._source &&
              props.ticketData._source.platform &&
              props.ticketData._source.platform.platform_name === "Telegram" &&
              props.telegramFeeds.hits &&
              props.telegramFeeds.hits.length) ||
              (props.ticketData._source &&
                props.ticketData._source.platform.platform_name &&
                props.ticketData._source.platform.platform_name ===
                  "Facebook" &&
                props.facebook_dm.hits &&
                props.facebook_dm.hits.length) ||
              (props.ticketData._source &&
                props.ticketData._source.platform.platform_name &&
                props.ticketData._source.platform.platform_name ===
                  "Instagram" &&
                props.instagramDirectMessage.hits &&
                props.instagramDirectMessage.hits.length) ||
              (props.ticketData._source &&
                props.ticketData._source.platform.platform_name &&
                props.ticketData._source.platform.platform_name ===
                  "Whatsapp" &&
                props.whatsAppFeed.hits &&
                props.whatsAppFeed.hits.length) ||
              (props.ticketData._source &&
                props.ticketData._source.platform.platform_name &&
                props.ticketData._source.platform.platform_name === "Twitter" &&
                props.twitterDirectMeassage.hits &&
                props.twitterDirectMeassage.hits.length > 0) ||
              (props.ticketData._source &&
                props.ticketData._source.platform.platform_name &&
                props.ticketData._source.platform.platform_name === "Discord" &&
                props.discordFeeds.hits &&
                props.discordFeeds.hits.length)) && (
              <Formik
                initialValues={initial_val_for_TicketPlatformMessagesContainer}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <TicketPlatformMessagesContainer
                      // index={index}
                      mass_ticket_history={props.mass_ticket_history}
                      finalStatuses={props.finalStatuses}
                      category_custom_value={props.category_custom_value}
                      openCloseModal={props.openCloseModal}
                      tagopenCloseModal={props.tagopenCloseModal}
                      setFieldValue={setFieldValue}
                      values={values}
                      replyClicked={props.replyClicked}
                      // ref={containerRef}
                      filters={props.filters}
                      setScrollToIndex={setScrollToIndex}
                      // item={item}
                      ticketData={props.ticketData}
                      fetchFunction={props.fetchFunction}
                      statusList={props.statusList}
                      customerData={props.customerData}
                      showFeedDetails={props.showFeedDetails}
                      ticket_count_filters={props.ticket_count_filters}
                      pageNumber={props.pageNumber}
                      page_limit={props.page_limit}
                      page_length={props.page_length}
                      messages={
                        props.ticketData._source.platform.platform_name ===
                        "Telegram"
                          ? props.telegramFeeds.hits
                          : props.ticketData._source.platform.platform_name ===
                            "Facebook"
                          ? props.facebook_dm.hits &&
                            props.facebook_dm.hits.length &&
                            props.facebook_dm.hits
                          : props.ticketData._source.platform.platform_name ===
                            "Instagram"
                          ? props.instagramDirectMessage.hits
                          : props.ticketData._source.platform.platform_name ===
                              "Twitter" &&
                            props.twitterDirectMeassage.hits &&
                            props.twitterDirectMeassage.hits.length
                          ? props.twitterDirectMeassage.hits
                          : props.ticketData._source.platform.platform_name ===
                              "Whatsapp" &&
                            props.whatsAppFeed.hits &&
                            props.whatsAppFeed.hits.length
                          ? props.whatsAppFeed.hits
                          : props.ticketData._source.platform.platform_name ===
                            "Discord"
                          ? props.discordFeeds.hits &&
                            props.discordFeeds.hits.length &&
                            props.discordFeeds.hits
                          : []
                      }
                      timeAgo={timeAgo}
                      openAIsuggestionTab={openAIsuggestionTab}
                      setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                      aiSuggestionTab={aiSuggestionTab}
                      setAiSuggestionTab={setAiSuggestionTab}
                    />
                  </Form>
                )}
              </Formik>
            )}

          {!isRequesting &&
            props.ticketData &&
            props.ticketData._source &&
            props.ticketData._source.Notes.length !== 0 && (
              <Grid>
                <Box
                  sx={{
                    paddingX: 2,
                    paddingY: 1,
                  }}
                >
                  <Grid
                    item
                    container
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    alignItems="center"
                    justifyContent={"flex-end"}
                  >
                    <Grid
                      item
                      xl={9}
                      lg={9}
                      md={9}
                      sm={9}
                      xs={9}
                      alignItems="flex-end"
                      justifyContent={"center"}
                      textAlign="left"
                      sx={{
                        borderRadius: "10px",
                        border: "solid 0.5px transparent",
                        backgroundColor: "white",
                        color: `${theme.palette.text.primary}`,
                      }}
                    >
                      <Box
                        sx={{
                          // border: "1px solid gray",
                          p: 1,
                          borderRadius: "5px",
                          // backgroundColor: "yellow",
                        }}
                      >
                        <span>Notes :</span>
                        {!isRequesting &&
                          props.ticketData._source &&
                          props.ticketData._source.Notes &&
                          props.ticketData._source.Notes.map(
                            (item, index, array) => (
                              <NotesUI
                                item={item}
                                index={index}
                                Notes={array}
                                ticketData={props.ticketData}
                              />
                            )
                          )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )}

          {!isRequesting &&
            props.finalStatuses &&
            props.finalStatuses.length > 0 &&
            props.finalStatuses.includes(
              props.ticketData &&
                props.ticketData._source &&
                props.ticketData._source.status.status_id
            ) && (
              <Grid item container xl={12} mt={0.5}>
                <Box
                  width={"100%"}
                  sx={{
                    paddingX: 2,
                    paddingY: 0,
                  }}
                  // className="bg-warning"
                >
                  <Grid
                    item
                    container
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    alignItems="center"
                    justifyContent={"flex-end"}
                    // className="bg-success"
                  >
                    <Grid
                      item
                      textAlign={"right"}
                      // xl={10}
                      // lg={10}
                      // md={10}
                      // sm={10}
                      // className="bg-info"
                      sx={{
                        backgroundColor: "white",
                        border: "solid 0.5px #92b9c4",
                        fontFamily: "BookAntiqua-Italic",
                        borderRadius: 1,
                      }}
                      px={2}
                    >
                      <span
                        style={{
                          color: `${theme.palette.primary.main}`,
                        }}
                      >
                        <span>
                          {props.ticket_closed &&
                            props.ticket_closed[0] &&
                            props.ticket_closed[0]._source.description.split(
                              "By"
                            )[1]}
                        </span>
                        &nbsp;
                        <span>{`has closed the ticket ID :`}</span>
                        &nbsp;
                        <span>
                          {props.ticketData &&
                            props.ticketData._id &&
                            props.ticketData._id}
                        </span>
                        &nbsp;
                        <span>at</span>
                        &nbsp;
                        <span
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          {(function createdDate() {
                            let created =
                              props.ticket_closed &&
                              props.ticket_closed[0] &&
                              props.ticket_closed[0]._source.created_on;
                            let dateConversion = new Date(created);
                            dateConversion =
                              dateConversion.toDateString() +
                              ", " +
                              dateConversion.toLocaleTimeString("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              });

                            return dateConversion;
                          })()}
                        </span>
                      </span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )}
        </Box>
      </LoadingIndicator>
    </Box>
  );
};

export default connect(mapStateToProps)(TicketFeedComponent);
