import React, { useEffect, useState } from "react";
import environment from "environment";
import { Box } from "@mui/material";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import { axiosRequest } from "../../../../utilities/FetchRequest";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../../components/loadingBgImage/LoadingBgImage";

const CategoryWiseDistributionChart = (props) => {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchData();
      setLoading(false);
    })();
  }, []);

  async function fetchData(month) {
    let API = `${environment.api.ticketReports}?report_type=["count"]&reports=["customeValue"]`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (response.hasOwnProperty("message")) {
          setData(null);
          setApiError(response.message);
        } else {
          setData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setData(null);
        setApiError(err.message);
        setLoading(false);
      });

    // return fetch(
    //   `${environment.api.ticketReports}?report_type=["count"]&reports=["customeValue"]`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((response) => {
    //     setData(response);
    //   });
  }

  let categoryList = [];

  data &&
    data.ticket_report &&
    data.ticket_report.customeValue &&
    data.ticket_report.customeValue
      .filter((item) => Object.keys(item)[0] === props.filterValue)
      .map((value) => {
        Object.entries(value)[0][1].map((item) => {
          categoryList.push({
            name: item.key,
            count: item.count,
            percentage: item.percentage,
          });
        });
      });

  return (
    <LoadingIndicator isActive={loading}>
      <Box
        style={{ width: "100%" }}
        sx={{
          paddingX: 5,
        }}
      >
        {!loading ? (
          apiError ? (
            <Box
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flex: "12",
              }}
            >
              {apiError}
            </Box>
          ) : (
            <Box height={"100%"}>
              <Box
                sx={{
                  padding: "12px",
                  height: "100%",
                }}
              ></Box>
              {!loading && <NoDataFound />}
            </Box>
          )
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  );
};

export default CategoryWiseDistributionChart;
