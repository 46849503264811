import { BaseModel } from "sjs-base-model";

export default class RevenueThresoldModel extends BaseModel {
  // response getting from the api mentioned end point
  data = [];
  message = "";
  status = "";
  constructor(data1) {
    super();
    this.update(data1);
  }
}
