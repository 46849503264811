import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { Box, Grid, ListItem, Tooltip, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import PaginationUI from "../../../components/pagination/Pagination";
import { axiosRequest } from "../../../utilities/FetchRequest";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import NoDataFound from "../../../components/nodatafound/NoDataFound";

function RoleSummary(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [roleSummaryData, setRoleSummaryData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchAgentRoleSummary(props.DateFilter);
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchAgentRoleSummary(props.DateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchAgentRoleSummary(props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchAgentRoleSummary(dateRange) {
    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["agent_role_summary"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    try {
      const res = axiosRequest(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      const response = await res;
      if (response.hasOwnProperty("message")) {
        setRoleSummaryData(null);
        setApiError(response.message);
      } else {
        setRoleSummaryData(response);
        setApiError(null);
      }
    } catch (err) {
      setRoleSummaryData(null);
      setApiError(err.message);
      setLoading(false);
    }
  }

  function ReturnUserName(userId) {
    return userId === "Unassigned"
      ? "Unassigned"
      : props.userList &&
          props.userList.length > 0 &&
          props.userList.filter((user) => user.user_id === userId).length &&
          props.userList.filter((user) => user.user_id === userId)[0].user_name;
  }

  const roundToThreeDec = (decimal) => {
    const number = decimal;
    const roundedNumber = number.toFixed(3);
    return roundedNumber;
  };

  const returnWorkingHours = (hours) => {
    return hours !== 0 ? roundToThreeDec(hours) : 0;
  };

  let columns = [];

  columns.push(
    { field: "id", headerName: "Id", resizable: false, width: 50, hide: true },
    {
      field: "user_name",
      headerName: "Assigned To",
      width: 150,
      resizable: true,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "role_name",
      headerName: "Role",
      width: 100,
      resizable: true,
      headerAlign: "center",
      flex: 1,
    },

    {
      field: "assigned_tickets",
      headerName: "Assigned Tickets",
      width: 100,
      resizable: true,
      align: "center",
      headerAlign: "center",
      flex: 1,
      hide: true,
    },

    {
      field: "cummulative",
      headerName: "Tickets",
      resizable: true,
      align: "center",
      headerAlign: "center",
      flex: 1.5,
      renderCell: (params) => (
        <Box
          sx={{
            height: "100%",
            width: "auto",
            textAlign: "left",
            display: "flex",
            justifyContent: "flex-start",
          }}
          style={{
            marginHorizontal: "5%",
          }}
        >
          <ListItem
            // title={`Assigned tickets : ${params.row.assigned_tickets}`}
            sx={{
              width: "auto",
              cursor: "pointer",
              textAlign: "left",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            // className="bg-success"
          >
            <Tooltip title={`Assigned :${params.row.assigned_tickets}`}>
              <Box
                sx={{
                  // width: `${
                  //   (params.row.assigned_tickets /
                  //     (params.row.assigned_tickets +
                  //       params.row.assigned_tickets)) *
                  //   100
                  // }%`,
                  width: "auto",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  backgroundColor: "#6aa6ff",
                  color: "black",
                  textAlign: "center",
                  paddingX: 1,
                  borderBottomLeftRadius: "4px",
                  borderTopLeftRadius: "4px",
                }}
              >
                {params.row.assigned_tickets}
              </Box>
            </Tooltip>
            <Tooltip title={`Resolved :${params.row.resolved_tickets}`}>
              <Box
                sx={{
                  // width: `${
                  //   (params.row.resolved_tickets /
                  //     (params.row.assigned_tickets * 2)) *
                  //   100
                  // }%`,
                  width: "auto",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  backgroundColor: "#bab8b8",
                  color: "black",
                  textAlign: "center",
                  paddingX: 1,
                }}
              >
                {params.row.resolved_tickets}
              </Box>
            </Tooltip>
            <Tooltip title={`UnResolved :${params.row.unresolved_tickets}`}>
              <Box
                sx={{
                  // width: `${
                  //   (params.row.unresolved_tickets /
                  //     (params.row.assigned_tickets * 2)) *
                  //   100
                  // }%`,
                  width: "auto",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  backgroundColor: "#e5e2e2",
                  color: "black",
                  textAlign: "center",
                  paddingX: 1,
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                }}
              >
                {params.row.unresolved_tickets}
              </Box>
            </Tooltip>
          </ListItem>
        </Box>
      ),
    },

    {
      field: "resolved_tickets",
      headerName: "Resolved Tickets",
      width: 100,
      resizable: true,
      align: "center",
      headerAlign: "center",
      flex: 1,
      hide: true,
    },

    {
      field: "unresolved_tickets",
      headerName: "UnResolved Tickets",
      width: 100,
      resizable: true,
      align: "center",
      headerAlign: "center",
      flex: 1,
      hide: true,
    }
  );

  columns.push(
    {
      field: "frt",
      headerName: "Avg FRT",
      width: 100,
      resizable: true,
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    // {
    //   field: "tat",
    //   headerName: "Avg. TAT(in min)",
    //   width: 100,
    //   resizable: true,
    //   align: "center",
    //   headerAlign: "center",
    //   flex: 1,
    // },
    // {
    //   field: "within_frt",
    //   headerName: "FRT Within Breach",
    //   width: 100,
    //   resizable: true,
    //   align: "center",
    //   headerAlign: "center",
    //   flex: 1,
    // },
    // {
    //   field: "within_tat",
    //   headerName: "TAT Within Breach",
    //   width: 100,
    //   resizable: true,
    //   align: "center",
    //   headerAlign: "center",
    //   flex: 1,
    // },
    {
      field: "beyond_frt",
      headerName: "FRT Breach",
      width: 100,
      resizable: true,
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    // {
    //   field: "beyond_tat",
    //   headerName: "TAT Beyond Breach",
    //   width: 100,
    //   resizable: true,
    //   align: "center",
    //   headerAlign: "center",
    //   flex: 1,
    // },
    {
      field: "working_hours",
      headerName: "Active Hrs",
      width: 150,
      resizable: true,
      align: "center",
      headerAlign: "center",
      flex: 1,
    }
  );

  const HoursAndMinutes = (decimal) => {
    let number = decimal ? decimal : 0;
    const hours = Math.floor(number); // Extract the whole number part (hours)
    const minutes = Math.floor((number - hours) * 60); // Convert the decimal part to minutes
    const seconds = Math.floor(((number - hours) * 60 - minutes) * 60); // Convert the decimal part to seconds

    return decimal
      ? `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      : "00:00:00";
  };

  let updatedRows = [];

  roleSummaryData &&
    roleSummaryData.ticket_report &&
    roleSummaryData.ticket_report.agent_role_summary &&
    roleSummaryData.ticket_report.agent_role_summary.map((item, index) => {
      if (item.user_id !== "Unassigned") {
        const rows = {
          id: index + 1,
          user_name: ReturnUserName(item.user_id),
          role_name: item.role,
          assigned_tickets: prefixZero_for_singleDigit(item.assigend_ticket),
          resolved_tickets: prefixZero_for_singleDigit(item.resolved_tickets),
          unresolved_tickets: prefixZero_for_singleDigit(
            item.unresolved_tickets
          ),
          frt: HoursAndMinutes(item.frt),
          tat: HoursAndMinutes(item.tat),
          within_frt: item.within_frt,
          within_tat: item.within_tat,
          beyond_frt: prefixZero_for_singleDigit(item.beyond_frt),
          beyond_tat: item.beyond_tat,
          working_hours: HoursAndMinutes(
            item.workinh_hours ? item.workinh_hours : item.working_hours
          ),
        };

        props.statusList &&
          props.statusList.forEach((key) => {
            if (key) {
              const filteredStatus = item.status.find(
                (val) => val.key === key.status_id
              );

              if (filteredStatus) {
                rows[key.status_name] = filteredStatus && filteredStatus.count;
              } else {
                rows[key.status_name] = 0;
              }
            }
          });

        updatedRows.push(rows);
      }
    });

  const totalRows = updatedRows && updatedRows.length;
  const paginationSize = 10;
  const totalPages = Math.ceil(totalRows && totalRows / paginationSize);

  const [currentPage, setCurrentPage] = useState(0);

  const handleChange = (event, value) => {
    setCurrentPage(value - 1);
  };
  const startIndex = currentPage * paginationSize;
  const endIndex = startIndex + paginationSize;

  const finalRows = updatedRows && updatedRows.slice(startIndex, endIndex);

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      updatedRows?.map((item) => {
        exportData?.push({
          ["Assigned To"]: item?.user_name,
          ["Role"]: item?.role_name,
          ["Assigned Tckets"]: item?.assigned_tickets,
          ["Resolved Tickets"]: item?.resolved_tickets,
          ["Unresolved Tickets"]: item?.unresolved_tickets,
          ["Avg FRT"]: item?.frt,
          ["FRT Breach"]: prefixZero_for_singleDigit(item.beyond_frt),
          ["Active Hrs"]: item.working_hours
            ? item.working_hours
            : item.working_hours,
        });
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, updatedRows]);

  return props.dataGridHeight ? (
    <LoadingIndicator isActive={loading}>
      <Box height={"100%"}>
        {!loading ? (
          <Box height={"100%"}>
            {apiError ? (
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  flex: "12",
                }}
              >
                {apiError}
              </Box>
            ) : !loading &&
              updatedRows.length > 0 &&
              props.dataGridHeight.current ? (
              <Box
                sx={{
                  padding: "12px",
                  height: "100%",
                }}
              >
                <Grid
                  item
                  container
                  lg={12}
                  md={12}
                  sm={12}
                  sx={{ height: 400 }}
                >
                  {props.dataGridHeight.current && (
                    <DataGridFunction
                      rows={updatedRows}
                      columns={columns}
                      height={props.dataGridHeight.current}
                      rowHeight={50}
                      headerHeight={50}
                      onCellClick={onCellClick}
                      rowBorder={false}
                      rowColor={true}
                      headerColor={true}
                    />
                  )}
                </Grid>
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  ) : null;
}

export default RoleSummary;
