import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import DatasetDeleteModel from "./modals/DatasetDeleteModel";
import DatasetGetModal from "./modals/DatasetGetModal";

export default class TelegramDatasetsEffect {
  static async requestTelegramDatasets(params, callback) {
    const endpoint = environment.api.telegram_datasets;
    let response = EffectUtility.getToModel(DatasetGetModal, endpoint, params);
    return response;
  }

  static async requestTelegramBots(params, callback) {
    const endpoint = environment.api.telegram_bots;
    let response = EffectUtility.getToModel(DatasetGetModal, endpoint, params);
    return response;
  }

  static async requestDeleteDataSets(id, callback) {
    const endpoint = environment.api.telegram_datasets + "/" + id;
    let response = EffectUtility.deleteToModel(DatasetDeleteModel, endpoint);

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }

    return response;
  }
}
