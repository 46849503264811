import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import Logo from "../logoContainer/Logo";
import axios from "axios";
import { datadogLogs } from "@datadog/browser-logs";
import { Box, Grid, Typography } from "@mui/material";
import { CategoryScale } from "chart.js";
import { Doughnut, Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import { theme } from "../../../../views/App";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../../utilities/FetchRequest";
import { NivoPieChart } from "../../../../components/nivoCharts/NivoPieChart";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../../components/ticketPartials";
import ReactEcharts from "echarts-for-react";

function TicketClouserTatBreachChart(props) {
  Chart.register(CategoryScale);

  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [tatData, setTatData] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchTatData(props.DateFilter);
      await fetchData(props.DateFilter);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchTatData(props.DateFilter);
        await fetchData(props.DateFilter);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchTatData(props.openDateFilter);
        await fetchData(props.openDateFilter);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchTatData(dateRange) {
    const cancelToken = axios.CancelToken.source();

    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["reportOn_tat_breach"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        setLoading(false);

        if (response.hasOwnProperty("message")) {
          setTatData(null);
          setApiError(response.message);
        } else {
          setTatData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setTatData(null);
        setApiError(err.message);
        setLoading(false);
      });
  }

  async function fetchData(dateRange) {
    const cancelToken = axios.CancelToken.source();

    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["reportOn_frt_breach"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (response.hasOwnProperty("message")) {
          setData(null);
          setApiError(response.message);
        } else {
          setData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setData(null);
        setApiError(err.message);
        setLoading(false);
      });
  }

  let tattableList = [];

  tatData &&
    tatData.ticket_report &&
    tatData.ticket_report.reportOn_tat_breach
      ?.filter((tat) => {
        return tat.key === "beyond_breach";
      })
      .map((item) => {
        tattableList.push({
          name: item.key.replace(/_/g, " "),
          count: item.count,
          percentage: item.percentage,
          sentiment_break_up: item.sentiment,
        });
      });

  let tatcountData = [];
  let tatnameLabels = [];

  tatnameLabels = tattableList[0]?.sentiment_break_up
    ? Object.keys(tattableList[0]?.sentiment_break_up)
    : [];

  tatcountData = tattableList[0]?.sentiment_break_up
    ? Object.values(tattableList[0]?.sentiment_break_up)
    : [];

  // Transforming the data
  const TatchartData = tatnameLabels?.map((nameLabel, index) => {
    return {
      id: nameLabel.charAt(0).toUpperCase() + nameLabel.slice(1),
      label: nameLabel.charAt(0).toUpperCase() + nameLabel.slice(1),
      value: prefixZero_for_singleDigit(tatcountData[index]),
    };
  });

  let tableList = [];

  data &&
    data.ticket_report &&
    data.ticket_report.reportOn_frt_breach
      ?.filter((frt) => {
        return frt.key === "beyond_breach";
      })
      .map((item) => {
        tableList.push({
          name: item.key.replace(/_/g, " "),
          count: item.count,
          percentage: item.percentage,
          sentiment_break_up: item.sentiment,
        });
      });

  let countData = [];
  let nameLabels = [];

  let percentlables = [];
  let truepercent = [];

  nameLabels = tableList[0]?.sentiment_break_up
    ? Object.keys(tableList[0]?.sentiment_break_up)
    : [];

  countData = tableList[0]?.sentiment_break_up
    ? Object.values(tableList[0]?.sentiment_break_up)
    : [];

  percentlables = tableList.map((item) => {
    return item.count;
  });

  truepercent = tableList.map((item) => {
    return item.percentage;
  });

  // Transforming the data
  const chartData = nameLabels?.map((nameLabel, index) => {
    return {
      id: nameLabel.charAt(0).toUpperCase() + nameLabel.slice(1),
      label: nameLabel.charAt(0).toUpperCase() + nameLabel.slice(1),
      value: prefixZero_for_singleDigit(countData[index]),
    };
  });

  const noDataFoundBoll =
    countData.every((value) => value === 0) &&
    tatcountData.every((value) => value === 0);

  chartData.sort((a, b) => {
    const sortOrder = { Positive: 0, Negative: 1, Neutral: 2 };
    return sortOrder[a.id] - sortOrder[b.id];
  });

  TatchartData.sort((a, b) => {
    const sortOrder = { Positive: 0, Negative: 1, Neutral: 2 };
    return sortOrder[a.id] - sortOrder[b.id];
  });

  const formatValue = (value) => {
    if (value >= 1e6) {
      return (value / 1e6).toFixed(2) + "M";
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(2) + "K";
    }
    return value;
  };

  const getOption = () => {
    let shouldShowLegend = false;

    if (
      (props.ChartContainerHeight?.current &&
        props.ChartContainerWidth?.current &&
        props.ChartContainerHeight.current >= 350 &&
        props.ChartContainerWidth.current > 350) ||
      (props.ChartContainerHeight?.current === undefined &&
        props.ChartContainerWidth?.current === undefined)
    ) {
      shouldShowLegend = true;
    }

    const series = [];

    const seriesData = chartData.map((item) => ({
      name: item.label,
      value: item.value,
    }));
    return {
      tooltip: {
        trigger: "item",
        confine: true,
        formatter: (params) => {
          let val = params.value;
          let formattedvalue = formatValue(val);
          const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${params.color};border-radius:50%;"></span>`;
          return `${colorSpan}${params.name}: ${formattedvalue}`;
        },
      },
      legend: {
        icon: "circle",
        show: shouldShowLegend,
        left: 0,
        orient: "vertical",
      },
      series: [
        {
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          padAngle: 5,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            focus: "series",
          },
          labelLine: {
            show: false,
          },
          data: seriesData,
          color: [
            `${theme.reports.positive}`,
            `${theme.reports.negative}`,
            `${theme.reports.neutral}`,
          ],
          itemStyle: {
            borderRadius: 10,
          },
        },
      ],
    };
  };

  const getOption1 = () => {
    const series = [];

    const seriesData = TatchartData.map((item) => ({
      name: item.label,
      value: item.value,
    }));
    return {
      tooltip: {
        trigger: "item",
        formatter: (params) => {
          let val = params.value;
          let formattedvalue = formatValue(val);
          const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${params.color};border-radius:50%;"></span>`;
          return `${colorSpan}${params.name}: ${formattedvalue}`;
        },
      },
      series: [
        {
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: true,
          padAngle: 5,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            focus: "series",
          },
          labelLine: {
            show: false,
          },
          data: seriesData,
          color: [
            `${theme.reports.positive}`,
            `${theme.reports.negative}`,
            `${theme.reports.neutral}`,
          ],
          itemStyle: {
            borderRadius: 10,
          },
        },
      ],
    };
  };

  useEffect(() => {
    if (props.DownloadClicked) {
      let exportData = [];

      const frtBreach = {
        Category: "FRT Breach",
      };

      // Populate the tat_breach object with label-value pairs
      chartData.forEach((item) => {
        frtBreach[item.label] = item.value;
      });
      frtBreach["Total"] = tableList && tableList[0]?.count;

      const tatBreach = {
        Category: "TAT Breach",
      };

      // Populate the tat_breach object with label-value pairs
      TatchartData.forEach((item) => {
        tatBreach[item.label] = item.value;
      });

      tatBreach["Total"] = tattableList && tattableList[0]?.count;

      exportData = [frtBreach, tatBreach];

      props.setexcelData(exportData);
    }
  }, [props.DownloadClicked, chartData, TatchartData]);
  // return (
  //   <LoadingIndicator isActive={loading}>
  //     {!loading ? (
  //       <Box
  //         style={{
  //           width: "100%",
  //           height: "100%",
  //         }}
  //       >
  //         <MuiSnackbarAlert
  //           open={alertPopUp}
  //           onClose={() => setAlertPopUp(false)}
  //           message={errorMessage}
  //         />

  //         {!noDataFoundBoll &&
  //         !loading &&
  //         tatcountData &&
  //         tatcountData.length !== 0 ? (
  //           <Box textAlign={"center"} height="100%" width={"100%"}>
  //             <Grid
  //               height={"100%"}
  //               width="100%"
  //               item
  //               container
  //               justifyContent="space-between"
  //               alignItems={"center"}
  //               xl={12}
  //               lg={12}
  //               md={12}
  //               sm={12}
  //               xs={12}
  //               pb={"5vh"}
  //             >
  //               {!countData.every((value) => value === 0) && (
  //                 <Grid item height={"100%"} xl={6} lg={6} md={6} sm={6} xs={6}>
  //                   <ReactEcharts
  //                     option={getOption()}
  //                     style={{
  //                       height: "100%",
  //                       width: "100%",
  //                     }}
  //                     notMerge={true}
  //                     lazyUpdate={true}
  //                   />
  //                   <p className="font-weight-bold">
  //                     FRT Breach ({`${tableList && tableList[0]?.count}`})
  //                   </p>
  //                 </Grid>
  //               )}
  //               {!tatcountData.every((value) => value === 0) && (
  //                 <Grid item height={"100%"} xl={6} lg={6} md={6} sm={6} xs={6}>
  //                   <ReactEcharts
  //                     option={getOption1()}
  //                     style={{
  //                       height: "100%",
  //                       width: "100%",
  //                     }}
  //                     notMerge={true}
  //                     lazyUpdate={true}
  //                   />
  //                   <p className="font-weight-bold">
  //                     TAT Breach ({`${tattableList && tattableList[0]?.count}`})
  //                   </p>
  //                 </Grid>
  //               )}
  //             </Grid>
  //           </Box>
  //         ) : (
  //           !loading && <NoDataFound />
  //         )}
  //       </Box>
  //     ) : (
  //       <Box
  //         sx={{
  //           width: "100%",
  //           height: "30vh",
  //           display: "flex",
  //           alignItems: "center",
  //           justifyContent: "center",
  //         }}
  //       >
  //         <LoadingBgImage />
  //       </Box>
  //     )}
  //   </LoadingIndicator>
  // );
  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <Box
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {apiError ? (
            <Box
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flex: "12",
              }}
            >
              {apiError}
            </Box>
          ) : (
            <Box
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              {!noDataFoundBoll &&
              !loading &&
              tatcountData &&
              tatcountData.length !== 0 ? (
                <Box textAlign={"center"} height="100%" width={"100%"}>
                  <Grid
                    height={"100%"}
                    width="100%"
                    container
                    justifyContent="space-between"
                    alignItems={"center"}
                    spacing={2}
                  >
                    {!countData.every((value) => value === 0) && (
                      <Grid item height={"100%"} width="100%" md={6}>
                        <Box height="100%" width={"100%"}>
                          <ReactEcharts
                            option={getOption()}
                            notMerge={true}
                            lazyUpdate={true}
                            style={{
                              width: "100%",
                              height: "85%",
                            }}
                          />
                          <p className="font-weight-bold">
                            FRT Breach ({`${tableList && tableList[0]?.count}`})
                          </p>
                        </Box>
                      </Grid>
                    )}
                    {!tatcountData.every((value) => value === 0) && (
                      <Grid item height={"100%"} width="100%" md={6}>
                        <Box height="100%" width={"100%"}>
                          <ReactEcharts
                            option={getOption1()}
                            notMerge={true}
                            lazyUpdate={true}
                            style={{
                              width: "100%",
                              height: "85%",
                            }}
                          />
                          <p className="font-weight-bold">
                            TAT Breach (
                            {`${tattableList && tattableList[0]?.count}`})
                          </p>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              ) : (
                !loading && <NoDataFound />
              )}
            </Box>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage />
        </Box>
      )}
    </LoadingIndicator>
  );
}

export default TicketClouserTatBreachChart;
