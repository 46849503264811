import React, { useEffect, useRef, useState } from "react";
import "./cssFiles/ResponseManagementPlatformCounts.css";
import environment from "environment";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import Logo from "./logoContainer/Logo";
import Description from "./descriptionContainer/Description";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { theme } from "../../../views/App";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import ReactEcharts from "echarts-for-react";
function FollowersDemographicAge(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  useEffect(() => {
    //   setData(null);
    (async function CallApi() {
      setLoading(true);
      props.account_selected && (await fetchData(props.account_selected));
      setLoading(false);
    })();
  }, [props.account_selected]);
  useEffect(() => {
    if (props.clearClicked) {
      setData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.account_selected);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);
  useEffect(() => {
    if (props.filterClicked) {
      setData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.account_selected);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.account_selected, props.filterClicked]);
  async function fetchData(account_id) {
    let API = `${environment.api.instagramReports}?reports=["get_follower_demographics_age"]&account_id=${account_id?.account_id}`;
    await axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (
          response.hasOwnProperty("error") ||
          response.hasOwnProperty("error_id")
        ) {
          response.hasOwnProperty("error_id")
            ? setApiError(`${response?.message} - ${response?.error_id}`)
            : setApiError(response?.error);
          setData(null);
        } else {
          setLoading(false);
          setData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setData(null);
        setApiError(err.message);
        setLoading(false);
      });
  }
  const getOption = () => {
    const formatValue = (value) => {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + "K";
      }
      return value;
    };
    const colorValues = Object.values(theme.platform);
    let shouldShowLegend = false;
    if (
      (props.ChartContainerHeight?.current &&
        props.ChartContainerWidth?.current &&
        props.ChartContainerHeight.current >= 350 &&
        props.ChartContainerWidth.current > 350) ||
      (props.ChartContainerHeight?.current === undefined &&
        props.ChartContainerWidth?.current === undefined)
    ) {
      shouldShowLegend = true;
    }

    // Extract demographic age group keys
    const ageGroups = Object.keys(
      data?.[0]?.get_follower_demographics_age || {}
    );
    // Prepare the series and legend keys based on the types within each age group
    const series = [];
    const keys = ["female", "male", "unknow"]; // These are the categories we will use for the legend (since these are the common keys across all age groups)

    keys.forEach((key, index) => {
      const seriesData = ageGroups.map((ageGroup) => {
        const value =
          data?.[0].get_follower_demographics_age[ageGroup][key] || 0;
        return {
          name: ageGroup,
          value: value,
        };
      });
      console.log("series", seriesData);
      series.push({
        name: key,
        type: "bar",
        showBackground: true,
        backgroundStyle: {
          color: "#f8f9f91A", // 1A represents the transparency (10% opacity)
        },
        stack: "total",
        label: {
          show: true,
          position: "insideRight",
          formatter: (params) => {
            let val = params.value;
            let formattedvalue = formatValue(val);
            return val === 0 ? "" : formattedvalue;
          },
          fontFamily: "BookAntiqua",
          color: theme.palette.primary.dark,
        },
        emphasis: {
          focus: "series",
        },

        data: seriesData,
        itemStyle: {
          color: colorValues[index % colorValues.length],
        },
      });
    });

    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        confine: true,
        formatter: (params) => {
          let tooltipContent = `${params[0].axisValue}<br/>`;
          params.forEach((item) => {
            if (item.value !== 0) {
              let val = item.value;
              let formattedvalue = formatValue(val);
              const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${item.color};border-radius:50%;"></span>`;
              tooltipContent += `${colorSpan}${item.seriesName}: ${formattedvalue}<br/>`;
            }
          });
          return tooltipContent;
        },
      },
      legend: {
        icon: "circle",
        type: "scroll",

        data: shouldShowLegend ? keys : [],
      },
      grid: {
        top: props.ChartContainerHeight?.current >= 500 ? "5%" : "10%",

        left: "5%",
        right: "5%",
        bottom: "5%",
        containLabel: true,
      },
      labelLayout: {
        hideOverlap: true,
      },
      xAxis: {
        type: "category",
        data: ageGroups,
        axisLabel: {
          show: props.detailedReport ? true : false,
          padding: [0, 30, 0, 0],
          hideOverlap: true,
          fontFamily: "BookAntiqua",
        },
        axisTick: {
          show: true,
        },
        name:
          props.detailedReport || props.ChartContainerWidth?.current > 600
            ? "Age Group"
            : "",
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: 0,
        },
      },

      dataZoom: [
        {
          type: "inside",
          filterMode: "weakFilter",
        },
      ],

      yAxis: {
        type: "value",
        name:
          props.detailedReport || props.ChartContainerHeight?.current > 300
            ? "Count"
            : "",
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: 25,
        },
        axisLabel: {
          formatter: (value) => formatValue(value),
          hideOverlap: true,
          fontFamily: "BookAntiqua",
        },
      },
      series: series,
    };
  };
  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <>
          {(!loading && props.account_selected) ||
          data?.[0]?.get_follower_demographics_age ? (
            <Box
              style={{
                width: "100%",
                overflowX: "auto",
                padding: "12px",
              }}
            >
              {apiError ? (
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    flex: "12",
                  }}
                >
                  {apiError}
                </Box>
              ) : (
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <ReactEcharts
                    option={getOption()}
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    notMerge={true}
                    lazyUpdate={true}
                    // onEvents={{
                    //   click: handleClick,
                    // }}
                  />
                </Box>
              )}
            </Box>
          ) : (
            !loading && <NoDataFound />
          )}
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage />
        </Box>
      )}
    </LoadingIndicator>
  );
}
export default FollowersDemographicAge;
