import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { Box, Grid, ListItem, Tooltip, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { prefixZero_for_singleDigit } from "../../../components/ticketPartials";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import NoDataFound from "../../../components/nodatafound/NoDataFound";

function TicketsByCustomFields(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [platformwiseTickets, setPlatformwiseTickets] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchPlatformwiseTicketsByCategory(props.DateFilter);
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.DateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchPlatformwiseTicketsByCategory(dateRange) {
    const type_value_filters = props.type_value?.length
      ? props.type_value.map((item) => `${item.type_id}`)
      : [];

    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["platform_ticket_by_custom_value"]&advanced_filters={"custom_value_type":${
      props.type_value?.length
        ? JSON.stringify(type_value_filters)
        : `["text","select","radio","toggle","date"]`
    }}${
      props.DateFilter || (props.filterClicked && props.openDateFilter)
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    try {
      const res = axiosRequest(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      const response = await res;
      if (response.hasOwnProperty("message")) {
        setPlatformwiseTickets(null);
        setApiError(response.message);
      } else {
        setPlatformwiseTickets(response);
        setApiError(null);
      }
    } catch (err) {
      setPlatformwiseTickets(null);
      setApiError(err.message);
      setLoading(false);
    }
  }

  let columns = [];

  columns.push(
    {
      field: "id",
      headerName: "Sl No",
      resizable: false,
      width: 100,
      hide: true,
    },
    {
      field: "field_name",
      headerName: "Custom Field Name",
      resizable: true,
      hide: false,
      flex: 10,
      renderCell: (params) => {
        return (
          <Typography
            className={`${
              params?.row?.field_name.startsWith("Total")
                ? "font-weight-bold p-0 m-0"
                : "font-weight-normal p-0 m-0"
            }`}
          >
            {params?.row?.field_name}
          </Typography>
        );
      },
    },

    {
      field: "count",
      headerName: "Count",
      resizable: true,
      hide: false,
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Typography
            className={`${
              params?.row?.field_name.startsWith("Total")
                ? "font-weight-bold p-0 m-0"
                : "font-weight-normal p-0 m-0"
            }`}
          >
            {params?.row?.count}
          </Typography>
        );
      },
    }
  );

  let updatedRows = [];
  if (platformwiseTickets) {
    platformwiseTickets?.ticket_report?.platform_ticket_by_custom_value?.forEach(
      (entyItem, index) => {
        // Loop through each value in the `values` array
        entyItem?.values?.forEach((valueItem) => {
          updatedRows?.push({
            id: updatedRows.length + 1, // Ensure unique IDs
            field_name: `${entyItem.key} - ${valueItem.key}`,
            count: valueItem.count,
          });
        });
      }
    );
  }

  const totalRow = {
    id: updatedRows.length + 1,
    field_name: "Total",
    count: platformwiseTickets?.ticket_report?.platform_ticket_by_custom_value[
      platformwiseTickets?.ticket_report?.platform_ticket_by_custom_value
        ?.length - 1
    ]?.total_tickets
      ? platformwiseTickets?.ticket_report?.platform_ticket_by_custom_value[
          platformwiseTickets?.ticket_report?.platform_ticket_by_custom_value
            ?.length - 1
        ]?.total_tickets
      : "00",
  };

  platformwiseTickets?.ticket_report?.platform_ticket_by_custom_value?.length >
    1 && updatedRows.push(totalRow);
  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      updatedRows?.map((item) => {
        exportData.push({
          ["Custom Field Name"]: item?.field_name,
          ["Count"]: item?.count,
        });
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, updatedRows]);

  return props.dataGridHeight ? (
    <LoadingIndicator isActive={loading}>
      <Box height={"100%"}>
        {!loading ? (
          <Box height={"100%"}>
            {apiError ? (
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  flex: "12",
                }}
              >
                {apiError}
              </Box>
            ) : !loading &&
              updatedRows.length > 0 &&
              props.dataGridHeight.current ? (
              <Box
                sx={{
                  padding: "12px",
                  height: "100%",
                }}
              >
                <Grid
                  item
                  container
                  lg={12}
                  md={12}
                  sm={12}
                  sx={{ height: 400 }}
                >
                  {props.dataGridHeight.current && (
                    <DataGridFunction
                      rows={updatedRows}
                      columns={columns}
                      height={props.dataGridHeight.current}
                      rowHeight={50}
                      headerHeight={50}
                      onCellClick={onCellClick}
                      rowBorder={false}
                      rowColor={true}
                      headerColor={true}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            id: false,
                          },
                        },
                      }}
                    />
                  )}
                </Grid>
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  ) : null;
}

export default TicketsByCustomFields;
