import BaseReducer from "../../../../utilities/BaseReducer";
import ReleaseHistoryAction from "./ReleaseHistoryAction";

export default class ReleaseHistoryReducer extends BaseReducer {
  initialState = {
    musiclabelsReleaseShadowStatusWise: [],
  };

  [ReleaseHistoryAction.REQUEST_MUSICLABELS_RELEASE_SHADOW_STATUS_WISE_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      musiclabelsReleaseShadowStatusWise: action.payload,
    };
  }
}
