import AutomationFlowReduser from "./automationFlow/AutomationFlowReduser";
import DiscoveredDataFilterReducer from "./discoveredData/DiscoveredDataFilterReducer";
import DiscoveredDataReducer from "./discoveredData/DiscoveredDataReducer";
import GoogleConfigGetReduser from "./googleSearch/GoogleConfigGetReduser";
import GoogleDiscoveredDataGetReduser from "./googleSearch/GoogleDiscoveredDataGetReduser";
import GoogleSearchExportReduser from "./googleSearch/GoogleSearchExportReduser";
import GoogleSearchGetReduser from "./googleSearch/GoogleSearchGetReduser";
import HssCategoryReduser from "./hssCategory/HssCategoryReducer";
import HssDetailsByIDReducer from "./hssDetails/HssDetailsByIDReducer";
import HssDetailsReducer from "./hssDetails/HssDetailsReducer";
import HssDetailsShadowReduser from "./hssDetails/HssDetailsShadowReduser";
import KeywordDiscoverPatterFIlterReduser from "./keywordDiscoverPattern/KeywordDiscoverPatterFIlterReduser";
import KeywordDiscoverPatterReduser from "./keywordDiscoverPattern/KeywordDiscoverPatterReduser";
import NvProjectFilterReduser from "./nvProject/NvProjectFilterReduser";
import NvProjectReduser from "./nvProject/NvProjectReduser";
import PromptsReduser from "./promptConfig/PromptsReduser";
import PropertywiseUrlsReducer from "./propertywiseUrls/PropertywiseUrlsReducer";
import SitesExecutionLogReduser from "./sitesExecutionLogs/SitesExecutionLogReduser";
import TelegramChannelGetReduser from "./telegramChannelMonitoring/TelegramChannelGetReduser";
import TelegramBotsReduser from "./telegramDatasets/TelegramBotsReduser";
import TelegramDatasetsReduser from "./telegramDatasets/TelegramDatasetsReduser";
import TelegramISearchHistoryGetReduser from "./telegramIntermediate/TelegramISearchHistoryGetReduser";
import TelegramIntermediateExportGetReduser from "./telegramIntermediate/TelegramIntermediateExportGetReduser";
import TelegramIntermediateGetReduser from "./telegramIntermediate/TelegramIntermediateGetReduser";
import TelegramReportsReduser from "./websweepxReports/TelegramReportsReduser";
import TelegramWsxReportsReduser from "./websweepxReports/TelegramWsxReportsReduser";
import TelegramchannelWiseLiksReduser from "./websweepxReports/TelegramchannelWiseLiksReduser";
import WebsweepxCMSWSXHSSWiseReduser from "./websweepxReports/WebsweepxCMSWSXHSSWiseReduser";
import WebsweepxCMSWSXTelegramReduser from "./websweepxReports/WebsweepxCMSWSXTelegramReduser";
import WebsweepxCrawledPagesReduser from "./websweepxReports/WebsweepxCrawledPagesReduser";
import WebsweepxReportsCMSWSXHostWiseReduser from "./websweepxReports/WebsweepxReportsCMSWSXHostWiseReduser";
import WebsweepxReportsCMSWSXPropertiesReduser from "./websweepxReports/WebsweepxReportsCMSWSXPropertiesReduser";
import WebsweepxReportsCMSWSXReduser from "./websweepxReports/WebsweepxReportsCMSWSXReduser";
import WebsweepxReportsReduser from "./websweepxReports/WebsweepxReportsReduser";
import WebsweepxTopSitesReduser from "./websweepxReports/WebsweepxTopSitesReduser";
import WsxStatsReportReduser from "./websweepxReports/WsxStatsReportReduser";
import WhitelistedSitesReduser from "./whitelistedSites/WhitelistedSitesReduser";

export const websweepXRootReducer = {
  websweepxHssDetails: new HssDetailsReducer().reducer,
  websweepxDiscoveredData: new DiscoveredDataReducer().reducer,
  websweepxDiscoveredDataFilter: new DiscoveredDataFilterReducer().reducer,
  websweepNvProject: new NvProjectReduser().reducer,
  websweepNvProjectFilter: new NvProjectFilterReduser().reducer,
  websweepXReports: new WebsweepxReportsReduser().reducer,
  reportsOnTelegram: new TelegramReportsReduser().reducer,
  reportsOnTelegramWsx: new TelegramWsxReportsReduser().reducer,
  websweepXReportCMSvsWSX: new WebsweepxReportsCMSWSXReduser().reducer,
  websweepXReportCMSvsWSXProperties:
    new WebsweepxReportsCMSWSXPropertiesReduser().reducer,
  websweepXReportCMSvsWSXHostWise: new WebsweepxReportsCMSWSXHostWiseReduser()
    .reducer,
  websweepXReportCMSvsWSXHSSWise: new WebsweepxCMSWSXHSSWiseReduser().reducer,
  websweepxCrawledPagesReport: new WebsweepxCrawledPagesReduser().reducer,
  websweepxCMSvsWSXTelegramReport: new WebsweepxCMSWSXTelegramReduser().reducer,
  websweepXHssCategoryReducer: new HssCategoryReduser().reducer,
  websweepXhssCategory: new HssCategoryReduser().reducer,
  aotomationFlow: new AutomationFlowReduser().reducer,
  topSitestobeAutomated: new WebsweepxTopSitesReduser().reducer,
  websweepXWhitelistedSites: new WhitelistedSitesReduser().reducer,
  websweepXPrompts: new PromptsReduser().reducer,
  telegram_datasets: new TelegramDatasetsReduser().reducer,
  telegram_bots: new TelegramBotsReduser().reducer,

  websweepXHssDetailsShadow: new HssDetailsShadowReduser().reducer,
  wsxStatsReportData: new WsxStatsReportReduser().reducer,
  websweepXSitesExecutionLogs: new SitesExecutionLogReduser().reducer,
  HSSPropertyByID: new HssDetailsByIDReducer().reducer,
  websweepxTelegramChannelMonitoring: new TelegramChannelGetReduser().reducer,
  websweepxTelegramIntermediate: new TelegramIntermediateGetReduser().reducer,
  telegram_search_history: new TelegramISearchHistoryGetReduser().reducer,

  websweepxTelegramIntermediateExport:
    new TelegramIntermediateExportGetReduser().reducer,
  telegramChannelWiseLinks: new TelegramchannelWiseLiksReduser().reducer,
  websweepxGoogleSearchConfig: new GoogleConfigGetReduser().reducer,
  websweepxGoogleSearchMain: new GoogleSearchGetReduser().reducer,
  websweepxGoogleSearchDiscovered: new GoogleDiscoveredDataGetReduser().reducer,
  websweepxGoogleSearchDiscoveredExport: new GoogleSearchExportReduser()
    .reducer,
  propertywise_content_urls_history: new PropertywiseUrlsReducer().reducer,
  keyword_discover_pattern: new KeywordDiscoverPatterReduser().reducer,
  keyword_discover_pattern_filter: new KeywordDiscoverPatterFIlterReduser()
    .reducer,
};
