import React, { useEffect, useRef, useState } from "react";
import "./cssFiles/ResponseManagementPlatformCounts.css";
import environment from "environment";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { theme } from "../../../views/App";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";

function FacebookProfileSummary(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [pageImpressionData, setPageImpressionData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  useEffect(() => {
    setData(null);
    (async function CallApi() {
      if (props.account_selected && !props?.detailReportOpen) {
        setLoading(true);

        await fetchData(props.account_selected, props.DateFilter);
        if (props.account_selected) {
          const isFirstCallSuccess = await fetchData(
            props.account_selected,
            props.DateFilter
          );
          if (!isFirstCallSuccess) {
            setLoading(false);
            return;
          }
          await fetchPageImpressions(props.account_selected, props.DateFilter);
          setLoading(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    setData(null);
    (async function CallApi() {
      if (props.account_selected && props?.detailReportOpen) {
        setLoading(true);

        await fetchData(props.account_selected, props.DateFilter);
        await fetchPageImpressions(props.account_selected, props.DateFilter);
        setLoading(false);
      }
    })();
  }, [props?.detailReportOpen, props.account_selected]);

  useEffect(() => {
    if (props.clearClicked) {
      setData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.account_selected, props.DateFilter);
        await fetchPageImpressions(props.account_selected, props.DateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      setData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.account_selected, props.openDateFilter);
        await fetchPageImpressions(props.account_selected, props.DateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.account_selected, props.filterClicked, props.openDateFilter]);

  async function fetchData(account_id, dateRange) {
    let API = `${environment.api.facebookReportsummary}?account_id=${account_id?.account_id}&reports=["facebook_profile_summary"]`;
    await axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (
          response.hasOwnProperty("error") ||
          response.hasOwnProperty("error_id")
        ) {
          response.hasOwnProperty("error_id")
            ? setApiError(`${response?.message} - ${response?.error_id}`)
            : setApiError(response?.error);
          setData(null);
          setLoading(false);
          return false;
        } else {
          setData(response);
          setApiError(null);
          return true;
        }
      })
      .catch((err) => {
        setData(null);
        setPageImpressionData(null);
        setApiError(err.message);
        setLoading(false);
        return true;
      });
  }
  async function fetchPageImpressions(account_id) {
    let API = `${environment.api.facebookReportsummary}?account_id=${account_id?.account_id}&reports=["page_impressions"]`;
    await axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (
          response.hasOwnProperty("error") ||
          response.hasOwnProperty("error_id")
        ) {
          response.hasOwnProperty("error_id")
            ? setApiError(`${response?.meassage}-${response?.error_id}`)
            : setApiError(response?.error);
          setPageImpressionData(null);
        } else {
          setPageImpressionData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setApiError(err.message);
        setLoading(false);
        setPageImpressionData(null);
        setData(null);
      });
  }

  let NoDataFoundBool = !data && !props.account_selected;

  // Calculate the height of each box based on the maximum length

  const isXl = useMediaQuery(() => theme.breakpoints.up("xl"));
  const isLg = useMediaQuery(() => theme.breakpoints.up("lg"));
  const isLaptop = useMediaQuery(() => theme.breakpoints.up("laptop"));
  const isMd = useMediaQuery(() => theme.breakpoints.up("md"));

  useEffect(() => {
    if (props.DownloadClicked) {
      props.setexcelData([data && data[0].facebook_profile_summary]);
    }
  }, [props.DownloadClicked, data]);

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: "12",
      }}
    >
      <Box
        className="scrollable"
        sx={{
          overflowY: "auto",
          "& .MuiDataGrid-virtualScroller": {
            "&::-webkit-scrollbar": {
              width: "3px",
              height: "3px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#b0d2da",
              borderRadius: "3px",
              "&:hover": {
                backgroundColor: "#85bac6",
              },
            },
          },
        }}
      >
        {!loading ? (
          <>
            {!NoDataFoundBool ? (
              <Box
                style={{
                  width: "100%",
                  overflowX: "auto",
                  padding: "12px",
                }}
              >
                {apiError ? (
                  <Box
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      flex: "12",
                    }}
                  >
                    {apiError}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      padding: 2,
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    <Grid container xs={12}>
                      <Grid item xs={12} md={12}>
                        <Card
                          sx={{ borderRadius: 2, boxShadow: 2, padding: 1 }}
                        >
                          {/* <CardMedia
                          component="img"
                          image={
                            data &&
                            data[0] &&
                            data[0]?.facebook_profile_summary
                              ?.profile_picture_url
                          }
                          alt="Most Engaging Post"
                          sx={{
                            height: isXl
                              ? 150 // Adjust as needed for xl
                              : isLg
                              ? 100 // Adjust as needed for lg
                              : isLaptop
                              ? 100 // Adjust as needed for md
                              : isMd
                              ? 75 // Adjust as needed for md
                              : 75, // Default for smaller size
                            objectFit: "contain",
                          }}
                        /> */}
                          <Typography
                            variant="body2"
                            textAlign={"center"}
                            sx={{ color: "#777" }}
                          >
                            Bio :{" "}
                            {data &&
                              data[0] &&
                              data[0]?.facebook_profile_summary?.about}
                          </Typography>
                        </Card>
                      </Grid>
                    </Grid>

                    <Grid item container xs={12} gap={1} mt={2}>
                      {/* Top Overview Section */}
                      <Grid
                        container
                        xs={12}
                        gap={1}
                        justifyContent={"space-between"}
                      >
                        {[
                          {
                            label: "Rating count",
                            value:
                              data &&
                              data[0] &&
                              data[0]?.facebook_profile_summary?.rating_count,
                          },
                          {
                            label: "Followers",
                            value:
                              data &&
                              data[0] &&
                              data[0]?.facebook_profile_summary
                                ?.followers_count,
                          },
                          {
                            label: "Fan Count",
                            value:
                              data &&
                              data[0] &&
                              data[0]?.facebook_profile_summary?.fan_count,
                          },
                          {
                            label: "Name",
                            value:
                              data &&
                              data[0] &&
                              data[0]?.facebook_profile_summary?.name,
                          },
                          // {
                          //   label: "Username",
                          //   value:
                          //     data &&
                          //     data[0] &&
                          //     data[0]?.facebook_profile_summary?.username,
                          // },
                          {
                            label: "Impressions",
                            value:
                              pageImpressionData &&
                              pageImpressionData[0] &&
                              pageImpressionData[0]?.page_impressions &&
                              pageImpressionData[0]?.page_impressions[2]?.count,
                          },
                        ].map((item, index) => (
                          <Grid item xs={12} md={2.2} key={index}>
                            <Card
                              sx={{
                                backgroundColor: "#fff",
                                borderRadius: 2,
                                boxShadow: 2,
                                textAlign: "center",
                                padding: 2,
                              }}
                            >
                              <CardContent>
                                <Typography
                                  variant="h6"
                                  sx={{ fontWeight: "bold", color: "#333" }}
                                >
                                  {item.value}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{ color: "#777" }}
                                >
                                  {item.label}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            ) : (
              <NoDataFound />
            )}
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default FacebookProfileSummary;
