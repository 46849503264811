import BaseReducer from "../../../../utilities/BaseReducer";
import TelegramDatasetsACtion from "./TelegramDatasetsACtion";

export default class TelegramBotsReduser extends BaseReducer {
  initialState = {
    telegram_bots: [],
  };
  [TelegramDatasetsACtion.REQUEST_GET_TELEGRAM_BOTS_FINISHED](state, action) {
    return {
      ...state,
      telegram_bots: action.payload,
    };
  }
}
