import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  Popover,
  FormControlLabel,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import {
  returnLogo,
  returnPlatformDropdownLogos,
} from "../../../../components/ticketPartials";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import { theme } from "../../../../views/App";
import AllMentionsAction from "../../../stores/allMentions/AllMentionsAction";
import TwitterAction from "../../../stores/twitterFeeds/TwitterAction";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TimeAgo from "javascript-time-ago";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import TranslatedContent from "../ticketDetails/components/TranslateFunction";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FeedIcon from "@mui/icons-material/Feed";
import copy from "copy-to-clipboard";
import StarIcon from "@mui/icons-material/Star";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SentimentValueUpdatePopper from "./components/SentimentValueUpdatePopper";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";
import SentimentDissatisfiedRoundedIcon from "@mui/icons-material/SentimentDissatisfiedRounded";
import SentimentNeutralRoundedIcon from "@mui/icons-material/SentimentNeutralRounded";
import StyleIcon from "@mui/icons-material/Style";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import CustomerDataAction from "../../../../stores/ticketCustomerData/CustomerDataAction";
import PriorityAction from "../../../stores/priority/PriorityAction";
import LoadingBgImage from "../../../../components/loadingBgImage/LoadingBgImage";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import PaginationUI from "../../../../components/pagination/Pagination";
import MentionsFilters from "./filters/MentionsFilters";
import PlatformAction from "../../../stores/platform/PlatformAction";
import { format } from "date-fns";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import environment from "environment";
import { makeStyles } from "@material-ui/core";
import SendMentionsEmail from "./components/SendMentionsEmail";
import { axiosRequest } from "../../../../utilities/FetchRequest";
import DateSelectionPopper from "../ticketDetails/components/DateSelectionPopper";
import MentionsDateSelectionPopper from "../ticketDetails/components/MentionsDateSelectionPopper";
import MuiButton from "../../../../components/muiButton/MuiButton";
import RenderMedia from "../../../../components/renderMedia/RenderMedia";
const XLSX = require("xlsx");

// mapstateto props function
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    AllMentionsAction.REQUEST_ALLMENTIONS,
    AllMentionsAction.REQUEST_MENTIONS_CUSTOMERS_AND_ACCOUNTNAMES,
    PriorityAction.REQUEST_PRIORITY_FILTER,
    PlatformAction.REQUEST_PLATFORM,
  ]),
  exportRequesting: selectRequesting(state, [
    AllMentionsAction.REQUEST_ALLMENTIONS_EXPORT_FILTER,
  ]),

  allMentions: state.allmentions.allmentions.hits || [],
  pagination: state.allmentions.allmentions || [],
  filterpriority: state.filterpriority.filterpriority.hits || [],
  platform: state.platform.platform.hits || {},
  allmentionsExportFilter:
    state.allmentionsExportFilter.allmentionsExportFilter.hits || [],
  all_customer_and_account_names:
    state.all_customer_and_account_names.all_customer_and_account_names || {},
});

const AllmentionsMain = (props) => {
  let { state } = useLocation();
  const [exportData, setExportData] = useState([]);

  let { isRequesting } = props;
  const timeAgo = new TimeAgo("en-US");
  const icon = (
    <CheckBoxOutlineBlankIcon
      fontSize="small"
      color={`${theme.palette.ticketListView.checkbox}`}
      style={{
        width: 15,
        height: 15,
      }}
    />
  );
  const checkedIcon = (
    <CheckBoxIcon
      fontSize="small"
      style={{
        color: `${theme.palette.ticketListView.iconColor}`,
        width: 15,
        height: 15,
      }}
    />
  );

  // Ticket ID / Mentions ID copy function
  const copyToClipboard = (copyText) => {
    copy(copyText);
    setCopied(true);
  };

  const [emailClicked, setEmailClicked] = useState(false);

  // Influencer Part / Section
  const handleInfluencerPut = (item) => {
    let customer_id = item._source.customer_id;
    let params = {
      //   filters: `[${filters.current}]`,
      page_number: pageNumber,
      page_limit: `${page_limit}`,
    };

    if (item._source.customer_id) {
      if (item._source.customer_id.is_influencer) {
        let data = { is_influencer: false };

        props
          .dispatch(
            CustomerDataAction.requestPutCustomerData(
              JSON.stringify(data),
              customer_id
            )
          )
          .then(() => {
            props.dispatch(AllMentionsAction.requestAllmentions(params));
          });
      } else {
        let data = { is_influencer: true };

        props
          .dispatch(
            CustomerDataAction.requestPutCustomerData(
              JSON.stringify(data),
              customer_id
            )
          )
          .then(() => {
            props.dispatch(AllMentionsAction.requestAllmentions(params));
          });
      }
    }
  };

  // Category Update Section
  const [sendEmailFeedData, setSendEmailFeedData] = useState(null);
  const [sendEmailOpen, setSendEmailOpen] = useState(false);
  const [page_limit] = useState(10);

  const [customer_popover_anchorEl, set_customer_popover_anchorEl] =
    React.useState(null);
  const handlePopoverOpen = (event) => {
    set_customer_popover_anchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    set_customer_popover_anchorEl(null);
  };
  const customer_popover_open = Boolean(customer_popover_anchorEl);
  const [customerPopOver, setCustomerPopOver] = useState({
    customerID: null,
    customerName: null,
    userName: null,
    platform: null,
    followers: null,
    following: null,
  });

  const handleMouseHoverStateChange = (
    customer_id,
    customer_name,
    platform,
    username,
    followers_count,
    follows_count
  ) => {
    setCustomerPopOver({
      customerID: customer_id,
      customerName: customer_name,
      platform: platform,
      userName: username,
      followers: followers_count,
      following: follows_count,
    });
  };

  const useStyles = makeStyles(() => ({
    popover: {
      boxShadow: `2px 2px 4px rgba(0, 0, 0, 0.1)`,
    },
  }));

  const classes = useStyles();

  const openCloseModal = useCallback((open) => {
    setSendEmailOpen(open);
  }, []);

  const [showBox, setShowBox] = useState(false);
  const [ticketData, setTicketData] = useState(null);

  const handleButtonClick = (item) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setShowBox(true);
    setTicketData(item);
  };

  let visibleOpenLinkBtn = (check_platform) => {
    let Boolean = true;

    if (
      check_platform?._source?.platform === "Ambition Box" ||
      check_platform?._source?.platform === "Consumer Complaints" ||
      check_platform?._source?.platform === "Discord" ||
      check_platform?._index === "twitter_direct_messages" ||
      check_platform?._index === "facebook_direct_messages" ||
      check_platform?._source?.platform === "Email"
    ) {
      Boolean = false;
    }

    // if (check_platform?._source?.platform === "Instagram") {
    //   if (
    //     check_platform?._source?.activities?.activities_id === "25" ||
    //     check_platform?._source?.activities?.activities_id === "13" ||
    //     check_platform?._source?.activities?.activities_id === "14"
    //   ) {
    //     Boolean = false;
    //   }
    // }

    return Boolean;
  };

  // open feed link fn
  function OpenNewWindow(item) {
    // feed link
    if (
      item._source &&
      (item._source.platform === "Twitter" ||
        item._source.platform === "Instagram" ||
        item._source.platform === "Threads" ||
        item._source.platform === "LinkedIn")
    ) {
      window.open(item._source.feed_link);
    } else if (item._source && item._source.platform === "Youtube") {
      if (item._source.activities === "Videos") {
        window.open(item._source.video_url);
      } else if (item._source.activities === "Comments") {
        window.open(item._source.comment_url);
      }
    } else if (platform === "Threads") {
      window.open(item._source.feed_link);
    } else if (
      (item._source && item._source.platform === "Playstore") ||
      (item._source && item._source.platform === "Glassdoor")
    ) {
      window.open(item._source.feed_link);
    } else if (item._source && item._source.platform === "Indeed") {
      window.open(item._source.url);
    } else if (item._source && item._source.platform === "Appstore") {
      window.open(item._source.app_url);
    } else if (item._source && item._source.platform === "Quora") {
      window.open(item._source.question_link);
    } else if (item._index === "facebook_webhook") {
      item._source?.feed_link && window.open(item._source.feed_link);
    } else if (
      item._source &&
      item._source.platform === "GMB"
      // &&
      // item._source &&
      // item._source.activities === "Questions Answers"
    ) {
      window.open(item._source.gmb_profile_link);
    } else {
      alert("No Link Found");
    }
  }

  const handleSentiment = (event, newSentiment) => {
    if (newSentiment) {
      setSentimental_val(newSentiment);
      setPageNumber(0);

      let filters = JSON.parse(localStorage.getItem("allMentionsParameters"));
      filters.sentimentValue = newSentiment;
      localStorage.setItem("allMentionsParameters", JSON.stringify(filters));

      let object_new = {};

      Object.assign(
        object_new,
        filterContents
          ? {
              ...filterContents,
              sentimentValue: newSentiment,
            }
          : {
              sentimentValue: newSentiment,
            }
      );

      setFilterContents(
        filterContents
          ? {
              ...filterContents,
              sentimentValue: newSentiment,
            }
          : {
              sentimentValue: newSentiment,
            }
      );

      applyFilter(object_new);
    }
  };

  let TicketCountData = [];

  if (
    props.ticketCount &&
    props.ticketCount.data &&
    props.ticketCount.data.length !== 0
  ) {
    TicketCountData = [...props.ticketCount.data];
  }

  let selected_status = null;

  function returnTicketPriorityCount(priorityName) {
    return TicketCountData.length > 0 &&
      TicketCountData.filter((item) => item.key === selected_status).length > 0
      ? TicketCountData.filter(
          (item) => item.key === selected_status
        )[0].priority.filter((item) => item.key === priorityName).length > 0
        ? TicketCountData.filter((item) => item.key === selected_status)[0]
            .priority.filter((item) => item.key === priorityName)
            .map((item) => item.count)
        : 0
      : 0;
  }

  let sentiment_index = ["Positive", "Negative", "Neutral"];

  const mentioncopyToClipboard = (copyText) => {
    copy(copyText);
    setmentioncopied(true);
  };

  const returnCustomValues = (custom_fields) => {
    let category_values =
      props.customticketfields &&
      props.customticketfields.hits &&
      props.customticketfields.hits.hits[0]._source.field_values;

    let filtered_category_vals = [];
    if (custom_fields && custom_fields.length !== 0) {
      filtered_category_vals = custom_fields.filter((item) => {
        return item.field_type === "category";
      });
    }

    let sub_value_id = [];

    if (filtered_category_vals && filtered_category_vals.length !== 0) {
      filtered_category_vals.map((item) => {
        sub_value_id.push(item.field_values[0].id);
      });
    }
    function ReturnCategoryName(nodes, id, names = []) {
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];

        names.push(nodes[i].value);

        if (node.id === id) {
          return names;
        } else if (node.sub_value && node.sub_value.length > 0) {
          const foundIndexes = ReturnCategoryName(node.sub_value, id, [
            ...names,
          ]);
          if (foundIndexes) {
            return foundIndexes;
          }
        }
        names.pop();
      }
      return null;
    }
    let custom_field_vals =
      category_values &&
      category_values.length !== 0 &&
      ReturnCategoryName(category_values, sub_value_id[0]);

    return custom_field_vals;
  };

  const returntrueCategoryValues = (ticket) => {
    let bool_val = false;

    if (
      ticket &&
      ticket._source &&
      ticket._source.custom_value &&
      ticket._source.custom_value.length !== 0
    ) {
      ticket._source.custom_value
        .filter((val) => {
          return val.field_type === "category";
        })
        .map((item) => {
          if (item && item.field_values) {
            item.field_values[0].id !== ""
              ? (bool_val = true)
              : (bool_val = false);
          }
        });
    }

    return bool_val;
  };
  let returnCustomValuesAsString = (data) => {
    let values = [];

    values = returnCustomValues(data);

    // Initialize an empty string to store the result
    let result = "";

    // Loop through the values and append them to the result string with a slash
    for (var i = 0; i < values.length; i++) {
      result += values[i];
      // Add a slash after every value, except for the last one
      if (i !== values.length - 1) {
        result += "/";
      }
    }
    return result;
  };

  const [copied, setCopied] = useState(false);
  const [mentioncopied, setmentioncopied] = useState(false);
  const [translatedDescription, setTranslatedDescription] = useState([]);

  const [selected_tickets, set_Selected_tickets] = useState(() => {
    if (localStorage.getItem("selected_mentions_for_bulk_update")) {
      return JSON.parse(
        localStorage.getItem("selected_mentions_for_bulk_update")
      );
    } else {
      return [];
    }
  });

  const returnCheckBoxValue = (item) => {
    let statuscheck =
      selected_tickets && selected_tickets.length > 0
        ? selected_tickets.some((obj) => obj._id === item._id)
        : false;

    return statuscheck;
  };

  // priority list (Took from AllTickets.js)
  let filterprioritylist = [];

  if (props?.filterpriority?.hits) {
    props.filterpriority.hits.forEach((value) => {
      filterprioritylist.push({
        ticket_priority_name: value._source.ticket_priority_name,
        color: value._source.color,
        priority_id: value._id,
      });
    });
  }

  const renderPriorityColor = (p_name) => {
    let p_list = filterprioritylist.filter((val) => {
      return val.ticket_priority_name === p_name;
    });

    return `${p_list.length > 0 ? p_list[0].color : null}`;
  };

  const renderSentimentEmoji = (sentiment) => {
    if (sentiment === "Positive") {
      return (
        <SentimentSatisfiedAltRoundedIcon
          titleAccess="Positive"
          style={{
            fontSize: "16px",
            color: `green`,
          }}
        />
      );
    } else if (sentiment === "Negative") {
      return (
        <SentimentDissatisfiedRoundedIcon
          titleAccess="Negative"
          style={{
            fontSize: "16px",
            color: `${theme.palette.sentimentIconColor.negative}`,
          }}
        />
      );
    } else if (sentiment === "Neutral") {
      return (
        <SentimentNeutralRoundedIcon
          titleAccess="Neutral"
          style={{
            fontSize: "16px",
            color: `${theme.palette.sentimentIconColor.neutral}`,
          }}
        />
      );
    }
  };
  let getTotalMentions = () => {
    return props?.allMentions?.total?.value || 0;
  };

  //for pageNumber

  const fetchAllMentions = (params) => {
    props.dispatch(AllMentionsAction.requestAllmentions(params));
  };

  const fetchMentionsAccountsAndCustomerNames = () => {
    props.dispatch(AllMentionsAction.requestMentionsCustomerAndAccountNames());
  };

  let filteredPlatformList = [];

  const platform_list = [];

  let platformList = [];

  if (props.platform.hits) {
    props.platform.hits.map((item) => {
      platformList.push({
        platform_logo: item._source.platform_name,
        platform_name: item._source.platform_name,
        platform_alias: item._source.alias,
        platform_id: item._id,
      });
    });
  }

  filteredPlatformList = [...new Set(platformList)];

  let allCustomersList = [];
  let allAccountNamesList = [];

  if (props.all_customer_and_account_names) {
    allCustomersList =
      props.all_customer_and_account_names.customer_names ?? [];
    allAccountNamesList =
      props.all_customer_and_account_names.account_names ?? [];
  }

  if (!localStorage.getItem("allMentionsParameters")) {
    let filters = {
      platform: null,

      accountName: null,
      customerName: null,
      sentimentValue: null,
      keywordConfig: false,
      activities: null,
      createdDatelistSelected: "Last 2 Days",
      createdDate: [null, null],
    };
    localStorage.setItem("allMentionsParameters", JSON.stringify(filters));
  }

  useEffect(() => {
    let filters = JSON.parse(localStorage.getItem("allMentionsParameters"));

    if (filters.accountName || filters.accountName === null) {
      setAccountName(filters.accountName);
    }

    filters.mention_ids && setTicketIds(filters.mention_ids);

    filters.searchQuery && setSearchQuery(filters.searchQuery);
    filters.searchTypeSelected &&
      setSearchTypeSelected(filters.searchTypeSelected);

    if (filters.activities || filters.activities === null) {
      if (Array.isArray(filters.activities) && filters.activities.length === 0)
        filters.activities = null;
      setActivities(filters.activities);
    }
    if (filters.customerName || filters.customerName === null) {
      setCustomerName(filters.customerName);
    }
    if (filters.sentimentValue || filters.sentimentValue === null) {
      setSentimental_val(filters.sentimentValue);
    }

    if (filters.keywordConfig === null) {
      setKeyword_config(filters.keywordConfig);
    }

    if (filters.ratingValue || filters.ratingValue === null) {
      setRating_val(filters.ratingValue);
    }

    if (filters.createdDate || filters.createdDate === null) {
      setCreatedDate(filters.createdDate);
    }
    if (
      filters.createdDatelistSelected ||
      filters.createdDatelistSelected === null
    ) {
      setCreatedDatelistSelected(filters.createdDatelistSelected);
    }
    if (filters.platform || filters.platform === null) {
      // filters.platform || filters.platform === null;
      setPlatform(filters.platform);
    }

    props.setSearchChanges && props.setSearchChanges(false);

    localStorage.setItem("allMentionsParameters", JSON.stringify(filters));
  }, [localStorage.getItem("allMentionsParameters"), props.searchChanges]);

  useEffect(() => {
    let filters = JSON.parse(localStorage.getItem("allMentionsParameters"));

    if (props.searchChanges) {
      setFilterContents(
        filterContents
          ? {
              ...filterContents,
              mention_ids: filters?.mention_ids,
              searchQuery: filters?.searchQuery,
            }
          : {
              mention_ids: filters?.mention_ids,
              searchQuery: filters?.searchQuery,
            }
      );

      applyFilter(filters);
    }
  }, [localStorage.getItem("allMentionsParameters"), props.searchChanges]);

  const [platform, setPlatform] = useState(() => {
    if (localStorage.getItem("allMentionsParameters")) {
      return JSON.parse(localStorage.getItem("allMentionsParameters")).platform;
    } else {
      return null;
    }
  });

  const [activities, setActivities] = useState(() => {
    if (localStorage.getItem("allMentionsParameters")) {
      return JSON.parse(localStorage.getItem("allMentionsParameters"))
        .activities;
    } else {
      return null;
    }
  });

  function TicketIDFilter(value) {
    return `["_id","must","","terms",["${value}"]]`;
  }

  function TicketContentFilter(value) {
    return `["_id","must","","terms",${JSON.stringify(value)}]`;
  }

  const FetchFunction = () => {
    let filter = [];

    const today = new Date();

    let date1 = new Date();
    let previousday = new Date(date1);
    previousday.setDate(previousday.getDate() - 1);

    //Last 2 days filter
    let last2days = [...Array(2).keys()].map((index) => {
      const date = new Date();
      date.setDate(date.getDate() - index);
      return date;
    });

    // start from today's date last 7 days
    const last7Days = [...Array(7).keys()].map((index) => {
      const date = new Date();
      date.setDate(date.getDate() - index);

      return date;
    });

    // last 30 days
    let date2 = new Date();
    let last30Days = new Date(new Date().setDate(date2.getDate() - 30));

    if (platform) {
      filter.push(`["platform.keyword","must","","match","${platform}"]`);
    }

    // search
    if (searchQuery !== null) {
      if (searchTypeSelected === "Mention-ID") {
        filter.push(TicketIDFilter(searchQuery));
      } else if (searchTypeSelected === "Content" && ticket_ids) {
        filter.push(TicketContentFilter(ticket_ids));
      }
    }

    if (activities && activities?.length !== 0) {
      if (platform === "Twitter") {
        const isDmPresent = activities.some(
          (item) => item.activity_name === "Direct Message"
        );

        let filtereddata = activities.filter((item) => {
          return item.activity_name !== "Direct Message";
        });

        if (isDmPresent) {
          filter.push(`["_index","must","","match","twitter_direct_messages"]`);

          if (filtereddata.length !== 0) {
            filter.push(
              `["tweet_type.keyword","must","","terms",${JSON.stringify(
                filtereddata.map((item) => {
                  return item.activity_name;
                })
              )}]`
            );
          }
        } else {
          filter.push(
            `["tweet_type.keyword","must","","terms",${JSON.stringify(
              filtereddata.map((item) => {
                return item.activity_name;
              })
            )}]`
          );
        }
      } else if (platform !== "Twitter") {
        filter.push(
          `["activities.keyword","must","","terms",${JSON.stringify(
            activities.map((item) => {
              return item.activity_name;
            })
          )}]`
        );
      }
    }

    if (customerName) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }

    if (accountName) {
      filter.push(
        `["account_name.keyword","must","","match","${accountName}"]`
      );
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value","must","","match","0"]`);
      }
    }

    if (keyword_config) {
      filter.push(`["from_keyword","must","","match","${keyword_config}"]`);
    }

    if (platform && rating_val) {
      function singleDigitToWords(num) {
        const numberWords = {
          1: "ONE",
          2: "TWO",
          3: "THREE",
          4: "FOUR",
          5: "FIVE",
        };

        return numberWords[num] || "number out of range";
      }

      if (platform === "GMB") {
        filter.push(
          `["review_rating.keyword","must","","match","${singleDigitToWords(
            rating_val
          )}"]`
        );
      } else if (platform === "Ambition Box") {
        filter.push(`["rating.keyword","must","","match","${rating_val}"]`);
      } else if (platform === "Appstore") {
        filter.push(`["rating","must","","match","${rating_val}"]`);
      } else if (platform === "Playstore") {
        filter.push(`["ratings.keyword","must","","match","${rating_val}"]`);
      }
    }

    function TodayFilter() {
      return `["created_on","must","lte","range","${format(
        new Date(),
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        today,
        "yyyy-MM-dd"
      )}"]`;
    }

    function PreviousFilter() {
      return `["created_on","must","lte","range","${format(
        previousday,
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        previousday,
        "yyyy-MM-dd"
      )}"]`;
    }

    function Last2DaysFilter() {
      return `["created_on","must","lte","range","${format(
        last2days[0],
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        last2days[1],
        "yyyy-MM-dd"
      )}"]`;
    }

    function Last7DaysFilter() {
      return `["created_on","must","lte","range","${format(
        last7Days[0],
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        last7Days[6],
        "yyyy-MM-dd"
      )}"]`;
    }

    function Last30DaysFilter() {
      return `["created_on","must","lte","range","${format(
        today,
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        last30Days,
        "yyyy-MM-dd"
      )}"]`;
    }

    function CustomDateFilter() {
      return `["created_on","must","lte","range","${format(
        new Date(createdDate[1]),
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        new Date(createdDate[0]),
        "yyyy-MM-dd"
      )}"]`;
    }

    if (createdDatelistSelected === "Today") {
      filter.push(TodayFilter());
    }

    if (createdDatelistSelected === "Previous Day") {
      filter.push(PreviousFilter());
    }

    if (createdDatelistSelected === "Last 2 Days") {
      filter.push(Last2DaysFilter());
    }
    if (createdDatelistSelected === "Last 7 Days") {
      filter.push(Last7DaysFilter());
    }
    if (createdDatelistSelected === "Last 30 Days") {
      filter.push(Last30DaysFilter());
    }

    if (
      createdDatelistSelected === "Custom" &&
      createdDate &&
      createdDate[0] &&
      createdDate[1]
    ) {
      filter.push(CustomDateFilter());
    }

    let params = {
      page_limit: `${page_limit}`,
      filters: `[${filter}]`,
      // order_by: `[["${props.sort_by}", "${props.sort_order}"]]`,
    };

    fetchAllMentions(params);
  };

  useEffect(() => {
    let filter_params = {
      page_limit: `none`,
      batch_size: `10000`,
      // order_by: `[["${props.sort_by}", "${props.sort_order}"]]`,
    };

    FetchFunction();

    fetchMentionsAccountsAndCustomerNames();
  }, [props.sort_by, props.sort_order]);

  const [pageNumber, setPageNumber] = useState(() => {
    if (state && state.action === "directed") {
      return 0;
    } else if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).pageNumber;
    } else {
      return 0;
    }
  });

  const [accountName, setAccountName] = useState(() => {
    if (localStorage.getItem("allMentionsParameters")) {
      return JSON.parse(localStorage.getItem("allMentionsParameters"))
        .accountName;
    } else {
      return null;
    }
  });

  const [customerName, setCustomerName] = useState(() => {
    if (localStorage.getItem("allMentionsParameters")) {
      return JSON.parse(localStorage.getItem("allMentionsParameters"))
        .customerName;
    } else {
      return null;
    }
  });

  const [sentimental_val, setSentimental_val] = useState(() => {
    if (localStorage.getItem("allMentionsParameters")) {
      return JSON.parse(localStorage.getItem("allMentionsParameters"))
        .sentimentValue;
    } else {
      return null;
    }
  });

  const [keyword_config, setKeyword_config] = useState(() => {
    if (localStorage.getItem("allMentionsParameters")) {
      return JSON.parse(localStorage.getItem("allMentionsParameters"))
        .keywordConfig;
    } else {
      return null;
    }
  });

  const [rating_val, setRating_val] = useState(() => {
    if (localStorage.getItem("allMentionsParameters")) {
      return JSON.parse(localStorage.getItem("allMentionsParameters"))
        .ratingValue;
    } else {
      return null;
    }
  });

  const [createdDatelistSelected, setCreatedDatelistSelected] = useState(() => {
    if (localStorage.getItem("allMentionsParameters")) {
      return JSON.parse(localStorage.getItem("allMentionsParameters"))
        .createdDatelistSelected;
    } else {
      return "Last 2 Days";
    }
  });

  const [createdDate, setCreatedDate] = useState(() => {
    if (localStorage.getItem("allMentionsParameters")) {
      return JSON.parse(localStorage.getItem("allMentionsParameters"))
        .createdDate;
    } else {
      return null;
    }
  });

  const [modifiedDatelistSelected, setModifiedDatelistSelected] = useState(
    () => {
      if (
        localStorage.getItem("mentionsFilters") &&
        localStorage.getItem("mentionsFilters").modifiedDatelistSelected
      ) {
        return JSON.parse(localStorage.getItem("mentionsFilters"))
          .modifiedDatelistSelected;
      } else {
        return null;
      }
    }
  );

  const [modifiedDate, setModifiedDate] = useState(() => {
    if (
      localStorage.getItem("mentionsFilters") &&
      localStorage.getItem("mentionsFilters").modifiedDate
    ) {
      return [
        JSON.parse(localStorage.getItem("mentionsFilters")).modifiedDate &&
        JSON.parse(localStorage.getItem("mentionsFilters")).modifiedDate[0]
          ? new Date(
              JSON.parse(
                localStorage.getItem("mentionsFilters")
              ).modifiedDate[0]
            )
          : null,
        JSON.parse(localStorage.getItem("mentionsFilters")).modifiedDate &&
        JSON.parse(localStorage.getItem("mentionsFilters")).modifiedDate[1]
          ? new Date(
              JSON.parse(
                localStorage.getItem("mentionsFilters")
              ).modifiedDate[1]
            )
          : null,
      ];
    } else {
      return [null, null];
    }
  });
  const [unAssigned, SetUnAssigned] = useState(() => {
    if (state && state.action === "directed") {
      return state.unAssigned;
    } else if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).unAssigned;
    } else {
      return false;
    }
  });

  const [assigned_To, setAssigned_To] = useState(() => {
    if (state && state.action === "directed") {
      return state.assigned_to;
    } else if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).assigned_To;
    } else {
      return null;
    }
  });
  const [category, setCategory] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).category;
    } else {
      return null;
    }
  });

  const [ticket_ids, setTicketIds] = useState(() => {
    if (localStorage.getItem("allMentionsParameters")) {
      return JSON.parse(localStorage.getItem("allMentionsParameters"))
        .mention_ids;
    } else {
      return [];
    }
  });

  const [searchTypeSelected, setSearchTypeSelected] = useState(() => {
    if (localStorage.getItem("allMentionsParameters")) {
      return JSON.parse(localStorage.getItem("allMentionsParameters"))
        .searchTypeSelected;
    } else {
      return null;
    }
  });

  const [searchQuery, setSearchQuery] = useState(() => {
    if (localStorage.getItem("allMentionsParameters")) {
      return JSON.parse(localStorage.getItem("allMentionsParameters"))
        .searchQuery;
    } else {
      return null;
    }
  });

  const [filterContents, setFilterContents] = useState({
    createdDate: createdDate,
    createdDatelistSelected: createdDatelistSelected,
    platform: platform,
    accountName: accountName,
    customerName: customerName,
    mention_ids: ticket_ids,
    searchQuery: searchQuery,
    searchTypeSelected: searchTypeSelected,
    sentimental_val: sentimental_val,
    keyword_config: keyword_config,
    rating_val: rating_val,
  });

  const applyFilter = (filtervalue) => {
    const today = new Date();

    let date1 = new Date();
    let previousday = new Date(date1);
    previousday.setDate(previousday.getDate() - 1);

    //Last 2 days filter
    let last2days = [...Array(2).keys()].map((index) => {
      const date = new Date();
      date.setDate(date.getDate() - index);
      return date;
    });

    // start from today's date last 7 days
    const last7Days = [...Array(7).keys()].map((index) => {
      const date = new Date();
      date.setDate(date.getDate() - index);

      return date;
    });

    // last 30 days
    let date2 = new Date();
    let last30Days = new Date(new Date().setDate(date2.getDate() - 30));

    let filter = [];

    if (filtervalue?.platform) {
      filter.push(
        `["platform.keyword","must","","match","${filtervalue?.platform}"]`
      );
    }

    // search
    if (filtervalue?.searchQuery !== null) {
      if (filtervalue?.searchTypeSelected === "Mention-ID") {
        filter.push(TicketIDFilter(filtervalue?.searchQuery));
      } else if (
        filtervalue?.searchTypeSelected === "Content" &&
        filtervalue?.mention_ids
      ) {
        filter.push(TicketContentFilter(filtervalue?.mention_ids));
      }
    }

    if (filtervalue?.activities && filtervalue?.activities?.length !== 0) {
      if (filtervalue?.platform === "Twitter") {
        const isDmPresent = filtervalue?.activities.some(
          (item) => item.activity_name === "Direct Message"
        );

        let filtereddata = filtervalue?.activities.filter((item) => {
          return item.activity_name !== "Direct Message";
        });

        if (isDmPresent) {
          filter.push(`["_index","must","","match","twitter_direct_messages"]`);

          if (filtereddata.length !== 0) {
            filter.push(
              `["tweet_type.keyword","must","","terms",${JSON.stringify(
                filtereddata.map((item) => {
                  return item.activity_name;
                })
              )}]`
            );
          }
        } else {
          filter.push(
            `["tweet_type.keyword","must","","terms",${JSON.stringify(
              filtereddata.map((item) => {
                return item.activity_name;
              })
            )}]`
          );
        }
      } else if (filtervalue?.platform !== "Twitter") {
        filter.push(
          `["activities.keyword","must","","terms",${JSON.stringify(
            filtervalue?.activities.map((item) => {
              return item.activity_name;
            })
          )}]`
        );
      }
    }

    if (filtervalue?.customerName) {
      filter.push(
        `["customer_name.keyword","must","","match","${filtervalue?.customerName}"]`
      );
    }

    if (filtervalue?.accountName) {
      filter.push(
        `["account_name.keyword","must","","match","${filtervalue?.accountName}"]`
      );
    }

    if (filtervalue?.sentimentValue) {
      if (filtervalue?.sentimentValue === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (filtervalue?.sentimentValue === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (filtervalue?.sentimentValue === "Neutral") {
        filter.push(`["sentiment_value","must","","match","0"]`);
      }
    }

    if (filtervalue?.keywordConfig) {
      filter.push(
        `["from_keyword","must","","match","${filtervalue?.keywordConfig}"]`
      );
    }

    if (filtervalue?.platform && filtervalue?.ratingValue) {
      function singleDigitToWords(num) {
        const numberWords = {
          1: "ONE",
          2: "TWO",
          3: "THREE",
          4: "FOUR",
          5: "FIVE",
        };

        return numberWords[num] || "number out of range";
      }

      if (filtervalue?.platform === "GMB") {
        filter.push(
          `["review_rating.keyword","must","","match","${singleDigitToWords(
            filtervalue?.ratingValue
          )}"]`
        );
      } else if (filtervalue?.platform === "Ambition Box") {
        filter.push(
          `["rating.keyword","must","","match","${filtervalue?.ratingValue}"]`
        );
      } else if (filtervalue?.platform === "Appstore") {
        filter.push(
          `["rating","must","","match","${filtervalue?.ratingValue}"]`
        );
      } else if (filtervalue?.platform === "Playstore") {
        filter.push(
          `["ratings.keyword","must","","match","${filtervalue?.ratingValue}"]`
        );
      }
    }

    function TodayFilter() {
      return `["created_on","must","lte","range","${format(
        new Date(),
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        today,
        "yyyy-MM-dd"
      )}"]`;
    }

    function PreviousFilter() {
      return `["created_on","must","lte","range","${format(
        previousday,
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        previousday,
        "yyyy-MM-dd"
      )}"]`;
    }

    function Last2DaysFilter() {
      return `["created_on","must","lte","range","${format(
        last2days[0],
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        last2days[1],
        "yyyy-MM-dd"
      )}"]`;
    }

    function Last7DaysFilter() {
      return `["created_on","must","lte","range","${format(
        last7Days[0],
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        last7Days[6],
        "yyyy-MM-dd"
      )}"]`;
    }

    function Last30DaysFilter() {
      return `["created_on","must","lte","range","${format(
        today,
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        last30Days,
        "yyyy-MM-dd"
      )}"]`;
    }

    function CustomDateFilter() {
      return `["created_on","must","lte","range","${format(
        filtervalue?.createdDate[1].$d
          ? filtervalue?.createdDate[1].$d
          : filtervalue?.createdDate[1],
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        filtervalue?.createdDate[0].$d
          ? filtervalue?.createdDate[0].$d
          : filtervalue?.createdDate[0],
        "yyyy-MM-dd"
      )}"]`;
    }

    if (filtervalue?.createdDatelistSelected === "Today") {
      filter.push(TodayFilter());
    }

    if (filtervalue?.createdDatelistSelected === "Previous Day") {
      filter.push(PreviousFilter());
    }

    if (filtervalue?.createdDatelistSelected === "Last 2 Days") {
      filter.push(Last2DaysFilter());
    }
    if (filtervalue?.createdDatelistSelected === "Last 7 Days") {
      filter.push(Last7DaysFilter());
    }
    if (filtervalue?.createdDatelistSelected === "Last 30 Days") {
      filter.push(Last30DaysFilter());
    }

    if (filtervalue?.createdDatelistSelected === "Custom") {
      filter.push(CustomDateFilter());
    }

    let params = {
      page_limit: `${page_limit}`,
      filters: `[${filter}]`,
      // order_by: `[["${props.sort_by}", "${props.sort_order}"]]`,
    };

    fetchAllMentions(params);
    props.onClose && props.onClose();
  };

  const exportFilter = (filtervalue) => {
    const today = new Date();

    let date1 = new Date();
    let previousday = new Date(date1);
    previousday.setDate(previousday.getDate() - 1);

    //Last 2 days filter
    let last2days = [...Array(2).keys()].map((index) => {
      const date = new Date();
      date.setDate(date.getDate() - index);
      return date;
    });

    // start from today's date last 7 days
    const last7Days = [...Array(7).keys()].map((index) => {
      const date = new Date();
      date.setDate(date.getDate() - index);

      return date;
    });

    // last 30 days
    let date2 = new Date();
    let last30Days = new Date(new Date().setDate(date2.getDate() - 30));

    let filter = [];

    if (filtervalue?.platform) {
      filter.push(
        `["platform.keyword","must","","match","${filtervalue?.platform}"]`
      );
    }

    // search
    if (filtervalue?.searchQuery !== null) {
      if (filtervalue?.searchTypeSelected === "Mention-ID") {
        filter.push(TicketIDFilter(filtervalue?.searchQuery));
      } else if (
        filtervalue?.searchTypeSelected === "Content" &&
        filtervalue?.mention_ids
      ) {
        filter.push(TicketContentFilter(filtervalue?.mention_ids));
      }
    }

    if (filtervalue?.activities && filtervalue?.activities?.length !== 0) {
      if (filtervalue?.platform === "Twitter") {
        const isDmPresent = filtervalue?.activities.some(
          (item) => item.activity_name === "Direct Message"
        );

        let filtereddata = filtervalue?.activities.filter((item) => {
          return item.activity_name !== "Direct Message";
        });

        if (isDmPresent) {
          filter.push(`["_index","must","","match","twitter_direct_messages"]`);

          if (filtereddata.length !== 0) {
            filter.push(
              `["tweet_type.keyword","must","","terms",${JSON.stringify(
                filtereddata.map((item) => {
                  return item.activity_name;
                })
              )}]`
            );
          }
        } else {
          filter.push(
            `["tweet_type.keyword","must","","terms",${JSON.stringify(
              filtereddata.map((item) => {
                return item.activity_name;
              })
            )}]`
          );
        }
      } else if (filtervalue?.platform !== "Twitter") {
        filter.push(
          `["activities.keyword","must","","terms",${JSON.stringify(
            filtervalue?.activities.map((item) => {
              return item.activity_name;
            })
          )}]`
        );
      }
    }

    if (filtervalue?.customerName) {
      filter.push(
        `["customer_name.keyword","must","","match","${filtervalue?.customerName}"]`
      );
    }

    if (filtervalue?.accountName) {
      filter.push(
        `["account_name.keyword","must","","match","${filtervalue?.accountName}"]`
      );
    }

    if (filtervalue?.sentimentValue) {
      if (filtervalue?.sentimentValue === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (filtervalue?.sentimentValue === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (filtervalue?.sentimentValue === "Neutral") {
        filter.push(`["sentiment_value","must","","match","0"]`);
      }
    }

    if (filtervalue?.keywordConfig) {
      filter.push(
        `["from_keyword","must","","match","${filtervalue?.keywordConfig}"]`
      );
    }

    if (filtervalue?.platform && filtervalue?.ratingValue) {
      function singleDigitToWords(num) {
        const numberWords = {
          1: "ONE",
          2: "TWO",
          3: "THREE",
          4: "FOUR",
          5: "FIVE",
        };

        return numberWords[num] || "number out of range";
      }

      if (filtervalue?.platform === "GMB") {
        filter.push(
          `["review_rating.keyword","must","","match","${singleDigitToWords(
            filtervalue?.ratingValue
          )}"]`
        );
      } else if (filtervalue?.platform === "Ambition Box") {
        filter.push(
          `["rating.keyword","must","","match","${filtervalue?.ratingValue}"]`
        );
      } else if (filtervalue?.platform === "Appstore") {
        filter.push(
          `["rating","must","","match","${filtervalue?.ratingValue}"]`
        );
      } else if (filtervalue?.platform === "Playstore") {
        filter.push(
          `["ratings.keyword","must","","match","${filtervalue?.ratingValue}"]`
        );
      }
    }

    function TodayFilter() {
      return `["created_on","must","lte","range","${format(
        new Date(),
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        today,
        "yyyy-MM-dd"
      )}"]`;
    }

    function PreviousFilter() {
      return `["created_on","must","lte","range","${format(
        previousday,
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        previousday,
        "yyyy-MM-dd"
      )}"]`;
    }

    function Last2DaysFilter() {
      return `["created_on","must","lte","range","${format(
        last2days[0],
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        last2days[1],
        "yyyy-MM-dd"
      )}"]`;
    }

    function Last7DaysFilter() {
      return `["created_on","must","lte","range","${format(
        last7Days[0],
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        last7Days[6],
        "yyyy-MM-dd"
      )}"]`;
    }

    function Last30DaysFilter() {
      return `["created_on","must","lte","range","${format(
        today,
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        last30Days,
        "yyyy-MM-dd"
      )}"]`;
    }

    function CustomDateFilter() {
      return `["created_on","must","lte","range","${format(
        filtervalue?.createdDate[1].$d
          ? filtervalue?.createdDate[1].$d
          : filtervalue?.createdDate[1],
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        filtervalue?.createdDate[0].$d
          ? filtervalue?.createdDate[0].$d
          : filtervalue?.createdDate[0],
        "yyyy-MM-dd"
      )}"]`;
    }

    if (filtervalue?.createdDatelistSelected === "Today") {
      filter.push(TodayFilter());
    }

    if (filtervalue?.createdDatelistSelected === "Previous Day") {
      filter.push(PreviousFilter());
    }

    if (filtervalue?.createdDatelistSelected === "Last 2 Days") {
      filter.push(Last2DaysFilter());
    }
    if (filtervalue?.createdDatelistSelected === "Last 7 Days") {
      filter.push(Last7DaysFilter());
    }
    if (filtervalue?.createdDatelistSelected === "Last 30 Days") {
      filter.push(Last30DaysFilter());
    }

    if (filtervalue?.createdDatelistSelected === "Custom") {
      filter.push(CustomDateFilter());
    }

    let params = {
      page_limit: `none`,
      filters: `[${filter}]`,
    };

    const callBack = (response) => {
      function DateOnly(item) {
        const dateObj = new Date(item);

        const day = String(dateObj.getDate()).padStart(2, "0");
        const month = String(dateObj.getMonth() + 1).padStart(2, "0");
        const year = String(dateObj.getFullYear());
        const formattedDate = `${day}/${month}/${year}`;

        return dateObj ? `${formattedDate}` : 0;
      }

      function getSentimentCategory(sentimentValue) {
        if (sentimentValue === 0) {
          return "Neutral";
        } else if (sentimentValue < 0) {
          return "Negative";
        } else {
          return "Positive";
        }
      }

      const getFeedContent = (item) => {
        if (
          item._source.platform === "Appstore" ||
          item._source.platform === "Ambition Box" ||
          item._source.platform === "Indeed"
        ) {
          return `Rating: ${item._source.rating}`;
        }
        if (item._source.platform === "Playstore") {
          return `Rating: ${
            item._source.ratings
          }\n: ${item._source.review_content
            ?.replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&amp;/g, "&")}`;
        }
        if (item._source.platform === "Glassdoor") {
          return `Rating: ${item._source.overallRating}`;
        }
        if (
          item._source.platform === "GMB" &&
          item._source.activities === "Reviews"
        ) {
          return `Rating: ${item._source.review_rating}`;
        }
        if (item._index === "twitter") {
          return item._source.content_of_the_tweet
            ?.replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&amp;/g, "&");
        }
        if (
          // item._source.platform === "Instagram" ||
          item._source.platform === "Youtube" ||
          (item._source.platform === "LinkedIn" &&
            item._source.activities === "Comment")
        ) {
          return item._source.comment_content;
        }
        if (item._source.platform === "Appstore") {
          return item._source.review;
        }
        if (item._source.platform === "Playstore") {
          return item._source.review_content;
        }
        if (item._source.platform === "Glassdoor") {
          return item._source.review;
        }

        if (item._source.platform === "Ambition Box") {
          return `Title: ${item._source.title}\nPros: ${item._source.likes}\nCons: ${item._source.dislikes}
          `;
        }

        // if (item._source.platform === "Ambition Box") {
        //   return `Title: ${item._source.title}
        //   // \nPros: ${item._source.likes}\nCons: ${item._source.dislikes}
        //   `;
        // }
        if (item._source.platform === "Indeed") {
          return item._source.rating_description;
        }
        if (item._source.platform === "Consumer Complaints") {
          return `Title: ${item._source.title}}`;
        }

        //  if (item._source.platform === "Consumer Complaints") {
        //    return `Title: ${item._source.title}\nDescription: ${item._source.description}`;
        //  }
        if (
          item._source.platform === "Discord" ||
          item._source.platform === "Telegram"
        ) {
          return item._source.message_content;
        }
        if (item._source.platform === "Quora") {
          return `Question: ${
            item._source.question ?? item._source.question_text
          }}`;
        }
        // if (item._source.platform === "Quora") {
        //   return `Question: ${item._source.question}\nAnswer: ${item._source.answer}`;
        // }
        if (item._source.platform === "Email") {
          return item._source.subject;
        }
        if (
          item._source.platform === "GMB" &&
          item._source.activities === "Reviews"
        ) {
          return item._source.review_comment;
        }
        if (
          item._source.platform === "GMB" &&
          item._source.activities === "Questions Answers"
        ) {
          return item._source.question;
        }
        if (
          item._source.platform === "LinkedIn" &&
          item._source.activities === "Post Mention"
        ) {
          return item._source.post_content;
        }
        // if (item._source.platform === "Reddit") {
        //   return `Title: ${item._source.title}\nDescription: ${item._source.description}`;
        // }
        if (item._source.platform === "Reddit") {
          return `Title: ${item._source.title}}`;
        }
        if (item._source.platform === "Whatsapp") {
          return item._source.message_content;
        }

        if (item._source.platform === "Threads") {
          return item._source.thread_content;
        }

        if (item._index === "instagram_direct") {
          return item._source.message_content;
        }

        if (item._index === "instagram_feed") {
          return item._source.activities === "Comments"
            ? item._source.comment_content
            : item._source.activities === "Tagged Posts"
            ? item._source.parent_post_caption
            : item._source.post_content;
        }

        if (item._index === "twitter_direct_messages") {
          return item._source.message_text;
        }

        if (item._index === "facebook_webhook") {
          return item._source.activities === "Comments"
            ? item._source.comment_content
            : item?._source?.post_content;
        }

        if (
          item._index === "facebook_direct_messages" &&
          item._source.message_text
        ) {
          return item._source.message_text;
        }
      };

      const getCustomerName = (item) => {
        if (
          item._source.platform === "Twitter" &&
          item._source.activities === "Direct Message"
        ) {
          return item._source.customer_handle;
        }
        if (
          item._source.platform === "Facebook" &&
          item._source.activities === "Direct Message"
        ) {
          return item._source.sender_name;
        } else {
          return item?._source?.customer_name ?? null;
        }
      };

      const getAccountName = (item) => {
        if (
          item._source.platform === "Instagram" &&
          item._source.activities === "Direct Message"
        ) {
          return item._source.account_username;
        } else {
          return item?._source?.account_name ?? null;
        }
      };

      function TimeOnly(item) {
        const dateObj = new Date(item);

        // Format: hh:mm:ss
        const hours = String(dateObj.getHours()).padStart(2, "0");
        const minutes = String(dateObj.getMinutes()).padStart(2, "0");
        const seconds = String(dateObj.getSeconds()).padStart(2, "0");
        const formattedTime = `${hours}:${minutes}:${seconds}`;

        return dateObj ? `${formattedTime}` : 0;
      }

      if (response) {
        let Export = [];

        if (response.hits) {
          response.hits.hits.forEach((list) => {
            let objValues = {};

            Object.assign(
              objValues,

              list._id && {
                ["Mention ID"]: `${list._id.toString()}`,
              },
              {
                ["Feed"]: getFeedContent(list),
              },
              list._source.platform && {
                ["Platform"]: list._source.platform,
              },
              {
                ["Activity"]:
                  list._index === "twitter"
                    ? list._source.tweet_type
                    : list._index === "quora_questions"
                    ? "Questions Answers"
                    : list._index === "quora_answers"
                    ? "Questions Answers"
                    : list._index === "twitter_direct_messages"
                    ? "Direct Message"
                    : list._source.activities,
              },
              list._source.created_on && {
                ["Mention Created Date"]: DateOnly(list._source.created_on),
              },
              list._source.created_on && {
                ["Mention Created Time"]: TimeOnly(list._source.created_on),
              },

              list._source.sentiment_value !== null && {
                ["Sentiment"]: `${getSentimentCategory(
                  list._source.sentiment_value
                )}`,
              },
              {
                ["Account Name"]: getAccountName(list),
              },
              {
                ["Customer Name"]: getCustomerName(list),
              },
              list._source.app_id && {
                ["App ID"]: list._source.app_id,
              },
              list._source.app_name && {
                ["App Name"]: list._source.app_name,
              },
              list._source.ticket_id && {
                ["Ticket ID"]: list._source.ticket_id,
              }
            );

            Export.push(objValues);
          });

          setExportData(Export);
        }
      }
    };
    props.dispatch(
      AllMentionsAction.requestAllmentionsExportFilter(params, callBack)
    );
    props.onClose && props.onClose();
  };

  useEffect(() => {
    if (exportData.length > 0) {
      // Create a new workbook and worksheet
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(exportData);

      // Define cell styles and formats

      const formatCells = (worksheet) => {
        const range = XLSX.utils.decode_range(worksheet["!ref"]);
        for (let R = range.s.r; R <= range.e.r; ++R) {
          for (let C = range.s.c; C <= range.e.c; ++C) {
            const cellAddress = { c: C, r: R };
            const cellRef = XLSX.utils.encode_cell(cellAddress);
            if (!worksheet[cellRef]) continue;

            // Define the format based on cell value or header
            const cell = worksheet[cellRef];
            if (R === 0) {
              // Assuming first row is header
              cell.t = "s"; // Text type
            } else {
              const header =
                worksheet[XLSX.utils.encode_cell({ c: C, r: 0 })].v;
              switch (header) {
                case "SL No.":
                  cell.t = "s"; // Text type
                  cell.z = "@"; // Apply text format
                  break;
              }
            }
          }
        }
      };
      //  ."Ticket Non Working Hours",

      // Apply cell formatting
      formatCells(worksheet);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Tickets");

      // Export the workbook to a file
      XLSX.writeFile(workbook, "Mentions.xlsx");
    }
  }, [exportData]);

  const returnStarsIcon = (stars) => {
    let i;
    let displayStars = [];
    if (typeof stars === "string") {
      switch (stars) {
        case "ONE":
          i = 1;
          break;
        case "TWO":
          i = 2;
          break;
        case "THREE":
          i = 3;
          break;
        case "FOUR":
          i = 4;
          break;
        case "FIVE":
          i = 5;
          break;
        case "1":
          i = 1;
          break;
        case "2":
          i = 2;
          break;
        case "3":
          i = 3;
          break;
        case "4":
          i = 4;
          break;
        case "5":
          i = 5;
          break;
      }
      // for (let k = 1; k <= i; k++) {
      //   displayStars.push(<StarIcon sx={{ color: "gold" }} />);
      // }
    }
    if (typeof stars === "number") {
      i = stars;
      // if (stars === i) {
      //   for (let k = 1; k <= i; k++) {
      //     displayStars.push(<StarIcon sx={{ color: "gold" }} />);
      //   }
      // }
    }

    // if (stars === i) {
    for (let k = 1; k <= i; k++) {
      displayStars.push(<StarIcon sx={{ color: "gold" }} />);
    }
    // }
    return displayStars;
  };

  const handleChangePagination = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const filter = [];
    let params = {};

    const today = new Date();

    let date1 = new Date();
    let previousday = new Date(date1);
    previousday.setDate(previousday.getDate() - 1);

    //Last 2 days filter
    let last2days = [...Array(2).keys()].map((index) => {
      const date = new Date();
      date.setDate(date.getDate() - index);
      return date;
    });

    // start from today's date last 7 days
    const last7Days = [...Array(7).keys()].map((index) => {
      const date = new Date();
      date.setDate(date.getDate() - index);

      return date;
    });

    // last 30 days
    let date2 = new Date();
    let last30Days = new Date(new Date().setDate(date2.getDate() - 30));
    if (platform) {
      filter.push(`["platform.keyword","must","","match","${platform}"]`);
    }

    if (activities && activities?.length !== 0) {
      if (platform === "Twitter") {
        const isDmPresent = activities.some(
          (item) => item.activity_name === "Direct Message"
        );

        let filtereddata = activities.filter((item) => {
          return item.activity_name !== "Direct Message";
        });

        if (isDmPresent) {
          filter.push(`["_index","must","","match","twitter_direct_messages"]`);

          if (filtereddata.length !== 0) {
            filter.push(
              `["tweet_type.keyword","must","","terms",${JSON.stringify(
                filtereddata.map((item) => {
                  return item.activity_name;
                })
              )}]`
            );
          }
        } else {
          filter.push(
            `["tweet_type.keyword","must","","terms",${JSON.stringify(
              filtereddata.map((item) => {
                return item.activity_name;
              })
            )}]`
          );
        }
      } else if (platform !== "Twitter") {
        filter.push(
          `["activities.keyword","must","","terms",${JSON.stringify(
            activities.map((item) => {
              return item.activity_name;
            })
          )}]`
        );
      }
    }

    if (customerName) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }

    // if (searchQuery) {
    //   filter.push(`["_id","must","","terms",["${searchQuery}"]]`);
    // }

    // search
    if (searchQuery !== null) {
      if (searchTypeSelected === "Mention-ID") {
        filter.push(TicketIDFilter(searchQuery));
      } else if (searchTypeSelected === "Content" && ticket_ids) {
        filter.push(TicketContentFilter(ticket_ids));
      }
    }

    if (accountName) {
      filter.push(
        `["account_name.keyword","must","","match","${accountName}"]`
      );
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value","must","","match","0"]`);
      }
    }

    if (keyword_config) {
      filter.push(`["from_keyword","must","","match","${keyword_config}"]`);
    }
    if (platform && rating_val) {
      function singleDigitToWords(num) {
        const numberWords = {
          1: "ONE",
          2: "TWO",
          3: "THREE",
          4: "FOUR",
          5: "FIVE",
        };

        return numberWords[num] || "number out of range";
      }

      if (platform === "GMB") {
        filter.push(
          `["review_rating.keyword","must","","match","${singleDigitToWords(
            rating_val
          )}"]`
        );
      } else if (platform === "Ambition Box") {
        filter.push(`["rating.keyword","must","","match","${rating_val}"]`);
      } else if (platform === "Appstore") {
        filter.push(`["rating","must","","match","${rating_val}"]`);
      } else if (platform === "Playstore") {
        filter.push(`["ratings.keyword","must","","match","${rating_val}"]`);
      }
    }

    function TodayFilter() {
      return `["created_on","must","lte","range","${format(
        new Date(),
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        today,
        "yyyy-MM-dd"
      )}"]`;
    }

    function PreviousFilter() {
      return `["created_on","must","lte","range","${format(
        previousday,
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        previousday,
        "yyyy-MM-dd"
      )}"]`;
    }

    function Last2DaysFilter() {
      return `["created_on","must","lte","range","${format(
        last2days[0],
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        last2days[1],
        "yyyy-MM-dd"
      )}"]`;
    }

    function Last7DaysFilter() {
      return `["created_on","must","lte","range","${format(
        last7Days[0],
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        last7Days[6],
        "yyyy-MM-dd"
      )}"]`;
    }

    function Last30DaysFilter() {
      return `["created_on","must","lte","range","${format(
        today,
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        last30Days,
        "yyyy-MM-dd"
      )}"]`;
    }

    function CustomDateFilter() {
      return `["created_on","must","lte","range","${format(
        new Date(createdDate[1]),
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        new Date(createdDate[0]),
        "yyyy-MM-dd"
      )}"]`;
    }

    if (createdDatelistSelected === "Today") {
      filter.push(TodayFilter());
    }

    if (createdDatelistSelected === "Previous Day") {
      filter.push(PreviousFilter());
    }

    if (createdDatelistSelected === "Last 2 Days") {
      filter.push(Last2DaysFilter());
    }
    if (createdDatelistSelected === "Last 7 Days") {
      filter.push(Last7DaysFilter());
    }
    if (createdDatelistSelected === "Last 30 Days") {
      filter.push(Last30DaysFilter());
    }

    if (
      createdDatelistSelected === "Custom" &&
      createdDate &&
      createdDate[0] &&
      createdDate[1]
    ) {
      filter.push(CustomDateFilter());
    }

    params = {
      page_limit: `${page_limit}`,
      page_number: `${value}` - 1,
      filters: `[${filter}]`,
      // order_by: `[["${props.sort_by}", "${props.sort_order}"]]`,
    };

    if (value - 1 !== props.pagination.current_page_no) {
      fetchAllMentions(params);
    }
  };

  const sentiment_value_list = ["Positive", "Negative", "Neutral"];

  // dateselectionpopper
  const [dateSelecte] = useState([
    "Last 2 Days",
    "Today",
    "Previous Day",
    "Last 7 Days",
    "Last 30 Days",
    "Custom",
  ]);

  const [dateSelected] = useState([
    "Last 2 Days",
    "Today",
    "Previous Day",
    "Last 7 Days",
    "Last 30 Days",
    "Custom",
  ]);

  let [open_ticket, set_open_ticket] = useState(null);
  let [circuler_progress_id, set_circuler_pogress_id] = useState(null);

  const openTicketFunction = (ticket_id) => {
    set_circuler_pogress_id(ticket_id);
    let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

    let searchQuery = ticket_id;
    let searchTypeSelected = "Ticket-ID";
    let selected_status;
    let activities = [];
    let assigned_To = null;
    let createdDate = [null, null];
    let modifiedDate = [null, null];
    let modifiedDatelistSelected = null;
    let createdDatelistSelected = null;
    let platform = null;
    let priority = null;
    let selected = [];
    let sentiment_value = null;
    let tags = [];
    let category = null;

    set_open_ticket(true);

    let API = `${environment.api.baseApi}/ticket/${ticket_id}`;

    axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      set_open_ticket(false);
      selected_status =
        response &&
        response._source &&
        response._source.status &&
        response._source.status.status_id;

      props.setValue(0);
      props.setSearchTypeSelected(searchTypeSelected);

      let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
      if (filters) {
        filters.searchQuery = searchQuery;
        filters.searchTypeSelected = searchTypeSelected;
        filters.selected_status = selected_status;
        filters.pageNumber = `0`;
        filters.activities = activities;
        filters.assigned_To = assigned_To;
        filters.createdDate = createdDate;
        filters.createdDatelistSelected = createdDatelistSelected;
        filters.modifiedDate = modifiedDate;
        filters.modifiedDatelistSelected = modifiedDatelistSelected;
        filters.platform = platform;
        filters.priority = priority;
        filters.selected = selected;
        filters.sentiment_value = sentiment_value;
        filters.tags = tags;
        filters.category = category;

        localStorage.setItem("mentionsFilters", JSON.stringify(filters));
      } else {
        localStorage.setItem(
          "mentionsFilters",
          JSON.stringify({
            searchQuery,
            searchTypeSelected,
            selected_status,
            activities,
            assigned_To,
            createdDate,
            createdDatelistSelected,
            modifiedDate,
            modifiedDatelistSelected,
            platform,
            priority,
            selected,
            sentiment_value,
            tags,
            category,
          })
        );
      }
    });
  };

  const ratings_value_list = ["1", "2", "3", "4", "5"];

  //Export button function
  const handleExport = () => {
    exportFilter(filterContents);
  };

  return (
    <Box height={"100%"}>
      <Box
        sx={{
          width: "100%",
          backgroundColor: theme.palette.background.default,
          marginBottom: 4,
        }}
      >
        <MentionsFilters
          open={props.open}
          onClose={props.onClose}
          sentiment_value_list={sentiment_value_list}
          ratings_value_list={ratings_value_list}
          dateSelecte={dateSelecte}
          dateSelected={dateSelected}
          customerName={allCustomersList}
          accountName={allAccountNamesList}
          filteredPlatformList={filteredPlatformList}
          clearFilter={(value) => {
            if (value === "date") {
              setCreatedDatelistSelected(null);
              setCreatedDate([null, null]);
              setModifiedDatelistSelected(null);
              setModifiedDate([null, null]);
            }
            if (value === "platform") {
              setPlatform(null);
              setActivities([]);
            }
            if (value === "category") {
              setCategory(null);
            }
            if (value === "agent") {
              setAssigned_To(null);
              SetUnAssigned(false);
            }
            if (value === "sentiment") {
              setSentimental_val(null);
            }
            if (value === "keyword") {
              setKeyword_config(false);
            }

            if (value === "rating") {
              setRating_val(null);
            }

            // setSearchQuery(null)
            // setTags([]);
            // setSearchTypeSelected(null);
          }}
          saveFilter={(filters) => {
            !filters.searchQuery && props.setSearchQuery(null);
            !filters.createdDatelistSelected &&
              setCreatedDatelistSelected(null);
            !filters.createdDate && setCreatedDate([null, null]);
            !filters.modifiedDatelistSelected &&
              setModifiedDatelistSelected(null);
            !filters.modifiedDate && setModifiedDate([null, null]);
            !filters.platform && setPlatform(null);
            !filters.activities && setActivities([]);
            !filters.sentiment_value && setSentimental_val(null);
            !filters.keywordConfig && setKeyword_config(null);
            !filters.sentimentValue && setRating_val(null);
            !filters.searchTypeSelected && setSearchTypeSelected(null);
          }}
          applyFilter={applyFilter}
          filterContents={filterContents}
          setFilterContents={setFilterContents}
        />

        <Box
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.default,
            fontSize: "16px",
            position: "sticky",
            top: 0,
            zIndex: 99,
          }}
        >
          <Grid
            item
            container
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            justifyContent="space-between"
            alignItems={"center"}
            sx={{
              backgroundColor: `${theme.palette.background.primary}`,
              lineHeight: 2.69,
              padding: 0,
              margin: 0,
            }}
          >
            <Grid item>
              <Grid item container alignItems={"center"}>
                <Grid item className="mx-2" sx={{ fontWeight: "bold" }}>
                  Total Mentions ({getTotalMentions()})
                </Grid>

                <Grid
                  item
                  // xl={2} lg={2} md={2} sm={3} xs={3}
                >
                  <ToggleButtonGroup
                    value={sentimental_val}
                    exclusive
                    onChange={handleSentiment}
                    aria-label="priority"
                    sx={{
                      "&.MuiToggleButtonGroup-root": {
                        // height: "40px",
                      },
                    }}
                  >
                    {sentiment_index.map((item) => (
                      <ToggleButton
                        value={item ? item : null}
                        aria-label="laptop"
                        style={{
                          border: "none",
                          padding: 0,
                          margin: 0,
                          textTransform: "none",
                        }}
                        sx={{
                          "&.MuiToggleButton-root": {
                            // height: "10px",
                          },
                          "&.Mui-selected": {
                            fontWeight: "bold",
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <ListItem>
                          <Box>{renderSentimentEmoji(item)}</Box>
                          <Grid
                            xl={3}
                            lg={3}
                            md={3}
                            sm={3}
                            title={
                              item
                                ? `${item}  
                            // (${returnTicketPriorityCount(item)})
                            `
                                : null
                            }
                          >
                            <span style={{ display: "flex" }}>
                              <p className="p-0 m-0 ml-1">
                                {item
                                  ? item.length > 10
                                    ? item.substring(0, 10 - 3) + "..."
                                    : item
                                  : null}
                              </p>
                              {/* <p className="p-0 m-0 ml-1">
                            {item && `(${returnTicketPriorityCount(item)})`}
                          </p> */}
                            </span>
                          </Grid>
                        </ListItem>
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Grid>
                <Grid
                  item
                  className="ml-2"
                  // xl={2} lg={2} md={2} sm={2} xs={3}
                >
                  <MentionsDateSelectionPopper
                    dateSelecte={dateSelecte}
                    date={createdDate}
                    setDate={setCreatedDate}
                    setPageNumber={setPageNumber}
                    createdDatelistSelected={createdDatelistSelected}
                    setCreatedDatelistSelected={setCreatedDatelistSelected}
                    setModifiedDate={setModifiedDate}
                    setModifiedDatelistSelected={setModifiedDatelistSelected}
                    applyFilter={applyFilter}
                    filterContents={filterContents}
                    setFilterContents={setFilterContents}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                item
                container
                gap={1}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <Grid item>
                  <Box>
                    <Tooltip title="Keyword Config" arrow placement="left">
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            sx={{
                              margin: 0,
                              padding: 0,
                              color: `${theme.palette.ticketListView.checkbox}`,
                              marginRight: 1,
                            }}
                            checked={keyword_config}
                            label="jshdjkbhj"
                            onChange={async (e) => {
                              const newValue = e.target.checked;
                              if (newValue) {
                                setKeyword_config(newValue);
                                setPageNumber(0);

                                let filters = JSON.parse(
                                  localStorage.getItem("allMentionsParameters")
                                );
                                filters.keywordConfig = newValue;
                                localStorage.setItem(
                                  "allMentionsParameters",
                                  JSON.stringify(filters)
                                );

                                let object_new = {};

                                Object.assign(
                                  object_new,
                                  filterContents
                                    ? {
                                        ...filterContents,
                                        keywordConfig: newValue,
                                      }
                                    : {
                                        keywordConfig: newValue,
                                      }
                                );

                                setFilterContents(
                                  filterContents
                                    ? {
                                        ...filterContents,
                                        keywordConfig: newValue,
                                      }
                                    : {
                                        keywordConfig: newValue,
                                      }
                                );

                                applyFilter(object_new);
                              } else {
                                {
                                  setKeyword_config(newValue);
                                  setPageNumber(0);

                                  let filters = JSON.parse(
                                    localStorage.getItem(
                                      "allMentionsParameters"
                                    )
                                  );
                                  filters.keywordConfig = newValue;
                                  localStorage.setItem(
                                    "allMentionsParameters",
                                    JSON.stringify(filters)
                                  );

                                  let object_new = {};

                                  Object.assign(
                                    object_new,
                                    filterContents
                                      ? {
                                          ...filterContents,
                                          keywordConfig: newValue,
                                        }
                                      : {
                                          keywordConfig: newValue,
                                        }
                                  );

                                  setFilterContents(
                                    filterContents
                                      ? {
                                          ...filterContents,
                                          keywordConfig: newValue,
                                        }
                                      : {
                                          keywordConfig: newValue,
                                        }
                                  );

                                  applyFilter(object_new);
                                }
                              }
                            }}
                          />
                        }
                        label={
                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: keyword_config ? "bold" : "normal",
                            }}
                          >
                            Keyword config
                          </span>
                        }
                      />
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item sx={{ fontWeight: "bold" }} textAlign={"right"}>
                  <MuiButton
                    name={"Export"}
                    onClick={handleExport}
                    loading={props.exportRequesting}
                  />
                </Grid>
              </Grid>{" "}
            </Grid>
          </Grid>
        </Box>

        {(accountName !== null ||
          customerName !== null ||
          searchQuery !== null ||
          (props.selectionModel && props.selectionModel.length !== 0) ||
          sentimental_val !== null ||
          keyword_config !== null ||
          rating_val !== null ||
          createdDatelistSelected !== null ||
          platform !== null ||
          (activities && activities.length !== 0) ||
          (createdDate && createdDate[0] !== null)) && (
          <Box
            height="auto"
            sx={{
              position: "sticky",
              top: 43,
              zIndex: 10,
            }}
          >
            <Grid
              item
              container
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              alignItems={"center"}
              justifyContent="flex-start"
              sx={{
                backgroundColor: `white`,
                height: "100%",
                padding: 0,
                margin: 0,
                // borderBottom: "1px solid gray",
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                fontSize: "14px",
              }}
            >
              {platform && (
                <Grid sx={{ paddingLeft: 1, paddingY: "5px" }}>
                  <Grid>
                    Platform :{" "}
                    <Chip
                      label={platform}
                      onDelete={() => {
                        let filters = JSON.parse(
                          localStorage.getItem("allMentionsParameters")
                        );
                        filters.platform = null;
                        filters.activities = null;
                        filters.ratingValue = null;
                        //filters.pageNumber = `0`;

                        setPlatform(null);
                        setActivities(null);
                        setPageNumber(0);

                        let object_new = {};

                        Object.assign(
                          object_new,
                          filterContents
                            ? {
                                ...filterContents,
                                platform: null,
                                activities: null,
                                ratingValue: null,
                              }
                            : {
                                platform: null,

                                activities: null,
                                ratingValue: null,
                              }
                        );

                        setFilterContents(
                          filterContents
                            ? {
                                ...filterContents,
                                platform: null,

                                activities: null,
                                ratingValue: null,
                              }
                            : {
                                platform: null,

                                activities: null,
                                ratingValue: null,
                              }
                        );

                        applyFilter(object_new);

                        localStorage.setItem(
                          "allMentionsParameters",
                          JSON.stringify(filters)
                        );
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                </Grid>
              )}
              {activities && activities.length !== 0 && (
                <Grid sx={{ paddingLeft: 1, paddingY: "5px" }}>
                  <Grid>
                    Activities :{" "}
                    {activities &&
                      activities.length !== 0 &&
                      activities.map((activity) => (
                        <Chip
                          style={{
                            marginRight: 2,
                          }}
                          label={activity?.activity_name}
                          onDelete={async () => {
                            let filters = JSON.parse(
                              localStorage.getItem("allMentionsParameters")
                            );
                            let updatedActivities = activities.filter(
                              (obj) => obj !== activity
                            );

                            updatedActivities =
                              updatedActivities.length === 0
                                ? null
                                : updatedActivities;
                            if (
                              platform === "GMB" &&
                              updatedActivities === null
                            )
                              updatedActivities = ["Reviews"];
                            filters.activities = updatedActivities;

                            setActivities(updatedActivities);
                            setPageNumber(0);

                            let object_new = {};

                            Object.assign(
                              object_new,
                              filterContents
                                ? {
                                    ...filterContents,
                                    activities: null,
                                  }
                                : {
                                    activities: null,
                                  }
                            );

                            setFilterContents(
                              filterContents
                                ? {
                                    ...filterContents,
                                    activities: null,
                                  }
                                : {
                                    activities: null,
                                  }
                            );

                            applyFilter(object_new);

                            localStorage.setItem(
                              "allMentionsParameters",
                              JSON.stringify(filters)
                            );
                          }}
                          variant="outlined"
                          size="small"
                        />
                      ))}
                  </Grid>
                </Grid>
              )}
              {accountName && (
                <Grid sx={{ paddingLeft: 1, paddingY: "5px" }}>
                  <Grid>
                    Account Name :{" "}
                    <Chip
                      label={accountName}
                      onDelete={() => {
                        let filters = JSON.parse(
                          localStorage.getItem("allMentionsParameters")
                        );
                        filters.accountName = null;

                        setAccountName(null);
                        setPageNumber(0);

                        let object_new = {};

                        Object.assign(
                          object_new,
                          filterContents
                            ? {
                                ...filterContents,
                                accountName: null,
                              }
                            : {
                                accountName: null,
                              }
                        );

                        setFilterContents(
                          filterContents
                            ? {
                                ...filterContents,
                                accountName: null,
                              }
                            : {
                                accountName: null,
                              }
                        );
                        applyFilter(object_new);

                        localStorage.setItem(
                          "allMentionsParameters",
                          JSON.stringify(filters)
                        );
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                </Grid>
              )}
              {customerName && (
                <Grid sx={{ paddingLeft: 1, paddingY: "5px" }}>
                  <Grid>
                    Customer Name :{" "}
                    <Chip
                      label={customerName}
                      onDelete={() => {
                        let filters = JSON.parse(
                          localStorage.getItem("allMentionsParameters")
                        );
                        filters.customerName = null;

                        setCustomerName(null);
                        setPageNumber(0);

                        let object_new = {};

                        Object.assign(
                          object_new,
                          filterContents
                            ? {
                                ...filterContents,
                                customerName: null,
                              }
                            : {
                                customerName: null,
                              }
                        );

                        setFilterContents(
                          filterContents
                            ? {
                                ...filterContents,
                                customerName: null,
                              }
                            : {
                                customerName: null,
                              }
                        );
                        applyFilter(object_new);

                        localStorage.setItem(
                          "allMentionsParameters",
                          JSON.stringify(filters)
                        );
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                </Grid>
              )}
              {createdDatelistSelected && (
                <Grid sx={{ paddingLeft: 1, paddingY: "5px" }}>
                  {createdDatelistSelected &&
                  createdDatelistSelected !== "Custom" ? (
                    <Grid>
                      Created Date :{" "}
                      <Chip
                        label={createdDatelistSelected}
                        onDelete={async () => {
                          let filters = JSON.parse(
                            localStorage.getItem("allMentionsParameters")
                          );
                          filters.createdDatelistSelected = null;

                          setCreatedDatelistSelected(null);
                          setPageNumber(0);

                          let object_new = {};

                          Object.assign(
                            object_new,
                            filterContents
                              ? {
                                  ...filterContents,
                                  createdDatelistSelected: null,
                                }
                              : {
                                  createdDatelistSelected: null,
                                }
                          );

                          setFilterContents(
                            filterContents
                              ? {
                                  ...filterContents,
                                  createdDatelistSelected: null,
                                }
                              : {
                                  createdDatelistSelected: null,
                                }
                          );
                          applyFilter(object_new);

                          localStorage.setItem(
                            "allMentionsParameters",
                            JSON.stringify(filters)
                          );
                        }}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                  ) : (
                    createdDate &&
                    createdDate[0] && (
                      <Grid>
                        Created Date :{" "}
                        <Chip
                          label={`${new Date(createdDate[0]).toLocaleDateString(
                            "en-US"
                          )} - ${new Date(createdDate[1]).toLocaleDateString(
                            "en-US"
                          )}`}
                          onDelete={async () => {
                            let filters = JSON.parse(
                              localStorage.getItem("allMentionsParameters")
                            );
                            filters.createdDatelistSelected = null;
                            filters.createdDate = [null, null];
                            setPageNumber(0);
                            setCreatedDatelistSelected(null);
                            setCreatedDate([null, null]);

                            let object_new = {};

                            Object.assign(
                              object_new,
                              filterContents
                                ? {
                                    ...filterContents,
                                    createdDatelistSelected: null,
                                    createdDate: [null, null],
                                  }
                                : {
                                    createdDatelistSelected: null,
                                    createdDate: [null, null],
                                  }
                            );

                            setFilterContents(
                              filterContents
                                ? {
                                    ...filterContents,
                                    createdDatelistSelected: null,
                                    createdDate: [null, null],
                                  }
                                : {
                                    createdDatelistSelected: null,
                                    createdDate: [null, null],
                                  }
                            );
                            applyFilter(object_new);

                            localStorage.setItem(
                              "allMentionsParameters",
                              JSON.stringify(filters)
                            );
                          }}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              )}
              {sentimental_val && (
                <Grid sx={{ paddingLeft: 1, paddingY: "5px" }}>
                  <Grid>
                    Sentiment Value :{" "}
                    <Chip
                      label={sentimental_val}
                      onDelete={async () => {
                        let filters = JSON.parse(
                          localStorage.getItem("allMentionsParameters")
                        );
                        filters.sentimentValue = null;

                        setSentimental_val(null);
                        setPageNumber(0);

                        let object_new = {};

                        Object.assign(
                          object_new,
                          filterContents
                            ? {
                                ...filterContents,
                                sentimentValue: null,
                              }
                            : {
                                sentimentValue: null,
                              }
                        );

                        setFilterContents(
                          filterContents
                            ? {
                                ...filterContents,
                                sentimentValue: null,
                              }
                            : {
                                sentimentValue: null,
                              }
                        );
                        applyFilter(object_new);

                        localStorage.setItem(
                          "allMentionsParameters",
                          JSON.stringify(filters)
                        );
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                </Grid>
              )}{" "}
              {keyword_config && (
                <Grid sx={{ paddingLeft: 1, paddingY: "5px" }}>
                  <Grid>
                    <Chip
                      label={keyword_config ? "Keyword config" : null}
                      onDelete={async () => {
                        let filters = JSON.parse(
                          localStorage.getItem("allMentionsParameters")
                        );
                        filters.keywordConfig = false;

                        setKeyword_config(false);
                        setPageNumber(0);

                        let object_new = {};

                        Object.assign(
                          object_new,
                          filterContents
                            ? {
                                ...filterContents,
                                keywordConfig: false,
                              }
                            : {
                                keywordConfig: false,
                              }
                        );

                        setFilterContents(
                          filterContents
                            ? {
                                ...filterContents,
                                keywordConfig: false,
                              }
                            : {
                                keywordConfig: false,
                              }
                        );
                        applyFilter(object_new);

                        localStorage.setItem(
                          "allMentionsParameters",
                          JSON.stringify(filters)
                        );
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                </Grid>
              )}
              {rating_val && (
                <Grid sx={{ paddingLeft: 1, paddingY: "5px" }}>
                  <Grid>
                    Rating :{" "}
                    <Chip
                      label={rating_val}
                      onDelete={async () => {
                        let filters = JSON.parse(
                          localStorage.getItem("allMentionsParameters")
                        );
                        filters.ratingValue = null;

                        setRating_val(null);
                        setPageNumber(0);

                        let object_new = {};

                        Object.assign(
                          object_new,
                          filterContents
                            ? {
                                ...filterContents,
                                ratingValue: null,
                              }
                            : {
                                ratingValue: null,
                              }
                        );

                        setFilterContents(
                          filterContents
                            ? {
                                ...filterContents,
                                ratingValue: null,
                              }
                            : {
                                ratingValue: null,
                              }
                        );
                        applyFilter(object_new);

                        localStorage.setItem(
                          "allMentionsParameters",
                          JSON.stringify(filters)
                        );
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                </Grid>
              )}
              {searchQuery && (
                <Grid sx={{ paddingLeft: 1, paddingY: "5px" }}>
                  <Grid>
                    Search :{" "}
                    <Chip
                      label={searchQuery}
                      onDelete={async () => {
                        let filters = JSON.parse(
                          localStorage.getItem("allMentionsParameters")
                        );
                        filters.searchQuery = null;
                        filters.searchTypeSelected = "Mention-ID";
                        filters.pageNumber = `0`;

                        setSearchTypeSelected("Mention-ID");
                        setSearchQuery(null);
                        setPageNumber(0);

                        let object_new = {};

                        Object.assign(
                          object_new,
                          filterContents
                            ? {
                                ...filterContents,
                                searchTypeSelected: "Mention-ID",
                                searchQuery: null,
                              }
                            : {
                                searchTypeSelected: "Mention-ID",
                                searchQuery: null,
                              }
                        );

                        setFilterContents(
                          filterContents
                            ? {
                                ...filterContents,
                                searchTypeSelected: "Mention-ID",
                                searchQuery: null,
                              }
                            : {
                                searchTypeSelected: "Mention-ID",
                                searchQuery: null,
                              }
                        );

                        applyFilter(object_new);

                        props.setSearchQuery("");
                        localStorage.setItem(
                          "allMentionsParameters",
                          JSON.stringify(filters)
                        );
                      }}
                      sx={{
                        width: "auto",
                        maxWidth: "70vw",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                </Grid>
              )}
              {props.selectionModel && props.selectionModel.length > 0 && (
                <Grid sx={{ paddingLeft: 1, paddingY: "5px" }}>
                  <Grid>
                    Selected Feeds :{" "}
                    {props.selectionModel && (
                      <Chip
                        label={props.selectionModel.length}
                        onDelete={async () => {
                          props.setSelectionModel([]);
                          set_Selected_tickets([]);
                          FetchFunction();
                          localStorage.getItem(
                            "selected_mentions_for_bulk_update"
                          ) &&
                            localStorage.removeItem(
                              "selected_mentions_for_bulk_update"
                            );
                        }}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        )}

        <Box>
          {!isRequesting &&
          props.allMentions.hits &&
          props?.allMentions?.hits.length !== 0 ? (
            props?.allMentions?.hits.map((item) => {
              return (
                <LoadingIndicator isActive={isRequesting}>
                  {
                    // (item._index === "facebook_webhook" &&
                    //   item._source.comments.length !== 0)
                    // ||
                    // item._index !== "facebook_webhook" &&
                    <Box
                      sx={{
                        padding: 0,
                        //margin: "1.2%",
                        marginTop: 0,
                        //marginBottom: "0.5%",
                        fontSize: "14px",
                      }}
                    >
                      <List
                        sx={{
                          width: "100%",
                          paddingTop: "1px",
                          paddingBottom: "1px",
                        }}
                      >
                        <Grid
                          container
                          lg={12}
                          md={12}
                          sm={12}
                          sx={{
                            // height: "auto",
                            borderTopLeftRadius: theme.borderRadius,
                            borderTopRightRadius: theme.borderRadius,
                            backgroundColor: `${theme.palette.background.paper}`,
                            border: `solid 0.5px ${theme.palette.background.primary}`,
                            width: "100%",
                          }}
                        >
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            container
                            sx={{
                              border: `solid 0.5px ${theme.palette.background.default}`,
                            }}
                          >
                            <Grid
                              lg={6}
                              md={6}
                              sm={6}
                              container
                              alignItems={"center"}
                              gap={1}
                              item
                              justifyContent={"flex-start"}
                            >
                              <Grid item sm={3}>
                                <ListItem
                                  sx={{
                                    paddingY: 0,
                                    paddingRight: 0,
                                    paddingLeft: "5px",
                                  }}
                                >
                                  <div style={{ paddingBottom: "2px" }}>
                                    {returnPlatformDropdownLogos(
                                      item._source.platform &&
                                        item._source.platform.platform_name
                                        ? item._source.platform.platform_name
                                        : item._source.platform
                                    )}
                                  </div>
                                  &nbsp;&nbsp;
                                  {item._source.platform === "Twitter"
                                    ? item._source.tweet_type
                                    : item._source.platform === "Instagram" ||
                                      item._source.platform === "LinkedIn" ||
                                      item._source.platform === "Indeed" ||
                                      item._source.platform === "Youtube" ||
                                      item._source.platform === "Appstore" ||
                                      item._source.platform === "Glassdoor" ||
                                      "Ambition Box" ||
                                      item._source.platform ===
                                        "Consumer Complaints" ||
                                      item._source.platform === "Discord" ||
                                      item._source.platform === "Telegram" ||
                                      item._source.platform === "Quora" ||
                                      item._source.platform === "Email" ||
                                      item._source.platform === "GMB" ||
                                      item._source.platform === "Reddit" ||
                                      item._source.platform === "Whatsapp"
                                    ? item._source.activities
                                    : ""}
                                  {item._source.platform === "Quora" &&
                                    !item._source.activities &&
                                    "Questions Answers"}
                                  {/* {item._index === "facebook_webhook" &&
                                    item._source.activities} */}
                                  {item._index === "twitter_direct_messages" &&
                                    "Direct Message"}
                                </ListItem>
                              </Grid>
                              <Grid
                                item
                                lg={4}
                                sm={6}
                                md={5}
                                sx={{ justifyContent: "flex-start" }}
                                onMouseEnter={(e) => {
                                  handleMouseHoverStateChange(
                                    item._source.customer_id,
                                    item._source.customer_name,
                                    item._source.platform,
                                    item._source.platform == "Twitter"
                                      ? item._source.customer_handle
                                      : null,
                                    item._source.followers_count,
                                    item._source.follows_count,
                                    item._source
                                  );
                                }}
                              >
                                <ListItem
                                  sx={{
                                    paddingX: 0,
                                    paddingTop: "9px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    fontWeight: "bold",
                                    // width: "150px",
                                  }}
                                >
                                  <ListItemAvatar>
                                    <Avatar
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                      sx={{ marginLeft: "auto" }}
                                    ></Avatar>
                                  </ListItemAvatar>
                                  <div>
                                    <Typography
                                      aria-owns={
                                        customer_popover_open
                                          ? "mouse-over-popover"
                                          : undefined
                                      }
                                      aria-haspopup="true"
                                      onMouseEnter={handlePopoverOpen}
                                      onMouseLeave={handlePopoverClose}
                                      onWheel={handlePopoverClose}
                                    >
                                      &nbsp;
                                      {item._source.platform === "GMB" &&
                                      item._source.activities === "Reviews"
                                        ? item._source.reviewer_name
                                        : item._source.platform === "GMB" &&
                                          item._source.activities ===
                                            "Questions Answers"
                                        ? item._source.question_author_name
                                        : item._source.customer_name}
                                      {item._index ===
                                        "twitter_direct_messages" &&
                                        item._source.customer_handle}
                                      {item._index ===
                                        "twitter_direct_messages" &&
                                        item._source.customer_handle}
                                      {item._index ===
                                        "facebook_direct_messages" &&
                                        item._source.sender_name}
                                    </Typography>

                                    {!Object.values(customerPopOver).every(
                                      (value) => value === null
                                    ) && (
                                      <Popover
                                        classes={{
                                          paper: classes.popover,
                                        }}
                                        id="mouse-over-popover"
                                        sx={{
                                          pointerEvents: "none",
                                          marginTop: 1,
                                        }}
                                        open={customer_popover_open}
                                        anchorEl={customer_popover_anchorEl}
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "left",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "left",
                                        }}
                                        onClose={handlePopoverClose}
                                        disableRestoreFocus
                                      >
                                        <Typography
                                          sx={{
                                            p: 0.5,
                                            // boxShadow: `${theme.shadows}`,
                                          }}
                                        >
                                          <Box width={"auto"} minWidth={160}>
                                            <Grid
                                              item
                                              container
                                              xl={12}
                                              lg={12}
                                              md={12}
                                              sm={12}
                                              xs={12}
                                              justifyContent="flex-start"
                                              alignItems={"center"}
                                            >
                                              <Grid
                                                item
                                                xl={2}
                                                lg={2}
                                                md={2}
                                                sm={2}
                                                xs={2}
                                              >
                                                <Avatar
                                                  style={{
                                                    width: "24px",
                                                    height: "24px",
                                                  }}
                                                  // src={
                                                  //   customer_id.customer_profile_url &&
                                                  //   customer_id.customer_profile_url
                                                  // }
                                                >
                                                  {customerPopOver.customerName &&
                                                    customerPopOver.customerName.charAt(
                                                      0
                                                    ) &&
                                                    customerPopOver.customerName.charAt(
                                                      0
                                                    )}
                                                </Avatar>
                                              </Grid>

                                              <Grid
                                                item
                                                xl={9}
                                                lg={9}
                                                md={9}
                                                sm={9}
                                                xs={9}
                                              >
                                                <p
                                                  className="p-0 m-0 ml-1"
                                                  style={{
                                                    fontSize: "12px",
                                                  }}
                                                  customer_handel
                                                >
                                                  {customerPopOver.customerName &&
                                                    customerPopOver.customerName}
                                                </p>
                                                <p
                                                  className="p-0 m-0 ml-1"
                                                  style={{
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  @
                                                  {customerPopOver.platform ==
                                                  "Twitter"
                                                    ? customerPopOver.userName
                                                    : customerPopOver.customerName}
                                                </p>
                                                {/* </>
                                                )} */}
                                              </Grid>
                                            </Grid>

                                            <hr className="p-0 m-0" />

                                            <Grid
                                              container
                                              sx={{
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              {customerPopOver.followers && (
                                                <Box sx={{ fontSize: "12px" }}>
                                                  {customerPopOver.followers}
                                                  &nbsp;Followers
                                                </Box>
                                              )}
                                              {customerPopOver.following && (
                                                <Box sx={{ fontSize: "12px" }}>
                                                  {customerPopOver.following}
                                                  &nbsp;Following
                                                </Box>
                                              )}
                                            </Grid>
                                          </Box>
                                        </Typography>
                                      </Popover>
                                    )}
                                  </div>
                                  &nbsp;
                                  {item._source.platform === "Twitter"
                                    ? `(@${
                                        item._source.customer_handel ||
                                        item._source.customer_handle
                                      })`
                                    : ""}
                                </ListItem>
                              </Grid>

                              <Grid item sm={2}>
                                <ListItem
                                  sx={{
                                    paddingY: 0,
                                    paddingRight: 0,
                                    paddingLeft: "5px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item._source.from_keyword
                                    ? "Keyword Config"
                                    : null}
                                </ListItem>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              lg={6}
                              md={6}
                              sm={6}
                              //alignItems="flex-end"
                              // sx={{ alignItems: "flex-end" }}
                              justifyContent="flex-end"
                              sx={{ fontWeight: "normal" }}
                            >
                              <Grid item>
                                <ListItem>
                                  {item._source.platform === "Appstore" ||
                                  item._source.platform === "Playstore"
                                    ? item._source.app_name
                                    : item._source.account_name}
                                  {item._index === "instagram_direct" &&
                                    item._source.account_username}
                                  {/* {item._source.account_name} */}
                                  &nbsp;
                                </ListItem>
                              </Grid>
                              <Grid
                                item
                                alignItems="center"
                                justifyContent="center"
                                textAlign="right"
                              >
                                <ListItem>
                                  <AccessTimeIcon
                                    style={{
                                      width: "16px",
                                      marginRight: 2,
                                      color: `${theme.palette.ticketListView.iconColor}`,
                                    }}
                                  />
                                  <p className="m-0 p-0">
                                    {item._index !==
                                      "twitter_direct_messages" &&
                                    item._index !==
                                      "facebook_direct_messages" &&
                                    timeAgo.format(
                                      new Date(item._source.created_on)
                                    ).length > 12
                                      ? timeAgo
                                          .format(
                                            new Date(item._source.created_on)
                                          )
                                          .substring(0, 12 - 2)
                                      : item._index !==
                                          "twitter_direct_messages" &&
                                        item._index !==
                                          "facebook_direct_messages" &&
                                        timeAgo.format(
                                          new Date(item._source.created_on)
                                        )}

                                    {(item._index ===
                                      "twitter_direct_messages" ||
                                      item._index ===
                                        "facebook_direct_messages") &&
                                    timeAgo.format(
                                      new Date(item._source.message_at)
                                    ).length > 12
                                      ? timeAgo
                                          .format(
                                            new Date(item._source.message_at)
                                          )
                                          .substring(0, 12 - 2)
                                      : (item._index ===
                                          "twitter_direct_messages" ||
                                          item._index ===
                                            "facebook_direct_messages") &&
                                        timeAgo.format(
                                          new Date(item._source.message_at)
                                        )}

                                    {/* {item._index === "facebook_webhook" &&
                                    item._source?.comment_created_at &&
                                    timeAgo.format(
                                      new Date(item._source?.comment_created_at)
                                    ).length > 12
                                      ? timeAgo
                                          .format(
                                            new Date(
                                              item._source?.comment_created_at
                                            )
                                          )
                                          .substring(0, 12 - 2)
                                      : item._index === "facebook_webhook" &&
                                        item._source?.comment_created_at &&
                                        timeAgo.format(
                                          new Date(
                                            item._source?.comment_created_at
                                          )
                                        )} */}
                                  </p>
                                </ListItem>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          sx={{
                            //border: `solid 0.5px ${theme.palette.background.primary}`,
                            borderLeft: `solid 0.5px ${theme.palette.background.primary}`,
                            borderRight: `solid 0.5px ${theme.palette.background.primary}`,
                            backgroundColor: `${theme.palette.background.paper}`,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            item._source &&
                              item._source.ticket_id &&
                              openTicketFunction(item._source.ticket_id);
                          }}
                          className="two-line-paragraph"
                        >
                          <ListItem sx={{ paddingLeft: "18px" }}>
                            <Typography
                              sx={{
                                backgroundColor: `${theme.palette.background.paper}`,
                              }}
                            >
                              {item._source.platform === "Appstore" ||
                              item._source.platform === "Ambition Box" ||
                              item._source.platform === "Indeed" ? (
                                <>
                                  Rating :{" "}
                                  {returnStarsIcon(item._source.rating)} <br />
                                </>
                              ) : null}
                              {item._source.platform === "Playstore" ? (
                                <>
                                  Rating :{" "}
                                  {returnStarsIcon(item._source.ratings)} <br />
                                </>
                              ) : null}
                              {item._source.platform === "Glassdoor" ? (
                                <>
                                  Rating :{" "}
                                  {returnStarsIcon(item._source.overallRating)}{" "}
                                  <br />
                                </>
                              ) : null}
                              {item._source.platform === "GMB" &&
                              item._source.activities === "Reviews" ? (
                                <>
                                  Rating :{" "}
                                  {returnStarsIcon(item._source.review_rating)}{" "}
                                  <br />
                                </>
                              ) : null}

                              {item._source.platform === "Youtube" &&
                                item._source.activities === "Videos" &&
                                item._source.video_title && (
                                  <>
                                    <strong>Title:</strong>{" "}
                                    {item._source.video_title} <br />
                                  </>
                                )}

                              {item._source.platform === "Youtube" &&
                                item._source.activities === "Videos" &&
                                item._source.video_description && (
                                  <>
                                    <strong>Description:</strong>{" "}
                                    {item._source.video_description}
                                  </>
                                )}

                              {item._source.platform === "Twitter" ? (
                                item._source.content_of_the_tweet
                                  ?.replace(/&lt;/g, "<")
                                  .replace(/&gt;/g, ">")
                                  .replace(/&amp;/g, "&")
                              ) : (item._source.platform === "Youtube" &&
                                  item?._source?.activities === "Comments") ||
                                (item._source.platform === "LinkedIn" &&
                                  item?._source?.activities === "Comment") ? (
                                item._source.comment_content
                              ) : item._source.platform === "Appstore" ? (
                                item._source.review
                              ) : item._source.platform === "Playstore" ? (
                                item._source.review_content
                              ) : item._source.platform === "Glassdoor" ? (
                                item._source.review
                              ) : item._source.platform === "Ambition Box" ? (
                                <>
                                  {" "}
                                  Title: {item._source.title}
                                  <br />
                                  Pros: {item._source.likes}
                                  <br />
                                  Cons: {item._source.dislikes}
                                </>
                              ) : item._source.platform === "Indeed" ? (
                                item._source.rating_description
                              ) : item._source.platform ===
                                "Consumer Complaints" ? (
                                <>
                                  <strong>Title:</strong> {item._source.title}
                                  <br />
                                  <strong>Description:</strong>{" "}
                                  {item._source.description}
                                </>
                              ) : item._source.platform === "Discord" ||
                                item._source.platform === "Telegram" ? (
                                item._source.message_content
                              ) : item._source.platform === "Quora" ? (
                                <>
                                  <strong>Question:</strong>{" "}
                                  {item._source.question
                                    ? item._source.question
                                    : item._source.question_text}
                                  <br />
                                  {item._source.answer && (
                                    <strong>Answer:</strong>
                                  )}{" "}
                                  {item._source.answer}
                                </>
                              ) : item._source.platform === "Email" ? (
                                item._source.subject
                              ) : item._source.platform === "GMB" &&
                                item._source.activities === "Reviews" ? (
                                item._source.review_comment
                              ) : item._source.platform === "GMB" &&
                                item._source.activities ===
                                  "Questions Answers" ? (
                                item._source.question
                              ) : item._source.platform === "LinkedIn" &&
                                item?._source?.activities === "Post Mention" ? (
                                item?._source.post_content
                              ) : item._source.platform === "Reddit" ? (
                                <>
                                  <strong>Title:</strong> {item._source.title}
                                  <br />
                                  <strong>Description:</strong>{" "}
                                  {item._source.description}
                                </>
                              ) : item._source.platform === "Whatsapp" ? (
                                item._source.message_content
                              ) : (
                                //item.comment_content
                                ""
                              )}

                              {item._source.platform === "Threads" &&
                                item._source.thread_content}

                              {item._source.platform === "Instagram" &&
                              (item._source.activities === "Tagged Posts" ||
                                item._source.activities === "Captions Mention")
                                ? item._source.parent_post_media_url && (
                                    <Box width="100%">
                                      {item?._source
                                        ?.parent_post_mediya_type ===
                                      "IMAGE" ? (
                                        <Box
                                          width="100%"
                                          sx={{ padding: "10px" }}
                                        >
                                          <RenderMedia
                                            image_url={
                                              item?._source
                                                .parent_post_media_url
                                            }
                                            mediaType="img"
                                            height="100"
                                          />
                                        </Box>
                                      ) : (
                                        item?._source
                                          .parent_post_mediya_type ===
                                          "VIDEO" && (
                                          <Box
                                            width="100%"
                                            sx={{ padding: "10px" }}
                                          >
                                            <RenderMedia
                                              image_url={
                                                item?._source
                                                  .parent_post_media_url
                                              }
                                              mediaType="video"
                                              height="100"
                                            />
                                          </Box>
                                        )
                                      )}
                                    </Box>
                                  )
                                : null}

                              {item._source.platform === "Instagram" &&
                              (item._source.activities === "Tagged Posts" ||
                                item._source.activities ===
                                  "Captions Mention") ? (
                                item._source.parent_post_caption ? (
                                  <p>{item._source.parent_post_caption}</p>
                                ) : null
                              ) : null}

                              {item._source.platform === "Instagram" &&
                                item._source.activities !==
                                  "Captions Mention" &&
                                item._source.activities !== "Tagged Posts" &&
                                item._source.comment_content}
                              {item._source.platform === "LinkedIn" &&
                                item._source.activities ===
                                  "Post Mention Comments" &&
                                item._source.comment_content}

                              {item._index === "instagram_direct" &&
                                item._source.message_content}

                              {item._index === "facebook_webhook" &&
                                (() => {
                                  const { activities, media, post_media } =
                                    item._source;

                                  const isMediaAttached =
                                    (activities === "Comments" &&
                                      media?.media_url) ||
                                    (activities === "Post Mentions" &&
                                      post_media?.length) ||
                                    (activities === "Comment Mentions" &&
                                      media?.media_url);

                                  return isMediaAttached ? (
                                    <Box width="100%">
                                      <Chip
                                        label="Media Attached"
                                        sx={{
                                          "& .MuiChip-label": {
                                            fontWeight: "bold",
                                          },
                                        }}
                                      />
                                    </Box>
                                  ) : null;
                                })()}

                              {item._index === "facebook_webhook" &&
                                (item._source.activities === "Comments"
                                  ? item._source.comment_content
                                  : item._source.activities === "Post Mentions"
                                  ? item._source.post_content
                                  : item._source.activities ===
                                    "Comment Mentions"
                                  ? item._source.comment_content
                                  : "")}

                              {item._index === "twitter_direct_messages" ||
                              (item._index === "facebook_direct_messages" &&
                                item._source.message_text)
                                ? item._source.message_text
                                : ""}
                            </Typography>
                          </ListItem>
                        </Grid>
                        {translatedDescription.includes(item._id) && (
                          <Grid
                            item
                            sx={{
                              backgroundColor: `${theme.palette.background.light}`,
                              border: `1px solid ${theme.palette.background.light}`,
                              borderRadius: "3px",
                              padding: "5px",
                              marginY: "5px",
                            }}
                          >
                            <Grid
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography component={"p"}>
                                Translated to English
                              </Typography>
                              <Typography
                                component={"p"}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  cursor: "pointer",
                                }}
                                onClick={(event) => {
                                  event.stopPropagation();

                                  let translatedIds = [
                                    ...translatedDescription,
                                  ];

                                  if (translatedIds.includes(item._id)) {
                                    translatedIds.splice(
                                      translatedIds.indexOf(item._id),
                                      1
                                    );
                                  } else {
                                    translatedIds.push(item._id);
                                  }
                                  setTranslatedDescription([
                                    ...new Set(translatedIds),
                                  ]);
                                }}
                              >
                                <HighlightOffOutlinedIcon
                                  titleAccess="Close"
                                  sx={{ width: "12px" }}
                                />
                              </Typography>
                            </Grid>

                            <TranslatedContent
                              content={
                                item._source.platform === "Twitter"
                                  ? item._source.content_of_the_tweet
                                  : item._source.platform === "LinkedIn"
                                  ? item._source.comment_content
                                  : item._source.platform === "Appstore"
                                  ? item._source.review
                                  : item._source.platform === "Playstore"
                                  ? item._source.review_content
                                  : item._source.platform === "Glassdoor"
                                  ? item._source.reviews
                                  : item._source.platform === "Ambition Box"
                                  ? item._source.title
                                  : item._source.platform === "Threads"
                                  ? item._source.thread_content
                                  : item._source.platform === "Indeed"
                                  ? item._source.rating_description
                                  : item._source.platform ===
                                    "Consumer Complaints"
                                  ? item._source.description
                                  : item._source.platform === "Discord" ||
                                    item._source.platform === "Telegram"
                                  ? item._source.message_content
                                  : item._source.platform === "Quora"
                                  ? item._source.question
                                  : item._source.platform === "Email"
                                  ? item._source.subject
                                  : item._source.platform === "Facebook"
                                  ? item._source.post_content
                                  : item._source.platform === "GMB" &&
                                    activities &&
                                    activities === "Reviews"
                                  ? item._source.review_comment
                                  : item._source.platform === "GMB" &&
                                    activities &&
                                    activities === "Questions Answers"
                                  ? item._source.question
                                  : item._source.platform === "Reddit"
                                  ? item._source.title
                                  : item._source.platform === "Whatsapp"
                                  ? item._source.message_content
                                  : item._source.platform === "Youtube"
                                  ? item._source.activities === "Comments"
                                    ? item._source.comment_content
                                    : item._source.video_title +
                                      "\n" +
                                      item._source.video_description
                                  : item._source.platform === "Instagram"
                                  ? item._source.activities ===
                                    "Captions Mention"
                                    ? item._source.parent_post_caption
                                    : item._source.activities === "Comments"
                                    ? item?._source?.comment_content
                                    : item._source.activities ===
                                      "Comment Mentions"
                                    ? item?._source?.comment_content
                                    : item._source.activities === "Tagged Posts"
                                    ? item?._source?.parent_post_caption
                                    : null
                                  : ""
                              }
                            />
                          </Grid>
                        )}
                        {/* Bottom Section */}
                        <Grid
                          container
                          lg={12}
                          md={12}
                          sm={12}
                          sx={{
                            backgroundColor: `#f3f9fb`,
                            width: "100%",
                            border: `solid 0.5px ${theme.palette.background.primary}`,
                          }}
                          // justifyContent="space-between"
                          // alignItems="flex-start"
                        >
                          <Grid
                            container
                            lg={6}
                            md={8}
                            sm={6}
                            alignItems="center"
                          >
                            <Grid
                              item
                              alignItems="center"
                              justifyContent="center"
                              textAlign="center"
                              sx={
                                {
                                  //borderRight: `1px solid ${theme.palette.background.primary}`,
                                }
                              }
                            >
                              <ListItem
                                sx={{
                                  borderRight: `1px solid ${theme.palette.background.primary}`,
                                  paddingLeft: "16px",
                                }}
                              >
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  sx={{
                                    width: 6,
                                    height: 6,
                                    color: `${theme.palette.primary.light}`,
                                  }}
                                  defaultChecked={returnCheckBoxValue(item)}
                                  onChange={(e) => {
                                    let selectedTickets = [
                                      ...props.selectionModel,
                                    ];
                                    let index = selectedTickets.findIndex(
                                      (obj) => obj._id === item._id
                                    );

                                    if (e.target.checked === true) {
                                      if (index === -1) {
                                        selectedTickets.push(item);
                                      }
                                    }

                                    if (e.target.checked === false) {
                                      if (index !== -1) {
                                        selectedTickets.splice(index, 1);
                                      }
                                    }

                                    props.setSelectionModel([
                                      ...new Set(selectedTickets),
                                    ]);

                                    localStorage.setItem(
                                      "selected_mentions_for_bulk_update",
                                      JSON.stringify([
                                        ...new Set(selectedTickets),
                                      ])
                                    );
                                  }}
                                />
                              </ListItem>
                            </Grid>
                            <Grid
                              item
                              alignItems="center"
                              justifyContent={"center"}
                              textAlign="center"
                            >
                              <Tooltip title="Mention Id">
                                <ListItem
                                  className="py-0 m-0 px-1"
                                  sx={{
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                    borderRight: `1px solid ${theme.palette.background.primary}`,
                                  }}
                                >
                                  <IconButton
                                    color="primary"
                                    onClick={(event) => {
                                      mentioncopyToClipboard(item._id);
                                    }}
                                    sx={{
                                      color: `${theme.palette.ticketListView.iconColor}`,
                                    }}
                                  >
                                    <FeedIcon
                                      fontSize="small"
                                      style={{
                                        width: "15px",
                                      }}
                                    />
                                  </IconButton>
                                  <Snackbar
                                    message="Mention Id copied to clipboard"
                                    sx={{ height: "70%" }}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                    autoHideDuration={500}
                                    onClose={() => setmentioncopied(false)}
                                    open={mentioncopied}
                                  />
                                </ListItem>
                              </Tooltip>
                            </Grid>
                            {/* <Grid
                              item
                              alignItems="center"
                              justifyContent={"center"}
                              textAlign="center"
                            >
                              <Tooltip title="Ticket Id">
                                <ListItem
                                  className="py-0 m-0 px-1"
                                  sx={{
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                    borderRight: `1px solid ${theme.palette.background.primary}`,
                                  }}
                                >
                                  <IconButton
                                    color="primary"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      copyToClipboard(item._source.ticket_id);
                                    }}
                                    sx={{
                                      color: `${theme.palette.ticketListView.iconColor}`,
                                    }}
                                  >
                                    <FileCopyIcon
                                      fontSize="small"
                                      style={{
                                        width: "13px",
                                      }}
                                    />
                                  </IconButton>
                                  <Snackbar
                                    message="Ticket Id copied to clipboard"
                                    sx={{ height: "70%" }}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                    autoHideDuration={500}
                                    onClose={() => setCopied(false)}
                                    open={copied}
                                  />
                                </ListItem>
                              </Tooltip>
                            </Grid> */}

                            {item._index !== "facebook_direct_messages" &&
                              typeof item._source.sentiment_value ===
                                "number" && (
                                <Grid
                                  item
                                  alignItems="center"
                                  justifyContent="center"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                  data-testid="mentionSentiment"
                                >
                                  <SentimentValueUpdatePopper
                                    priorityList={filterprioritylist}
                                    renderPriorityColor={renderPriorityColor}
                                    ticketId={item._id}
                                    fetchFunction={() => {
                                      FetchFunction();
                                    }}
                                    feedData={item}
                                    from="feeds"
                                    platform={
                                      item._source.platform
                                        ? item._source.platform
                                        : item._index
                                    }
                                    feedId={item._id}
                                  >
                                    <Typography
                                      sx={{
                                        borderRight: `1px solid ${theme.palette.background.primary}`,
                                        paddingRight: "7px",
                                        paddingLeft: "7px",
                                      }}
                                    >
                                      {item._source.sentiment_value &&
                                      item._source.sentiment_value > 0 ? (
                                        <SentimentSatisfiedAltRoundedIcon
                                          titleAccess="Positive"
                                          style={{
                                            width: "16px",
                                            color: `green`,
                                          }}
                                        />
                                      ) : item._source.sentiment_value < 0 ? (
                                        <SentimentDissatisfiedRoundedIcon
                                          titleAccess="Negative"
                                          style={{
                                            width: "16px",
                                            color: `${theme.palette.sentimentIconColor.negative}`,
                                          }}
                                        />
                                      ) : item._source.sentiment_value !==
                                        null ? (
                                        <SentimentNeutralRoundedIcon
                                          titleAccess="Neutral"
                                          style={{
                                            width: "16px",
                                            color: `${theme.palette.sentimentIconColor.neutral}`,
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Typography>
                                  </SentimentValueUpdatePopper>
                                </Grid>
                              )}
                            {item._source.custom_value &&
                              item._source.custom_value.length >= 0 && (
                                <div>
                                  {returntrueCategoryValues(item) ? (
                                    <Grid
                                      item
                                      alignItems="center"
                                      justifyContent={"center"}
                                      textAlign="center"
                                      sx={{
                                        borderRight: `1px solid ${theme.palette.background.primary}`,
                                      }}
                                      title={
                                        returnCustomValues(
                                          item._source.custom_value
                                        ) &&
                                        returnCustomValuesAsString(
                                          item._source.custom_value
                                        )
                                      }
                                    >
                                      <ListItem className="py-0 m-0 px-1">
                                        <StyleIcon
                                          style={{
                                            width: "16px",
                                            marginRight: 2,
                                            color: `${theme.palette.ticketListView.iconColor}`,
                                            color: `${theme.palette.ticketListView.iconColor}`,
                                          }}
                                        />
                                        <p
                                          className="m-0 p-0"
                                          style={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            width: "100px",
                                            cursor: "pointer",
                                          }}
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            setSendEmailFeedData(item);

                                            openCloseModal(!sendEmailOpen);
                                          }}
                                        >
                                          {returnCustomValues(
                                            item._source.custom_value
                                          ) &&
                                            returnCustomValuesAsString(
                                              item._source.custom_value
                                            )}
                                        </p>
                                      </ListItem>
                                    </Grid>
                                  ) : (
                                    <Grid
                                      item
                                      alignItems="center"
                                      justifyContent={"center"}
                                      textAlign="center"
                                      sx={{
                                        borderRight: `1px solid ${theme.palette.background.primary}`,
                                      }}
                                    >
                                      <ListItem className="py-0 m-0 px-1">
                                        <StyleIcon
                                          style={{
                                            width: "16px",
                                            marginRight: 2,
                                            color: `${theme.palette.ticketListView.iconColor}`,
                                            color: `${theme.palette.ticketListView.iconColor}`,
                                          }}
                                        />
                                        <p
                                          className="m-0 p-0"
                                          style={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            width: "100px",
                                            cursor: "pointer",
                                          }}
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            setSendEmailFeedData(item);
                                            openCloseModal(!sendEmailOpen);
                                          }}
                                        >
                                          Select Category
                                        </p>
                                      </ListItem>
                                    </Grid>
                                  )}
                                </div>
                              )}
                          </Grid>
                          <Grid
                            lg={6}
                            md={4}
                            sm={6}
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Grid
                              item
                              justifyContent="center"
                              alignItems="center"
                              sx={{
                                //border: `1px solid ${theme.palette.background.primary}`,
                                borderRadius: "5px",
                              }}
                              paddingX={1}
                            >
                              <ListItem
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setSendEmailFeedData(item);

                                  openCloseModal(!sendEmailOpen);
                                }}
                                className="px-2 m-0"
                                sx={{
                                  height: 27,
                                  display: "flex",
                                  textAlign: "center",
                                  cursor: "pointer",
                                  backgroundColor: `${theme.palette.background.paper}`,
                                  border: `1px solid ${theme.palette.background.primary}`,
                                  borderRadius: "5px",
                                }}
                              >
                                <EmailOutlinedIcon
                                  style={{
                                    width: "16px",
                                    marginRight: 2,
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                  }}
                                />
                                <Typography
                                  fontSize={{
                                    xl: "14px",
                                    lg: "12px",
                                    md: "11px",
                                    laptop: "11px",
                                  }}
                                  display={{
                                    xl: "block",
                                    lg: "block",
                                    md: "none",
                                    sm: "none",
                                    laptop: "none",
                                  }}
                                >
                                  <p className="m-0 p-0">Send Email</p>
                                </Typography>
                              </ListItem>
                            </Grid>
                            {visibleOpenLinkBtn(item) && (
                              <Grid
                                item
                                sx={{
                                  backgroundColor: `${theme.palette.background.paper}`,
                                  border: `1px solid ${theme.palette.background.primary}`,
                                  borderRadius: "5px",
                                }}
                                paddingX={1}
                              >
                                <ListItem
                                  className="p-0 m-0"
                                  sx={{
                                    height: 25,
                                    display: "flex",
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    OpenNewWindow(item);
                                  }}
                                >
                                  <OpenInNewIcon
                                    style={{
                                      width: "16px",
                                      marginRight: 2,
                                      color: `${theme.palette.ticketListView.iconColor}`,
                                    }}
                                  />
                                  <Typography
                                    fontSize={{
                                      xl: "14px",
                                      lg: "12px",
                                      md: "11px",
                                      laptop: "11px",
                                    }}
                                    display={{
                                      xl: "block",
                                      lg: "block",
                                      md: "none",
                                      sm: "none",
                                      laptop: "none",
                                    }}
                                  >
                                    <p className="m-0 p-0">Open Link</p>
                                  </Typography>
                                </ListItem>
                              </Grid>
                            )}
                            {/* {item._source &&
                              item._source.customer_id &&
                              item._source.customer_id !== null && (
                                <Grid
                                  item
                                  sx={{
                                    backgroundColor:
                                      theme.palette.background.paper,
                                    border: `1px solid ${theme.palette.background.primary}`,
                                    borderRadius: "5px",
                                    marginLeft: "5px",
                                  }}
                                  paddingX={1}
                                >
                                  <Tooltip
                                    title={
                                      item._source.customer_id &&
                                      item._source.customer_id.is_influencer
                                        ? "UnMark as Influencer"
                                        : "Mark as Influencer"
                                    }
                                  >
                                    <ListItem
                                      className="p-0 m-0"
                                      sx={{
                                        height: 25,
                                        display: "flex",
                                        textAlign: "center",
                                        cursor: "pointer",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        handleInfluencerPut(item);
                                      }}
                                    >
                                      {item._source.customer_id &&
                                      item._source.customer_id.is_influencer ? (
                                        <>
                                          <CancelIcon
                                            style={{
                                              width: "16px",
                                              marginRight: 2,
                                              color: `${theme.palette.ticketListView.iconColor}`,
                                            }}
                                          />
                                          <Typography
                                            fontSize={{
                                              xl: "14px",
                                              lg: "12px",
                                              md: "11px",
                                              laptop: "11px",
                                            }}
                                            display={{
                                              xl: "block",
                                              lg: "block",
                                              md: "none",
                                              sm: "none",
                                              laptop: "none",
                                            }}
                                          >
                                            <p className="m-0 p-0">
                                              UnMark as Influencer
                                            </p>
                                          </Typography>
                                        </>
                                      ) : (
                                        <>
                                          <CheckCircleOutlineIcon
                                            style={{
                                              width: "16px",
                                              marginRight: 2,
                                              color: "red",
                                              color: `${theme.palette.ticketListView.iconColor}`,
                                            }}
                                          />
                                          <Typography
                                            fontSize={{
                                              xl: "14px",
                                              lg: "12px",
                                              md: "11px",
                                              laptop: "11px",
                                            }}
                                            display={{
                                              xl: "block",
                                              lg: "block",
                                              md: "block",
                                              sm: "none",
                                              laptop: "none",
                                            }}
                                          >
                                            <p className="m-0 p-0">
                                              Mark as Influencer
                                            </p>
                                          </Typography>
                                        </>
                                      )}
                                    </ListItem>
                                  </Tooltip>
                                </Grid>
                              )} */}
                            <Grid item paddingX={1}>
                              <Tooltip title="Translate">
                                <ListItem
                                  className="p-0 m-0"
                                  sx={{
                                    height: 25,
                                    display: "flex",
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    let translatedIds = [
                                      ...translatedDescription,
                                    ];

                                    if (translatedIds.includes(item._id)) {
                                      translatedIds.splice(
                                        translatedIds.indexOf(item._id),
                                        1
                                      );
                                    } else {
                                      translatedIds.push(item._id);
                                    }
                                    setTranslatedDescription([
                                      ...new Set(translatedIds),
                                    ]);
                                  }}
                                >
                                  <GTranslateIcon
                                    style={{
                                      width: "16px",
                                      color: translatedDescription.includes(
                                        item._id
                                      )
                                        ? `${theme.palette.primary.main}`
                                        : `${theme.palette.ticketListView.iconColor}`,
                                    }}
                                  />
                                </ListItem>
                              </Tooltip>
                            </Grid>{" "}
                          </Grid>
                        </Grid>
                      </List>
                    </Box>
                  }
                </LoadingIndicator>
              );
            })
          ) : isRequesting ? (
            <Box
              sx={{
                width: "100%",
                height: "80vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingBgImage />
            </Box>
          ) : (
            <NoDataFound />
          )}
        </Box>
      </Box>

      {/* pagination  */}
      <Box
        width={"100%"}
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
          backgroundColor: `${theme.palette.background.default}`,
        }}
      >
        <Grid
          item
          container
          // className="bg-success"
          xl={12}
          lg={12}
          sm={12}
          md={12}
          xs={12}
          justifyContent="flex-end"
          alignItems={"center"}
        >
          <PaginationUI
            pagelength={props.pagination.total_page_no}
            page={props.pagination.current_page_no}
            handleChange={handleChangePagination}
          />
        </Grid>

        <SendMentionsEmail
          open={sendEmailOpen}
          onClose={() => {
            setSendEmailOpen(false);
          }}
          ticketData={sendEmailFeedData && sendEmailFeedData}
          setSendEmailOpen={setSendEmailOpen}
          customticketfields={[]}
          fetchFunction={() => {
            // fetchFunction(pageNumber);
          }}
        />
      </Box>
    </Box>
  );
};

export default connect(mapStateToProps)(AllmentionsMain);
