import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import { Form, Formik, Field, FieldArray, ErrorMessage } from "formik";
import * as yup from "yup";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  RowIcons_key_conf,
  returnPlatformDropdownLogos,
} from "../../../components/ticketPartials";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import MuiButton from "../../../components/muiButton/MuiButton";
import KeywordConfigAction from "../../stores/keywordConfiguration/KeywordConfigAction";
import MuiTextField from "../../../components/muiTextField/MuiTextField";

const mapDispatchToProps = (dispatch) => {
  return {};
};

function KeywordConfigurationForm(props) {
  let initial = {};
  if (props.edit) {
    initial = {
      config_name: props.editData.config_name,
      rule_group: [
        {
          keywords: [],
        },
      ],
      exclude: [
        {
          keywords: [],
        },
      ],
      location: props.editData.location,
      account: props.editData.account,
    };
  } else {
    initial = {
      config_name: "",
      location: "",
      account: [],
      rule_group: [
        {
          keywords: [],
        },
      ],
      exclude: [
        {
          keywords: [],
        },
      ],
    };
  }

  // const validationSchema = yup.object({});
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [accountsSelected, setAccountsSelected] = useState([]);
  const [saved, setSaved] = useState(true);
  useEffect(() => {
    if (accountsSelected && accountsSelected.length !== 0) {
      setSaved(false);
    } else {
      setSaved(true);
    }
  }, [accountsSelected]);

  // checkbox selection
  const handleChange = (event, setFieldValue) => {
    const value = event.target.value;
    if (selected.indexOf(value) !== -1) {
      // if value already present
      const newSelected = selected.filter((s) => s !== value);
      setSelected(newSelected);

      const filteredAccounts = accountsSelected.filter((account) =>
        newSelected.includes(account.platform_id)
      );

      let uniqueSet = new Set([...filteredAccounts].map(JSON.stringify));
      // form value account field updating

      setFieldValue("account", Array.from(uniqueSet).map(JSON.parse));
    } else {
      // If value is newly selected, add it to selected
      const newSelected = [...selected, value];
      setSelected(newSelected);

      // Filter accountsSelected based on the new selected value
      // Filter accounts that match the selected platform_id
      const filteredAccounts = props.all_accounts
        .filter(
          (account) => account._source?.platform === getPlatformName(value)
        )
        .map((item) => ({
          account_id: item._id,
          platform_id: value,
          account_name: item._source.account_name,
        }));

      if (filteredAccounts?.length === 1) {
        const updatedAccounts = [...filteredAccounts, ...accountsSelected];

        // Ensure uniqueness
        const uniqueSet = new Set(updatedAccounts.map(JSON.stringify));

        const performedArray = Array.from(uniqueSet).map(
          (item) => JSON.parse(item).account_id
        );
        setSelectionModel(performedArray);
        setAccountsSelected(Array.from(uniqueSet).map(JSON.parse));
        setFieldValue("account", Array.from(uniqueSet).map(JSON.parse));
      }
    }
  };
  useEffect(() => {
    const filteredAccounts = accountsSelected.filter((account) =>
      selected.includes(account.platform_id)
    );

    if (filteredAccounts.length !== accountsSelected.length) {
      setAccountsSelected(filteredAccounts);
    }

    const filteredSelectionModel = filteredAccounts.map(
      (account) => account.account_id
    );
    setSelectionModel(filteredSelectionModel);
  }, [selected]);
  useEffect(() => {
    setSaved(selectionModel.length === 0);
  }, [selectionModel]);
  useEffect(() => {
    if (props.open && props.edit) {
      const InitialRuleGroupArray = [];
      if (props.editData.rule_group) {
        props.editData.rule_group.map((item) => {
          return InitialRuleGroupArray.push({
            keywords: item.keywords.toString(),
          });
        });
      }

      initial.rule_group = InitialRuleGroupArray;

      const InitialExcludeWords = [];
      if (props.editData.exclude) {
        props.editData.exclude.map((item) => {
          return InitialExcludeWords.push({
            keywords: item.keywords.toString(),
          });
        });
      }

      initial.exclude = InitialExcludeWords;

      const selectedPlatform = [];
      const selectedAccounts = [];
      props.editData.account.length > 0 &&
        props.editData.account.map((item) => {
          return (
            selectedPlatform.push(item.platform_id),
            selectedAccounts.push(item.account_id)
          );
        });

      setSelected([...new Set(selectedPlatform)]);
      setSelectionModel([...new Set(selectedAccounts)]);
      const initialAccountsSelected = props.editData.account.map((item) => ({
        account_id: item.account_id,
        platform_id: item.platform_id,
        account_name: item.account_name,
      }));

      setAccountsSelected(initialAccountsSelected);
    }
  }, [props.open]);

  function getPlatformName(platformId) {
    return (
      props.platforms.length > 0 &&
      props.platforms.filter((item) => item.platform_id === platformId)[0]
        ?.platform_name
    );
  }

  function AccountNameList(setFieldValue, values, errors) {
    let rows = [];

    selected.map((platformId) => {
      props.all_accounts
        .filter((item) => item._source.platform === getPlatformName(platformId))
        .map((item) => {
          rows.push({
            account_id: item._id,
            platform_id: platformId,
            account_name: item._source.account_name,
          });
        });
    });

    const handleCheckboxChange = (event, row) => {
      let newSelection = [...selectionModel];

      if (event.target.checked) {
        newSelection.push(row.account_id);
      } else {
        newSelection = newSelection.filter((id) => id !== row.account_id);
      }
      setSelectionModel(newSelection);
      const selectedRows = rows.filter((row) =>
        newSelection.includes(row.account_id)
      );
      setAccountsSelected(selectedRows);

      let uniqueSet = new Set([...selectedRows].map(JSON.stringify));
      // form value account field updating
      setFieldValue("account", Array.from(uniqueSet).map(JSON.parse));
    };

    let columns = [
      {
        field: "select",
        headerName: "Select",
        flex: 4,
        sortable: false,
        renderCell: (params) => (
          <Checkbox
            checked={selectionModel.includes(params.row.account_id)}
            onChange={(event) => handleCheckboxChange(event, params.row)}
          />
        ),
      },
      {
        field: "account_id",
        headerName: "Account ID",
        width: 200,
        hide: true,
      },
      {
        field: "platform_id",
        headerName: "Platform ID",
        width: 100,
        hide: true,
      },
      {
        field: "account_name",
        headerName: "Account Name / Page name",
        flex: 8,

        renderCell: (params) => (
          <>
            {returnPlatformDropdownLogos(
              getPlatformName(params.row.platform_id)
            )}
            <span className="text-wrap">{params.row.account_name}</span>
          </>
        ),
      },
    ];

    return (
      <Grid container item lg={12}>
        <DataGridFunction
          columns={columns}
          rows={rows}
          rowHeight={80}
          height={300}
          getRowId={(item) => item.account_id}
          getRowHeight={() => "auto"}
          selectionModel={selectionModel}
          initialState={{
            columns: {
              columnVisibilityModel: {
                account_id: false,
                platform_id: false,
              },
            },
          }}
          onSelectionModelChange={(ids) => {
            // Get selected row objects based on IDs
            const selectedRows = rows.filter((row) =>
              ids.includes(row.account_id)
            );

            setAccountsSelected(selectedRows);
            setSelectionModel(ids);
          }}
          customText={"No Accounts Available"}
          customFooter={CustomFooter}
          emptyNoData
        />
        <Box px={1} sx={{ color: "#e93017" }}>
          <ErrorMessage name="account" />
        </Box>
        {/* <Grid
          item
          lg={12}
          sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
        >
          <MuiButton
            name={"Save"}
            disabled={saved ? true : false}
            onClick={async () => {
              let uniqueSet = new Set(
                [...accountsSelected].map(JSON.stringify)
              );
              // form value account field updating
              setFieldValue("account", Array.from(uniqueSet).map(JSON.parse));

              setSaved(true);
            }}
          />
       
        </Grid> */}
      </Grid>
    );
  }

  let enabledPlatform = ["1", "9", "2"];

  const returnlistItem = (setFieldValue) => {
    const listItem = props.platforms
      ? props.platforms.length >= 0 &&
        props.platforms
          .filter((item) => {
            return enabledPlatform.includes(item.platform_id);
          })
          .map((option) => {
            return (
              <Grid
                item
                xl={6}
                container
                md={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      value={option.platform_id}
                      label={option.platform_id}
                      onChange={(e) => {
                        handleChange(e, setFieldValue);
                      }}
                      checked={selected.includes(option.platform_id)}
                    />
                  }
                  label={option.platform_alias}
                />{" "}
              </Grid>
            );
          })
      : null;

    return listItem;
  };

  const oncloseFunction = () => {
    props.onClose();
    setSelected([]);
    setAccountsSelected([]);
    setSelectionModel([]);
  };

  const CustomFooter = () => {
    const accounts = [];

    selected.forEach((platformId) => {
      props.all_accounts
        .filter((item) => item._source.platform === getPlatformName(platformId))
        .forEach((item) => {
          accounts.push({
            account_id: item._id,
            platform_id: platformId,
            account_name: item._source.account_name,
          });
        });
    });

    const existingPlatforms = [
      ...new Set(accounts.map((item) => item.platform_id)),
    ];

    const missingPlatforms = selected.filter(
      (id) => !existingPlatforms.includes(id)
    );

    return missingPlatforms.length > 0 ? (
      <Box
        sx={{ width: "100%", textAlign: "center", p: 2, bgcolor: "#f5f5f5" }}
      >
        <Typography variant="body2" color="textSecondary">
          No accounts Available for{" "}
          {missingPlatforms.map(getPlatformName).join(", ")} platform(s).
        </Typography>
      </Box>
    ) : null;
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        oncloseFunction();
      }}
      fullWidth
      maxWidth="md"
    >
      <Formik
        initialValues={initial}
        // validationSchema={validationSchema}
        validate={(values) => {
          var errors = {};

          if (!values.config_name) {
            errors.config_name = "Configuration name is required";
          }
          if (
            !values.rule_group[0].keywords.length &&
            !values.exclude[0].keywords.length
          ) {
            errors.rule_group = "Required";
            errors.exclude = "Required";
          }

          if (
            values.rule_group[0].keywords.length &&
            !values.exclude[0].keywords.length
          ) {
            delete errors.exclude;
          }
          if (
            values.exclude[0].keywords.length &&
            !values.rule_group[0].keywords.length
          ) {
            delete errors.rule_group;
          }
          // if (!values.location) {
          //   errors.location = "Location is required";
          // }
          // if (!values.rule_group[0].keywords.length) {
          //   errors.rule_group = "Add atleast one keywords rule group";
          // }
          // if (!values.exclude[0].keywords.length) {
          //   errors.exclude = "Add atleast one Exclude keywords";
          // }
          if (
            !values.account.length ||
            (accountsSelected && accountsSelected).length === 0
          ) {
            errors.account = "Select atleast one Account in any platform";
          }
          return errors;
        }}
        onSubmit={(values, formikHelpers) => {
          let obj = {};

          let showAlert = false;

          if (values?.exclude?.some((item) => item.keywords.length == 1)) {
            showAlert = true;
          }

          if (values?.rule_group?.some((item) => item.keywords.length == 1)) {
            showAlert = true;
          }

          if (showAlert) {
            alert("Some items have keywords with length less than 2.");
          } else {
            Object.assign(
              obj,
              values.config_name && { config_name: values.config_name },
              values.location && { location: values.location },
              values.rule_group[0].keywords.length !== 0
                ? {
                    rule_group: values.rule_group
                      .map((item) => {
                        if (
                          item?.keywords !== "" &&
                          item?.keywords?.length >= 2
                        ) {
                          return {
                            keywords: item.keywords
                              ?.split(",")
                              .map((keyword) => keyword.trim()),
                          };
                        }
                      })
                      .filter(Boolean),
                  }
                : {
                    rule_group: [{ keywords: [] }],
                  },
              values.exclude[0].keywords.length !== 0
                ? {
                    exclude: values.exclude
                      .map((item) => {
                        if (
                          item?.keywords !== "" &&
                          item?.keywords?.length >= 2
                        ) {
                          return {
                            keywords: item.keywords
                              ?.split(",")
                              .map((keyword) => keyword.trim()),
                          };
                        }
                      })
                      .filter(Boolean),
                  }
                : {
                    exclude: [{ keywords: [] }],
                  },
              values.account && {
                account:
                  accountsSelected && accountsSelected.length !== 0
                    ? values.account.map((item) => {
                        return {
                          account_id: item.account_id,
                          platform_id: item.platform_id,
                        };
                      })
                    : [],
              }
            );

            if (!props.edit) {
              setLoading(true);
              props
                .dispatch(
                  KeywordConfigAction.requestPostKeywordConfig(
                    JSON.stringify(obj)
                  )
                )
                .then(() => {
                  const params = {
                    page_limit: "15",
                    order_by: '[["created_on", "desc"]]',
                  };

                  formikHelpers.resetForm();
                  setSelected([]);
                  setAccountsSelected([]);
                  props.onClose();
                  setLoading(false);

                  props.dispatch(
                    KeywordConfigAction.requestKeywordConfiguration(params)
                  );

                  let params_filter = {
                    page_limit: "none",
                  };

                  props.dispatch(
                    KeywordConfigAction.requestKeywordConfigurationFilter(
                      params_filter
                    )
                  );
                });
            } else {
              setLoading(true);

              props
                .dispatch(
                  KeywordConfigAction.requestPutKeywordConfig(
                    JSON.stringify(obj),
                    props.editData.rowData._id
                  )
                )
                .then(() => {
                  const params = {
                    page_limit: "none",
                    order_by: '[["modified_on", "desc"]]',
                  };

                  formikHelpers.resetForm();
                  setSelected([]);
                  setAccountsSelected([]);
                  props.onClose();
                  setLoading(false);

                  props.dispatch(
                    KeywordConfigAction.requestKeywordConfiguration(params)
                  );

                  let params_filter = {
                    page_limit: "none",
                  };

                  props.dispatch(
                    KeywordConfigAction.requestKeywordConfigurationFilter(
                      params_filter
                    )
                  );
                });
            }
          }
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
          resetForm,
          setFieldValue,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "100%" }}>
                <Box width="100%" mb={3}>
                  <Grid
                    container
                    xl={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xl={11} textAlign="center">
                      <h3 className="dailogtext">
                        {!props.edit ? (
                          <b>
                            <span className="span_first_letter">
                              &nbsp;&nbsp;A
                            </span>
                            dd &nbsp;
                            <span className="span_first_letter">K</span>
                            eyword &nbsp;
                            <span className="span_first_letter">C</span>
                            onfiguration
                          </b>
                        ) : (
                          <b>
                            <span className="span_first_letter">
                              &nbsp;&nbsp;E
                            </span>
                            dit &nbsp;
                            <span className="span_first_letter">K</span>
                            eyword &nbsp;
                            <span className="span_first_letter">C</span>
                            onfiguration
                          </b>
                        )}
                      </h3>
                    </Grid>
                    <Grid item xl={1} textAlign="right">
                      <div
                        className="col-2 closebtn"
                        onClick={() => {
                          setSelected([]);
                          setAccountsSelected([]);
                          setSelectionModel([]);
                          // resetForm();
                          // setSaved(true);
                          props.onClose();
                        }}
                      >
                        <i
                          title="Close"
                          className="fa fa-times-circle-o fa-2x"
                          style={{ size: "15px" }}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContentText>
              <Grid container>
                <Grid
                  container
                  item
                  lg={selected.length > 0 ? 6 : 12}
                  md={selected.length > 0 ? 6 : 12}
                  xl={selected.length > 0 ? 6 : 12}
                  sm={selected.length > 0 ? 6 : 12}
                  rowGap={2}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xl={12}
                    container
                    sx={{ display: "flex", width: "100%" }}
                  >
                    {returnlistItem(setFieldValue)}
                  </Grid>
                </Grid>
                {selected.length === 0 && (
                  <Box px={1} sx={{ color: "#e93017", mb: 1 }}>
                    <ErrorMessage name="account" />
                  </Box>
                )}

                {/* ACCOUNT IDS DATAGRID  */}

                {selected.length > 0 && (
                  <Grid
                    container
                    itemlg={selected.length > 0 ? 6 : 12}
                    md={selected.length > 0 ? 6 : 12}
                    xl={selected.length > 0 ? 6 : 12}
                    sm={selected.length > 0 ? 6 : 12}
                  >
                    <Grid item lg={12} md={12} xl={12} sm={12}>
                      {AccountNameList(setFieldValue, values, errors)}
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} mt={1}>
                    Configuration Name * :
                  </Grid>
                  <Grid item lg={9} md={9} sm={9}>
                    <Field
                      as={MuiTextField}
                      autoFocus
                      variant="outlined"
                      placeholder="Configuration name"
                      id="config_name"
                      size="small"
                      name="config_name"
                      error={
                        Boolean(errors.config_name) &&
                        Boolean(touched.config_name)
                      }
                      helperText={
                        Boolean(touched.config_name) && errors.config_name
                      }
                    />
                  </Grid>
                </Grid>

                {/* <Grid item lg={12} md={12} sm={12} mt={1} container>
                  <Grid item lg={3} md={3} sm={3} mt={1}>
                    Geo Location * :
                  </Grid>
                  <Grid item lg={9} md={9} sm={9}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Location"
                      id="location"
                      size="small"
                      name="location"
                      error={
                        Boolean(errors.location) && Boolean(touched.location)
                      }
                      helperText={Boolean(touched.location) && errors.location}
                    />
                  </Grid>
                </Grid> */}

                <Grid item lg={12} md={12} sm={12} container>
                  <FieldArray name="rule_group">
                    {({ insert, remove, push }) => (
                      <Grid
                        container
                        lg={12}
                        md={12}
                        sm={12}
                        mt={1}
                        // px={1}
                        className="d-flex justify-content-center"
                      >
                        {values.rule_group &&
                          values.rule_group.length > 0 &&
                          values.rule_group.map((rule, index) => (
                            <Grid
                              container
                              lg={12}
                              md={12}
                              sm={12}
                              // justifyContent="space-between"
                              alignItems="center"
                              mt={1}
                              // px={1}
                              key={index}
                              // className="bg-success"
                            >
                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                container
                              >
                                {!values.exclude[0].keywords.length ? (
                                  <Grid item lg={3} md={3} sm={3} mt={1}>
                                    Include Words *:
                                  </Grid>
                                ) : (
                                  <Grid item lg={3} md={3} sm={3} mt={1}>
                                    Include Words :
                                  </Grid>
                                )}

                                <Grid item lg={7} md={7} sm={7}>
                                  <Field
                                    as={MuiTextField}
                                    variant="outlined"
                                    placeholder="Enter values"
                                    id={`rule_group.${index}.keywords`}
                                    size="small"
                                    name={`rule_group.${index}.keywords`}
                                    error={
                                      Boolean(errors.rule_group) &&
                                      Boolean(touched.rule_group)
                                    }
                                    helperText={
                                      Boolean(touched.rule_group) &&
                                      errors.rule_group
                                    }
                                  />
                                </Grid>
                                {values.rule_group.length > 1 && (
                                  <Grid
                                    item
                                    xl={1}
                                    lg={1}
                                    md={1}
                                    sm={1}
                                    ml={1}
                                    container
                                    className="d-flex justify-content-end"
                                  >
                                    <MuiButton
                                      name={<DeleteIcon />}
                                      onClick={() => remove(index)}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          ))}
                        {values.rule_group &&
                        values.rule_group.length > 0 &&
                        values.rule_group[
                          values.rule_group && values.rule_group.length - 1
                        ].keywords &&
                        values.rule_group[
                          values.rule_group && values.rule_group.length - 1
                        ].keywords.length >= 2 ? (
                          <Grid
                            container
                            lg={12}
                            md={12}
                            sm={12}
                            justifyContent="flex-end"
                            mt={1}
                          >
                            {" "}
                            <MuiButton
                              onClick={() => push({ keywords: "" })}
                              name={"Add"}
                            />
                          </Grid>
                        ) : (
                          <Grid
                            container
                            lg={12}
                            md={12}
                            sm={12}
                            justifyContent="flex-end"
                            mt={1}
                          >
                            <Typography>
                              Enter a keyword with at least 2 letters to add
                              another
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </FieldArray>
                </Grid>
                {/* working */}
                <Grid item lg={12} md={12} sm={12} container>
                  <FieldArray name="exclude">
                    {({ insert, remove, push }) => (
                      <Grid
                        container
                        lg={12}
                        md={12}
                        sm={12}
                        mt={1}
                        // px={1}
                        className="d-flex justify-content-center"
                      >
                        {values.exclude &&
                          values.exclude.length > 0 &&
                          values.exclude.map((exclude, index) => (
                            <Grid
                              container
                              lg={12}
                              md={12}
                              sm={12}
                              // justifyContent="space-between"
                              alignItems="center"
                              mt={1}
                              // px={1}
                              key={index}
                              // className="bg-success"
                            >
                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                container
                              >
                                {!values.rule_group[0].keywords.length ? (
                                  <Grid item lg={3} md={3} sm={3} mt={1}>
                                    Exclude Words *:
                                  </Grid>
                                ) : (
                                  <Grid item lg={3} md={3} sm={3} mt={1}>
                                    Exclude Words:
                                  </Grid>
                                )}

                                <Grid item lg={7} md={7} sm={7}>
                                  <Field
                                    as={MuiTextField}
                                    variant="outlined"
                                    placeholder="Enter values"
                                    id={`exclude.${index}.keywords`}
                                    size="small"
                                    name={`exclude.${index}.keywords`}
                                    error={
                                      Boolean(errors.exclude) &&
                                      Boolean(touched.exclude)
                                    }
                                    helperText={
                                      Boolean(touched.exclude) && errors.exclude
                                    }
                                  />
                                </Grid>
                                {values.exclude.length > 1 && (
                                  <Grid
                                    item
                                    xl={1}
                                    lg={1}
                                    md={1}
                                    sm={1}
                                    ml={1}
                                    container
                                    className="d-flex justify-content-end"
                                  >
                                    <MuiButton
                                      name={<DeleteIcon />}
                                      onClick={() => remove(index)}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          ))}
                        {values.exclude &&
                        values.exclude.length > 0 &&
                        values.exclude[values.exclude.length - 1].keywords &&
                        values.exclude[values.exclude.length - 1].keywords
                          .length >= 2 ? (
                          <Grid
                            container
                            lg={12}
                            md={12}
                            sm={12}
                            justifyContent="flex-end"
                            mt={1}
                          >
                            {" "}
                            <MuiButton
                              onClick={() => push({ keywords: "" })}
                              name={"Add"}
                            />
                          </Grid>
                        ) : (
                          <Grid
                            container
                            lg={12}
                            md={12}
                            sm={12}
                            justifyContent="flex-end"
                            mt={1}
                          >
                            <Typography>
                              Enter a keyword with at least 2 letters to add
                              another
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </FieldArray>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="submit"
                      name="Add Configuraton"
                      width="100%"
                      loading={loading}
                    />
                  ) : (
                    <MuiButton
                      type="submit"
                      name="Submit"
                      width="100%"
                      loading={loading}
                    />
                  )}
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="reset"
                      name="Reset"
                      onClick={() => {
                        setSelected([]);
                        setAccountsSelected([]);
                        setSelectionModel([]);
                      }}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default connect(mapDispatchToProps)(KeywordConfigurationForm);
