import BaseReducer from "../../../utilities/BaseReducer";
import BrandCompitatorAction from "./BrandCompitatorAction";

// reducer function
export default class BrandCompitatorActivitiesListReducer extends BaseReducer {
  initialState = {
    competitor_activities_list: [],
  };

  [BrandCompitatorAction.REQUEST_BRAND_COMPITATOR_ACTIVIIES_LIST_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      competitor_activities_list: action.payload,
    };
  }
}
