import { Box, Grid, Typography } from "@mui/material";
import LoadingIndicator from "./loading-indicator/LoadingIndicator";
import { theme } from "../views/App";
import TotalMentions from "../ormTicketing/components/reportPdfTemplates/TotalMentions";
import { firstLetterOrange, getItemsById } from "./ticketPartials";
import { useEffect, useRef, useState, useCallback } from "react";
import { connect } from "react-redux";
import HourlyTicketsResolved from "../ormTicketing/components/reportPdfTemplates/HourlyTicketsResolved";
import AvgFRTPlatformWise from "../ormTicketing/components/reportPdfTemplates/AvgFRTPlatformWise";
import PlatformwiseStatus from "../ormTicketing/components/reportPdfTemplates/PlatformwiseStatus";
import OverallTicketStatusUserwise from "../ormTicketing/components/reportPdfTemplates/OverallTicketStatusUserwise";
import PlatformWiseReport from "../ormTicketing/components/reportPdfTemplates/PlatformWiseReport";
import RoleSummary from "../ormTicketing/components/reportPdfTemplates/RoleSummary";
import ReportContainer from "./ReportsContainer";
import BridgeUsersAvgFRTChart from "../ormTicketing/components/reportPdfTemplates/BridgeUsersAvgFRTChart";
import TotalMentionsChart from "../ormTicketing/components/reportPdfTemplates/chartReports/TotalMentionsChart";
import FRTEfficiencyAgentWiseChart from "../ormTicketing/components/reportPdfTemplates/FRTEfficiencyAgentWiseChart";
import FRTEfficiencyAgentWise from "../ormTicketing/components/reportPdfTemplates/FRTEfficiencyAgentWise";
import OverallAverageFRT from "../ormTicketing/components/reportPdfTemplates/OverallAverageFRT";
import OverallAverageTAT from "../ormTicketing/components/reportPdfTemplates/OverallAverageTAT";
import BridgeUsersAvgFRT from "../ormTicketing/components/reportPdfTemplates/BridgeUsersAvgFRT";
import BridgeUsersAvgTAT from "../ormTicketing/components/reportPdfTemplates/BridgeUsersAvgTAT";
import BridgeUsersAvgTATChart from "../ormTicketing/components/reportPdfTemplates/BridgeUsersAvgTATChart";
import ResponseOverviewChart from "../ormTicketing/components/reportPdfTemplates/chartReports/ResponseOverviewChart";
import HourlylatformResolvedChart from "../ormTicketing/components/reportPdfTemplates/chartReports/HourlylatformResolvedChart";
import NegativeMentionsDispositionWise from "../ormTicketing/components/reportPdfTemplates/NegativeMentionsDispositionWise";
import NegativeMentionsDispositionWiseChart from "../ormTicketing/components/reportPdfTemplates/chartReports/NegativeMentionDispositionwiseChart";
import NegativeMentions from "../ormTicketing/components/reportPdfTemplates/NegativeMentions";
import NegativeMentionsChart from "../ormTicketing/components/reportPdfTemplates/chartReports/NegativeMentionsChart";
import TotalNegativeMentions from "../ormTicketing/components/reportPdfTemplates/TotalNegativeMentions";
import TotalNegativeMentionsChart from "../ormTicketing/components/reportPdfTemplates/chartReports/TotalNeagativeMentionsChart";
import OverallSentimentMentions from "../ormTicketing/components/reportPdfTemplates/OverallSentimentMentions";
import SentimentAnalysisLast12monthsChart from "../ormTicketing/components/reportPdfTemplates/chartReports/SentimentAnalysisLast12monthsChart";
import PlatformWiseSentimentOverview from "../ormTicketing/components/reportPdfTemplates/PlatformWiseSentimentOverview";
import SentimentOvertime from "../ormTicketing/components/reportPdfTemplates/SentimentOvertime";
import CategoryWiseSentiments from "../ormTicketing/components/reportPdfTemplates/CategoryWiseSentiments";
import PlatformWiseOverallSentiment from "../ormTicketing/components/reportPdfTemplates/PlatformWiseOverallSentiment";
import ChannelWiseSentimentDistribution from "../ormTicketing/components/reportPdfTemplates/ChannelWiseSentimentDisrtibution";
import MentionsTrendWithEngagement from "../ormTicketing/components/reportPdfTemplates/MentionsTrendWithEngagement";
import OverallSentimentMentions3months from "../ormTicketing/components/reportPdfTemplates/OverallSentimentMentions3months";
import OverallTicketStatusUserwiseChart from "../ormTicketing/components/reportPdfTemplates/OverallTicketStatusUserwiseChart";
import TicketSummary from "../ormTicketing/components/reportPdfTemplates/TicketSummary";
import EscalationsReports from "../ormTicketing/components/reportPdfTemplates/EscalationsReports";
import ResponseOverview from "../ormTicketing/components/reportPdfTemplates/ResponseOverview";
import TatOverview from "../ormTicketing/components/reportPdfTemplates/TatOverview";
import CategoryWiseDistribution from "../ormTicketing/components/reportPdfTemplates/CategoryWiseDistribution";
import CategoryWiseDistributionChart from "../ormTicketing/components/reportPdfTemplates/chartReports/CategoryWiseDistributionChart";
import CategoryWiseTatDistribution from "../ormTicketing/components/reportPdfTemplates/CategoryWiseTatDistribution";
import OverallShareOfVoice from "../ormTicketing/components/reportPdfTemplates/OverallShareOfVoice";
import TicketClouserTatBreachChart from "../ormTicketing/components/reportPdfTemplates/chartReports/TicketClosureTatBreachChart";
import ShareOfVoiceChart from "../ormTicketing/components/reportPdfTemplates/chartReports/ShareOfVoiceChart";
import WordCloudReport from "../ormTicketing/components/reportPdfTemplates/WordCloudReport";
import TicketCategoryReport from "../ormTicketing/components/reportPdfTemplates/TicketCategoryReport";
import TicketSummaryChartView from "../ormTicketing/components/reportPdfTemplates/TicketSummaryChartView";
import Platformwisetop10categories from "../ormTicketing/components/reportPdfTemplates/Platformwisetop10categories";
import PlatformwiseTicketsByCategory from "../ormTicketing/components/reportPdfTemplates/PlatformwiseTicketsByCategory";
import PlatformwiseNegativeTicketsByCategory from "../ormTicketing/components/reportPdfTemplates/PlatformwiseNegativeTicketsByCategory";
import FrtTatAveragesByCategory from "../ormTicketing/components/reportPdfTemplates/FrtTatAveragesByCategory";
import SenrimentsByTicketsCategory from "../ormTicketing/components/reportPdfTemplates/SenrimentsByTicketsCategory";
import TicketsByCategory from "../ormTicketing/components/reportPdfTemplates/TicketsByCategory";
import NegativeTicketsByCategory from "../ormTicketing/components/reportPdfTemplates/NegativeTicketsByCategory";
import SentimentByCustomFields from "../ormTicketing/components/reportPdfTemplates/SentimentByCustomFields";
import SentimentOnCustomValue from "../ormTicketing/components/reportPdfTemplates/SentimentOnCustomValue";
import NegativeCustomValueReport from "../ormTicketing/components/reportPdfTemplates/NegativeCustomValueReport";
import TicketsByCustomFields from "../ormTicketing/components/reportPdfTemplates/TicketsByCustomFields";
import FrtTatAveargesByCustomFields from "../ormTicketing/components/reportPdfTemplates/FrtTatAveargesByCustomFields";
import TicketsByCategoryChart from "../ormTicketing/components/reportPdfTemplates/TicketsByCategoryChart";
import NegativeTicketsByCategoryChart from "../ormTicketing/components/reportPdfTemplates/NegativeTicketsByCategoryChart";
import PlatformwiseTicketsByCategoryChart from "../ormTicketing/components/reportPdfTemplates/PlatformwiseTicketsByCategoryChart";
import PlatformwiseNegativeTicketsByCategoryChart from "../ormTicketing/components/reportPdfTemplates/PlatformwiseNegativeTicketsByCategoryChart";
import FrtTatAveragesByCategoryChart from "../ormTicketing/components/reportPdfTemplates/FrtTatAveragesByCategoryChart";
import SentimentbyTicketsCategoryChart from "../ormTicketing/components/reportPdfTemplates/SentimentbyTicketsCategoryChart";
import ShareOfVoiceTrend from "../ormTicketing/components/reportPdfTemplates/ShareOfVoiceTrend";
import SentimentwiseShareOfVoice from "../ormTicketing/components/reportPdfTemplates/SentimentwiseShareOfVoice";
import CompetitorSummaryReport from "../ormTicketing/components/reportPdfTemplates/CompetitorSummaryReport";
import InstagramProfileSummary from "../ormTicketing/components/reportPdfTemplates/InstagramProfileSummary";
import InstagramInsights from "../ormTicketing/components/reportPdfTemplates/chartReports/InstagramInsights";
import { format, isValid } from "date-fns";
import FollowerDemographicsCountry from "../ormTicketing/components/reportPdfTemplates/chartReports/FollowerDemographicsCountry";
import FollowersDemographicAge from "../ormTicketing/components/reportPdfTemplates/FollowersDemographicAge";
import FollowerDemographicsCity from "../ormTicketing/components/reportPdfTemplates/chartReports/FollowerDemographicsCity";
import FacebookProfileSummary from "../ormTicketing/components/reportPdfTemplates/FacebookProfileSummary";
import FacebookInsights from "../ormTicketing/components/reportPdfTemplates/FacebookInsights";
import DailyPageFans from "../ormTicketing/components/reportPdfTemplates/DailyPageFans";
import PageReachBreakdownOvertime from "../ormTicketing/components/reportPdfTemplates/PageReachBreakdownOvertime";
import PostReachByType from "../ormTicketing/components/reportPdfTemplates/PostReachByType";
import FollowersOverTime from "../ormTicketing/components/reportPdfTemplates/FollowersOverTime";
import YoutubeProfileSummary from "../ormTicketing/components/reportPdfTemplates/YoutubeProfileSummary";
import YoutubeViewsOvertime from "../ormTicketing/components/reportPdfTemplates/YoutubeViewsOvertime";
import YoutubeChannelWatchOvertime from "../ormTicketing/components/reportPdfTemplates/YoutubeChannelWatchOvertime";
import YoutubeVideoEnagementOvertime from "../ormTicketing/components/reportPdfTemplates/YoutubeVideoEnagementOvertime";
import YoutubeChannelViewsOvertime from "../ormTicketing/components/reportPdfTemplates/YoutubeChannelViewsOvertime";
import YoutubeSubscribersGainLostOvertime from "../ormTicketing/components/reportPdfTemplates/YoutubeSubscribersGainLostOvertime";
import PlatformWiseOverallSentiment_mentions from "../ormTicketing/components/reportPdfTemplates/PlatformWiseOverallSentiment_mentions";
import SentimentOvertime_mentions from "../ormTicketing/components/reportPdfTemplates/SentimentOvertime_mentions";
import ChannelWiseSentimentDistribution_mentions from "../ormTicketing/components/reportPdfTemplates/ChannelWiseSentimentDistribution_mentions";
import PlatformWiseSentimentOverview_mentions from "../ormTicketing/components/reportPdfTemplates/PlatformWiseSentimentOverview_mentions";
import OverallSentimentMentions_mentions from "../ormTicketing/components/reportPdfTemplates/OverallSentimentMentions_mentions";
import SentimentAnalysisLast12monthsChart_mentions from "../ormTicketing/components/reportPdfTemplates/chartReports/SentimentAnalysisLast12monthsChart_mentions";
import NegativeMentions_mentions from "../ormTicketing/components/reportPdfTemplates/NegativeMentions_mentions";
import TotalMentionsChart_mentions from "../ormTicketing/components/reportPdfTemplates/chartReports/TotalMentionsChart_mentions";
import ResponseOverview_mentions from "../ormTicketing/components/reportPdfTemplates/ResponseOverview_mentions";
import ResponseOverviewChart_mentions from "../ormTicketing/components/reportPdfTemplates/chartReports/ResponseOverviewChart_mentions";
import TotalMentions_mentions from "../ormTicketing/components/reportPdfTemplates/TotalMentions_mentions";
import NegativeMentionsChart_mentions from "../ormTicketing/components/reportPdfTemplates/chartReports/NegativeMentionsChart_mentions";
import OverallSentimentMentions3months_mentions from "../ormTicketing/components/reportPdfTemplates/OverallSentimentMentions3months_mentions";
import MentionsTrendWithEngagement_mentions from "../ormTicketing/components/reportPdfTemplates/MentionsTrendWithEngagement_mentions";
import WordCloudReport_mentions from "../ormTicketing/components/reportPdfTemplates/WordCloudReport_mentions";
import TicketWordCloudReport from "../ormTicketing/components/reportPdfTemplates/TicketWordCloudReport";

let mapStateToProps = (state) => ({});

const ReturnReports = ({
  report,
  platform_list,
  descriptionCallback,
  activity_list,
  statusList,
  userList,
  setDetailReportId,
  setDetailReportOpen,
  myTicketsTime,
  DateFilter,
  detailReportOpen,
  account_list,
}) => {
  let report_id = report
    ? report
    : report.report_name
    ? JSON.parse(report.report_name).report_id
    : null;

  // console.log("ReturnReportsprops", {
  //   report,
  //   platform_list,
  //   descriptionCallback,
  //   activity_list,
  //   statusList,
  //   userList,
  //   setDetailReportId,
  //   setDetailReportOpen,
  //   myTicketsTime,
  //   DateFilter,
  //   detailReportOpen,
  //   account_list,
  // });

  const summaryBoxRef = useRef(null);
  let dataGridHeight = useRef(null);
  const ChartContainerHeight = useRef(null);
  const ChartContainerWidth = useRef(null);

  let instagram_accounts = account_list?.filter((item) => {
    return item?.platform === "Instagram";
  });

  let facebook_accounts = account_list?.filter((item) => {
    return item?.platform === "Facebook";
  });

  let youtube_accounts = account_list?.filter((item) => {
    return item?.platform === "Youtube";
  });

  let reportsAccountList = [];

  if (
    [
      "vhPdBSDnyodn5_f5k-F1",
      "vhPdGowBpodn5_f5k-F3",
      "vhPdGowBpodn5_f5k-F4",
      "vhPdGowBpodn5_f5k-F5",
      "vhPdGowBpodn5_f5k-F6",
      "vhPdGowBpodn5_f5k-F7",
    ].includes(report)
  ) {
    reportsAccountList = facebook_accounts;
  }

  if (
    [
      "vhPdGowBpodn5_f5k-I1",
      "vhPdGowBpodn5_f5k-I2",
      "vhPdGowBpodn5_f5k-I4",
      "vhPdGowBpodn5_f5k-I5",
      "vhPdGowBpodn5_f5k-I5",
    ].includes(report)
  ) {
    reportsAccountList = instagram_accounts;
  }
  if (
    [
      "jgMSJSDnyodn5_f5k-Y1",
      "jgMSJSDnyodn5_f5k-Y2",
      "jgMSJSDnyodn5_f5k-Y3",
      "jgMSJSDnyodn5_f5k-Y4",
      "jgMSJSDnyodn5_f5k-Y5",
      "jgMSJSDnyodn5_f5k-Y6",
    ].includes(report)
  ) {
    reportsAccountList = youtube_accounts;
  }

  // const [account_selected, setaccount_selected] = useState(
  //   reportsAccountList?.length ? reportsAccountList[0] : null
  // );

  const account_selected = reportsAccountList?.length
    ? reportsAccountList[0]
    : null;

  useEffect(() => {
    if (summaryBoxRef?.current) {
      const calculateHeights = () => {
        const outerBoxHeight = summaryBoxRef.current.clientHeight;
        const outerBoxWidth = summaryBoxRef.current.clientWidth;
        if (
          summaryBoxRef.current &&
          summaryBoxRef.current.children[1] &&
          summaryBoxRef.current.children[0]
        ) {
          summaryBoxRef.current.children[0].style.height = 50;
          dataGridHeight.current = outerBoxHeight - 75;
          ChartContainerHeight.current = summaryBoxRef.current.clientHeight;
          ChartContainerWidth.current = summaryBoxRef.current.clientWidth;
        }
      };

      calculateHeights();

      // Use ResizeObserver to listen for changes in the size of summaryBoxRef
      const resizeObserver = new ResizeObserver(calculateHeights);
      resizeObserver.observe(summaryBoxRef.current);

      // Cleanup ResizeObserver on component unmount
      return () => {
        resizeObserver.disconnect();
      };
    }
  }); // Empty dependency array to ensure the effect runs only once on mount

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      {report_id === "rBPdGowBpodn5_f5jeOH" && (
        <Grid
          lg={12}
          p={1}
          sx={{
            height: "100%",
          }}
          // sx={{ border: "1px solid grey" }}
        >
          <TicketSummary
            platformList={platform_list}
            myTicketsTime={myTicketsTime}
            DateFilter={DateFilter}
            report={report}
            setDetailReportId={setDetailReportId}
            setDetailReportOpen={setDetailReportOpen}
            detailReportOpen={detailReportOpen}
          />
        </Grid>
      )}

      {report_id === "rBPdGowBpodn5_f5jeOD" && (
        <Grid
          lg={12}
          p={1}
          sx={{
            height: "100%",
          }}
        >
          <TicketSummaryChartView
            platformList={platform_list}
            myTicketsTime={myTicketsTime}
            DateFilter={DateFilter}
            statusList={statusList}
            report={report}
            setDetailReportId={setDetailReportId}
            setDetailReportOpen={setDetailReportOpen}
            detailReportOpen={detailReportOpen}
          />
        </Grid>
      )}

      <ReportContainer
        summaryBoxRef={summaryBoxRef}
        theme={theme}
        report={report}
        detailReportOpen={detailReportOpen}
        setDetailReportId={setDetailReportId}
        setDetailReportOpen={setDetailReportOpen}
      >
        {/* Ticket Reports */}
        {report_id === "uxPdGowBpodn5_f5kuPX" && (
          <TotalMentions
            report={report && getItemsById(report)}
            description={report.description}
            platformList={platform_list}
            dataGridHeight={dataGridHeight}
          />
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M11" && (
          <TotalMentions_mentions
            report={report && getItemsById(report)}
            description={report.description}
            platformList={platform_list}
            dataGridHeight={dataGridHeight}
          />
        )}
        {report_id === "vBPdGowBpodn5_f5k-Mo" && (
          <TotalMentionsChart
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
          />
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M08" && (
          <TotalMentionsChart_mentions
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
          />
        )}
        {report_id === "zBPgGowBpodn5_f5uONx" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <EscalationsReports platformList={platform_list} />
          </Grid>
        )}
        {report_id === "sBPdGowBpodn5_f5juPF" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <ResponseOverview
              platformList={platform_list}
              activity_list={activity_list}
              DateFilter={DateFilter}
            />
          </Grid>
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M09" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <ResponseOverview_mentions
              platformList={platform_list}
              activity_list={activity_list}
              DateFilter={DateFilter}
            />
          </Grid>
        )}
        {report_id === "sBPdGowBpodn5_f5juPG" && (
          <ResponseOverviewChart
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            callBack={descriptionCallback}
            description={report.description}
            platformList={platform_list}
            activity_list={activity_list}
            DateFilter={DateFilter}
          />
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M10" && (
          <ResponseOverviewChart_mentions
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            callBack={descriptionCallback}
            description={report.description}
            platformList={platform_list}
            activity_list={activity_list}
            DateFilter={DateFilter}
          />
        )}
        {report_id === "tRPdGowBpodn5_f5kOPx" && (
          <AvgFRTPlatformWise
            filterValue={report.filter}
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            platformList={platform_list}
            dataGridHeight={dataGridHeight}
          />
        )}
        {report_id === "yxPdGowBpodn5_f5mOPQ" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <TatOverview />
          </Grid>
        )}
        {report_id === "zRPgGowBpodn5_f5ueN5" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <CategoryWiseDistribution platformList={platform_list} />
          </Grid>
        )}
        {report_id === "zhPgGowBpodn5_f5uuNw" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <CategoryWiseDistributionChart platformList={platform_list} />
          </Grid>
        )}
        {report_id === "zxPgGowBpodn5_f5u-OE" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <CategoryWiseTatDistribution platformList={platform_list} />
          </Grid>
        )}
        {report_id === "0BPgGowBpodn5_f5vON5" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <OverallShareOfVoice platformList={platform_list} />
          </Grid>
        )}
        {report_id === "shPdGowBpodn5_f5j-Os" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <TicketClouserTatBreachChart
              platformList={platform_list}
              ChartContainerWidth={ChartContainerWidth}
              ChartContainerHeight={ChartContainerHeight}
              DateFilter={DateFilter}
            />
          </Grid>
        )}
        {report_id === "0RPgGowBpodn5_f5veOg" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <ShareOfVoiceChart platformList={platform_list} />
          </Grid>
        )}
        {report_id === "rRPdGowBpodn5_f5jePU" && (
          <HourlyTicketsResolved
            filterValue={report.filter}
            platformList={platform_list}
            dataGridHeight={dataGridHeight}
            DateFilter={DateFilter}
          />
        )}
        {report_id === "rRPdGowBpodn5_f5jePV" && (
          <PlatformwiseStatus
            filterValue={report.filter}
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            platformList={platform_list}
            statusList={statusList}
            dataGridHeight={dataGridHeight}
            DateFilter={DateFilter}
          />
        )}
        {report_id === "rRPdGowBpodn5_f5jePW" && (
          <Platformwisetop10categories
            filterValue={report.filter}
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            platformList={platform_list}
            statusList={statusList}
            dataGridHeight={dataGridHeight}
            DateFilter={DateFilter}
          />
        )}
        {report_id === "rRPdGowBpodn5_f5jePx" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <HourlylatformResolvedChart
              platformList={platform_list}
              statusList={statusList}
              DateFilter={DateFilter}
            />
          </Grid>
        )}
        {report_id === "tBPdGowBpodn5_f5kOOZ" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <WordCloudReport
              platformList={platform_list}
              DateFilter={DateFilter}
              activity_list={activity_list}
            />
          </Grid>
        )}{" "}
        {report_id === "tBPdGowBpodn5_f5kOOZ2" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <TicketWordCloudReport
              platformList={platform_list}
              DateFilter={DateFilter}
              activity_list={activity_list}
            />
          </Grid>
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M15" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <WordCloudReport_mentions
              platformList={platform_list}
              DateFilter={DateFilter}
              activity_list={activity_list}
            />
          </Grid>
        )}
        {report_id === "rxPdGowBpodn5_f5juNd" && (
          <OverallTicketStatusUserwise
            filterValue={report.filter}
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            statusList={statusList}
            callBack={descriptionCallback}
            description={report.description}
            platformList={platform_list}
            dataGridHeight={dataGridHeight}
            DateFilter={DateFilter}
          />
        )}
        {report_id === "rxPdGowBpodn5_f5juNE" && (
          <OverallTicketStatusUserwiseChart
            filterValue={report.filter}
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
            statusList={statusList}
            DateFilter={DateFilter}
          />
        )}
        {report_id === "rxPdGowBpodn5_f5TKC" && (
          <TicketsByCategoryChart
            filterValue={report.filter}
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
            statusList={statusList}
            DateFilter={DateFilter}
          />
        )}
        {report_id === "rxPdGowBpodn5_f5STC" && (
          <SentimentbyTicketsCategoryChart
            filterValue={report.filter}
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
            statusList={statusList}
            DateFilter={DateFilter}
          />
        )}
        {report_id === "rxPdGowBpodn5_f5NTC" && (
          <NegativeTicketsByCategoryChart
            filterValue={report.filter}
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
            statusList={statusList}
            DateFilter={DateFilter}
          />
        )}
        {report_id === "xxPdGowBpodn5_f5l-NJ" && (
          <PlatformWiseReport
            platformList={platform_list}
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            dataGridHeight={dataGridHeight}
          />
        )}
        {report_id === "sRPdGowBpodn5_f5j-Ma" && (
          <RoleSummary
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            dataGridHeight={dataGridHeight}
            statusList={statusList}
            userList={userList}
            DateFilter={DateFilter}
          />
        )}
        {report_id === "sRPdGowBpodn5_f5j-MB" && (
          <PlatformwiseTicketsByCategory
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            dataGridHeight={dataGridHeight}
            statusList={statusList}
            userList={userList}
            DateFilter={DateFilter}
            platform_list={platform_list}
          />
        )}{" "}
        {report_id === "sRPdGowBpodn5_f5j-BM" && (
          <PlatformwiseTicketsByCategoryChart
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            dataGridHeight={dataGridHeight}
            statusList={statusList}
            userList={userList}
            DateFilter={DateFilter}
            platform_list={platform_list}
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
          />
        )}{" "}
        {report_id === "sRPdGowBpodn5_f5j-CM" && (
          <PlatformwiseNegativeTicketsByCategoryChart
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            dataGridHeight={dataGridHeight}
            statusList={statusList}
            userList={userList}
            DateFilter={DateFilter}
            platform_list={platform_list}
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
          />
        )}
        {report_id === "sRPdGowBpodn5_f5j-MC" && (
          <PlatformwiseNegativeTicketsByCategory
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            dataGridHeight={dataGridHeight}
            statusList={statusList}
            userList={userList}
            DateFilter={DateFilter}
            platform_list={platform_list}
          />
        )}
        {report_id === "sRPdGowBpodn5_f5j-MF" && (
          <TicketsByCategory
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            dataGridHeight={dataGridHeight}
            statusList={statusList}
            userList={userList}
            DateFilter={DateFilter}
            platform_list={platform_list}
          />
        )}
        {report_id === "sRPdGowBpodn5_f5j-MG" && (
          <NegativeTicketsByCategory
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            dataGridHeight={dataGridHeight}
            statusList={statusList}
            userList={userList}
            DateFilter={DateFilter}
            platform_list={platform_list}
          />
        )}
        {report_id === "sRPdGowBpodn5_f5j-MD" && (
          <FrtTatAveragesByCategory
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            dataGridHeight={dataGridHeight}
            statusList={statusList}
            userList={userList}
            DateFilter={DateFilter}
            platform_list={platform_list}
          />
        )}{" "}
        {report_id === "sRPdGowBpodn5_f5j-DM" && (
          <FrtTatAveragesByCategoryChart
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            dataGridHeight={dataGridHeight}
            statusList={statusList}
            userList={userList}
            DateFilter={DateFilter}
            platform_list={platform_list}
          />
        )}{" "}
        {report_id === "sRPdGowBpodn5_f5j-ME" && (
          <SenrimentsByTicketsCategory
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            dataGridHeight={dataGridHeight}
            statusList={statusList}
            userList={userList}
            DateFilter={DateFilter}
            platform_list={platform_list}
          />
        )}
        {report_id === "sRPdGowBpodn5_f5j-MH" && (
          <SentimentOnCustomValue
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            dataGridHeight={dataGridHeight}
            statusList={statusList}
            userList={userList}
            DateFilter={DateFilter}
            platform_list={platform_list}
          />
        )}
        {report_id === "sRPdGowBpodn5_f5j-My" && (
          <FrtTatAveargesByCustomFields
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            dataGridHeight={dataGridHeight}
            statusList={statusList}
            userList={userList}
            DateFilter={DateFilter}
            platform_list={platform_list}
          />
        )}
        {report_id === "sRPdGowBpodn5_f5j-Mz" && (
          <TicketsByCustomFields
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            dataGridHeight={dataGridHeight}
            statusList={statusList}
            userList={userList}
            DateFilter={DateFilter}
            platform_list={platform_list}
          />
        )}
        {report_id === "sRPdGowBpodn5_f5j-MI" && (
          <NegativeCustomValueReport
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            dataGridHeight={dataGridHeight}
            statusList={statusList}
            userList={userList}
            DateFilter={DateFilter}
            platform_list={platform_list}
          />
        )}
        {report_id === "yBPdGowBpodn5_f5l-Or" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <TicketCategoryReport
              platformList={platform_list}
              dataGridHeight={dataGridHeight}
            />
          </Grid>
        )}
        {/* Agent Reports */}
        {report_id === "txPdGowBpodn5_f5keN0" && (
          <OverallAverageFRT
            report={report && getItemsById(report)}
            description={report.description}
            dataGridHeight={dataGridHeight}
            DateFilter={DateFilter}
            platformList={platform_list}
          />
        )}
        {report_id === "uBPdGowBpodn5_f5keOw" && (
          <OverallAverageTAT
            report={report && getItemsById(report)}
            dataGridHeight={dataGridHeight}
            description={report.description}
            platformList={platform_list}
            DateFilter={DateFilter}
          />
        )}
        {report_id === "vRPdGowBpodn5_f5k-Ns" && (
          <BridgeUsersAvgFRT
            report={report && getItemsById(report)}
            dataGridHeight={dataGridHeight}
            description={report.description}
            platformList={platform_list}
            DateFilter={DateFilter}
          />
        )}
        {report_id === "vRPdGowBpodn5_f5k-NT" && (
          <BridgeUsersAvgFRTChart
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
            DateFilter={DateFilter}
          />
        )}
        {report_id === "vhPdGowBpodn5_f5k-Oq" && (
          <BridgeUsersAvgTAT
            report={report && getItemsById(report)}
            dataGridHeight={dataGridHeight}
            description={report.description}
            platformList={platform_list}
            DateFilter={DateFilter}
          />
        )}
        {report_id === "vhPdGowBpodn5_f5k-OR" && (
          <BridgeUsersAvgTATChart
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
            DateFilter={DateFilter}
          />
        )}
        {report_id === "wBPdGowBpodn5_f5lONv" && (
          <FRTEfficiencyAgentWise
            dataGridHeight={dataGridHeight}
            description={report.description}
            platformList={platform_list}
            report={report && getItemsById(report)}
          />
        )}
        {/* Sentimental Analysis reports */}
        {report_id === "0hPgGowBpodn5_f5vuOU" && (
          <NegativeMentionsDispositionWise
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            filterValue={report.filter}
            platformList={platform_list}
          />
        )}
        {report_id === "0xPgGowBpodn5_f5v-Of" && (
          <NegativeMentionsDispositionWiseChart
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            filterValue={report.filter}
            platformList={platform_list}
          />
        )}
        {report_id === "wRPdGowBpodn5_f5leNX" && (
          <NegativeMentions
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            filterValue={report.filter}
            platformList={platform_list}
            dataGridHeight={dataGridHeight}
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
          />
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M07" && (
          <NegativeMentions_mentions
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            filterValue={report.filter}
            platformList={platform_list}
            dataGridHeight={dataGridHeight}
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
          />
        )}
        {report_id === "whPdGowBpodn5_f5leOg" && (
          <NegativeMentionsChart
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            filterValue={report.filter}
            platformList={platform_list}
          />
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M12" && (
          <NegativeMentionsChart_mentions
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            filterValue={report.filter}
            platformList={platform_list}
          />
        )}
        {report_id === "1BPgGowBpodn5_f5weMf" && (
          <TotalNegativeMentions
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            filterValue={report.filter}
            platformList={platform_list}
            dataGridHeight={dataGridHeight}
          />
        )}
        {report_id === "1BPgGowBpodn5_f5weGH" && (
          <SentimentByCustomFields
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            filterValue={report.filter}
            platformList={platform_list}
            dataGridHeight={dataGridHeight}
          />
        )}
        {report_id === "1RPgGowBpodn5_f5wuMn" && (
          <TotalNegativeMentionsChart
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            filterValue={report.filter}
            platformList={platform_list}
          />
        )}
        {report_id === "yRPdGowBpodn5_f5mOMQ" && (
          <OverallSentimentMentions
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            filterValue={report.filter}
            platformList={platform_list}
            dataGridHeight={dataGridHeight}
          />
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M05" && (
          <OverallSentimentMentions_mentions
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            filterValue={report.filter}
            platformList={platform_list}
            dataGridHeight={dataGridHeight}
          />
        )}
        {report_id === "yhPdGowBpodn5_f5mON9" && (
          <SentimentAnalysisLast12monthsChart
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            filterValue={report.filter}
            platformList={platform_list}
          />
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M06" && (
          <SentimentAnalysisLast12monthsChart_mentions
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            filterValue={report.filter}
            platformList={platform_list}
          />
        )}
        {report_id === "uRPdGowBpodn5_f5kuMT" && (
          <PlatformWiseSentimentOverview
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            filterValue={report.filter}
            platformList={platform_list}
            DateFilter={DateFilter}
          />
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M04" && (
          <PlatformWiseSentimentOverview_mentions
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            filterValue={report.filter}
            platformList={platform_list}
            DateFilter={DateFilter}
          />
        )}
        {report_id === "uhPdGowBpodn5_f5kuNa" && (
          <SentimentOvertime
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            filterValue={report.filter}
            platformList={platform_list}
            DateFilter={DateFilter}
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
          />
        )}
        {report_id === "jgMSJSDnyodn5_f5k-M02" && (
          <SentimentOvertime_mentions
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            filterValue={report.filter}
            platformList={platform_list}
            DateFilter={DateFilter}
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
          />
        )}
        {report_id === "1hPgGowBpodn5_f5w-Me" && (
          <CategoryWiseSentiments
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            filterValue={report.filter}
            platformList={platform_list}
          />
        )}
        {report_id === "xRPdGowBpodn5_f5luOh" && (
          <PlatformWiseOverallSentiment
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            filterValue={report.filter}
            platformList={platform_list}
            dataGridHeight={dataGridHeight}
          />
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M01" && (
          <PlatformWiseOverallSentiment_mentions
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            filterValue={report.filter}
            platformList={platform_list}
            dataGridHeight={dataGridHeight}
          />
        )}
        {report_id === "smhWkclkeGjn5_ewk_01" && (
          <ShareOfVoiceTrend
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            dataGridHeight={dataGridHeight}
          />
        )}
        {report_id === "smhWkclkeGjn5_ewk_02" && (
          <SentimentwiseShareOfVoice
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            dataGridHeight={dataGridHeight}
          />
        )}
        {report_id === "smhWkclkeGjn5_ewk_03" && (
          <CompetitorSummaryReport
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
          />
        )}
        {report_id === "vxPdGowBpodn5_f5lOMO" && (
          <ChannelWiseSentimentDistribution
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            filterValue={report.filter}
            platformList={platform_list}
            dataGridHeight={dataGridHeight}
            DateFilter={DateFilter}
          />
        )}
        {report_id === "jgMSJSDnyodn5_f5k-M03" && (
          <ChannelWiseSentimentDistribution_mentions
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            filterValue={report.filter}
            platformList={platform_list}
            dataGridHeight={dataGridHeight}
            DateFilter={DateFilter}
          />
        )}
        {report_id === "thPdGowBpodn5_f5keM7" && (
          <MentionsTrendWithEngagement
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            filterValue={report.filter}
            platformList={platform_list}
            DateFilter={DateFilter}
          />
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M14" && (
          <MentionsTrendWithEngagement_mentions
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            filterValue={report.filter}
            platformList={platform_list}
            DateFilter={DateFilter}
          />
        )}
        {report_id === "wxPdGowBpodn5_f5leP9" && (
          <OverallSentimentMentions3months
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            filterValue={report.filter}
            platformList={platform_list}
          />
        )}{" "}
        {report_id === "jgMSJSDnyodn5_f5k-M13" && (
          <OverallSentimentMentions3months_mentions
            report={
              report && report.report_name
                ? JSON.parse(report.report_name).report_name
                : report.report_id
                ? getItemsById(report.report_id)[0]?.report_name
                : ""
            }
            filterValue={report.filter}
            platformList={platform_list}
          />
        )}
        {report_id === "zBPgGowBpodn5_f5uONx" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <EscalationsReports platformList={platform_list} />
          </Grid>
        )}
        {report_id === "vhPdGowBpodn5_f5k-I2" && (
          <InstagramProfileSummary
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
            DateFilter={DateFilter}
            account_list={account_list}
            account_selected={account_selected}
          />
        )}
        {report_id === "vhPdGowBpodn5_f5k-I1" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <InstagramInsights
              platformList={platform_list}
              ChartContainerWidth={ChartContainerWidth}
              ChartContainerHeight={ChartContainerHeight}
              DateFilter={[
                isValid(
                  new Date(new Date().getFullYear(), new Date().getMonth(), 1)
                ) &&
                  format(
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth(),
                      1
                    ),
                    "yyyy-MM-dd"
                  ),
                isValid(new Date()) && format(new Date(), "yyyy-MM-dd"),
              ]}
              account_list={account_list}
              account_selected={account_selected}
            />
          </Grid>
        )}
        {report_id === "vhPdGowBpodn5_f5k-I4" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <FollowerDemographicsCountry
              platformList={platform_list}
              ChartContainerWidth={ChartContainerWidth}
              ChartContainerHeight={ChartContainerHeight}
              DateFilter={[
                isValid(
                  new Date(new Date().getFullYear(), new Date().getMonth(), 1)
                ) &&
                  format(
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth(),
                      1
                    ),
                    "yyyy-MM-dd"
                  ),
                isValid(new Date()) && format(new Date(), "yyyy-MM-dd"),
              ]}
              account_list={account_list}
              account_selected={account_selected}
            />
          </Grid>
        )}
        {report_id === "vhPdGowBpodn5_f5k-I5" && (
          <Grid
            lg={12}
            p={1}
            sx={{
              height: "100%",
            }}
          >
            <FollowerDemographicsCity
              platformList={platform_list}
              ChartContainerWidth={ChartContainerWidth}
              ChartContainerHeight={ChartContainerHeight}
              DateFilter={[
                isValid(
                  new Date(new Date().getFullYear(), new Date().getMonth(), 1)
                ) &&
                  format(
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth(),
                      1
                    ),
                    "yyyy-MM-dd"
                  ),
                isValid(new Date()) && format(new Date(), "yyyy-MM-dd"),
              ]}
              account_list={account_list}
              account_selected={account_selected}
            />
          </Grid>
        )}
        {report_id === "vhPdGowBpodn5_f5k-I6" && (
          <FollowersDemographicAge
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
            account_list={account_list}
            account_selected={account_selected}
          />
        )}
        {report_id === "vhPdBSDnyodn5_f5k-F1" && (
          <FacebookProfileSummary
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
            DateFilter={DateFilter}
            account_list={account_list}
            account_selected={account_selected}
          />
        )}
        {report_id === "vhPdGowBpodn5_f5k-F3" && (
          <FacebookInsights
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
            account_list={facebook_accounts}
            account_selected={account_selected}
            DateFilter={[
              isValid(
                new Date(new Date().getFullYear(), new Date().getMonth(), 1)
              ) &&
                format(
                  new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                  "yyyy-MM-dd"
                ),
              isValid(new Date()) && format(new Date(), "yyyy-MM-dd"),
            ]}
          />
        )}
        {report_id === "vhPdGowBpodn5_f5k-F5" && (
          <DailyPageFans
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
            account_list={facebook_accounts}
            account_selected={account_selected}
            DateFilter={[
              isValid(
                new Date(new Date().getFullYear(), new Date().getMonth(), 1)
              ) &&
                format(
                  new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                  "yyyy-MM-dd"
                ),
              isValid(new Date()) && format(new Date(), "yyyy-MM-dd"),
            ]}
          />
        )}
        {report_id === "vhPdGowBpodn5_f5k-F7" && (
          <PageReachBreakdownOvertime
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
            account_list={facebook_accounts}
            account_selected={account_selected}
            DateFilter={[
              isValid(
                new Date(new Date().getFullYear(), new Date().getMonth(), 1)
              ) &&
                format(
                  new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                  "yyyy-MM-dd"
                ),
              isValid(new Date()) && format(new Date(), "yyyy-MM-dd"),
            ]}
          />
        )}
        {report_id === "vhPdGowBpodn5_f5k-F6" && (
          <FollowersOverTime
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
            account_list={facebook_accounts}
            account_selected={account_selected}
            DateFilter={[
              isValid(
                new Date(new Date().getFullYear(), new Date().getMonth(), 1)
              ) &&
                format(
                  new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                  "yyyy-MM-dd"
                ),
              isValid(new Date()) && format(new Date(), "yyyy-MM-dd"),
            ]}
          />
        )}
        {report_id === "vhPdGowBpodn5_f5k-F4" && (
          <PostReachByType
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
            account_list={facebook_accounts}
            account_selected={account_selected}
            DateFilter={[
              isValid(
                new Date(new Date().getFullYear(), new Date().getMonth(), 1)
              ) &&
                format(
                  new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                  "yyyy-MM-dd"
                ),
              isValid(new Date()) && format(new Date(), "yyyy-MM-dd"),
            ]}
          />
        )}
        {report_id === "jgMSJSDnyodn5_f5k-Y1" && (
          <YoutubeProfileSummary
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
            DateFilter={DateFilter}
            account_list={account_list}
            account_selected={account_selected}
          />
        )}
        {report_id === "jgMSJSDnyodn5_f5k-Y2" && (
          <YoutubeViewsOvertime
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
            DateFilter={DateFilter}
            account_list={account_list}
            account_selected={account_selected}
          />
        )}
        {report_id === "jgMSJSDnyodn5_f5k-Y3" && (
          <YoutubeChannelWatchOvertime
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
            DateFilter={DateFilter}
            account_list={account_list}
            account_selected={account_selected}
          />
        )}
        {report_id === "jgMSJSDnyodn5_f5k-Y4" && (
          <YoutubeVideoEnagementOvertime
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
            DateFilter={DateFilter}
            account_list={account_list}
            account_selected={account_selected}
          />
        )}
        {report_id === "jgMSJSDnyodn5_f5k-Y5" && (
          <YoutubeChannelViewsOvertime
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
            DateFilter={DateFilter}
            account_list={account_list}
            account_selected={account_selected}
          />
        )}
        {report_id === "jgMSJSDnyodn5_f5k-Y6" && (
          <YoutubeSubscribersGainLostOvertime
            ChartContainerWidth={ChartContainerWidth}
            ChartContainerHeight={ChartContainerHeight}
            platformList={platform_list}
            DateFilter={DateFilter}
            account_list={account_list}
            account_selected={account_selected}
          />
        )}
      </ReportContainer>
    </div>
  );
};

export default connect(mapStateToProps)(ReturnReports);
