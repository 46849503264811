import React, { useEffect, useRef, useState } from "react";
import "./cssFiles/ResponseManagementPlatformCounts.css";
import environment from "environment";
import { Box, Card, Grid, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { format, isValid } from "date-fns";

function YoutubeProfileSummary(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    setData(null);
    (async function CallApi() {
      setLoading(true);

      props.account_selected &&
        (await fetchData(props.account_selected, props.openDateFilter));

      setLoading(false);
    })();
  }, [props.openDateFilter, props.account_selected]);

  useEffect(() => {
    if (props.clearClicked) {
      setData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.account_selected, props.openDateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      setData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.account_selected, props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.account_selected, props.filterClicked, props.openDateFilter]);

  async function fetchData(account_id, dateRange) {
    let API = `${environment.api.youtubeReportSummary}?account_id=${
      account_id?.account_id
    }${
      props.openDateFilter || props.filterClicked
        ? `&start_date=${dateRange[0]}&end_date=${dateRange[1]}`
        : `&start_date=${format(
            new Date().setFullYear(new Date().getFullYear() - 1),
            "yyyy-MM-dd"
          )}&end_date=${format(new Date(), "yyyy-MM-dd")}`
    }`;

    await axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (
          response.hasOwnProperty("error") ||
          response.hasOwnProperty("error_id")
        ) {
          response.hasOwnProperty("error_id")
            ? setApiError(`${response?.message} - ${response?.error_id}`)
            : setApiError(response?.error);
          setData(null);
        } else {
          if (response?.status === "success") {
            setData(response?.data);
            setApiError(null);
          }
        }
      })
      .catch((err) => {
        setData(null);
        setApiError(err.message);
        setLoading(false);
      });
  }

  let NoDataFoundBool = !data && !props.account_selected;

  useEffect(() => {
    if (props.DownloadClicked) {
      function convertCamelCaseToWords(input) {
        return input
          .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space before uppercase letters
          .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
      }

      let exportData = [];
      data?.length > 0 &&
        data.map((item) => {
          Object.entries(item)?.map(([Key, value]) =>
            exportData.push({
              Key: convertCamelCaseToWords(Key),
              Count: value,
            })
          );
        });

      props.setexcelData(exportData);
    }
  }, [props.DownloadClicked, data]);

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: "12",
      }}
    >
      <Box
        className="scrollable"
        sx={{
          overflowY: "auto",
          "& .MuiDataGrid-virtualScroller": {
            "&::-webkit-scrollbar": {
              width: "3px",
              height: "3px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#b0d2da",
              borderRadius: "3px",
              "&:hover": {
                backgroundColor: "#85bac6",
              },
            },
          },
        }}
      >
        {!loading ? (
          <>
            {!NoDataFoundBool ? (
              <Box
                style={{
                  width: "100%",
                  overflowX: "auto",
                  padding: "12px",
                }}
              >
                {apiError ? (
                  <Box
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      flex: "12",
                    }}
                  >
                    {apiError}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      padding: 2,
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    <Grid container xs={12} columnSpacing={1}>
                      <Grid item xs={12} md={6}>
                        <Card
                          sx={{ borderRadius: 2, boxShadow: 2, padding: 1 }}
                        >
                          {[
                            {
                              label: "Views",
                              value: data?.[0]?.views,
                            },
                            {
                              label: "Subscribers Gained",
                              value: data?.[0]?.subscribersGained,
                            },
                            {
                              label: "Subscribers Lost",
                              value: data?.[0]?.subscribersLost,
                            },
                            {
                              label: "Annotation click rate",
                              value: data?.[0]?.annotationClickThroughRate,
                            },
                            {
                              label: "Shared",
                              value: data?.[0]?.shares,
                            },
                            {
                              label: "Likes",
                              value: data?.[0]?.likes,
                            },
                            {
                              label: "Dislikes",
                              value: data?.[0]?.dislikes,
                            },
                          ]?.map((item) => (
                            <Grid
                              item
                              xs={12}
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <Typography
                                component={"span"}
                                sx={{ fontWeight: "bold", fontSize: 12 }}
                              >
                                {item.label}
                              </Typography>
                              <Typography component={"span"}>
                                {item.value}
                              </Typography>
                            </Grid>
                          ))}
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Card
                          sx={{ borderRadius: 2, boxShadow: 2, padding: 1 }}
                        >
                          {[
                            {
                              label: "Minutes Wathed (Est)",
                              value: data?.[0]?.estimatedMinutesWatched,
                            },
                            {
                              label: "Teaser Impressions",
                              value: data?.[0]?.cardTeaserImpressions,
                            },
                            {
                              label: "Teaser Clicks ",
                              value: data?.[0]?.cardTeaserClicks,
                            },
                            {
                              label: "Card Impressions",
                              value: data?.[0]?.cardImpressions,
                            },
                            {
                              label: "Card Clicks",
                              value: data?.[0]?.cardClicks,
                            },
                            {
                              label: "Avg. View %",
                              value: data?.[0]?.averageViewPercentage,
                            },
                            {
                              label: "Avg. View Duration",
                              value: data?.[0]?.averageViewDuration,
                            },
                          ]?.map((item) => (
                            <Grid
                              item
                              xs={12}
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <Typography
                                component={"span"}
                                sx={{ fontWeight: "bold", fontSize: 12 }}
                              >
                                {item.label}
                              </Typography>
                              <Typography component={"span"}>
                                {item.value}
                              </Typography>
                            </Grid>
                          ))}
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            ) : (
              <NoDataFound />
            )}
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default YoutubeProfileSummary;
