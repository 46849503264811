export function ReturnFeedData({ ticketData, index, item }) {
  let feedData = null;
  let feedSourceData = null;
  let feedId = null;
  let customerName = "";
  let accountName = "";
  let activityName = "";
  let feedTimeValue = "";
  let customerAvatarUrl = "";

  let sentimentValue = null;
  let feedLink = "";
  let feedReplies = [];

  const { platform, account_name, customer_id, activities } =
    ticketData?._source;
  const { platform_name } = platform;
  const { activities_name } = activities;

  feedData = item;
  feedSourceData = feedData?._source;
  feedId = item?._id;
  activityName = feedSourceData?.activities;

  if (platform_name === "Facebook") {
    activityName = feedSourceData?.activities;
    sentimentValue = feedSourceData?.sentiment_value;

    if (item?._index === "facebook_webhook") {
      feedLink = feedSourceData?.feed_link;
      feedReplies = feedSourceData?.replies;
      accountName = account_name?.account_name;

      if (activityName !== "Post Mentions") {
        customerName = feedSourceData?.customer_name;
        feedTimeValue = feedSourceData?.comment_created_at;
        customerAvatarUrl = customer_id?.customer_profile_url;
      } else {
        customerName = feedSourceData?.user_name;
        feedTimeValue = feedSourceData?.post_created_at;
        customerAvatarUrl = customer_id?.customer_profile_url;
      }
    }
    if (item?._index === "facebook_direct_messages") {
      if (feedSourceData?.flag === "received") {
        customerName = feedSourceData?.sender_name;
        accountName = feedSourceData?.account_name;
        feedTimeValue = feedSourceData?.message_at;
      } else {
        feedReplies.push(feedData);
      }
    }
  }

  if (platform_name === "Twitter") {
    accountName = feedSourceData?.account_name;
    sentimentValue = feedSourceData?.sentiment_value;

    if (item?._index === "twitter") {
      customerName =
        feedSourceData?.customer_name +
        ` (@${feedSourceData?.customer_handel})`;
      activityName = feedSourceData?.tweet_type;
      feedTimeValue = feedSourceData?.created;
      customerAvatarUrl = customer_id?.customer_profile_url;
      feedLink = feedSourceData?.feed_link;
      feedReplies = feedSourceData?.agent_replies;
    }
    if (item?._index === "twitter_direct_messages") {
      if (feedSourceData?.flag === "received") {
        activityName = feedSourceData?.activities;
        feedTimeValue = feedSourceData?.message_at.split("Z")[0];
        customerAvatarUrl = feedSourceData?.customer_profile_pic
          ? feedSourceData?.customer_profile_pic
          : customer_id?.customer_profile_url;
        customerName =
          feedSourceData?.sender_name +
          ` (@${feedSourceData?.customer_handle})`;
      } else {
        feedReplies.push(feedData);
      }
    }
  }

  if (platform_name === "Instagram") {
    if (feedData?._index === "instagram_feed") {
      customerName = customer_id?.customer_name;
      accountName = feedSourceData?.account_name;
      activityName = feedSourceData?.activities;

      feedTimeValue = feedSourceData?.comment_created_time
        ? feedSourceData?.comment_created_time
        : feedSourceData?.parent_post_created_time
        ? feedSourceData?.parent_post_created_time
        : "";
      customerAvatarUrl = customer_id?.customer_profile_url;
      sentimentValue = feedSourceData?.sentiment_value;
      feedLink = feedSourceData?.feed_link;

      feedSourceData?.replies_for_comment &&
        (feedReplies = feedSourceData?.replies_for_comment);
    } else if (feedData?._index === "instagram_direct") {
      if (feedSourceData?.flag === "received") {
        feedTimeValue = feedSourceData?.message_recived_on;
        customerName = feedSourceData?.customer_username;
        accountName = feedSourceData?.account_username;
        activityName = feedSourceData?.activities;
        customerAvatarUrl = customer_id?.customer_profile_url;
      } else {
        feedReplies.push(feedData);
      }
    }
  }

  if (platform_name === "Ambition Box") {
    activityName = feedSourceData?.activities;
    customerName = customer_id?.customer_name;
    feedTimeValue = feedSourceData?.posted_on;
    customerAvatarUrl = customer_id?.customer_profile_url;
    accountName = feedSourceData?.account_name;
    sentimentValue = feedSourceData?.sentiment_value;
  }

  if (platform_name === "GMB") {
    accountName = feedSourceData?.account_name;
    sentimentValue = feedSourceData?.sentiment_value;
    feedLink = feedSourceData?.gmb_profile_link;
    activityName = feedSourceData.activities;

    if (item._index === "gmb_questions_answers") {
      customerName = feedSourceData?.question_author_name;
      feedTimeValue = feedSourceData?.question_created_at;
      customerAvatarUrl = feedSourceData?.question_author_profile_pic;
      feedReplies = feedSourceData?.answers;
    }

    if (item._index === "gmb_review") {
      customerName = feedSourceData?.reviewer_name;
      feedTimeValue = feedSourceData?.created_on;
      customerAvatarUrl = feedSourceData?.reviewer_photo;
      feedSourceData?.review_replay_on &&
        feedReplies.push({
          reply_time: feedSourceData?.review_replay_on,
          reply_content: feedSourceData?.review_replay,
        });
    }
  }

  if (platform_name === "LinkedIn") {
    customerName =
      customer_id?.customer_name + ` (@${customer_id?.customer_name})`;
    accountName = feedSourceData?.account_name;
    activityName = feedSourceData?.activities;
    feedTimeValue = feedSourceData?.created_at;
    customerAvatarUrl = customer_id?.customer_profile_url;
    sentimentValue = feedSourceData?.sentiment_value;
    feedLink = feedSourceData?.feed_link;
    feedReplies = feedSourceData?.replies;
  }

  if (platform_name === "Youtube") {
    activityName = feedSourceData?.activities;

    if (activityName === "Comments") {
      customerName = feedSourceData?.commented_username;
      feedTimeValue = feedSourceData?.comment_created_time;
      customerAvatarUrl = feedSourceData?.commented_user_image_url;
    }

    if (activityName === "Videos") {
      customerName = feedSourceData?.customer_name;
      feedTimeValue = feedSourceData?.video_published_date;
    }
    accountName = feedSourceData?.account_name;
    sentimentValue = feedSourceData?.sentiment_value;
    feedLink = feedSourceData?.video_url;
    feedReplies = feedSourceData?.replies;
  }

  if (platform_name === "Playstore") {
    activityName = feedSourceData.activities;
    customerName = feedSourceData?.customer_name;
    accountName = feedSourceData?.app_name;
    feedTimeValue = feedSourceData?.review_created_at;
    customerAvatarUrl = feedSourceData?.customer_image;
    sentimentValue = feedSourceData?.sentiment_value;
    feedLink = feedSourceData?.feed_link;
    feedReplies = feedSourceData?.reply_content
      ? [
          {
            reply_content: feedSourceData?.reply_content,
            reply_created_at: feedSourceData?.reply_created_at,
          },
        ]
      : [];
  }

  if (platform_name === "Appstore") {
    customerName = feedSourceData?.customer_name;
    accountName = feedSourceData?.app_name;
    feedTimeValue = feedSourceData?.review_date;
    customerAvatarUrl = feedSourceData?.customer_image;
    sentimentValue = feedSourceData?.sentiment_value;
    feedLink = feedSourceData?.app_url;
    feedReplies = feedSourceData?.reply_content
      ? [
          {
            reply_content: feedSourceData?.reply_content,
            reply_created_at: feedSourceData?.reply_created_at,
          },
        ]
      : [];
  }

  if (platform_name === "Glassdoor") {
    activityName = feedSourceData?.activities;
    customerName = feedSourceData?.customer_name;
    accountName = feedSourceData?.account_name;
    feedTimeValue = feedSourceData?.date;
    sentimentValue = feedSourceData?.sentiment_value;
  }

  if (platform_name === "Email") {
    activityName = feedSourceData?.activities;
    customerName = feedSourceData?.customer_name;
    accountName = feedSourceData?.account_name;
    feedTimeValue = feedSourceData?.date;
    sentimentValue = feedSourceData?.sentiment_value;
    feedReplies = feedSourceData?.replies;
  }

  if (platform_name === "Consumer Complaints") {
    activityName = feedSourceData?.activities;
    customerName = feedSourceData?.customer_name;
    accountName = feedSourceData?.account_name;
    feedTimeValue = feedSourceData?.created_on;
    sentimentValue = feedSourceData?.sentiment_value;
    feedLink = feedSourceData?.link;
  }

  if (platform_name === "Quora") {
    customerName = feedSourceData?.customer_name;
    accountName = feedSourceData?.account_name;
    activityName = feedSourceData?.activities;
    feedTimeValue = feedSourceData?.created_on;
    sentimentValue = feedSourceData?.sentiment_value;
    feedLink = feedSourceData?.question_url;
  }

  if (platform_name === "Trust Pilot") {
    activityName = feedSourceData?.activities;
    customerName = feedSourceData?.customer_name;
    accountName = account_name?.account_name;
    feedTimeValue = feedSourceData?.created_at;
    customerAvatarUrl = customer_id?.customer_profile_url;
    sentimentValue = feedSourceData?.sentiment_value;
    feedLink = feedSourceData?.feed_link;
  }

  if (platform_name === "Pinterest") {
    activityName = feedSourceData?.activities;
    customerName = feedSourceData?.commented_username;
    accountName = feedSourceData?.account_name;
    feedTimeValue = feedSourceData?.commented_on;
    feedLink = feedSourceData?.feed_link;
  }

  if (platform_name === "Indeed") {
    customerName = feedSourceData?.customer_name;
    accountName = feedSourceData?.account_name;
    activityName = feedSourceData?.activities;
    feedTimeValue = feedSourceData?.created_on;
    sentimentValue = feedSourceData?.sentiment_value;
    feedLink = feedSourceData?.url;
  }

  if (platform_name === "Threads") {
    customerName = feedSourceData?.customer_name;
    accountName = feedSourceData?.account_name;
    feedTimeValue = feedSourceData?.thread_created_datetime;
    sentimentValue = feedSourceData?.sentiment_value;
    feedLink = feedSourceData?.feed_link;
    feedReplies = feedSourceData?.agent_replies;
  }

  if (platform_name === "Reddit") {
    accountName = feedSourceData?.account_name;
    activityName = feedSourceData?.activities;
    feedLink = feedSourceData?.link;

    if (feedData?._index === "reddit_posts") {
      feedTimeValue = feedSourceData?.posted_on;
      customerName = feedSourceData?.posted_by;
    }
    if (feedData?._index === "reddit_comments") {
      customerName = feedSourceData?.commented_by;
      feedTimeValue = feedSourceData?.commented_on;
      feedReplies = feedSourceData?.replies;
    }
  }

  if (platform_name === "Telegram") {
    activityName = feedSourceData?.activities ?? "Direct Message";

    if (feedSourceData?.flag === "received") {
      customerName = feedSourceData?.customer_name;
      accountName = feedSourceData?.account_name;
      feedTimeValue = feedSourceData?.message_recieved_on;
      sentimentValue = feedSourceData?.sentiment_value;
      feedReplies = feedSourceData?.replies;
    } else {
      feedReplies.push(feedData);
    }
  }
  if (platform_name === "Discord") {
    activityName = feedSourceData?.activities ?? "Direct Message";

    if (feedSourceData?.flag === "received") {
      customerName = feedSourceData?.customer_name ?? "";
      accountName = feedSourceData?.account_name;
      feedTimeValue = feedSourceData?.message_recieved_on;
      sentimentValue = feedSourceData?.sentiment_value;
      feedReplies = feedSourceData?.replies;
    } else {
      // feedReplies.push(feedData);
    }
  }

  if (platform_name === "Whatsapp") {
    activityName = activities_name ?? "Direct Message";

    if (feedSourceData?.flag === "received") {
      customerName = feedSourceData?.customer_name ?? "";
      accountName = feedSourceData?.account_name;
      feedTimeValue = feedSourceData?.message_recieved_on;
      sentimentValue = feedSourceData?.sentiment_value;
      feedReplies = feedSourceData?.replies;
    } else {
      feedReplies.push(feedData);
    }
  }

  return {
    customerName,
    accountName,
    activityName,
    feedTimeValue,
    customerAvatarUrl,
    sentimentValue,
    feedLink,
    feedId,
    feedSourceData,
    platform_name,
    feedReplies,
    feedData,
  };
}

export function SortFeedsBasedOnTime({ platform, allFeeds }) {
  if (platform === "Facebook") {
    allFeeds = allFeeds.map((item) => {
      let feedCreatedTime = item.feedCreatedTime;

      if (item._index === "facebook_webhook") {
        if (item._source.activities !== "Post Mentions") {
          feedCreatedTime = item._source.comment_created_at;
        } else {
          feedCreatedTime = item._source.post_created_at;
        }
      } else if (item._index === "facebook_direct_messages") {
        feedCreatedTime = item._source.message_at;
      }

      return { ...item, feedCreatedTime };
    });
  }
  if (platform === "Youtube") {
    allFeeds = allFeeds.map((item) => {
      let feedCreatedTime = item.feedCreatedTime;

      if (item._source.activities === "Comments") {
        feedCreatedTime = item._source.comment_created_time;
      } else if (item._source.activities === "Videos") {
        feedCreatedTime = item._source.video_published_date;
      }

      return { ...item, feedCreatedTime };
    });
  }
  if (platform === "Twitter") {
    allFeeds = allFeeds.map((item) => {
      let feedCreatedTime = item.feedCreatedTime;

      if (item._index === "twitter") {
        feedCreatedTime = item._source.created;
      } else if (item._index === "twitter_direct_messages") {
        feedCreatedTime = item._source.message_at.split("Z")[0];
      }

      return { ...item, feedCreatedTime };
    });
  }

  if (platform === "Instagram") {
    allFeeds = allFeeds.map((item) => {
      let feedCreatedTime = item.feedCreatedTime;

      if (item._index === "instagram_feed") {
        feedCreatedTime = item._source.comment_created_time
          ? item._source.comment_created_time
          : item._source.parent_post_created_time;
      } else if (item._index === "instagram_direct") {
        if (item._source.flag === "received") {
          feedCreatedTime = item._source.message_recived_on;
        } else {
          feedCreatedTime = item._source.message_sent_on;
        }
      }
      return { ...item, feedCreatedTime };
    });
  }
  if (platform === "LinkedIn") {
    allFeeds = allFeeds.map((item) => {
      let feedCreatedTime = item.feedCreatedTime;

      if (item._index === "linkedin_feed") {
        feedCreatedTime = item._source.created_at;
      }
      return { ...item, feedCreatedTime };
    });
  }
  if (platform === "Reddit") {
    allFeeds = allFeeds.map((item) => {
      let feedCreatedTime = item.feedCreatedTime;
      if (item._index === "reddit_posts") {
        feedCreatedTime = item._source.posted_on;
      } else if (item._index === "reddit_comments") {
        feedCreatedTime = item._source.commented_on;
      }
      return { ...item, feedCreatedTime };
    });
  }

  if (platform === "Appstore") {
    allFeeds = allFeeds.map((item) => {
      let feedCreatedTime = item.feedCreatedTime;
      feedCreatedTime = item._source.created_on;

      return { ...item, feedCreatedTime };
    });
  }

  if (platform === "GMB") {
    allFeeds = allFeeds.map((item) => {
      let feedCreatedTime = item.feedCreatedTime;
      if (item._index === "gmb_questions_answers") {
        feedCreatedTime = item._source.question_created_at;
      } else if (item._index === "gmb_review") {
        feedCreatedTime = item._source.created_on;
      }
      return { ...item, feedCreatedTime };
    });
  }

  if (platform === "Playstore") {
    allFeeds = allFeeds.map((item) => {
      let feedCreatedTime = item.feedCreatedTime;
      feedCreatedTime = item._source.review_created_at;
      return { ...item, feedCreatedTime };
    });
  }

  if (platform === "Telegram") {
    allFeeds = allFeeds.map((item) => {
      let feedCreatedTime = item.feedCreatedTime;
      feedCreatedTime = item._source.message_recieved_on;
      return { ...item, feedCreatedTime };
    });
  }

  if (platform === "Ambition Box") {
    allFeeds = allFeeds.map((item) => {
      let feedCreatedTime = item.feedCreatedTime;
      feedCreatedTime = item._source.posted_on;
      return { ...item, feedCreatedTime };
    });
  }

  if (platform === "Consumer Complaints") {
    allFeeds = allFeeds.map((item) => {
      let feedCreatedTime = item.feedCreatedTime;
      feedCreatedTime = item._source.created_on;
      return { ...item, feedCreatedTime };
    });
  }

  if (platform === "Discord") {
    allFeeds = allFeeds.map((item) => {
      let feedCreatedTime = item.feedCreatedTime;
      feedCreatedTime = item._source.message_recieved_on;
      return { ...item, feedCreatedTime };
    });
  }

  if (platform === "Glassdoor") {
    allFeeds = allFeeds.map((item) => {
      let feedCreatedTime = item.feedCreatedTime;
      feedCreatedTime = item._source.date;
      return { ...item, feedCreatedTime };
    });
  }

  if (platform === "Indeed") {
    allFeeds = allFeeds.map((item) => {
      let feedCreatedTime = item.feedCreatedTime;
      feedCreatedTime = item._source.created_on;
      return { ...item, feedCreatedTime };
    });
  }
  if (platform === "Email") {
    allFeeds = allFeeds.map((item) => {
      let feedCreatedTime = item.feedCreatedTime;
      feedCreatedTime = item._source.date;
      return { ...item, feedCreatedTime };
    });
  }

  if (platform === "Pinterest") {
    allFeeds = allFeeds.map((item) => {
      let feedCreatedTime = item.feedCreatedTime;
      feedCreatedTime = item._source.commented_on;
      return { ...item, feedCreatedTime };
    });
  }

  if (platform === "Quora") {
    allFeeds = allFeeds.map((item) => {
      let feedCreatedTime = item.feedCreatedTime;
      feedCreatedTime = item._source.created_on;
      return { ...item, feedCreatedTime };
    });
  }

  if (platform === "Threads") {
    allFeeds = allFeeds.map((item) => {
      let feedCreatedTime = item.feedCreatedTime;
      feedCreatedTime = item._source.thread_created_datetime;
      return { ...item, feedCreatedTime };
    });
  }

  if (platform === "Trust Pilot") {
    allFeeds = allFeeds.map((item) => {
      let feedCreatedTime = item.feedCreatedTime;
      feedCreatedTime = item._source.created_at;
      return { ...item, feedCreatedTime };
    });
  }
  if (platform === "Whatsapp") {
    allFeeds = allFeeds.map((item) => {
      let feedCreatedTime = item.feedCreatedTime;
      feedCreatedTime = item._source.message_recieved_on;
      return { ...item, feedCreatedTime };
    });
  }

  allFeeds = allFeeds?.sort(
    (a, b) => new Date(a.feedCreatedTime) - new Date(b.feedCreatedTime)
  );

  return allFeeds; // Ensure the function returns the modified array
}

export function returnFeedKeyToMatchTicketDesc({ platform, feedData }) {
  let feedKeycheck;
  if (platform === "Facebook") {
    if (feedData._index === "facebook_webhook") {
      if (feedData._source.activities !== "Post Mentions") {
        feedKeycheck = "comment_content";
      } else {
        feedKeycheck = "post_content";
      }
    } else if (feedData._index === "facebook_direct_messages") {
      feedKeycheck = "message_text";
    }
  }

  if (platform === "Youtube") {
    if (feedData._source.activities === "Videos") {
      feedKeycheck = "video_title";
    }
    if (feedData._source.activities === "Comments") {
      feedKeycheck = "comment_content";
    }
  }

  if (platform === "Twitter") {
    if (feedData._index === "twitter") {
      feedKeycheck = "content_of_the_tweet";
    } else if (feedData._index === "twitter_direct_messages") {
      feedKeycheck = "message_text";
    }
  }

  if (platform === "Instagram") {
    if (feedData._index === "instagram_feed") {
      feedKeycheck = "feed_id";
    } else if (feedData._index === "instagram_direct") {
      feedKeycheck = "message_text";
    }
  }
  if (platform === "LinkedIn") {
    if (feedData._index === "linkedin_feed") {
      feedKeycheck = "feed_id";
    }
  }
  if (platform === "Reddit") {
    if (feedData._index === "reddit_posts") {
      feedKeycheck = "description";
    } else if (feedData._index === "reddit_comments") {
      feedKeycheck = "description";
    }
  }
  if (platform === "Appstore") {
    feedKeycheck = "review_heading";
  }
  if (platform === "GMB") {
    if (feedData._index === "gmb_questions_answers") {
      feedKeycheck = "question";
    } else if (feedData._index === "gmb_review") {
      feedKeycheck = "review_comment";
    }
  }
  if (platform === "Appstore") feedKeycheck = "review_heading";

  if (platform === "Playstore") feedKeycheck = "review_content";

  if (platform === "Telegram") feedKeycheck = "message_content";

  if (platform === "Consumer Complaints") feedKeycheck = "description";

  if (platform === "Discord") feedKeycheck = "message_content";

  if (platform === "Glassdoor") feedKeycheck = "reviews";

  if (platform === "Indeed") feedKeycheck = "rating_description";

  if (platform === "Email") feedKeycheck = "subject";

  if (platform === "Pinterest") feedKeycheck = "rating_description";

  if (platform === "Quora") feedKeycheck = "answer";

  if (platform === "Threads") feedKeycheck = "thread_content";

  if (platform === "Trust Pilot") feedKeycheck = "rating_description";

  if (platform === "Whatsapp") feedKeycheck = "message_content";

  return feedKeycheck;
}
