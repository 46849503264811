import BaseReducer from "../../../../utilities/BaseReducer";
import RevenueThresoldAction from "./RevenueThresoldAction";

export default class RevenueThresoldReduser extends BaseReducer {
  initialState = {
    RevenueThresold: [],
  };
  [RevenueThresoldAction.REQUEST_REVENUETHRESOLD_FINISHED](state, action) {
    return {
      ...state,
      RevenueThresold: action.payload,
    };
  }
}
