import BaseReducer from "../../../utilities/BaseReducer";
import FacebookAction from "./FacebookAction";

export default class FacebookPostMentionsReducer extends BaseReducer {
  initialState = {
    facebook_postMentions: [],
  };

  [FacebookAction.REQUEST_FACEBOOK_POST_MENTIONS_FINISHED](state, action) {
    return {
      ...state,
      facebook_postMentions: action.payload,
    };
  }
}
