import { Box, Grid } from "@mui/material";
import React from "react";
import { theme } from "../../../../../views/App";

const ReturnDeletedStatement = ({ componentProps, feed_item }) => {
  let allowed_platforms = ["LinkedIn", "Playstore", "GMB", "Facebook"];

  return componentProps?.ticketData._source.assigned_to.user_id !== "Unassigned"
    ? allowed_platforms?.includes(
        componentProps?.ticketData._source.platform.platform_name
      ) &&
        (componentProps?.ticketData._source.platform.platform_name ===
        "Facebook"
          ? feed_item?._source?.is_deleted
          : feed_item?._source?.feed_deleted) && (
          <Grid item container xl={12}>
            <Box
              width={"100%"}
              sx={{
                paddingX: 0,
                paddingY: 0,
              }}
              // className="bg-success"
            >
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                alignItems="center"
                justifyContent={"flex-start"}
              >
                <Grid
                  item
                  textAlign={"left"}
                  sx={{
                    backgroundColor: "white",
                    // border: `solid 0.5px ${theme.palette.text.error}`,
                    fontFamily: "BookAntiqua-Italic",
                    borderRadius: 1,
                    fontSize: "12px",
                  }}
                  // px={2}
                >
                  <span
                    style={{
                      color: `${theme.palette.text.error}`,
                      // fontWeight: "bold",
                    }}
                  >
                    <span
                      style={{
                        opacity: 1,
                      }}
                    >{`User has deleted this `}</span>
                    <span
                      style={{
                        opacity: 1,
                        color: `${theme.palette.text.error}`,
                      }}
                    >
                      '{feed_item?._source?.activities}'
                    </span>
                    &nbsp;
                    <span
                      style={{
                        opacity: 1,
                      }}
                    >{`from the console.`}</span>
                    &nbsp;
                  </span>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )
    : null;
};

export default ReturnDeletedStatement;
