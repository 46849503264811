import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { connect } from "react-redux";
import { format } from "date-fns";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import TwitterAction from "../../../stores/twitterFeeds/TwitterAction";
import { BorderContainer } from "../../../../components/componentLayout/ComponentLayout";
import AutoCompleteComponent from "../../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../../components/muiButton/MuiButton";
import DateRangeStyled from "../../../../components/dateRangePickerComponent/DateRangePickerStyled";
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    TwitterAction.REQUEST_TWITTER,
    TwitterAction.REQUEST_TWITTER_FILTER,
  ]),
});

function DateDurationPopper(props) {
  // const [open, setOpen] = React.useState(false);
  // const statePopperRef = React.useRef(null);
  // const prevOpen = React.useRef(open);
  let maxDate = new Date();

  const [date, setDate] = useState([null, null]);
  const [page_limit] = useState(10);

  const dateValueHandleChange = (e, v) => {
    let date1 = e[0] && e[0].$d;
    let date2 = e[1] && e[1].$d;
    setDate([date1, date2]);
  };

  // ****************** DATE value handle change *********************
  const today = new Date();

  let date1 = new Date();
  let previousday = new Date(date1);
  previousday.setDate(previousday.getDate() - 1);

  //Last 2 days filter
  let last2days = [...Array(2).keys()].map((index) => {
    const date = new Date();
    date.setDate(date.getDate() - index);
    return date;
  });

  // start from today's date last 7 days
  const last7Days = [...Array(7).keys()].map((index) => {
    const date = new Date();
    date.setDate(date.getDate() - index);

    return date;
  });

  // last 30 days
  let date2 = new Date();
  let last30Days = new Date(new Date().setDate(date2.getDate() - 30));

  const [createdDatelistSelected, setCreatedDatelistSelected] = useState(() => {
    if (localStorage.getItem("competatorAnalysisDataParameters")) {
      return JSON.parse(
        localStorage.getItem("competatorAnalysisDataParameters")
      ).createdDatelistSelected;
    } else {
      return null;
    }
  });

  const [createdDate, setCreatedDate] = useState(() => {
    if (localStorage.getItem("competatorAnalysisDataParameters")) {
      return JSON.parse(
        localStorage.getItem("competatorAnalysisDataParameters")
      ).createdDate;
    } else {
      return null;
    }
  });

  const [modifiedDatelistSelected, setModifiedDatelistSelected] = useState(
    () => {
      if (localStorage.getItem("analysisFilters")) {
        return JSON.parse(localStorage.getItem("analysisFilters"))
          .modifiedDatelistSelected;
      } else {
        return null;
      }
    }
  );

  const [modifiedDate, setModifiedDate] = useState(() => {
    if (localStorage.getItem("analysisFilters")) {
      return [
        JSON.parse(localStorage.getItem("analysisFilters")).modifiedDate &&
        JSON.parse(localStorage.getItem("analysisFilters")).modifiedDate[0]
          ? new Date(
              JSON.parse(
                localStorage.getItem("analysisFilters")
              ).modifiedDate[0]
            )
          : null,
        JSON.parse(localStorage.getItem("analysisFilters")).modifiedDate &&
        JSON.parse(localStorage.getItem("analysisFilters")).modifiedDate[1]
          ? new Date(
              JSON.parse(
                localStorage.getItem("analysisFilters")
              ).modifiedDate[1]
            )
          : null,
      ];
    } else {
      return [null, null];
    }
  });

  useEffect(() => {}, [props.popopen]);

  // created date  filter setstate function
  const createdDateHandle = (event, createdDate) => {
    if (createdDate !== null) {
      setCreatedDatelistSelected(createdDate);
    } else {
      setCreatedDatelistSelected(null);
      setCreatedDate([null, null]);
    }
  };

  const createdDateValueHandleChange = (e, v) => {
    setCreatedDate(e);
  };

  const modifiedOnDateHandle = (event, modifiedDate) => {
    setModifiedDatelistSelected(modifiedDate);
  };

  const modifiedOnValueHandleChange = (e, v) => {
    setModifiedDate(e);
  };

  //****************** dispatch functions   *******************
  function fetchTwitter(params) {
    props.dispatch(TwitterAction.requestTwitter(params));
  }

  //**********************************************************

  async function handleSaveFilters() {
    // created_time filter
    let filters = JSON.parse(
      localStorage.getItem("competatorAnalysisDataParameters")
    );
    if (createdDate) filters.createdDate = createdDate;
    if (createdDatelistSelected) {
      if (createdDatelistSelected !== "Custom") {
        filters.createdDate = [null, null];
      }
      filters.createdDatelistSelected = createdDatelistSelected;
    }

    localStorage.setItem(
      "competatorAnalysisDataParameters",
      JSON.stringify(filters)
    );
    props.fetchTicketCount();

    let object_new = {};

    Object.assign(
      object_new,
      props.filterContents
        ? {
            ...props.filterContents,
            createdDate: createdDate,
            createdDatelistSelected: createdDatelistSelected,
          }
        : {
            createdDate: createdDate,
            createdDatelistSelected: createdDatelistSelected,
          }
    );

    props.setFilterContents(
      props.filterContents
        ? {
            ...props.filterContents,
            createdDate: createdDate,
            createdDatelistSelected: createdDatelistSelected,
          }
        : {
            createdDate: createdDate,
            createdDatelistSelected: createdDatelistSelected,
          }
    );
    props.applyFilter(object_new);
  }

  function applyFilter() {
    let params = {};
    const filter = [];

    function TodayFilter() {
      return `["created_on","must","lte","range","${format(
        new Date(),
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        today,
        "yyyy-MM-dd"
      )}"]`;
    }

    function PreviousFilter() {
      return `["created_on","must","lte","range","${format(
        previousday,
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        previousday,
        "yyyy-MM-dd"
      )}"]`;
    }

    function Last2DaysFilter() {
      return `["created_on","must","lte","range","${format(
        last2days[0],
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        last2days[1],
        "yyyy-MM-dd"
      )}"]`;
    }

    function Last7DaysFilter() {
      return `["created_on","must","lte","range","${format(
        last7Days[0],
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        last7Days[6],
        "yyyy-MM-dd"
      )}"]`;
    }

    function Last30DaysFilter() {
      return `["created_on","must","lte","range","${format(
        today,
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        last30Days,
        "yyyy-MM-dd"
      )}"]`;
    }

    function CustomDateFilter() {
      return `["created_on","must","lte","range","${format(
        createdDate[1].$d,
        "yyyy-MM-dd"
      )}"],["created_on","must","gte","range","${format(
        createdDate[0].$d,
        "yyyy-MM-dd"
      )}"]`;
    }

    if (createdDatelistSelected === "Today") {
      filter.push(TodayFilter());
    }

    if (createdDatelistSelected === "Previous Day") {
      filter.push(PreviousFilter());
    }

    if (createdDatelistSelected === "Last 2 Days") {
      filter.push(Last2DaysFilter());
    }
    if (createdDatelistSelected === "Last 7 Days") {
      filter.push(Last7DaysFilter());
    }
    if (createdDatelistSelected === "Last 30 Days") {
      filter.push(Last30DaysFilter());
    }

    if (createdDatelistSelected === "Custom") {
      filter.push(CustomDateFilter());
    }

    params = {
      filters: `[${filter}]`,
      page_number: 0,
      page_limit: `${page_limit}`,
      order_by: '[["created", "desc"]]',
    };

    if (createdDate) {
      let filters = JSON.parse(
        localStorage.getItem("competatorAnalysisDataParameters")
      );
      filters.createdDate = createdDate;
      localStorage.setItem(
        "competatorAnalysisDataParameters",
        JSON.stringify(filters)
      );
    }
  }

  function handleClearFilters() {
    setDate([null, null]);
    setCreatedDatelistSelected(null);
    setCreatedDate([null, null]);

    let filters = JSON.parse(
      localStorage.getItem("competatorAnalysisDataParameters")
    );
    filters.createdDate = [null, null];
    filters.createdDatelistSelected = null;
    localStorage.setItem(
      "competatorAnalysisDataParameters",
      JSON.stringify(filters)
    );

    const params = {
      order_by: '[["created", "desc"]]',
      page_limit: `${page_limit}`,
    };
  }

  return (
    <BorderContainer>
      <ClickAwayListener onClickAway={props.handleClose}>
        <div
          autoFocusItem={props.popopen}
          id="composition-menu"
          aria-labelledby="composition-button"
        >
          <Grid
            container
            sx={{
              height: 250,
            }}
          >
            <Typography
              component={"p"}
              className="dashboard-title"
              sx={{ fontSize: 18 }}
            >
              {"Date Duration".split(" ").map((item) => (
                <span className="dashboard-title">{item}</span>
              ))}
            </Typography>

            <Grid
              item
              lg={12}
              md={12}
              laptop={12}
              columnGap={2}
              rowGap={1}
              sx={
                {
                  // marginTop: 2,
                }
              }
              display="flex"
              justifyContent={"space-evenly"}
            >
              <Box>
                <Grid item lg={12} md={12} laptop={12} textAlign="left">
                  <Typography component={"p"}>Created On</Typography>
                  <AutoCompleteComponent
                    list={props.dateSelecte}
                    textFieldProps={{
                      placeholder: "Created Date",
                    }}
                    value={createdDatelistSelected}
                    handleChange={createdDateHandle}
                    size="small"
                  />
                </Grid>
                {createdDatelistSelected === "Custom" && (
                  <Grid item lg={12} md={12} laptop={12} marginTop={2}>
                    {/* <DatePicker
                    value={createdDate}
                    onChange={createdDateValueHandleChange}
                  /> */}

                    <DateRangeStyled
                      value={createdDate}
                      onChange={createdDateValueHandleChange}
                      maxDate={maxDate}
                    />
                  </Grid>
                )}
              </Box>
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              laptop={12}
              columnGap={2}
              rowGap={1}
              sx={{
                marginY: 5,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Grid item>
                <MuiButton
                  variant="outlined"
                  width={"103px"}
                  height={"35px"}
                  type="submit"
                  name={"Reset"}
                  loading={false}
                  backgroundColor={"white"}
                  noTextOnLoading={true}
                  onClick={() => {
                    handleClearFilters();
                  }}
                />
              </Grid>
              <Grid item>
                <MuiButton
                  variant="contained"
                  width={"103px"}
                  height={"35px"}
                  type="submit"
                  name={"Apply"}
                  loading={false}
                  noTextOnLoading={true}
                  onClick={() => {
                    handleSaveFilters();
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </ClickAwayListener>
    </BorderContainer>
  );
}

export default connect(mapStateToProps)(DateDurationPopper);
