import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { Box } from "@mui/material";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { prefixZero_for_singleDigit } from "../../../components/ticketPartials";
import DataGridFunction from "../../../components/datagridComponent/datagrid";

function ShareOfVoiceTrend(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    setData(null);
    (async function CallApi() {
      setLoading(true);

      await fetchData(props.openDateFilter);

      setLoading(false);
    })();
  }, [props.openDateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      setData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.openDateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      setData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.filterClicked, props.openDateFilter]);

  async function fetchData(dateRange) {
    let API = `${
      environment.api.brandCompetitorReport
    }?reports=["share_of_voice_trend"]${
      props.openDateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;
    await axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (
          response.hasOwnProperty("error") ||
          response.hasOwnProperty("error_id")
        ) {
          response.hasOwnProperty("error_id")
            ? setApiError(`${response?.message} - ${response?.error_id}`)
            : setApiError(response?.error);
          setData(null);
        } else {
          setData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setData(null);
        setApiError(err.message);
        setLoading(false);
      });
  }

  let NoDataFoundBool = !data;

  // Define the correct order of intervals
  const intervalOrder = [
    "00AM-04AM",
    "04AM-08AM",
    "08AM-12PM",
    "12PM-04PM",
    "04PM-08PM",
    "08PM-00AM",
  ];

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];
      rows?.forEach((item) => {
        exportData.push(item);
      });

      props.setexcelData(exportData);
    }
  }, [props.DownloadClicked, data]);

  let rows = [];

  if (data?.brand_competitor_reports?.share_of_voice_trend) {
    data?.brand_competitor_reports?.share_of_voice_trend?.length > 0 &&
      (data?.brand_competitor_reports?.share_of_voice_trend).map(
        (item, index) => {
          let row = { id: index + 1, Brand: item.key };

          // Initialize all intervals with 0
          intervalOrder.forEach((interval) => {
            row[interval] = 0;
          });

          // Populate the actual values from the intervals
          item.intervals.forEach((interval) => {
            const [key, value] = Object.entries(interval)[0]; // Extract key-value pair
            if (row.hasOwnProperty(key)) {
              row[key] = value;
            }
          });

          Object.assign(row, { Total: item.total_mentions });

          rows.push(row);
        }
      );
  }

  let columns = [
    {
      field: "Brand",
      headerName: "Brand",
      resizable: true,
      minWidth: 150,

      renderCell: (params) => (
        <p
          className={`${
            params.row.Brand === "Total"
              ? "font-weight-bold p-0 m-0"
              : "font-weight-normal p-0 m-0"
          }`}
        >
          {params.row.Brand}
        </p>
      ),
    },
  ];

  intervalOrder.map((item) => {
    columns.push({
      field: item,
      headerName: item,
      // resizable: true,
      align: "center",
      width: 120,

      headerAlign: "center",
      renderCell: (params) => (
        <p
          className={`${
            params.row.Brand === "Total"
              ? "font-weight-bold p-0 m-0"
              : "font-weight-normal p-0 m-0"
          }`}
        >
          {prefixZero_for_singleDigit(params.row?.[item])}
        </p>
      ),
    });
  });

  columns.push({
    field: "Total",
    headerName: "Total",
    resizable: true,
    align: "center",
    width: 100,

    headerAlign: "center",
    renderCell: (params) => (
      <p className={"font-weight-bold p-0 m-0"}>
        {prefixZero_for_singleDigit(params.row["Total"])}
      </p>
    ),
  });

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  return (
    <LoadingIndicator isActive={loading}>
      <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flex: "12",
        }}
      >
        {!loading ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            {!NoDataFoundBool ? (
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                }}
              >
                {apiError ? (
                  <Box
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      flex: "12",
                    }}
                  >
                    {apiError}
                  </Box>
                ) : (
                  <Box
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      flex: "12",
                    }}
                  >
                    <Box style={{ width: "100%", height: "100%" }}>
                      {props.dataGridHeight.current && rows && (
                        <DataGridFunction
                          rows={rows}
                          columns={columns}
                          rowBorder={false}
                          isRequesting={loading}
                          height={props.dataGridHeight.current}
                          rowHeight={50}
                          headerHeight={80}
                          onCellClick={onCellClick}
                          rowColor={true}
                          headerColor={true}
                          initialState={{
                            pinnedColumns: {
                              left: ["Brand"],
                              right: ["Total"],
                            },
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage width={"50%"} />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  );
}

export default ShareOfVoiceTrend;
