import ActionUtility from "../../../../utilities/ActionUtility";
import WebsweepxReportsEffect from "./WebsweepxReportsEffect";

export default class WebsweepxReportsAction {
  static REQUEST_GET_WEBSWEEPXREPORTS =
    "WebsweepxReportsAction.REQUEST_GET_WEBSWEEPXREPORTS";
  static REQUEST_GET_WEBSWEEPXREPORTS_FINISHED =
    "WebsweepxReportsAction.REQUEST_GET_WEBSWEEPXREPORTS_FINISHED";

  static REQUEST_WEBSWEEPXREPORTCMSvsWSX =
    "WebsweepxReportsAction.REQUEST_WEBSWEEPXREPORTCMSvsWSX";
  static REQUEST_WEBSWEEPXREPORTCMSvsWSX_FINISHED =
    "WebsweepxReportsAction.REQUEST_WEBSWEEPXREPORTCMSvsWSX_FINISHED";

  static REQUEST_WEBSWEEPXREPORTCMSvsWSXPROPERTIES =
    "WebsweepxReportsAction.REQUEST_WEBSWEEPXREPORTCMSvsWSXPROPERTIES";
  static REQUEST_WEBSWEEPXREPORTCMSvsWSXPROPERTIES_FINISHED =
    "WebsweepxReportsAction.REQUEST_WEBSWEEPXREPORTCMSvsWSXPROPERTIES_FINISHED";

  static REQUEST_WEBSWEEPXREPORTCMSvsWSXHOSTWISE =
    "WebsweepxReportsAction.REQUEST_WEBSWEEPXREPORTCMSvsWSXHOSTWISE";
  static REQUEST_WEBSWEEPXREPORTCMSvsWSXHOSTWISE_FINISHED =
    "WebsweepxReportsAction.REQUEST_WEBSWEEPXREPORTCMSvsWSXHOSTWISE_FINISHED";

  static REQUEST_WEBSWEEPXREPORTCMSvsWSXHSSWISE =
    "WebsweepxReportsAction.REQUEST_WEBSWEEPXREPORTCMSvsWSXHSSWISE";
  static REQUEST_WEBSWEEPXREPORTCMSvsWSXHSSWISE_FINISHED =
    "WebsweepxReportsAction.REQUEST_WEBSWEEPXREPORTCMSvsWSXHSSWISE_FINISHED";

  static REQUEST_GET_TOPSITESTOBEAUTOMATED =
    "WebsweepxReportsAction.REQUEST_GET_TOPSITESTOBEAUTOMATED";
  static REQUEST_GET_TOPSITESTOBEAUTOMATED_FINISHED =
    "WebsweepxReportsAction.REQUEST_GET_TOPSITESTOBEAUTOMATED_FINISHED";

  static REQUEST_GET_WSX_STATS_REPORT =
    "WebsweepxReportsAction.REQUEST_GET_WSX_STATS_REPORT";
  static REQUEST_GET_WSX_STATS_REPORT_FINISHED =
    "WebsweepxReportsAction.REQUEST_GET_WSX_STATS_REPORT_FINISHED";

  static REQUEST_GET_CRAWLEDPAGESREPORT =
    "WebsweepxReportsAction.REQUEST_GET_CRAWLEDPAGESREPORT";
  static REQUEST_GET_CRAWLEDPAGESREPORT_FINISHED =
    "WebsweepxReportsAction.REQUEST_GET_CRAWLEDPAGESREPORT_FINISHED";

  static REQUEST_GET_CMSvsWSXTELEGRAMREPORT =
    "WebsweepxReportsAction.REQUEST_GET_CMSvsWSXTELEGRAMREPORT";
  static REQUEST_GET_CMSvsWSXTELEGRAMREPORT_FINISHED =
    "WebsweepxReportsAction.REQUEST_GET_CMSvsWSXTELEGRAMREPORT_FINISHED";

  static REQUEST_GET_TELEGRAM_CHANNELWISE_LINKS =
    "WebsweepxReportsAction.REQUEST_GET_TELEGRAM_CHANNELWISE_LINKS";
  static REQUEST_GET_TELEGRAM_CHANNELWISE_LINKS_FINISHED =
    "WebsweepxReportsAction.REQUEST_GET_TELEGRAM_CHANNELWISE_LINKS_FINISHED";

  static REQUEST_GET_TELEGRAM_REPORTS =
    "WebsweepxReportsAction.REQUEST_GET_TELEGRAM_REPORTS";
  static REQUEST_GET_TELEGRAM_REPORTS_FINISHED =
    "WebsweepxReportsAction.REQUEST_GET_TELEGRAM_REPORTS_FINISHED";

  static REQUEST_GET_TELEGRAM_WSX_REPORTS =
    "WebsweepxReportsAction.REQUEST_GET_TELEGRAM_WSX_REPORTS";
  static REQUEST_GET_TELEGRAM_WSX_REPORTS_FINISHED =
    "WebsweepxReportsAction.REQUEST_GET_TELEGRAM_WSX_REPORTS_FINISHED";

  // METHODS
  static requestWebsweepxReports(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebsweepxReportsAction.REQUEST_GET_WEBSWEEPXREPORTS,
        WebsweepxReportsEffect.requestWebsweepxReports,
        params,
        callback
      );
    };
  }

  static requestGetTelegramChannelWiseLinks(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebsweepxReportsAction.REQUEST_GET_TELEGRAM_CHANNELWISE_LINKS,
        WebsweepxReportsEffect.requestGetTelegramChannelWiseLinks,
        params,
        callback
      );
    };
  }

  static requestGetTelegramReports(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebsweepxReportsAction.REQUEST_GET_TELEGRAM_REPORTS,
        WebsweepxReportsEffect.requestGetTelegramReports,
        params,
        callback
      );
    };
  }

  static requestGetTelegramWsxReports(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebsweepxReportsAction.REQUEST_GET_TELEGRAM_WSX_REPORTS,
        WebsweepxReportsEffect.requestGetTelegramWsxReports,
        params,
        callback
      );
    };
  }

  static requestWebsweepxReportCMSvsWSX(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebsweepxReportsAction.REQUEST_WEBSWEEPXREPORTCMSvsWSX,
        WebsweepxReportsEffect.requestWebsweepxReportCMSvsWSX,
        params,
        callback
      );
    };
  }

  static requestWebsweepxReportCMSvsWSXProperties(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebsweepxReportsAction.REQUEST_WEBSWEEPXREPORTCMSvsWSXPROPERTIES,
        WebsweepxReportsEffect.requestWebsweepxReportCMSvsWSXProperties,
        params,
        callback
      );
    };
  }

  static requestWebsweepxReportCMSvsWSXHostWise(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebsweepxReportsAction.REQUEST_WEBSWEEPXREPORTCMSvsWSXHOSTWISE,
        WebsweepxReportsEffect.requestWebsweepxReportCMSvsWSXHostWise,
        params,
        callback
      );
    };
  }

  static requestWebsweepxReportCMSvsWSXHSSWise(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebsweepxReportsAction.REQUEST_WEBSWEEPXREPORTCMSvsWSXHSSWISE,
        WebsweepxReportsEffect.requestWebsweepxReportCMSvsWSXHSSWise,
        params
      );
    };
  }

  static requestTopSitesToBeAutomated(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebsweepxReportsAction.REQUEST_GET_TOPSITESTOBEAUTOMATED,
        WebsweepxReportsEffect.requestTopSitesToBeAutomated,
        params,
        callback
      );
    };
  }

  static requestWsxStatsReport(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebsweepxReportsAction.REQUEST_GET_WSX_STATS_REPORT,
        WebsweepxReportsEffect.requestWsxStatsReport,
        params,
        callback
      );
    };
  }

  static requestCrawledPagesReport(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebsweepxReportsAction.REQUEST_GET_CRAWLEDPAGESREPORT,
        WebsweepxReportsEffect.requestCrawledPagesReport,
        params,
        callback
      );
    };
  }

  static requestCMSvsWSXTelegramReport(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebsweepxReportsAction.REQUEST_GET_CMSvsWSXTELEGRAMREPORT,
        WebsweepxReportsEffect.requestCMSvsWSXTelegramReport,
        params,
        callback
      );
    };
  }
}
