import ActionUtility from "../../../../utilities/ActionUtility";
import RevenueReportErrorsEffect from "./RevenueReportErrorsEffect";

export default class RevenueReportErrorsAction {
  // RevenueReportErrorsAction action with an function declaration
  static REQUEST_REVENUE_REPORT_ERRORS =
    "RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERRORS";
  static REQUEST_REVENUE_REPORT_ERRORS_FINISHED =
    "RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERRORS_FINISHED";

  static REQUEST_REVENUE_REPORT_ERRORS_FILTER =
    "RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERRORS_FILTER";
  static REQUEST_REVENUE_REPORT_ERRORS_FILTER_FINISHED =
    "RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERRORS_FILTER_FINISHED";

  static REQUEST_REVENUE_REPORT_ERROR_BY_ID =
    "RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERROR_BY_ID";
  static REQUEST_REVENUE_REPORT_ERROR_BY_ID_FINISHED =
    "RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERROR_BY_ID_FINISHED";

  static REQUEST_REVENUE_REPORT_ERROR_LOGS =
    "RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERROR_LOGS";
  static REQUEST_REVENUE_REPORT_ERROR_LOGS_FINISHED =
    "RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERROR_LOGS_FINISHED";

  static REQUEST_REVENUE_REPORT_ERROR_LOGS_FILTER =
    "RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERROR_LOGS_FILTER";
  static REQUEST_REVENUE_REPORT_ERROR_LOGS_FILTER_FINISHED =
    "RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERROR_LOGS_FILTER_FINISHED";

  // METHODS
  static requestRevenueReportErrors(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERRORS,
        RevenueReportErrorsEffect.requestRevenueReportErrors,
        params
      );
    };
  }

  static requestRevenueReportErrorsFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERRORS_FILTER,
        RevenueReportErrorsEffect.requestRevenueReportErrorsFilter,
        params
      );
    };
  }

  static requestRevenueReportErrorById(id, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERROR_BY_ID,
        RevenueReportErrorsEffect.requestRevenueReportErrorById,
        id,
        params,
        callBack
      );
    };
  }

  static requestRevenueReportErrorLogs(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERROR_LOGS,
        RevenueReportErrorsEffect.requestRevenueReportErrorLogs,
        params
      );
    };
  }

  static requestRevenueReportErrorLogsFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERROR_LOGS_FILTER,
        RevenueReportErrorsEffect.requestRevenueReportErrorLogsFilter,
        params
      );
    };
  }
}
