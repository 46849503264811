import React, { useState, useEffect, useRef } from "react";
import environment from "environment";
import Logo from "./logoContainer/Logo";
import { connect } from "react-redux";
import Description from "./descriptionContainer/Description";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import PaginationUI from "../../../components/pagination/Pagination";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, []),
});

function OverallTicketStatusUserwise(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  async function fetchData(dateRange) {
    let API = `${
      environment.api.baseApi
    }/agent_ticket_status?show_unassigned=false${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (response.hasOwnProperty("message")) {
          setData(null);
          setApiError(response.message);
        } else {
          setData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setData(null);
        setApiError(err.message);
        setLoading(false);
      });
  }

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchData(props.DateFilter);

      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.DateFilter);

        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  let TicketsCountList = [];

  data &&
    data.data &&
    data.data.map((item) => {
      TicketsCountList.push({
        agent_name: item.key,
        total_tickets: item.count,
        status: item.status,
      });
    });

  let NoDataFoundBool = data?.data.length === 0;

  let status_id_list = [];
  TicketsCountList &&
    TicketsCountList.map((item) => {
      item.status &&
        item.status.map((e) => {
          status_id_list.push(e.key);
        });
    });

  status_id_list = [...new Set(status_id_list)];

  function StatusName(val) {
    return (
      props.statusList &&
      props.statusList
        .filter((item) => item.status_id === val)
        .map((item) => {
          return item.status_name;
        })
    );
  }

  let columns = [];

  columns = [
    // { field: "id", headerName: "Sl No", resizable: false, flex: 1, width: 50 },
    {
      field: "agent_name",
      headerName: "Bridge User",
      minWidh: 150,
      flex: 1.5,
      resizable: true,
    },
    {
      field: "total_tickets",
      headerName: "Total",
      minWidh: 150,
      flex: 1,
      resizable: true,
      align: "center",
      headerAlign: "center",
    },
  ];

  status_id_list &&
    status_id_list.map((item) => {
      item &&
        columns.push({
          field: StatusName(item)[0],
          headerName: StatusName(item)[0],
          minWidh: 100,
          flex: 1,
          resizable: true,
          align: "center",
          headerAlign: "center",
        });
    });

  let updatedRows = [];

  TicketsCountList &&
    TicketsCountList.sort((a, b) => {
      const agentNameA = a.agent_name.toLowerCase();
      const agentNameB = b.agent_name.toLowerCase();

      if (agentNameA < agentNameB) {
        return -1; // agentNameA comes before agentNameB
      }
      if (agentNameA > agentNameB) {
        return 1; // agentNameA comes after agentNameB
      }
      return 0; // agentNameA and agentNameB are equal
    });

  TicketsCountList &&
    TicketsCountList.map((item, index) => {
      if (item?.agent_name !== "Unassigned" && item?.agent_name !== "total") {
        const rows = {
          id: index + 1,
          agent_name: item.agent_name,
          total_tickets: prefixZero_for_singleDigit(item.total_tickets),
        };

        status_id_list.forEach((key) => {
          if (key) {
            const filteredStatus = item.status.find((val) => val.key === key);

            rows[StatusName(key)[0]] = prefixZero_for_singleDigit(
              filteredStatus?.count
            );
          }
        });

        updatedRows.push(rows);
      } else if (item?.agent_name === "total") {
        const rows = {
          id: index + 1,
          agent_name: item.agent_name,
          total_tickets: prefixZero_for_singleDigit(item.total_tickets),
        };

        status_id_list.forEach((key) => {
          if (key) {
            const filteredStatus = item.status.find(
              (val) => val.status_key === key
            );

            rows[StatusName(key)[0]] = prefixZero_for_singleDigit(
              filteredStatus?.status_count
            );
          }
        });

        updatedRows.push(rows);
      }
    });

  const totalIndex = updatedRows.findIndex(
    (item) => item.agent_name === "total"
  );
  if (totalIndex !== -1) {
    const [totalItem] = updatedRows.splice(totalIndex, 1);
    updatedRows.push(totalItem);
  }

  const totalRows = updatedRows && updatedRows.length;
  const paginationSize = 10;
  const totalPages = Math.ceil(totalRows && totalRows / paginationSize);

  const [currentPage, setCurrentPage] = useState(0);

  const handleChange = (event, value) => {
    setCurrentPage(value - 1);
  };
  const startIndex = currentPage * paginationSize;
  const endIndex = startIndex + paginationSize;

  const finalRows = updatedRows && updatedRows.slice(startIndex, endIndex);

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (props.DownloadClicked) {
      const rearrangedArray = updatedRows.map((item) => {
        // Create a new object with the desired order
        const rearrangedItem = {
          ["Bridge User"]: item.agent_name, // Add platform as the first key
          ...Object.keys(item)
            .filter((key) => key !== "agent_name" && key !== "total_tickets")
            .reduce((acc, key) => {
              acc[key] = prefixZero_for_singleDigit(item[key]);
              return acc;
            }, {}), // Add all other keys except platform and total
          Total: prefixZero_for_singleDigit(item.total_tickets), // Add total as the last key
        };

        delete rearrangedItem["id"];

        return rearrangedItem;
      });

      props.setexcelData(rearrangedArray);
    }
  }, [props.DownloadClicked, updatedRows]);

  return !loading ? (
    <Box height={"100%"}>
      {apiError ? (
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flex: "12",
          }}
        >
          {apiError}
        </Box>
      ) : !loading && !NoDataFoundBool && props.dataGridHeight.current ? (
        <Box sx={{ padding: "12px", height: "100%" }}>
          <Grid container lg={12} md={12} sm={12}>
            <DataGridFunction
              rows={updatedRows}
              columns={columns}
              height={props.dataGridHeight.current}
              rowHeight={50}
              headerHeight={80}
              onCellClick={onCellClick}
              rowBorder={false}
              rowColor={true}
              headerColor={true}
              initialState={{
                pinnedColumns: {
                  left: ["agent_name", "total_tickets"],
                },
              }}
            />
          </Grid>
        </Box>
      ) : (
        !loading && <NoDataFound />
      )}
    </Box>
  ) : (
    <Box
      sx={{
        width: "100%",
        height: "30vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoadingBgImage />
    </Box>
  );
}
export default connect(mapStateToProps)(OverallTicketStatusUserwise);
