import React, { useEffect, useState, useCallback } from "react";
import Grid from "@mui/material/Grid";
import { Button, createFilterOptions, IconButton } from "@mui/material";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import PlatformAction from "../../../ormTicketing/stores/platform/PlatformAction";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import GoogleAlertConfigAction from "../../stores/googleAlertConfig/GoogleAlertConfigAction";
import GoogleAlertConfigPost from "./GoogleAlertConfigPost";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    GoogleAlertConfigAction.REQUEST_GOOGLE_ALERT_CONFIG,
    GoogleAlertConfigAction.REQUEST_GOOGLE_ALERT_CONFIG_FILTER,
    PlatformAction.REQUEST_PLATFORM,
  ]),
  google_alerts_config:
    state.google_alerts_config.google_alerts_config.hits || [],
  google_alerts_config_filter:
    state.google_alerts_config_filter.google_alerts_config_filter || {},
  pagination: state.google_alerts_config.google_alerts_config || {},
  platform: state.platform.platform || [],
});

function GoogleAlertConfiguration(props) {
  const { isRequesting } = props;
  const [config, setConfig] = useState(null);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [page_limit] = useState(15);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  function fetchData() {
    const param = {
      order_by: '[["created_on", "desc"]]',
      page_limit: `${page_limit}`,
    };
    props.dispatch(GoogleAlertConfigAction.requestGoogleAlertConfig(param));
  }

  function fetchDataFilter() {
    const param = {
      order_by: `[["alert_name.keyword", "asc"]]`,
      page_limit: "none",
    };
    props.dispatch(
      GoogleAlertConfigAction.requestGoogleAlertConfigFilter(param)
    );
  }

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  useEffect(() => {
    fetchData();
    fetchDataFilter();
  }, []);

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let filter_params = {};
    const filter = [];

    if (config !== null) {
      filter.push(`["alert_name.keyword","must","","match","${config}"]`);
    }

    if (config !== null && FilterClicked) {
      filter_params = {
        order_by: '[["created_on", "desc"]]',
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    } else {
      filter_params = {
        order_by: '[["created_on", "desc"]]',
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      props.dispatch(
        GoogleAlertConfigAction.requestGoogleAlertConfig(filter_params)
      );
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "SL NO",
      width: 150,
      flex: 2,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "config_unique_id",
      headerName: "Project ID",
      width: 200,
      hide: true,
    },

    {
      field: "alert_name",
      headerName: "Alert Name",
      width: 250,
      flex: 3,
    },

    {
      field: "rss_url",
      headerName: "RSS url",
      width: 250,
      flex: 3,
      resizable: false,
    },

    {
      field: "created_by",
      headerName: "Created By",
      width: 250,
      flex: 3,
      resizable: false,
    },

    {
      field: "enabled",
      headerName: "Enabled",
      width: 250,
      flex: 1,
      resizable: false,
      hide: true,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      resizable: false,
      align: "center",

      width: 160,
      flex: 2,
      cellClassName: "actions",
      renderCell: (params) => (
        <IconButton
          title="Edit"
          onClick={(event) => {
            event.ignore = true;
            setEditRowsModel(params.row);
            setEdit(true);
            setOpen(true);
          }}
          sx={{ border: "0" }}
        >
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
  ];

  const rows = [];
  if (props.google_alerts_config.hits) {
    props.google_alerts_config.hits.map((item, index) => {
      return rows.push({
        id: item._id
          ? props.pagination.current_page_no * page_limit + ++index
          : "",
        config_unique_id: item._id,
        alert_name: item._source.alert_name,
        created_by: item._source.created_by.user_name,
        created_by: item._source.created_by.user_name,
        enabled: item._source.enabled,
        rss_url: item._source.rss_url,
      });
    });
  }

  const alertName_handlechange = (event, value) => {
    if (value != null) {
      setConfig(value);
    } else {
      setConfig(null);
    }
  };

  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  // Filter function for Client
  const applyFilter = () => {
    const filter = [];
    if (config !== null) {
      filter.push(`["alert_name.keyword","must","","match","${config}"]`);
    }

    if (config !== null) {
      const params = {
        order_by: '[["created_on", "desc"]]',
        filters: `[${filter}]`,
      };
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);

      props.dispatch(GoogleAlertConfigAction.requestGoogleAlertConfig(params));
    } else {
      alert("Please add filters");
      setClearLoader(false);
    }
  };

  const ClearState = () => {
    if (config !== null) {
      setClearLoader(true);
      setConfig(null);
      !FilterClicked && setClearLoader(false);
    }

    setFilterClicked(false);

    const params = {
      order_by: '[["created_on", "desc"]]',
      page_limit: 15,
    };
    FilterClicked &&
      props.dispatch(GoogleAlertConfigAction.requestGoogleAlertConfig(params));
  };

  let alertNameList = [];
  if (props.google_alerts_config_filter.hits) {
    props.google_alerts_config_filter.hits.hits.map((item) => {
      return alertNameList.push(item._source.alert_name);
    });
  }

  alertNameList.sort((a, b) => a.localeCompare(b));
  alertNameList = [...new Set(alertNameList)];

  //  datagrid rows

  return (
    <React.Fragment>
      {/* Filter */}

      <GoogleAlertConfigPost
        open={open}
        onSubmit={() => {}}
        onClose={() => {
          setOpen(false);
        }}
        projectEditData={editRowsModel}
        edit={edit}
      />

      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        justifyContent="space-between"
        pr={2}
      >
        <Grid item lg={2} md={5} sm={5} mt={1}>
          <AutoCompleteComponent
            value={config}
            textFieldProps={{
              placeholder: "Alert Name",
            }}
            filterOptions={createFilterOptions({
              matchFrom: "start",
              stringify: (option) => option,
            })}
            list={alertNameList}
            handleChange={alertName_handlechange}
            size="small"
            width="100%"
          />
        </Grid>
        <Grid item lg={6} md={7} sm={7} container>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            container
            spacing={1}
            justifyContent="flex-end"
            mt={0}
          >
            <Grid
              lg={8}
              sm={8}
              md={8}
              display={"flex"}
              justifyContent={"end"}
              mt={1}
              mr={2}
              sx={{ gap: 2 }}
            >
              <Grid lg={3} md={3} sm={3}>
                <MuiButton
                  name={"Filter"}
                  width="100%"
                  onClick={applyFilter}
                  loading={filterLoader && isRequesting}
                />
              </Grid>
              <Grid lg={3} md={3} sm={3}>
                <MuiButton
                  name={"Clear"}
                  width="100%"
                  onClick={ClearState}
                  loading={clearLoader && isRequesting}
                />
              </Grid>
            </Grid>
            <Grid>
              {/* post button component*/}
              <PostPlusButton
                open={open}
                openCloseModal={openCloseModal}
                editMode={editMode}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* ***************************************************************  user filter */}

      {/* data grid */}

      <Grid item lg={12} md={12} sm={12} xs={12}>
        {/* loading icon */}
        <LoadingIndicator isActive={isRequesting}>
          {!isRequesting && (
            <DataGridFunction
              columns={columns}
              rows={rows}
              rowHeight={60}
              pagelength={props.pagination.total_page_no}
              page={props.pagination.current_page_no}
              handleChange={handleChange}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    config_unique_id: false,
                    platform: false,
                  },
                },
              }}
            />
          )}
        </LoadingIndicator>
      </Grid>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(GoogleAlertConfiguration);
