import React, { useEffect, useRef, useState } from "react";
import "./cssFiles/ResponseManagementPlatformCounts.css";
import environment from "environment";
import Logo from "./logoContainer/Logo";
import Description from "./descriptionContainer/Description";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { theme } from "../../../views/App";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { format, isValid } from "date-fns";
import { firstLetterOrange } from "../../../components/ticketPartials";

function FacebookInsights(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [insightsData, setInsightsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  useEffect(() => {
    (async function CallApi() {
      if (!props?.detailReportOpen && props.account_selected) {
        setLoading(true);
        setInsightsData(null);
        await fetchData(props.account_selected, props.DateFilter);
      }
    })();
  }, []);

  useEffect(() => {
    (async function CallApi() {
      if (props?.detailReportOpen && props.account_selected) {
        setLoading(true);

        setInsightsData(null);

        await fetchData(props.account_selected, props.DateFilter);
        setLoading(false);
      }
    })();
  }, [props?.detailReportOpen, props.account_selected]);

  useEffect(() => {
    if (props.clearClicked) {
      setInsightsData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.account_selected, props.DateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      setInsightsData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.account_selected, props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.account_selected, props.filterClicked, props.openDateFilter]);

  async function fetchData(account_id, dateRange) {
    let postsummaryAPI = `${environment.api.facebookReportsummary}?account_id=${account_id?.account_id}&reports=["facebook_insights"]`;
    await axiosRequest(postsummaryAPI, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (
          response.hasOwnProperty("error") ||
          response.hasOwnProperty("error_id")
        ) {
          response.hasOwnProperty("error_id")
            ? setApiError(`${response?.message} - ${response?.error_id}`)
            : setApiError(response?.error);
          setInsightsData(null);
          setLoading(false);
        } else {
          setInsightsData(response);
          setApiError(null);
          setLoading(false);
        }
      })
      .catch((err) => {
        setInsightsData(null);
        setApiError(err.message);
        setLoading(false);
      });
  }

  let NoDataFoundBool = !insightsData && !props.account_selected;

  // Calculate the height of each box based on the maximum length

  const isXl = useMediaQuery(() => theme.breakpoints.up("xl"));
  const isLg = useMediaQuery(() => theme.breakpoints.up("lg"));
  const isLaptop = useMediaQuery(() => theme.breakpoints.up("laptop"));
  const isMd = useMediaQuery(() => theme.breakpoints.up("md"));

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [
        {
          "Total Comments":
            insightsData && insightsData[0]?.facebook_insights?.total_comments,
          Album:
            insightsData &&
            insightsData[0]?.facebook_insights?.total_posts?.album,
          Links:
            insightsData &&
            insightsData[0]?.facebook_insights?.total_posts?.links,
          Photos:
            insightsData &&
            insightsData[0]?.facebook_insights?.total_posts?.photos,
          Reels:
            insightsData &&
            insightsData[0]?.facebook_insights?.total_posts?.reels,
          "Shared Story":
            insightsData &&
            insightsData[0]?.facebook_insights?.total_posts?.shared_story,
          Status:
            insightsData &&
            insightsData[0]?.facebook_insights?.total_posts?.status,
          "Total Posts":
            insightsData &&
            insightsData[0]?.facebook_insights?.total_posts?.total_posts,
          Video:
            insightsData &&
            insightsData[0]?.facebook_insights?.total_posts?.video,
          Angry:
            insightsData &&
            insightsData[0]?.facebook_insights?.total_reactions?.angry,
          haha:
            insightsData &&
            insightsData[0]?.facebook_insights?.total_reactions?.haha,
          Like:
            insightsData &&
            insightsData[0]?.facebook_insights?.total_reactions?.like,
          Love:
            insightsData &&
            insightsData[0]?.facebook_insights?.total_reactions?.love,
          Sad:
            insightsData &&
            insightsData[0]?.facebook_insights?.total_reactions?.sad,
          "Total Reaction":
            insightsData &&
            insightsData[0]?.facebook_insights?.total_reactions?.total_reaction,
          Wow:
            insightsData &&
            insightsData[0]?.facebook_insights?.total_reactions?.wow,
        },
      ];
      props.setexcelData(exportData);
    }
  }, [props.DownloadClicked, insightsData]);

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: "12",
      }}
    >
      <Box
        className="scrollable"
        sx={{
          overflowY: "auto",
          "& .MuiDataGrid-virtualScroller": {
            "&::-webkit-scrollbar": {
              width: "3px",
              height: "3px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#b0d2da",
              borderRadius: "3px",
              "&:hover": {
                backgroundColor: "#85bac6",
              },
            },
          },
        }}
      >
        {!loading ? (
          <>
            {!NoDataFoundBool ? (
              <Box
                style={{
                  width: "100%",
                  overflowX: "auto",
                  padding: "12px",
                }}
              >
                {apiError ? (
                  <Box
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      flex: "12",
                    }}
                  >
                    {apiError}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      padding: 2,
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    <Grid container xs={12} columnSpacing={1}>
                      <Grid item xs={12} md={6}>
                        {firstLetterOrange("Total Posts")}

                        <Card
                          sx={{ borderRadius: 2, boxShadow: 2, padding: 1 }}
                        >
                          {[
                            {
                              label: "Total Comments",
                              value:
                                insightsData?.[0]?.facebook_insights
                                  ?.total_comments,
                            },

                            {
                              label: "Album",
                              value:
                                insightsData?.[0]?.facebook_insights
                                  ?.total_posts.album,
                            },
                            {
                              label: "Links",
                              value:
                                insightsData?.[0]?.facebook_insights
                                  ?.total_posts.links,
                            },
                            {
                              label: "Photos",
                              value:
                                insightsData?.[0]?.facebook_insights
                                  ?.total_posts.photos,
                            },
                            {
                              label: "Reels",
                              value:
                                insightsData?.[0]?.facebook_insights
                                  ?.total_posts.reels,
                            },
                            {
                              label: "Shared Story",
                              value:
                                insightsData?.[0]?.facebook_insights
                                  ?.total_posts.shared_story,
                            },
                            {
                              label: "Status",
                              value:
                                insightsData?.[0]?.facebook_insights
                                  ?.total_posts.status,
                            },
                            {
                              label: "Total Posts",
                              value:
                                insightsData?.[0]?.facebook_insights
                                  ?.total_posts.total_posts,
                            },
                            {
                              label: "Video",
                              value:
                                insightsData?.[0]?.facebook_insights
                                  ?.total_posts.video,
                            },
                          ]?.map((item) => (
                            <Grid
                              item
                              xs={12}
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <Typography
                                component={"span"}
                                sx={{ fontWeight: "bold", fontSize: 12 }}
                              >
                                {item.label}
                              </Typography>
                              <Typography component={"span"}>
                                {item.value}
                              </Typography>
                            </Grid>
                          ))}
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {firstLetterOrange("Total Reactions")}
                        <Card
                          sx={{ borderRadius: 2, boxShadow: 2, padding: 1 }}
                        >
                          {[
                            {
                              label: "Angry",
                              value:
                                insightsData?.[0]?.facebook_insights
                                  ?.total_reactions.angry,
                            },
                            {
                              label: "haha",
                              value:
                                insightsData?.[0]?.facebook_insights
                                  ?.total_reactions.haha,
                            },
                            {
                              label: "Like",
                              value:
                                insightsData?.[0]?.facebook_insights
                                  ?.total_reactions.like,
                            },
                            {
                              label: "Love",
                              value:
                                insightsData?.[0]?.facebook_insights
                                  ?.total_reactions.love,
                            },
                            {
                              label: "Sad",
                              value:
                                insightsData?.[0]?.facebook_insights
                                  ?.total_reactions.angry,
                            },
                            {
                              label: "Total Reaction",
                              value:
                                insightsData?.[0]?.facebook_insights
                                  ?.total_reactions?.total_reaction,
                            },
                            {
                              label: "Wow",
                              value:
                                insightsData?.[0]?.facebook_insights
                                  ?.total_reactions.wow,
                            },
                          ]?.map((item) => (
                            <Grid
                              item
                              xs={12}
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <Typography
                                component={"span"}
                                sx={{ fontWeight: "bold", fontSize: 12 }}
                              >
                                {item.label}
                              </Typography>
                              <Typography component={"span"}>
                                {item.value}
                              </Typography>
                            </Grid>
                          ))}
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            ) : (
              <NoDataFound />
            )}
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default FacebookInsights;
