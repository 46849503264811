import React, { useEffect, useRef, useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import { makeStyles } from "@material-ui/core";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { isValid } from "date-fns";
import { theme } from "../../../views/App";
import DatePicker from "../../../components/dateRangePickerComponent/DateRangePicker";
const useStyles = makeStyles({
  gridContainer: {
    height: "auto", // set the height of the grid container
    overflow: "auto", // add scrollbars when necessary
    "&::-webkit-scrollbar": {
      display: "none", // hide the scrollbar for webkit-based browsers (e.g. Chrome, Safari)
    },
    "-ms-overflow-style": "none", // hide the scrollbar for Internet Explorer and Edge
    "scrollbar-width": "none", // hide the scrollbar for Firefox and other browsers that support the new scrollbar style
  },
});

function SL_MentionsDateSelectionPopper(props) {
  const [open, setOpen] = React.useState(false);
  const [dateOpen, setDateOpen] = useState(false);
  const dateSelectionPopperRef = React.useRef(null);
  const scrollRef = useRef(null);
  const prevOpen = React.useRef(open);
  const [dateRange, setDateRange] = useState([null, null]);

  const classes = useStyles();

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      dateSelectionPopperRef.current.focus();
    }
    prevOpen.current = open;

    props.createdDatelistSelected == "Custom"
      ? setDateOpen(true)
      : setDateOpen(false);

    if (props.date) {
      setDateRange(props.date);
    }
  }, [open]);

  useEffect(() => {
    let filters = JSON.parse(localStorage.getItem("sl_allMentionsParameters"));
    if (filters) {
      props.setCreatedDatelistSelected(filters.createdDatelistSelected);
      props.setDate([
        JSON.parse(localStorage.getItem("sl_allMentionsParameters"))
          .createdDate &&
        JSON.parse(localStorage.getItem("sl_allMentionsParameters"))
          .createdDate[0]
          ? new Date(
              JSON.parse(
                localStorage.getItem("sl_allMentionsParameters")
              ).createdDate[0]
            )
          : null,
        JSON.parse(localStorage.getItem("sl_allMentionsParameters"))
          .createdDate &&
        JSON.parse(localStorage.getItem("sl_allMentionsParameters"))
          .createdDate[1]
          ? new Date(
              JSON.parse(
                localStorage.getItem("sl_allMentionsParameters")
              ).createdDate[1]
            )
          : null,
      ]);
      props.setModifiedDatelistSelected(filters.modifiedDatelistSelected);
      props.setModifiedDate([
        JSON.parse(localStorage.getItem("sl_allMentionsParameters"))
          .modifiedDate &&
        JSON.parse(localStorage.getItem("sl_allMentionsParameters"))
          .modifiedDate[0]
          ? new Date(
              JSON.parse(
                localStorage.getItem("sl_allMentionsParameters")
              ).modifiedDate[0]
            )
          : null,
        JSON.parse(localStorage.getItem("sl_allMentionsParameters"))
          .modifiedDate &&
        JSON.parse(localStorage.getItem("sl_allMentionsParameters"))
          .modifiedDate[1]
          ? new Date(
              JSON.parse(
                localStorage.getItem("sl_allMentionsParameters")
              ).modifiedDate[1]
            )
          : null,
      ]);
    }
  }, [localStorage.getItem("sl_allMentionsParameters")]);

  let maxDate = new Date();

  const dateValueHandleChange = (e, v) => {
    setDateRange(e);
    let filters = JSON.parse(localStorage.getItem("sl_allMentionsParameters"));
    let date1 = e[0] && e[0].$d ? e[0].$d : dateRange[0] && dateRange[0];
    let date2 = e[1] && e[1].$d ? e[1].$d : dateRange[1] && dateRange[1];

    const isPastDate = (date) => {
      const now = new Date();
      return date <= now;
    };

    if (
      isValid(date1) &&
      isValid(date2) &&
      isPastDate(date1) &&
      isPastDate(date2)
    ) {
      props.setDate([date1, date2]);
      props.setCreatedDatelistSelected("Custom");
      props.setPageNumber(0);

      if (filters) {
        filters.createdDate = [date1, date2];
        filters.createdDatelistSelected = "Custom";
        filters.pageNumber = `0`;

        localStorage.setItem(
          "sl_allMentionsParameters",
          JSON.stringify(filters)
        );

        props.setFilterContents(
          props.filterContents
            ? {
                ...props.filterContents,
                createdDate: [date1, date2],
                createdDatelistSelected: "Custom",
              }
            : {
                createdDate: [date1, date2],
                createdDatelistSelected: "Custom",
              }
        );

        props.applyFilter(filters);
      } else {
        localStorage.setItem(
          "sl_allMentionsParameters",
          JSON.stringify({
            createdDate: [date1, date2],
          })
        );
        // props.applyFilter(JSON.stringify(filters));
      }
      setOpen(false);
      setDateOpen(false);
    }
  };

  const handlePopper = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      dateSelectionPopperRef.current &&
      dateSelectionPopperRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid container>
      <Typography
        component={"div"}
        ref={dateSelectionPopperRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handlePopper}
        style={{
          backgroundColor: "none",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <span
          style={{
            fontWeight:
              props && props.createdDatelistSelected !== null ? "none" : "none",
          }}
        >
          Duration{" "}
          {props.createdDatelistSelected &&
            `: ${props.createdDatelistSelected}`}
        </span>

        <Typography sx={{ paddingLeft: 1 }}>
          {!open ? (
            <KeyboardArrowDownIcon
              sx={{
                fontSize: "16px",
                color: theme.palette.primary.main,
                cursor: "pointer",
              }}
            />
          ) : (
            <KeyboardArrowUpIcon
              sx={{
                fontSize: "16px",
                color: theme.palette.primary.main,
                cursor: "pointer",
              }}
            />
          )}
        </Typography>
      </Typography>

      <Popper
        open={open}
        anchorEl={dateSelectionPopperRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{
          zIndex: 200,
          position: "relative",
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            sx={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper
              style={{
                width: "auto",
                minWidth: 150,

                height: "auto",
                boxShadow: theme.shadows,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <div
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  // style={{ display: "flex" }}
                >
                  <Grid
                    item
                    sx={{ padding: 2 }}
                    gap={1}
                    className={classes.gridContainer}
                    ref={scrollRef}
                  >
                    {props.dateSelecte.map((item) => (
                      <Typography
                        component={"div"}
                        sx={{
                          marginBottom: 2,
                          cursor: "pointer",
                          textAlign: "left",
                          fontWeight:
                            props.createdDatelistSelected === item
                              ? "bold"
                              : "none",
                        }}
                        onClick={() => {
                          if (item !== "Custom") {
                            props.setDate([null, null]);
                            props.setCreatedDatelistSelected(item);
                            props.setPageNumber(0);
                            setOpen(false);
                            setDateOpen(false);

                            let filters = JSON.parse(
                              localStorage.getItem("sl_allMentionsParameters")
                            );

                            if (filters) {
                              filters.createdDatelistSelected = item;
                              filters.createdDate = props.date;
                              filters.pageNumber = `0`;

                              props.setFilterContents(
                                props.filterContents
                                  ? {
                                      ...props.filterContents,
                                      createdDate: props.date,
                                      createdDatelistSelected: item,
                                    }
                                  : {
                                      createdDate: props.date,
                                      createdDatelistSelected: item,
                                    }
                              );

                              props.applyFilter(filters);

                              localStorage.setItem(
                                "sl_allMentionsParameters",
                                JSON.stringify(filters)
                              );
                            }
                          } else {
                            setDateOpen(true);
                          }
                        }}
                      >
                        {item}
                      </Typography>
                    ))}
                  </Grid>
                  {dateOpen && (
                    <Grid item sx={{ padding: 2 }}>
                      <DatePicker
                        value={dateRange ? dateRange : [null, null]}
                        onChange={dateValueHandleChange}
                        maxDate={maxDate}
                        disableFuture={true}
                      />
                    </Grid>
                  )}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
}

export default SL_MentionsDateSelectionPopper;
