import {
  Avatar,
  Box,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Snackbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import StyleIcon from "@mui/icons-material/Style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import ReplyIcon from "@mui/icons-material/Reply";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ReplyComponent from "../components/ReplyComponent";
import EmailComponent from "../components/EmailComponent";
import TicketFeedRepliesContainer from "./TicketFeedRepliesContainer";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DirectClosePopper from "../components/DirectClosePopper";
import copy from "copy-to-clipboard";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ThreeDotsPopper from "../components/ThreeDotsPopper";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { Form, Formik } from "formik";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";
import SentimentDissatisfiedRoundedIcon from "@mui/icons-material/SentimentDissatisfiedRounded";
import SentimentNeutralRoundedIcon from "@mui/icons-material/SentimentNeutralRounded";
import SentimentValueUpdatePopper from "../../allTickets/components/SentimentValueUpdatePopper";
import { selectRequesting } from "../../../../../selectors/requesting/RequestingSelector";
import TwitterAction from "../../../../stores/twitterFeeds/TwitterAction";
import RetweetAction from "../../../../stores/retweet/RetweetAction";
import UnRetweetAction from "../../../../stores/unRetweet/UnRetweetAction";
import InstagramFeedsAction from "../../../../stores/instagramFeeds/InstagramFeedsAction";
import GmbQuestionFeedsAction from "../../../../stores/gmbQuestions/GmbQuestionFeedsAction";
import LinkedInFeedAction from "../../../../stores/linkedInFeed/LinkedInFeedAction";
import YoutubeAction from "../../../../stores/youtube/YoutubeAction";
import GmbReviewAction from "../../../../stores/gmbReviews/GmbReviewAction";
import PlayStoreAction from "../../../../stores/playstore/PlayStoreAction";
import TelegramAction from "../../../../stores/telegram/TelegramAction";
import GlassdoorFeedsAction from "../../../../stores/glassdoorFeeds/GlassdoorFeedsAction";
import EmailFeedsAction from "../../../../stores/emailFeeds/EmailFeedsAction";
import AmbitionBoxFeedsAction from "../../../../../stores/ambitionBoxFeeds/AmbitionBoxFeedsAction";
import FacebookDmAction from "../../../../stores/facebook_dm/FacebookDmAction";
import InstagramDirectMessageAction from "../../../../stores/instagramDirectMessage/InstagramDirectMessageAction";
import QuoraFeedsAction from "../../../../stores/quoraFeeds/QuoraFeedsAction";
import TwitterDirectMessageAction from "../../../../stores/linkedIn_account/twitterDirectMessage/TwitterDirectMessageAction";
import TicketsAction from "../../../../stores/tickets/TicketsAction";
import FacebookAction from "../../../../stores/facebook/FacebookAction";
import WhatsAppFeedAction from "../../../../stores/whatsappFeed/WhatsAppFeedAction";
import AppStoreAction from "../../../../stores/linkedInFeed/appstore/AppStoreAction";
import IndeedFeedAction from "../../../../stores/indeed/IndeedFeedAction";
import RedditFeedAction from "../../../../stores/reddit/RedditFeedAction";
import ConsumerAction from "../../../../stores/consumerFeeds/ConsumerAction";
import DiscordFeedsAction from "../../../../stores/discord/DiscordFeedsAction";
import TrustpilotFeedsAction from "../../../../stores/trustpilot/TrustpilotFeedsAction";
import PinterestFeedsAction from "../../../../stores/pinterest/PinterestFeedsAction";
import { returnPlatformDropdownLogos } from "../../../../../components/ticketPartials";
import { theme } from "../../../../../views/App";
import LoadingIndicator from "../../../../../components/loading-indicator/LoadingIndicator";
import Details from "../../../../../views/manualTicketDetails/ticketDetailTabs/Details";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import LoadingBgImage from "../../../../../components/loadingBgImage/LoadingBgImage";
import NotesUI from "./NotesUI";
import ReturnDeletedStatement from "./ReturnDeletedStatement";
import {
  ReturnFeedData,
  returnFeedKeyToMatchTicketDesc,
  SortFeedsBasedOnTime,
} from "./ticketFeedDataRetrive";
import RenderFeedContent from "./TicketFeedContentBox";

TimeAgo.addDefaultLocale(en);
// mapstateto props function fetching
let mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TwitterAction.REQUEST_TWITTER,
    RetweetAction.REQUEST_RETWEET,
    UnRetweetAction.REQUEST_UNRETWEET,
    InstagramFeedsAction.REQUEST_INSTAGRAM_FEEDS,
    GmbQuestionFeedsAction.REQUEST_GMB_QUESTION_FEED,
    LinkedInFeedAction.REQUEST_LINKEDIN_FEED,
    YoutubeAction.REQUEST_YOUTUBE,
    GmbReviewAction.REQUEST_GMB_REVIEW_FEED,
    PlayStoreAction.REQUEST_PLAYSTORE,
    TelegramAction.REQUEST_TELEGRAM_FEEDS,
    GlassdoorFeedsAction.REQUEST_GLASSDOOR_FEED,
    EmailFeedsAction.REQUEST_EMAIL_FEEDS,
    AmbitionBoxFeedsAction.REQUEST_AMBITIONBOX_FEED,
    FacebookDmAction.REQUEST_FACEBOOK_DM,
    InstagramDirectMessageAction.REQUEST_INSTAGRAM_DIRECT_MESSAGE,
    QuoraFeedsAction.REQUEST_QUORA_FEED,
    TwitterDirectMessageAction.REQUEST_TWITTERDIRECTMESSAGE,
    TicketsAction.REQUEST_TICKETS_BY_ID,
    FacebookAction.REQUEST_FACEBOOK,
    FacebookAction.REQUEST_FACEBOOK_POST_MENTIONS,
    WhatsAppFeedAction.REQUEST_WHATSAPP_FEED,
    AppStoreAction.REQUEST_APPSTORE,
    IndeedFeedAction.REQUEST_INDEED_FEEDS,
    RedditFeedAction.REQUEST_REDDIT_COMMENTS,
    RedditFeedAction.REQUEST_REDDIT_POSTS,
    ConsumerAction.REQUEST_CONSUMER,
    DiscordFeedsAction.REQUEST_DISCORD_FEEDS,
    TrustpilotFeedsAction.REQUEST_TRUSTPILOT_FEEDS,
    PinterestFeedsAction.REQUEST_PINTEREST_FEEDS,
    FacebookAction.REQUEST_FACEBOOK_COMMENT_HIDE_POST,
    FacebookAction.REQUEST_FACEBOOK_COMMENT_DELETE,
    InstagramFeedsAction.REQUEST_INSTAGRAM_COMMENT_HIDE,
    InstagramFeedsAction.REQUEST_INSTAGRAM_COMMENT_DELETE,
    // TicketsAction.REQUEST_RELATED_TICKETS,
    // TicketsAction.REQUEST_PUT_TICKETS,
  ]),
  twitter: state.twitter.twitter.hits || [],
  threadFeeds: state.threadFeeds.threadFeeds.hits || {},
  retweet: state.retweet.retweet || [],
  unretweet: state.unretweet.unretweet || [],
  instagramFeeds: state.instagramFeeds.instagramFeeds.hits || {},
  facebook: state.facebook.facebook.hits || {},
  facebook_postMentions:
    state.facebook_postMentions.facebook_postMentions.hits || {},
  GMBQuestionFeeds: state.GMBQuestionFeeds.GMBQuestionFeeds.hits || [],
  linkedInFeeds: state.linkedInFeeds.linkedInFeeds.hits || [],
  youtubeFeed: state.youtubeFeed.youtubeFeed.hits || [],
  GMBReviewFeeds: state.GMBReviewFeeds.GMBReviewFeeds.hits || [],
  playstore: state.playstore.playstore.hits || [],
  telegramFeeds: state.telegramFeeds.telegramFeeds.hits || [],
  glassdoorFeeds: state.glassdoorFeeds.glassdoorFeeds.hits || {},
  emailFeeds: state.emailFeeds.emailFeeds.hits || {},
  ambitionBoxFeeds: state.ambitionBoxFeeds.ambitionBoxFeeds.hits || {},
  facebook_dm: state.facebook_dm.facebook_dm.hits || [],
  instagramDirectMessage:
    state.instagramDirectMessage.instagramDirectMessage.hits || [],
  twitterDirectMeassage:
    state.twitterDirectMeassage.twitterDirectMeassage.hits || [],
  quoraFeed: state.quoraFeed.quoraFeed.hits || {},
  whatsAppFeed: state.whatsAppFeed.whatsAppFeed.hits || [],
  appStore: state.appstore.appstore.hits || {},
  indeedFeeds: state.indeedFeeds.indeedFeeds.hits || {},
  trustPilotFeeds: state.trustPilotFeeds.trustPilotFeeds.hits || {},
  pinterestFeeds: state.pinterestFeeds.pinterestFeeds.hits || {},
  redditFeeds: state.redditFeeds.redditFeeds.hits || {},
  redditPosts: state.redditPosts.redditPosts.hits || {},
  redditComments: state.redditComments.redditComments.hits || {},
  consumer_feed: state.consumer_feed.consumer_feed.hits || {},
  discordFeeds: state.discordFeeds.discordFeeds.hits || {},
  related_tickets: state.related_tickets.related_tickets || [],
});
const TicketFeed = (props) => {
  let ticketPlatform;
  let ticketActivity;
  let ticketFeedId;
  let ticketStatus;
  let ticketCustomValue;
  let ticketassignedTo;

  if (props?.ticketData?._source) {
    let { platform, activities, feed_id, status, custom_value, assigned_to } =
      props?.ticketData?._source;

    ticketPlatform = platform;
    ticketActivity = activities;
    ticketFeedId = feed_id;
    ticketStatus = status;
    ticketCustomValue = custom_value;
    ticketassignedTo = assigned_to?.user_id;
  }

  // console.log("ticketPlatform", {
  //   ticketPlatform,
  //   ticketActivity,
  //   ticketFeedId,
  //   ticketStatus,
  //   ticketCustomValue,
  // });

  const {
    openAIsuggestionTab,
    setOpenAIsuggestionTab,
    aiSuggestionTab,
    setAiSuggestionTab,
  } = props;

  const timeAgo = new TimeAgo("en-US");
  const [replyOpenStates, setReplyOpenStates] = useState({}); // Track reply open state
  const [emailOpenStates, setEmailOpenStates] = useState({}); // Track reply open state

  const handleReplyToggle = (index) => {
    setReplyOpenStates((prev) => ({
      ...Object.keys(prev).reduce((acc, key) => {
        acc[key] = false; // Close all previous ones
        return acc;
      }, {}),
      [index]: true, // Open only the last clicked index
    }));

    setEmailOpenStates({});
  };

  const handleEmailToggle = (index) => {
    setEmailOpenStates((prev) => ({
      ...Object.keys(prev).reduce((acc, key) => {
        acc[key] = false; // Close all previous ones
        return acc;
      }, {}),
      [index]: true, // Open only the last clicked index
    }));

    setReplyOpenStates({});
  };

  const [copied, setCopied] = useState(false);

  const copyToClipboard = (copyText) => {
    copy(copyText);
    setCopied(true);
  };

  const icon = (
    <CheckBoxOutlineBlankIcon
      className="p-0 m-0"
      fontSize="small"
      style={{
        color: `${theme.palette.ticketListView.iconColor}`,
        width: "13px",
      }}
    />
  );
  const checkedIcon = (
    <CheckBoxIcon
      fontSize="small"
      className="p-0 m-0"
      style={{
        // color: `${theme.palette.ticketListView.iconColor}`,
        width: "13px",
      }}
    />
  );

  const { isRequesting } = props;

  // fetch function for Instagram Platform
  function fetchInstaFeeds() {
    let filter = [];

    filter.push(
      `[["feed_id.keyword","must","","terms",${JSON.stringify(
        ticketFeedId ? ticketFeedId : []
      )}]]`
    );
    let params = {
      filters: `${filter}`,
      order_by: '[["created_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(InstagramFeedsAction.requestInstagramFeeds(params));
  }

  // fetch fn for instagram dm
  function fetchClosedTicketInstaDm() {
    let filter = [];

    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        ticketFeedId ? ticketFeedId : []
      )}],["is_deleted","must_not","","match","true"]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
      order_by: '[["created_on", "asc"]]',
    };
    props.dispatch(
      InstagramDirectMessageAction.requestInstagramDirectMessage(params)
    );
  }

  function fetchOpenTicketInstaDm() {
    let filter = [];

    filter.push(
      `["customer_username.keyword","must","","match","${
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id.platform &&
        props.ticketData._source.customer_id.platform[0] &&
        props.ticketData._source.customer_id.platform[0].account_id
      }"],["account_id.keyword","must","","match","${
        props.ticketData._source &&
        props.ticketData._source.account_name &&
        props.ticketData._source.account_name.account_id
      }"],["project.project_id.keyword","must","","match","${
        props.ticketData._source.project.project_id
      }"],["is_deleted","must_not","","match","true"]`
    );

    const params = {
      filters: `[${filter}]`,
      page_limit: "none",
      order_by: `[["created_on","asc"]]`,
    };

    props.dispatch(
      InstagramDirectMessageAction.requestInstagramDirectMessage(params)
    );
  }

  // gmd feeds fetch function
  function fetchQuestionsFeed() {
    let filter = [];
    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        ticketFeedId ? ticketFeedId : []
      )}]]`
    );
    let params = {
      filters: `${filter}`,
      order_by: '[["created_on", "asc"]]',
      page_limit: "none",
    };
    props.dispatch(GmbQuestionFeedsAction.requestGmbQuestionFeed(params));
  }

  // fetch function for playstore platform
  function fetchAppStore() {
    let filter = [];

    filter.push(
      `[["review_id.keyword","must","","terms",${JSON.stringify(
        ticketFeedId ? ticketFeedId : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      order_by: '[["created_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(AppStoreAction.requestAppStore(param));
  }

  // fetch function for linkedIn Platform

  function fetchOpenLinkedInFeed() {
    let filter = [];

    filter.push(
      `[["customer_id.keyword","must","","term",${JSON.stringify(
        props.ticketData._source.customer_id
          ? props.ticketData._source.customer_id?.platform?.[0]?.account_id
          : []
      )}]]`
    );
    let params = {
      filters: `${filter}`,
      order_by: '[["created_at", "asc"]]',
      page_limit: "none",
    };
    props.dispatch(LinkedInFeedAction.requestLinkedInFeed(params));
  }

  function fetchClosedLinkedInFeed() {
    let filter = [];

    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        ticketFeedId ? ticketFeedId : []
      )}]]`
    );
    let params = {
      filters: `${filter}`,
      order_by: '[["created_at", "asc"]]',
      page_limit: "none",
    };
    props.dispatch(LinkedInFeedAction.requestLinkedInFeed(params));
  }

  //Youtube feed fetch Function
  function fetchYoutubeFeeds() {
    let filter = [];

    filter.push(
      `[["feed_id.keyword","must","","terms",${JSON.stringify(
        ticketFeedId ? ticketFeedId : []
      )}]]`
    );
    let params = {
      filters: `${filter}`,
      order_by: '[["created_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(YoutubeAction.requestYoutube(params));
  }

  // fetch fn for gmb reviews
  function fetchReviewsFeed() {
    let filter = [];
    filter.push(
      `[["review_id.keyword","must","","terms",${JSON.stringify(
        ticketFeedId ? ticketFeedId : []
      )}]]`
    );
    let params = {
      filters: `${filter}`,
      order_by: '[["created_on", "asc"]]',
      page_limit: "none",
    };
    props.dispatch(GmbReviewAction.requestGmbReviewFeed(params));
  }
  // fetch function for playstore platform
  function fetchPlayStore() {
    let filter = [];
    filter.push(
      `[["reviewId.keyword","must","","terms",${JSON.stringify(
        ticketFeedId ? ticketFeedId : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      order_by: '[["review_created_at", "asc"]]',
      page_limit: "none",
    };
    props.dispatch(PlayStoreAction.requestPlayStore(param));
  }

  // telegram feed fetch function
  function fetchTelegramMessage() {
    let filter = [];

    filter.push(
      `[["feed_id","must","","terms",${JSON.stringify(
        ticketFeedId ? ticketFeedId : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      order_by: '[["created_on", "asc"]]',
      page_limit: "none",
    };

    props.dispatch(TelegramAction.requestTelegramFeeds(params));
  }
  //fetch function for Glassdoor platform
  function fetchGlassDoorReview() {
    let filter = [];

    filter.push(
      `[["feed_id.keyword","must","","terms",${JSON.stringify(
        ticketFeedId ? ticketFeedId : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      order_by: '[["created_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(GlassdoorFeedsAction.requestGlassdoorFeed(param));
  }

  // fetch fn of ambition  box
  function fetchAmbitionBoxReview() {
    let filter = [];

    filter.push(
      `[["feed_id.keyword","must","","terms",${JSON.stringify(
        ticketFeedId ? ticketFeedId : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      page_limit: "none",
    };
    props.dispatch(AmbitionBoxFeedsAction.requestAmbitionBoxFeed(param));
  }

  function fetchClosedEmailFeeds() {
    let filter = [];

    filter.push(
      `[["feed_id.keyword","must","","terms",${JSON.stringify(
        ticketFeedId ? ticketFeedId : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      page_limit: "none",
      order_by: `[["created_on","asc"]]`,
    };
    props.dispatch(EmailFeedsAction.requestEmailFeeds(param));
  }

  function fetchOpenEmailFeeds() {
    let filter = [];

    filter.push(
      `[["customer_name.keyword","must","","match",${JSON.stringify(
        props.ticketData._source.customer_id &&
          props.ticketData._source.customer_id.customer_name
          ? props.ticketData._source.customer_id.customer_name
          : null
      )}]]`
    );

    let param = {
      filters: `${filter}`,
      page_limit: "none",
      order_by: `[["created_on","asc"]]`,
    };
    props.dispatch(EmailFeedsAction.requestEmailFeeds(param));
  }

  //fetch function for Email platform
  function fetchEmailFeeds() {
    props?.finalStatuses?.length > 0 &&
    props.finalStatuses.includes(ticketStatus?.status_id)
      ? fetchClosedEmailFeeds()
      : fetchOpenEmailFeeds();
  }

  //fetch fn for Quora Platform
  function fetchQuorafeed() {
    let filter = [];
    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      page_limit: "none",
    };
    props.dispatch(QuoraFeedsAction.requestQuoraFeed(param));
  }

  // fetch function for Twitter Platform
  function fetchTwitter() {
    let filter = [];

    filter.push(
      `[["tweet_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      order_by: '[["created_on", "asc"]]',
      page_limit: "none",
    };
    props.dispatch(TwitterAction.requestTwitter(param));
  }

  // fetch fn for twitter direct mesage
  function fetchClosedTicketTwitterDm() {
    let filter = [];
    filter.push(
      `[["message_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      order_by: `[["created_on","asc"]]`,
      page_limit: "none",
    };
    props.dispatch(
      TwitterDirectMessageAction.requestTwitterDirectMessage(params)
    );
  }

  const fetchTwitterPlatform = () => {
    props?.finalStatuses?.length > 0 &&
    props.finalStatuses.includes(ticketStatus?.status_id)
      ? fetchClosedTicketTwitterDm()
      : fetchOpenTicketTwitterDm();
    fetchTwitter();
  };

  const fetchThreads = () => {
    let filter = [];

    filter.push(
      `[["thread_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      order_by: '[["created_on", "asc"]]',
      page_limit: "none",
    };
    props.dispatch(InstagramFeedsAction.requestThreads(param));
  };

  function fetchOpenTicketTwitterDm() {
    let filter = [];

    filter.push(
      `["feed_id.keyword","must","","match","${
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id.platform &&
        props.ticketData._source.customer_id.platform[0] &&
        props.ticketData._source.customer_id.platform[0].account_id
      }"],["account_name.keyword","must","","match","${
        props.ticketData._source.account_name &&
        props.ticketData._source.account_name.account_name
      }"],["project.project_id.keyword","must","","match","${
        props.ticketData._source.project.project_id
      }"]`
    );

    const params = {
      filters: `[${filter}]`,
      page_limit: "none",
      order_by: `[["created_on","asc"]]`,
    };

    props.dispatch(
      TwitterDirectMessageAction.requestTwitterDirectMessage(params)
    );
  }

  function Callbackfunc(resp) {
    let filter = [];

    filter.push(
      `[["conversation_id.keyword","must","","match",${JSON.stringify(
        resp &&
          resp.hits &&
          resp.hits.hits &&
          resp.hits.hits[0] &&
          resp.hits.hits[0]._source.conversation_id
          ? resp.hits.hits[0]._source.conversation_id
          : ""
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
      order_by: '[["created_on", "asc"]]',
    };
    props.dispatch(FacebookDmAction.requestFacebookMessage(params));
  }

  function fetchFbDm_message() {
    let filter = [];

    filter.push(
      `[["message_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
      order_by: '[["created_on", "asc"]]',
    };
    props.dispatch(
      FacebookDmAction.requestFacebookMessage(params, Callbackfunc)
    );
  }

  // fetch function for Facebook Platform
  function fetchClosedFacebook() {
    let filter = [];

    // Add comment_id filter
    filter.push([
      "comment_id.keyword",
      "must",
      "",
      "terms",
      props.ticketData._source.feed_id ? props.ticketData._source.feed_id : [],
    ]);

    // Add activities filter
    filter.push([
      "activities.keyword",
      "must_not",
      "",
      "match",
      "Post Mentions",
    ]);

    let param = {
      // comments: props.ticketData._source && props.ticketData._source.feed_id,
      filters: filter,
      page_limit: "none",
    };
    props.dispatch(FacebookAction.requestFacebook(param));
  }

  function fetchClosedFbPostMentions() {
    let filter = [];

    // Add _id filter
    filter.push([
      "_id",
      "must",
      "",
      "terms",
      props.ticketData._source.feed_id ? props.ticketData._source.feed_id : [],
    ]);

    // Add activities filter
    filter.push(["activities.keyword", "must", "", "match", "Post Mentions"]);

    let params = {
      filters: filter,
      order_by: '[["created_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(FacebookAction.requestFacebookPostMentions(params));
  }

  function fetchOpenFacebook() {
    let filter = [];

    // Add comment_id filter
    filter.push([
      "comment_id.keyword",
      "must",
      "",
      "terms",
      props.ticketData._source.feed_id ? props.ticketData._source.feed_id : [],
    ]);

    // Add customer_id filter
    filter.push([
      "commented_user_id.keyword",
      "must",
      "",
      "term",
      props.ticketData._source.customer_id
        ? props.ticketData._source.customer_id.platform?.[0]?.account_id
        : [],
    ]);

    // Add activities filter
    filter.push([
      "activities.keyword",
      "must_not",
      "",
      "match",
      "Post Mentions",
    ]);

    let param = {
      // comments: props.ticketData._source && props.ticketData._source.feed_id,
      filters: filter,
      page_limit: "none",
    };
    props.dispatch(FacebookAction.requestFacebook(param));
  }

  function fetchOPenFbPostMentions() {
    let filter = [];

    // Add _id filter
    filter.push([
      "_id",
      "must",
      "",
      "terms",
      props.ticketData._source.feed_id ? props.ticketData._source.feed_id : [],
    ]);

    // Add customer_id filter
    filter.push([
      "user_id.keyword",
      "must",
      "",
      "term",
      props.ticketData._source.customer_id
        ? props.ticketData._source.customer_id.platform?.[0]?.account_id
        : [],
    ]);

    // Add activities filter
    filter.push(["activities.keyword", "must", "", "match", "Post Mentions"]);

    let params = {
      filters: filter,
      order_by: '[["created_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(FacebookAction.requestFacebookPostMentions(params));
  }

  const fetchClosedTicketDm = () => {
    let filter = [];

    filter.push(
      `[["message_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
    };

    props.dispatch(FacebookDmAction.requestFacebookMessage(params));
  };

  const fetchFacebookPlatform = () => {
    if (
      props?.finalStatuses?.length > 0 &&
      props.finalStatuses.includes(ticketStatus?.status_id)
    ) {
      fetchClosedTicketDm();
      fetchClosedFacebook();
      fetchClosedFbPostMentions();
    } else {
      fetchFbDm_message();
      fetchOpenFacebook();
      fetchOPenFbPostMentions();
    }
  };

  const fetchInstagramPlatform = () => {
    props?.finalStatuses?.length > 0 &&
    props.finalStatuses.includes(ticketStatus?.status_id)
      ? fetchClosedTicketInstaDm()
      : fetchOpenTicketInstaDm();

    fetchInstaFeeds();
  };

  const fetchLinkedINPlatform = () => {
    props?.finalStatuses?.length > 0 &&
    props.finalStatuses.includes(ticketStatus?.status_id)
      ? fetchClosedLinkedInFeed()
      : fetchOpenLinkedInFeed();
  };

  // fetch fn for whatsapp
  function fetchOpenWhatsAppFeed() {
    let filter = [];

    filter.push(
      `[["customer_number.keyword","must","","match","${
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id.platform &&
        props.ticketData._source.customer_id.platform[0].account_id
      }"]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
      order_by: '[["created_on", "asc"]]',
    };
    props.dispatch(WhatsAppFeedAction.requestWhatsAppFeed(params));
  }

  function fetchClosedWhatsAppFeed() {
    let filter = [];

    filter.push(
      `[["feed_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
      order_by: '[["created_on", "asc"]]',
    };
    props.dispatch(WhatsAppFeedAction.requestWhatsAppFeed(params));
  }

  const fetchWhatsAppPlatform = () => {
    props.finalStatuses?.length > 0 &&
    props.finalStatuses.includes(ticketStatus?.status_id)
      ? fetchClosedWhatsAppFeed()
      : fetchOpenWhatsAppFeed();
  };

  function GMBplatformFeed() {
    fetchQuestionsFeed();
    fetchReviewsFeed();
  }

  function fetchIndeed() {
    let filter = [];

    filter.push(
      `[["review_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
    };

    props.dispatch(IndeedFeedAction.requestIndeedFeed(params));
  }

  function fetchTrustpilotPlatform() {
    let filter = [];

    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
    };

    props.dispatch(TrustpilotFeedsAction.requestTrustpilotFeeds(params));
  }

  function fetchPinterestPlatform() {
    let filter = [];

    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
    };

    props.dispatch(PinterestFeedsAction.requestPinterestFeeds(params));
  }

  function fetchRedditFeed() {
    let filter = [];

    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
    };
    if (props.ticketData._source.activities.activities_name === "Comments") {
      props.dispatch(RedditFeedAction.requestRedditComments(params));
    } else if (
      props.ticketData._source.activities.activities_name === "Posts"
    ) {
      props.dispatch(RedditFeedAction.requestRedditPosts(params));
    }
  }

  const isSmallScreen = useMediaQuery("(max-width:1200px)");

  function fetchConsumerFeeds() {
    let filter = [];

    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
    };

    props.dispatch(ConsumerAction.requestConsumer(params));
  }

  function fetchDiscordFeeds() {
    let filter = [];

    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
    };

    props.dispatch(DiscordFeedsAction.requestDiscordFeeds(params));
  }

  let allowed_platforms = ["LinkedIn", "Playstore", "GMB"];

  async function getRelatedFeedIds() {
    let related_tickets_feedIds = [];

    if (props.related_tickets.hits?.hits?.length > 0) {
      props.related_tickets.hits.hits.forEach((item) => {
        // Ensure `feed_id` is an array and flatten it properly
        if (Array.isArray(item._source?.feed_id)) {
          related_tickets_feedIds.push(...item._source.feed_id); // Spreading array values
        }
      });
    }

    return related_tickets_feedIds;
  }

  useEffect(() => {
    if (ticketPlatform) {
      // getRelatedFeedIds().then((relatedFeedIDs) => {
      //   console.log("relatedFeedIDs", relatedFeedIDs);
      //   if (relatedFeedIDs?.length > 0) {
      ticketPlatform?.platform_name === "Twitter" && fetchTwitterPlatform();

      ticketPlatform?.platform_name === "Threads" && fetchThreads();

      ticketPlatform?.platform_name === "Facebook" && fetchFacebookPlatform();

      ticketPlatform?.platform_name === "Instagram" && fetchInstagramPlatform();

      ticketPlatform?.platform_name === "Whatsapp" && fetchWhatsAppPlatform();

      ticketPlatform?.platform_name === "GMB" && GMBplatformFeed();

      ticketPlatform?.platform_name === "LinkedIn" && fetchLinkedINPlatform();
      ticketPlatform?.platform_name === "Youtube" && fetchYoutubeFeeds();
      ticketPlatform?.platform_name === "Ambition Box" &&
        fetchAmbitionBoxReview();

      ticketPlatform?.platform_name === "Glassdoor" && fetchGlassDoorReview();

      ticketPlatform?.platform_name === "Email" && fetchEmailFeeds();

      ticketPlatform?.platform_name === "Telegram" && fetchTelegramMessage();

      ticketPlatform?.platform_name === "Playstore" && fetchPlayStore();

      ticketPlatform?.platform_name === "Quora" && fetchQuorafeed();

      ticketPlatform?.platform_name === "Appstore" && fetchAppStore();

      ticketPlatform?.platform_name === "Indeed" && fetchIndeed();

      ticketPlatform?.platform_name === "Trust Pilot" &&
        fetchTrustpilotPlatform();

      ticketPlatform?.platform_name === "Pinterest" && fetchPinterestPlatform();

      ticketPlatform?.platform_name === "Reddit" && fetchRedditFeed();

      ticketPlatform?.platform_name === "Consumer Complaints" &&
        fetchConsumerFeeds();

      ticketPlatform?.platform_name === "Discord" && fetchDiscordFeeds();
      // }
      // });
    }
  }, [props.ticketData]);

  const returnCustomValues = (custom_fields) => {
    let category_values = props.category_custom_value;

    let filtered_category_vals = [];
    if (custom_fields && custom_fields.length !== 0) {
      filtered_category_vals = custom_fields.filter((item) => {
        return item.field_type === "category";
      });
    }

    let sub_value_id = [];

    if (filtered_category_vals && filtered_category_vals.length !== 0) {
      filtered_category_vals.map((item) => {
        sub_value_id.push(item.field_values[0]?.id);
      });
    }
    function ReturnCategoryName(nodes, id, names = []) {
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];

        names.push(nodes[i].value);

        if (node.id === id) {
          return names;
        } else if (node.sub_value && node.sub_value.length > 0) {
          const foundIndexes = ReturnCategoryName(node.sub_value, id, [
            ...names,
          ]);
          if (foundIndexes) {
            return foundIndexes;
          }
        }
        names.pop();
      }
      return null;
    }
    let custom_field_vals = ReturnCategoryName(
      category_values && category_values.length !== 0 && category_values,
      sub_value_id && sub_value_id[0] && sub_value_id[0]
    );

    return custom_field_vals;
  };

  let returnCustomValuesAsString = (data) => {
    let values = [];

    values = returnCustomValues(data);

    // Initialize an empty string to store the result
    let result = "";

    // Loop through the values and append them to the result string with a slash
    for (var i = 0; i < values.length; i++) {
      result += values[i];
      // Add a slash after every value, except for the last one
      if (i !== values.length - 1) {
        result += "/";
      }
    }
    return result;
  };

  const returntrueCategoryValues = (ticket) => {
    let bool_val = false;

    if (ticketCustomValue?.length !== 0) {
      ticketCustomValue
        .filter((val) => {
          return val.field_type === "category";
        })
        .map((item) => {
          if (item && item.field_values) {
            item?.field_values?.length && item?.field_values[0]?.id !== ""
              ? (bool_val = true)
              : (bool_val = false);
          }
        });
    }

    return bool_val;
  };

  const returnDirectMessageDate = () => {
    let directMessageData;
    if (ticketPlatform?.platform_name === "Twitter") {
      if (props?.twitterDirectMeassage?.hits?.length) {
        directMessageData = props.twitterDirectMeassage.hits;
      }
    } else {
      directMessageData = null;
    }
    return directMessageData;
  };

  const returnVisibleReplyBtn = (item, index) => {
    let boolean = true;

    if (ticketassignedTo === "Unassigned") {
      boolean = false;
    } else {
      if (ticketPlatform?.platform_name === "Instagram") {
        if (
          item._source.activities === "Tagged Posts"
          // ||
          // item._source.activities === "Captions Mention" ||
          // item._source.activities === "Comment Mentions"
        ) {
          boolean = false;
        }
      }

      if (ticketPlatform?.platform_name === "GMB") {
        if (item._index === "gmb_questions_answers") {
          // boolean = false;
        }
      }

      if (
        // ticketPlatform?.platform_name === "Appstore" ||
        ticketPlatform?.platform_name === "Consumer Complaints" ||
        ticketPlatform?.platform_name === "Indeed" ||
        ticketPlatform?.platform_name === "Ambition Box" ||
        ticketPlatform?.platform_name === "Quora"
      ) {
        boolean = false;
      }

      if (
        ticketPlatform?.platform_name === "Instagram" &&
        item._source.is_deleted
      ) {
        boolean = false;
      }

      if (ticketPlatform?.platform_name === "Reddit") {
        boolean = false;
      }

      if (
        ticketPlatform?.platform_name === "Playstore" &&
        props?.playstore?.hits?.length > 1
      ) {
        if (index !== undefined && index !== props.playstore.hits.length - 1) {
          boolean = false;
        }
      }

      if (
        ticketPlatform?.platform_name === "Facebook" &&
        props?.facebook?.hits?.length > 1
      ) {
        if (index !== undefined && item?._source?.is_deleted) {
          boolean = false;
        }
      }

      if (
        ticketPlatform?.platform_name === "GMB" &&
        props?.GMBReviewFeeds?.hits?.length > 1
      ) {
        if (
          index !== undefined &&
          index !== props.GMBReviewFeeds.hits.length - 1
        ) {
          boolean = false;
        }
      }

      if (item?._source?.feed_deleted === true) {
        boolean = false;
      }

      return boolean;
    }
  };

  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    return (
      date.toDateString() +
      " " +
      date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    );
  };

  const returnLogStatement = (ticket_id) => {
    let filtered_tickets =
      props.mass_ticket_history &&
      props.mass_ticket_history.filter((item) => {
        return item._source.ticket_id === ticket_id;
      });

    filtered_tickets =
      filtered_tickets &&
      filtered_tickets.filter((val) => {
        return val._source.final_status === true;
      });

    let closed_user =
      filtered_tickets[0] &&
      filtered_tickets[0]._source.description.split("By")[1];

    return (
      <Grid item container xl={12} m={0.5}>
        <Box
          width={"100%"}
          sx={{
            // paddingX: 2,
            paddingY: 0,
          }}
        >
          <Grid
            item
            container
            xl={12}
            lg={12}
            md={12}
            sm={12}
            alignItems="center"
            justifyContent={"flex-end"}
            // className="bg-success"
          >
            <Grid
              item
              textAlign={"right"}
              // xl={10}
              // lg={10}
              // md={10}
              // sm={10}
              // className="bg-info"
              sx={{
                backgroundColor: "white",
                border: "solid 0.5px #92b9c4",
                fontFamily: "BookAntiqua-Italic",
                borderRadius: 1,
              }}
              px={2}
            >
              <span
                style={{
                  color: `${theme.palette.primary.main}`,
                }}
              >
                <i
                  style={{
                    opacity: 1,
                  }}
                >
                  {`${closed_user ? closed_user : null}`}
                </i>
                &nbsp;
                <i
                  style={{
                    opacity: 0.8,
                  }}
                >{`has closed the ticket ID :`}</i>
                &nbsp;
                <i>
                  {filtered_tickets[0] && filtered_tickets[0]._source.ticket_id}
                </i>
                &nbsp;
                <i
                  style={{
                    opacity: 0.8,
                  }}
                >
                  at
                </i>
                &nbsp;
                <i
                  style={{
                    opacity: 1,
                    textDecoration: "underline",
                  }}
                >
                  {(function createdDate() {
                    let created =
                      filtered_tickets[0] &&
                      filtered_tickets[0]._source.created_on;
                    let dateConversion = new Date(created);
                    dateConversion =
                      dateConversion.toDateString() +
                      ", " +
                      dateConversion.toLocaleTimeString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      });

                    return dateConversion;
                  })()}
                </i>
              </span>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  };

  function ReturnFeeds(
    index,
    setFieldValue,
    values,
    item,
    setScrollToIndex,
    Array
  ) {
    if (props.replyClicked && replyOpenStates[index]) {
      setScrollToIndex(index);
      props?.setReplyClicked(false);
    } else if (props.emailClicked && emailOpenStates[index]) {
      setScrollToIndex(index);
      props?.setEmailClicked(false);
    } else {
      setScrollToIndex(Array.length - 1);
    }

    const {
      customerName,
      accountName,
      activityName,
      feedTimeValue,
      customerAvatarUrl,
      sentimentValue,
      feedLink,
      feedId,
      feedSourceData,
      platform_name,
      feedReplies,
      feedData,
    } = ReturnFeedData({
      index,
      item,
      ticketData: props.ticketData,
    });

    const idDmSent = feedSourceData?.flag === "sent";

    // console.log(
    //   "feed_Data",
    //   {
    //     customerName,
    //     accountName,
    //     activityName,
    //     feedTimeValue,
    //     customerAvatarUrl,
    //     sentimentValue,
    //     feedLink,
    //     feedId,
    //     feedSourceData,
    //     platform_name,
    //     feedReplies,
    //     feedData,
    //   },
    //   idDmSent
    // );

    return (
      <Box width={"100%"}>
        <Box>
          {index > 0 &&
            item._source.ticket_id !== Array[index - 1]._source.ticket_id && (
              <Divider textAlign="right">
                {returnLogStatement(Array[index - 1]._source.ticket_id)}
              </Divider>
            )}

          {!idDmSent && (
            <Box
              sx={{
                paddingX: 2,
                paddingTop: 2,
                paddingBottom: 3,
              }}
              // className="bg-warning"
            >
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                alignItems="center"
                justifyContent={"flex-start"}
                // className="bg-success"
              >
                <Grid
                  item
                  xl={props.showFeedDetails ? 10 : 8}
                  lg={10}
                  md={9}
                  sm={9}
                  xs={9}
                  alignItems="flex-start"
                  justifyContent={"center"}
                  textAlign="left"
                  sx={{
                    borderRadius: "10px",
                    backgroundColor: "white",
                    border: "solid 0.5px #92b9c4",
                  }}
                  // className="bg-warning"
                >
                  <Box width={"100%"}>
                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent="space-between"
                      alignItems={"center"}
                      // className="bg-warning"
                      py={1}
                      px={2}
                    >
                      <Grid
                        item
                        container
                        xl={7.5}
                        lg={8}
                        md={8}
                        sm={8}
                        xs={8}
                        justifyContent="flex-start"
                        alignItems={"center"}
                        // className="bg-success"
                        gap={2}
                      >
                        <Grid item>
                          <ListItem className="p-0 m-0">
                            <Avatar
                              style={{
                                width:
                                  platform_name === "Youtube" ? null : "20px",
                                height:
                                  platform_name === "Youtube" ? null : "20px",
                                // backgroundColor:
                                //   ticketPlatform
                                //     .platform_name === "Youtube" ||
                                //   ticketPlatform
                                //     .platform_name === "Indeed" ||
                                //   ticketPlatform
                                //     .platform_name === "Trust Pilot"
                                //     ? "white"
                                //     : `${theme.palette.primary.main}`,
                              }}
                            >
                              {returnPlatformDropdownLogos(platform_name)}
                            </Avatar>

                            <p
                              className="m-0 p-0 ml-1"
                              style={{
                                color: `${theme.palette.text.primary}`,
                                fontSize: "13px",
                              }}
                            >
                              {activityName}
                            </p>
                          </ListItem>
                        </Grid>

                        {/* Customer Name and Profile box */}
                        <Grid item>
                          <ListItem className="p-0 m-0">
                            <Avatar
                              style={{
                                width: "20px",
                                height: "20px",
                              }}
                              src={customerAvatarUrl}
                            >
                              {customerName?.charAt(0)}
                            </Avatar>

                            <p
                              className="p-0 m-0 ml-1"
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              {customerName}
                            </p>
                          </ListItem>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        container
                        xl={4.5}
                        xs={4}
                        justifyContent="flex-end"
                        alignItems={"center"}
                        gap={1}
                        // className="bg-warning"
                      >
                        <Grid
                          px={1}
                          py={0}
                          item
                          sx={{
                            backgroundColor: "#fafafa",
                            border: `0.2px solid 
                      ${theme.palette.background.primary}`,
                            borderRadius: 2,
                            color: "#39717c",
                          }}
                          textAlign="center"
                        >
                          <span
                            style={{
                              color: `${theme.palette.ticketListView.checkbox}`,
                              fontSize: "12px",
                            }}
                          >
                            {accountName}
                          </span>
                        </Grid>

                        <Grid item>
                          <Tooltip
                            title={`Mentioned On : ${getFormattedDate(
                              feedTimeValue
                            )}`}
                            arrow
                            placement="left"
                            style={{
                              cursor: "default",
                            }}
                          >
                            <ListItem className="p-0 m-0">
                              <AccessTimeIcon
                                style={{
                                  width: "13px",
                                  marginRight: 2,
                                  color: `${theme.palette.text.dark}`,
                                }}
                              />
                              <span
                                className="m-0 p-0"
                                style={{
                                  fontSize: "12px",
                                  color: `${theme.palette.text.dark}`,
                                }}
                              >
                                {feedTimeValue
                                  ? timeAgo?.format(new Date(feedTimeValue))
                                  : null}
                              </span>
                            </ListItem>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>

                    <hr className="p-0 m-0" />

                    <Box py={0.5}>
                      <RenderFeedContent
                        index={index}
                        item={item}
                        ticketData={props.ticketData}
                        quoraFeed={props.quoraFeed}
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    </Box>

                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent="space-between"
                      alignItems={"center"}
                      style={{
                        backgroundColor: "#f3f9fb",
                        borderTop: `0.5px solid 
                      ${theme.palette.background.primary}`,
                        borderBottom: `0.5px solid 
                      ${theme.palette.background.primary}`,
                      }}
                      px={2}
                    >
                      <Grid
                        item
                        container
                        xs={10}
                        justifyContent="flex-start"
                        alignItems={"center"}
                        my={0.5}
                        // className="bg-success"
                      >
                        <Grid
                          item
                          alignItems="center"
                          justifyContent={"flex-start"}
                          textAlign="left"
                          sx={{
                            borderRight: `1px solid ${theme.palette.background.primary}`,
                          }}
                          pr={1}
                          // className="bg-light"
                        >
                          <Grid item>
                            <ListItem
                              className="p-0 m-0"
                              style={{
                                padding: 0,
                                margin: 0,
                                color: `${theme.palette.ticketListView.iconColor}`,
                              }}
                            >
                              <Checkbox
                                className="p-0 m-0"
                                icon={icon}
                                checkedIcon={checkedIcon}
                              />
                            </ListItem>
                          </Grid>
                        </Grid>
                        {item?._source?.ticket_id && (
                          <Grid
                            item
                            alignItems="center"
                            justifyContent={"center"}
                            textAlign="center"
                            // className="bg-info"
                            sx={{
                              borderRight: `1px solid ${theme.palette.background.primary}`,
                            }}
                            px={1}
                          >
                            <Grid item>
                              <IconButton
                                className="p-0 m-0"
                                title="Ticket Id"
                                color="primary"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  copyToClipboard(item._source.ticket_id);
                                }}
                                sx={{
                                  color: `${theme.palette.ticketListView.iconColor}`,
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faCopy}
                                  style={{
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                    width: "13px",
                                  }}
                                ></FontAwesomeIcon>
                              </IconButton>
                              <Snackbar
                                message="Ticket Id copied to clipboard"
                                sx={{ height: "70%" }}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                                autoHideDuration={500}
                                onClose={() => setCopied(false)}
                                open={copied}
                              />
                            </Grid>
                          </Grid>
                        )}
                        <Grid
                          item
                          alignItems="center"
                          justifyContent={"center"}
                          textAlign="center"
                          // className="bg-info"
                          sx={{
                            borderRight: `1px solid ${theme.palette.background.primary}`,
                          }}
                          px={1}
                        >
                          <SentimentValueUpdatePopper
                            ticketData={props.ticketData}
                            feedData={item}
                            from="feeds"
                            fb_comment_id={
                              platform_name === "Facebook" &&
                              props?.facebook?.hits?.[index]?._source
                                ?.comment_id
                            }
                            feedId={feedId}
                            fetchFunction={(sentiment) => {
                              let localstorage_sentiment =
                                localStorage.getItem("mentionsFilters") &&
                                JSON.parse(
                                  localStorage.getItem("mentionsFilters")
                                ).sentiment_value;

                              if (localstorage_sentiment) {
                                if (localstorage_sentiment !== sentiment) {
                                  props.fetchFunction();
                                } else {
                                  props.dispatch(
                                    TicketsAction.requestTicketsById(
                                      props.ticketData._id
                                    )
                                  );
                                }
                              } else {
                                props.dispatch(
                                  TicketsAction.requestTicketsById(
                                    props.ticketData._id
                                  )
                                );
                              }
                            }}
                            filters={props.filters}
                            pageNumber={props.pageNumber}
                            page_limit={props.page_limit}
                          >
                            {sentimentValue > 0 ? (
                              <SentimentSatisfiedAltRoundedIcon
                                titleAccess="Positive"
                                style={{
                                  width: "16px",
                                  color: `green`,
                                }}
                              />
                            ) : sentimentValue < 0 ? (
                              <SentimentDissatisfiedRoundedIcon
                                titleAccess="Negative"
                                style={{
                                  width: "16px",
                                  color: `${theme.palette.sentimentIconColor.negative}`,
                                }}
                              />
                            ) : sentimentValue !== null ? (
                              <SentimentNeutralRoundedIcon
                                titleAccess="Neutral"
                                style={{
                                  width: "16px",
                                  color: `${theme.palette.sentimentIconColor.neutral}`,
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </SentimentValueUpdatePopper>
                        </Grid>

                        {feedSourceData?.ticket_id ===
                        props?.ticketData?._id ? (
                          <Grid
                            item
                            container
                            xl={3}
                            lg={1.5}
                            md={0.5}
                            xs={0.5}
                            alignItems="center"
                            justifyContent={"center"}
                            textAlign="center"
                            sx={{
                              borderRight: `1px solid ${theme.palette.background.primary}`,
                            }}
                            title={
                              ticketCustomValue?.length !== 0 &&
                              returntrueCategoryValues(props.ticketData) &&
                              returnCustomValues(ticketCustomValue) &&
                              returnCustomValuesAsString(ticketCustomValue)
                            }
                          >
                            <ListItem className="py-0 m-0 px-1">
                              <StyleIcon
                                style={{
                                  width: "16px",
                                  marginRight: 2,
                                  color: `${theme.palette.ticketListView.iconColor}`,
                                }}
                              />
                              <p
                                className="m-0 p-0"
                                data-testid="ticketFeedCategory"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "100px",
                                  cursor: "pointer",
                                  fontSize: "12px",
                                }}
                                onClick={() => {
                                  props.openCloseModal(
                                    !props.categoryUpdateOpen
                                  );
                                }}
                              >
                                {ticketCustomValue?.length !== 0 &&
                                returntrueCategoryValues(props.ticketData)
                                  ? returnCustomValues(ticketCustomValue) &&
                                    returnCustomValuesAsString(
                                      ticketCustomValue
                                    )
                                  : `Select Category`}
                              </p>
                            </ListItem>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent="space-between"
                      alignItems={"center"}
                      my={1}
                      px={2}
                      display="flex"
                      // className="bg-warning"
                    >
                      <Grid
                        item
                        container
                        xl={5}
                        xs={7}
                        laptop={5}
                        justifyContent="flex-start"
                        alignItems={"center"}
                        display="flex"
                        // className="bg-success"
                      >
                        {/* {ticketassignedTo !== "Unassigned" &&
                          allowed_platforms?.includes(platform_name) &&
                          !returnVisibleReplyBtn(item) && (
                            <Grid
                              item
                              alignItems="center"
                              justifyContent={"center"}
                              textAlign="center"
                              display={{
                                sm: "block",
                                laptop: "block",
                              }}
                            >
                              <Typography fontSize={{ xl: 15, md: 10 }}>
                                <IconButton
                                  className="p-0 m-0"
                                  title={
                                    item._source.is_deleted
                                      ? `This feed has been Deleted from ${item._source.platform}`
                                      : `User has deleted this '${item?._source?.activities}' from the console.`
                                  }
                                >
                                  <ReportGmailerrorredIcon
                                    style={{
                                      width: "13px",
                                      // marginRight: 2,
                                      color: `${theme.palette.text.error}`,
                                    }}
                                  />
                                </IconButton>
                              </Typography>
                            </Grid>
                          )} */}

                        <Grid
                          item
                          alignItems="center"
                          justifyContent={"center"}
                          textAlign="center"
                          sx={{
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: "5px",
                          }}
                          display={{
                            xl: "block",
                            lg: "block",
                            md: "none",
                            sm: "none",
                            laptop: "none",
                          }}
                        >
                          <ReturnDeletedStatement
                            componentProps={props}
                            feed_item={item}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        container
                        xl={7}
                        lg={5}
                        laptop={7}
                        md={5}
                        sm={5}
                        xs={5}
                        justifyContent="flex-end"
                        alignItems={"center"}
                        gap={1}
                        display="flex"
                        // className="bg-info"
                      >
                        {/* {!returnVisibleReplyBtn(item) && (
                          <Grid
                            item
                            alignItems="center"
                            justifyContent={"center"}
                            textAlign="center"
                          >
                            <Typography fontSize={{ xl: 15, md: 10 }}>
                              <IconButton
                                className="p-0 m-0"
                                title={
                                  item._source.is_deleted
                                    ? `This feed has been Deleted from ${item._source.platform}`
                                    : ticketassignedTo === "Unassigned"
                                    ? "A reply can't be sent without a user assigned."
                                    : "There is no reply action for this activity"
                                }
                              >
                                <ReportGmailerrorredIcon
                                  style={{
                                    width: "13px",
                                    // marginRight: 2,
                                    color: `${theme.palette.text.error}`,
                                  }}
                                />
                              </IconButton>
                            </Typography>
                          </Grid>
                        )} */}

                        {/* Ignore button Container */}
                        {props?.finalStatuses?.length > 0 &&
                          props.finalStatuses.includes(
                            props?.ticketData?._source?.status?.status_id
                          ) === false && (
                            <Grid
                              item
                              alignItems="center"
                              justifyContent={"center"}
                              textAlign="center"
                              sx={{
                                // "&:hover": {
                                //   transform: "scale(1.05)",
                                // },
                                backgroundColor: theme.palette.background.paper,
                                border: `1px solid ${theme.palette.background.primary}`,
                                borderRadius: "5px",
                              }}
                              paddingX={1}

                              // className="bg-warning"
                            >
                              <DirectClosePopper
                                finalStatuses={props.finalStatuses}
                                statusList={props.statusList}
                                ticketID={props.ticketData._id}
                                fetchFunction={() => {
                                  props.fetchFunction();
                                }}
                                item={props.ticketData}
                                returnVisibleReplyBtn={returnVisibleReplyBtn(
                                  item,
                                  index
                                )}
                              >
                                <Tooltip
                                  title={
                                    !returnVisibleReplyBtn(item, index)
                                      ? ticketassignedTo === "Unassigned"
                                        ? "Assign this ticket to a Bridge user to enable ignore option"
                                        : `User has deleted this '${item?._source?.activities}' from the console`
                                      : isSmallScreen
                                      ? "Ignore"
                                      : null
                                  }
                                >
                                  <ListItem
                                    className="p-0 m-0"
                                    sx={{
                                      height: 20,
                                      display: "flex",
                                      textAlign: "center",
                                      cursor: "pointer",
                                      opacity: returnVisibleReplyBtn(
                                        item,
                                        index
                                      )
                                        ? 1
                                        : 0.5,

                                      cursor: returnVisibleReplyBtn(item, index)
                                        ? "pointer"
                                        : "default",
                                    }}
                                  >
                                    <HighlightOffIcon
                                      style={{
                                        width: "13px",
                                        marginRight: 2,
                                        color: `${theme.palette.ticketListView.iconColor}`,
                                      }}
                                    />
                                    <Typography
                                      fontSize={{
                                        xl: "13px",
                                        lg: "12px",
                                        md: "11px",
                                        laptop: "11px",
                                      }}
                                      display={{
                                        xl: "block",
                                        sm: "none",
                                      }}
                                    >
                                      <p className="m-0 p-0">Ignore</p>
                                    </Typography>
                                  </ListItem>
                                </Tooltip>
                              </DirectClosePopper>
                            </Grid>
                          )}

                        {/* Reply Button Container */}
                        {props?.finalStatuses?.length > 0 &&
                          props.finalStatuses.includes(
                            props?.ticketData?._source?.status?.status_id
                          ) === false && (
                            <Grid
                              item
                              alignItems="center"
                              justifyContent={"center"}
                              textAlign="center"
                              sx={{
                                // "&:hover": {
                                //   transform: "scale(1.05)",
                                // },
                                backgroundColor: theme.palette.background.paper,
                                border: `1px solid ${theme.palette.background.primary}`,
                                borderRadius: "5px",
                              }}
                              paddingX={1}
                              data-index={index}
                            >
                              <Tooltip
                                title={
                                  !returnVisibleReplyBtn(item, index)
                                    ? ticketassignedTo === "Unassigned"
                                      ? "Assign this ticket to a Bridge user to enable reply option"
                                      : `User has deleted this '${item?._source?.activities}' from the console`
                                    : isSmallScreen
                                    ? "Reply"
                                    : null
                                }
                              >
                                <ListItem
                                  className="p-0 m-0"
                                  sx={{
                                    height: 20,
                                    display: "flex",
                                    textAlign: "center",

                                    opacity: returnVisibleReplyBtn(item, index)
                                      ? 1
                                      : 0.5,

                                    cursor: returnVisibleReplyBtn(item, index)
                                      ? "pointer"
                                      : "default",
                                  }}
                                  onClick={() => {
                                    // handleReplyButtonClick(
                                    //   index,
                                    //   setFieldValue,
                                    //   values
                                    // );

                                    if (returnVisibleReplyBtn(item, index)) {
                                      handleReplyToggle(index);
                                    }
                                  }}
                                >
                                  <ReplyIcon
                                    style={{
                                      width: "13px",
                                      marginRight: 2,
                                      color: `${theme.palette.ticketListView.iconColor}`,
                                    }}
                                  />
                                  <Typography
                                    fontSize={{
                                      xl: "13px",
                                      lg: "12px",
                                      md: "11px",
                                      laptop: "11px",
                                    }}
                                    display={{
                                      xl: "block",
                                      sm: "none",
                                    }}
                                  >
                                    <p className="m-0 p-0">Reply</p>
                                  </Typography>
                                </ListItem>
                              </Tooltip>
                            </Grid>
                          )}

                        {/* {props?.finalStatuses?.length > 0 &&
                          props.finalStatuses.includes(
                            props?.ticketData?._source?.status?.status_id
                          ) === false && (
                            <Grid
                              item
                              alignItems="center"
                              justifyContent={"center"}
                              textAlign="center"
                              sx={{
                                // "&:hover": {
                                //   transform: "scale(1.05)",
                                // },
                                backgroundColor: theme.palette.background.paper,
                                border: `1px solid ${theme.palette.background.primary}`,
                                borderRadius: "5px",
                              }}
                              paddingX={1}
                            >
                              <ListItem
                                className="p-0 m-0"
                                sx={{
                                  height: 20,
                                  display: "flex",
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleEmailToggle(
                                    index,
                                    setFieldValue,
                                    values
                                  );
                                }}
                              >
                                <EmailOutlinedIcon
                                  style={{
                                    width: "13px",
                                    marginRight: 2,
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                  }}
                                />
                                <Typography
                                  fontSize={{
                                    xl: "13px",
                                    lg: "12px",
                                    md: "11px",
                                    laptop: "11px",
                                  }}
                                  display={{
                                    xl: "block",
                                    sm: "none",
                                  }}
                                >
                                  <p className="m-0 p-0">Send Email</p>
                                </Typography>
                              </ListItem>
                            </Grid>
                          )} */}

                        {/* Open Link Button Container */}
                        {props?.finalStatuses?.length > 0 &&
                          props.finalStatuses.includes(
                            props?.ticketData?._source?.status?.status_id
                          ) &&
                          [
                            "Twitter",
                            "Facebook",
                            "Instagram",
                            "Youtube",
                            "LinkedIn",
                            "GMB",
                            "Appstore",
                            "Indeed",
                            "Reddit",
                            "Consumer Complaints",
                            "Quora",
                            "Trust Pilot",
                            "Pinterest",
                            "Playstore",
                            "Threads",
                          ]?.includes(platform_name) &&
                          feedLink && (
                            <Grid
                              item
                              alignItems="center"
                              justifyContent={"center"}
                              textAlign="center"
                            >
                              <Typography
                                component={"a"}
                                href={feedLink}
                                target="_blank"
                                title="Feed Link"
                              >
                                <IconButton className="p-0 m-0">
                                  <OpenInNewIcon
                                    style={{
                                      width: "13px",
                                      color: `${theme.palette.ticketListView.iconColor}`,
                                    }}
                                  />
                                </IconButton>
                              </Typography>
                            </Grid>
                          )}

                        {props.finalStatuses &&
                          props.finalStatuses.length > 0 &&
                          props.finalStatuses.includes(
                            props.ticketData &&
                              props.ticketData._source &&
                              props.ticketData._source.status.status_id
                          ) === false &&
                          [
                            "Twitter",
                            "Facebook",
                            "Instagram",
                            "Youtube",
                            "LinkedIn",
                            "GMB",
                            "Appstore",
                            "Indeed",
                            "Reddit",
                            "Consumer Complaints",
                            "Quora",
                            "Trust Pilot",
                            "Pinterest",
                            "Playstore",
                            "Threads",
                          ]?.includes(platform_name) &&
                          feedLink && (
                            <Grid
                              item
                              alignItems="center"
                              justifyContent={"center"}
                              textAlign="center"
                              sx={{
                                backgroundColor: theme.palette.background.paper,
                                border: `1px solid ${theme.palette.background.primary}`,
                                borderRadius: "5px",
                              }}
                              paddingX={1}
                            >
                              <Typography
                                component={"a"}
                                href={feedLink ?? ""}
                                target="_blank"
                                // title="Feed Link"
                              >
                                <ListItem
                                  className="p-0 m-0"
                                  sx={{
                                    height: 20,
                                    display: "flex",
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <OpenInNewIcon
                                    style={{
                                      width: "13px",
                                      marginRight: 2,
                                      color: `${theme.palette.ticketListView.iconColor}`,
                                    }}
                                  />
                                  <Typography
                                    fontSize={{
                                      xl: "13px",
                                      lg: "12px",
                                      md: "11px",
                                      laptop: "11px",
                                    }}
                                    display={{
                                      xl: "block",
                                      lg: "none",
                                      md: "none",
                                      sm: "none",
                                      laptop: "none",
                                    }}
                                  >
                                    <p className="m-0 p-0">Open Link</p>
                                  </Typography>
                                </ListItem>
                              </Typography>
                            </Grid>
                          )}

                        {/* working */}
                        {props?.finalStatuses?.length > 0 &&
                          props.finalStatuses.includes(
                            props?.ticketData?._source?.status?.status_id
                          ) === false && (
                            <Grid
                              item
                              // xl={1}
                              // lg={1}
                              // md={1}
                              // sm={1}
                              // xs={1}
                              alignItems="center"
                              justifyContent={"center"}
                              textAlign="center"
                              // className="bg-success"
                            >
                              <ThreeDotsPopper
                                comment_id={item?._source?.comment_id}
                                is_hidden={
                                  platform_name === "Instagram"
                                    ? item?._source?.comment_is_hidden &&
                                      item._source.comment_is_hidden
                                    : platform_name === "Facebook"
                                    ? item &&
                                      item._source &&
                                      item._source.is_hidden
                                    : false
                                }
                                is_deleted={
                                  platform_name === "Instagram"
                                    ? item?._source?.is_deleted &&
                                      item._source.is_deleted
                                    : platform_name === "Facebook"
                                    ? item?._source?.is_deleted &&
                                      item._source.is_deleted
                                    : false
                                }
                                ticketData={props.ticketData}
                                feedData={
                                  platform_name === "Instagram"
                                    ? props.instagramFeeds.hits[index]
                                    : null
                                }
                                feed={item}
                                retweeted={item?._source?.retweeted}
                                tweet_id={item?._source?.tweet_id}
                                setTranslate={() =>
                                  setFieldValue(
                                    `feed[${index}].translateClicked`,
                                    !values.feed[index].translateClicked
                                  )
                                }
                                translated={
                                  values?.feed?.[index]?.translateClicked
                                    ? values.feed[index].translateClicked
                                    : false
                                }
                                openAIsuggestionTab={openAIsuggestionTab}
                                setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                                aiSuggestionTab={aiSuggestionTab}
                                setAiSuggestionTab={setAiSuggestionTab}
                              >
                                <ListItem
                                  className="m-0 p-0"
                                  sx={{
                                    height: 15,
                                    backgroundColor:
                                      theme.palette.background.paper,
                                    // padding: 1,
                                    cursor: "pointer",
                                    // border: `0.5px solid #d3e2e6`,
                                    marginY: 1,
                                    // "&:hover": {
                                    //   transform: "scale(1.05)",
                                    // },
                                  }}
                                >
                                  <MoreVertIcon
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.text.primary}`,
                                    }}
                                  />
                                </ListItem>
                              </ThreeDotsPopper>
                            </Grid>
                          )}

                        {props?.finalStatuses?.length > 0 &&
                          props.finalStatuses.includes(
                            props?.ticketData?._source?.status?.status_id
                          ) && (
                            <Grid
                              item
                              alignItems="center"
                              justifyContent={"center"}
                              textAlign="center"
                              // className="bg-success"
                            >
                              <ListItem
                                className="m-0 p-0"
                                sx={{
                                  height: 15,
                                  backgroundColor:
                                    theme.palette.background.paper,
                                  cursor: "pointer",
                                  marginY: 1,
                                  // "&:hover": {
                                  //   transform: "scale(1.05)",
                                  // },
                                }}
                                onClick={() => {
                                  handleEmailToggle(
                                    index,
                                    setFieldValue,
                                    values
                                  );
                                }}
                              >
                                <IconButton
                                  className="p-0 m-0"
                                  title="Send Mail"
                                >
                                  <EmailOutlinedIcon
                                    style={{
                                      width: "13px",
                                      marginRight: 2,
                                      color: `${theme.palette.ticketListView.iconColor}`,
                                    }}
                                  />
                                </IconButton>
                              </ListItem>
                            </Grid>
                          )}

                        {props?.finalStatuses?.length > 0 &&
                          props.finalStatuses.includes(
                            props?.ticketData?._source?.status?.status_id
                          ) && (
                            <Grid
                              item
                              alignItems="center"
                              justifyContent={"center"}
                              textAlign="center"
                            >
                              <ListItem
                                className="m-0 p-0"
                                sx={{
                                  height: 15,
                                  backgroundColor:
                                    theme.palette.background.paper,
                                  cursor: "pointer",
                                  marginY: 1,
                                  // "&:hover": {
                                  //   transform: "scale(1.05)",
                                  // },
                                }}
                                onClick={() => {
                                  setFieldValue(
                                    `feed[${index}].translateClicked`,
                                    !values.feed[index].translateClicked
                                  );
                                }}
                              >
                                <IconButton
                                  className="p-0 m-0"
                                  title="Translate"
                                >
                                  <GTranslateIcon
                                    style={{
                                      width: "13px",
                                      marginRight: 2,
                                      color: values.feed[index].translateClicked
                                        ? theme.palette.primary.main
                                        : `${theme.palette.ticketListView.iconColor}`,
                                    }}
                                  />
                                </IconButton>
                              </ListItem>
                            </Grid>
                          )}
                      </Grid>
                    </Grid>
                  </Box>

                  {replyOpenStates[index] && (
                    <Box data-reply-open={values.feed[index].replyOpen}>
                      <ReplyComponent
                        setReplyOpenStates={setReplyOpenStates}
                        replyOpenStates={replyOpenStates}
                        directMessageDate={returnDirectMessageDate()}
                        index={index}
                        fetchFunction={props.fetchFunction}
                        setFieldValue={setFieldValue}
                        values={values}
                        // setReplyClicked={props.setReplyClicked}
                        showBoxReplyBox={values.feed[index].replyOpen}
                        ticketData={props.ticketData}
                        filters={props.filters}
                        pageNumber={props.pageNumber}
                        page_limit={props.page_limit}
                        page_length={props.page_length}
                        ticket_count_filters={props.ticket_count_filters}
                        statusList={props.statusList ? props.statusList : []}
                        feedData={feedData ? feedData : null}
                      />
                    </Box>
                  )}

                  {emailOpenStates[index] && (
                    <EmailComponent
                      index={index}
                      setFieldValue={setFieldValue}
                      values={values}
                      showEmailBox={values.feed[index].emailOpen}
                      setEmailClicked={props.setEmailClicked}
                      ticketData={props.ticketData}
                      setEmailOpenStates={setEmailOpenStates}
                      emailOpenStates={emailOpenStates}
                      feedData={
                        props?.emailFeeds?.hits?.[index]?._source
                          ? props.emailFeeds.hits[index]._source
                          : null
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          )}

          {/* Replies container for different platforms  */}
          <Grid>
            {!isRequesting &&
              feedReplies?.length !== 0 &&
              feedReplies?.map((reply) => (
                <TicketFeedRepliesContainer
                  showFeedDetails={props.showFeedDetails}
                  ticketData={props.ticketData}
                  feedData={feedData}
                  reply={reply}
                  openCloseModal={props.openCloseModal}
                  category_custom_value={props.category_custom_value}
                />
              ))}
          </Grid>
        </Box>
      </Box>
    );
  }

  const boxRef = useRef(null);
  const [scrollToIndex, setScrollToIndex] = useState(null);

  useEffect(() => {
    if (
      !isRequesting &&
      boxRef.current &&
      scrollToIndex !== null &&
      (props.replyClicked || props.emailClicked)
    ) {
      const container = boxRef.current.querySelector(
        `[data-index="${scrollToIndex}"]`
      );

      if (container) {
        container.scrollIntoView({ behavior: "smooth" });

        // Delay resetting scrollToIndex to prevent immediate re-renders
        setScrollToIndex(null);
      }
    }
  }, [isRequesting, scrollToIndex]);

  function RenderFormikWrappedFeed({ ticketPlatformName, ticketSourceData }) {
    let ticketContentCheck = null;

    let allFeeds = [];

    if (ticketPlatformName === "Facebook") {
      ticketContentCheck = ticketSourceData.ticket_desc;

      if (props.facebook?.hits?.length) {
        allFeeds = [...allFeeds, ...props.facebook.hits];
      }
      if (props.facebook_postMentions?.hits?.length) {
        allFeeds = [...allFeeds, ...props.facebook_postMentions.hits];
      }
      if (props.facebook_dm?.hits?.length) {
        allFeeds = [...allFeeds, ...props.facebook_dm.hits];
      }
    }

    if (ticketPlatformName === "Twitter") {
      ticketContentCheck = ticketSourceData.ticket_desc;

      if (props.twitter?.hits?.length) {
        allFeeds = [...allFeeds, ...props.twitter.hits];
      }

      if (props.twitterDirectMeassage?.hits?.length) {
        allFeeds = [...allFeeds, ...props.twitterDirectMeassage.hits];
      }
    }

    if (ticketPlatformName === "Instagram") {
      ticketContentCheck = ticketSourceData.ticket_name;

      if (props.instagramFeeds?.hits?.length) {
        allFeeds = [...allFeeds, ...props.instagramFeeds.hits];
      }

      if (props.instagramDirectMessage?.hits?.length) {
        allFeeds = [...allFeeds, ...props.instagramDirectMessage.hits];
      }
    }

    if (ticketPlatformName === "GMB") {
      ticketContentCheck = ticketSourceData.ticket_desc;

      if (props.GMBQuestionFeeds?.hits?.length) {
        allFeeds = [...allFeeds, ...props.GMBQuestionFeeds.hits];
      }
      if (props.GMBReviewFeeds?.hits?.length) {
        allFeeds = [...allFeeds, ...props.GMBReviewFeeds.hits];
      }
    }

    if (ticketPlatformName === "LinkedIn") {
      ticketContentCheck = ticketSourceData.ticket_name;

      if (props.linkedInFeeds?.hits?.length) {
        allFeeds = [...allFeeds, ...props.linkedInFeeds.hits];
      }
    }

    if (ticketPlatformName === "Youtube") {
      ticketContentCheck = ticketSourceData.ticket_desc;
      if (props.youtubeFeed?.hits?.length) {
        allFeeds = [...allFeeds, ...props.youtubeFeed.hits];
      }
    }

    if (ticketPlatformName === "Playstore") {
      ticketContentCheck = ticketSourceData.ticket_desc?.split("/ratings/")[0];

      if (props.playstore?.hits?.length) {
        allFeeds = [...allFeeds, ...props.playstore.hits];
      }
    }

    if (ticketPlatformName === "Appstore") {
      ticketContentCheck = ticketSourceData.ticket_desc?.split("/ratings/")[0];

      if (props.appStore?.hits?.length) {
        allFeeds = [...allFeeds, ...props.appStore.hits];
      }
    }

    if (ticketPlatformName === "Ambition Box") {
      ticketContentCheck = ticketSourceData.ticket_desc;
      if (props.ambitionBoxFeeds?.hits?.length) {
        allFeeds = [...allFeeds, ...props.ambitionBoxFeeds.hits];
      }
    }

    if (ticketPlatformName === "Glassdoor") {
      ticketContentCheck = ticketSourceData.ticket_desc;
      if (props.glassdoorFeeds?.hits?.length) {
        allFeeds = [...allFeeds, ...props.glassdoorFeeds.hits];
      }
    }

    if (ticketPlatformName === "Email") {
      ticketContentCheck = ticketSourceData.ticket_desc;
      if (props.emailFeeds?.hits?.length) {
        allFeeds = [...allFeeds, ...props.emailFeeds.hits];
      }
    }

    if (ticketPlatformName === "Quora") {
      ticketContentCheck = ticketSourceData.ticket_desc;
      if (props.quoraFeed?.hits?.length) {
        allFeeds = [...allFeeds, ...props.quoraFeed.hits];
      }
    }

    if (ticketPlatformName === "Indeed") {
      ticketContentCheck =
        ticketSourceData.ticket_desc?.split("/ratings/")?.[0] ?? "";
      if (props.indeedFeeds?.hits?.length) {
        allFeeds = [...allFeeds, ...props.indeedFeeds.hits];
      }
    }

    if (ticketPlatformName === "Trust Pilot") {
      ticketContentCheck = ticketSourceData.ticket_desc;
      if (props.trustPilotFeeds?.hits?.length) {
        allFeeds = [...allFeeds, ...props.trustPilotFeeds.hits];
      }
    }

    if (ticketPlatformName === "Pinterest") {
      ticketContentCheck = ticketSourceData.ticket_desc;
      if (props.pinterestFeeds?.hits?.length) {
        allFeeds = [...allFeeds, ...props.pinterestFeeds.hits];
      }
    }

    if (ticketPlatformName === "Reddit") {
      ticketContentCheck = ticketSourceData.ticket_desc;

      if (props.redditPosts?.hits?.length) {
        allFeeds = [...allFeeds, ...props.redditPosts.hits];
      }
      if (props.redditComments?.hits?.length) {
        allFeeds = [...allFeeds, ...props.redditComments.hits];
      }
    }

    if (ticketPlatformName === "Consumer Complaints") {
      ticketContentCheck = ticketSourceData.ticket_desc;

      if (props.consumer_feed?.hits?.length) {
        allFeeds = [...allFeeds, ...props.consumer_feed.hits];
      }
    }

    if (ticketPlatformName === "Telegram") {
      if (props.telegramFeeds?.hits?.length) {
        allFeeds = [...allFeeds, ...props.telegramFeeds.hits];
      }
    }

    if (ticketPlatformName === "Discord") {
      ticketContentCheck = ticketSourceData.ticket_desc;

      if (props.discordFeeds?.hits?.length) {
        allFeeds = [...allFeeds, ...props.discordFeeds.hits];
      }
    }

    if (ticketPlatformName === "Threads") {
      ticketContentCheck = ticketSourceData.ticket_desc;

      if (props.threadFeeds?.hits?.length) {
        allFeeds = [...allFeeds, ...props.threadFeeds.hits];
      }
    }

    if (ticketPlatformName === "Whatsapp") {
      ticketContentCheck = ticketSourceData.ticket_desc;

      if (props.whatsAppFeed?.hits?.length) {
        allFeeds = [...allFeeds, ...props.whatsAppFeed.hits];
      }
    }

    const sortedFeeds =
      allFeeds?.length > 0
        ? SortFeedsBasedOnTime({
            platform: ticketPlatformName,
            allFeeds: allFeeds,
          })
        : [];

    // Compute last direct message received index once (O(n) complexity)
    const lastDMreceivedIndex = sortedFeeds.reduce(
      (lastIdx, item, index) =>
        item._source.activities === "Direct Message" &&
        item._source.flag === "received"
          ? index
          : lastIdx,
      -1
    );

    return (
      ticketPlatformName &&
      allFeeds?.length > 0 && (
        <Formik
          initialValues={{
            feed: sortedFeeds?.map((item, index, array) => {
              const feedContentKey = returnFeedKeyToMatchTicketDesc({
                platform: ticketPlatformName,
                feedData: item,
              });

              const isDirectMessage =
                ticketActivity?.activities_name === "Direct Message";
              const isMatchingContent =
                item._source?.[feedContentKey] === ticketContentCheck;
              const isLastDM = index === lastDMreceivedIndex;

              if (props.replyClicked) {
                // Find the last matching content index

                if (isDirectMessage) {
                  setReplyOpenStates((prev) => ({
                    ...Object.keys(prev).reduce((acc, key) => {
                      acc[key] = false; // Close all previous ones
                      return acc;
                    }, {}),
                    [array.findLastIndex(
                      (item) =>
                        item._source.activities === "Direct Message" &&
                        item._source.flag === "received"
                    )]: true, // Open only the last clicked index
                  }));
                } else if (isMatchingContent) {
                  setReplyOpenStates((prev) => ({
                    ...Object.keys(prev).reduce((acc, key) => {
                      acc[key] = false; // Close all previous ones
                      return acc;
                    }, {}),
                    [index]: true, // Open only the last clicked index
                  }));
                }
              }

              if (props.emailClicked) {
                // Find the last matching content index

                if (isDirectMessage) {
                  setEmailOpenStates((prev) => ({
                    ...Object.keys(prev).reduce((acc, key) => {
                      acc[key] = false; // Close all previous ones
                      return acc;
                    }, {}),
                    [array.findLastIndex(
                      (item) =>
                        item._source.activities === "Direct Message" &&
                        item._source.flag === "received"
                    )]: true, // Open only the last clicked index
                  }));
                } else if (isMatchingContent) {
                  setEmailOpenStates((prev) => ({
                    ...Object.keys(prev).reduce((acc, key) => {
                      acc[key] = false; // Close all previous ones
                      return acc;
                    }, {}),
                    [index]: true, // Open only the last clicked index
                  }));
                }
              }

              return {
                replyOpen: replyOpenStates[index] || false,
                emailOpen: emailOpenStates[index] || false,
                translateClicked: false,
              };
            }),
          }}
          enableReinitialize={false}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                {sortedFeeds?.map((item, index, Array) =>
                  ReturnFeeds(
                    index,
                    setFieldValue,
                    values,
                    item,
                    setScrollToIndex,
                    Array
                  )
                )}
              </Form>
            );
          }}
        </Formik>
      )
    );
  }

  return (
    <Box
      width={"100%"}
      sx={{
        paddingY: 0,
        paddingLeft: 1,
        height: "100%",
        position: "relative",
      }}
    >
      <LoadingIndicator isActive={isRequesting}>
        {isRequesting && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              justifyContent: "center",
              display: isRequesting ? "block" : "none",
              alignItems: "center",
              zIndex: 1,
            }}
          >
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
              }}
            >
              <LoadingBgImage />
            </Grid>
          </div>
        )}
        <Box
          sx={{ height: "100%", overflowY: "auto" }}
          ref={boxRef}
          className="scrollable"
        >
          {!isRequesting && ticketPlatform && (
            <>
              <RenderFormikWrappedFeed
                ticketSourceData={props.ticketData._source}
                ticketPlatformName={ticketPlatform?.platform_name}
              />

              {ticketPlatform?.platform_id === "14" && (
                <Box
                  sx={{
                    paddingX: 2,
                    paddingTop: 2,
                    paddingBottom: 3,
                  }}
                >
                  <Details
                    ticketData={props.ticketData && props.ticketData}
                    filters={props.filters}
                    fetchFunction={props.fetchFunction}
                  />
                </Box>
              )}
            </>
          )}

          {!isRequesting && props?.ticketData?._source?.Notes?.length !== 0 && (
            <Grid>
              <Box
                sx={{
                  paddingX: 2,
                  paddingY: 1,
                }}
              >
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  justifyContent={"flex-end"}
                >
                  <Grid
                    item
                    xs={9}
                    alignItems="flex-end"
                    justifyContent={"center"}
                    textAlign="left"
                    sx={{
                      borderRadius: "10px",
                      border: "solid 0.5px transparent",
                      backgroundColor: "white",
                      color: `${theme.palette.text.primary}`,
                    }}
                  >
                    <Box
                      sx={{
                        p: 1,
                        borderRadius: "5px",
                      }}
                    >
                      <span>Notes :</span>
                      {!isRequesting &&
                        props.ticketData._source?.Notes.map(
                          (item, index, array) => (
                            <NotesUI
                              item={item}
                              index={index}
                              Notes={array}
                              ticketData={props.ticketData}
                            />
                          )
                        )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}

          {!isRequesting &&
            props?.finalStatuses?.length > 0 &&
            props.finalStatuses.includes(
              props?.ticketData?._source?.status?.status_id
            ) && (
              <Grid item container xl={12} mt={0.5}>
                <Box
                  width={"100%"}
                  sx={{
                    paddingX: 2,
                    paddingY: 0,
                  }}
                  // className="bg-warning"
                >
                  <Grid
                    item
                    container
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    alignItems="center"
                    justifyContent={"flex-end"}
                    // className="bg-success"
                  >
                    <Grid
                      item
                      textAlign={"right"}
                      sx={{
                        backgroundColor: "white",
                        border: "solid 0.5px #92b9c4",
                        fontFamily: "BookAntiqua-Italic",
                        borderRadius: 1,
                      }}
                      px={2}
                    >
                      <span
                        style={{
                          color: `${theme.palette.primary.main}`,
                        }}
                      >
                        <span>
                          {props.ticket_closed &&
                            props.ticket_closed[0] &&
                            props.ticket_closed[0]._source.description.split(
                              "By"
                            )[1]}
                        </span>
                        &nbsp;
                        <span>{`has closed the ticket ID :`}</span>
                        &nbsp;
                        <span>
                          {props.ticketData &&
                            props.ticketData._id &&
                            props.ticketData._id}
                        </span>
                        &nbsp;
                        <span>at</span>
                        &nbsp;
                        <span
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          {(function createdDate() {
                            let created =
                              props.ticket_closed &&
                              props.ticket_closed[0] &&
                              props.ticket_closed[0]._source.created_on;
                            let dateConversion = new Date(created);
                            dateConversion =
                              dateConversion.toDateString() +
                              ", " +
                              dateConversion.toLocaleTimeString("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              });

                            return dateConversion;
                          })()}
                        </span>
                      </span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )}
        </Box>
      </LoadingIndicator>
    </Box>
  );
};

export default connect(mapStateToProps)(TicketFeed);
