import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { endDate, monthNames, startDate } from "./dateFunctions/DateFunctions";
import { format } from "date-fns";
import Logo from "./logoContainer/Logo";
import Description from "./descriptionContainer/Description";
import { Box, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { ro } from "date-fns/locale";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";

function AvgFRTPlatformWise(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [loading, setLoading] = useState(false);

  const [current_month_data, set_current_month_data] = useState(null);

  const [today, setToday] = useState(new Date());
  const [apiError, setApiError] = useState(null);
  // this month
  let current_month = new Date();
  let current_month_end_date = endDate(current_month);

  // last month
  let previous_month = new Date();
  previous_month.setMonth(previous_month.getMonth() - 1);

  //  before_previous_month
  let before_previous_month = new Date();
  before_previous_month.setMonth(before_previous_month.getMonth() - 2);

  let before_previous_month_start_date = startDate(before_previous_month);

  // last three month names

  function platformName(val) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === val)
        .map((item) => {
          return item.platform_alias;
        })
    );
  }
  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      // current month data fetching
      await fetchData(before_previous_month_start_date, current_month_end_date);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        // current month data fetching
        await fetchData(
          before_previous_month_start_date,
          current_month_end_date
        );
        setLoading(false);
        setToday(new Date());
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      // Assuming props.openDateFilter is provided and valid
      const date = new Date(props.openDateFilter);
      setToday(new Date(props.openDateFilter));

      const firstDateOfLastMonth = new Date(
        date.getFullYear(),
        date.getMonth() - 2,
        1
      );
      const firstDateString = format(firstDateOfLastMonth, "yyyy-MM-dd");

      const endOfCurrentMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      );
      const endDateString = format(endOfCurrentMonth, "yyyy-MM-dd");

      (async function CallApi() {
        setLoading(true);
        // current month data fetching
        await fetchData(new Date(firstDateString), new Date(endDateString));
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchData(from, to) {
    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["frt_platform"]&from=${format(
      from,
      "yyyy-MM-dd"
    )}&to=${format(to, "yyyy-MM-dd")}`;

    await axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (response.hasOwnProperty("message")) {
          set_current_month_data(null);
          setApiError(response.message);
        } else {
          set_current_month_data(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        set_current_month_data(null);
        setApiError(err.message);
        setLoading(false);
      });
  }

  const HoursAndMinutes = (decimal) => {
    let number = decimal ? decimal : 0;
    const totalSeconds = number * 3600; // Convert the decimal to total seconds
    const hours = Math.floor(totalSeconds / 3600); // Extract the whole number part (hours)
    const minutes = Math.floor((totalSeconds % 3600) / 60); // Convert the remaining seconds to minutes
    const seconds = Math.floor(totalSeconds % 60); // Get the remaining seconds

    return decimal
      ? `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      : "00:00:00";
  };

  function convertDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to the month since it is zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastSecondFirstDay = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  ); // Set the date to the first day of the previous month

  const lastThirdFirstDay = new Date(
    today.getFullYear(),
    today.getMonth() - 2,
    1
  ); // Set the date to the first day of the previous month

  let currentMonth = convertDate(thisMonth);
  let lastSecond = convertDate(lastSecondFirstDay);
  let lastThird = convertDate(lastThirdFirstDay);

  // const formattedDate =

  let FirstMonth = {};
  let secondMonth = {};
  let thirdMonth = {};
  let TotalMonth = {};

  current_month_data &&
    current_month_data.ticket_report &&
    current_month_data.ticket_report.frt_platform &&
    current_month_data.ticket_report.frt_platform.length !== 0 &&
    current_month_data.ticket_report.frt_platform.map((item) => {
      if (Object.values(item).includes(currentMonth)) {
        FirstMonth = item;
      }
      if (Object.values(item).includes(lastSecond)) {
        secondMonth = item;
      }
      if (Object.values(item).includes(lastThird)) {
        thirdMonth = item;
      }
      if (Object.values(item).includes("total_count")) {
        TotalMonth = item;
      }
    });

  let NoDataFoundBool =
    (current_month_data && current_month_data?.status === "Failed") ||
    (current_month_data &&
      current_month_data.ticket_report &&
      current_month_data.ticket_report.frt_platform &&
      current_month_data.ticket_report.frt_platform.length === 4);

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  function returnMonthName(monthString) {
    let dateObj = new Date(monthString);
    let options = { year: "numeric", month: "short" };
    let formattedDate = dateObj.toLocaleDateString("en-US", options);
    return formattedDate;
  }

  let columns = [
    {
      field: "platforms",
      headerName: "Platforms",
      resizable: true,
      flex: 2,
      minWidth: 150,
      renderCell: (params) => (
        <p
          className={`${
            params.row.platforms == "Total"
              ? "font-weight-bold p-0 m-0"
              : "font-weight-normal p-0 m-0"
          }`}
        >
          {params.row.platforms}
        </p>
      ),
    },

    {
      field: new Date(lastThird).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      }),

      headerName: (
        <div
          style={{
            lineHeight: "23px",
            width: "100%",
            fontSize: "14px",
            minWidth: "150px",
          }}
          // className="bg-warning"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {new Date(lastThird).toLocaleDateString("en-US", {
              month: "short",
              year: "numeric",
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span>{"FRT value"}</span>

            <span>{"Count"}</span>
          </div>
        </div>
      ),
      resizable: true,
      flex: 2,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) =>
        params.field === `${returnMonthName(lastThird)}` ? (
          params.row.platforms !== "Total" ? (
            <div
              style={{
                width: "150px",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <span align="left">
                {params.row[`${returnMonthName(lastThird)}frt`] !== 0
                  ? HoursAndMinutes(
                      params.row[`${returnMonthName(lastThird)}frt`]
                    )
                  : "00:00:00"}
              </span>

              <span align="right">
                {prefixZero_for_singleDigit(
                  params.row[`${returnMonthName(lastThird)}Count`]
                )}
              </span>
            </div>
          ) : (
            <p
              style={{
                width: "150px",
                justifyContent: "space-between",
                display: "flex",
              }}
              className={`font-weight-bold p-0 m-0`}
            >
              <span align="right">
                {params.row[`${returnMonthName(lastThird)}frt`] !== 0
                  ? HoursAndMinutes(
                      params.row[`${returnMonthName(lastThird)}frt`]
                    )
                  : "00:00:00"}
              </span>

              <span align="right">
                {prefixZero_for_singleDigit(
                  params.row[`${returnMonthName(lastThird)}Count`]
                )}
              </span>
            </p>
          )
        ) : null,
    },

    {
      field: new Date(lastSecond).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      }),

      headerName: (
        <div
          style={{
            lineHeight: "23px",
            width: "100%",
            fontSize: "14px",
            minWidth: "150px",
          }}
          // className="bg-warning"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {new Date(lastSecond).toLocaleDateString("en-US", {
              month: "short",
              year: "numeric",
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span>{"FRT value"}</span>

            <span>{"Count"}</span>
          </div>
        </div>
      ),
      resizable: true,
      flex: 2,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        if (params.field === `${returnMonthName(lastSecond)}`) {
          return params.row.platforms !== "Total" ? (
            <div
              style={{
                width: "150px",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <span align="left">
                {params.row[`${returnMonthName(lastSecond)}frt`] !== 0
                  ? HoursAndMinutes(
                      params.row[`${returnMonthName(lastSecond)}frt`]
                    )
                  : "00:00:00"}
              </span>

              <span align="right">
                {prefixZero_for_singleDigit(
                  params.row[`${returnMonthName(lastSecond)}Count`]
                )}
              </span>
            </div>
          ) : (
            <p
              style={{
                width: "150px",
                justifyContent: "space-between",
                display: "flex",
              }}
              className={`font-weight-bold p-0 m-0`}
            >
              <span align="right">
                {params.row[`${returnMonthName(lastSecond)}frt`] !== 0
                  ? HoursAndMinutes(
                      params.row[`${returnMonthName(lastSecond)}frt`]
                    )
                  : "00:00:00"}
              </span>

              <span align="right">
                {prefixZero_for_singleDigit(
                  params.row[`${returnMonthName(lastSecond)}Count`]
                )}
              </span>
            </p>
          );
        }
        return null; // Return null if the condition is not met
      },
    },

    {
      field: new Date(currentMonth).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      }),
      headerName: (
        <div
          style={{
            lineHeight: "23px",
            width: "100%",
            fontSize: "14px",
            minWidth: "150px",
          }}
          // className="bg-warning"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {new Date(currentMonth).toLocaleDateString("en-US", {
              month: "short",
              year: "numeric",
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span>{"FRT value"}</span>

            <span>{"Count"}</span>
          </div>
        </div>
      ),
      resizable: true,
      flex: 2,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) =>
        params.field === `${returnMonthName(currentMonth)}` ? (
          params.row.platforms !== "Total" ? (
            <div
              style={{
                width: "150px",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <span align="left">
                {params.row[`${returnMonthName(currentMonth)}frt`] !== 0
                  ? HoursAndMinutes(
                      params.row[`${returnMonthName(currentMonth)}frt`]
                    )
                  : "00:00:00"}
              </span>

              <span align="right">
                {prefixZero_for_singleDigit(
                  params.row[`${returnMonthName(currentMonth)}Count`]
                )}
              </span>
            </div>
          ) : (
            <p
              style={{
                width: "150px",
                justifyContent: "space-between",
                display: "flex",
              }}
              className={`font-weight-bold p-0 m-0`}
            >
              <span align="right">
                {params.row[`${returnMonthName(currentMonth)}frt`] !== 0
                  ? HoursAndMinutes(
                      params.row[`${returnMonthName(currentMonth)}frt`]
                    )
                  : "00:00:00"}
              </span>

              <span align="right">
                {prefixZero_for_singleDigit(
                  params.row[`${returnMonthName(currentMonth)}Count`]
                )}
              </span>
            </p>
          )
        ) : null,
    },
  ];

  columns.push({
    field: "avg_frt",
    headerName: "Avg FRT",
    resizable: true,
    flex: 2,
    minWidth: 150,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <p
        className={`${
          params.row.platforms == "Total"
            ? "font-weight-bold p-0 m-0"
            : "font-weight-normal p-0 m-0"
        }`}
      >
        {params.row.avg_frt}
      </p>
    ),
  });
  let rows = [];

  if (
    current_month_data &&
    current_month_data.ticket_report &&
    current_month_data.ticket_report.frt_platform &&
    current_month_data.ticket_report.frt_platform.length !== 0
  ) {
    current_month_data &&
      current_month_data.ticket_report &&
      current_month_data.ticket_report.frt_platform &&
      current_month_data.ticket_report.frt_platform.length !== 0 &&
      current_month_data.ticket_report.frt_platform.map((item, index) => {
        const platformKey = Object.keys(item)[0];
        let platformData = item[platformKey];

        let lastThirdTotalData =
          current_month_data.ticket_report.frt_platform[
            current_month_data.ticket_report.frt_platform.length - 4
          ];

        let lasSecondTotalData =
          current_month_data.ticket_report.frt_platform[
            current_month_data.ticket_report.frt_platform.length - 3
          ];

        let currentMonthTotalData =
          current_month_data.ticket_report.frt_platform[
            current_month_data.ticket_report.frt_platform.length - 2
          ];

        if (platformKey !== "count") {
          let lastThirdData;
          if (Array.isArray(platformData)) {
            lastThirdData = platformData?.filter((platform) => {
              return platform.key === lastThird;
            });
          }

          let lastSecondData;
          if (Array.isArray(platformData)) {
            lastSecondData = platformData?.filter((platform) => {
              return platform.key === lastSecond;
            });
          }

          let currentMonthData;
          if (Array.isArray(platformData)) {
            currentMonthData = platformData?.filter((platform) => {
              return platform.key === currentMonth;
            });
          }

          const lastThirdKey = `${new Date(lastThird).toLocaleDateString(
            "en-US",
            {
              month: "short",
              year: "numeric",
            }
          )}Count`; // Construct the dynamic key
          const lastThirdCount = lastThirdData && lastThirdData[0]?.count;

          const lastThirFrtKey = `${new Date(lastThird).toLocaleDateString(
            "en-US",
            {
              month: "short",
              year: "numeric",
            }
          )}frt`; // Construct the dynamic key
          const lastThirdFRT = lastThirdData && lastThirdData[0]?.frt_value;

          const lastSeconFrtKey = `${new Date(lastSecond).toLocaleDateString(
            "en-US",
            {
              month: "short",
              year: "numeric",
            }
          )}frt`; // Construct the dynamic key
          const lastSecondCountFRT =
            lastSecondData && lastSecondData[0]?.frt_value;

          const current_monthFrtKey = `${new Date(
            currentMonth
          ).toLocaleDateString("en-US", {
            month: "short",
            year: "numeric",
          })}frt`; // Construct the dynamic key
          const current_monthCountFRT =
            currentMonthData && currentMonthData[0]?.frt_value;

          const lastSecondKey = `${new Date(lastSecond).toLocaleDateString(
            "en-US",
            {
              month: "short",
              year: "numeric",
            }
          )}Count`; // Construct the dynamic key
          const lastSecondCount = lastSecondData && lastSecondData[0]?.count;

          const current_monthKey = `${new Date(currentMonth).toLocaleDateString(
            "en-US",
            {
              month: "short",
              year: "numeric",
            }
          )}Count`; // Construct the dynamic key
          const current_monthCount =
            currentMonthData && currentMonthData[0]?.count;

          const row = {
            id: index + 1,
            platforms: platformName(platformKey),
            avg_frt:
              platformData.filter((plat) => {
                return plat.key === "total_count";
              })[0].frt_value !== 0
                ? HoursAndMinutes(
                    platformData.filter((plat) => {
                      return plat.key === "total_count";
                    })[0].frt_value
                  )
                : "00:00:00",
          };

          row[lastThirdKey] = lastThirdCount;
          row[lastSecondKey] = lastSecondCount;
          row[current_monthKey] = current_monthCount;
          row[lastThirFrtKey] = lastThirdFRT;
          row[lastSeconFrtKey] = lastSecondCountFRT;
          row[current_monthFrtKey] = current_monthCountFRT;

          rows.push(row);
        }
        if (Object.values(item)[2] === "total_count") {
          const lastThirdKey = `${new Date(lastThird).toLocaleDateString(
            "en-US",
            {
              month: "short",
              year: "numeric",
            }
          )}Count`; // Construct the dynamic key

          const lastSecondKey = `${returnMonthName(lastSecond)}Count`; // Construct the dynamic key

          const current_monthKey = `${returnMonthName(currentMonth)}Count`; // Construct the dynamic key

          const lastThirFrtKey = `${new Date(lastThird).toLocaleDateString(
            "en-US",
            {
              month: "short",
              year: "numeric",
            }
          )}frt`; // Construct the dynamic key

          const lastSeconFrtKey = `${returnMonthName(lastSecond)}frt`; // Construct the dynamic key

          const current_monthFrtKey = `${returnMonthName(currentMonth)}frt`;

          const lastThirdCount =
            lastThirdTotalData && lastThirdTotalData?.count;

          const lastSecondCount =
            lasSecondTotalData && lasSecondTotalData?.count;

          const current_monthCount =
            currentMonthTotalData && currentMonthTotalData?.count;

          const lastThirdFRT =
            lastThirdTotalData && lastThirdTotalData?.frt_value;

          const lastSecondCountFRT =
            lasSecondTotalData && lasSecondTotalData?.frt_value;

          const current_monthCountFRT =
            currentMonthTotalData && currentMonthTotalData?.frt_value;

          const row = {
            id: rows.length + 1,
            platforms: "Total",
            avg_frt:
              current_month_data &&
              current_month_data.ticket_report &&
              current_month_data.ticket_report.frt_platform[
                current_month_data.ticket_report.frt_platform.length - 1
              ].frt_value !== 0
                ? HoursAndMinutes(
                    current_month_data.ticket_report.frt_platform[
                      current_month_data.ticket_report.frt_platform.length - 1
                    ].frt_value
                  )
                : "00:00:00",
          };

          row[lastThirdKey] = lastThirdCount;
          row[lastSecondKey] = lastSecondCount;
          row[current_monthKey] = current_monthCount;
          row[lastThirFrtKey] = lastThirdFRT;
          row[lastSeconFrtKey] = lastSecondCountFRT;
          row[current_monthFrtKey] = current_monthCountFRT;

          rows.push(row);
        }
      });
  }

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];
      rows?.map((item) => {
        exportData.push({
          platforms: Array.isArray(item.platforms)
            ? item.platforms[0]
            : item.platforms,

          [`${new Date(lastThird).toLocaleDateString("en-US", {
            month: "short",
            year: "numeric",
          })} (FRT value| Count)`]: `${
            item[`${returnMonthName(lastThird)}frt`]
              ? HoursAndMinutes(`${item[`${returnMonthName(lastThird)}frt`]}`)
              : "00:00:00"
          } | ${
            item[`${returnMonthName(lastThird)}Count`]
              ? item[`${returnMonthName(lastThird)}Count`]
              : 0
          } `,

          [`${new Date(lastSecond).toLocaleDateString("en-US", {
            month: "short",
            year: "numeric",
          })} (FRT value| Count)`]: `${
            item[`${returnMonthName(lastSecond)}frt`]
              ? HoursAndMinutes(`${item[`${returnMonthName(lastSecond)}frt`]}`)
              : "00:00:00"
          } | ${
            item[`${returnMonthName(lastSecond)}Count`]
              ? item[`${returnMonthName(lastSecond)}Count`]
              : 0
          } `,

          [`${new Date(currentMonth).toLocaleDateString("en-US", {
            month: "short",
            year: "numeric",
          })} (FRT value| Count)`]: `${
            item[`${returnMonthName(currentMonth)}frt`]
              ? HoursAndMinutes(
                  `${item[`${returnMonthName(currentMonth)}frt`]}`
                )
              : "00:00:00"
          } | ${
            item[`${returnMonthName(currentMonth)}Count`]
              ? item[`${returnMonthName(currentMonth)}Count`]
              : 0
          } `,

          ["Avg FRT"]: item.avg_frt,
        });
      });
      props.setexcelData(exportData);
    }
  }, [rows, props.DownloadClicked]);

  return (
    <LoadingIndicator isActive={loading}>
      {!loading && !NoDataFoundBool && props.dataGridHeight ? (
        <div
          style={{
            width: "100%",
            overflowX: "auto",
            borderRadius: theme.table.borderRadius,
          }}
        >
          {apiError ? (
            <Box
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flex: "12",
              }}
            >
              {apiError}
            </Box>
          ) : (
            props.dataGridHeight.current &&
            rows && (
              <DataGridFunction
                rows={rows}
                columns={columns}
                rowBorder={false}
                height={props.dataGridHeight.current}
                rowHeight={50}
                headerHeight={80}
                onCellClick={onCellClick}
                rowColor={true}
                headerColor={true}
              />
            )
          )}
        </div>
      ) : !NoDataFoundBool ? (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage />
        </Box>
      ) : (
        <NoDataFound />
      )}
    </LoadingIndicator>
  );
}

export default AvgFRTPlatformWise;
