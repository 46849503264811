import BaseReducer from "../../../utilities/BaseReducer";
import GoogleAlertConfigAction from "./GoogleAlertConfigAction";

// GoogleAlertsReduser
export default class GoogleAlertsReduser extends BaseReducer {
  initialState = {
    google_alerts: [],
  };
  [GoogleAlertConfigAction.REQUEST_GOOGLE_ALERTS_FINISHED](state, action) {
    return {
      ...state,
      google_alerts: action.payload,
    };
  }
}
