import React from "react";
import "./NoDataFound.css";

// No data found
export default function NoDataFound({ customText }) {
  return (
    <div className="nodataFoundContainer">
      <p className="nodataFoundText">
        {customText ? customText : "No data found"}
      </p>
    </div>
  );
}
