import React, { useEffect, useState } from "react";
import environment from "environment";
import Logo from "./logoContainer/Logo";
import Description from "./descriptionContainer/Description";
import { Box } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { firstLetterOrange } from "../../../components/ticketPartials";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
// import CategoryWiseDistributionChart from "./charts/CategoryWiseDistributionChart";

const CategoryWiseTatDistribution = (props) => {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchData();
      setLoading(false);
    })();
  }, []);

  async function fetchData(month) {
    let API = `${environment.api.ticketReports}?report_type=["count"]&reports=["tat_custom_value"]`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (response.hasOwnProperty("message")) {
          setData(null);
          setApiError(response.message);
        } else {
          setData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setData(null);
        setApiError(err.message);
        setLoading(false);
      });

    // return fetch(
    //   `${environment.api.ticketReports}?report_type=["count"]&reports=["tat_custom_value"]`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((response) => {
    //     setData(response);
    //   });
  }

  let categoryList = [];

  data &&
    data.ticket_report.tat_custom_value
      .filter((item) => Object.keys(item)[0] === props.filterValue)
      .map((value) => {
        Object.entries(value)[0][1].map((item) => {
          categoryList.push({ name: item.key, count: item.count });
        });
      });

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {apiError ? (
            <Box
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flex: "12",
              }}
            >
              {apiError}
            </Box>
          ) : (
            <div style={{ width: "100%", overflowX: "auto" }}>
              {categoryList.length > 0 ? (
                <table
                  style={{
                    width: "100%",
                    border: `1px solid ${theme.table.border}`,
                    textAlign: "left",
                  }}
                  border="2"
                >
                  <tbody style={{ width: "100%" }}>
                    <tr
                      style={{
                        border: `1px solid ${theme.table.border}`,
                        color: `${theme.palette.text.primary}`,
                        backgroundColor: `${theme.palette.background.primary}`,
                      }}
                    >
                      <th className="pl-5">Category Name</th>
                      <th className="pl-5">Count</th>
                    </tr>

                    {categoryList &&
                      categoryList.map((value, index) => (
                        <tr
                          style={{
                            backgroundColor:
                              index % 2 === 0
                                ? `${theme.table.rowColor}`
                                : "white",
                            color: `${theme.table.rowText}`,
                            border: `1px solid ${theme.table.border}`,
                          }}
                        >
                          <td className="pl-5">
                            {value.name ? value.name : "-"}
                          </td>
                          <td className="pl-5">{value.count}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                <NoDataFound />
              )}
            </div>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage width={"50%"} />
        </Box>
      )}
    </LoadingIndicator>
  );
};

export default CategoryWiseTatDistribution;
