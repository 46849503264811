import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  ListItem,
} from "@mui/material";

import { Form, Formik, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import { format } from "date-fns";
import TicketsAction from "../../ormTicketing/stores/tickets/TicketsAction";
import AutoCompleteMultiSelect from "../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import MuiButton from "../../components/muiButton/MuiButton";
import StyleIcon from "@mui/icons-material/Style";
import { theme } from "../App";
import CategoryUpdateDialog from "../../ormTicketing/views/ticketsListView/ticketDetails/components/CategoryUpdateDialog";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

const BulkAssign = (props) => {
  let initial = {};

  const [categoryUpdateOpen, setCategoryUpdateOpen] = useState(false);
  const [category, setCategory] = useState([]);
  let [categoryError, setcategoryError] = useState(false);

  // category edit button open close model
  const openCloseModal = useCallback((open) => {
    setCategoryUpdateOpen(open);
  }, []);

  initial = {
    user: "",
    status: "",
    message: "",
    priority: "",
    sentiment_value: "",
    custom_value: [],
  };

  const validationSchema = yup.object({
    user: yup.object().shape({}).required("User is Required").nullable(),
    status: yup.object().shape({}).required("Status is Required").nullable(),
  });

  let [filtered_final_data, setFiltered_final_data] = useState([]);

  useEffect(() => {
    setFiltered_final_data(props.selectedRows);
  }, [props.selectedRows]);

  if (props.open) {
    filtered_final_data =
      filtered_final_data.length > 0 &&
      filtered_final_data.map((item) => {
        return {
          _id: item._id ? item._id : "",
          _index: item._index,
          _score: item._score,
          _source: {
            assigned_to: item._source.assigned_to,
            status: item._source.status,
          },
        };
      });
  }

  const [loading, setLoading] = useState(false);

  let priority_list = [];

  props.filterprioritylist &&
    props.filterprioritylist.length !== 0 &&
    props.filterprioritylist.map((item) => {
      priority_list.push({
        priority_id: item.priority_id,
        priority_name: item.ticket_priority_name,
        color: item.color,
      });
    });

  const [CategoryUpdateData, setCategoryUpdateData] = useState(null);

  let sentiment_value_option = ["Positive", "Negative", "Neutral"];

  let returnSentimentValueString = (sentiment_string) => {
    let sentiment_score = "";
    if (sentiment_string === "Positive") {
      sentiment_score = 0.1;
    } else if (sentiment_string === "Negative") {
      sentiment_score = -0.1;
    } else if (sentiment_string === "Neutral") {
      sentiment_score = 0.0;
    }

    return sentiment_score;
  };

  const returntrueCategoryValues = (ticket) => {
    let bool_val = false;

    if (
      ticket &&
      ticket._source &&
      ticket._source.custom_value &&
      ticket._source.custom_value.length !== 0
    ) {
      ticket._source.custom_value
        .filter((val) => {
          return val.field_type === "category";
        })
        .map((item) => {
          if (item && item.field_values) {
            item?.field_values?.length && item?.field_values[0]?.id !== ""
              ? (bool_val = true)
              : (bool_val = false);
          }
        });
    }

    return bool_val;
  };

  const returnCustomValues = (custom_fields) => {
    let category_values =
      props.customticketfields &&
      props.customticketfields.hits &&
      props.customticketfields.hits.hits?.filter(
        (item) => item._source.field_type === "category"
      )?.[0]._source.field_values;

    let filtered_category_vals = [];
    if (custom_fields && custom_fields.length !== 0) {
      filtered_category_vals = custom_fields.filter((item) => {
        return item.field_type === "category";
      });
    }

    let sub_value_id = [];

    if (filtered_category_vals && filtered_category_vals.length !== 0) {
      filtered_category_vals.map((item) => {
        sub_value_id.push(item.field_values[0]?.id);
      });
    }
    function ReturnCategoryName(nodes, id, names = []) {
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];

        names.push(nodes[i].value);

        if (node.id === id) {
          return names;
        } else if (node.sub_value && node.sub_value.length > 0) {
          const foundIndexes = ReturnCategoryName(node.sub_value, id, [
            ...names,
          ]);
          if (foundIndexes) {
            return foundIndexes;
          }
        }
        names.pop();
      }
      return null;
    }
    let custom_field_vals =
      category_values &&
      category_values.length !== 0 &&
      ReturnCategoryName(category_values, sub_value_id[0]);

    return custom_field_vals;
  };

  let returnCustomValuesAsString = (data) => {
    let values = [];

    values = returnCustomValues(data);

    // Initialize an empty string to store the result
    let result = "";

    // Loop through the values and append them to the result string with a slash
    for (var i = 0; i < values.length; i++) {
      result += values[i];
      // Add a slash after every value, except for the last one
      if (i !== values.length - 1) {
        result += "/";
      }
    }
    return result;
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        // validationSchema={validationSchema}
        validate={(values) => {
          var errors = {};

          if (
            !(
              values.status ||
              values.user ||
              values.priority ||
              values?.sentiment_value ||
              values?.custom_value?.length
            )
          ) {
            errors.message = "Any of the one field is Required";
          }

          return errors;
        }}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);
          let object = {
            data: filtered_final_data.map((item) => {
              const sourceObj = {};
              Object.assign(
                sourceObj,
                values.user.user_id && {
                  assigned_to: {
                    user_id: values.user.user_id,
                    user_name: values.user.user_name,
                  },
                },

                { status: values.status.status_id },

                values.priority &&
                  values.priority.priority_id && {
                    priority: {
                      priority_id: values.priority.priority_id,
                      priority_name: values.priority.priority_name,
                    },
                  },

                values.sentiment_value && {
                  sentiment_value: returnSentimentValueString(
                    values?.sentiment_value
                  ),
                },
                values.custom_value?.length && {
                  custom_value: values?.custom_value,
                }
              );
              return {
                _id: item._id,
                _index: item._index,
                _score: item._score,
                _source: sourceObj,
              };
            }),
          };
          props
            .dispatch(
              TicketsAction.requestBulkPutTicket(JSON.stringify(object))
            )
            .then(() => {
              props.fetchFunction(values.status);
              props.onClose();
              setFiltered_final_data([]);
              props.setSelectedRows([]);
              setLoading(false);
              localStorage.getItem("selected_tickets_for_bulk_update") &&
                localStorage.removeItem("selected_tickets_for_bulk_update");
            });
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
          setFieldValue,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "100%" }}>
                <Box width="100%" mb={3}>
                  <Grid
                    container
                    xl={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xl={11} textAlign="center">
                      <h3 className="dailogtext">
                        <b>
                          <span className="span_first_letter">
                            &nbsp;&nbsp;B
                          </span>
                          ulk &nbsp;
                          <span className="span_first_letter">U</span>
                          pdate &nbsp;
                        </b>
                      </h3>
                    </Grid>
                    <Grid item xl={1} textAlign="right">
                      <div className="col-2 closebtn">
                        <i
                          title="Close"
                          className="fa fa-times-circle-o fa-2x"
                          style={{ size: "15px" }}
                          aria-hidden="true"
                          onClick={() => {
                            props.onClose();
                          }}
                        ></i>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContentText>

              {/* <Box sx={{ width: "300px", margin: "auto", marginLeft: "140px" }}> */}
              <Grid container justifyContent="center" alignItems="center">
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xl={7} lg={7}>
                    <Field
                      name="user"
                      size="small"
                      // Calling AutoCompleteMultiSelect Component
                      disabled={props.edit ? true : false}
                      value={values.user ? values.user : null}
                      component={AutoCompleteMultiSelect}
                      options={props.userlist}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.user_name === value.user_name
                      }
                      getOptionLabel={(option) =>
                        option ? option.fullname : ""
                      }
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Users",
                      }}
                    />
                  </Grid>

                  <Grid item xl={7} lg={7}>
                    <Field
                      name="status"
                      size="small"
                      // Calling AutoCompleteMultiSelect Component
                      disabled={props.edit ? true : false}
                      value={values.status ? values.status : null}
                      component={AutoCompleteMultiSelect}
                      options={props.statusList}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.status_name === value.status_name
                      }
                      getOptionLabel={(option) => option.status_name}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Status",
                      }}
                    />
                  </Grid>

                  <Grid item xl={7} lg={7}>
                    <Field
                      name="priority"
                      component={AutoCompleteMultiSelect}
                      disableClearable={true}
                      size="small"
                      label="priority"
                      options={priority_list.length !== 0 ? priority_list : []}
                      value={values.priority ? values.priority : null}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.priority_name === value.priority_name
                      }
                      //setting custom label to custom value
                      getOptionLabel={(option) => option.priority_name}
                      renderOption={(props, option) => {
                        return (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <Box
                              mr={1}
                              width={10}
                              height={10}
                              borderRadius="50%"
                            >
                              {returnColor(option.color)}
                            </Box>
                            {option.priority_name}
                          </Box>
                        );
                      }}
                      textFieldProps={{
                        fullWidth: true,
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Select Priority",
                      }}
                    />
                  </Grid>

                  <Grid item xl={7} lg={7}>
                    <Field
                      name="sentiment_value"
                      size="small"
                      // Calling AutoCompleteMultiSelect Component
                      value={
                        values.sentiment_value ? values.sentiment_value : null
                      }
                      component={AutoCompleteMultiSelect}
                      options={sentiment_value_option}
                      // disabled={true}
                      // onChange={(_, value) => {
                      //   setFieldValue(`client`, value);
                      //   setFieldValue(`platform`, []);
                      //   setOrg_id(value.organization_id);
                      // }}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option === value
                      }
                      getOptionLabel={(option) => option}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Sentiment Value",
                      }}
                    />
                  </Grid>

                  <Grid item xl={7} lg={7}>
                    <Grid item xl={10} lg={10} md={10} sm={10}>
                      {values?.custom_value?.length >= 0 && (
                        <div>
                          {returntrueCategoryValues({
                            _source: values,
                          }) ? (
                            <Grid
                              item
                              py={0.5}
                              px={1}
                              alignItems="center"
                              justifyContent={"flex-start"}
                              textAlign="left"
                              sx={{
                                borderRight: `1px solid ${theme.palette.background.primary}`,
                              }}
                              title={
                                returnCustomValues(values?.custom_value) &&
                                returnCustomValuesAsString(values?.custom_value)
                              }
                            >
                              <ListItem
                                className="p-0 m-0 "
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <StyleIcon
                                  style={{
                                    width: 13,
                                    marginRight: 2,
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                  }}
                                />
                                <p
                                  className="m-0 p-0"
                                  data-testid="individualCategory"
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: "100px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setCategoryUpdateData(values);

                                    openCloseModal(!categoryUpdateOpen);
                                  }}
                                >
                                  {returnCustomValues(values?.custom_value) &&
                                    returnCustomValuesAsString(
                                      values?.custom_value
                                    )}
                                </p>
                              </ListItem>
                            </Grid>
                          ) : (
                            <Grid
                              item
                              py={0.5}
                              px={1}
                              alignItems="center"
                              justifyContent={"flex-start"}
                              textAlign="left"
                              sx={{
                                borderRight: `1px solid ${theme.palette.background.primary}`,
                              }}
                            >
                              <ListItem
                                // className="py-0 m-0 px-1"

                                className="p-0 m-0"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <StyleIcon
                                  style={{
                                    width: 13,
                                    marginRight: 2,
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                  }}
                                />
                                <p
                                  className="m-0 p-0"
                                  data-testid="individualCategory"
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: "100px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setCategoryUpdateData(values);
                                    openCloseModal(!categoryUpdateOpen);
                                  }}
                                >
                                  Select Category
                                </p>
                              </ListItem>
                            </Grid>
                          )}
                        </div>
                      )}

                      <CategoryUpdateDialog
                        open={categoryUpdateOpen}
                        onClose={() => {
                          setCategoryUpdateOpen(false);
                        }}
                        ticketData={{
                          _source: values,
                        }}
                        custom_value={values?.custom_value ?? []}
                        customticketfields={props.customticketfields}
                        customticketfields_filter={
                          props.customticketfields_filter
                        }
                        fetchFunction={() => {
                          // fetchFunction(pageNumber);
                        }}
                        setFieldValue={setFieldValue}
                        from="bulkUpdate"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <ErrorMessage
                  name="message"
                  component="div"
                  className="error"
                />
              </Grid>

              {/* </Box> */}
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="submit"
                      name="Submit"
                      width="100%"
                      loading={loading}
                    />
                  ) : (
                    <MuiButton
                      type="submit"
                      name="Submit"
                      width="100%"
                      loading={loading}
                    />
                  )}
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="reset"
                      name="Reset"
                      onClick={Formik.resetForm}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                        Formik.resetForm();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(BulkAssign);
const returnColor = (color) => {
  return (
    <Box
      width={10}
      height={10}
      borderRadius="50%"
      sx={{
        backgroundColor: `${color}`,
      }}
    ></Box>
  );
};
