import BaseReducer from "../../../../utilities/BaseReducer";
import WebsweepxReportsAction from "./WebsweepxReportsAction";

export default class TelegramWsxReportsReduser extends BaseReducer {
  initialState = {
    reportsOnTelegramWsx: [],
  };
  [WebsweepxReportsAction.REQUEST_GET_TELEGRAM_WSX_REPORTS_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      reportsOnTelegramWsx: action.payload,
    };
  }
}
