import React, { useEffect, useRef, useState } from "react";
import "./cssFiles/ResponseManagementPlatformCounts.css";
import environment from "environment";
import { Box, useMediaQuery } from "@mui/material";
import { theme } from "../../../views/App";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { format, isValid } from "date-fns";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";

function YoutubeSubscribersGainLostOvertime(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    setData(null);
    (async function CallApi() {
      setLoading(true);

      props.account_selected &&
        (await fetchData(props.account_selected, props.openDateFilter));

      setLoading(false);
    })();
  }, [props.openDateFilter, props.account_selected]);

  useEffect(() => {
    if (props.clearClicked) {
      setData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.account_selected, props.openDateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      setData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.account_selected, props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.account_selected, props.filterClicked, props.openDateFilter]);

  async function fetchData(account_id, dateRange) {
    let API = `${environment.api.youtubeReports}?account_id=${
      account_id?.account_id
    }&report_type=subscribers Gained/Lost over time${
      props.openDateFilter || props.filterClicked
        ? `&start_date=${dateRange[0]}&end_date=${dateRange[1]}`
        : `&start_date=${format(
            new Date().setFullYear(new Date().getFullYear() - 1),
            "yyyy-MM-dd"
          )}&end_date=${format(new Date(), "yyyy-MM-dd")}`
    }`;
    await axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (
          response.hasOwnProperty("error") ||
          response.hasOwnProperty("error_id")
        ) {
          response.hasOwnProperty("error_id")
            ? setApiError(`${response?.message} - ${response?.error_id}`)
            : setApiError(response?.error);
          setData(null);
        } else {
          setData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setData(null);
        setApiError(err.message);
        setLoading(false);
      });
  }

  let NoDataFoundBool = !data && !props.account_selected;

  useEffect(() => {
    if (props.DownloadClicked) {
      let exportData = [];

      data &&
        Object.keys(data)?.length > 0 &&
        Object.entries(data).map(([date, dateObj]) => {
          !["totalsubscribersGained", "totalsubscribersLost"].includes(date) &&
            exportData.push({
              Date: date,
              "Subscribers Gained": dateObj.subscribersGained,
              "Subscribers Lost": dateObj.subscribersLost,
            });
        });

      exportData.push({
        Date: "Total",
        "Subscribers Gained": data.totalsubscribersGained,
        "Subscribers Lost": data.totalsubscribersLost,
      });

      props.setexcelData(exportData);
    }
  }, [props.DownloadClicked, data]);

  let categories = data && Object.keys(data);

  const categoriesToRemove = ["totalsubscribersLost", "totalsubscribersGained"];

  // Filter out the unwanted names
  categories = categories?.filter((name) => !categoriesToRemove.includes(name));


  const subscribersGained = categories?.map(
    (date) => data?.[date]?.subscribersGained
  );
  const subscribersLost = categories?.map(
    (date) => data?.[date]?.subscribersLost
  );

  const option = {
    title: {
      text: "",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#283b56",
        },
      },
    },
    legend: {
      data: ["Subscribers Gained", "Subscribers Lost"],
    },
    toolbox: {
      show: false,
      feature: {
        dataView: { readOnly: false },
        restore: {},
        saveAsImage: {},
      },
    },
    dataZoom: [
      {
        type: "inside",
        start: 0,
        end: 100,
      },
    ],
    xAxis: [
      {
        type: "category",
        boundaryGap: true,
        data: categories,
      },
    ],
    yAxis: [
      {
        type: "value",
        scale: true,
        name: "Subscribers",
        max: 30,
        min: 0,
        boundaryGap: [0.2, 0.2],
      },
    ],
    series: [
      {
        name: "Subscribers Gained",
        type: "bar",
        data: subscribersGained,
        smooth: true,
        itemStyle: {
          color: theme.reports.positive, // Example color for fans bar
        },
      },
      {
        name: "Subscribers Lost",
        type: "bar",
        smooth: true,
        data: subscribersLost,
        itemStyle: {
          color: theme.reports.negative, // Example color for fans bar
        },
      },
    ],
  };

  return (
    <LoadingIndicator isActive={loading}>
      <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flex: "12",
        }}
      >
        {!loading ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            {!NoDataFoundBool ? (
              <Box
                style={{
                  width: "100%",
                  paddingY: "12px",
                  paddingRight: "12px",
                  height: "100%",
                }}
              >
                {apiError ? (
                  <Box
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      flex: "12",
                    }}
                  >
                    {apiError}
                  </Box>
                ) : (
                  <ReactEcharts
                    onEvents={{
                      click: () => null,
                    }}
                    option={option}
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    notMerge={true}
                    lazyUpdate={true}
                  />
                )}
              </Box>
            ) : (
              <NoDataFound />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage width={"50%"} />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  );
}

export default YoutubeSubscribersGainLostOvertime;
