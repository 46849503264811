import ActionUtility from "../../../../utilities/ActionUtility";
import RevenueThresoldEffect from "./RevenueThresoldEffect";

export default class RevenueThresoldAction {
  // RevenueThresoldAction action with an function declaration
  static REQUEST_REVENUETHRESOLD =
    "RevenueThresoldAction.REQUEST_REVENUETHRESOLD";
  static REQUEST_REVENUETHRESOLD_FINISHED =
    "RevenueThresoldAction.REQUEST_REVENUETHRESOLD_FINISHED";

  static requestRevenueThresold(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueThresoldAction.REQUEST_REVENUETHRESOLD,
        RevenueThresoldEffect.requestRevenueThresold,
        params,
        callBack
      );
    };
  }
}
