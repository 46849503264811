import BaseReducer from "../../../../utilities/BaseReducer";
import TelegramIntermediateAction from "./TelegramIntermediateAction";

export default class TelegramISearchHistoryGetReduser extends BaseReducer {
  initialState = {
    telegram_search_history: [],
  };
  [TelegramIntermediateAction.REQUEST_TELEGRAM_SEARCH_HISTORY_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      telegram_search_history: action.payload,
    };
  }
}
