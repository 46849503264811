import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { connect } from "react-redux";
import { selectRequesting } from "../../../../../selectors/requesting/RequestingSelector";
import CustomTicketFieldsAction from "../../../../../stores/customticketfields/CustomTicketFieldsAction";
import TicketsAction from "../../../../stores/tickets/TicketsAction";
import MuiButton from "../../../../../components/muiButton/MuiButton";
import MuiTextField from "../../../../../components/muiTextField/MuiTextField";
import AutoCompleteMultiSelect from "../../../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import SwitchButton from "../../../../../components/switchComponent/SwitchButton";
import MuiDatePicker from "../../../../../components/datePickerComponent/MuidatePicker";
import LoadingIndicator from "../../../../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../../../../components/loadingBgImage/LoadingBgImage";

// mapState to props
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    CustomTicketFieldsAction.REQUEST_CUSTOMTICKETFIELDS,
    TicketsAction.REQUEST_PUT_TICKETS,
  ]),
  customticketfields: state.customticketfields.customticketfields || {},
  customticketfields_filter:
    state.customticketfields_filter.customticketfields_filter.hits || {},
});

const CustomTIcektsTab = (props) => {
  const theme = useTheme();
  //useEffect function
  useEffect(() => {
    props.ticketData._source && fetchCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ticketData._source]);

  //custom fields fetching for respective project
  function fetchCustomFields() {
    const params = {
      filters: `[["project.project_id.keyword","must","","term","${props.ticketData?._source.project?.project_id}"]]`,
      page_limit: "none",
    };
    props.dispatch(CustomTicketFieldsAction.requestCustomTicketFields(params));
  }

  //function for defining type of initial values for custom fields
  function setCustomFieldValueType(data) {
    if (data === "text") {
      return [{ value: "", id: "" }];
    } else if (data === "select") {
      return [];
    } else if (data === "date") {
      return [{ value: "", id: "" }];
    } else if (data === "multiselect") {
      return [];
    } else if (data === "radio") {
      return [{ value: "", id: "" }];
    } else if (data === "toggle") {
      return [{ value: "", id: "" }];
    } else if (data === "category") {
      return [];
    } else {
      return [];
    }
  }

  //CUSTOM FIELDS INITIAL VALUES ASSIGNING
  let customFieldInitialValues;

  //initial values if tickets custom_value = []
  if (
    props.ticketData &&
    props.ticketData._source?.custom_value?.length === 0
  ) {
    let custom_value = [];

    if (props.customticketfields.hits) {
      props.customticketfields.hits.hits.map((item) => {
        return custom_value.push({
          field_id: item._id,
          field_name: item._source.field_name,
          field_type: item._source.field_type,
          field_values: setCustomFieldValueType(item._source.field_type),
        });
      });
    }
    customFieldInitialValues = { custom_value };
  }

  // initial values if (ticket custom_value = [{},{}]) < (project custom field = [{},{},{}])
  else if (
    props.ticketData &&
    props.ticketData._source?.custom_value?.length <
      (props.customticketfields.hits &&
        props.customticketfields.hits.hits.length)
  ) {
    let custom_value = [...props.ticketData._source?.custom_value];

    if (props.customticketfields.hits) {
      for (let i = 0; i < props.customticketfields.hits.hits.length; i++) {
        let isFound = false;

        for (let j = 0; j < custom_value.length; j++) {
          if (
            props.customticketfields.hits.hits[i]._id ===
            custom_value[j].field_id
          ) {
            isFound = true;
            break;
          }
        }
        if (isFound === false) {
          custom_value.push({
            field_id: props.customticketfields.hits.hits[i]._id,
            field_name:
              props.customticketfields.hits.hits[i]._source.field_name,
            field_type:
              props.customticketfields.hits.hits[i]._source.field_type,
            field_values: setCustomFieldValueType(
              props.customticketfields.hits.hits[i]._source.field_type
            ),
          });
        }
      }
      customFieldInitialValues = { custom_value };
    }
  }
  //  initial values if (ticket custom_value = [{},{}]) === (project custom field = [{},{}])
  else if (props.ticketData._source) {
    let custom_value = [...props.ticketData._source?.custom_value];

    customFieldInitialValues = { custom_value };
  }

  function returnFieldName(fieldId) {
    return (
      props.customticketfields.hits &&
      props.customticketfields.hits.hits
        .filter((val) => val._id === fieldId)
        .map((item) => item._source.field_name)[0]
    );
  }

  const [ticketFieldsLoading, setTicketFieldsLoading] = useState(false);

  // console.log("INITIAL_VALUES", customFieldInitialValues);

  return (
    <Box className="scrollable" sx={{ height: "100%" }}>
      <LoadingIndicator isActive={props.isRequesting}>
        {!props.isRequesting && props.ticketData._source && (
          <Grid
            container
            item
            xl={12}
            lg={12}
            laptop={12}
            md={12}
            sm={12}
            p={1}
            sx={{
              overflow: "auto",
              overscrollBehaviorY: "contain",
            }}
          >
            <Grid
              container
              item
              lg={12}
              laptop={12}
              md={12}
              sm={12}
              my={1}
              py={1}
              sx={{
                width: "100%",
                border: "solid 0.5px white",
                boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                borderRadius: theme.palette.buttonComponent.borderRadius.large,
                backgroundColor: "white",
              }}
            >
              <Formik
                initialValues={customFieldInitialValues}
                enableReinitialize={true}
                // validationSchema={tagsValidationSchema}
                onSubmit={(values, formikHelpers) => {
                  setTicketFieldsLoading(true);
                  props
                    .dispatch(
                      TicketsAction.requestPutTicket(
                        values,
                        props.ticketData._id
                      )
                    )
                    .then(() => {
                      props.dispatch(
                        TicketsAction.requestTicketsById(props.ticketData._id)
                      );
                      setTicketFieldsLoading(false);
                    });
                }}
                validateOnBlur={true}
                validateOnChange
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <Form style={{ width: "100%" }} onSubmit={handleSubmit}>
                      <Box
                        // className="bg-info"
                        sx={{ width: "100%" }}
                      >
                        <Grid
                          container
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          laptop={12}
                          justifyContent="space-between"
                          alignItems="center"
                          px={2}
                          // className="bg-warning"
                        >
                          <Grid
                            item
                            lg={6}
                            laptop={6}
                            md={6}
                            sm={6}
                            alignSelf="center"
                            fontSize={"20px"}
                          >
                            {/* <span>Custom Fields</span> */}
                          </Grid>

                          <Grid
                            item
                            xl={6}
                            lg={6}
                            laptop={6}
                            md={6}
                            sm={6}
                            textAlign="right"
                          >
                            <MuiButton
                              variant="contained"
                              width={"35%"}
                              height={"25px"}
                              type="submit"
                              name={"Update"}
                              loading={ticketFieldsLoading}
                              disabled={
                                values?.custom_value?.length &&
                                values?.custom_value.filter(
                                  (item) => item.field_type !== "category"
                                )?.length === 0
                              }
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      <Grid
                        container
                        item
                        xl={12}
                        lg={12}
                        laptop={12}
                        md={12}
                        sm={12}
                        px={2}
                        // className="bg-success"
                        sx={{
                          overflow: "auto",
                        }}
                      >
                        {values.custom_value?.length &&
                        values?.custom_value.filter(
                          (item) => item.field_type !== "category"
                        )?.length === 0 ? (
                          <Box>
                            <Typography fontWeight={"bold"}>
                              No Custom Fields Created yet!
                            </Typography>
                          </Box>
                        ) : (
                          values.custom_value.map((item, index) => {
                            if (item.field_type === "text") {
                              return (
                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  laptop={12}
                                  md={12}
                                  sm={12}
                                  mt={1}
                                  key={index}
                                >
                                  <label htmlFor={item.field_name}>
                                    {returnFieldName(item.field_id)}
                                  </label>

                                  <Field
                                    as={MuiTextField}
                                    variant="outlined"
                                    id="postal_code"
                                    size="small"
                                    name={`custom_value.${index}.field_values[0].value`}
                                  />
                                </Grid>
                              );
                            } else if (item.field_type === "select") {
                              let fieldOptions = [];

                              fieldOptions =
                                props.customticketfields?.hits?.hits?.filter(
                                  (e) => e._id === item.field_id
                                ).length === 0
                                  ? []
                                  : props.customticketfields.hits.hits?.filter(
                                      (e) => e._id === item.field_id
                                    )[0]?._source?.field_values;
                              return (
                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  laptop={12}
                                  md={12}
                                  sm={12}
                                  mt={1}
                                >
                                  <label htmlFor={item.field_name}>
                                    {returnFieldName(item.field_id)}
                                  </label>
                                  <Field
                                    name={`custom_value[${index}].field_values`}
                                    component={AutoCompleteMultiSelect}
                                    options={fieldOptions}
                                    size="small"
                                    isOptionEqualToValue={(option, value) =>
                                      // setting value property to custom value
                                      option.value === value.value
                                    }
                                    // setting custom label to custom value
                                    getOptionLabel={(option) => option.value}
                                    textFieldProps={{
                                      fullWidth: true,
                                      margin: "normal",
                                      variant: "outlined",
                                    }}
                                    multiple
                                  />
                                </Grid>
                              );
                            } else if (item.field_type === "toggle") {
                              return (
                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  laptop={12}
                                  md={12}
                                  sm={12}
                                  mt={1}
                                >
                                  <label htmlFor={item.field_name}>
                                    {returnFieldName(item.field_id)}
                                  </label>
                                  <br />
                                  <FormControlLabel
                                    control={
                                      <Field
                                        component={SwitchButton}
                                        type="checkbox"
                                        name={`custom_value.${index}.field_values[0].value`}
                                        checked={
                                          item.field_values[0]
                                            ? item.field_values[0].value
                                            : null
                                        }
                                      />
                                    }
                                  />
                                </Grid>
                              );
                            } else if (item.field_type === "radio") {
                              let radioOptions = [];

                              radioOptions =
                                props.customticketfields?.hits?.hits?.filter(
                                  (e) => e._id === item.field_id
                                ).length === 0
                                  ? []
                                  : props.customticketfields.hits.hits
                                      ?.filter(
                                        (e) => e._id === item.field_id
                                      )[0]
                                      ?._source?.field_values?.map(
                                        (item) => item.value
                                      );

                              return (
                                <Grid item xl={12} mt={2}>
                                  <label htmlFor={item.field_name}>
                                    {returnFieldName(item.field_id)}
                                  </label>
                                  <Field
                                    name={`custom_value[${index}].field_values[0].value`}
                                  >
                                    {({ field }) => (
                                      <RadioGroup
                                        className="p-0 m-0"
                                        {...field}
                                        row
                                        onChange={(event) => {
                                          let obj = { id: "", value: "" };
                                          obj.value = event.target.value;

                                          setFieldValue(
                                            `custom_value[${index}].field_values[0]`,
                                            obj
                                          );
                                        }}
                                      >
                                        {radioOptions.map((option) => (
                                          <FormControlLabel
                                            key={option}
                                            className="p-0 m-0"
                                            value={option}
                                            control={
                                              <Radio
                                                className="p-1 m-0"
                                                size="small"
                                              />
                                            }
                                            label={
                                              <span
                                                style={{
                                                  fontSize: 12,
                                                  padding: 0,
                                                }}
                                              >
                                                {option}
                                              </span>
                                            }
                                            sx={{ fontSize: 12, padding: 0 }}
                                          />
                                        ))}
                                      </RadioGroup>
                                    )}
                                  </Field>
                                </Grid>
                              );
                            } else if (item.field_type === "date") {
                              return (
                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  mt={2}
                                >
                                  <label htmlFor={item.field_name}>
                                    {returnFieldName(item.field_id)}
                                  </label>{" "}
                                  &nbsp;&nbsp;
                                  <Field
                                    component={MuiDatePicker}
                                    size="small"
                                    value={
                                      values.custom_value[index].field_values[0]
                                        .value
                                        ? values.custom_value[index]
                                            .field_values[0].value
                                        : null
                                    }
                                    disablePast={false}
                                    format="DD/MM/YYYY"
                                    name={`custom_value.${index}.field_values[0].value`}
                                    textFieldProps={{
                                      fullWidth: true,
                                      margin: "normal",
                                      variant: "outlined",
                                    }}
                                  />
                                </Grid>
                              );
                            }
                          })
                        )}
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        )}
      </LoadingIndicator>
    </Box>
  );
};

export default connect(mapStateToProps)(CustomTIcektsTab);
