import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import AuthAction from "../../stores/Auth/AuthAction";
import { connect } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockIcon from "@mui/icons-material/Lock";
import "./signin.css";
import logo from "../../components/logo/logo.webp";
import { Grid, TextField, useTheme } from "@mui/material";
import MuiButton from "../../components/muiButton/MuiButton";
import PlatformAction from "../../ormTicketing/stores/platform/PlatformAction";
import SubSourceAction from "../../stores/subSource/SubSourceAction";

import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router";
import PagePermissionAction from "../../stores/pagePermission/PagePermissionAction";
import UserLastWorkingProjectAction from "../../ormTicketing/stores/userLastWorkingProject/UserLastWorkingProjectAction";
import PrivacyPolicy from "../../components/privacyPolicy/PrivacyPolicy";
import ProjectAction from "../../ormTicketing/stores/project/projectAction";
import jwtDecode from "jwt-decode";
import { theme } from "../App";
import CopyrightIcon from "@mui/icons-material/Copyright";

const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    AuthAction.REQUEST_LOGIN,
    PagePermissionAction.REQUEST_PAGE_PERMISSION_FINISHED,
    UserLastWorkingProjectAction.REQUEST_LAST_WORKING_PROJECT_BY_ID,
  ]),
  lastWorkingProject:
    state.userLastWorkingProjectById.userLastWorkingProjectById || {},
});

function SignIn(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // ****************************************************** handle submit
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //
  //     props
  //       .dispatch(
  //         AuthAction.requestLogin(data.get("email"), data.get("password"))
  //       )
  //       .then(() => {
  //         props.dispatch(PlatformAction.requestFilterPlatform());
  //         props.dispatch(SubSourceAction.requestSubSource());
  //       })
  //   );
  // };

  const validationSchema = yup.object().shape({
    email: yup.string().trim().required("Email/UserName is required"),
    password: yup.string().trim().required("Password is required"),
  });

  let initial = {};

  initial = {
    email: "",
    password: "",
  };

  const textFieldForUsernameRef = React.useRef(null);
  const textFieldForPasswordRef = React.useRef(null);
  const buttonForLoginRef = React.useRef(null);

  function fetchLastWorkingProject(id) {
    props.dispatch(
      UserLastWorkingProjectAction.requestLastWorkingProjectById(id)
    );
  }

  const [privacyPolicyOpen, setPrivacyPolicyOpen] = React.useState(false);

  return (
    <Box className="loginPageContainer">
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        onSubmit={(values, formikHelpers) => {
          const projectNavigationFunction = (projectfilter, from) => {
            let project_object = [];
            if (projectfilter) {
              projectfilter.hits &&
                projectfilter.hits.hits &&
                projectfilter.hits.hits.length &&
                projectfilter.hits.hits.length === 1 &&
                projectfilter.hits.hits.map((item) => {
                  project_object.push({
                    project_id: item._id,
                    project_name: item._source.project_name,
                    organization_id:
                      item._source?.organization_id?.organization_id,
                  });
                });
            }
            let obj = {
              project: project_object.length > 0 && project_object[0],
              user_id:
                localStorage.getItem("userId") &&
                localStorage.getItem("userId"),
            };

            if (projectfilter && projectfilter.hits) {
              if (
                projectfilter.hits.hits &&
                projectfilter.hits.hits.length &&
                projectfilter.hits.hits.length === 1
              ) {
                if (
                  props.lastWorkingProject &&
                  props.lastWorkingProject._source
                ) {
                  navigate(from !== "/logout" ? from : "/", {
                    state: { from: from !== "/logout" ? from : "/" },
                  });
                } else {
                  props
                    .dispatch(
                      UserLastWorkingProjectAction.requestPostLastWorkingProject(
                        JSON.stringify(obj)
                      )
                    )
                    .then(() => {
                      localStorage.setItem(
                        "user_last_working_project",
                        JSON.stringify(obj.project)
                      );
                      let param = {
                        page_limit: "none",
                      };

                      props
                        .dispatch(
                          PagePermissionAction.requestPagePermission(param)
                        )
                        .then(() => {
                          navigate(from !== "/logout" ? from : "/", {
                            state: {
                              from: from !== "/logout" ? from : "/",
                            },
                          });
                        });
                    });
                }
              } else if (projectfilter.hits.hits.length === 0) {
                navigate("/projectSelect", {
                  state: { from: from !== "/logout" ? from : "/" },
                });
                const auth = localStorage.getItem("Auth");
                const authData = jwtDecode(auth);
                // replace = true;
                // props.onClose();
              } else {
                navigate("/projectSelect", {
                  state: { from: from !== "/logout" ? from : "/" },
                });
                const auth = localStorage.getItem("Auth");
                const authData = jwtDecode(auth);
                // replace = true;
                // props.onClose();
              }
            }
          };

          const callBackFunction = () => {
            if (
              location.state.from !== "/logout" &&
              location.state.from !== "/"
            ) {
              let ID = localStorage.getItem("userId");
              fetchLastWorkingProject(ID);
              if (localStorage.getItem("page_permission") === null) {
                if (
                  localStorage.getItem("user_last_working_project") === null
                ) {
                  localStorage.setItem("open", false);
                  // navigate("/projectSelect", {
                  //   state: { from: location.state.from },
                  // });

                  const callBack = (response) => {
                    projectNavigationFunction(response, location.state.from);
                  };
                  let param = { page_limit: "none" };

                  props.dispatch(
                    ProjectAction.requestProjectFilter(param, callBack)
                  );
                }
              } else {
                navigate(location.state.from, {
                  state: { from: location.pathname },
                });
              }
            }
          };

          const callBackFunction_two = () => {
            if (location.state.from !== "/logout") {
              let ID = localStorage.getItem("userId");
              fetchLastWorkingProject(ID);
              // navigate("/projectSelect", {
              //   state: { from: "/" },
              // });
              const callBack = (response) => {
                projectNavigationFunction(response, "/");
              };
              let param = { page_limit: "none" };
              props.dispatch(
                ProjectAction.requestProjectFilter(param, callBack)
              );
            }
          };

          props
            .dispatch(
              AuthAction.requestLogin(
                values.email,
                values.password,
                callBackFunction,
                callBackFunction_two
              )
            )
            .then(() => {
              const params = {
                order_by: '[["created_on", "asc"]]',
                page_limit: "none",
              };

              const platform_param = {
                level: "organization",
                page_limit: "none",
                order_by: `[["platform_name.keyword","asc"]]`,
              };
              const param = { page_limit: "none" };
              if (localStorage.getItem("Auth") !== null) {
                props.dispatch(
                  PlatformAction.requestFilterPlatform(platform_param)
                );
                props.dispatch(SubSourceAction.requestSubSource(params));
              }
            });
        }}
      >
        {({ values, errors, isValid, touched, dirty, handleChange }) => (
          <Form>
            <Box width={"100%"} height="100%">
              <Grid
                container
                item
                p={8}

                // className="bg-info"
              >
                <img
                  src={logo}
                  className="logoContainer"
                  style={{
                    aspectRatio: "4:2",
                  }}
                  alt="logo"
                />
              </Grid>
              <Grid
                container
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                laptop={12}
                // className="bg-success"
                justifyContent={"center"}
                alignItems="center"
                mt={{
                  xl: "10%",
                  lg: "12%",
                  md: "12%",
                  sm: "25%",
                  xs: "18%",
                  laptop: "2%",
                }}
              >
                <Grid item xl={3} lg={4} laptop={5} md={6} sm={8} xs={6}>
                  <Box className="login-box">
                    <Grid
                      // sx={{ opacity: "0.8" }}
                      // onSubmit={handleSubmit}
                      item
                      container
                      flexDirection={"column"}
                      alignItems={"center"}
                      alignSelf={"center"}
                    >
                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        laptop={12}
                        justifyContent={"center"}
                        alignItems="center"
                        mb={1}
                      >
                        <span
                          className="signin-header"
                          style={{
                            fontFamily: theme.typography.fontFamily,
                          }}
                        >
                          Sign in to your account
                        </span>
                      </Grid>
                      <Grid
                        item
                        width={"65%"}
                        // className="bg-warning"
                      >
                        {/* ************************************************************************* userName */}
                        <Field
                          // className="bg-warning"
                          id="email"
                          as={TextField}
                          name="email"
                          autoComplete="email"
                          placeholder="User Name"
                          inputRef={textFieldForUsernameRef}
                          error={
                            Boolean(errors.email) && Boolean(touched.email)
                          }
                          helperText={Boolean(touched.email) && errors.email}
                          autoFocus
                          sx={{
                            borderRadius:
                              theme.palette.buttonComponent.borderRadius.small,
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            "& .MuiOutlinedInput-root ": {
                              height: "40px",
                              "&:hover": {
                                // Apply styles for hover state
                                border: `0.5px solid transparent`,
                                outline: `0.5px solid transparent`,
                              },
                              "&.Mui-focused fieldset": {
                                border: `0.5px solid ${theme.palette.primary.light}`,
                                outline: `0.5px solid transparent`,
                              },
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className="p-0 m-0 pr-1"
                              >
                                <PersonIcon
                                  sx={{ color: "#e1e6e8", fontSize: "20px" }}
                                />
                              </InputAdornment>
                            ),
                            onKeyPress: (event) => {
                              const { key } = event;
                              if (key === "Enter") {
                                textFieldForPasswordRef.current.focus();
                              }
                            },
                          }}
                          fullWidth
                        />
                        <br />
                        <br />
                        {/* <br /> */}

                        {/* ************************************************************************* Password */}
                        <Field
                          name="password"
                          as={TextField}
                          type={showPassword ? "text" : "password"}
                          id="password"
                          inputRef={textFieldForPasswordRef}
                          placeholder="Password"
                          autoComplete="current-password"
                          error={
                            Boolean(errors.password) &&
                            Boolean(touched.password)
                          }
                          helperText={
                            Boolean(touched.password) && errors.password
                          }
                          sx={{
                            borderRadius:
                              theme.palette.buttonComponent.borderRadius.small,
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            "& .MuiOutlinedInput-root": {
                              height: "40px",
                              borderColor: "blue",
                              "&:hover": {
                                // Apply styles for hover state
                                border: `0.5px solid transparent`,
                                outline: `0.5px solid transparent`,
                              },
                              "&.Mui-focused fieldset": {
                                border: `1.5px solid ${theme.palette.primary.light}`,
                                outline: `0.5px solid transparent`,
                              },
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className="p-0 m-0 pr-1"
                              >
                                <LockIcon
                                  sx={{ color: "#e1e6e8", fontSize: "20px" }}
                                />
                              </InputAdornment>
                            ),

                            onKeyPress: (event) => {
                              const { key } = event;
                              if (key === "Enter") {
                                buttonForLoginRef.current &&
                                  buttonForLoginRef.current.click();
                              }
                            },

                            endAdornment: (
                              <InputAdornment
                                position="end"
                                className="p-0 m-0"
                              >
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff sx={{ color: "#e1e6e8" }} />
                                  ) : (
                                    <Visibility sx={{ color: "#e1e6e8" }} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                        />
                        <br />
                        <br />
                        <br />
                        {/* <Typography
                        mt={0.5}
                        mb={2}
                        //  className="bg-warning"
                        textAlign="right"
                      >
                        forgot password?
                      </Typography> */}
                        {/* ************************************************************************* Submit */}
                        <MuiButton
                          loading={props.isRequesting}
                          className="submit-btn"
                          type="submit"
                          name={!props.isRequesting ? "Login" : "Logging In"}
                          width={"100%"}
                          buttonRef={buttonForLoginRef}
                        />
                        <br />
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid
                    item
                    xs={12}
                    pt={2}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography
                      sx={{
                        color: "#797676!important",
                        fontSize: theme.typography.fontSize,
                      }}
                    >
                      <span>
                        <CopyrightIcon sx={{ marginTop: -0.5 }} />
                      </span>
                      Aiplex Bridge {new Date().getFullYear()} - All Rights
                      Reserved.
                    </Typography>
                    {/* <Typography
                      sx={{
                        color: "#797676!important",
                        fontSize: theme.typography.fontSize,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setPrivacyPolicyOpen(true);
                      }}
                    >
                      Privacy
                    </Typography> */}

                    <PrivacyPolicy
                      open={privacyPolicyOpen}
                      onClose={() => {
                        setPrivacyPolicyOpen(false);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
export default connect(mapStateToProps)(SignIn);
