import React, { useState, useEffect, useRef } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Checkbox,
  FormControlLabel,
  IconButton,
  Chip,
  styled,
} from "@mui/material";
import { connect } from "react-redux";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import UserAction from "../../ormTicketing/stores/user/UserAction";
import StatusAction from "../../ormTicketing/stores/status/StatusAction";
import DIYReportTemplateAction from "../../ormTicketing/stores/newReportTemplate/DIYReportTemplateAction";
import PlatformAccountsAction from "../../ormTicketing/stores/platformAccounts/PlatformAccountsAction";
import _ from "lodash";
import {
  selectedStaticReport,
  templateReportsForSocialListeing,
} from "../../components/ticketPartials";
import { theme } from "../../views/App";
import ReturnDemoStaticReports from "../../components/ReturnDemoStaticReports";
import ReturnReports from "../../components/ReturnReports";
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";
import DetailedReportScreen from "../../ormTicketing/views/diyReport/DetailedReportScreen";
import YesOrNoDailog from "../../components/yesOrNoDailog/YesOrNoDailog";
import DiyReportPostForm from "../../ormTicketing/views/diyReport/DiyReportPostForm";
import MuiButton from "../../components/muiButton/MuiButton";
import PlatformAction from "../../ormTicketing/stores/platform/PlatformAction";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

let mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    UserAction.REQUEST_USER_FILTER,
    StatusAction.REQUEST_STATUS_FILTER,
    DIYReportTemplateAction.REQUEST_DIY_REPORT_TEMPLATE_FILTER,
    PlatformAccountsAction.REQUEST_PLATFORM_ACCOUNT,
  ]),
  saveReportTemplate: selectRequesting(state, [
    DIYReportTemplateAction.REQUEST_POST_DIY_REPORT_TEMPLATE,
    DIYReportTemplateAction.REQUEST_PUT_DIY_REPORT_TEMPLATE,
  ]),
  diyReportTemplateFilter:
    state.diyReportTemplateFilter.diyReportTemplateFilter || [],
  platform: state.platform.platform || [],
  userfilter: state.userfilter.userfilter || [],
  statusFilter: state.statusFilter.statusFilter || [],
  platformAccount: state.platformAccount.platformAccount || [],
});

const SocialLIsteningDIYReport = (props) => {
  const boxRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [draggingItem, setDraggingItem] = useState(null);
  const [postdialogOpen, setPostdialogOpen] = useState(false);
  const [pdfViewOpen, setPdfViewOpen] = useState(false);

  const [detailReportOpen, setDetailReportOpen] = useState();
  const [detailReportId, setDetailReportId] = useState();

  const [templateSelected, setTemplateSelected] = useState(null);

  function fetchOrgPlatforms() {
    let param = {
      page_limit: "none",
      order_by: `[["platform_name.keyword","asc"]]`,
      // level: "organization",
    };
    props.dispatch(PlatformAction.requestPlatform(param));
  }

  function fetchStatusFilter() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(StatusAction.requestStatusFilter(params));
  }

  function fetchUsers() {
    let param = { page_limit: "none" };
    props.dispatch(UserAction.requestUserFilter(param));
  }

  function fetchDIYreportTemplates() {
    const params = { page_limit: "none", order_by: `[["created_on","asc"]]` };
    props.dispatch(
      DIYReportTemplateAction.requestDIYReportTemplateFilter(params)
    );
  }

  function fetchPlatformAccounts() {
    let params = {
      page_limit: "none",
      order_by: '[["created_on", "desc"]]',
    };

    props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
  }

  const account_list = [];

  if (props?.platformAccount?.hits) {
    props?.platformAccount?.hits?.hits?.map((item) => {
      account_list.push({
        platform: item?._source.platform,
        account_id: item?._id,
        account_name:
          item?._source.platform === "Facebook"
            ? item?._source.page_name
            : item?._source.account_name,
      });
    });
  }

  useEffect(() => {
    // if (
    //   props?.platform === undefined ||
    //   Object.keys(props.platform).length === 0
    // ) {
    fetchOrgPlatforms();
    // }

    // if (
    //   props?.statusFilter === undefined ||
    //   Object.keys(props.statusFilter).length === 0
    // ) {
    fetchStatusFilter();
    // }

    // if (props?.userfilter === undefined || props?.userfilter.length === 0) {
    fetchUsers();
    fetchPlatformAccounts();
    // }

    // if (
    //   props?.diyReportTemplateFilter === undefined ||
    //   props?.diyReportTemplateFilter.length === 0
    // ) {
    fetchDIYreportTemplates();
    // }
  }, []);

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={
        <KeyboardArrowRightIcon sx={{ fontSize: "0.9rem", color: "#86aeb6" }} />
      }
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor: "#e7f1f4",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
    backgroundColor: "white",
  }));

  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // Platforms list creation for reports
  let platform_list = [];
  if (props?.platform?.hits) {
    props.platform?.hits?.hits &&
      props.platform.hits.hits.length > 0 &&
      props.platform.hits.hits.map((item) => {
        platform_list.push({
          platform_id: item._id,
          platform_name: item._source.platform_name,
          platform_alias: item._source.alias,
        });
      });
  }

  // Activity list creation for reports
  const activitiesData = JSON.parse(localStorage.getItem("activities"));
  let activity_list = [];
  activitiesData &&
    activitiesData.hits &&
    activitiesData.hits.hits &&
    activitiesData.hits.hits.length > 0 &&
    activitiesData.hits.hits.map((item) => {
      activity_list.push({
        activity_id: item._id,
        activity_name: item._source.activities,
        platform: item._source.platform,
      });
    });

  // Status list creation for reports
  let statusList = [];
  if (props.statusFilter.hits) {
    props.statusFilter.hits.hits &&
      props.statusFilter.hits.hits.length > 0 &&
      props.statusFilter.hits.hits.map((item) => {
        statusList.push({
          status_id: item._id,
          status_name: item._source.status_name,
        });
      });
  }

  // User list creation for reports
  let userList = [];
  if (props.userfilter) {
    props.userfilter.data &&
      props.userfilter.data.length > 0 &&
      props.userfilter.data.map((user) => {
        userList.push({
          user_id: user.id,
          user_name: user.username,
        });
      });
  }

  let reportTemplateList = [];

  if (
    localStorage.getItem("show_demo_reports") &&
    JSON.parse(localStorage.getItem("show_demo_reports"))
  ) {
    reportTemplateList.push({
      template_id: "demo_static_report_id",
      template_name: "Demo_Static",
    });
  }

  if (props.diyReportTemplateFilter) {
    props.diyReportTemplateFilter?.hits?.hits.length > 0 &&
      props.diyReportTemplateFilter.hits.hits.map((template) => {
        reportTemplateList.push({
          template_id: template._id,
          template_name: template?._source.report_template_name,
        });
      });
  }

  const [breakPoints, setBreakPoints] = useState([
    {
      label: "LG",
      width: 1200,
    },
    {
      label: "MD",
      width: 996,
    },
    {
      label: "SM",
      width: 768,
    },
    {
      label: "XS",
      width: 480,
    },
    {
      label: "XSS",
      width: 200,
    },
  ]);

  const [selectedBreakPoint, setSelectedBreakPoint] = useState(null);

  const [layouts, setLayouts] = useState({
    lg: [
      {
        i: "0",
        x: 0,
        y: 0,
        w: 12, // Set the width to the number of columns in lg
        h: 6, // Set the height based on the number of rows or your preference
      },
    ],
    md: [
      {
        i: "0",
        x: 0,
        y: 0,
        w: 12, // Set the width to the number of columns in lg
        h: 6, // Set the height based on the number of rows or your preference
      },
    ],
    sm: [
      {
        i: "0",
        x: 0,
        y: 0,
        w: 12, // Set the width to the number of columns in lg
        h: 6, // Set the height based on the number of rows or your preference
      },
    ],
    xs: [
      {
        i: "0",
        x: 0,
        y: 0,
        w: 12, // Set the width to the number of columns in lg
        h: 6, // Set the height based on the number of rows or your preference
      },
    ],
    xxs: [
      {
        i: "0",
        x: 0,
        y: 0,
        w: 12, // Set the width to the number of columns in lg
        h: 6, // Set the height based on the number of rows or your preference
      },
    ],
  });

  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
  const [compactType, setCompactType] = useState("vertical");
  const [mounted, setMounted] = useState(false);
  const [toolbox, setToolbox] = useState({ lg: [] });
  const [openReportChooseDialog, setOpenReportChooseDialog] = useState(false);

  useEffect(() => {
    setMounted(true);

    let deviceWidth = summaryBoxRef?.current.clientWidth;

    const closestBreakpoint = breakPoints.reduce((prev, curr) => {
      return Math.abs(curr.width - deviceWidth) <
        Math.abs(prev.width - deviceWidth)
        ? curr
        : prev;
    });

    setBreakPoints((prev) => [
      ...prev,
      {
        label: "YS",
        width: deviceWidth,
      },
    ]);

    setSelectedBreakPoint({
      label: "YS",
      width: deviceWidth,
    });
  }, []);

  useEffect(() => {
    if (!pdfViewOpen) {
      setTemplateSelected(null);
      setReports([]);
      setCurrentBreakpoint("lg");
      setLayouts({
        lg: [],
        md: [],
        sm: [],
        xs: [],
        xxs: [],
      });
    }
  }, [pdfViewOpen]);

  useEffect(() => {
    if (templateSelected) {
      let selectedReport = null;
      if (templateSelected?.template_id === "demo_static_report_id") {
        selectedReport = selectedStaticReport;
        if (pdfViewOpen)
          LayoutChangePDFView(selectedReport?._source?.template?.layout);
        else setLayouts(selectedReport?._source?.template?.layout);
        setReports(selectedReport?._source?.template?.reports);
      } else if (
        props.diyReportTemplateFilter?.hits?.hits?.length &&
        templateSelected
      ) {
        selectedReport = props.diyReportTemplateFilter.hits.hits.filter(
          (template) => template._id === templateSelected?.template_id
        )[0];

        if (pdfViewOpen)
          LayoutChangePDFView(selectedReport?._source?.template?.layout);
        else setLayouts(selectedReport?._source?.template?.layout);
        setReports(selectedReport?._source?.template?.reports);
      } else {
        setLayouts({
          lg: [],
          md: [],
          sm: [],
          xs: [],
          xxs: [],
        });
        setReports();
      }
    }
  }, [templateSelected]);

  const onBreakpointChange = (breakpoint) => {
    setCurrentBreakpoint(breakpoint);
    setToolbox({
      ...toolbox,
      [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || [],
    });
  };

  const onCompactTypeChange = () => {
    let oldCompactType = "";

    const compactType =
      oldCompactType === "horizontal"
        ? "vertical"
        : oldCompactType === "vertical"
        ? null
        : "horizontal";
    setCompactType(compactType);
  };

  const onLayoutChange = (layout, layouts) => {
    layout[0] && setLayouts({ ...layouts });
  };

  let [reports, setReports] = useState([]);

  function LayoutChangePDFView(layoutItem = layouts) {
    const updatedLayouts = {
      ...layoutItem,
      sm: layoutItem?.sm
        ? layoutItem?.sm?.map((layout) => ({
            ...layout,
            w: 12,
            h: 12,
          }))
        : layoutItem?.lg?.map((layout) => ({
            ...layout,
            w: 12,
            h: 12,
          })),
    };
    setLayouts(updatedLayouts);
  }

  const generateDOMforPdf = () => {
    return _.map(layouts.lg, function (l, i) {
      const matchingItem = reports.find((item) => item.id === i);

      return (
        <Typography
          component={"div"}
          key={i}
          sx={{
            backgroundColor: "white",
            border: `1px solid ${theme.palette.borderColor.textField}`,
            overflow: "hidden",
            cursor: "grab",
            overflowY: "hidden",
          }}
        >
          <Box key={i} height="100%">
            <div
              style={{
                height: "100%",
              }}
              className="report-element"
            >
              {matchingItem &&
              templateSelected?.template_id === "demo_static_report_id" ? (
                <ReturnDemoStaticReports
                  report={matchingItem.reportID}
                  platform_list={platform_list}
                  activity_list={activity_list}
                  statusList={statusList}
                  userList={userList}
                  setDetailReportOpen={setDetailReportOpen}
                  setDetailReportId={setDetailReportId}
                />
              ) : (
                matchingItem && (
                  <ReturnReports
                    report={matchingItem.reportID}
                    platform_list={platform_list}
                    activity_list={activity_list}
                    statusList={statusList}
                    userList={userList}
                    setDetailReportOpen={setDetailReportOpen}
                    setDetailReportId={setDetailReportId}
                    detailReportOpen={detailReportOpen}
                  />
                )
              )}
            </div>
          </Box>
        </Typography>
      );
    });
  };

  const generateDOMforReport = () => {
    return _.map(layouts.lg, function (layout, i) {
      const matchingItem = reports.find((item) => item.id === i);

      return (
        <Typography
          component={"div"}
          key={i}
          sx={{
            backgroundColor: "white",
            border: `1px solid ${theme.palette.borderColor.textField}`,
            overflow: "hidden",
            cursor: "grab",
            overflowY: "hidden",
          }}
        >
          <Box key={i} height="100%">
            <div
              style={{
                height: "100%",
              }}
            >
              {matchingItem &&
              templateSelected?.template_id === "demo_static_report_id" ? (
                <ReturnDemoStaticReports
                  report={matchingItem.reportID}
                  platform_list={platform_list}
                  activity_list={activity_list}
                  statusList={statusList}
                  userList={userList}
                  setDetailReportOpen={setDetailReportOpen}
                  setDetailReportId={setDetailReportId}
                />
              ) : (
                matchingItem && (
                  <ReturnReports
                    report={matchingItem.reportID}
                    platform_list={platform_list}
                    activity_list={activity_list}
                    statusList={statusList}
                    userList={userList}
                    setDetailReportOpen={setDetailReportOpen}
                    setDetailReportId={setDetailReportId}
                    account_list={account_list}
                  />
                )
              )}
            </div>
          </Box>
        </Typography>
      );
    });
  };

  function SelectReports(props) {
    const selectReportRef = useRef(null);
    useEffect(() => {
      const calculateHeights = () => {
        const outerBoxHeight = selectReportRef?.current?.clientHeight;

        if (
          selectReportRef.current &&
          selectReportRef.current.children[1] &&
          selectReportRef.current.children[0]
        ) {
          selectReportRef.current.children[0].style.height = `50px`;
          selectReportRef.current.children[1].style.height = `${
            outerBoxHeight - 50
          }px`;
        }
      };

      if (props.open && selectReportRef.current) {
        calculateHeights();

        // Use ResizeObserver to listen for changes in the size of selectReportRef
        const resizeObserver = new ResizeObserver(calculateHeights);
        resizeObserver.observe(selectReportRef.current);

        return () => {
          resizeObserver.disconnect();
        };
      }

      // Cleanup ResizeObserver on component unmount
    }, [props.open]); // Empty dependency array to ensure the effect runs only once on mount

    return (
      props.open && (
        <Grid container>
          <Dialog
            open={props.open}
            onClose={props.onClose}
            fullWidth
            maxWidth="md"
            className="scrollable"
          >
            <DialogContent>
              <DialogContentText
                sx={{
                  backgroundColor: theme.palette.background.default,
                }}
              >
                <Box
                  ref={selectReportRef}
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    flex: "12",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent={"space-between"}
                      fullWidth
                      alignItems={"center"}
                      sx={{
                        px: 3,
                        py: 2,
                      }}
                    >
                      <Typography component={"h3"}>
                        <span
                          style={{
                            fontSize: 24,
                            color: theme.palette.ticketListView.checkbox,
                          }}
                        >
                          <span className="span_first_letter">S</span>
                          elect &nbsp;
                          <span className="span_first_letter">R</span>eport{" "}
                        </span>
                      </Typography>
                      <div
                        className="closebtn"
                        onClick={() => {
                          props.onClose();
                        }}
                      >
                        <i
                          title="Close"
                          className="fa fa-times-circle-o"
                          style={{ width: 16 }}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </Grid>
                  </Box>
                  <Box
                    className="scrollable "
                    sx={{
                      padding: "12px",
                      overflowY: "auto",
                      height: "100%",
                      "& .MuiDataGrid-virtualScroller": {
                        "&::-webkit-scrollbar": {
                          width: "3px",
                          height: "3px",
                        },
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: "transparent",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "#b0d2da",
                          borderRadius: "3px",
                          "&:hover": {
                            backgroundColor: "#85bac6",
                          },
                        },
                      },
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        height: "100%",
                        px: 3,
                        pb: 2,
                      }}
                      // className="bg-info"
                    >
                      {Object.entries(templateReportsForSocialListeing).map(
                        ([reportBased, reportTemplates]) => {
                          return (
                            <Accordion
                              // expanded={expanded === reportBased}
                              style={{
                                backgroundColor:
                                  theme.palette.background.default,
                                width: "100%",
                                // height: "fit-content",
                                border: "solid 0.5px #d3e2e6",
                                borderLeft: "transparent",
                                borderRight: "transparent",
                              }}
                              // onChange={handleChange(reportBased)}
                              // className="bg-warning my-2"
                            >
                              <AccordionSummary>
                                <Typography
                                  style={{
                                    fontWeight:
                                      expanded === reportBased
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {reportBased}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {reportTemplates.length &&
                                  reportTemplates.map((report) => (
                                    <Grid xs={12} px={0.8}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            value={JSON.stringify(report)}
                                            label={report.report_name}
                                            onChange={(e, v) => {
                                              if (v) {
                                                const newLayouts = {
                                                  ...layouts,
                                                  ["lg"]: [
                                                    ...layouts["lg"],
                                                    {
                                                      i: layouts["lg"].length
                                                        ? `${layouts["lg"].length}`
                                                        : "0",
                                                      x: 0,
                                                      y:
                                                        layouts["lg"].reduce(
                                                          (sum, layout) =>
                                                            sum + layout.h,
                                                          0
                                                        ) + 12,
                                                      w: 6, // Set the width to the number of columns in lg
                                                      h: 12,
                                                    },
                                                  ],
                                                  ["md"]: layouts["md"] && [
                                                    ...layouts["md"],
                                                    {
                                                      i: layouts["md"]?.length
                                                        ? `${layouts["md"]?.length}`
                                                        : "0",
                                                      x: 0,
                                                      y:
                                                        layouts["md"].reduce(
                                                          (sum, layout) =>
                                                            sum + layout.h,
                                                          0
                                                        ) + 6,
                                                      w: 5, // Set the width to the number of columns in lg
                                                      h: 12,
                                                    },
                                                  ],

                                                  ["sm"]: layouts["sm"] && [
                                                    ...layouts["sm"],
                                                    {
                                                      i: layouts["sm"]?.length
                                                        ? `${layouts["sm"]?.length}`
                                                        : "0",
                                                      x: 0,
                                                      y:
                                                        layouts["sm"].reduce(
                                                          (sum, layout) =>
                                                            sum + layout.h,
                                                          0
                                                        ) + 6,
                                                      w: 3, // Set the width to the number of columns in lg
                                                      h: 12,
                                                    },
                                                  ],

                                                  ["xs"]: layouts["xs"] && [
                                                    ...layouts["xs"],
                                                    {
                                                      i: layouts["xs"]?.length
                                                        ? `${layouts["xs"]?.length}`
                                                        : "0",
                                                      x: 0,
                                                      y:
                                                        layouts["xs"].reduce(
                                                          (sum, layout) =>
                                                            sum + layout.h,
                                                          0
                                                        ) + 6,
                                                      w: 3, // Set the width to the number of columns in lg
                                                      h: 12,
                                                    },
                                                  ],

                                                  ["xxs"]: layouts["xxs"] && [
                                                    ...layouts["xxs"],
                                                    {
                                                      i: layouts["xxs"]?.length
                                                        ? `${layouts["xxs"]?.length}`
                                                        : "0",
                                                      x: 0,
                                                      y:
                                                        layouts["xxs"].reduce(
                                                          (sum, layout) =>
                                                            sum + layout.h,
                                                          0
                                                        ) + 6,
                                                      w: 3, // Set the width to the number of columns in lg
                                                      h: 12,
                                                    },
                                                  ],
                                                };

                                                setLayouts(newLayouts);

                                                let newReports;

                                                if (reports) {
                                                  newReports = [
                                                    ...reports,
                                                    {
                                                      id: reports.length,
                                                      reportID: JSON.parse(
                                                        e.target.value
                                                      )?.report_id,
                                                    },
                                                  ];
                                                } else {
                                                  newReports = [
                                                    {
                                                      id: 0,
                                                      reportID: JSON.parse(
                                                        e.target.value
                                                      )?.report_id,
                                                    },
                                                  ];
                                                }

                                                setReports(newReports);
                                              } else {
                                                const previousLayouts = {
                                                  ...layouts,
                                                };
                                                const previousReports = [
                                                  ...reports,
                                                ];

                                                const newReports =
                                                  previousReports
                                                    .filter(
                                                      (item) =>
                                                        item.reportID !==
                                                        report.report_id
                                                    )
                                                    .map((item, index) => {
                                                      return {
                                                        ...item,
                                                        id: index,
                                                      };
                                                    });

                                                const idOfReportLayout =
                                                  previousReports.find(
                                                    (obj) =>
                                                      obj.reportID ===
                                                      report.report_id
                                                  )?.id;

                                                const newLayouts = {
                                                  ...previousLayouts,
                                                  ["lg"]: [
                                                    ...previousLayouts[
                                                      "lg"
                                                    ].filter(
                                                      (item) =>
                                                        item.i !==
                                                        idOfReportLayout.toString()
                                                    ),
                                                  ].map((item, index) => {
                                                    return {
                                                      ...item,
                                                      i: index.toString(),
                                                    };
                                                  }),
                                                  ["sm"]: [
                                                    ...previousLayouts[
                                                      "sm"
                                                    ].filter(
                                                      (item) =>
                                                        item.i !==
                                                        idOfReportLayout.toString()
                                                    ),
                                                  ].map((item, index) => {
                                                    return {
                                                      ...item,
                                                      i: index.toString(),
                                                    };
                                                  }),
                                                  ["md"]: [
                                                    ...previousLayouts[
                                                      "md"
                                                    ].filter(
                                                      (item) =>
                                                        item.i !==
                                                        idOfReportLayout.toString()
                                                    ),
                                                  ].map((item, index) => {
                                                    return {
                                                      ...item,
                                                      i: index.toString(),
                                                    };
                                                  }),
                                                  ["xs"]: [
                                                    ...previousLayouts[
                                                      "xs"
                                                    ].filter(
                                                      (item) =>
                                                        item.i !==
                                                        idOfReportLayout.toString()
                                                    ),
                                                  ].map((item, index) => {
                                                    return {
                                                      ...item,
                                                      i: index.toString(),
                                                    };
                                                  }),
                                                };

                                                setReports(newReports);
                                                setLayouts(newLayouts);
                                              }
                                              props.onClose();
                                            }}
                                            checked={props.reportsSelected?.some(
                                              (choosenReport) =>
                                                choosenReport.reportID ===
                                                report.report_id
                                            )}
                                            sx={{
                                              "& .MuiSvgIcon-root": {
                                                fontSize: "16px", // Change the size of the checkbox icon
                                                color: "#86aeb6",
                                              },
                                              "& .MuiTypography-root": {
                                                color: "#86aeb6", // Change the color of the label text
                                              },
                                            }}
                                          />
                                        }
                                        label={report.report_name}
                                      />
                                    </Grid>
                                  ))}
                              </AccordionDetails>
                            </Accordion>
                          );
                        }
                      )}
                    </Grid>
                  </Box>
                </Box>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </Grid>
      )
    );
  }
  const componentRef = useRef();

  const [confirmDialog, setConfirmDialog] = useState(false);

  const deleteFile = () => {
    props
      .dispatch(
        DIYReportTemplateAction.requestDIYDeleteReportTemplate(
          templateSelected?.template_id
        )
      )
      .then(() => {
        setTemplateSelected(null);
        fetchDIYreportTemplates();
        setConfirmDialog(false);
      });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const a4SheetWidthInMM = 210; // Example for 300 DPI

  const onDrop = (layout, layoutItem, _ev) => {
    const newItem = {
      ...layoutItem,
      y: Math.max(...layout.map((item) => item.y + item.h), 0),
    };

    setLayouts({
      ...layouts,
      [currentBreakpoint]: [...layout, newItem],
    });

    alert(
      `Element parameters:\n${JSON.stringify(newItem, ["x", "y", "w", "h"], 2)}`
    );
  };

  const isDraggable = window.location.pathname === "/socialListeningDiyReport";

  // Set the data-dashboard attribute on the body
  document.body.setAttribute("data-dashboard", isDraggable);

  const summaryBoxRef = useRef(null);

  useEffect(() => {
    const calculateHeights = () => {
      const outerBoxHeight = summaryBoxRef.current.clientHeight;

      if (
        summaryBoxRef.current &&
        summaryBoxRef.current.children[1] &&
        summaryBoxRef.current.children[0]
      ) {
        summaryBoxRef.current.children[0].style.height = `50px`;
        summaryBoxRef.current.children[1].style.height = `${
          outerBoxHeight - 50
        }px`;
      }
    };

    calculateHeights();

    // Use ResizeObserver to listen for changes in the size of summaryBoxRef
    const resizeObserver = new ResizeObserver(calculateHeights);
    resizeObserver.observe(summaryBoxRef.current);

    // Cleanup ResizeObserver on component unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []); // Empty dependency array to ensure the effect runs only once on mount

  const [downloading, setDownloading] = useState(false);

  // const handleDownload = async () => {
  //   setDownloading(true);
  //   const pdf = new jsPDF();
  //   let currentPage = 1;
  //   let currentY = 0;
  //   let currentX = 0;
  //   let accumulatedHeightInMM = 0;

  //   const reportsContainer = document.getElementById("reportContainer");
  //   const reportElements =
  //     reportsContainer?.querySelectorAll(".report-element");

  //   for (const reportElement of reportElements) {
  //     const reportHeight = reportElement.offsetHeight;
  //     const reportWidth = reportElement.offsetWidth;

  //     const dpi = 96;
  //     const reportHeightInMM = (reportHeight * 25.4) / dpi;
  //     const reportWidthInMM = (reportWidth * 25.4) / dpi;

  //     // Adjust the logic for moving to the next line or next page
  //     if (currentX + reportWidthInMM > pdf.internal.pageSize.getWidth()) {
  //       currentX = 0;
  //       currentY += accumulatedHeightInMM; // Use accumulated height
  //       accumulatedHeightInMM = 0;
  //     }

  //     // Adjust the logic for moving to the next page
  //     if (currentY + reportHeightInMM > pdf.internal.pageSize.getHeight()) {
  //       pdf.addPage();
  //       currentPage++;
  //       currentY = 0;
  //     }

  //     // Add the report to the PDF
  //     const canvas = await html2canvas(reportElement);
  //     const imgData = canvas.toDataURL("image/png");
  //     pdf.addImage(imgData, "PNG", currentX, currentY);

  //     // Update coordinates and accumulated height
  //     currentX += reportWidthInMM;
  //     accumulatedHeightInMM = Math.max(accumulatedHeightInMM, reportHeightInMM);
  //   }

  //   pdf.save("reports.pdf");
  //   setDownloading(false);
  // };

  const SinglePageReportDownload = async () => {
    setDownloading(true);
    const pdf = new jsPDF();
    let currentPage = 1;

    const reportsContainer = document.getElementById("reportContainer");
    const reportElements =
      reportsContainer?.querySelectorAll(".report-element");

    // Define margin
    const margin = 10; // 10mm margin
    const dpi = 96;

    for (let num = 0; num < reportElements.length; num++) {
      let reportElement = reportElements[num];

      const reportWidth = reportElement.offsetWidth;
      const reportHeight = reportElement.offsetHeight;
      const reportWidthInMM = (reportWidth * 25.4) / dpi;
      const reportHeightInMM = (reportHeight * 25.4) / dpi;

      // Calculate dimensions with margin
      const availableWidth = pdf.internal.pageSize.getWidth() - margin * 2;
      const availableHeight = pdf.internal.pageSize.getHeight() - margin * 2;

      const scale = Math.min(
        availableWidth / reportWidthInMM,
        availableHeight / reportHeightInMM
      );

      const canvas = await html2canvas(reportElement);
      const imgData = canvas.toDataURL("image/png");

      // Center the image within the margins
      const xPos = margin;
      const yPos = margin;

      // Adjust the width and height based on scaling
      const imgWidth = reportWidthInMM * scale;
      const imgHeight = reportHeightInMM * scale;

      // Add the image to the PDF with margins
      pdf.addImage(imgData, "PNG", xPos, yPos, imgWidth, imgHeight);

      if (num < reportElements.length - 1) {
        pdf.addPage();
        currentPage++;
      }
    }

    pdf.save("reports.pdf");
    setDownloading(false);
  };

  return (
    <Box
      ref={summaryBoxRef}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: "12",
        paddingBottom: "20px",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid
          item
          container
          alignItems={"center"}
          justifyContent="space-between"
          style={{
            position: "sticky",
            top: `${boxRef?.current?.offsetTop}px`,
            backgroundColor: theme.palette.background.paper,
            zIndex: 10,
            boxShadow: theme.shadows,
          }}
          // className="bg-warning"
        >
          <Grid
            item
            container
            lg={6}
            md={6}
            sm={12}
            px={1}
            sx={{ display: "flex", gap: 2 }}
            alignItems="center"
          >
            <AutoCompleteComponent
              value={templateSelected}
              list={reportTemplateList}
              textFieldProps={{
                placeholder: "Select template",
              }}
              getOptionLabel={(option) => option.template_name}
              isOptionEqualToValue={(option, value) =>
                option && option.template_name === value && value.template_name
              }
              handleChange={(e, v) => {
                setTemplateSelected(v);
              }}
              size="small"
              width={250}
            />

            {reports && reports.length !== 0 && (
              <Grid item alignItems={"center"} justifyContent="center">
                <Grid>
                  No of Reports :{" "}
                  {true && (
                    <Chip
                      label={reports.length}
                      variant="outlined"
                      size="small"
                    />
                  )}
                </Grid>
              </Grid>
            )}

            {/*  {templateSelected &&
              templateSelected?.template_id !== "demo_static_report_id" && (
                <>
                  <IconButton
                    title="Copy Template"
                    onClick={() => {
                      setPostdialogOpen(true);
                    }}
                  >
                    <CopyAllIcon color={theme.palette.primary.main} />
                  </IconButton>

                  <IconButton
                    title="Delete Template"
                    onClick={() => {
                      setConfirmDialog(true);
                    }}
                  >
                    <DeleteIcon color={theme.palette.primary.main} />
                  </IconButton>
                </>
              )}*/}
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            display={"flex"}
            justifyContent="flex-end"
            pt={2}
            pb={1}
            px={4.5}
            sx={{ gap: 2 }}
          >
            {!pdfViewOpen && (
              <div>
                {breakPoints
                  .sort((a, b) => b.width - a.width)
                  .map((item) => (
                    <IconButton
                      onClick={() => {
                        setSelectedBreakPoint(item);
                      }}
                      sx={{
                        backgroundColor:
                          selectedBreakPoint?.width === item.width
                            ? theme.palette.primary.main
                            : "none",
                      }}
                    >
                      <Typography
                        component={"div"}
                        sx={{
                          color:
                            selectedBreakPoint?.width === item.width
                              ? "white"
                              : theme.palette.primary.main,
                        }}
                      >
                        {item.label}
                      </Typography>
                    </IconButton>
                  ))}
              </div>
            )}
            {((!pdfViewOpen &&
              templateSelected?.template_id !== "demo_static_report_id") ||
              (!pdfViewOpen && !templateSelected)) && (
              <MuiButton
                onClick={() => {
                  setOpenReportChooseDialog(!openReportChooseDialog);
                }}
                name={"Add Report Component"}
                size={"small"}
                disabled={props.isRequesting}
              />
            )}

            {((!pdfViewOpen &&
              templateSelected?.template_id !== "demo_static_report_id") ||
              (!pdfViewOpen && !templateSelected)) && (
              <MuiButton
                onClick={() => {
                  if (reports?.length) {
                    if (templateSelected) {
                      const reportLayout = {
                        layout: layouts,
                        reports: reports,
                      };

                      const post_data = {
                        template: reportLayout,
                      };

                      props
                        .dispatch(
                          DIYReportTemplateAction.requestDIYPutReportTemplate(
                            post_data,
                            templateSelected?.template_id
                          )
                        )
                        .then(() => {
                          fetchDIYreportTemplates();
                        });
                    } else {
                      setPostdialogOpen(true);
                    }
                  } else {
                    alert(
                      "Please select atleast a report or Create a new template"
                    );
                  }
                }}
                name={"Save Template"}
                size={"small"}
              />
            )}

            {reports &&
              reports?.length !== 0 &&
              templateSelected?.template_id !== "demo_static_report_id" && (
                <MuiButton
                  name={pdfViewOpen ? "Report View" : "PDF View"}
                  onClick={() => {
                    setPdfViewOpen(!pdfViewOpen);
                    LayoutChangePDFView();
                  }}
                  size={"small"}
                  // disabled={selectedReports.length > 0 ? false : true}
                />
              )}

            {/* {reports && reports?.length !== 0 && pdfViewOpen && (
              <MuiButton
                name={"Save as PDF"}
                onClick={handlePrint}
                size={"small"}
                // disabled={selectedReports.length > 0 ? false : true}
              />
            )} */}
            {pdfViewOpen && reports && reports?.length !== 0 && (
              <MuiButton
                loading={downloading}
                name={"Download Report"}
                onClick={SinglePageReportDownload}
                size={"small"}
              />
            )}
          </Grid>
        </Grid>
      </Box>

      <Box
        className="scrollable"
        sx={{
          paddingY: 3,
          overflowY: "scroll",
          "& .MuiDataGrid-virtualScroller": {
            "&::-webkit-scrollbar": {
              width: "3px",
              height: "3px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#b0d2da",
              borderRadius: "3px",
              "&:hover": {
                backgroundColor: "#85bac6",
              },
            },
          },
        }}
      >
        {!pdfViewOpen && (
          <Box
            sx={{
              width: "max-content",
              margin: "auto",
              height: "100%",
            }}
          >
            <ResponsiveReactGridLayout
              style={{
                width: selectedBreakPoint?.width + 1 || "100%",
                border:
                  selectedBreakPoint?.label !== "YS"
                    ? `1px solid ${theme.palette.primary.light}`
                    : "none",
              }}
              {...props}
              margin={[5, 5]}
              layouts={layouts}
              measureBeforeMount={false}
              useCSSTransforms={mounted}
              compactType={compactType}
              preventCollision={!compactType}
              onLayoutChange={onLayoutChange}
              onBreakpointChange={onBreakpointChange}
              // draggable
              onDragStart={(_, v) => {
                setDragging(true);
                setDraggingItem(v);
              }}
              // onDrop={onDrop}
              onDragStop={() => {
                setDragging(false);
                setDraggingItem(null);
              }}
            >
              {layouts && reports && generateDOMforReport()}
            </ResponsiveReactGridLayout>
          </Box>
        )}
        {pdfViewOpen && (
          <Box
            id={"reportContainer"}
            sx={{
              height: "auto",
              width: `${a4SheetWidthInMM}mm`,
              // height: `${a4SheetHeightInPixels}px`,
              margin: "auto",
              backgroundColor: "white",
              boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
              border: "dotted 1px #d3e2e6",
            }}
            ref={componentRef}
          >
            <ResponsiveReactGridLayout
              style={{
                width: `${a4SheetWidthInMM}mm`,
                // height: `1380px`,
                overflow: "hidden",
                // margin: 15,
                padding: 15,
              }}
              {...props}
              layouts={layouts}
              measureBeforeMount={false}
              useCSSTransforms={mounted}
              compactType={compactType}
              preventCollision={!compactType}
              // onLayoutChange={onLayoutChange}
              onBreakpointChange={onBreakpointChange}
              // draggable
              onDragStart={(_, v) => {
                setDragging(true);
                setDraggingItem(v);
              }}
              // onDrop={onDrop}
              onDragStop={() => {
                setDragging(false);
                setDraggingItem(null);
              }}
            >
              {generateDOMforPdf()}
            </ResponsiveReactGridLayout>
          </Box>
        )}
      </Box>

      <DetailedReportScreen
        open={detailReportOpen}
        onClose={() => {
          setDetailReportOpen(false);
        }}
        report={detailReportId}
        platform_list={platform_list}
        activity_list={activity_list}
        statusList={statusList}
        userList={userList}
        setDetailReportOpen={setDetailReportOpen}
        setDetailReportId={setDetailReportId}
        detailReportOpen={detailReportOpen}
        account_list={account_list}
      />

      <YesOrNoDailog
        open={Boolean(confirmDialog)}
        content={"Are you sure you want to delete ?"}
        cancel={() => {
          setConfirmDialog(false);
        }}
        submit={() => {
          deleteFile();
        }}
      />

      <SelectReports
        open={openReportChooseDialog}
        onClose={() => {
          setOpenReportChooseDialog(false);
        }}
        reportsSelected={reports}
      />

      <DiyReportPostForm
        open={postdialogOpen}
        onClose={() => {
          setPostdialogOpen(false);
        }}
        template={{
          layout: layouts,
          reports: reports,
        }}
      />
    </Box>
  );
};

export default connect(mapStateToProps)(SocialLIsteningDIYReport);

SocialLIsteningDIYReport.defaultProps = {
  className: "layout",
  rowHeight: 30,
  overflow: "hidden",
  onLayoutChange: (layout, layouts) => {},
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  containerPadding: [0, 0],
  resizeHandles: ["se", "ne", "sw", "nw"],
};
