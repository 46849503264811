import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { connect } from "react-redux";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import TwitterAction from "../../../stores/twitterFeeds/TwitterAction";
import InstagramFeedsAction from "../../../stores/instagramFeeds/InstagramFeedsAction";
import { BorderContainer } from "../../../../components/componentLayout/ComponentLayout";
import AutoCompleteComponent from "../../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../../components/muiButton/MuiButton";

const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    TwitterAction.REQUEST_TWITTER,
    TwitterAction.REQUEST_TWITTER_FILTER,
    InstagramFeedsAction.REQUEST_INSTAGRAM_FEEDS,
    InstagramFeedsAction.REQUEST_INSTAGRAM_FILTER,
  ]),
});

function AccountNamePopper(props) {
  let { isRequesting } = props;

  //const [accountName, setAccountName] = useState(null);
  const [page_limit] = useState(10);

  const [accountName, setAccountName] = useState(() => {
    if (localStorage.getItem("competatorAnalysisDataParameters")) {
      return JSON.parse(
        localStorage.getItem("competatorAnalysisDataParameters")
      ).accountName;
    } else {
      return null;
    }
  });

  useEffect(() => {}, [props.open]);

  // Sentimentvalue to callback function
  const accountNameHandleChange = (event, value) => {
    setAccountName(value);
  };

  //****************** dispatch functions   *******************
  function fetchTwitter(params) {
    props.dispatch(TwitterAction.requestTwitter(params));
  }

  //******************SAVE FILTERS FUNCTION************************
  async function handleSaveFilters() {
    let filters = JSON.parse(
      localStorage.getItem("competatorAnalysisDataParameters")
    );
    filters.accountName = accountName;
    localStorage.setItem(
      "competatorAnalysisDataParameters",
      JSON.stringify(filters)
    );

    props.fetchTicketCount();

    let object_new = {};

    Object.assign(
      object_new,
      props.filterContents
        ? {
            ...props.filterContents,
            accountName: accountName,
          }
        : {
            accountName: accountName,
          }
    );

    props.setFilterContents(
      props.filterContents
        ? {
            ...props.filterContents,
            accountName: accountName,
          }
        : {
            accountName: accountName,
          }
    );

    props.applyFilter(object_new);
  }

  function handleClearFilters() {
    if (accountName !== null) {
      setAccountName(null);
    }
    let filters = JSON.parse(
      localStorage.getItem("competatorAnalysisDataParameters")
    );
    filters.accountName = null;
    localStorage.setItem(
      "competatorAnalysisDataParameters",
      JSON.stringify(filters)
    );
    const params = {
      order_by: '[["created", "desc"]]',
      page_limit: `${page_limit}`,
    };
  }

  function applyFilter() {
    let params = {};
    const filter = [];

    if (accountName !== null) {
      filter.push(
        `["competator_account_name.keyword", "must", "", "match", "${accountName}"]`
      );
    }

    params = {
      filters: `[${filter}]`,
      page_number: 0,
      page_limit: `${page_limit}`,
      order_by: '[["created", "desc"]]',
    };
  }

  return (
    <Box>
      <BorderContainer>
        <ClickAwayListener onClickAway={props.handleClose}>
          <div
            autoFocusItem={props.popopen}
            id="composition-menu"
            aria-labelledby="composition-button"
          >
            <Grid
              container
              // className="bg-warning"
              sx={{
                height: 250,
              }}
            >
              <Typography
                component={"p"}
                className="dashboard-title"
                sx={{ fontSize: 18 }}
              >
                {"Brand Compitator Account Name".split(" ").map((item) => (
                  <span className="dashboard-title">{item}</span>
                ))}
              </Typography>

              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                laptop={12}
                columnGap={2}
                rowGap={1}
                sx={{
                  marginTop: 2,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid item xl={3}>
                  <AutoCompleteComponent
                    value={accountName}
                    list={props.accountName}
                    size="small"
                    textFieldProps={{
                      placeholder: "Brand CompitatorAccount Name",
                    }}
                    disableClearable={true}
                    getOptionLabel={(option) => (option ? option : "")}
                    handleChange={accountNameHandleChange}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                laptop={12}
                columnGap={2}
                rowGap={1}
                sx={{
                  marginY: 5,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Grid item>
                  <MuiButton
                    variant="outlined"
                    width={"103px"}
                    height={"35px"}
                    type="submit"
                    name={"Reset"}
                    loading={false}
                    backgroundColor={"white"}
                    noTextOnLoading={true}
                    onClick={() => {
                      handleClearFilters();
                    }}
                  />
                </Grid>
                <Grid item>
                  <MuiButton
                    variant="contained"
                    width={"103px"}
                    height={"35px"}
                    type="submit"
                    name={"Apply"}
                    loading={false}
                    noTextOnLoading={true}
                    onClick={() => {
                      handleSaveFilters();
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </ClickAwayListener>
      </BorderContainer>
    </Box>
  );
}

export default connect(mapStateToProps)(AccountNamePopper);
