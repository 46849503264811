import { BaseModel } from "sjs-base-model";

export default class ClientRevenueBalanceGetModel extends BaseModel {
  balance = 0;
  total_claimed = 0;
  total_revenue = 0;
  advance_payment = false;
  balance_recoupment = 0;
  recouped = 0;
  total_advance_paid = 0;
  total_claimed_inr = 0;
  total_revenue_inr = 0;
  balance_inr = 0;
  constructor(data1) {
    super();

    this.update(data1);
  }
}
