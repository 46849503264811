import ActionUtility from "../../../utilities/ActionUtility";
import GoogleAlertConfigEffect from "./GoogleAlertConfigEffect";

export default class GoogleAlertConfigAction {
  // GoogleAlertConfigAction action with an function declaration
  static REQUEST_GOOGLE_ALERT_CONFIG =
    "GoogleAlertConfigAction.REQUEST_GOOGLE_ALERT_CONFIG";
  static REQUEST_GOOGLE_ALERT_CONFIG_FINISHED =
    "GoogleAlertConfigAction.REQUEST_GOOGLE_ALERT_CONFIG_FINISHED";

  static REQUEST_GOOGLE_ALERT_CONFIG_FILTER =
    "GoogleAlertConfigAction.REQUEST_GOOGLE_ALERT_CONFIG_FILTER";
  static REQUEST_GOOGLE_ALERT_CONFIG_FILTER_FINISHED =
    "GoogleAlertConfigAction.REQUEST_GOOGLE_ALERT_CONFIG_FILTER_FINISHED";

  static REQUEST_PUT_GOOGLE_ALERT_CONFIG =
    "GoogleAlertConfigAction.REQUEST_PUT_GOOGLE_ALERT_CONFIG";
  static REQUEST_PUT_GOOGLE_ALERT_CONFIG_FINISHED =
    "GoogleAlertConfigAction.REQUEST_PUT_GOOGLE_ALERT_CONFIG_FINISHED";

  static REQUEST_POST_GOOGLE_ALERT_CONFIG =
    "GoogleAlertConfigAction.REQUEST_POST_GOOGLE_ALERT_CONFIG";
  static REQUEST_POST_GOOGLE_ALERT_CONFIG_FINISHED =
    "GoogleAlertConfigAction.REQUEST_POST_GOOGLE_ALERT_CONFIG_FINISHED";

  static REQUEST_GOOGLE_ALERTS =
    "GoogleAlertConfigAction.REQUEST_GOOGLE_ALERTS";
  static REQUEST_GOOGLE_ALERTS_FINISHED =
    "GoogleAlertConfigAction.REQUEST_GOOGLE_ALERTS_FINISHED";

  // METHODS
  // GoogleAlertConfig GET function
  static requestGoogleAlertConfig(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GoogleAlertConfigAction.REQUEST_GOOGLE_ALERT_CONFIG,
        GoogleAlertConfigEffect.requestGoogleAlertConfig,
        params
      );
    };
  }

  static requestGoogleAlerts(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GoogleAlertConfigAction.REQUEST_GOOGLE_ALERTS,
        GoogleAlertConfigEffect.requestGoogleAlerts,
        params
      );
    };
  }

  static requestGoogleAlertConfigFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GoogleAlertConfigAction.REQUEST_GOOGLE_ALERT_CONFIG_FILTER,
        GoogleAlertConfigEffect.requestGoogleAlertConfigFilter,
        params
      );
    };
  }
  static requestPutGoogleAlertConfig(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GoogleAlertConfigAction.REQUEST_PUT_GOOGLE_ALERT_CONFIG,
        GoogleAlertConfigEffect.requestPutGoogleAlertConfig,
        data,
        id
      );
    };
  }

  // GoogleAlertConfigfunction
  static requestPostGoogleAlertConfig(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GoogleAlertConfigAction.REQUEST_POST_GOOGLE_ALERT_CONFIG,
        GoogleAlertConfigEffect.requestPostGoogleAlertConfig,
        data
      );
    };
  }
}
