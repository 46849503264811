import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { Box, Grid, ListItem, Tooltip, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { prefixZero_for_singleDigit } from "../../../components/ticketPartials";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import NoDataFound from "../../../components/nodatafound/NoDataFound";

function SenrimentsByTicketsCategory(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [platformwiseTickets, setPlatformwiseTickets] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchPlatformwiseTicketsByCategory(props.DateFilter);
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.DateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchPlatformwiseTicketsByCategory(dateRange) {
    let API = `${
      environment.api.sentimentalAnalysisReports
    }?report_type=["count"]&reports=["sentiment_analysis_report_per_category"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    try {
      const res = axiosRequest(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      const response = await res;
      if (response.hasOwnProperty("message")) {
        setPlatformwiseTickets(null);
        setApiError(response.message);
      } else {
        setPlatformwiseTickets(response);
        setApiError(null);
      }
    } catch (err) {
      setPlatformwiseTickets(null);
      setApiError(err.message);
      setLoading(false);
    }
  }

  let columns = [];

  columns.push(
    // { field: "id", headerName: "Sl", resizable: false, width: 50, hide: true },
    {
      field: "Category",
      headerName: "Category",
      resizable: true,
      hide: false,
      flex: 0.5,
      renderCell: (params) => {
        const Category = params?.row?.Category || {}; // Safely get platforms

        return (
          <Typography
            className={`${
              params.row.Category.startsWith("Total")
                ? "font-weight-bold p-0 m-0"
                : "font-weight-normal p-0 m-0"
            }`}
          >
            {Category}
          </Typography>
        );
      },
    },

    {
      field: "positive_sentiment",
      headerName: "Positive",
      resizable: true,
      hide: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Typography
            className={`${
              params.row.Category.startsWith("Total")
                ? "font-weight-bold p-0 m-0"
                : "font-weight-normal p-0 m-0"
            }`}
          >
            {params?.row?.positive_sentiment}
          </Typography>
        );
      },
    },

    {
      field: "negative_sentiment",
      headerName: "Negative",
      resizable: true,
      hide: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Typography
            className={`${
              params.row.Category.startsWith("Total")
                ? "font-weight-bold p-0 m-0"
                : "font-weight-normal p-0 m-0"
            }`}
          >
            {params?.row?.negative_sentiment}
          </Typography>
        );
      },
    },
    {
      field: "neutral_sentiment",
      headerName: "Neutral",
      resizable: true,
      hide: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Typography
            className={`${
              params.row.Category.startsWith("Total")
                ? "font-weight-bold p-0 m-0"
                : "font-weight-normal p-0 m-0"
            }`}
          >
            {params?.row?.neutral_sentiment}
          </Typography>
        );
      },
    }
  );

  let updatedRows = [];

  platformwiseTickets?.sentiment_analysis_report?.sentiment_analysis_report_per_category[0]?.children?.map(
    (item, index) => {
      updatedRows?.push({
        id: index + 1,
        Category: item?.value
          ? `${item?.value} (${item?.count})`
          : ` (${item?.count})`,
        positive_sentiment: prefixZero_for_singleDigit(
          item?.sentiments?.positive_sentiment
        ),
        negative_sentiment: prefixZero_for_singleDigit(
          item?.sentiments?.negative_sentiment
        ),
        neutral_sentiment: prefixZero_for_singleDigit(
          item?.sentiments?.neutral_sentiment
        ),
      });
    }
  );
  const totalRow = {
    id: updatedRows.length + 1,
    Category: `Total (${platformwiseTickets?.sentiment_analysis_report?.sentiment_analysis_report_per_category[1]?.total_tickets})`,
    positive_sentiment: prefixZero_for_singleDigit(
      platformwiseTickets?.sentiment_analysis_report
        ?.sentiment_analysis_report_per_category[1]?.positive_sentiment
    ),
    negative_sentiment: prefixZero_for_singleDigit(
      platformwiseTickets?.sentiment_analysis_report
        ?.sentiment_analysis_report_per_category[1]?.negative_sentiment
    ),
    neutral_sentiment: prefixZero_for_singleDigit(
      platformwiseTickets?.sentiment_analysis_report
        ?.sentiment_analysis_report_per_category[1]?.neutral_sentiment
    ),
  };
  platformwiseTickets?.sentiment_analysis_report
    ?.sentiment_analysis_report_per_category[0]?.children?.length > 0 &&
    updatedRows.push(totalRow);
  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      updatedRows?.map((item, index) => {
        exportData?.push({
          Category: item?.Category,
          ["Positive"]: item?.positive_sentiment,
          ["Negative"]: item?.negative_sentiment,
          ["Neutral"]: item?.neutral_sentiment,
        });
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, updatedRows]);

  return props.dataGridHeight ? (
    <LoadingIndicator isActive={loading}>
      <Box height={"100%"}>
        {!loading ? (
          <Box height={"100%"}>
            {apiError ? (
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  flex: "12",
                }}
              >
                {apiError}
              </Box>
            ) : !loading &&
              updatedRows.length > 0 &&
              props.dataGridHeight.current ? (
              <Box
                sx={{
                  padding: "12px",
                  height: "100%",
                }}
              >
                <Grid
                  item
                  container
                  lg={12}
                  md={12}
                  sm={12}
                  sx={{ height: 400 }}
                >
                  {props.dataGridHeight.current && (
                    <DataGridFunction
                      rows={updatedRows}
                      columns={columns}
                      height={props.dataGridHeight.current}
                      rowHeight={50}
                      headerHeight={50}
                      onCellClick={onCellClick}
                      rowBorder={false}
                      rowColor={true}
                      headerColor={true}
                    />
                  )}
                </Grid>
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  ) : null;
}

export default SenrimentsByTicketsCategory;
