import React, { forwardRef } from "react";
import { fieldToTextField } from "formik-material-ui";
import { Autocomplete, Paper, TextField } from "@mui/material";
import { theme } from "../../views/App";
import { makeStyles } from "@material-ui/core";

const CustomListbox = forwardRef((props, ref) => (
  <Paper
    {...props}
    ref={ref}
    sx={{
      // maxHeight: 200,
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#b0d2da",
        borderRadius: "3px",
        "&:hover": {
          backgroundColor: "#85bac6",
        },
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
      color: props.dropDownFontColor,
    }}
  />
));

const AutoCompleteMultiSelect = ({
  disabled,
  readOnly,
  disableCloseOnSelect,
  diasbleSvg,
  disableClearable,
  textFieldProps,
  size,
  limitTags,
  fieldValue,
  renderOption,
  filterOptions,
  onChange,
  margin,
  svgColor,
  helperTextfromPropsDisabled,
  ...props
}) => {
  const useStyles = makeStyles({
    root: {
      border: `1px solid ${theme.palette.primary.main}`,
      marginTop: 2,
      marginBottom: 2,
      zIndex: 1,
      overflow: "hidden",
    },
  });

  const {
    form: { setTouched, setFieldValue, touched },
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();

  return (
    <Autocomplete
      classes={{
        paper: classes.root,
      }}
      style={{ zIndex: 1 }}
      disableCloseOnSelect={disableCloseOnSelect}
      disableClearable={disableClearable}
      readOnly={readOnly ? readOnly : false}
      {...props}
      {...field}
      renderOption={renderOption}
      ListboxComponent={(listboxProps) => (
        <CustomListbox
          {...listboxProps}
          dropDownFontColor={props.dropDownFontColor}
        />
      )}
      limitTags={limitTags ? limitTags : 3}
      disabled={disabled}
      onChange={
        onChange
          ? onChange
          : (_, value) => {
              setFieldValue(name, value);
            }
      }
      filterOptions={filterOptions}
      // isOptionEqualToValue={(option, value) =>
      //   //setting value property to custom value
      //   option.project_name === value.project_name
      // }
      // //setting custom label to custom value
      // getOptionLabel={(option) => option.project_name}
      onBlur={() => setTouched({ ...touched, [name]: true })}
      getOptionSelected={(item, current) => item.value === current.value}
      renderInput={(props) => (
        <TextField
          InputLabelProps={{ shrink: true }}
          {...props}
          {...textFieldProps}
          helperText={helperTextfromPropsDisabled ? null : helperText}
          error={error}
          size={size}
          sx={{
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
            svg: {
              display: diasbleSvg ? diasbleSvg : "block",
              color: svgColor ? svgColor : "#bab8b8",
            },
            margin: margin,
          }}
        />
      )}
    />
  );
};

export default AutoCompleteMultiSelect;
