import React from "react";
import SignIn from "../../views/Login/SignIn";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { LogoutOutlined, PersonAddAlt1, Shop } from "@mui/icons-material";
import {
  CategoryRounded,
  Description,
  Home,
  LocalOffer,
  Person,
} from "@material-ui/icons";
import MyProfile from "../../views/myProfile/MyProfile";
import WelcomePage from "../../views/welcomePage/WelcomePage";
import Logout from "../../views/logout/Logout";
import UnderDevelopment from "../UnderDevelopment";
import TwitterCallback from "../oauth_callback/TwitterCallback";
import OpenFormProject from "../../views/projectDialog/ProjectFormDialog";
import GoogleCallback from "../oauth_callback/GoogleCallBack";
import YoutubeCallBack from "../oauth_callback/YoutubeCallBack";
import FacebookCallBack from "../oauth_callback/FacebookCallBack";
import PageNotFound from "../PageNotFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBlackTie, faGoogle } from "@fortawesome/free-brands-svg-icons";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import Reports from "../../views/reports/Reports";
import {
  faBusinessTime,
  faCircleNodes,
  faDiagramProject,
  faFolderTree,
  faGear,
  faGlobe,
  faGrip,
  faHandshake,
  faHashtag,
  faMagnifyingGlassChart,
  faNotesMedical,
  faPenRuler,
  faScrewdriverWrench,
  faSliders,
  faTag,
  faTicket,
  faTicketSimple,
  faUsers,
  faWeightScale,
} from "@fortawesome/free-solid-svg-icons";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import GMBcallBack from "../oauth_callback/GMBcallback";
import LinkedInCallback from "../oauth_callback/LinkedInCallback";
import DiscordCallBack from "../oauth_callback/DiscordCallbock";
import PinterestCallback from "../oauth_callback/PinterestCallback";
import Dashboard from "../../ormTicketing/views/dashboard/Dashboard";
import Tag from "../../ormTicketing/views/tag/Tag";
import CategoryList from "../../ormTicketing/views/categoryList/CategoryList";
import TicketType from "../../ormTicketing/views/ticketType/TicketType";
import MiscellaneousSettings from "../../ormTicketing/views/ticketSettings/MiscellaneousSettings";
import KeywordConfigurations from "../../ormTicketing/views/keywordConfig/KeywordConfigurations";
import StatusGridView from "../../ormTicketing/views/status/StatusGridView";
import TicketPriority from "../../ormTicketing/views/ticketPriority/TicketPriority";
import CustomTicketFields from "../../ormTicketing/views/customTicketFields/CustomTicketFields";
import AssignmentRule from "../../ormTicketing/views/assignment/AssignmentRule";
import CannedMessage from "../../ormTicketing/views/cannedMessage/CannedMessage";
import BrandCompitator from "../../ormTicketing/views/brandCompitator/BrandCompitator";
import ManageUser from "../../ormTicketing/views/manageUser/ManageUser";
import Organization from "../../common/views/organization/Organization";
import Client from "../../common/views/client/Client";
import ProjectList from "../../common/views/projectList/ProjectList";
import AddRole from "../../common/views/addRole/AddRole";
import UserRoleMappingList from "../../common/views/userRoleMapping/UserRoleMappingList";
import ManageUserPermission from "../../common/views/manageUserPermission/ManageUserPermission";
import AddAccount from "../../ormTicketing/views/addAccount/AddAccount";
import TaskScheduler from "../../ormTicketing/views/scheduler/TaskScheduler";
import CreateTicket from "../../ormTicketing/views/createTicket/CreateTicket";
import TicketsListView from "../../ormTicketing/views/ticketsListView/TicketsListView";
import MailTriggerLIst from "../../common/views/mailTriggerList/MailTriggerLIst";
import UserMailMapping from "../../common/views/userMailMapping/UserMailMapping";
import NotificationListView from "../../ormTicketing/views/notifications/NotificationListView";
import AiSettings from "../../common/views/aiSettings/AiSettings";
import PsychologyIcon from "@mui/icons-material/Psychology";
import {
  faFileWord,
  faShareFromSquare,
} from "@fortawesome/free-regular-svg-icons";
import ReportDetailedScreen from "../../ormTicketing/components/reportPdfTemplates/ReportDetailedScreen";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DIYReport from "../../ormTicketing/views/diyReport/DIYReport";
import Dragabledashboard from "../../ormTicketing/views/dashboard/Dragabledashboard";
import RedditCallback from "../oauth_callback/RedditCallback";
import Faq from "../../ormTicketing/views/faq/Faq";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import AutoResponse from "../../ormTicketing/views/autoResponse/AutoResponse";
import Permissions from "../../common/views/permissions/Permissions";
import PlatformPost from "../../ormTicketing/views/socialPosting/PlatformPost";
import InternetSpeedTest from "../../views/internetTest/InternetSpeedTest";
import KpiConfig from "../../ormTicketing/views/kpiConfig/KpiConfig";
import AutomationLogs from "../../ormTicketing/views/telegramReports/AutomationLogs";
import CompetatorsListview from "../../ormTicketing/views/competatorAnalysisData/CompetatorsListview";
import GoogleAlertConfiguration from "../../common/views/googleAlertConfig/GoogleAlertConfiguration";
import GoogleAlerts from "../../common/views/googleAlerts/GoogleAlerts";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import Mentions from "../../socialListening/views/Mentions";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import AbcIcon from "@mui/icons-material/Abc";
import { Navigate } from "react-router";
import Version from "../../ormTicketing/views/version/Version";
import SocialLIsteningDIYReport from "../../socialListening/views/SocialLIsteningDIYReport";
export const newroutes = async () => {
  let data = [
    //SIGN IN ROUTE DATA
    {
      category: "Auth",
      path: "/",
      element: <SignIn />,
      id: "sign",
      title: localStorage.getItem("Auth") ? null : "Sign In",
      icon: (
        <InboxIcon
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        />
      ),
      type: "item",
      roles: [],
    },

    {
      category: "Main",
      path: "/welcome",
      element: <WelcomePage />,
      id: "home",
      title: "Home",
      icon: (
        <Home
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        />
      ),
      type: "item",
      visibility: "hidden",
      hide: true,
      roles: [],
    },

    // OLD  Dashboard page
    // {
    //   category: "Main",
    //   path: "/dashboard",
    //   element: <Dashboard />,
    //   id: "dashboard",
    //   title: "Dashboard",
    //   icon: (
    //     <FontAwesomeIcon
    //       icon={faGrip}
    //       style={{
    //         width: 20,
    //         height: 20,
    //         opacity: 0.9,
    //       }}
    //     ></FontAwesomeIcon>
    //   ),
    //   type: "item",
    //   roles: [],
    // },

    // Dragable Dashboard page
    {
      category: "Main",
      path: "/dashboard",
      element: <Dragabledashboard />,
      id: "dashboard",
      title: "Dashboard",
      icon: (
        <FontAwesomeIcon
          icon={faGrip}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },

    //Organization List
    {
      category: "User Settings",
      path: "/organization",
      element: <Organization />,
      id: "organization",
      title: "Organization",
      icon: (
        <FontAwesomeIcon
          icon={faBusinessTime}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },

    //Trigger List
    {
      category: "User Settings",
      path: "/mailTriggerList",
      element: <MailTriggerLIst />,
      id: "mailTriggerList",
      title: "Mail Trigger List",
      icon: (
        <RecordVoiceOverIcon
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        />
      ),
      type: "item",
      roles: [],
    },

    // Email Trigger User Mapping
    {
      category: "User Settings",
      path: "/mailtriggerUserMapping",
      element: <UserMailMapping />,
      id: "mailtriggerUserMapping",
      title: "User Mail Subscription",
      icon: (
        <RecordVoiceOverIcon
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        />
      ),
      type: "item",
      roles: [],
    },

    {
      category: "User Settings",
      path: "/Client",
      element: <Client />,
      id: "client",
      title: "Client",
      icon: (
        <FontAwesomeIcon
          icon={faHandshake}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },

    // // Project page
    {
      category: "User Settings",
      path: "/project",
      element: <ProjectList />,
      id: "project",
      title: "Project",
      icon: (
        <FontAwesomeIcon
          icon={faDiagramProject}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },

    // //  Mange user
    {
      category: "User Settings",
      path: "/manageUser",
      element: <ManageUser />,
      id: "manageUser",
      title: "Manage User",
      icon: (
        <FontAwesomeIcon
          icon={faUsers}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },
    // //  Add Role
    {
      category: "User Settings",
      path: "/addRole",
      element: <AddRole />,
      id: "addRole",
      title: "Add Role",

      icon: (
        <FontAwesomeIcon
          icon={faBlackTie}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },
    // // User Role Mapping List
    {
      category: "User Settings",
      path: "/userRoleMapping",
      element: <UserRoleMappingList />,
      id: "userRoleMapping",
      title: "User Role Mapping",
      icon: (
        <PersonAddAlt1
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        />
      ),
      type: "item",
      roles: [],
    },
    // //  Mange user permissions
    {
      category: "User Settings",
      path: "/manageUserPermission",
      element: <ManageUserPermission />,
      id: "manageUserPermission",
      title: "Role Permission",
      icon: (
        <FontAwesomeIcon
          icon={faSliders}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },

    // //  Mange user permissions
    {
      category: "User Settings",
      path: "/Permissions",
      element: <Permissions />,
      id: "manageUserPermission",
      title: "Permissions",
      hide: true,
      icon: (
        <FontAwesomeIcon
          icon={faSliders}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },

    //Notifications List
    {
      category: "Main",
      path: `/notifications`,
      element: <NotificationListView />,
      id: "notifications",
      title: "Notifications",
      type: "item",
      roles: [],
      hide: true,
    },

    //Project Open Dialog page
    {
      category: "Logout",
      path: "/projectSelect",
      element: <OpenFormProject />,
      id: "projectSelect",
      title: "Select Project",
      icon: (
        <DashboardIcon
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        />
      ),
      type: "item",
      roles: [],
      visibility: "hidden",
      hide: true,
    },

    // TICKET REVIEWS PAGE ROUTE DATA
    {
      category: "Main",
      path: "/Tickets",
      element: <TicketsListView />,
      id: "ticketReviews",
      title: "Tickets",
      icon: (
        <FontAwesomeIcon
          className="p-0 m-0"
          icon={faTicket}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },

    //  ROUTE DATA
    {
      category: "no_auth",
      path: "/Version",
      element: <Version />,
      id: "Version",
      title: "Version",
      icon: (
        <FontAwesomeIcon
          className="p-0 m-0"
          icon={faTicket}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
      hide: true,
    },

    //CREATE TICKET ROUTE DATA
    {
      category: "Main",
      path: "/createTicket",
      element: <CreateTicket />,
      id: "create_ticket",
      title: "Create Ticket",
      icon: (
        <FontAwesomeIcon
          className="p-0 m-0"
          icon={faNotesMedical}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },

    {
      category: "Main",
      path: "/speedTest",
      element: <InternetSpeedTest />,
      id: "speedTest",
      title: "Speed Test",
      icon: (
        <FontAwesomeIcon
          className="p-0 m-0"
          icon={faGlobe}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },

    // ********Feeds pages**********
    {
      category: "Ticket Settings",
      path: "/tag",
      element: <Tag />,
      id: "tag",
      title: "Ticket Tag",
      // icon: <LocalOffer />,
      icon: (
        <FontAwesomeIcon
          className="p-0 m-0"
          icon={faTag}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },
    //status page
    {
      category: "Ticket Settings",
      path: "/status",
      element: <StatusGridView />,
      id: "status",
      title: "Ticket Status",
      icon: (
        <FontAwesomeIcon
          icon={faTicketSimple}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },
    // Ticket Priority Page
    {
      category: "Ticket Settings",
      path: "/priority",
      element: <TicketPriority />,
      id: "priority",
      title: "Ticket Priority",
      icon: (
        <FontAwesomeIcon
          icon={faTicketSimple}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },
    //custom fields
    {
      category: "Ticket Settings",
      path: "/customTicketFields",
      element: <CustomTicketFields />,
      id: "custom_ticket_fields",
      title: "Custom Fields",
      icon: (
        <FontAwesomeIcon
          icon={faFolderTree}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },
    {
      category: "Ticket Settings",
      path: "/cannedMessage",
      element: <CannedMessage />,
      id: "cannedMessage",
      title: "Canned Responses",
      icon: (
        <FontAwesomeIcon
          icon={faShareFromSquare}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },

    // category page
    {
      category: "Ticket Settings",
      path: "/category",
      element: <CategoryList />,
      id: "Category",
      title: "Ticket Category",
      icon: (
        <CategoryRounded
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        />
      ),
      type: "item",
      roles: [],
    },
    //ticket Type page
    {
      category: "Ticket Settings",
      path: "/ticketType",
      element: <TicketType />,
      id: "ticketType",
      title: "Ticket Type",
      icon: (
        <Description
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        />
      ),
      type: "item",
      roles: [],
    },

    //ticket Type page
    {
      category: "Workflow Settings",
      path: "/miscellaneousSettings",
      element: <MiscellaneousSettings />,
      id: "miscellaneous_settings",
      title: "SLA Settings",
      icon: (
        <FontAwesomeIcon
          icon={faScrewdriverWrench}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },

    {
      category: "Workflow Settings",
      path: "/automationLogs",
      element: <AutomationLogs />,
      id: "automationLogs",
      title: "Automation Logs",
      icon: (
        <FontAwesomeIcon
          icon={faScrewdriverWrench}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },

    {
      category: "Workflow Settings",
      path: "/assignment",
      element: <AssignmentRule />,
      id: "assignment",
      title: "Assignment Rule",
      icon: (
        <FontAwesomeIcon
          icon={faPenRuler}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },

    {
      category: "Workflow Settings",
      path: "/googleAlertConfig",
      element: <GoogleAlertConfiguration />,
      id: "googleAlertConfig",
      title: "Google Alert Config",
      icon: (
        <FontAwesomeIcon
          icon={faGear}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },

    {
      category: "Workflow Settings",
      path: "/googleAlerts",
      element: <GoogleAlerts />,
      id: "googleAlerts",
      title: "Google Alerts",
      icon: (
        <FontAwesomeIcon
          icon={faGoogle}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },

    {
      category: "Workflow Settings",
      path: "/orm-automation",
      element: <AutoResponse />,
      id: "auto-response",
      title: "ORM Automation",
      icon: (
        <SmartToyIcon
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        />
      ),
      type: "item",
      roles: [],
    },

    {
      category: "Settings",
      path: "/addAccount",
      element: <AddAccount />,
      id: "addAccount",
      title: "Platform Integration",
      // icon: <GroupAddIcon />,
      icon: (
        <FontAwesomeIcon
          icon={faCircleNodes}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },

    // keyword configuration
    {
      category: "Settings",
      path: "/keywordConfiguration",
      element: <KeywordConfigurations />,
      id: "keywordConfiguration",
      title: "Keyword Setup",
      icon: (
        <FontAwesomeIcon
          icon={faFileWord}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },

    // My Profile Page Route Data
    {
      category: "Main",
      path: "/myProfile",
      element: <MyProfile />,
      id: "myProfile_id",
      title: "My Profile",
      icon: (
        <Person
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        />
      ),
      type: "item",
      roles: [],
      visibility: "hidden",
      hide: true,
    },

    // Logout Page
    {
      category: "Logout",
      path: "/logout",
      element: <Logout />,
      id: "logout",
      title: "Logout",
      icon: (
        <LogoutOutlined
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        />
      ),
      type: "item",
      roles: [],
    },

    //under development page
    {
      category: "Main",
      path: "/underDevelopment",
      element: <UnderDevelopment />,
      id: "underDevelopment",
      title: "Under Development",
      icon: (
        <DoNotDisturbAltIcon
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        />
      ),
      type: "item",
      roles: [],
      visibility: "hidden",
      hide: true,
    },

    {
      category: "Main",
      path: "/twittercallback",
      element: <TwitterCallback />,
      id: "twittercallback",
      title: "Twitter Callback",
      icon: <Shop />,
      type: "item",
      roles: [],
      hide: true,
    },

    {
      category: "Main",
      path: "/reddit_oauth",
      element: <RedditCallback />,
      id: "redditcallback",
      title: "Redddit Callback",
      icon: <Shop />,
      type: "item",
      roles: [],
      hide: true,
    },
    {
      category: "Main",
      path: "/google_auth_login",
      element: <GoogleCallback />,
      id: "googlecallback",
      title: "Google Callback",
      icon: <Shop />,
      type: "item",
      roles: [],
      hide: true,
    },

    {
      category: "Main",
      path: "/youtube_auth_login",
      element: <YoutubeCallBack />,
      id: "youtubeCallBack",
      title: "Youtube Callback",
      icon: <Shop />,
      type: "item",
      roles: [],
      hide: true,
    },

    {
      category: "Main",
      path: "/facebook_auth_login",
      element: <FacebookCallBack />,
      id: "facebookCallBack",
      title: "Facebook Callback",
      icon: <Shop />,
      type: "item",
      roles: [],
      hide: true,
    },
    {
      category: "Main",
      path: "/gmb_auth_login",
      element: <GMBcallBack />,
      id: "gmbCallBack",
      title: "GMB Callback",
      icon: <Shop />,
      type: "item",
      roles: [],
      hide: true,
    },

    {
      category: "Main",
      path: "/linkedIn_auth_login",
      element: <LinkedInCallback />,
      id: "linkedInCallBack",
      title: "LinkedIn Callback",
      icon: <Shop />,
      type: "item",
      roles: [],
      hide: true,
    },

    {
      category: "Main",
      path: "/discord_auth_login",
      element: <DiscordCallBack />,
      id: "discordCallBack",
      title: "Discord Callback",
      icon: <Shop />,
      type: "item",
      roles: [],
      hide: true,
    },

    {
      category: "Main",
      path: "/pinterest_auth_login",
      element: <PinterestCallback />,
      id: "pinterestCallBack",
      title: "Pinterest Callback",
      icon: <Shop />,
      type: "item",
      roles: [],
      hide: true,
    },

    //Scheduler
    {
      category: "Settings",
      path: "/scheduler",
      element: <TaskScheduler />,
      id: "scheduler",
      title: "Scheduler",
      icon: (
        <FontAwesomeIcon
          icon={faWeightScale}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },

    {
      category: "Main",
      path: "*",
      element: <PageNotFound />,
      id: "pageNotFound",
      title: "Page Not Found",
      icon: <ScheduleIcon />,
      type: "item",
      roles: [],
      hide: true,
    },

    //reports
    {
      category: "Reports",
      path: "/reports",
      element: <Reports />,
      id: "reports",
      title: "Detailed Reports",
      icon: (
        <FontAwesomeIcon
          icon={faMagnifyingGlassChart}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },

    //reports
    {
      category: "Social_Posting",
      path: "/platformPost",
      element: <PlatformPost />,
      id: "platformPost",
      title: "Platform Post",
      icon: (
        <FontAwesomeIcon
          icon={faMagnifyingGlassChart}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },

    //ticket Stats reports

    // {
    //   category: "Reports",
    //   path: "/ticketStatsReport",
    //   element: <TicketStatsReport />,
    //   id: "ticketStatsreports",
    //   title: "DIY Dashboard",
    //   // icon: <RecordVoiceOverIcon />,
    //   icon: (
    //     <FontAwesomeIcon
    //       icon={faTv}
    //       style={{
    //         width: 20,
    //         height: 20,
    //         opacity: 0.9,
    //       }}
    //     ></FontAwesomeIcon>
    //   ),
    //   type: "item",
    //   roles: [],
    // },

    {
      category: "Reports",
      path: "/diy_report",
      element: <DIYReport />,
      id: "diy_report",
      title: "DIY Dashboard",
      icon: <AssessmentIcon />,
      type: "item",
      roles: [],
    },

    {
      category: "Reports",
      path: "/kpi-report-config",
      element: <KpiConfig />,
      id: "kpi-report-config",
      title: "KPI Report Config",
      icon: (
        <SmartToyIcon
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        />
      ),
      type: "item",
      roles: [],
    },

    {
      category: "Main",
      path: "/faq/:module/:topic/:query",
      element: <Faq />,
      id: "faq",
      title: "FAQ's",
      icon: <QuestionAnswerIcon />,
      type: "item",
      roles: [],
      hide: true,
    },
    {
      category: "Main",
      path: "/faq/:module/:topic",
      element: <Faq />,
      id: "faq",
      title: "FAQ's",
      icon: <QuestionAnswerIcon />,
      type: "item",
      roles: [],
      hide: true,
    },
    {
      category: "Main",
      path: "/faq/:module",
      element: <Faq />,
      id: "faq",
      title: "FAQ's",
      icon: <QuestionAnswerIcon />,
      type: "item",
      roles: [],
      hide: true,
    },
    {
      category: "Main",
      path: "/faq",
      element: <Faq />,
      id: "faq",
      title: "FAQ's",
      icon: <QuestionAnswerIcon />,
      type: "item",
      roles: [],
    },

    //frontEndResource
    // {
    //   category: "Settings",
    //   path: "/frontendResource",
    //   element: <FrontEndResourceList />,
    //   id: "frontEndResource",
    //   title: "Front End Resources",
    //   icon: <AddchartIcon />,
    //   type: "item",
    //   roles: [],
    // },

    //Page tagged posts
    // {
    //   category: "Feeds",
    //   path: "/pageTaggedPosts",
    //   element: <PageTaggedPosts />,
    //   id: "pageTaggedPosts",
    //   title: "Page Tagged Posts",
    //   icon: <AddTaskIcon />,
    //   type: "item",
    //   roles: [],
    // },

    // brand
    // {
    //   category: "Ticket Settings",
    //   path: "/brand_competitor_account",
    //   element: <BrandCompitator />,
    //   id: "brandCompetitor",
    //   title: "Brand Competitor",
    //   icon: (
    //     <LocalOffer
    //       style={{
    //         width: 20,
    //         height: 20,
    //         opacity: 0.9,
    //       }}
    //     />
    //   ),
    //   type: "item",
    //   roles: [],
    // },

    {
      category: "Main",
      path: "/ai_settings",
      element: <AiSettings />,
      id: "ai_settings",
      title: "AI settings",
      icon: <PsychologyIcon />,
      type: "item",
      roles: [],
    },

    {
      category: "Main",
      path: "/detailed_report",
      element: <ReportDetailedScreen />,
      id: "dashboard",
      title: "Detail Report",
      icon: <PsychologyIcon />,
      type: "item",
      roles: [],
      hide: true,
    },

    {
      category: "Social Listening",
      path: "/social_listen_dashboard",
      element: <Dragabledashboard />,
      id: "social_listen_dashboard",
      title: "Dashboard",
      icon: <SpaceDashboardIcon />,
      type: "item",
      roles: [],
    },

    {
      category: "Social Listening",
      path: "/social_listen_mention",
      element: <Mentions />,
      id: "social_listen_mention",
      title: "Mentions",
      icon: <SpeakerNotesIcon />,
      type: "item",
      roles: [],
    },

    {
      category: "Social Listening",
      path: "/brandCompetitor",
      element: <CompetatorsListview />,
      id: "brandCompetitor",
      title: "Competitors",
      icon: (
        <FontAwesomeIcon
          className="p-0 m-0"
          icon={faHashtag}
          style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
        ></FontAwesomeIcon>
      ),
      type: "item",
      roles: [],
    },
    {
      category: "Social Listening",
      path: "/social_listen_keyword_monitor",
      element: <Navigate to={"/keywordConfiguration"} />,
      id: "social_listen_keyword_monitor",
      title: "Keyword Monitoring",
      icon: <AbcIcon />,
      type: "item",
      roles: [],
    },

    {
      category: "Social Listening",
      path: "/socialListeningDiyReport",
      element: <SocialLIsteningDIYReport />,
      id: "socialListeningDiyReport",
      title: "DIY Report",
      icon: <AssessmentIcon />,
      type: "item",
      roles: [],
    },
  ];

  if (process.env.REACT_APP_SURVEY_ENABLED === "true") {
    await import("../../aiplex_tools_frontend_modular/survey/routes/Routes")
      .then((module) => {
        for (let i = 0; i < module.surveyRoute.length; i++) {
          data.push(module.surveyRoute[i]);
        }
      })
      .catch((err) => {
        console.log("ERROR_MOD", err);
      });
  }

  if (process.env.REACT_APP_DATAEXTRACTION_ENABLED === "true") {
    await import(
      "../../aiplex_tools_frontend_modular/dataExtraction/routes/Routes"
    ).then((module) => {
      for (let i = 0; i < module.dataExtractionRoute.length; i++) {
        data.push(module.dataExtractionRoute[i]);
      }
    });
  }

  // if (process.env.REACT_APP_CLOUDFARE === "true") {
  //   await import(
  //     "../../aiplex_tools_frontend_modular/antipiracySupport/routes/Routes"
  //   ).then((module) => {
  //     for (let i = 0; i < module.cloudfareRoute.length; i++) {
  //       data.push(module.cloudfareRoute[i]);
  //     }
  //   });
  // }

  if (process.env.REACT_APP_INFLUENCER_ENABLED === "true") {
    await import(
      "../../aiplex_tools_frontend_modular/influencer/routes/Routes"
    ).then((module) => {
      for (let i = 0; i < module.influencerRoute.length; i++) {
        data.push(module.influencerRoute[i]);
      }
    });
  }

  if (process.env.REACT_APP_SITE_DISCOVERY_ENABLED === "true") {
    await import(
      "../../aiplex_tools_frontend_modular/siteLinkDiscovery/routes/Routes"
    ).then((module) => {
      for (let i = 0; i < module.siteLinkDiscoveryRoute.length; i++) {
        data.push(module.siteLinkDiscoveryRoute[i]);
      }
    });
  }

  if (process.env.REACT_APP_LINK_CHECKING_ENABLED === "true") {
    await import(
      "../../aiplex_tools_frontend_modular/linkChecking/routes/Routes"
    ).then((module) => {
      for (let i = 0; i < module.linkCheckingRoutes.length; i++) {
        data.push(module.linkCheckingRoutes[i]);
      }
    });
  }

  if (process.env.REACT_APP_MCN_ENABLED === "true") {
    await import(
      "../../aiplex_tools_frontend_modular/youtube_mcn/routes/Routes"
    ).then((module) => {
      for (let i = 0; i < module.mcnRoutes.length; i++) {
        data.push(module.mcnRoutes[i]);
      }
    });
  }

  if (process.env.REACT_APP_CLOUD_MAIL_ENABLED === "true") {
    await import("../../cloudMail/routes/Routes").then((module) => {
      for (let i = 0; i < module.cloudMailRoutes.length; i++) {
        data.push(module.cloudMailRoutes[i]);
      }
    });
  }

  if (process.env.REACT_APP_OPEN_AI_ENABLED === "true") {
    await import("../../chatGpt/routes/Routes").then((module) => {
      for (let i = 0; i < module.chatGptRoutes.length; i++) {
        data.push(module.chatGptRoutes[i]);
      }
    });
  }

  if (process.env.REACT_APP_A3LABEL_ENABLED === "true") {
    await import("../../a3label_org_intranet/a3labels/routes/Routes")
      .then((module) => {
        for (let i = 0; i < module.a3LabelRoute.length; i++) {
          data.push(module.a3LabelRoute[i]);
        }
      })
      .catch((err) => {
        console.log("ERROR_MOD", err);
      });
  }

  if (process.env.REACT_APP_CRM_ENABLED === "true") {
    await import("../../crm_core_frontend/crm_core/routes/Routes").then(
      (module) => {
        for (let i = 0; i < module.crmRoute.length; i++) {
          data.push(module.crmRoute[i]);
        }
      }
    );
  }

  if (process.env.REACT_APP_FINGERPRINTING_ENABLED === "true") {
    await import(
      "../../aiplex_tools_frontend_modular/fingerPrinting/routes/Routes"
    )
      .then((module) => {
        for (let i = 0; i < module.fingerPrintingRoutes.length; i++) {
          data.push(module.fingerPrintingRoutes[i]);
        }
      })
      .catch((err) => {
        console.log("ERROR_MOD", err);
      });
  }

  if (process.env.REACT_APP_DSR_ENABLED === "true") {
    await import("../../aiplex_tools_frontend_modular/dsr/routes/DsrRoutes")
      .then((module) => {
        for (let i = 0; i < module.dsrRoutes.length; i++) {
          data.push(module.dsrRoutes[i]);
        }
      })
      .catch((err) => {
        console.log("ERROR_MOD", err);
      });
  }

  if (process.env.REACT_APP_WEBSWEEPX_ENABLED === "true") {
    await import(
      "../../aiplex_tools_frontend_modular/websweepX/routes/WebSweepXRoutes"
    )
      .then((module) => {
        for (let i = 0; i < module.websweepxRoutes.length; i++) {
          data.push(module.websweepxRoutes[i]);
        }
      })
      .catch((err) => {
        console.log("ERROR_MOD", err);
      });
  }

  if (process.env.REACT_APP_WEBFORM_AUTOMATION_ENABLED === "true") {
    await import(
      "../../aiplex_tools_frontend_modular/webformAutomation/routes/webformRoutes"
    )
      .then((module) => {
        for (let i = 0; i < module.webformRoutes.length; i++) {
          data.push(module.webformRoutes[i]);
        }
      })
      .catch((err) => {
        console.log("ERROR_MOD", err);
      });
  }

  return { data };
};
