import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import HttpUtility from "../../../utilities/HttpUtility";
import EffectUtility from "../../../utilities/EffectUtility";
import TicketsModel from "./models/TicketsModel";
import TicketPostDataModel from "./models/TicketsPostDataModel";
import TicketPutDataModel from "./models/TicketsPutDataModel";
import TicketByIdModel from "./models/TicketByIdModel";
import TicketFieldsModel from "./models/TicketFieldsModel";
import TicketsBulkPutModel from "./models/TicketsBulkPutModel";

export default class TicketsEffect {
  //get ticket request end point
  static async requestTickets(params) {
    const endpoint = environment.api.tickets;

    return EffectUtility.getToModel(TicketsModel, endpoint, params);
  }

  static async requestAllTicketsforSelectAll(params) {
    const endpoint = environment.api.tickets;

    return EffectUtility.getToModel(TicketsModel, endpoint, params);
  }

  //get related ticket request end point
  static async requestRelatedTickets(params, fetchMassTicketHistory) {
    const endpoint = environment.api.tickets;

    let Response = EffectUtility.getToModel(TicketsModel, endpoint, params);

    Response.then((res) => {
      if (
        res.hits &&
        res.hits.hits &&
        res.hits.hits.length !== 0 &&
        fetchMassTicketHistory
      ) {
        fetchMassTicketHistory(
          res.hits.hits.map((item) => {
            return item._id;
          })
        );
      }
    });

    return Response;
  }
  static async requestClearRelatedTickets() {
    return {};
  }

  static async requestTicketsForReports(params, Callback) {
    const endpoint = environment.api.export_ticket_report;

    const Response = EffectUtility.getToModel(TicketsModel, endpoint, params);

    if (Callback) {
      Response.then((res) => {
        Callback(res);
      });
    }

    return Response;
  }

  static async requestTicketsForReportsWithPagination(params) {
    const endpoint = environment.api.tickets;

    return EffectUtility.getToModel(TicketsModel, endpoint, params);
  }

  //get ticket request bu id end point
  static async requestTicketsById(id) {
    if (id) {
      const endpoint = environment.api.tickets + "/" + id;

      return EffectUtility.getToModel(TicketByIdModel, endpoint);
    } else {
      return {};
    }
  }

  static async requestTicketByIdClear() {
    return {};
  }

  static async requestAllTicketsforSelectAllClear() {
    return {};
  }

  //get ticket request bu id end point
  static async requestTicketsByFields(params) {
    const endpoint = environment.api.tickets + "/fields";

    return EffectUtility.getToModel(TicketFieldsModel, endpoint, params);
  }

  //get ticket request end point for filter
  static async requestTicketsFilter(params) {
    const endpoint = environment.api.ticketfilter;
    return EffectUtility.getToModel(TicketsModel, endpoint, params);
  }

  // Post api
  static async requestPostTicket(data) {
    const endpoint = environment.api.tickets + "/add";

    return EffectUtility.postToModel(TicketPostDataModel, endpoint, data);
  }

  //PUT api
  static async requestPutTicketSentiment(data, id) {
    const endpoint = environment.api.update_ticket_sentiment;

    return EffectUtility.putToModel(TicketPutDataModel, endpoint, data);
  }

  //PUT api
  static async requestPutTicket(data, id) {
    const endpoint = environment.api.tickets + "/" + id;

    return EffectUtility.putToModel(TicketPutDataModel, endpoint, data);
  }
  //Bulk PUT api
  static async requestBulkPutTicket(data) {
    const endpoint = environment.api.ticketsBulkPut;

    return EffectUtility.putToModel(TicketsBulkPutModel, endpoint, data);
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
