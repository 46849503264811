import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import Logo from "../logoContainer/Logo";
import axios from "axios";
import { datadogLogs } from "@datadog/browser-logs";
import { Box, Grid, Typography } from "@mui/material";
import { CategoryScale } from "chart.js";
import { Doughnut, Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import { theme } from "../../../../views/App";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import MuiSnackbarAlert from "../../../../components/muiSnackbarAlert/MuiSnackbarAlert";
import LoadingBgImage from "../../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../../utilities/FetchRequest";
import { NivoPieChart } from "../../../../components/nivoCharts/NivoPieChart";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../../components/ticketPartials";
import ReactEcharts from "echarts-for-react";
import { format, isValid } from "date-fns";

function FollowerDemographicsCountry(props) {
  Chart.register(CategoryScale);

  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  useEffect(() => {
    console.log("hsjhjs", props.DateFilter);
    (async function CallApi() {
      props.account_selected &&
        (await fetchData(props.DateFilter, props.account_selected));
    })();
  }, [props.account_selected]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.DateFilter, props.account_selected);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.openDateFilter, props.account_selected);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  const [alertPopUp, setAlertPopUp] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  async function fetchData(dateRange, account_id) {
    setLoading(true);

    let API = `${environment.api.instagramReports}?account_id=${
      account_id?.account_id
    }&reports=["get_follower_demographics_country"]${
      props.DateFilter || props.filterClicked
        ? `&from_date=${dateRange[0]}&to_date=${dateRange[1]}`
        : `&from_date=${
            isValid(
              new Date(new Date().getFullYear(), new Date().getMonth(), 1)
            ) &&
            format(
              new Date(new Date().getFullYear(), new Date().getMonth(), 1),
              "yyyy-MM-dd"
            )
          }&to_date=${isValid(new Date()) && format(new Date(), "yyyy-MM-dd")}`
    }`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (
          response.hasOwnProperty("error") ||
          response.hasOwnProperty("error_id")
        ) {
          response.hasOwnProperty("error_id")
            ? setApiError(`${response?.message} - ${response?.error_id}`)
            : setApiError(response?.error);
          setData(null);
        } else {
          setLoading(false);
          setData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setData(null);
        setApiError(err.message);
        setLoading(false);
      });
  }

  const chartData = [];

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData =
        data &&
        data[0]?.get_follower_demographics_country?.map(({ count, key }) => ({
          Country: key,
          Count: count,
        }));

      props.setexcelData(exportData);
    }
  }, [props.DownloadClicked, chartData]);

  const noDataFound =
    !data || (data && !data[0]?.get_follower_demographics_country);

  console.log("noDataFound", noDataFound);

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: "12",
      }}
    >
      <Box
        className="scrollable"
        sx={{
          overflowY: "auto",
          "& .MuiDataGrid-virtualScroller": {
            "&::-webkit-scrollbar": {
              width: "3px",
              height: "3px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#b0d2da",
              borderRadius: "3px",
              "&:hover": {
                backgroundColor: "#85bac6",
              },
            },
          },
        }}
      >
        {!loading ? (
          <>
            {!loading && !noDataFound && props.account_selected ? (
              <Box
                style={{
                  width: "100%",
                  overflowX: "auto",
                  padding: "12px",
                }}
                // className="bg-success"
              >
                {apiError ? (
                  <Box
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      flex: "12",
                    }}
                  >
                    {apiError}
                  </Box>
                ) : (
                  <Grid
                    container
                    item
                    lg={12}
                    justifyContent="space-between"
                    alignItems="center"
                    // height="100%"
                    sx={{
                      flexWrap: "wrap",
                      // paddingY: "12px",
                    }}
                  >
                    {data &&
                      data[0]?.get_follower_demographics_country?.map(
                        (data, index) => (
                          <Grid item xs={12} md={12} key={index}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                srcSet={`https://flagcdn.com/w40/${data.key.toLowerCase()}.png 2x`}
                                src={`https://flagcdn.com/w20/${data.key.toLowerCase()}.png`}
                                alt=""
                              />

                              <span
                                style={{
                                  marginLeft: "10px",
                                }}
                              >
                                {data.key}
                              </span>

                              <span style={{ marginLeft: "auto" }}>
                                {data.count.toLocaleString()}
                              </span>
                            </div>
                          </Grid>
                        )
                      )}
                  </Grid>
                )}
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default FollowerDemographicsCountry;
