import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import RevenueReportErrorsGetModel from "./models/RevenueReportErrorsGetModel";
import RevenueReportErrorByIdModel from "./models/RevenueReportErrorByIdModel";

// RevenueReportErrorsEffect
export default class RevenueReportErrorsEffect {
  // calling an API according to the RevenueReportErrors model
  static async requestRevenueReportErrors(params) {
    const endpoint = environment.api.revenueReportErrors;
    return EffectUtility.getToModel(
      RevenueReportErrorsGetModel,
      endpoint,
      params
    );
  }

  // Get method for the RevenueReportErrors filter
  static async requestRevenueReportErrorsFilter(params) {
    const endpoint = environment.api.revenueReportErrors;
    return EffectUtility.getToModel(
      RevenueReportErrorsGetModel,
      endpoint,
      params
    );
  }

  static async requestRevenueReportErrorById(id, params, callBack) {
    const endpoint = environment.api.revenueReportErrors;
    let response = EffectUtility.getToModel(
      RevenueReportErrorByIdModel,
      endpoint,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }

  static async requestRevenueReportErrorLogs(params) {
    const endpoint = environment.api.revenueReportErrorLog;
    return EffectUtility.getToModel(
      RevenueReportErrorsGetModel,
      endpoint,
      params
    );
  }

  // Get method for the RevenueReportErrors filter
  static async requestRevenueReportErrorLogsFilter(params) {
    const endpoint = environment.api.revenueReportErrorLog;
    return EffectUtility.getToModel(
      RevenueReportErrorsGetModel,
      endpoint,
      params
    );
  }
}
