import { BaseModel } from "sjs-base-model";
import { boolean } from "yup";

// AssignmentRuleGetModel
export default class LogsModel extends BaseModel {
  data = {};

  constructor(data1) {
    super();

    this.update(data1);
  }
}
