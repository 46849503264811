import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import Logo from "../logoContainer/Logo";
import axios from "axios";
import { datadogLogs } from "@datadog/browser-logs";
import { Box, Grid, Typography } from "@mui/material";
import { CategoryScale } from "chart.js";
import { Doughnut, Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import { theme } from "../../../../views/App";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import MuiSnackbarAlert from "../../../../components/muiSnackbarAlert/MuiSnackbarAlert";
import LoadingBgImage from "../../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../../utilities/FetchRequest";
import { NivoPieChart } from "../../../../components/nivoCharts/NivoPieChart";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../../components/ticketPartials";
import ReactEcharts from "echarts-for-react";
import { format, isValid } from "date-fns";

function InstagramInsights(props) {
  Chart.register(CategoryScale);

  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("hsjhjs", props.DateFilter);
    (async function CallApi() {
      props.account_selected &&
        (await fetchData(props.DateFilter, props.account_selected));
    })();
  }, [props.account_selected]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.DateFilter, props.account_selected);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.openDateFilter, props.account_selected);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  const [alertPopUp, setAlertPopUp] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  async function fetchData(dateRange, account_id) {
    setLoading(true);

    let API = `${environment.api.instagramReports}?account_id=${
      account_id?.account_id
    }&reports=["instagram_insights"]${
      props.DateFilter || props.filterClicked
        ? `&from_date=${dateRange[0]}&to_date=${dateRange[1]}`
        : `&from_date=${
            isValid(
              new Date(new Date().getFullYear(), new Date().getMonth(), 1)
            ) &&
            format(
              new Date(new Date().getFullYear(), new Date().getMonth(), 1),
              "yyyy-MM-dd"
            )
          }&to_date=${isValid(new Date()) && format(new Date(), "yyyy-MM-dd")}`
    }`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (
          response.hasOwnProperty("error") ||
          response.hasOwnProperty("error_id")
        ) {
          response.hasOwnProperty("error_id")
            ? setApiError(`${response?.message} - ${response?.error_id}`)
            : setApiError(response?.error);
          setData(null);
        } else {
          setLoading(false);
          setData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setData(null);
        setApiError(err.message);
        setLoading(false);
      });
  }

  const chartData = [];

  console.log("dataaa", data);

  useEffect(() => {
    if (props.DownloadClicked) {
      props.setexcelData([data && data[0].instagram_insights?.total_metrics]);
    }
  }, [props.DownloadClicked, chartData]);

  const formatValue = (value) => {
    if (value >= 1e6) {
      return (value / 1e6).toFixed(2) + "M";
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(2) + "K";
    }
    return value;
  };

  const getOption = () => {
    let shouldShowLegend = false;

    if (
      (props.ChartContainerHeight?.current &&
        props.ChartContainerWidth?.current &&
        props.ChartContainerHeight.current >= 350 &&
        props.ChartContainerWidth.current > 350) ||
      (props.ChartContainerHeight?.current === undefined &&
        props.ChartContainerWidth?.current === undefined)
    ) {
      shouldShowLegend = true;
    }

    // Get the impressions and reach values from the provided data
    // const { impressions, reach } =
    //   data && data[0].instagram_insights.total_metrics;

    // Map the data to the seriesData for the pie chart
    const seriesData = [
      {
        name: "Impressions",
        value: data && data[0]?.instagram_insights?.total_metrics.impressions,
      },
      {
        name: "Reach",
        value: data && data[0]?.instagram_insights?.total_metrics.reach,
      },
    ];

    return {
      tooltip: {
        trigger: "item",
        confine: true,
        formatter: (params) => {
          let val = params.value;
          let formattedvalue = formatValue(val); // Apply your formatValue function
          const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${params.color};border-radius:50%;"></span>`;
          return `${colorSpan}${params.name}: ${formattedvalue}`;
        },
      },
      legend: {
        icon: "circle",
        show: shouldShowLegend,
        left: 0,
        orient: "vertical",
      },
      series: [
        {
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          padAngle: 5,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            focus: "series",
          },
          labelLine: {
            show: false,
          },
          data: seriesData,
          color: [
            `${theme.reports.positive}`,
            `${theme.reports.negative}`,
            `${theme.reports.neutral}`,
          ], // You can adjust these colors based on the type of data
          itemStyle: {
            borderRadius: 10,
          },
        },
      ],
    };
  };

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <Box
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <MuiSnackbarAlert
            open={alertPopUp}
            onClose={() => setAlertPopUp(false)}
            message={errorMessage}
          />

          {!loading && props.account_selected ? (
            <>
              {apiError ? (
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    flex: "12",
                  }}
                >
                  {apiError}
                </Box>
              ) : (
                <Box textAlign={"center"} height="100%" width={"100%"}>
                  <Grid
                    height={"100%"}
                    width="100%"
                    container
                    justifyContent="space-between"
                    alignItems={"center"}
                    spacing={2}
                  >
                    {
                      <Grid item height={"100%"} width="100%" md={12}>
                        <Box height="100%" width={"100%"}>
                          <ReactEcharts
                            option={getOption()}
                            notMerge={true}
                            lazyUpdate={true}
                            style={{
                              width: "100%",
                              height: "85%",
                            }}
                          />
                          <p className="font-weight-bold"></p>
                        </Box>
                      </Grid>
                    }
                  </Grid>
                </Box>
              )}
            </>
          ) : (
            !loading && <NoDataFound />
          )}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage />
        </Box>
      )}
    </LoadingIndicator>
  );
}

export default InstagramInsights;
