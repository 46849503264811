import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import environment from "environment";
import Chart from "chart.js/auto";
import Logo from "./logoContainer/Logo";
import Description from "./descriptionContainer/Description";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { NivoBarChart } from "../../../components/nivoCharts/NivoBarChart";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";
import ReactEcharts from "echarts-for-react";

const PlatformWiseSentimentOverview = (props) => {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchData(props.DateFilter);
      setLoading(false);
    })();
  }, [props.DateFilter]);
  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.DateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchData(dateRange) {
    let API = `${
      environment.api.sentimentalAnalysisReports
    }?report_type=["count"]&reports=["plaform_wise_sentiment_report"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange?.[0]}&to=${dateRange?.[1]}`
        : ""
    }`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (response.hasOwnProperty("message")) {
          setData(null);
          setApiError(response.message);
        } else {
          setData(response);
          setApiError(null);
        }
      })
      .catch((err) => {
        setData(null);
        setApiError(err.message);
        setLoading(false);
      });
  }

  const rows = [];
  const additionalData = {};

  if (data?.sentiment_analysis_report?.plaform_wise_sentiment_report?.buckets) {
    data?.sentiment_analysis_report?.plaform_wise_sentiment_report.buckets.map(
      (platform) => {
        let positiveObj =
          platform.postive_negative_neutral_sentiment.buckets.filter(
            (item) => item.key === "positive_sentiment"
          )?.[0];
        let negativeObj =
          platform.postive_negative_neutral_sentiment.buckets.filter(
            (item) => item.key === "negative_sentiment"
          )?.[0];
        let neutraleObj =
          platform.postive_negative_neutral_sentiment.buckets.filter(
            (item) => item.key === "neutral_sentiment"
          )?.[0];

        rows.push({
          Platform: platformName(platform.key)[0],
          Total: platform.doc_count,
          Positive: positiveObj?.doc_count,
          Negative: negativeObj?.doc_count,
          Neutral: neutraleObj?.doc_count,
        });
      }
    );

    additionalData["Negative Highest"] = `${platformName(
      Object.keys(
        data?.sentiment_analysis_report?.plaform_wise_sentiment_report
          ?.negative_highest
      )[0]
    )} - ${
      Object.values(
        data?.sentiment_analysis_report?.plaform_wise_sentiment_report
          ?.negative_highest
      )[0]
    }`;

    additionalData["Neutral Highest"] = `${platformName(
      Object.keys(
        data?.sentiment_analysis_report?.plaform_wise_sentiment_report
          ?.neutral_highest
      )[0]
    )} - ${
      Object.values(
        data?.sentiment_analysis_report?.plaform_wise_sentiment_report
          ?.neutral_highest
      )[0]
    }`;

    additionalData["Positive Highest"] = `${platformName(
      Object.keys(
        data?.sentiment_analysis_report?.plaform_wise_sentiment_report
          ?.positive_highest
      )[0]
    )} - ${
      Object.values(
        data?.sentiment_analysis_report?.plaform_wise_sentiment_report
          ?.positive_highest
      )[0]
    }`;

    additionalData["Total Negative"] =
      data?.sentiment_analysis_report?.plaform_wise_sentiment_report?.total_negative;
    additionalData["Total Neutral"] =
      data?.sentiment_analysis_report?.plaform_wise_sentiment_report?.total_neutral;
    additionalData["Total Positive"] =
      data?.sentiment_analysis_report?.plaform_wise_sentiment_report?.total_positive;
    additionalData["Total Volume"] =
      data?.sentiment_analysis_report?.plaform_wise_sentiment_report?.total_volume;
  }

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      // Make sure not to mutate `rows` directly if it's a prop or state
      const updatedRows = [...rows];

      updatedRows.push("");

      // Correct map with destructuring
      Object.entries(additionalData).map(([key, value]) => {
        updatedRows.push({
          Platform: key,
          Total: value,
        });
      });

      updatedRows.map((item) => {
        exportData.push({
          Platform: item?.Platform,
          Total: prefixZero_for_singleDigit(item.Total),
          Positive: prefixZero_for_singleDigit(item?.Positive),
          Negative: prefixZero_for_singleDigit(item?.Negative),
          Neutral: prefixZero_for_singleDigit(item?.Neutral),
        });
      });

      props.setexcelData(exportData);

      // Optionally reset DownloadClicked if needed
      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, rows]);

  let chartLabels = [];
  let chartDataSets = [
    {
      type: "bar",
      backgroundColor: `${theme.reports.positive}`,
      label: "Positive",
      yAxisID: "y",
      data: [],
    },
    {
      type: "bar",
      label: "Negative",
      yAxisID: "y",
      backgroundColor: `${theme.reports.negative}`,
      data: [],
    },
    {
      type: "bar",
      label: "Neutral",
      yAxisID: "y",
      backgroundColor: `${theme.reports.neutral}`,
      data: [],
    },
  ];

  function platformName(val) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === val)
        .map((item) => {
          return item.platform_alias;
        })
    );
  }

  let sentimentalCount;

  let positive_count = [];
  let negative_count = [];
  let neutral_count = [];

  let NoDataFoundBool =
    data &&
    data.sentiment_analysis_report &&
    data.sentiment_analysis_report.plaform_wise_sentiment_report.buckets &&
    data.sentiment_analysis_report.plaform_wise_sentiment_report.buckets
      .length === 0;

  data &&
    data.sentiment_analysis_report &&
    data.sentiment_analysis_report.plaform_wise_sentiment_report.buckets.map(
      (platform, index) => {
        chartLabels.push(String(platformName(platform.key)));
        platform.postive_negative_neutral_sentiment.buckets.map((item) => {
          if (item.key === "negative_sentiment") {
            chartDataSets[1].data.push(item.doc_count);
            negative_count.push(item.doc_count);
          }
        });

        platform.postive_negative_neutral_sentiment.buckets.map((item) => {
          if (item.key === "positive_sentiment") {
            chartDataSets[0].data.push(item.doc_count);
            positive_count.push(item.doc_count);
          }
        });

        platform.postive_negative_neutral_sentiment.buckets.map((item) => {
          if (item.key === "neutral_sentiment") {
            chartDataSets[2].data.push(item.doc_count);
            neutral_count.push(item.doc_count);
          }
        });

        // chartDataSets[3].data.push(platform.doc_count);
      }
    );

  Chart.register(CategoryScale);

  let TotalCount = [];

  chartDataSets &&
    chartDataSets.map((value) => {
      value.data.map((item) => {
        return TotalCount.push(item);
      });
    });

  let Postive_MAX = Math.max(...positive_count);
  let Negative_MAX = Math.max(...negative_count);
  let Neutral_MAX = Math.max(...neutral_count);

  let MaxValue = Postive_MAX + Negative_MAX + Neutral_MAX;

  function getDigit(value) {
    let digit = 1;

    for (let i = 0; value >= 10; i++) {
      value /= 10;
      digit *= 10;
    }

    return digit;
  }

  const digit = getDigit(MaxValue);
  let num = parseInt(MaxValue / digit);

  let updatedDigit = num * digit;

  let stepSize = updatedDigit / 10;

  const remainder = MaxValue % stepSize;

  const difference = stepSize - remainder;

  MaxValue += difference;

  const state = {
    labels: chartLabels,
    datasets: chartDataSets,
  };

  const chartData = chartLabels.map((chartLabel, index) => ({
    Platforms: chartLabel,
    "Positive(+ve)": positive_count[index],
    "Negative(-ve)": negative_count[index],
    "Neutral(∅)": neutral_count[index],
  }));

  let legendOffset,
    maxYAxisLength = 0;
  if (chartData.length > 0) {
    maxYAxisLength =
      chartData &&
      Math.max(
        ...chartData?.map((item) => {
          return String(item.Platforms?.length);
        })
      );
    if (maxYAxisLength > 30) legendOffset = -115 - maxYAxisLength * 3;
    else if (maxYAxisLength > 20) legendOffset = -110 - maxYAxisLength * 2;
    else if (maxYAxisLength > 10) legendOffset = -100 - maxYAxisLength;
    else if (maxYAxisLength > 0) legendOffset = -90;
  }

  const getBarOption = () => {
    const formatValue = (value) => {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + "K";
      }
      return value;
    };

    let shouldShowLegend = false;
    if (
      (props.ChartContainerHeight?.current &&
        props.ChartContainerWidth?.current &&
        props.ChartContainerHeight.current >= 350 &&
        props.ChartContainerWidth.current > 350) ||
      (props.ChartContainerHeight?.current === undefined &&
        props.ChartContainerWidth?.current === undefined)
    ) {
      shouldShowLegend = true;
    }
    return {
      tooltip: {
        trigger: "axis",
        confine: true,
        formatter: (params) => {
          let tooltipContent = `${params[0].axisValue}<br/>`;
          params.forEach((item) => {
            if (item.value !== 0) {
              let val = item.value;
              let formattedvalue = formatValue(val);
              const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${item.color};border-radius:50%;"></span>`;
              tooltipContent += `${colorSpan}${item.seriesName}: ${formattedvalue}<br/>`;
            }
          });
          return tooltipContent;
        },
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        icon: "circle",
        type: "scroll",
        data: shouldShowLegend
          ? ["Positive(+ve)", "Negative(-ve)", "Neutral(∅)"]
          : [],
      },
      grid: {
        top: props.ChartContainerHeight?.current >= 500 ? "5%" : "10%",
        left: "6%",
        right: "3%",
        bottom: "5%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        name:
          props.detailedReport || props.ChartContainerHeight?.current > 300
            ? "Count"
            : "",
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: 25,
        },
        axisLabel: {
          formatter: (value) => {
            let formattedValue = formatValue(value);
            return formattedValue;
          },
          hideOverlap: true,
        },
      },
      labelLayout: {
        hideOverlap: true,
      },

      yAxis: {
        type: "category",
        data: chartData.reverse().map((item) => item.Platforms),
        axisLabel: {
          show: props.detailedReport ? true : false,
          padding: [0, 30, 0, 0],

          hideOverlap: true,
        },
        axisTick: {
          show: true, // Hide the axis ticks completely
        },
        name:
          props.detailedReport || props.ChartContainerWidth?.current > 600
            ? "Platforms"
            : "",
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: 0,
        },
      },
      dataZoom: [
        {
          type: "inside",
          filterMode: "weakFilter",
        },
      ],
      series: [
        {
          name: "Positive(+ve)",
          type: "bar",
          stack: "total",
          label: {
            show: props.detailedReport ? true : false,
            formatter: (params) => {
              let val = params.value;
              let formattedvalue = formatValue(val);
              return val === 0 ? "" : formattedvalue;
            },
            color: theme.palette.primary.dark,
          },
          emphasis: {
            focus: "series",
          },
          itemStyle: {
            color: `${theme.reports.positive}`,
          },
          data: chartData.map((item) => item["Positive(+ve)"]),
        },
        {
          name: "Negative(-ve)",
          type: "bar",
          stack: "total",
          label: {
            show: props.detailedReport ? true : false,
            formatter: (params) => {
              let val = params.value;
              let formattedvalue = formatValue(val);
              return val === 0 ? "" : formattedvalue;
            },
            color: theme.palette.primary.dark,
          },
          emphasis: {
            focus: "series",
          },
          color: `${theme.reports.negative}`,

          data: chartData.map((item) => item["Negative(-ve)"]),
        },
        {
          name: "Neutral(∅)",
          type: "bar",
          stack: "total",
          label: {
            show: props.detailedReport ? true : false,
            formatter: (params) => {
              let val = params.value;
              let formattedvalue = formatValue(val);
              return val === 0 ? "" : formattedvalue;
            },
            color: theme.palette.primary.dark,
          },
          emphasis: {
            focus: "series",
          },
          color: `${theme.reports.neutral}`,

          data: chartData.map((item) => item["Neutral(∅)"]),
        },
      ],
    };
  };

  return (
    <LoadingIndicator isActive={loading} height="100%">
      <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flex: "12",
        }}
      >
        {!loading ? (
          <Box
            sx={{
              height: "100%",
            }}
          >
            {apiError ? (
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  flex: "12",
                }}
              >
                {apiError}
              </Box>
            ) : !loading && !NoDataFoundBool ? (
              <Grid
                container
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                justifyContent="flex-start"
                height="100%"
              >
                <Box sx={{ width: "100%" }}>
                  {props.detailedReport && (
                    <Grid container item textAlign="left">
                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <Grid item xs={2} lg={2} xl={2} md={2} sm={2}>
                          <span style={{ color: "#a4b1ba" }}>Positive</span>
                        </Grid>

                        <Grid item xs={2} lg={2} xl={2} md={2} sm={2}>
                          <p className="ont-weight-bold mb-2">
                            {data &&
                              data.sentiment_analysis_report &&
                              data.sentiment_analysis_report
                                .plaform_wise_sentiment_report.total_positive}
                          </p>
                        </Grid>

                        <Grid item xs={2} lg={2} xl={2} md={2} sm={2}>
                          <span style={{ color: "#a4b1ba" }}>Negative</span>
                        </Grid>

                        <Grid item xs={2} lg={2} xl={2} md={2} sm={2}>
                          <p className="ont-weight-bold mb-2 " align="left">
                            {data &&
                              data.sentiment_analysis_report &&
                              data.sentiment_analysis_report
                                .plaform_wise_sentiment_report &&
                              data.sentiment_analysis_report
                                .plaform_wise_sentiment_report.total_negative}
                          </p>
                        </Grid>

                        <Grid item xs={2} lg={2} xl={2} md={2} sm={2}>
                          <span style={{ color: "#a4b1ba" }}>Neutral</span>
                        </Grid>

                        <Grid item xs={2} lg={2} xl={2} md={2} sm={2}>
                          <p className="ont-weight-bold mb-2">
                            {data &&
                              data.sentiment_analysis_report &&
                              data.sentiment_analysis_report
                                .plaform_wise_sentiment_report.total_neutral}
                          </p>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <Grid item xs={2} lg={2} xl={2} md={2} sm={2}>
                          <span style={{ color: "#a4b1ba" }}>
                            Highest Positive{" "}
                          </span>
                        </Grid>

                        <Grid item xs={2} lg={2} xl={2} md={2} sm={2}>
                          <p className="font-weight-bold mb-2">
                            {`${
                              data &&
                              data.sentiment_analysis_report &&
                              data.sentiment_analysis_report
                                .plaform_wise_sentiment_report &&
                              data.sentiment_analysis_report
                                .plaform_wise_sentiment_report
                                .positive_highest &&
                              platformName(
                                Object.keys(
                                  data &&
                                    data.sentiment_analysis_report &&
                                    data.sentiment_analysis_report
                                      .plaform_wise_sentiment_report &&
                                    data.sentiment_analysis_report
                                      .plaform_wise_sentiment_report
                                      .positive_highest
                                )[0]
                              )
                            } (${
                              data &&
                              data.sentiment_analysis_report &&
                              data.sentiment_analysis_report
                                .plaform_wise_sentiment_report &&
                              data.sentiment_analysis_report
                                .plaform_wise_sentiment_report
                                .positive_highest &&
                              Object.values(
                                data &&
                                  data.sentiment_analysis_report &&
                                  data.sentiment_analysis_report
                                    .plaform_wise_sentiment_report &&
                                  data.sentiment_analysis_report
                                    .plaform_wise_sentiment_report
                                    .positive_highest
                              )[0]
                            })`}
                          </p>
                        </Grid>

                        <Grid item xs={2} lg={2} xl={2} md={2} sm={2}>
                          <span style={{ color: "#a4b1ba" }}>
                            Highest Negative{" "}
                          </span>
                        </Grid>

                        <Grid item xs={2} lg={2} xl={2} md={2} sm={2}>
                          <p className="font-weight-bold mb-2">
                            {`${
                              data &&
                              data.sentiment_analysis_report &&
                              data.sentiment_analysis_report
                                .plaform_wise_sentiment_report &&
                              data.sentiment_analysis_report
                                .plaform_wise_sentiment_report
                                .negative_highest &&
                              platformName(
                                Object.keys(
                                  data &&
                                    data.sentiment_analysis_report &&
                                    data.sentiment_analysis_report
                                      .plaform_wise_sentiment_report &&
                                    data.sentiment_analysis_report
                                      .plaform_wise_sentiment_report
                                      .negative_highest
                                )[0]
                              )
                            } (${
                              data &&
                              data.sentiment_analysis_report &&
                              data.sentiment_analysis_report
                                .plaform_wise_sentiment_report &&
                              data.sentiment_analysis_report
                                .plaform_wise_sentiment_report
                                .negative_highest &&
                              Object.values(
                                data &&
                                  data.sentiment_analysis_report &&
                                  data.sentiment_analysis_report
                                    .plaform_wise_sentiment_report &&
                                  data.sentiment_analysis_report
                                    .plaform_wise_sentiment_report
                                    .negative_highest
                              )[0]
                            })`}
                          </p>
                        </Grid>

                        <Grid item xs={2} lg={2} xl={2} md={2} sm={2}>
                          <span style={{ color: "#a4b1ba" }}>
                            Highest Neutral{" "}
                          </span>
                        </Grid>

                        <Grid item xs={2} lg={2} xl={2} md={2} sm={2}>
                          <p className="font-weight-bold mb-2">
                            {`${
                              data &&
                              data.sentiment_analysis_report &&
                              data.sentiment_analysis_report
                                .plaform_wise_sentiment_report &&
                              data.sentiment_analysis_report
                                .plaform_wise_sentiment_report
                                .neutral_highest &&
                              platformName(
                                Object.keys(
                                  data &&
                                    data.sentiment_analysis_report &&
                                    data.sentiment_analysis_report
                                      .plaform_wise_sentiment_report &&
                                    data.sentiment_analysis_report
                                      .plaform_wise_sentiment_report
                                      .neutral_highest
                                )[0]
                              )
                            } (${
                              data &&
                              data.sentiment_analysis_report &&
                              data.sentiment_analysis_report
                                .plaform_wise_sentiment_report &&
                              data.sentiment_analysis_report
                                .plaform_wise_sentiment_report
                                .neutral_highest &&
                              Object.values(
                                data &&
                                  data.sentiment_analysis_report &&
                                  data.sentiment_analysis_report
                                    .plaform_wise_sentiment_report &&
                                  data.sentiment_analysis_report
                                    .plaform_wise_sentiment_report
                                    .neutral_highest
                              )[0]
                            })`}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <ReactEcharts
                    option={getBarOption()}
                    onEvents={{
                      click: () => null,
                    }}
                    style={{
                      height: "90%",
                      width: "100%",
                    }}
                    notMerge={true}
                    lazyUpdate={true}
                  />
                </Box>
              </Grid>
            ) : (
              !loading && <NoDataFound />
            )}

            {window.location.pathname === "/ticketStatsReport" && (
              <Description
                description={props.description}
                callBack={props.callBack}
                report={props.report}
                index={0}
              />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage width={"50%"} />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  );
};
export default PlatformWiseSentimentOverview;
