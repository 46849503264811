import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import FacebookCommentDeleteModel from "./models/FacebookCommentDeleteModel";
import FacebookCommentPostModel from "./models/FacebookCommentPostModel";
import FacebookInnerFilterModel from "./models/FacebookInnerFilterModel";
import FacebookModel from "./models/FacebookModel";
import FacebookReplyPostModel from "./models/FacebookReplyPostModel";
import FacebookWebhookModel from "./models/FacebookWebHookModel";
import FbWebhookPutModel from "./models/FbWebhookPutModel";
import FacebookPostModel from "./models/FacebookPostDataModel";

// twitter api fetch model
export default class FacebookEffect {
  // calling an API according to the twitterModel
  static async requestFacebook(data, callBackFunction) {
    const endpoint = environment.api.facebook;

    let response = EffectUtility.postToModel(
      FacebookModel,
      endpoint,
      JSON.stringify(data)
    );

    response.then((resp) => {
      if (resp && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }
  static async requestFacebookPostMentions(data, callBackFunction) {
    const endpoint = environment.api.facebook;

    let response = EffectUtility.postToModel(
      FacebookModel,
      endpoint,
      JSON.stringify(data)
    );

    response.then((resp) => {
      if (resp && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestFacebookClear() {
    return {};
  }

  static async requestFacebookWebhook(params) {
    const endpoint = environment.api.facebookWebhook;

    return EffectUtility.getToModel(FacebookWebhookModel, endpoint, params);
  }

  static async requestFacebookInnerFilter(data, callBackFunction) {
    const endpoint = environment.api.fbInnerFilter;
    let response = EffectUtility.postToModel(
      FacebookInnerFilterModel,
      endpoint,
      data
    );
    response.then((resp) => {
      if (resp.data && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestFacebookInnerFilterClear() {
    return {};
  }

  static async requestFacebookFilter(params) {
    const endpoint = environment.api.facebookFilter;

    return EffectUtility.getToModel(FacebookModel, endpoint, params);
  }

  //Facebook feeds reply
  static async requestFacebookReplyPost(id, data, callback) {
    const endpoint = environment.api.facebookReply + "/" + id;
    let response = EffectUtility.postToModel(
      FacebookReplyPostModel,
      endpoint,
      data
    );

    response.then((resp) => {
      if (resp) {
        resp.status === "success" && callback && callback(resp);
      }
    });

    return response;
  }

  static async requestFacebookReplyPostMentions(id, data, callback) {
    const endpoint = environment.api.facebookPMReply + "/" + id;
    let response = EffectUtility.postToModel(
      FacebookReplyPostModel,
      endpoint,
      data
    );

    response.then((resp) => {
      if (resp) {
        resp.status === "success" && callback && callback(resp);
      }
    });

    return response;
  }
  static async requestFacebookCommentPost(id, data) {
    const endpoint = environment.api.facebookComment + "/" + id;
    return EffectUtility.postToModel(FacebookCommentPostModel, endpoint, data);
  }

  //Facebook Comment delete method
  static async requestFacebookCommentDelete(id, data) {
    const endpoint = environment.api.facebookCommentDelete + "/" + id;
    return EffectUtility.deleteToModel(
      FacebookCommentDeleteModel,
      endpoint,
      data
    );
  }

  //Facebook Comment Hide
  static async requestFacebookCommentHidePost(id, data) {
    const endpoint = environment.api.facebookCommentHide + "/" + id;
    return EffectUtility.postToModel(FacebookReplyPostModel, endpoint, data);
  }

  // put method for the Facebbok Feeds
  static async requestPutFbFeeds(data, id) {
    const endpoint = environment.api.facebookWebhook + "/" + id;
    return EffectUtility.putToModel(FbWebhookPutModel, endpoint, data);
  }

  // Posting data in facebook platform method
  static async requetFacebookDataPost(params, data) {
    const endpoint = environment.api.platformPost + "?" + params;

    return EffectUtility.postToModel(FacebookPostModel, endpoint, data);
  }
}
