import React, { useEffect, useState } from "react";
import environment from "environment";
import { Box, Grid, ListItem, Tooltip, Typography } from "@mui/material";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { axiosRequest } from "../../../utilities/FetchRequest";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import ReactEcharts from "echarts-for-react";
import { theme } from "../../../views/App";
import { getRandomColor } from "../../../a3label_org_intranet/a3labels/views/cumulativeReports/ReportsReusedFunctions";
import { prefixZero_for_singleDigit } from "../../../components/ticketPartials";
function SentimentbyTicketsCategoryChart(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [senitmentTickets, setSenitmentTickets] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchPlatformwiseTicketsByCategory(props.DateFilter);
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.DateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchPlatformwiseTicketsByCategory(dateRange) {
    let API = `${
      environment.api.sentimentalAnalysisReports
    }?report_type=["count"]&reports=["sentiment_analysis_report_per_category"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    try {
      const res = axiosRequest(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      const response = await res;
      if (response.hasOwnProperty("message")) {
        setSenitmentTickets(null);
        setApiError(response.message);
      } else {
        setSenitmentTickets(response);
        setApiError(null);
      }
    } catch (err) {
      setSenitmentTickets(null);
      setApiError(err.message);
      setLoading(false);
    }
  }

  // Flatten the nested structure starting from the root categories
  let updatedRows = [];

  senitmentTickets?.sentiment_analysis_report?.sentiment_analysis_report_per_category[0]?.children?.map(
    (item, index) => {
      updatedRows?.push({
        id: index + 1,
        Category: item?.value
          ? `${item?.value} (${item?.count})`
          : ` (${item?.count})`,
        positive_sentiment: prefixZero_for_singleDigit(
          item?.sentiments?.positive_sentiment
        ),
        negative_sentiment: prefixZero_for_singleDigit(
          item?.sentiments?.negative_sentiment
        ),
        neutral_sentiment: prefixZero_for_singleDigit(
          item?.sentiments?.neutral_sentiment
        ),
      });
    }
  );
  const totalRow = {
    id: updatedRows.length + 1,
    Category: `Total (${senitmentTickets?.sentiment_analysis_report?.sentiment_analysis_report_per_category[1]?.total_tickets})`,
    positive_sentiment: prefixZero_for_singleDigit(
      senitmentTickets?.sentiment_analysis_report
        ?.sentiment_analysis_report_per_category[1]?.positive_sentiment
    ),
    negative_sentiment: prefixZero_for_singleDigit(
      senitmentTickets?.sentiment_analysis_report
        ?.sentiment_analysis_report_per_category[1]?.negative_sentiment
    ),
    neutral_sentiment: prefixZero_for_singleDigit(
      senitmentTickets?.sentiment_analysis_report
        ?.sentiment_analysis_report_per_category[1]?.neutral_sentiment
    ),
  };
  senitmentTickets?.sentiment_analysis_report
    ?.sentiment_analysis_report_per_category[0]?.children?.length > 0 &&
    updatedRows.push(totalRow);

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      updatedRows?.map((item, index) => {
        exportData?.push({
          Category: item?.Category,
          ["Positive"]: item?.positive_sentiment,
          ["Negative"]: item?.negative_sentiment,
          ["Neutral"]: item?.neutral_sentiment,
        });
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, updatedRows]);

  // Transform the data and store it in a new constant

  const NegativeSentiment =
    senitmentTickets?.sentiment_analysis_report?.sentiment_analysis_report_per_category?.[0]?.children.map(
      (item) => item?.sentiments?.negative_sentiment
    );
  const PositiveSentiment =
    senitmentTickets?.sentiment_analysis_report?.sentiment_analysis_report_per_category?.[0]?.children.map(
      (item) => item?.sentiments?.positive_sentiment
    );
  const NeutralSentiment =
    senitmentTickets?.sentiment_analysis_report?.sentiment_analysis_report_per_category?.[0]?.children.map(
      (item) => item?.sentiments?.neutral_sentiment
    );
  let maxNegativeSentiment = 0;
  if (
    NeutralSentiment?.length > 0 &&
    NeutralSentiment?.length > 0 &&
    PositiveSentiment?.length > 0
  ) {
    maxNegativeSentiment = Math.max(
      ...NegativeSentiment,
      ...PositiveSentiment,
      ...NeutralSentiment
    );
  }
  const Categories =
    senitmentTickets?.sentiment_analysis_report?.sentiment_analysis_report_per_category?.[0]?.children.map(
      (item) => {
        let category = {
          name: item.value ? item.value : "",
        };

        if (maxNegativeSentiment) {
          category.max = maxNegativeSentiment;
        }

        return category;
      }
    );

  // Start transforming from the top level
  const getOption = () => {
    let shouldShowLegend = false;
    if (
      (props.ChartContainerHeight?.current &&
        props.ChartContainerWidth?.current &&
        props.ChartContainerHeight.current >= 350 &&
        props.ChartContainerWidth.current > 350) ||
      (props.ChartContainerHeight?.current === undefined &&
        props.ChartContainerWidth?.current === undefined)
    ) {
      shouldShowLegend = true;
    }

    return {
      tooltip: {
        trigger: "item",
      },
      legend: {
        data: ["Positive Sentiment", "Negative Sentiment", "Neutral Sentiment"],
      },
      radar: {
        indicator: Categories,
      },
      series: [
        {
          name: "Sentiment By Tickets Category Chart",
          type: "radar",
          data: [
            {
              value: NeutralSentiment,
              name: "Neutral Sentiment",
              itemStyle: {
                color: theme.reports.neutral,
              },
            },
            {
              value: NegativeSentiment,
              name: "Negative Sentiment",
              itemStyle: {
                color: theme.reports.negative,
              },
            },
            {
              value: PositiveSentiment,
              name: "Positive Sentiment",
              itemStyle: {
                color: theme.reports.positive,
              },
            },
          ],
        },
      ],
    };
  };

  return (
    <LoadingIndicator isActive={loading}>
      <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flex: "12",
        }}
      >
        {!loading ? (
          <>
            {apiError ? (
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  flex: "12",
                }}
              >
                {apiError}
              </Box>
            ) : !loading &&
              senitmentTickets?.sentiment_analysis_report
                ?.sentiment_analysis_report_per_category?.length ? (
              <Box height="100%" width={"100%"}>
                <ReactEcharts
                  option={getOption()}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  notMerge={true}
                  lazyUpdate={true}
                />
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage width={"50%"} />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  );
}

export default SentimentbyTicketsCategoryChart;
