import ToastStatusEnum from "../constants/ToastStatusEnum";
import ToastsAction from "../stores/toasts/ToastsAction";
import { datadogLogs } from "@datadog/browser-logs";
import environment from "environment";

export let ErrorLogs = [];

export function ClearErrorLogs() {
  ErrorLogs = [];
}

const errorToastMiddleware = () => (store) => (next) => (action) => {
  let toasts = store.getState().toasts;
  if (action.error) {
    const errorAction = action;

    ErrorLogs.push({
      error: errorAction.error,
      endpoint: errorAction.payload.url,
      type: errorAction.type,
      status: errorAction.payload.status,
      data: errorAction.payload.raw.data,
    });

    datadogLogs.logger.error(errorAction);

    if (errorAction.payload.status !== 403) {
      if (toasts.items.length === 0) {
        next(
          ToastsAction.add(
            errorAction.payload.message,
            ToastStatusEnum.Error,
            errorAction.payload.raw.data.message
              ? errorAction.payload.raw.data.message
              : errorAction.payload.raw.data.Message
              ? errorAction.payload.raw.data.Message
              : errorAction.payload.raw.data.error_description,
            {
              error: errorAction.payload.raw.data,
              config: errorAction.payload.raw.config,
            }
          )
        );
      }

      let post_log_data = {
        // message: { errorData: action, userId: localStorage.getItem("userId") },
        type: action.type,
        status: action.payload.status,
        url: action.payload.url,
        error: errorAction.payload.raw.data,
        payload_data: errorAction.payload?.raw?.config?.data,
        method: errorAction.payload.raw.config.method,
      };

      if (action.payload.status != 401) {
        fetch(`${environment.api.baseApi}/api/logs/frontendLogs`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(post_log_data),
        });
      }
    }
  }
  if (
    action.error === false &&
    action.payload !== undefined &&
    action.payload.status !== undefined &&
    (action.payload.status?.toLowerCase() === "success" ||
      action.payload.status?.toLowerCase() === "deleted")
  ) {
    const errorAction = action;

    next(
      ToastsAction.add(
        errorAction.payload.message,
        ToastStatusEnum.Success,
        errorAction.payload.status
      )
    );
  }

  next(action);
};

export default errorToastMiddleware;
