import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
} from "@mui/material";

import { Form, Formik, Field } from "formik";
import { connect } from "react-redux";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import MuiButton from "../../../components/muiButton/MuiButton";
import GoogleAlertConfigAction from "../../stores/googleAlertConfig/GoogleAlertConfigAction";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

const GoogleAlertConfigPost = (props) => {
  let initial = {};
  if (props.edit) {
    initial = {
      alert_name: props.projectEditData.alert_name,
      rss_url: props.projectEditData.rss_url || [],
      enabled: props.projectEditData.enabled,
    };
  } else {
    initial = {
      alert_name: "",
      rss_url: "",
    };
  }

  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        validate={(values) => {
          var errors = {};
          if (!values.rss_url) {
            errors.rss_url = "RSS url is Required";
          }

          if (!values.alert_name) {
            errors.alert_name = "Alert Name is Required";
          }

          return errors;
        }}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);
          const params = {
            order_by: '[["created_on", "desc"]]',
            page_limit: 15,
          };
          const param = {
            order_by: `[["alert_name.keyword", "asc"]]`,
            page_limit: "none",
          };
          if (props.edit) {
            props
              .dispatch(
                GoogleAlertConfigAction.requestPutGoogleAlertConfig(
                  JSON.stringify(values),
                  props.projectEditData.config_unique_id
                )
              )
              .then(() => {
                props.dispatch(
                  GoogleAlertConfigAction.requestGoogleAlertConfig(params)
                );
                props.dispatch(
                  GoogleAlertConfigAction.requestGoogleAlertConfigFilter(param)
                );
                props.onClose();
                setLoading(false);
              });
          } else {
            props
              .dispatch(
                GoogleAlertConfigAction.requestPostGoogleAlertConfig(values)
              )
              .then(() => {
                const params = {
                  order_by: '[["created_on", "desc"]]',
                  page_limit: 15,
                };
                const param = {
                  order_by: `[["alert_name.keyword", "asc"]]`,
                  page_limit: "none",
                };
                props.dispatch(
                  GoogleAlertConfigAction.requestGoogleAlertConfig(params)
                );
                props.dispatch(
                  GoogleAlertConfigAction.requestGoogleAlertConfigFilter(param)
                );
                props.onClose();
                setLoading(false);
              });
          }

          // formikHelpers.resetForm();
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "100%" }}>
                <Box width="100%" mb={3}>
                  <Grid
                    container
                    xl={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xl={11} textAlign="center">
                      <h3 className="dailogtext">
                        {!props.edit ? (
                          <b>
                            <span className="span_first_letter">
                              &nbsp;&nbsp;A
                            </span>
                            dd &nbsp;
                            <span className="span_first_letter">C</span>
                            onfiguration &nbsp;
                            <span className="span_first_letter">D</span>etails
                          </b>
                        ) : (
                          <b>
                            <span className="span_first_letter">
                              &nbsp;&nbsp;E
                            </span>
                            dit &nbsp;
                            <span className="span_first_letter">C</span>
                            onfiguration &nbsp;
                            <span className="span_first_letter">D</span>etails
                          </b>
                        )}
                      </h3>
                    </Grid>
                    <Grid item xl={1} textAlign="right">
                      <div
                        className="col-2 closebtn"
                        onClick={() => {
                          props.onClose();
                        }}
                      >
                        <i
                          title="Close"
                          className="fa fa-times-circle-o fa-2x"
                          style={{ size: "15px" }}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContentText>

              {/* <Box sx={{ width: "300px", margin: "auto", marginLeft: "140px" }}> */}
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} mt={1}>
                    Alert Name * :
                  </Grid>
                  <Grid item lg={9} md={9} sm={9}>
                    <Field
                      as={MuiTextField}
                      autoFocus
                      variant="outlined"
                      placeholder="Alert Name"
                      id="alert_name"
                      size="small"
                      name="alert_name"
                      error={
                        Boolean(errors.alert_name) &&
                        Boolean(touched.alert_name)
                      }
                      helperText={
                        Boolean(touched.alert_name) && errors.alert_name
                      }
                    />
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} container mt={1}>
                  <Grid item lg={3} md={3} sm={3} mt={1}>
                    RSS url * :
                  </Grid>
                  <Grid item lg={9} md={9} sm={9}>
                    <Field
                      as={MuiTextField}
                      autoFocus
                      variant="outlined"
                      placeholder="RSS url"
                      id="rss_url"
                      size="small"
                      name="rss_url"
                      error={
                        Boolean(errors.rss_url) && Boolean(touched.rss_url)
                      }
                      helperText={Boolean(touched.rss_url) && errors.rss_url}
                    />
                  </Grid>
                </Grid>

                {props.edit ? (
                  <Grid item lg={12} md={12} sm={12} container mt={1}>
                    <Grid item lg={3} md={3} sm={3} mt={1}>
                      {/* Enabled: */}
                    </Grid>
                    <Grid item lg={9} md={9} sm={9}>
                      <FormControlLabel
                        control={<Checkbox checked={values.enabled} />}
                        label="Enable/Disable"
                        name="enabled"
                        value={values.enabled}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>

              {/* </Box> */}
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="submit"
                      name="Add Alert Name"
                      width="100%"
                      loading={loading}
                    />
                  ) : (
                    <MuiButton
                      type="submit"
                      name="Submit"
                      width="100%"
                      loading={loading}
                    />
                  )}
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="reset"
                      name="Reset"
                      // onClick={Formik.resetForm}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(GoogleAlertConfigPost);
