import ActionUtility from "../../../utilities/ActionUtility";
import AutoresponseEffect from "./AutoresponseEffect";

export default class AutoresponseAction {
  static REQUEST_AUTORESPONSE = "AutoresponseAction.REQUEST_AUTORESPONSE";
  static REQUEST_AUTORESPONSE_FINISHED =
    "AutoresponseAction.REQUEST_AUTORESPONSE_FINISHED";

  static REQUEST_AUTORESPONSE_FILTER =
    "AutoresponseAction.REQUEST_AUTORESPONSE_FILTER";
  static REQUEST_AUTORESPONSE_FILTER_FINISHED =
    "AutoresponseAction.REQUEST_AUTORESPONSE_FILTER_FINISHED";

  static REQUEST_POST_AUTORESPONSE =
    "AutoresponseAction.REQUEST_POST_AUTORESPONSE";
  static REQUEST_POST_AUTORESPONSE_FINISHED =
    "AutoresponseAction.REQUEST_POST_AUTORESPONSE_FINISHED";

  static REQUEST_PUT_AUTORESPONSE =
    "AutoresponseAction.REQUEST_PUT_AUTORESPONSE";
  static REQUEST_PUT_AUTORESPONSE_FINISHED =
    "AutoresponseAction.REQUEST_PUT_AUTORESPONSE_FINISHED";

  static REQUEST_PUT_AUTORESPONSE_BULKUPDATE =
    "AutoresponseAction.REQUEST_PUT_AUTORESPONSE_BULKUPDATE";
  static REQUEST_PUT_AUTORESPONSE_BULKUPDATE_FINISHED =
    "AutoresponseAction.REQUEST_PUT_AUTORESPONSE_BULKUPDATE_FINISHED";

  static REQUEST_AUTOMATION_OPERATIONS =
    "AutoresponseAction.REQUEST_AUTOMATION_OPERATIONS";
  static REQUEST_AUTOMATION_OPERATIONS_FINISHED =
    "AutoresponseAction.REQUEST_AUTOMATION_OPERATIONS_FINISHED";

  static REQUEST_AUTOMATION_FIELDS =
    "AutoresponseAction.REQUEST_AUTOMATION_FIELDS";
  static REQUEST_AUTOMATION_FIELDS_FINISHED =
    "AutoresponseAction.REQUEST_AUTOMATION_FIELDS_FINISHED";

  static REQUEST_AUTORESPONSE_LOGS =
    "AutoresponseAction.REQUEST_AUTORESPONSE_LOGS";
  static REQUEST_AUTORESPONSE_LOGS_FINISHED =
    "AutoresponseAction.REQUEST_AUTORESPONSE_LOGS_FINISHED";

  static REQUEST_MAILTRIGGER_LOGS =
    "AutoresponseAction.REQUEST_MAILTRIGGER_LOGS";
  static REQUEST_MAILTRIGGER_LOGS_FINISHED =
    "AutoresponseAction.REQUEST_MAILTRIGGER_LOGS_FINISHED";

  static REQUEST_NOTIFICATION_LOGS =
    "AutoresponseAction.REQUEST_NOTIFICATION_LOGS";
  static REQUEST_NOTIFICATION_LOGS_FINISHED =
    "AutoresponseAction.REQUEST_NOTIFICATION_LOGS_FINISHED";

  static requestAutomationOperations(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AutoresponseAction.REQUEST_AUTOMATION_OPERATIONS,
        AutoresponseEffect.requestAutomationOperations,
        params
      );
    };
  }

  static requestMailTriggerLogs(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AutoresponseAction.REQUEST_MAILTRIGGER_LOGS,
        AutoresponseEffect.requestMailTriggerLogs,
        params
      );
    };
  }

  static requestAutoresponsLogs(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AutoresponseAction.REQUEST_AUTORESPONSE_LOGS,
        AutoresponseEffect.requestAutoresponsLogs,
        params
      );
    };
  }

  static requestNotificationLogs(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AutoresponseAction.REQUEST_NOTIFICATION_LOGS,
        AutoresponseEffect.requestNotificationLogs,
        params
      );
    };
  }

  static requestAutomationFields(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AutoresponseAction.REQUEST_AUTOMATION_FIELDS,
        AutoresponseEffect.requestAutomationFields,
        params
      );
    };
  }

  //METHODS
  static requestAutorespons(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AutoresponseAction.REQUEST_AUTORESPONSE,
        AutoresponseEffect.requestAutorespons,
        params
      );
    };
  }

  static requestAutoresponsFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AutoresponseAction.REQUEST_AUTORESPONSE_FILTER,
        AutoresponseEffect.requestAutoresponsFilter,
        params
      );
    };
  }

  static requestPostAutoresponse(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AutoresponseAction.REQUEST_POST_AUTORESPONSE,
        AutoresponseEffect.requestPostAutoresponse,
        data
      );
    };
  }

  static requestPutAutorespons(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AutoresponseAction.REQUEST_PUT_AUTORESPONSE,
        AutoresponseEffect.requestPutAutorespons,
        data,
        id
      );
    };
  }

  // Tag Bulk put function
  static requestPutAutoresponseBulkUpdate(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AutoresponseAction.REQUEST_PUT_AUTORESPONSE_BULKUPDATE,
        AutoresponseEffect.requestPutAutoresponseBulkUpdate,
        data
      );
    };
  }
}
