import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import InstagramDirectMessageModel from "./model/InstagramDirectMessageModel";
import InstagramDirectMessagePostModel from "./model/InstagramDirectMessagePostModel";

//Intagram effect
export default class InstagramDirectMessageEffect {
  // calling an API according to the InstagramDirectMessage
  static async requestInstagramDirectMessage(data) {
    const endpoint = environment.api.instagramDirectMessage;

    return EffectUtility.postToModel(
      InstagramDirectMessageModel,
      endpoint,
      JSON.stringify(data)
    );
  }

  // InstagramDirectMessage Post API
  static async requestInstagramDirectMessagePost(data, id, callback) {
    const endpoint = environment.api.instagramDirectMessagePost + "/" + id;

    let response = EffectUtility.postToModel(
      InstagramDirectMessagePostModel,
      endpoint,
      data
    );

    response.then((resp) => {
      if (resp) {
        resp.status === "success" && callback && callback(resp);
      }
    });

    return response;
  }

  static async requestInstagramDMClear() {
    return {};
  }
}
