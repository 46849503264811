import {
  Box,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./dashboard.css";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import ProjectAction from "../../stores/project/projectAction";
import StatusAction from "../../stores/status/StatusAction";
import { format, isValid } from "date-fns";
import { theme } from "../../../views/App";
import PlatformAction from "../../stores/platform/PlatformAction";
import UserAction from "../../stores/user/UserAction";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DatePicker from "../../../components/dateRangePickerComponent/DateRangePicker";
import { selectedStaticReport } from "../../../components/ticketPartials";
import DIYReportTemplateAction from "../../stores/newReportTemplate/DIYReportTemplateAction";
import { Responsive, WidthProvider } from "react-grid-layout";
import _ from "lodash";
import ReturnReports from "../../../components/ReturnReports";
import DetailedReportScreen from "../diyReport/DetailedReportScreen";
import ReturnDemoStaticReports from "../../../components/ReturnDemoStaticReports";
import PlatformAccountsAction from "../../stores/platformAccounts/PlatformAccountsAction";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

// mapstateto props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    DIYReportTemplateAction.REQUEST_DIY_REPORTS_BY_ID,
    ProjectAction.REQUEST_PROJECT_BY_ID,
    PlatformAction.REQUEST_PLATFORM,
    UserAction.REQUEST_USER_FILTER,
    StatusAction.REQUEST_STATUS_FILTER,
    PlatformAccountsAction.REQUEST_PLATFORM_ACCOUNT,
  ]),

  diyReportById: state.diyReportById.diyReportById || [],
  projectById: state.projectById.projectById || [],
  platform: state.platform.platform || [],
  userfilter: state.userfilter.userfilter || [],
  statusFilter: state.statusFilter.statusFilter || [],
  platformAccount: state.platformAccount.platformAccount || [],
});

function Dragabledashboard(props) {
  //useEffect function
  useEffect(() => {
    fetchReportTemplate();

    fetchOrgPlatforms();

    fetchStatusFilter();

    fetchUsers();
    fetchPlatformAccounts();
  }, []);

  function fetchPlatformAccounts() {
    let params = {
      page_limit: "none",
      order_by: '[["created_on", "desc"]]',
    };

    props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
  }

  const isDashboard = window.location.pathname === "/none";

  // Set the data-dashboard attribute on the body
  document.body.setAttribute("data-dashboard", isDashboard);

  const [myTicketsTime, setMyTicketTime] = useState(
    localStorage.getItem("my_tickets_date_filter")
      ? JSON.parse(localStorage.getItem("my_tickets_date_filter"))
      : "SI"
  );
  let maxDate = new Date();

  const [createdDate, setCreatedDate] = useState(
    localStorage.getItem("my_tickets_custom_date_filter")
      ? [
          new Date(
            JSON.parse(localStorage.getItem("my_tickets_custom_date_filter"))[0]
          ),
          new Date(
            JSON.parse(localStorage.getItem("my_tickets_custom_date_filter"))[1]
          ),
        ]
      : [null, null]
  );

  const [detailReportOpen, setDetailReportOpen] = useState();
  const [detailReportId, setDetailReportId] = useState();

  let onKeyDown = (event) => {
    if (event.key === "Backspace") {
      event.preventDefault();
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  const createdDateValueHandleChange = (e, v) => {
    let date1 = e[0] && e[0].$d ? e[0].$d : null;
    let date2 = e[1] && e[1].$d ? e[1].$d : null;

    const isPastDate = (date) => {
      const now = new Date();
      return date <= now;
    };

    if (
      isValid(date1) &&
      isValid(date2) &&
      isPastDate(date1) &&
      isPastDate(date2)
    ) {
      setCreatedDate([isValid(date1) && date1, isValid(date2) && date2]);
      setMyTicketTime("Custom");

      localStorage.setItem("my_tickets_date_filter", JSON.stringify("Custom"));

      if (isValid(date1) && isValid(date2)) {
        localStorage.setItem(
          "my_tickets_custom_date_filter",
          JSON.stringify([isValid(date1) && date1, isValid(date2) && date2])
        );
      } else {
        localStorage.removeItem("my_tickets_custom_date_filter");
      }
    }
  };

  const timeFilterHandleChange = async (event, time) => {
    setMyTicketTime(time);

    if (time !== "Custom") {
      setCreatedDate([new Date(), new Date()]);
    }

    if (time === "SI") {
      setCreatedDate([null, null]);
    }
    if (time != null) {
      localStorage.setItem("my_tickets_date_filter", JSON.stringify(time));
      localStorage.setItem(
        "my_tickets_custom_date_filter",
        JSON.stringify([new Date(), new Date()])
      );
    } else {
      localStorage.removeItem("my_tickets_date_filter");
    }
  };

  function fetchOrgPlatforms() {
    let param = {
      // level: "organization",
      page_limit: "none",
      order_by: `[["platform_name.keyword","asc"]]`,
    };
    props.dispatch(PlatformAction.requestPlatform(param));
  }

  function fetchUsers() {
    let param = { page_limit: "none" };
    props.dispatch(UserAction.requestUserFilter(param));
  }

  function fetchReportTemplate() {
    let report_id =
      localStorage.getItem("default_dashboard") &&
      JSON.parse(localStorage.getItem("default_dashboard"));

    if (JSON.parse(localStorage.getItem("default_dashboard"))) {
      if (report_id === "demo_static_report_id") {
      } else {
        props.dispatch(
          DIYReportTemplateAction.requestDIYReportsById(report_id)
        );
      }
    }
  }

  function fetchStatusFilter() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(StatusAction.requestStatusFilter(params));
  }

  let platform_list = [];
  if (props.platform.hits) {
    props.platform.hits.hits &&
      props.platform.hits.hits.length > 0 &&
      props.platform.hits.hits.map((item) => {
        platform_list.push({
          platform_id: item._id,
          platform_name: item._source.platform_name,
          platform_alias: item._source.alias,
        });
      });
  }

  let userList = [];
  if (props.userfilter) {
    props.userfilter.data &&
      props.userfilter.data.length > 0 &&
      props.userfilter.data.map((user) => {
        userList.push({
          user_id: user.id,
          user_name: user.username,
        });
      });
  }

  const activitiesData = JSON.parse(localStorage.getItem("activities"));
  let activity_list = [];
  activitiesData &&
    activitiesData.hits &&
    activitiesData.hits.hits &&
    activitiesData.hits.hits.length > 0 &&
    activitiesData.hits.hits.map((item) => {
      activity_list.push({
        activity_id: item._id,
        activity_name: item._source.activities,
        platform: item._source.platform,
      });
    });

  let statusList = [];
  if (props.statusFilter.hits) {
    props.statusFilter.hits.hits &&
      props.statusFilter.hits.hits.length > 0 &&
      props.statusFilter.hits.hits.map((item) => {
        statusList.push({
          status_id: item._id,
          status_name: item._source.status_name,
        });
      });
  }

  const account_list = [];

  if (props?.platformAccount?.hits) {
    props?.platformAccount?.hits?.hits?.map((item) => {
      account_list.push({
        platform: item?._source.platform,
        account_id: item?._id,
        account_name: item?._source.account_name,
      });
    });
  }

  const [dateRange, setDateRange] = useState(UpdateDateRange());

  function UpdateDateRange() {
    let date = myTicketsTime !== "Custom" ? myTicketsTime : createdDate;

    let date0 = new Date();
    let nextday = new Date(date0);
    nextday.setDate(nextday.getDate() + 1);

    let date1 = new Date();
    let previousday = new Date(date1);
    previousday.setDate(previousday.getDate() - 1);

    // start from today's date last 7 days
    const last7Days = [...Array(8).keys()].map((index) => {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);

      tomorrow.setDate(tomorrow.getDate() - index);

      return tomorrow;
    });

    // last 30 days
    let date2 = new Date();
    let last30Days = new Date(new Date().setDate(date2.getDate() - 30));

    // ***************************************
    // last 3 months
    let date3 = new Date();
    let last3Month = date3.setMonth(date3.getMonth() - 3);

    let last2days = [...Array(3).keys()].map((index) => {
      const date = new Date();
      const tomorrow = new Date(date);
      tomorrow.setDate(date.getDate() + 1);

      tomorrow.setDate(tomorrow.getDate() - index);
      return tomorrow;
    });

    switch (date) {
      case "Today":
        return [
          `${format(new Date(), "yyyy-MM-dd")}`,
          `${format(nextday, "yyyy-MM-dd")}`,
        ];
      case "Previous Day":
        return [
          `${format(previousday, "yyyy-MM-dd")}`,
          `${format(new Date(), "yyyy-MM-dd")}`,
        ];
      case "Last 2 Days":
        return [
          `${format(last2days[2], "yyyy-MM-dd")}`,
          `${format(last2days[0], "yyyy-MM-dd")}`,
        ];
      case "Last 7 Days":
        return [
          `${format(last7Days[7], "yyyy-MM-dd")}`,
          `${format(last7Days[0], "yyyy-MM-dd")}`,
        ];
      case "Last 30 Days":
        return [
          `${format(last30Days, "yyyy-MM-dd")}`,
          `${format(nextday, "yyyy-MM-dd")}`,
        ];
      case "Last 3 Months":
        return [
          `${format(last3Month, "yyyy-MM-dd")}`,
          `${format(nextday, "yyyy-MM-dd")}`,
        ];
      default:
        if (Array.isArray(date)) {
          return [
            `${format(date[0], "yyyy-MM-dd")}`,
            `${format(date[1], "yyyy-MM-dd")}`,
          ];
        } else {
          return null;
        }
    }
  }
  useEffect(() => {
    setDateRange(UpdateDateRange());
  }, [myTicketsTime, createdDate]);

  const { isRequesting } = props;

  const [dragging, setDragging] = useState(false);
  const [draggingItem, setDraggingItem] = useState(null);
  let [reports, setReports] = useState([]);

  const [layouts, setLayouts] = useState({ lg: [] });
  const [compactType, setCompactType] = useState("vertical");
  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
  const [toolbox, setToolbox] = useState({ lg: [] });

  const [mounted, setMounted] = useState(false);

  const onLayoutChange = (layout, layouts) => {
    setLayouts({ ...layouts });
  };

  const onBreakpointChange = (breakpoint) => {
    setCurrentBreakpoint(breakpoint);
    setToolbox({
      ...toolbox,
      [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || [],
    });
  };

  const generateDOM = () => {
    return _.map(layouts.lg, function (layout, i) {
      const matchingItem = reports.find((item) => item.id === i);

      return (
        <Typography
          component={"div"}
          key={i}
          sx={{
            backgroundColor: "white",
            border: `1px solid ${theme.palette.borderColor.textField}`,
            overflow: "hidden",
            cursor: "default",
            overflowY: "hidden",
          }}
        >
          <Box key={i} height="100%">
            <div
              style={{
                height: "100%",
              }}
            >
              {matchingItem &&
              localStorage.getItem("default_dashboard") &&
              JSON.parse(localStorage.getItem("default_dashboard")) ===
                "demo_static_report_id" ? (
                <ReturnDemoStaticReports
                  report={matchingItem.reportID}
                  platform_list={platform_list}
                  activity_list={activity_list}
                  statusList={statusList}
                  userList={userList}
                  setDetailReportOpen={setDetailReportOpen}
                  setDetailReportId={setDetailReportId}
                  myTicketsTime={
                    myTicketsTime !== "Custom" ? myTicketsTime : createdDate
                  }
                  DateFilter={dateRange}
                />
              ) : (
                matchingItem && (
                  <ReturnReports
                    report={matchingItem.reportID}
                    platform_list={platform_list}
                    activity_list={activity_list}
                    statusList={statusList}
                    userList={userList}
                    setDetailReportOpen={setDetailReportOpen}
                    setDetailReportId={setDetailReportId}
                    myTicketsTime={
                      myTicketsTime !== "Custom" ? myTicketsTime : createdDate
                    }
                    DateFilter={dateRange}
                    account_list={account_list}
                  />
                )
              )}
            </div>
          </Box>
        </Typography>
      );
    });
  };

  useEffect(() => {
    let report_id =
      localStorage.getItem("default_dashboard") &&
      JSON.parse(localStorage.getItem("default_dashboard"));

    let selectedReport = null;

    if (props?.diyReportById?.found) {
      if (props.diyReportById) {
        selectedReport = props.diyReportById;

        setLayouts(selectedReport?._source?.template?.layout);
        setReports(selectedReport?._source?.template?.reports);
      } else {
        setLayouts({
          lg: [],
        });
        setReports();
      }
    } else if (report_id === "demo_static_report_id") {
      if (selectedStaticReport) {
        selectedReport = selectedStaticReport;

        setLayouts(selectedReport?._source?.template?.layout);
        setReports(selectedReport?._source?.template?.reports);
      } else {
        setLayouts({
          lg: [],
        });
        setReports();
      }
    }
  }, [props?.diyReportById, localStorage.getItem("default_dashboard")]);

  let dateFilterList = [
    "Today",
    "Previous Day",
    "Last 2 Days",
    "Last 7 Days",
    "Last 30 Days",
    "Last 3 Months",
    "SI",
  ];

  const summaryBoxRef = useRef(null);

  useEffect(() => {
    const calculateHeights = () => {
      const outerBoxHeight = summaryBoxRef?.current?.clientHeight;

      if (
        summaryBoxRef.current &&
        summaryBoxRef.current.children[1] &&
        summaryBoxRef.current.children[0]
      ) {
        summaryBoxRef.current.children[0].style.height = `42px`;
        summaryBoxRef.current.children[1].style.height = `${
          outerBoxHeight - 42
        }px`;
      }
    };

    calculateHeights();

    // Use ResizeObserver to listen for changes in the size of summaryBoxRef
    const resizeObserver = new ResizeObserver(calculateHeights);
    summaryBoxRef.current && resizeObserver.observe(summaryBoxRef.current);

    // Cleanup ResizeObserver on component unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []); // Empty dependency array to ensure the effect runs only once on mount

  return (
    <Box
      className="scrollable"
      style={{
        height: "100%",
        maxHeight: `${window.innerHeight}px`,
        overflow: "auto",
        position: "relative",
      }}
    >
      <Stack direction={"column"} spacing={1} height="100%">
        <LoadingIndicator isActive={isRequesting} height={"100%"}>
          {localStorage.getItem("default_dashboard") &&
          JSON.parse(localStorage.getItem("default_dashboard")) !== "" ? (
            <Box
              ref={summaryBoxRef}
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flex: "12",
              }}
            >
              {props.diyReportById._source &&
                props.diyReportById._source.template.length !== 0 && (
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Grid
                      item
                      container
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      alignItems="center"
                      justifyContent={"space-between"}
                      sx={{
                        backgroundColor: "#c3d7dd",
                      }}
                    >
                      <Grid item xl={7} lg={7} md={8} laptop={8}>
                        <Box width={"100%"}>
                          <ToggleButtonGroup
                            exclusive
                            value={myTicketsTime}
                            onChange={timeFilterHandleChange}
                            aria-label="datefilter"
                            style={{
                              border: "none",
                            }}
                          >
                            {dateFilterList?.map((item, index) => (
                              <ToggleButton
                                key={item}
                                value={item ? item : null}
                                aria-label="datefilter"
                                style={{
                                  width: "auto",
                                  display: "flex",
                                  textAlign: "center",
                                  alignItems: "center",
                                }}
                                sx={{
                                  "&.MuiToggleButton-root": {
                                    padding: "0px 20px",
                                    margin: "0px",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    color: "#2a5e6c",
                                    fontWeight: "bold",
                                    lineHeight: 3.06,
                                    borderRadius: "6px",
                                    borderRight:
                                      // index !== dateFilterList.length - 1
                                      // ?
                                      `solid 0.2px #ffffff`,
                                    // : "none",
                                  },
                                  "&.Mui-selected": {
                                    backgroundColor: "#39717c",
                                    color: "#d3e2e6",
                                  },
                                }}
                              >
                                <Tooltip
                                  title={
                                    item === "SI" ? "Since Inception" : null
                                  }
                                >
                                  <span
                                    className="p-0 m-0"
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item}
                                  </span>
                                </Tooltip>
                              </ToggleButton>
                            ))}
                          </ToggleButtonGroup>
                        </Box>
                      </Grid>

                      <Grid
                        item
                        lg={4.9}
                        md={4.9}
                        laptop={3.9}
                        container
                        alignItems={"center"}
                        justifyContent="flex-end"
                        gap={0.5}
                      >
                        {
                          // myTicketsTime === "Custom" &&
                          <Grid item xl={5} lg={5} md={5} laptop={5}>
                            <DatePicker
                              value={
                                !dateFilterList.includes(myTicketsTime) &&
                                createdDate
                                  ? createdDate
                                  : [null, null]
                              }
                              onChange={createdDateValueHandleChange}
                              maxDate={maxDate}
                              onKeyDown={onKeyDown}
                              fieldheight={"35px"}
                              disableFuture
                            />
                          </Grid>
                        }

                        {/* <Grid item xl={5} lg={5} md={5} laptop={5}>
                          <AutoCompleteComponent
                            disablePortal
                            list={[
                              "Custom",
                              // "Custom",
                            ]}
                            fieldheight={"35px"}
                            handleChange={(e, v) => {
                              v === "Custom"
                                ? setMyTicketTime("Custom")
                                : setMyTicketTime(null);
                            }}
                            textFieldProps={{
                              placeholder: "Date",
                            }}
                            value={myTicketsTime}
                            size="small"
                            width="100%"
                          />
                        </Grid> */}
                      </Grid>

                      <Grid item xl={0.1} lg={0.1} md={0.2} laptop={0.2}></Grid>
                    </Grid>
                  </Box>
                )}
              <DetailedReportScreen
                open={detailReportOpen}
                onClose={() => {
                  setDetailReportOpen(false);
                }}
                report={detailReportId}
                platform_list={platform_list}
                activity_list={activity_list}
                statusList={statusList}
                userList={userList}
                setDetailReportOpen={setDetailReportOpen}
                setDetailReportId={setDetailReportId}
                detailReportOpen={detailReportOpen}
                account_list={account_list}
              />
              <Box
                className="scrollable"
                sx={{
                  overflowY: "scroll",
                  "& .MuiDataGrid-virtualScroller": {
                    "&::-webkit-scrollbar": {
                      width: "3px",
                      height: "3px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#b0d2da",
                      borderRadius: "3px",
                      "&:hover": {
                        backgroundColor: "#85bac6",
                      },
                    },
                  },
                }}
              >
                <Grid item xl={12} xs={12}>
                  <Box
                    sx={{
                      marginBottom: 3,
                      height: "auto",
                    }}
                  >
                    <ResponsiveReactGridLayout
                      style={{
                        width: "100%",
                      }}
                      margin={[5, 5]}
                      {...props}
                      isDraggable={false}
                      isResizable={false}
                      layouts={layouts}
                      measureBeforeMount={false}
                      useCSSTransforms={mounted}
                      compactType={compactType}
                      preventCollision={!compactType}
                      onLayoutChange={onLayoutChange}
                      onBreakpointChange={onBreakpointChange}
                    >
                      {generateDOM()}
                    </ResponsiveReactGridLayout>
                  </Box>
                </Grid>
              </Box>{" "}
            </Box>
          ) : (
            !isRequesting && (
              <div className="nodataFoundContainer">
                <p className="nodataFoundText">
                  Add default dashboard to the user in Add Role
                </p>
              </div>
            )
          )}
        </LoadingIndicator>
      </Stack>
    </Box>
  );
}

export default connect(mapStateToProps)(Dragabledashboard);

Dragabledashboard.defaultProps = {
  className: "layout",
  rowHeight: 30,
  onLayoutChange: (layout, layouts) => {},
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  containerPadding: [0, 0],
  resizeHandles: ["se", "ne", "sw", "nw"],
};
