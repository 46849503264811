import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import TelegramAccountDeleteModel from "./model/TelegramAccountDeleteModel";
import TelegramAccountModel from "./model/TelegramAccountModel";
import TelegramAccountPostModel from "./model/TelegramAccountPostModel";
import TelegramAccountPutModel from "./model/TelegramAccountPutModel";

// TelegramAccountEffect
export default class TelegramAccountEffect {
  // calling an API according to the Whatsapp account model
  // Get method for the Telegram account
  static async requestTelegramAccount(params) {
    const endpoint = environment.api.telegramAccount;
    return EffectUtility.getToModel(TelegramAccountModel, endpoint, params);
  }

  // put method for the Whatsapp account
  static async requestPutTelegramAccount(data, id) {
    const endpoint = environment.api.telegramAccount + "/" + id;
    return EffectUtility.putToModel(TelegramAccountPutModel, endpoint, data);
  }

  // post method for the Whatsapp account
  static async requestPostTelegramAccount(data) {
    const endpoint = environment.api.telegramAccount + "/add";

    return EffectUtility.postToModel(TelegramAccountPostModel, endpoint, data);
  }

  // post method for the Telegram Auth
  static async requestPostTelegramAuth(data, callBack) {
    const endpoint = environment.api.telegramAuth;

    const response = EffectUtility.postToModel(
      TelegramAccountPostModel,
      endpoint,
      data
    );
    response?.then((resp) => {
      if (resp && callBack) {
        callBack(resp);
      }
    });
    return response;
  } // post method for the Telegram Guet Otp

  static async requestPostTelegramGetOTP(data, callBackFunction) {
    const endpoint = environment.api.telegram_get_otp;

    const response = EffectUtility.postToModel(
      TelegramAccountPostModel,
      endpoint,
      data
    );

    response.then((resp) => {
      if (resp && callBackFunction) {
        callBackFunction(resp);
      }
    });

    return response;
  }

  // Delete API
  static async requestDeleteTelegramAccount(id) {
    const endpoint = environment.api.telegramAccount + "/" + id;
    return EffectUtility.deleteToModel(TelegramAccountDeleteModel, endpoint);
  }
}
