import BaseReducer from "../../../utilities/BaseReducer";
import AllMentionsAction from "./AllMentionsAction";

// Sl_AllMentionsReduser
export default class Sl_AllMentionsReduser extends BaseReducer {
  initialState = {
    sl_allmentions: [],
  };
  [AllMentionsAction.REQUEST_SL_ALLMENTIONS_FINISHED](state, action) {
    return {
      ...state,
      sl_allmentions: action.payload,
    };
  }
}
