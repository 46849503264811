import BaseReducer from "../../../utilities/BaseReducer";
import GoogleAlertConfigAction from "./GoogleAlertConfigAction";

export default class GoogleAlertConfigFilterReduser extends BaseReducer {
  //initial state of GoogleAlertConfigFilterReduser
  initialState = {
    google_alerts_config_filter: [],
  };
  //GoogleAlertConfigFilterReduser request action finish
  [GoogleAlertConfigAction.REQUEST_GOOGLE_ALERT_CONFIG_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      google_alerts_config_filter: action.payload,
    };
  }
}
