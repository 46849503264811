import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { Box, Grid, ListItem, Tooltip, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { prefixZero_for_singleDigit } from "../../../components/ticketPartials";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import NoDataFound from "../../../components/nodatafound/NoDataFound";

function PlatformwiseTicketsByCategory(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [platformwiseTickets, setPlatformwiseTickets] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError,setApiError]=useState(null)
  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchPlatformwiseTicketsByCategory(props.DateFilter)
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.DateFilter)
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.openDateFilter)
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchPlatformwiseTicketsByCategory(dateRange) {
    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["platform_wise_ticket_by_category"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    try {
      const res = axiosRequest(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      const response = await res;
      if (response.hasOwnProperty("message")) {
        setPlatformwiseTickets(null);
        setApiError(response.message);
      } else {
        setPlatformwiseTickets(response);
        setApiError(null);
      }
    } catch (err) {
      setPlatformwiseTickets(null);
      setApiError(err.message);
      setLoading(false);
    }
  }

  let columns = [];

  columns.push(
    // { field: "id", headerName: "Sl", resizable: false, width: 50, hide: true },
    {
      field: "Category",
      headerName: "Category",
      resizable: true,
      width: 200,
      hide: false,
      renderCell: (params) => {
        const Category = params?.row?.Category || {}; // Safely get platforms

        return (
          <Typography
            className={`${
              params.row.Category.startsWith("Total")
                ? "font-weight-bold p-0 m-0"
                : "font-weight-normal p-0 m-0"
            }`}
          >
            {Category}
          </Typography>
        );
      },
    }
  );

  if (props.platform_list?.length) {
    props.platform_list?.map((col) => {
      columns.push({
        field: col?.platform_id,
        headerName: col?.platform_alias,
        resizable: true,
        width: 150,
        hide: false,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          const platforms = params?.row?.platforms || {}; // Safely get platforms
          const value =
            prefixZero_for_singleDigit(platforms[col.platform_id]) || "00"; // Get value or default to 0

          return (
            <Typography
              className={`${
                params.row.Category.startsWith("Total")
                  ? "font-weight-bold p-0 m-0"
                  : "font-weight-normal p-0 m-0"
              }`}
            >
              {value} {/* Render the value */}
            </Typography>
          );
        },
      });
    });
  }

  let updatedRows = [];

  platformwiseTickets?.ticket_report?.platform_wise_ticket_by_category[0]?.children?.map(
    (item, index) => {
      updatedRows?.push({
        id: index + 1,
        Category: `${item?.value} (${item?.count})`,
        platforms: item?.platforms,
      });
    }
  );

  const totalRow = {
    id: updatedRows.length + 1,
    Category: `Total (${platformwiseTickets?.ticket_report?.platform_wise_ticket_by_category[1]?.total_tickets})`,
    platforms: {},
  };

  props.platform_list?.forEach((col) => {
    const platformId = col.platform_id;
    const totalValue =
      platformwiseTickets?.ticket_report
        ?.platform_wise_ticket_by_category?.[1]?.[platformId] ?? "00";

    totalRow.platforms[platformId] = prefixZero_for_singleDigit(totalValue);
  });

  platformwiseTickets?.ticket_report?.platform_wise_ticket_by_category[0]
    ?.children?.length > 0 && updatedRows.push(totalRow);
  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      updatedRows?.map((item, index) => {
        // Create a new entry for exportData
        const exportEntry = {
          Category: item?.Category,
        };

        if (props.platform_list?.length) {
          props.platform_list?.map((col) => {
            const platforms = item?.platforms || {}; // Safely get platforms

            exportEntry[`${col?.platform_alias}`] = platforms[col?.platform_id]
              ? prefixZero_for_singleDigit(platforms[col?.platform_id])
              : "00";
          });
        }
        exportData.push(exportEntry);
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, updatedRows]);

  return props.dataGridHeight ? (
    <LoadingIndicator isActive={loading}>
      <Box height={"100%"}>
        {!loading ? (
          <Box height={"100%"}>
            {apiError ? (
                        <Box
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            flex: "12",
                          }}
                        >
                          {apiError}
                        </Box>
                      ) :!loading &&
                        updatedRows.length > 0 &&
                        props.dataGridHeight.current ? (
                          <Box
                            sx={{
                              padding: "12px",
                              height: "100%",
                            }}
                          >
                            <Grid
                              item
                              container
                              lg={12}
                              md={12}
                              sm={12}
                              sx={{ height: 400 }}
                            >
                              {props.dataGridHeight.current && (
                                <DataGridFunction
                                  rows={updatedRows}
                                  columns={columns}
                                  height={props.dataGridHeight.current}
                                  rowHeight={50}
                                  headerHeight={50}
                                  onCellClick={onCellClick}
                                  rowBorder={false}
                                  rowColor={true}
                                  headerColor={true}
                                />
                              )}
                            </Grid>
                          </Box>
                        ) : (
                          !loading && <NoDataFound />
                        )}
            
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  ) : null;
}

export default PlatformwiseTicketsByCategory;
