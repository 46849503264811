import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import Description from "./descriptionContainer/Description";
import { Box, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { axiosRequest } from "../../../utilities/FetchRequest";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
const PlatformwiseStatus = (props) => {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const fetchData = async (dateRange) => {
    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["platform_wise_status"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    try {
      const response = await axiosRequest(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      if (response.hasOwnProperty("message")) {
        setData(null);
        setApiError(response.message);
      } else {
        setData(response);
        setApiError(null);
      }
    } catch (err) {
      setData(null);
      setApiError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchData(props.DateFilter);
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.DateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  function returnPlatformName(platformID) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === platformID)
        .map((item) => item.platform_alias)
    );
  }

  const rows = [];

  if (data && data.ticket_report && data.ticket_report.platform_wise_status) {
    data.ticket_report.platform_wise_status.forEach((item, index) => {
      const platformName = returnPlatformName(item?.platform_key)?.[0];
      if (item && platformName) {
        const row = {
          id: index + 1, // Ensure a unique id for each row
          platform: platformName,
          total: item?.platform_count,
        };
        rows.push(row);
      }
    });
  }

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  const columns = [
    {
      field: "platform",
      headerName: "Platforms",
      resizable: true,
      flex: 2,
      minWidth: 150,
      renderCell: (params) => (
        <p
          className={
            params.row.platform === "Total"
              ? "font-weight-bold p-0 m-0"
              : "font-weight-normal p-0 m-0"
          }
        >
          {params.row.platform}
        </p>
      ),
    },
  ];

  columns.push({
    field: "total",
    headerName: "Total",
    resizable: true,
    flex: 2,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <p
        className={
          params.row.total === "Total" || params.row.platform === "Total"
            ? "font-weight-bold p-0 m-0"
            : "font-weight-normal p-0 m-0"
        }
      >
        {prefixZero_for_singleDigit(params.row.total)}
      </p>
    ),
  });

  const TicketsCountList =
    data?.ticket_report?.platform_wise_status?.map((item) => ({
      status_bucket: item.status_bucket,
    })) || [];

  const status_id_list = TicketsCountList.flatMap(
    (item) => item.status_bucket?.map((e) => e.status_key) || []
  );

  // const new_status_id_list = [...new Set(status_id_list)];

  const new_status_id_list =
    props.statusList.map((status) => status.status_id) || [];

  new_status_id_list.forEach((item) => {
    const statusName = StatusName(item)[0];
    if (statusName) {
      columns.push({
        field: statusName,
        headerName: statusName,
        minWidth: 100,
        flex: 1,
        resizable: true,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => (
          <p
            className={
              params.row.platform === "Total"
                ? "font-weight-bold p-0 m-0"
                : "font-weight-normal p-0 m-0"
            }
          >
            {params.row[statusName]}
          </p>
        ),
      });
    }
  });

  columns.push({
    field: "Unassigned Tickets Count",
    headerName: "Unassigned",
    resizable: true,
    flex: 2,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <p
        className={
          params.row.platform === "Total"
            ? "font-weight-bold p-0 m-0"
            : "font-weight-normal p-0 m-0"
        }
      >
        {prefixZero_for_singleDigit(params.row["Unassigned Tickets Count"])}
      </p>
    ),
  });

  const rowEntries = [];
  data?.ticket_report?.platform_wise_status?.forEach((item, index) => {
    const row = {
      id: index + 1, // Ensure each row has a unique id
      platform:
        item?.platform_key === "total"
          ? "Total"
          : returnPlatformName(item?.platform_key)?.[0] || "Unknown Platform",
      total: prefixZero_for_singleDigit(item.platform_count),
      ["Unassigned Tickets Count"]: prefixZero_for_singleDigit(
        item.unassigned_tickets_count
      ),
    };

    new_status_id_list.forEach((key) => {
      const statusName = StatusName(key)[0];
      if (statusName || key === "total") {
        const filteredStatus = item.status_bucket.find(
          (val) => val.status_key === key
        );
        row[statusName] = prefixZero_for_singleDigit(
          filteredStatus?.status_count || 0
        );
      }
    });

    rowEntries.push(row);
  });

  function StatusName(val) {
    return (
      props.statusList &&
      props.statusList
        .filter((item) => item.status_id === val)
        .map((item) => item.status_name)
    );
  }

  useEffect(() => {
    if (props.DownloadClicked) {
      const rearrangedArray = rowEntries.map((item) => {
        // Create a new object with the desired order
        const rearrangedItem = {
          platform: item.platform, // Add platform as the first key
          ...Object.keys(item)
            .filter((key) => key !== "platform" && key !== "total")
            .reduce((acc, key) => {
              acc[key] = item[key];
              return acc;
            }, {}), // Add all other keys except platform and total
          total: prefixZero_for_singleDigit(item.total), // Add total as the last key
        };

        delete rearrangedItem["id"];

        // Rename the key
        const updatedData = {
          ...rearrangedItem,
          Unassigned: rearrangedItem["Unassigned Tickets Count"],
        };
        delete updatedData["Unassigned Tickets Count"];

        return updatedData;
      });

      props.setexcelData(rearrangedArray);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, rowEntries]);

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {apiError && !loading && props.dataGridHeight ? (
            <Box
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flex: "12",
              }}
            >
              {apiError}
            </Box>
          ) : (
            !loading && (
              <div
                style={{
                  width: "100%",
                  overflowX: "auto",
                  borderRadius: theme.table.borderRadius,
                }}
              >
                {props.dataGridHeight.current && rowEntries ? (
                  <DataGridFunction
                    rows={rowEntries}
                    columns={columns}
                    initialState={{
                      pinnedColumns: {
                        left: ["platform", "total"],
                      },
                    }}
                    rowBorder={false}
                    isRequesting={loading}
                    height={props.dataGridHeight.current}
                    rowHeight={50}
                    headerHeight={80}
                    onCellClick={onCellClick}
                    rowColor={true}
                    headerColor={true}
                    getRowId={(row) => row.id} // Ensure custom row ID assignment
                  />
                ) : (
                  <NoDataFound />
                )}
              </div>
            )
          )}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage />
        </Box>
      )}
    </LoadingIndicator>
  );
};

export default PlatformwiseStatus;
