import ActionUtility from "../../../../utilities/ActionUtility";
import promptConfigEffect from "./promptConfigEffect";

export default class promptConfigACtion {
  static REQUEST_GET_PROMPT = "promptConfigACtion.REQUEST_GET_PROMPT";
  static REQUEST_GET_PROMPT_FINISHED =
    "promptConfigACtion.REQUEST_GET_PROMPT_FINISHED";

  static REQUEST_GET_PROMPT_FILTER =
    "promptConfigACtion.REQUEST_GET_PROMPT_FILTER";
  static REQUEST_GET_PROMPT_FILTER_FINISHED =
    "promptConfigACtion.REQUEST_GET_PROMPT_FILTER_FINISHED";

  static REQUEST_POST_PROMPT = "promptConfigACtion.REQUEST_POST_PROMPT";
  static REQUEST_POST_PROMPT_FINISHED =
    "promptConfigACtion.REQUEST_POST_PROMPT_FINISHED";

  static REQUEST_PUT_PROMPT = "promptConfigACtion.REQUEST_PUT_PROMPT";
  static REQUEST_PUT_PROMPT_FINISHED =
    "promptConfigACtion.REQUEST_PUT_PROMPT_FINISHED";

  static REQUEST_POST_PROMPT_CHECKER =
    "promptConfigACtion.REQUEST_POST_PROMPT_CHECKER";
  static REQUEST_POST_PROMPT_CHECKER_FINISHED =
    "promptConfigACtion.REQUEST_POST_PROMPT_CHECKER_FINISHED";

  // METHODS
  static requestPrompts(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        promptConfigACtion.REQUEST_GET_PROMPT,
        promptConfigEffect.requestPrompts,
        params,
        callback
      );
    };
  }

  static requestPromptsFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        promptConfigACtion.REQUEST_GET_PROMPT_FILTER,
        promptConfigEffect.requestPromptsFilter,
        params
      );
    };
  }

  static requestPostPrompts(data, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        promptConfigACtion.REQUEST_POST_PROMPT,
        promptConfigEffect.requestPostPrompts,
        data,
        callBackFunction
      );
    };
  }

  static requestPostPromptsChecker(data, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        promptConfigACtion.REQUEST_POST_PROMPT_CHECKER,
        promptConfigEffect.requestPostPromptsChecker,
        data,
        callBackFunction
      );
    };
  }

  static requestPutPrompts(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        promptConfigACtion.REQUEST_PUT_PROMPT,
        promptConfigEffect.requestPutPrompts,
        data,
        id
      );
    };
  }
}
