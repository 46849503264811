import React, { useEffect, useMemo, useRef, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MyRoute from "../Route/routes";
import { connect, useDispatch } from "react-redux";
import MyAuthRoute from "../Route/Authroute";
import MenuNavLink from "./components/MenuNavLink";
import logo from "../logo/logo.webp";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import "./MainNav.css";
import A3WelcomePage from "../../a3label_org_intranet/a3labels/views/welcomePage/A3WelcomePage";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import AppsIcon from "@mui/icons-material/AppsOutlined";
import {
  Card,
  CardMedia,
  ClickAwayListener,
  Grid,
  Grow,
  Paper,
  Popper,
  Switch,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AuthAction from "../../stores/Auth/AuthAction";
import WorkingOnProjectNav from "../../views/WorkingOnProjectNav/WorkingOnProjectNav";
import StatusDropDown from "../statusDropdown/StatusDropDown";
import BuildIcon from "@mui/icons-material/Build";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WebIcon from "@mui/icons-material/Web";

// eslint-disable-next-line no-unused-vars
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { replace } from "lodash";
import AvatarIcon from "./components/AvatarIcon";
import BarChartIcon from "@mui/icons-material/BarChart";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import NotificationBox from "../notificationPopOver/NotificationBox";
import jwtDecode from "jwt-decode";
import ErrorLogPopper from "./components/ErrorLogPopper";
import {
  ClearErrorLogs,
  ErrorLogs,
} from "../../middlewares/errorToastMiddleware";
import environment from "environment";
import SetUserAction from "../../stores/setUser/SetUserAction";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import UserAction from "../../ormTicketing/stores/user/UserAction";
import UserLastWorkingProjectAction from "../../ormTicketing/stores/userLastWorkingProject/UserLastWorkingProjectAction";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartSimple,
  faCloud,
  faFolderOpen,
  faGear,
  faTicket,
  faUserGear,
} from "@fortawesome/free-solid-svg-icons";
import NewReleaseDialog from "../../a3label_org_intranet/a3labels/views/releases/NewReleaseDialog";

import PagePermissionAction from "../../stores/pagePermission/PagePermissionAction";
import StatusAction from "../../ormTicketing/stores/status/StatusAction";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import { newroutes } from "../Route/routecoll";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import A3NotificationBox from "../../a3label_org_intranet/a3labels/components/notificationPopOver/A3NotificationBox";
import A3NotificationAction from "../../a3label_org_intranet/a3labels/stores/notificationsA3/A3NotificationAction";
import OrmNotificationAction from "../../stores/ormNotifications/ORMNotificationAction";
import NotificationDialog from "../../ormTicketing/views/notifications/NotificationDialog";
import { faObjectGroup } from "@fortawesome/free-regular-svg-icons";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import TuneIcon from "@mui/icons-material/Tune";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import GoogleIcon from "@mui/icons-material/Google";
import DIYReportTemplateAction from "../../ormTicketing/stores/newReportTemplate/DIYReportTemplateAction";
import TelegramIcon from "@mui/icons-material/Telegram";
import ChatBot from "../../views/chatBot/ChatBot";
import CampaignIcon from "@mui/icons-material/Campaign";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";

// ***************************************************************************************

const drawerWidth = 220;
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    AuthAction.REQUEST_LOGIN,
    UserAction.REQUEST_USER_BY_ID,
    UserLastWorkingProjectAction.REQUEST_LAST_WORKING_PROJECT_BY_ID,
    A3NotificationAction.REQUEST_FILTER_NOTIFICATIONS,
    OrmNotificationAction.REQUEST_ORM_NOTIFICATIONS_BY_ID,
    OrmNotificationAction.REQUEST_ORM_NOTIFICATIONS_FILTER,
  ]),
  auth: state.auth,
  userById: state.userById.userById || [],
  lastWorkingProject:
    state.userLastWorkingProjectById.userLastWorkingProjectById || {},
  A3NotificationsFilter:
    state.A3NotificationsFilter.A3NotificationsFilter || [],
  ormNotificationsById: state.ormNotificationsById.ormNotificationsById || [],
  ormNotificationsFilter:
    state.ormNotificationsFilter.ormNotificationsFilter || [],
});
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,

  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: `calc(100% - 21px - calc(${theme.spacing(8)}))`,
  marginRight: 15,
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: theme.shadows,
  borderTopRightRadius: "10px",
  borderTopLeftRadius: "10px",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // margin Left: drawerWidth,
    width: `calc(100% - ${drawerWidth}px - 20px)`,
    boxShadow: theme.shadows,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// *****************************************************************************************************************************
function MiniDrawer(props) {
  const theme = useTheme();
  const { isRequesting } = props;
  const [routes, setRoutes] = useState({ data: [] });

  async function AppRoutes() {
    const data = await newroutes();
    setRoutes(data);
  }

  useEffect(() => {
    AppRoutes();
  }, []);

  let pathname = window.location.pathname;
  const [openReleaseDialog, setOpenReleaseDialog] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  // getting page_permission from local Storage
  let page_permission = localStorage.getItem("page_permission")
    ? [...JSON.parse(localStorage.getItem("page_permission"))]
    : [];

  page_permission = [...new Set(page_permission)];

  const [notificationsData, setNotificationData] = useState({});
  const [openNotificationDialog, setOpenNotificationsDialog] = useState(false);

  let selectedRow = null;

  const io = require("socket.io-client");
  const [IsUserLogin, setIsUserLogin] = useState(false);
  const [socketConnected, setSocketConnected] = useState(false);
  const [newNotificationsCount, setNewNotificationsCount] = useState(0);
  const [newA3NotificationsCount, setNewA3NotificationsCount] = useState(0);
  const [notificationReceived, setNotificationReceived] = useState(null);
  const [drsNotificationReceived, setDsrNotificationReceived] = useState(null);

  const socketRef = useRef(null);

  // Socket service for notifications

  useEffect(() => {
    const handleBeforeUnload = () => {
      // alert("beforeUnload " + socketRef?.current.id);

      // Disconnect the socket when the page is about to unload
      if (socketRef.current && socketConnected) {
        socketRef.current.disconnect();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);

      // Cleanup function: Disconnect the socket when the component is unmounted
      if (socketRef.current && socketConnected) {
        socketRef.current.disconnect();
      }
    };
  }, [socketConnected]);

  useEffect(() => {
    if (IsUserLogin) {
      fetchOrmNotifications();

      //  props.dispatch(A3NotificationAction.requestA3FilterNotifications(params));
    }

    if (IsUserLogin && !socketConnected) {
      // alert("CONNECTING TO SOCKET");
      const userId = jwtDecode(localStorage.getItem("Auth")).sub;
      socketRef.current = io(`wss://${environment.api.socketApi}`, {
        transportOptions: {
          polling: {
            extraHeaders: {
              "user-id": userId,
            },
          },
        },
        logger: false,
      });

      socketRef.current.on("connect", () => {
        // alert("Connected to Socket");
        setSocketConnected(true);
        localStorage.setItem("socket_id", socketRef.current.id);

        socketRef.current.on("message", (data) => {
          alert("Received message:", data);
        });
      });
    }

    return () => {
      // Disconnect the socket when the component is re-rendered
      if (socketRef.current && socketConnected) {
        socketRef.current.disconnect();
        setSocketConnected(false);
      }
    };
  }, [IsUserLogin]);

  useEffect(() => {
    if (socketConnected) {
      socketRef.current.on("send_notification", (data) => {
        setNotificationReceived(data);
      });

      socketRef.current.on("dsr_send_notification", (data) => {
        setDsrNotificationReceived(data);
      });

      socketRef.current.on("disconnect", () => {
        // alert("disconnected_from_server");
        setSocketConnected(false);
      });
    }
  }, [socketConnected]);

  if (!IsUserLogin) {
    if (localStorage.getItem("Auth") != null) {
      setIsUserLogin(true);
    } else {
      // Cleanup the socket when the user is not logged in
      if (socketRef.current && socketConnected) {
        socketRef.current.disconnect();
        setSocketConnected(false);
      }
    }
  }
  // *******************************************************************************

  useEffect(() => {
    if (notificationReceived !== null) {
      if (notificationReceived !== null) {
        setNotificationData({
          id: null,
          created_by: notificationReceived.created_by,
          created_on: new Date(
            notificationReceived.created_on
          ).toLocaleString(),
          modified_by: notificationReceived.modified_by,
          modified_on: notificationReceived.modified_on,
          notification_image_url: notificationReceived.notification_image_url,
          notification_msg: notificationReceived.notification_msg,
          notification_msg_read_status:
            notificationReceived.notification_msg_read_status,
          notification_sent: notificationReceived.notification_sent,
          notification_title: notificationReceived.notification_title,
          notification_type: notificationReceived.notification_type,
        });
      }

      setOpenNotificationsDialog(true);

      setNotificationReceived((prev) => null);

      setNewNotificationsCount((prev) => {
        return prev + 1;
      });
    }
  }, [notificationReceived]);

  useEffect(() => {
    if (drsNotificationReceived) {
      if (page_permission.includes("dsr_activity")) {
        navigate("/dsr_activity", {
          state: {
            alert: true,
          },
        });
      }
    }

    setDsrNotificationReceived((prev) => null);
  }, [drsNotificationReceived]);

  useEffect(() => {
    setNewA3NotificationsCount(
      props.A3NotificationsFilter.hits?.hits?.filter(
        (item) => item._source.notification_msg_read_status === false
      ).length
    );
  }, [props.A3NotificationsFilter]);

  useEffect(() => {
    setNewNotificationsCount(
      props.ormNotificationsById.hits?.filter(
        (item) => item._source.notification_msg_read_status === false
      ).length
    );
  }, [props.ormNotificationsById]);

  if (!IsUserLogin) {
    if (localStorage.getItem("Auth") != null) {
      setIsUserLogin(true);
    } else {
      socketRef.current && socketRef.current.close();
    }
  }

  if (routes) {
    routes?.data
      .filter((item) => item.path === pathname)
      .map((value) => (selectedRow = value.id));
  }

  const [welcomeOpen, setWelcomeOpen] = React.useState(
    JSON.parse(localStorage.getItem("open"))
  );

  useEffect((event) => {
    if (localStorage.getItem("open") !== null) {
      setWelcomeOpen(JSON.parse(localStorage.getItem("open")));
      if (localStorage.getItem("Auth") === null) {
        navigate("/");
      }
    } else {
      localStorage.setItem("open", true);
      setWelcomeOpen(JSON.parse(localStorage.getItem("open")));
    }

    if (localStorage.getItem("Auth") === null) {
      navigate("/", { state: { from: location.pathname } });
    } else {
      setIsUserLogin(true);
      let ID = localStorage.getItem("userId");
      props.dispatch(
        UserLastWorkingProjectAction.requestLastWorkingProjectById(ID)
      );
      let param = {
        page_limit: "none",
      };

      props
        .dispatch(PagePermissionAction.requestPagePermission(param))
        .then(async () => {
          await props.dispatch(StatusAction.requestStatusClear());
          await props.dispatch(StatusAction.requestStatusFilterClear());

          if (location.state && location.state.from) {
            if (location.state.from === "/") {
              navigate(`/dashboard`);
              replace = true;

              let report_id =
                localStorage.getItem("default_dashboard") &&
                JSON.parse(localStorage.getItem("default_dashboard"));
              JSON.parse(localStorage.getItem("default_dashboard")) &&
                props.dispatch(
                  DIYReportTemplateAction.requestDIYReportsById(report_id)
                );
            } else {
              navigate(`${location.state.from}`);
            }
          }
          if (location.pathname) {
            if (location.pathname === "/") {
              navigate("/dashboard");
            } else {
              navigate(`${location.pathname}`);
            }
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchOrmNotifications() {
    let params = {
      // page_limit: "none",
      order_by: `[["created_on","desc"]]`,
      filters: `[["notification_msg_read_status","must","","match","false"]]`,
    };

    // the below callback When the user login for the first time, the latest unread notification need to open
    let callback = (Response) => {
      Response?.hits &&
        Response.hits?.hits?.length > 0 &&
        setNotificationData({
          id: Response.hits?.hits[0]._id,
          created_by: Response.hits?.hits[0]?._source.created_by,
          created_on: new Date(
            Response.hits?.hits[0]?._source.created_on
          ).toLocaleString(),
          modified_by: Response.hits?.hits[0]?._source.modified_by,
          modified_on: Response.hits?.hits[0]?._source.modified_on,
          notification_image_url:
            Response.hits?.hits[0]?._source.notification_image_url,
          notification_msg: Response.hits?.hits[0]?._source.notification_msg,
          notification_msg_read_status:
            Response.hits?.hits[0]?._source.notification_msg_read_status,
          notification_sent: Response.hits?.hits[0]?._source.notification_sent,
          notification_title:
            Response.hits?.hits[0]?._source.notification_title,
          notification_type: Response.hits?.hits[0]?._source.notification_type,
        });

      Response?.hits?.hits &&
        Response.hits.hits.length > 0 &&
        setOpenNotificationsDialog(true);
    };

    // props.dispatch(OrmNotificationAction.requestOrmNotificationsFilter(params));

    const userId = localStorage.getItem("userId");
    props.dispatch(
      OrmNotificationAction.requestOrmNotificationsById(userId, params)
    );
  }

  const [open, setOpen] = React.useState(true);
  const [openFeeds, setOpenFeeds] = React.useState(true);
  const [openSettings, setOpenSettings] = React.useState(true);
  const [openTicketSettings, setOpenTicketSettings] = React.useState(true);
  const [openWorkflowSettings, setOpenWorkflowSettings] = React.useState(true);

  const [openUserSettings, setOpenUserSettings] = React.useState(true);
  const [openReports, setOpenReports] = React.useState(true);
  const [openSocialPosting, setOpenSocialPosting] = React.useState(true);

  const [openInfluencer, setOpenInfluencer] = React.useState(true);
  const [openSurveyTool, setOpenSurveyTool] = React.useState(true);
  const [openSiteDiscovery, setOpenSiteDiscovery] = React.useState(true);
  const [openLinkChecking, setOpenLinkChecking] = React.useState(true);
  const [openA3Labels, setOpenA3Labels] = React.useState(false);
  const [openCRMCore, setOpenCRMCore] = React.useState(true);
  const [openReleaseMetaData, setReleaseMetaData] = React.useState(false);
  const [openDraftReleaseMetaData, setDraftReleaseMetaData] =
    React.useState(false);
  const [openYoutubeOfficial, setYoutubeOfficial] = React.useState(false);
  const [openImportReports, setopenImportReports] = React.useState(false);

  const [openRevenue, setOpenRevenue] = React.useState(false);

  const [openfingerPrinting, setOpenFingerPrinting] = React.useState(true);

  const [openMCN, setOpenMCN] = React.useState(true);

  // handle click for feeds submenu
  const handleClickFeeds = () => {
    setOpenFeeds(!openFeeds);
  };

  // handle site discovery
  const handleSiteDiscovery = () => {
    setOpenSiteDiscovery(!openSiteDiscovery);
  };

  // handle link checking
  const handleLinkChecking = () => {
    setOpenLinkChecking(!openLinkChecking);
  };

  //handle MCN submenu
  const handleMCNClickSettings = () => {
    setOpenMCN(!openMCN);
  };

  // handle click for settings submenu
  const handleClickSettings = () => {
    setOpenSettings(!openSettings);
    !openSettings && setOpenTicketSettings(true);
    !openSettings && setOpenUserSettings(true);
    !openSettings && setOpenWorkflowSettings(true);
  };

  //handle click for reports submenu
  const handleReportSettings = () => {
    setOpenReports(!openReports);
  };

  //handle click for reports submenu
  const handleSocialPostingSettings = () => {
    setOpenSocialPosting(!openSocialPosting);
  };

  // handle click for Ticket settings submenu
  const handleClickTicketSettings = () => {
    setOpenTicketSettings(!openTicketSettings);
  };

  // handle click for Workflow settings submenu
  const handleClickWorkflowSettings = () => {
    setOpenWorkflowSettings(!openWorkflowSettings);
  };

  // handle click for User settings submenu
  const handleClickUserSettings = () => {
    setOpenUserSettings(!openUserSettings);
  };

  // handle click for A3Labels settings submenu
  const handleA3Labels = () => {
    setOpenA3Labels(!openA3Labels);
    !openA3Labels && setYoutubeOfficial(true);
    !openA3Labels && setOpenRevenue(true);
    !openA3Labels && setopenImportReports(true);
  };

  // handle click for CRM core settings submenu
  const handleCRMCore = () => {
    setOpenCRMCore(!openCRMCore);
  };

  // handle click for Youtube Official
  const handleYouTubeOfficial = () => {
    setYoutubeOfficial(!openYoutubeOfficial);
  };

  // handle click for Import Reports
  const handleImportReports = () => {
    setopenImportReports(!openImportReports);
  };

  // handle click for Revenue
  const handleRevenue = () => {
    setOpenRevenue(!openRevenue);
  };

  // handle click for Release Information
  const handleReleaseMetaData = () => {
    setReleaseMetaData(!openReleaseMetaData);
  };
  const handleReleasesList = () => {
    setReleaseMetaData(false);
    navigate("/A3release");
  };
  useEffect(() => {
    if (localStorage.getItem("id")) setReleaseMetaData(true);
    else setReleaseMetaData(false);
  }, [localStorage.getItem("id")]);

  // handle click for Draft Release Information
  const handleDraftReleaseMetaData = () => {
    setDraftReleaseMetaData(!openDraftReleaseMetaData);
  };
  const handleDraftReleasesList = () => {
    setDraftReleaseMetaData(false);
    navigate("/A3draftReleases");
  };
  useEffect(() => {
    if (localStorage.getItem("draft_releaseid")) setDraftReleaseMetaData(true);
    else setDraftReleaseMetaData(false);
  }, [localStorage.getItem("draft_releaseid")]);

  // handle fingerPrinting
  const handleFingerPrinting = () => {
    setOpenFingerPrinting(!openfingerPrinting);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // redirect to dashboard when you click on logo
  const logoClick = (item) => {
    navigate("/dashboard");
  };

  // hanlde influencer
  const handleClickInfluencerSettings = () => {
    setOpenInfluencer(!openInfluencer);
  };

  //handle Survey Tool submenu
  const handleSurveyToolClickSettings = () => {
    setOpenSurveyTool(!openSurveyTool);
  };

  let MCN_menu_array = [];
  let MCN_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      MCN_menu_array = routes?.data.filter(
        (col) => col.category === "Youtube" && col.id === allowed_page_Id
      );
      MCN_menu_array?.length &&
        MCN_menu_array[0] &&
        MCN_menu_array_main.push(MCN_menu_array[0]);
    });

  let InfluencerArray = [];
  let InfluencerArrayMain = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      InfluencerArray = routes?.data.filter(
        (col) => col.category === "Influencer" && col.id === allowed_page_Id
      );
      InfluencerArray?.length &&
        InfluencerArray[0] &&
        InfluencerArrayMain.push(InfluencerArray[0]);
    });

  let Survey_menu_array = [];
  let Survey_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      Survey_menu_array = routes?.data.filter(
        (col) => col.category === "Survey" && col.id === allowed_page_Id
      );
      Survey_menu_array.length &&
        Survey_menu_array[0] &&
        Survey_menu_array_main.push(Survey_menu_array[0]);
    });

  let feeds_menu_array = [];
  let feeds_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      feeds_menu_array = routes?.data.filter(
        (col) => col.category === "Feeds" && col.id === allowed_page_Id
      );
      feeds_menu_array.length &&
        feeds_menu_array[0] &&
        feeds_menu_array_main.push(feeds_menu_array[0]);
    });

  let sites_menu_array = [];
  let sites_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      sites_menu_array = routes?.data.filter(
        (col) => col.category === "Site Discovery" && col.id === allowed_page_Id
      );
      sites_menu_array.length &&
        sites_menu_array[0] &&
        sites_menu_array_main.push(sites_menu_array[0]);
    });

  let links_menu_array = [];
  let links_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      links_menu_array = routes?.data.filter(
        (col) => col.category === "Link Checking" && col.id === allowed_page_Id
      );
      links_menu_array.length &&
        links_menu_array[0] &&
        links_menu_array_main.push(links_menu_array[0]);
    });

  let settings_menu_array = [];
  let settings_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      settings_menu_array = routes?.data.filter(
        (col) => col.category === "Settings" && col.id === allowed_page_Id
      );
      settings_menu_array.length &&
        settings_menu_array[0] &&
        settings_menu_array_main.push(settings_menu_array[0]);
    });

  let Ticket_settings_menu_array = [];
  let Ticket_settings_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      Ticket_settings_menu_array = routes?.data.filter(
        (col) =>
          col.category === "Ticket Settings" && col.id === allowed_page_Id
      );
      Ticket_settings_menu_array.length &&
        Ticket_settings_menu_array[0] &&
        Ticket_settings_menu_array_main.push(Ticket_settings_menu_array[0]);
    });

  let workflow_settings_menu_array = [];
  let workflow_settings_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      workflow_settings_menu_array = routes?.data.filter(
        (col) =>
          col.category === "Workflow Settings" && col.id === allowed_page_Id
      );
      workflow_settings_menu_array.length &&
        workflow_settings_menu_array[0] &&
        workflow_settings_menu_array_main.push(workflow_settings_menu_array[0]);
    });

  let User_settings_menu_array = [];
  let User_settings_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      User_settings_menu_array = routes?.data.filter(
        (col) => col.category === "User Settings" && col.id === allowed_page_Id
      );
      User_settings_menu_array.length &&
        User_settings_menu_array[0] &&
        User_settings_menu_array_main.push(User_settings_menu_array[0]);
    });

  let A3Labels_menu_array = [];
  let A3Labels_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      A3Labels_menu_array = routes?.data.filter(
        (col) => col.category === "A3 Labels" && col.id === allowed_page_Id
      );
      A3Labels_menu_array.length &&
        A3Labels_menu_array[0] &&
        A3Labels_menu_array_main.push(A3Labels_menu_array[0]);
    });

  let Reports_menu_array = [];
  let Reports_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      Reports_menu_array = routes?.data.filter(
        (col) => col.category === "Reports" && col.id === allowed_page_Id
      );
      Reports_menu_array.length &&
        Reports_menu_array[0] &&
        Reports_menu_array_main.push(Reports_menu_array[0]);
    });

  let social_posting_menu_array = [];
  let social_posting_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      social_posting_menu_array = routes?.data.filter(
        (col) => col.category === "Social_Posting" && col.id === allowed_page_Id
      );
      social_posting_menu_array.length &&
        social_posting_menu_array[0] &&
        social_posting_menu_array_main.push(social_posting_menu_array[0]);
    });

  let CRMCore_menu_array = [];
  let CRMCore_menu_array_main = [];
  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      CRMCore_menu_array = routes?.data.filter(
        (col) => col.category === "CRM Core" && col.id === allowed_page_Id
      );
      CRMCore_menu_array.length &&
        CRMCore_menu_array[0] &&
        CRMCore_menu_array_main.push(CRMCore_menu_array[0]);
    });

  let UtubeCMS_menu_array = [];
  let UtubeCMS_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      UtubeCMS_menu_array = routes?.data.filter(
        (col) =>
          col.category === "YoutubeOfficial" && col.id === allowed_page_Id
      );
      UtubeCMS_menu_array.length &&
        UtubeCMS_menu_array[0] &&
        UtubeCMS_menu_array_main.push(UtubeCMS_menu_array[0]);
    });

  let ImportReports_menu_array = [];
  let ImportReports_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      ImportReports_menu_array = routes?.data.filter(
        (col) => col.category === "Import Reports" && col.id === allowed_page_Id
      );
      ImportReports_menu_array.length &&
        ImportReports_menu_array[0] &&
        ImportReports_menu_array_main.push(ImportReports_menu_array[0]);
    });

  let Revenue_menu_array = [];
  let Revenue_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      Revenue_menu_array = routes?.data.filter(
        (col) => col.category === "Revenue" && col.id === allowed_page_Id
      );
      Revenue_menu_array.length &&
        Revenue_menu_array[0] &&
        Revenue_menu_array_main.push(Revenue_menu_array[0]);
    });

  let Releases_menu_array = [];
  let Releases_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      Releases_menu_array = routes?.data.filter(
        (col) => col.category === "Releases" && col.id === allowed_page_Id
      );
      Releases_menu_array.length &&
        Releases_menu_array[0] &&
        Releases_menu_array_main.push(Releases_menu_array[0]);
    });

  let Draft_Releases_menu_array = [];
  let Draft_Releases_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      Draft_Releases_menu_array = routes?.data.filter(
        (col) => col.category === "Draft Releases" && col.id === allowed_page_Id
      );
      Draft_Releases_menu_array.length &&
        Draft_Releases_menu_array[0] &&
        Draft_Releases_menu_array_main.push(Draft_Releases_menu_array[0]);
    });

  let fingerPrinting_menu_array = [];
  let fingerPrinting_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      fingerPrinting_menu_array = routes?.data.filter(
        (col) => col.category === "FingerPrinting" && col.id === allowed_page_Id
      );
      fingerPrinting_menu_array.length &&
        fingerPrinting_menu_array[0] &&
        fingerPrinting_menu_array_main.push(fingerPrinting_menu_array[0]);
    });

  const returnMainMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "Main" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <ListItem
              key={text.id + index}
              disablePadding
              selected={selectedRow === text.id}
              onClick={(event) => {
                localStorage.setItem("pathIndex", text.id + index);
              }}
              sx={{
                display: text.hide === true ? "none" : "block",
              }}
            >
              <MenuNavLink to={`${text.path}`}>
                <ListItemButton title={!open ? text.title : null}>
                  <ListItemIcon
                    sx={{
                      color:
                        selectedRow === text.id
                          ? theme.palette.background.default
                          : theme.palette.primary.main,
                      minWidth: "36px",
                    }}
                  >
                    {text.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={text.title}
                    sx={{
                      color:
                        selectedRow === text.id
                          ? theme.palette.background.default
                          : theme.palette.primary.main,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
              </MenuNavLink>
            </ListItem>
          );
        })
      : null;
  };

  const returnLinkExtractionMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "DataExtraction" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <ListItem
              key={text.id + index}
              disablePadding
              selected={selectedRow === text.id}
              onClick={(event) => {
                localStorage.setItem("pathIndex", text.id + index);

                let token = JSON.parse(localStorage.getItem("Auth"));

                let params = {
                  access_token: token.access_token,
                };
                dispatch(SetUserAction.requestSetUser(params));
              }}
              sx={{
                display: text.hide === true ? "none" : "block",
              }}
            >
              <MenuNavLink to={`${text.path}`}>
                <ListItemButton title={!open ? text.title : null}>
                  <ListItemIcon
                    sx={{
                      color:
                        selectedRow === text.id
                          ? theme.palette.background.default
                          : theme.palette.primary.main,
                      minWidth: "36px",
                    }}
                  >
                    {text.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={text.title}
                    sx={{
                      color:
                        selectedRow === text.id
                          ? theme.palette.background.default
                          : theme.palette.primary.main,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
              </MenuNavLink>
            </ListItem>
          );
        })
      : null;
  };

  const returnCloudMailMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "Cloud Mail" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <ListItem
              key={text.id + index}
              disablePadding
              selected={selectedRow === text.id}
              onClick={(event) => {
                localStorage.setItem("pathIndex", text.id + index);
              }}
              sx={{
                display: text.hide === true ? "none" : "block",
              }}
            >
              <MenuNavLink to={`${text.path}`}>
                <ListItemButton title={!open ? text.title : null}>
                  <ListItemIcon
                    sx={{
                      color:
                        selectedRow === text.id
                          ? theme.palette.background.default
                          : theme.palette.primary.main,
                      minWidth: "36px",
                    }}
                  >
                    {text.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={text.title}
                    sx={{
                      color:
                        selectedRow === text.id
                          ? theme.palette.background.default
                          : theme.palette.primary.main,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
              </MenuNavLink>
            </ListItem>
          );
        })
      : null;
  };

  const returMCNMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "Youtube" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openMCN}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                        fontSize: "14px",
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
              {/* {index === 2 ? <Divider /> : ""}
            {index === 5 ? <Divider /> : ""}
            {index === 9 ? <Divider /> : ""} */}
            </Collapse>
          );
        })
      : null;
  };

  const returnInfluencerMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "Influencer" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openInfluencer}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                        fontSize: "14px",
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
              {/* {index === 2 ? <Divider /> : ""}
            {index === 5 ? <Divider /> : ""}
            {index === 9 ? <Divider /> : ""} */}
            </Collapse>
          );
        })
      : null;
  };

  const returnSurveyMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "Survey" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openSurveyTool}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                        fontSize: "14px",
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  const returnReportsMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "Reports" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openReports}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  // handleListItemClickFeeds(
                  //   event,
                  //   text.id + index
                  // );
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  const returnSocialPostingMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "Social_Posting" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openSocialPosting}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  // handleListItemClickFeeds(
                  //   event,
                  //   text.id + index
                  // );
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  const returnFeedsMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "Feeds" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openFeeds}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  // handleListItemClickFeeds(
                  //   event,
                  //   text.id + index
                  // );
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color: theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  const returnSiteDiscoveryMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "Site Discovery" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openSiteDiscovery}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  // handleListItemClickFeeds(
                  //   event,
                  //   text.id + index
                  // );
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  const returnLinkCheckingMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "Link Checking" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openLinkChecking}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  // handleListItemClickFeeds(
                  //   event,
                  //   text.id + index
                  // );
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  const returnYoutubeOfficialMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "YoutubeOfficial" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openYoutubeOfficial}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        // marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  const returnImportReportsMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "Import Reports" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openImportReports}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 0,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        // marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  const returnRevenueMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "Revenue" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openRevenue}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        // marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  const returnReleasesMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "Releases" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={openReleaseMetaData && localStorage.getItem("id")}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 4,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  const returnDraftReleasesMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "Draft Releases" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={
                openDraftReleaseMetaData &&
                localStorage.getItem("draft_releaseid")
              }
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 4,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  const returnSettingsMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "Settings" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openSettings}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      // px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        // marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  const returnTicketSettingsMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "Ticket Settings" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openTicketSettings}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        // marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  const returnWorkflowSettingsMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (
          col.category === "Workflow Settings" &&
          col.id === allowed_page_Id
        ) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openWorkflowSettings}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        // marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  const returnUserSettingsMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "User Settings" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openUserSettings}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
                // className="bg-warning"
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        // mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        // marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  const returnA3LabelsMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "A3 Labels" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    let restricted_ids = [
      "A3Upload",
      "A3Tracks",
      "A3Plan",
      //"A3Territories",
      "A3Submission",
      "A3ReleaseDate",
      "A3Promotion",
      "A3releaseInformation",
      "A3Upload(draft)",
      "A3Tracks(draft)",
      "A3Plan(draft)",
      //"A3Territories(draft)",
      "A3Submission(draft)",
      "A3ReleaseDate(draft)",
      "A3Promotion(draft)",
      "A3releaseInformation(draft)",
    ];

    let restricte_val_ids = [
      "A3_validation",
      "A3Upload(draft)",
      "A3Tracks(draft)",
      "A3Plan(draft)",
      //"A3Territories(draft)",
      "A3Submission(draft)",
      "A3ReleaseDate(draft)",
      "A3Promotion(draft)",
      "A3releaseInformation(draft)",
    ];

    let restricted_main_ids = [
      "A3Upload",
      "A3Tracks",
      "A3Plan",
      //"A3Territories",
      "A3Submission",
      "A3ReleaseDate",
      "A3Promotion",
      "A3releaseInformation",
      "A3_validation",
      "A3Upload(draft)",
      "A3Tracks(draft)",
      "A3Plan(draft)",
      //"A3Territories(draft)",
      "A3Submission(draft)",
      "A3ReleaseDate(draft)",
      "A3Promotion(draft)",
      "A3releaseInformation(draft)",
    ];

    let restricted_draft_ids = [
      "A3Upload",
      "A3Tracks",
      "A3Plan",
      //"A3Territories",
      "A3Submission",
      "A3Promotion",
      "A3ReleaseDate",
      "A3releaseInformation",
      "A3_validation",
    ];

    let just_val_id = ["A3_validation"];

    const returnSlicedArray = () => {
      let SlicedArray = [];
      if (
        localStorage.getItem("id") &&
        localStorage.getItem("validation_id") &&
        localStorage.getItem("draft_releaseid")
      ) {
        SlicedArray = filtered_route_main;
      } else if (
        localStorage.getItem("id") &&
        !localStorage.getItem("validation_id") &&
        !localStorage.getItem("draft_releaseid")
      ) {
        SlicedArray = filtered_route_main.filter((item) => {
          return !restricte_val_ids.includes(item.id);
        });
      } else if (
        localStorage.getItem("id") &&
        !localStorage.getItem("validation_id") &&
        localStorage.getItem("draft_releaseid")
      ) {
        SlicedArray = filtered_route_main.filter((item) => {
          return !just_val_id.includes(item.id);
        });
      } else if (
        localStorage.getItem("validation_id") &&
        !localStorage.getItem("id") &&
        !localStorage.getItem("draft_releaseid")
      ) {
        SlicedArray = filtered_route_main.filter((item) => {
          return !restricted_ids.includes(item.id);
        });
      } else if (
        localStorage.getItem("draft_releaseid") &&
        !localStorage.getItem("id") &&
        !localStorage.getItem("validation_id")
      ) {
        SlicedArray = filtered_route_main.filter((item) => {
          return !restricted_draft_ids.includes(item.id);
        });
      } else if (
        !localStorage.getItem("id") &&
        !localStorage.getItem("validation_id") &&
        !localStorage.getItem("draft_releaseid")
      ) {
        SlicedArray = filtered_route_main.filter((item) => {
          return !restricted_main_ids.includes(item.id);
        });
      }

      return SlicedArray;
    };

    if (window.location.pathname === "/A3release") {
      localStorage.getItem("id") && localStorage.removeItem("id");
      localStorage.getItem("draft_releaseid") &&
        localStorage.removeItem("draft_releaseid");

      localStorage.getItem("validation_id") &&
        localStorage.removeItem("validation_id");

      localStorage.getItem("submission_data") &&
        localStorage.removeItem("submission_data");
    }

    if (window.location.pathname === "/A3draftReleases") {
      localStorage.getItem("draft_releaseid") &&
        localStorage.removeItem("draft_releaseid");
      localStorage.getItem("id") && localStorage.removeItem("id");

      localStorage.getItem("submission_data") &&
        localStorage.removeItem("submission_data");
    }

    let sliced_filtered_route_main = returnSlicedArray();

    return sliced_filtered_route_main.length > 0 ? (
      <Collapse
        in={!openA3Labels}
        timeout="auto"
        unmountOnExit
        // key={text.id + index}
      >
        <ListItem
          key={"newRelease"}
          component="div"
          disablePadding
          sx={{
            color: theme.palette.primary.main,
          }}
          onClick={(event) => {
            localStorage.setItem("pathIndex", "newRelease");
            setOpenReleaseDialog(true);
          }}
        >
          <ListItemButton
            title={"New Release"}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: theme.palette.primary.main,
                minWidth: "30px",
                marginRight: "12px",
                marginLeft: "12px",
              }}
            >
              <FontAwesomeIcon icon={faFolderOpen}></FontAwesomeIcon>
            </ListItemIcon>
            <ListItemText
              primary={"New Release"}
              sx={{
                color: theme.palette.primary.main,
                opacity: open ? 1 : 0,
              }}
            />
          </ListItemButton>
        </ListItem>

        {sliced_filtered_route_main.map((text, index) => {
          return (
            <ListItem
              component="div"
              disablePadding
              sx={{
                color: theme.palette.primary.main,
                display: text.hide === true ? "none" : "block",
              }}
              selected={selectedRow === text.id}
              onClick={(event) => {
                localStorage.setItem("pathIndex", text.id + index);
              }}
            >
              <MenuNavLink to={`${text.path}`} page={text.title}>
                <ListItemButton
                  title={!open ? text.title : null}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color:
                        selectedRow === text.id
                          ? theme.palette.background.default
                          : theme.palette.primary.main,
                      minWidth: "30px",
                      marginRight: "12px",
                      marginLeft: "12px",
                    }}
                  >
                    {text.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={text.title}
                    sx={{
                      color:
                        selectedRow === text.id
                          ? theme.palette.background.default
                          : theme.palette.primary.main,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
              </MenuNavLink>
            </ListItem>
          );
        })}
      </Collapse>
    ) : null;
  };

  const returnCRMCoreMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "CRM Core" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });
    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openCRMCore}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                        fontSize: "14px",
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  const returnFingerPrintingMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "FingerPrinting" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });

      // if (col.category === "FingerPrinting") {
      //   filtered_route_main.push(col);
      // }
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openfingerPrinting}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  // ****************  DSR MENU **************************

  const [openDSR, setOpenDSR] = React.useState(true);

  const handleDSRMenuClickSettings = () => {
    setOpenDSR(!openDSR);
  };

  let dsr_menu_array = [];
  let dsr_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      dsr_menu_array = routes?.data.filter(
        (col) => col.category === "DSR" && col.id === allowed_page_Id
      );
      dsr_menu_array.length &&
        dsr_menu_array[0] &&
        dsr_menu_array_main.push(dsr_menu_array[0]);
    });

  const returnDSRMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "DSR" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openDSR}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                        fontSize: "14px",
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  // ****************  WEBSWEEPX MENU **************************

  const [openWebsweepX, setOpenWebsweepX] = React.useState(true);

  const handleWebsweepXMenuClickSettings = () => {
    setOpenWebsweepX(!openWebsweepX);
    !openWebsweepXGoogle && setOpenWebsweepXGoogle(!openWebsweepXGoogle);
    !openWebsweepXTelegram && setOpenWebsweepXTelegram(!openWebsweepXTelegram);
  };

  let WebsweepX_menu_array = [];
  let WebsweepX_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      WebsweepX_menu_array = routes?.data.filter(
        (col) => col.category === "WebsweepX" && col.id === allowed_page_Id
      );
      WebsweepX_menu_array.length &&
        WebsweepX_menu_array[0] &&
        WebsweepX_menu_array_main.push(WebsweepX_menu_array[0]);
    });

  const returnWebsweepXMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "WebsweepX" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openWebsweepX}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                        fontSize: "14px",
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  // ****************  WEBSWEEPX TELEGRAM SUB-MENU **************************

  const [openWebsweepXTelegram, setOpenWebsweepXTelegram] =
    React.useState(true);

  const handleWebsweepXTelegramMenu = () => {
    setOpenWebsweepXTelegram(!openWebsweepXTelegram);
  };

  let WebsweepXTelegram_menu_array = [];
  let WebsweepXTelegram_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      WebsweepXTelegram_menu_array = routes?.data.filter(
        (col) =>
          col.category === "WebsweepXTelegram" && col.id === allowed_page_Id
      );
      WebsweepXTelegram_menu_array.length &&
        WebsweepXTelegram_menu_array[0] &&
        WebsweepXTelegram_menu_array_main.push(WebsweepXTelegram_menu_array[0]);
    });

  const returnWebsweepXTelegramMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (
          col.category === "WebsweepXTelegram" &&
          col.id === allowed_page_Id
        ) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openWebsweepXTelegram}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      // px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                        fontSize: "14px",
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  // ****************  WEBSWEEPX GOGGLE SUB-MENU **************************

  const [openWebsweepXGoogle, setOpenWebsweepXGoogle] = React.useState(true);

  const handleWebsweepXGoogleMenu = () => {
    setOpenWebsweepXGoogle(!openWebsweepXGoogle);
  };

  let WebsweepXGoogle_menu_array = [];
  let WebsweepXGoogle_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      WebsweepXGoogle_menu_array = routes?.data.filter(
        (col) =>
          col.category === "WebsweepXGoogle" && col.id === allowed_page_Id
      );
      WebsweepXGoogle_menu_array.length &&
        WebsweepXGoogle_menu_array[0] &&
        WebsweepXGoogle_menu_array_main.push(WebsweepXGoogle_menu_array[0]);
    });

  const returnWebsweepXGoogleMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "WebsweepXGoogle" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openWebsweepXGoogle}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      // px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                        fontSize: "14px",
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  // ****************  WEBFORM AUTOMATION MENU **************************

  const [openWebform, setOpenWebform] = React.useState(true);

  const handleWebformMenuClickSettings = () => {
    setOpenWebform(!openWebform);
  };

  let Webform_menu_array = [];
  let Webform_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      Webform_menu_array = routes?.data.filter(
        (col) =>
          col.category === "Webform_Automation" && col.id === allowed_page_Id
      );
      Webform_menu_array.length &&
        Webform_menu_array[0] &&
        Webform_menu_array_main.push(Webform_menu_array[0]);
    });

  const returnWebformMenu = (routes) => {
    let filtered_route_main = [];
    let filtered_route = routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (
          col.category === "Webform_Automation" &&
          col.id === allowed_page_Id
        ) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openWebform}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                        fontSize: "14px",
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  // ******************** SOCIAL LISTENING MENU **************************
  const [openSocialListening, setOpenSocialListening] = React.useState(true);

  const handleSocialListeningMenuClickSettings = () => {
    setOpenSocialListening(!openSocialListening);
  };

  let SocialListening_menu_array = [];
  let SocialListening_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      SocialListening_menu_array = routes?.data.filter(
        (col) =>
          col.category === "Social Listening" && col.id === allowed_page_Id
      );
      SocialListening_menu_array.length &&
        SocialListening_menu_array[0] &&
        SocialListening_menu_array_main.push(SocialListening_menu_array[0]);
    });

  const returnSocialListeningMenu = (routes) => {
    let filtered_route_main = [];
    routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "Social Listening" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openSocialListening}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                        fontSize: "14px",
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  // ******************** CLOUDFARE MENU **************************
  const [openCloudfare, setOpenCloudfare] = React.useState(true);

  const handleClodfareMenuClickSettings = () => {
    setOpenCloudfare(!openCloudfare);
  };

  let cloudfare_menu_array = [];
  let cloudfare_menu_array_main = [];

  page_permission &&
    page_permission.length > 0 &&
    page_permission.map((allowed_page_Id) => {
      cloudfare_menu_array = routes?.data.filter(
        (col) => col.category === "Cloudfare" && col.id === allowed_page_Id
      );
      cloudfare_menu_array.length &&
        cloudfare_menu_array[0] &&
        cloudfare_menu_array_main.push(cloudfare_menu_array[0]);
    });

  const returnCloudfareMenu = (routes) => {
    let filtered_route_main = [];
    routes?.data.filter((col) => {
      page_permission.forEach((allowed_page_Id) => {
        if (col.category === "Cloudfare" && col.id === allowed_page_Id) {
          filtered_route_main.push(col);
        }
      });
    });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openCloudfare}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.primary.main,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={!open ? text.title : null}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        minWidth: "30px",
                        marginRight: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color:
                          selectedRow === text.id
                            ? theme.palette.background.default
                            : theme.palette.primary.main,
                        opacity: open ? 1 : 0,
                        fontSize: "14px",
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  const isScreenSmall = useMediaQuery("(max-width:1200px)");

  const [openMenu, setOpenMenu] = React.useState(false);
  const menuPopperRef = React.useRef(null);
  const prevOpen = React.useRef(openMenu);

  useEffect(() => {
    if (!isScreenSmall) {
      setOpenMenu(false);
    }
  }, [isScreenSmall]);

  const handleMenuPopper = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (menuPopperRef.current && menuPopperRef.current.contains(event.target)) {
      return;
    }
    setOpenMenu(false);
  };

  const topHeaderRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  const onlineAlertRef = useRef(null);
  const [alertBoxHeight, setAlertBoxHeight] = useState(0);

  useEffect(() => {
    const topHeaderHeight =
      topHeaderRef.current && topHeaderRef.current.offsetHeight;
    setHeaderHeight(topHeaderHeight);

    const alertHeight =
      onlineAlertRef.current && onlineAlertRef.current.offsetHeight;
    setAlertBoxHeight(alertHeight);
  });

  return (
    // **************************************************************
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          height: "100%",
        }}
        // className="bg-warning"
      >
        {props.auth.access_token === "" ||
        props.auth.access_token === undefined ? (
          <>
            <MyAuthRoute />
          </>
        ) : welcomeOpen && location.pathname === "/" ? (
          <A3WelcomePage
            onClose={() => {
              localStorage.setItem("open", false);
            }}
          />
        ) : (
          <>
            <AppBar
              open={open}
              sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: theme.palette.background.paper,
                scrollbarWidth: "none",
              }}
              ref={topHeaderRef}
            >
              <NotificationDialog
                open={openNotificationDialog}
                onClose={() => {
                  setOpenNotificationsDialog(false);
                }}
                notificationsData={notificationsData}
              />
              <Toolbar sx={{ height: "auto" }}>
                <Grid
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item container xl={5} lg={5} md={5.5} sm={7} xs={6}>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xl={12}
                      container
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {/* LOGO container */}
                      <Grid
                        // xl={5}
                        // lg={5}
                        // md={5}
                        // sm={5}
                        // xs={5}
                        marginLeft={"24px"}
                        className="wrapper"
                      >
                        <Card>
                          <CardMedia
                            component="img"
                            sx={{
                              maxWidth: "120px",
                              maxHeight: "55px",
                              height: "auto",
                            }}
                            image={logo}
                            onClick={logoClick}
                          />
                        </Card>
                      </Grid>

                      {/* Vertical divider */}
                      <Grid
                        // xl={1}
                        // lg={1}
                        // md={1}
                        // sm={1}
                        // xs={1}
                        sx={{
                          borderRight: 1,
                          height: "55px",
                          borderRightColor: theme.palette.text.dark,
                          pl: {
                            xl: "24px",
                            lg: "24px",
                            md: "20px",
                            sm: "20px",
                          },
                        }}
                      ></Grid>

                      {/* Project Selection */}
                      <Grid
                        item
                        // className="bg-warning"
                      >
                        <Typography
                          className="dashboard-title"
                          fontFamily={theme.typography.fontFamily}
                          fontSize={{
                            xl: "24px",
                            lg: "20px",
                            md: "18px",
                            sm: "16px",
                            xs: "10px",
                          }}
                          paddingLeft="17px"
                          overflow={"hidden"}
                          fontWeight={theme.typography.fontWeightRegular}
                          color={theme.palette.primary.main}
                          textAlign="left"
                        >
                          <span
                            onClick={() => {
                              navigate("/projectSelect", {
                                state: { from: location.pathname },
                              });
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <WorkingOnProjectNav />
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item container xl={7} lg={7} md={6.5} sm={5} xs={6}>
                    <Grid
                      item
                      container
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      // className="bg-light"
                    >
                      {/* PHONE ICON CONTAINER */}
                      {/* <Grid
                        // xl={0.5}
                        // lg={0.5}
                        // md={0.5}
                        // sm={0.5}
                        // xs={0.5}
                        // className="bg-light"
                        // p={1}
                        marginLeft={3}
                        marginRight={2}
                        component="button"
                        sx={{
                          backgroundColor: "transparent",
                          textAlign: "inherit",
                          border: "none",
                          color: theme.palette.appbar.icons,
                        }}
                        onClick={() => {
                          navigate("/underDevelopment");
                        }}
                        // className="bg-success"
                      >
                        <LocalPhoneIcon
                          sx={{
                            width: theme.typography.appIcon.width,
                            height: theme.typography.appIcon.width,
                          }}
                        />
                      </Grid> */}
                      <Grid
                        container
                        item
                        lg={12}
                        xl={12}
                        sm={12}
                        sx={{
                          display: {
                            lg: "flex",
                            xl: "flex",
                            laptop: "none",
                            sm: "none",
                          },
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        {/* Vertical Divider */}
                        <Grid
                          sx={{
                            borderRight: 1,
                            height: "45px",
                            borderRightColor:
                              theme.palette.primary.contrastText,
                          }}
                        ></Grid>

                        {/* PLUS ICON CONTAINER */}
                        <Grid
                          // xl={0.5}
                          // lg={0.5}
                          // md={0.5}
                          // sm={0.5}
                          // xs={0.5}
                          // className="bg-dark"
                          component="button"
                          marginRight={2}
                          marginLeft={3}
                          sx={{
                            backgroundColor: "transparent",
                            textAlign: "inherit",
                            border: "none",
                            color: theme.palette.appbar.icons,
                            alignSelf: "center",
                          }}
                          onClick={() => {
                            navigate("/createTicket");
                          }}
                          // className="bg-info"
                        >
                          <Link
                            to="/createTicket"
                            // target="_blank"
                            style={{
                              color: theme.palette.appbar.icons,
                            }}
                          >
                            <Tooltip
                              title="Create Ticket"
                              arrow
                              placement="bottom"
                            >
                              <AddCircleOutlineIcon
                                sx={{
                                  width: theme.typography.appIcon.width,
                                  height: theme.typography.appIcon.width,
                                }}
                              />
                            </Tooltip>
                          </Link>
                        </Grid>

                        {/* BELL ICON CONTAINER */}
                        <Grid
                          // xl={0.5}
                          // lg={0.5}
                          // md={0.5}
                          // sm={0.5}
                          // xs={0.5}
                          // className="bg-info"

                          mr={2}
                          component="button"
                          sx={{
                            backgroundColor: "transparent",
                            textAlign: "inherit",
                            border: "none",
                            color: theme.palette.appbar.icons,
                          }}
                          // className="bg-dark"
                        >
                          <NotificationBox
                            count={newNotificationsCount}
                            setNewNotificationsCount={setNewNotificationsCount}
                            setNotificationReceived={setNotificationReceived}
                            sx={{
                              width: theme.typography.appIcon.width,
                              height: theme.typography.appIcon.width,
                            }}
                          />
                        </Grid>
                        {/* A3 NOTIFICATIONS CONTAINER */}
                       <Grid
                          mr={2}
                          component="button"
                          sx={{
                            backgroundColor: "transparent",
                            textAlign: "inherit",
                            border: "none",
                            color: theme.palette.appbar.icons,
                          }}
                        >
                          <A3NotificationBox
                            count={newA3NotificationsCount}
                            setNewA3NotificationsCount={
                              setNewA3NotificationsCount
                            }
                            setNotificationReceived={setNotificationReceived}
                            sx={{
                              width: theme.typography.appIcon.width,
                              height: theme.typography.appIcon.width,
                            }}
                          />
                        </Grid>

                        {/* Status ICON CONTAINER */}
                        <Grid
                          // xl={3}
                          // lg={2.9}
                          // md={2.8}
                          // sm={2}
                          // xs={2}
                          marginRight={2}
                          width="max-content"

                          // className="bg-light"
                        >
                          <StatusDropDown />
                        </Grid>

                        {/* APP ICON CONTAINER */}
                        <Grid
                          // xl={0.5}
                          // lg={0.5}
                          // md={0.5}
                          // sm={0.5}
                          // xs={0.5}
                          // p={0}
                          marginRight={3}
                          component="button"
                          sx={{
                            backgroundColor: "transparent",
                            textAlign: "inherit",
                            border: "none",
                            color: theme.palette.appbar.icons,
                            alignSelf: "center",
                            // marginLeft: "10px",
                          }}
                          onClick={() => {
                            localStorage.setItem("open", true);
                            setWelcomeOpen(true);
                            navigate("/");
                          }}
                          // className="bg-success"
                        >
                          <Tooltip
                            title="Welcome Board"
                            arrow
                            placement="bottom"
                          >
                            <AppsIcon
                              sx={{
                                width: theme.typography.appIcon.width,
                                height: theme.typography.appIcon.width,
                              }}
                            />
                          </Tooltip>
                        </Grid>

                        {/* Vertical Divider */}
                        <Grid
                          sx={{
                            borderRight: 1,
                            height: "45px",
                            borderRightColor:
                              theme.palette.primary.contrastText,
                          }}
                        ></Grid>

                        {/*  PAGE NAME CONTAINER with GRAMMERLY*/}
                        <Grid
                          paddingX={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* <ErrorLogPopper errorLogs={ErrorLogs}> */}
                          <Typography
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                            }}
                            component="div"
                            fontFamily={theme.typography.fontFamily}
                            fontSize={{
                              xl: "24px",
                              lg: "18px",
                              md: "12px",
                              sm: "11px",
                              xs: "10px",
                            }}
                            // width="100%"
                            fontWeight={theme.typography.fontWeightRegular}
                            color={theme.palette.primary.main}
                          >
                            {routes?.data.map((item) => {
                              if (item.path === pathname) {
                                let text = item.title;
                                const myArray = text && text.split(" ");
                                return (
                                  <>
                                    {myArray &&
                                      myArray.map((item) => (
                                        <div>
                                          <p className="dashboard-title">
                                            {item}
                                          </p>
                                        </div>
                                      ))}
                                  </>
                                );
                              }
                            })}
                            {pathname.search("ticketDetails") !== -1 ? (
                              <p className="dashboard-title">Details</p>
                            ) : null}
                          </Typography>
                          {/* </ErrorLogPopper> */}

                          {/* <Box textAlign="right" p={0}>
                            <Typography
                              fontFamily={theme.typography.fontFamily}
                              fontWeight={theme.typography.fontWeightRegular}
                              color={theme.palette.primary.main}
                              sx={{
                                letterSpacing: 0.5,
                                fontSize: {
                                  xl: "14px",
                                  lg: "12px",
                                  md: "12px",
                                  sm: "11px",
                                  xs: "10px",
                                },
                              }}
                            >
                     
                            </Typography>
                          </Box> */}
                        </Grid>

                        {/* Vertical Divider */}
                        <Grid
                          sx={{
                            borderRight: 1,
                            height: "45px",
                            borderRightColor:
                              theme.palette.primary.contrastText,
                          }}
                        ></Grid>

                        {/* PROFILE CONTAINER */}
                        <Grid
                          paddingRight={1}
                          paddingLeft={2}
                          // className="bg-dark"
                          display="flex"
                          justifyContent="center"
                          alignSelf="center"
                        >
                          <Grid p={1}>
                            <Tooltip title="Profile" arrow placement="bottom">
                              <div
                                onClick={() => {
                                  navigate("/myProfile", {
                                    state: { from: location.pathname },
                                  });
                                  replace = true;
                                  // props.onClose();
                                }}
                              >
                                <AvatarIcon />
                              </div>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* popper conatiner */}

                      <Grid
                        container
                        item
                        lg={12}
                        xl={12}
                        sm={12}
                        sx={{
                          display: {
                            lg: "none",
                            xl: "none",

                            laptop: "flex",
                            sm: "flex",
                          },
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        {/* BELL ICON CONTAINER */}
                        <Grid
                          // xl={0.5}
                          // lg={0.5}
                          // md={0.5}
                          // sm={0.5}
                          // xs={0.5}
                          // className="bg-info"

                          mr={2}
                          component="button"
                          sx={{
                            backgroundColor: "transparent",
                            textAlign: "inherit",
                            border: "none",
                            color: theme.palette.appbar.icons,
                          }}
                          // className="bg-dark"
                        >
                          <NotificationBox
                            count={newNotificationsCount}
                            setNewNotificationsCount={setNewNotificationsCount}
                            sx={{
                              width: theme.typography.appIcon.width,
                              height: theme.typography.appIcon.width,
                            }}
                          />
                        </Grid>

                        {/* A3 NOTIFICATIONS CONTAINER */}
                        {/* <Grid
                          mr={2}
                          component="button"
                          sx={{
                            backgroundColor: "transparent",
                            textAlign: "inherit",
                            border: "none",
                            color: theme.palette.appbar.icons,
                          }}
                        >
                          <A3NotificationBox
                            count={newA3NotificationsCount}
                            setNewA3NotificationsCount={
                              setNewA3NotificationsCount
                            }
                            setNotificationReceived={setNotificationReceived}
                            sx={{
                              width: theme.typography.appIcon.width,
                              height: theme.typography.appIcon.width,
                            }}
                          />
                        </Grid> */}

                        <Typography
                          component={"div"}
                          ref={menuPopperRef}
                          id="composition-button"
                          aria-controls={
                            openMenu ? "composition-menu" : undefined
                          }
                          aria-expanded={openMenu ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={handleMenuPopper}
                          style={{ backgroundColor: "none" }}
                        >
                          <IconButton>
                            <MenuIcon />
                          </IconButton>
                        </Typography>

                        <Popper
                          open={openMenu}
                          anchorEl={menuPopperRef.current}
                          role={undefined}
                          placement="bottom-start"
                          transition
                          disablePortal
                          sx={{ zIndex: 100 }}
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom-start"
                                    ? "left top"
                                    : "left bottom",
                              }}
                            >
                              <Paper style={{ width: 250 }}>
                                <ClickAwayListener onClickAway={handleClose}>
                                  <div
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                  >
                                    <Grid
                                      container
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      p={2}
                                      sx={{ boxShadow: `${theme.shadows}` }}
                                    >
                                      {/*  PAGE NAME CONTAINER with GRAMMERLY*/}
                                      <Grid
                                        item
                                        lg={9}
                                        md={9}
                                        sm={9}
                                        paddingX={2}
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            display: "flex",
                                            justifyContent: "end",
                                            alignItems: "center",
                                          }}
                                          component="div"
                                          fontFamily={
                                            theme.typography.fontFamily
                                          }
                                          // fontSize={{
                                          //   xl: "24px",
                                          //   lg: "18px",
                                          //   md: "12px",
                                          //   sm: "11px",
                                          //   xs: "10px",
                                          // }}
                                          fontSize={"22px"}
                                          // width="100%"
                                          fontWeight={
                                            theme.typography.fontWeightRegular
                                          }
                                          color={theme.palette.primary.main}
                                        >
                                          {routes?.data.map((item) => {
                                            if (item.path === pathname) {
                                              let text = item.title;
                                              const myArray = text.split(" ");
                                              return (
                                                <>
                                                  {myArray.map((item) => (
                                                    <div>
                                                      <p className="dashboard-title">
                                                        {item}
                                                      </p>
                                                    </div>
                                                  ))}
                                                </>
                                              );
                                            }
                                          })}
                                          {pathname.search("ticketDetails") !==
                                          -1 ? (
                                            <p className="dashboard-title">
                                              Details
                                            </p>
                                          ) : null}
                                        </Typography>

                                        {/* <Box textAlign="right" p={0}>
                            <Typography
                              fontFamily={theme.typography.fontFamily}
                              fontWeight={theme.typography.fontWeightRegular}
                              color={theme.palette.primary.main}
                              sx={{
                                letterSpacing: 0.5,
                                fontSize: {
                                  xl: "14px",
                                  lg: "12px",
                                  md: "12px",
                                  sm: "11px",
                                  xs: "10px",
                                },
                              }}
                            >
                       
                            </Typography>
                          </Box> */}
                                      </Grid>

                                      {/* Status ICON CONTAINER */}
                                      <Grid
                                        mt={2}
                                        ml={2}
                                        xl={10}
                                        lg={10}
                                        md={10}
                                        sm={10}
                                        xs={10}

                                        // className="bg-light"
                                      >
                                        <StatusDropDown />
                                      </Grid>

                                      {/* PLUS ICON CONTAINER */}
                                      <Grid
                                        item
                                        mt={2}
                                        ml={2}
                                        lg={2}
                                        md={2}
                                        sm={2}
                                        // xs={0.5}
                                        // className="bg-dark"
                                        component="button"
                                        sx={{
                                          backgroundColor: "transparent",
                                          textAlign: "inherit",
                                          border: "none",
                                          color: theme.palette.appbar.icons,
                                          alignSelf: "center",
                                        }}
                                        onClick={() => {
                                          navigate("/createTicket");
                                        }}
                                        // className="bg-info"
                                      >
                                        <AddCircleOutlineIcon
                                          sx={{
                                            width:
                                              theme.typography.appIcon.width,
                                            height:
                                              theme.typography.appIcon.width,
                                          }}
                                        />
                                      </Grid>

                                      {/* APP ICON CONTAINER */}
                                      <Grid
                                        item
                                        mt={2}
                                        ml={2}
                                        // xl={0.5}
                                        lg={2}
                                        md={2}
                                        sm={2}
                                        // p={0}
                                        // marginRight={3}
                                        component="button"
                                        sx={{
                                          backgroundColor: "transparent",
                                          textAlign: "inherit",
                                          border: "none",
                                          color: theme.palette.appbar.icons,
                                          alignSelf: "center",
                                          // marginLeft: "10px",
                                        }}
                                        onClick={() => {
                                          localStorage.setItem("open", true);
                                          setWelcomeOpen(true);
                                          navigate("/");
                                        }}
                                        // className="bg-success"
                                      >
                                        <AppsIcon
                                          sx={{
                                            width:
                                              theme.typography.appIcon.width,
                                            height:
                                              theme.typography.appIcon.width,
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </div>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>

                        {/* PROFILE CONTAINER */}
                        <Grid
                          item
                          // lg={3}
                          // md={3}
                          // sm={3}
                          // paddingRight={1}
                          // paddingLeft={2}
                          // className="bg-dark"
                          // display="flex"
                          // justifyContent="center"
                          // alignSelf="center"
                        >
                          {/* <Divider
                            orientation="vertical"
                            variant="center"
                            flexItem
                          /> */}
                          <Grid p={1}>
                            <div
                              onClick={() => {
                                navigate("/myProfile", {
                                  state: { from: location.pathname },
                                });
                                replace = true;
                                // props.onClose();
                              }}
                            >
                              <Tooltip name="Profile">
                                <AvatarIcon />
                              </Tooltip>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <Drawer
              variant="permanent"
              open={open}
              hideBackdrop={true}
              sx={{
                "& .MuiDrawer-paper": { border: "none" },
                "& .MuiPaper-root": {
                  backgroundColor: theme.palette.background.primary,
                },
                "& .MuiTypography-root": {
                  fontWeight: theme.typography.fontWeightBold,
                },
              }}
            >
              <DrawerHeader
                sx={{
                  ...(!open && {
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: 1,
                  }),
                }}
              >
                <IconButton
                  color="primary"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    ...(open && { display: "none" }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <IconButton
                  onClick={handleDrawerClose}
                  sx={{ ...(!open && { display: "none" }) }}
                >
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </DrawerHeader>
              {/* <Divider /> */}
              <List
                sx={{
                  // selected and (selected + hover) states
                  "&& .Mui-selected, && .Mui-selected:hover": {
                    bgcolor: theme.palette.buttonComponent.background.primary,
                    color: theme.palette.background.default,
                  },
                }}
                className="scrollable"
              >
                {returnMainMenu(routes)}

                {returnLinkExtractionMenu(routes)}

                {returnCloudMailMenu(routes)}

                {/*********************************A3Labels***************************************************/}
                <ListItem disablePadding sx={{ display: "block" }}>
                  {A3Labels_menu_array_main.length > 0 && (
                    <ListItemButton
                      title={!open ? "A3 Clients" : null}
                      onClick={handleA3Labels}
                    >
                      <ListItemIcon
                        sx={{
                          color: theme.palette.primary.main,
                          minWidth: "36px",
                        }}
                      >
                        <MusicVideoIcon
                          style={{
                            width: 20,
                            height: 20,
                            opacity: 0.9,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="A3 Clients"
                        sx={{
                          color: theme.palette.primary.main,
                          opacity: open ? 1 : 0,
                        }}
                      />
                      <IconButton
                        sx={{
                          ...(open && { display: "none" }),
                        }}
                      >
                        {openA3Labels ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                      <IconButton sx={{ ...(!open && { display: "none" }) }}>
                        {openA3Labels ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                    </ListItemButton>
                  )}

                  {returnA3LabelsMenu(routes)}

                  {/*********************************Releases Nested List***************************************************/}
                  <ListItem
                    sx={{
                      display: "block",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    {Releases_menu_array_main.length > 0 && !openA3Labels && (
                      <ListItemButton
                        title={!open ? "Releases" : null}
                        onClick={handleReleasesList}
                        style={{
                          paddingRight: 0,
                        }}
                        sx={{
                          ...(!openReleaseMetaData &&
                            localStorage.getItem("id") &&
                            Releases_menu_array_main.find((item) =>
                              item.id.includes(
                                localStorage
                                  .getItem("pathIndex")
                                  ?.replace(/\d+$/, "")
                              )
                            ) && {
                              backgroundColor:
                                theme.palette.buttonComponent.background
                                  .primary,
                              color: theme.palette.background.default,
                              "&:hover": {
                                backgroundColor:
                                  theme.palette.buttonComponent.background
                                    .primary,
                              },
                            }),
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            color: "inherit",
                            minWidth: "36px",
                            marginLeft: 2.5,
                          }}
                        >
                          <QueueMusicIcon
                            style={{
                              width: 25,
                              height: 25,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Releases" />
                        <IconButton
                          sx={{
                            ...(!localStorage.getItem("id") && {
                              display: "none",
                            }),
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleReleaseMetaData();
                          }}
                        >
                          {!openReleaseMetaData ? (
                            <ExpandMore />
                          ) : (
                            <ExpandLess />
                          )}
                        </IconButton>
                      </ListItemButton>
                    )}
                    {!openA3Labels && returnReleasesMenu(routes)}
                  </ListItem>

                  {/*********************************Draft Releases Nested List***************************************************/}
                  <ListItem
                    sx={{
                      display: "block",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    {Draft_Releases_menu_array_main.length > 0 &&
                      !openA3Labels && (
                        <ListItemButton
                          title={!open ? "Draft Releases" : null}
                          onClick={handleDraftReleasesList}
                          style={{
                            paddingRight: 0,
                          }}
                          sx={{
                            ...(!openDraftReleaseMetaData &&
                              localStorage.getItem("draft_releaseid") &&
                              Draft_Releases_menu_array_main.find((item) =>
                                item.id.includes(
                                  localStorage
                                    .getItem("pathIndex")
                                    ?.replace(/\d+$/, "")
                                )
                              ) && {
                                backgroundColor:
                                  theme.palette.buttonComponent.background
                                    .primary,
                                color: theme.palette.background.default,
                                "&:hover": {
                                  backgroundColor:
                                    theme.palette.buttonComponent.background
                                      .primary,
                                },
                              }),
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              color: "inherit",
                              minWidth: "36px",
                              marginLeft: 2.5,
                            }}
                          >
                            <TuneIcon
                              style={{
                                width: 25,
                                height: 25,
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary="Draft Releases" />
                          <IconButton
                            sx={{
                              ...(!localStorage.getItem("draft_releaseid") && {
                                display: "none",
                              }),
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDraftReleaseMetaData();
                            }}
                          >
                            {!openDraftReleaseMetaData ? (
                              <ExpandMore />
                            ) : (
                              <ExpandLess />
                            )}
                          </IconButton>
                        </ListItemButton>
                      )}
                    {!openA3Labels && returnDraftReleasesMenu(routes)}
                  </ListItem>

                  {/*********************************YoutubeOfficial Nested List***************************************************/}
                  <ListItem
                    // disablePadding
                    sx={{
                      display: "block",
                      margin: 0,
                      padding: 0,
                      paddingLeft: 2.5,
                    }}
                  >
                    {!openA3Labels && UtubeCMS_menu_array_main.length > 0 && (
                      <ListItemButton
                        title={!open ? "Youtube Official" : null}
                        onClick={handleYouTubeOfficial}
                        style={{
                          // backgroundColor: "gray",
                          padding: 0,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            color: theme.palette.primary.main,
                            minWidth: "36px",
                            // marginLeft: 2,
                          }}
                          // className="bg-warning"
                        >
                          <YouTubeIcon
                            style={{
                              width: 20,
                              height: 20,
                              opacity: 0.9,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Youtube Official"
                          sx={{
                            color: theme.palette.primary.main,
                            opacity: open ? 1 : 0,
                          }}
                        />
                        <IconButton
                          sx={{
                            ...(open && { display: "none" }),
                          }}
                        >
                          {openYoutubeOfficial ? (
                            <ExpandMore />
                          ) : (
                            <ExpandLess />
                          )}
                        </IconButton>
                        <IconButton sx={{ ...(!open && { display: "none" }) }}>
                          {openYoutubeOfficial ? (
                            <ExpandMore />
                          ) : (
                            <ExpandLess />
                          )}
                        </IconButton>
                      </ListItemButton>
                    )}
                    {returnYoutubeOfficialMenu(routes)}
                  </ListItem>

                  {/*********************************Import Reports Nested List***************************************************/}
                  <ListItem
                    // disablePadding
                    sx={{
                      display: "block",
                      margin: 0,
                      padding: 0,
                      paddingLeft: 2.5,
                    }}
                  >
                    {!openA3Labels &&
                      ImportReports_menu_array_main.length > 0 && (
                        <ListItemButton
                          title={!open ? "Import Reports" : null}
                          onClick={handleImportReports}
                          style={{
                            // backgroundColor: "gray",
                            padding: 0,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              color: theme.palette.primary.main,
                              minWidth: "36px",
                              // marginLeft: 2,
                            }}
                            // className="bg-warning"
                          >
                            <YouTubeIcon
                              style={{
                                width: 20,
                                height: 20,
                                opacity: 0.9,
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Import Reports"
                            sx={{
                              color: theme.palette.primary.main,
                              opacity: open ? 1 : 0,
                            }}
                          />
                          <IconButton
                            sx={{
                              ...(open && { display: "none" }),
                            }}
                          >
                            {openImportReports ? (
                              <ExpandMore />
                            ) : (
                              <ExpandLess />
                            )}
                          </IconButton>
                          <IconButton
                            sx={{ ...(!open && { display: "none" }) }}
                          >
                            {openImportReports ? (
                              <ExpandMore />
                            ) : (
                              <ExpandLess />
                            )}
                          </IconButton>
                        </ListItemButton>
                      )}
                    {returnImportReportsMenu(routes)}
                  </ListItem>

                  {/*********************************Revenue Nested List***************************************************/}
                  <ListItem
                    // disablePadding
                    sx={{
                      display: "block",
                      margin: 0,
                      padding: 0,
                      paddingLeft: 2.5,
                    }}
                  >
                    {!openA3Labels && Revenue_menu_array_main.length > 0 && (
                      <ListItemButton
                        title={!open ? "Revenue" : null}
                        onClick={handleRevenue}
                        style={{
                          // backgroundColor: "gray",
                          padding: 0,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            color: theme.palette.primary.main,
                            minWidth: "36px",
                            // marginLeft: 2,
                          }}
                          // className="bg-warning"
                        >
                          <AccountBalanceIcon
                            style={{
                              width: 20,
                              height: 20,
                              opacity: 0.9,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Revenue"
                          sx={{
                            color: theme.palette.primary.main,
                            opacity: open ? 1 : 0,
                          }}
                        />
                        <IconButton
                          sx={{
                            ...(open && { display: "none" }),
                          }}
                        >
                          {openRevenue ? <ExpandMore /> : <ExpandLess />}
                        </IconButton>
                        <IconButton sx={{ ...(!open && { display: "none" }) }}>
                          {openRevenue ? <ExpandMore /> : <ExpandLess />}
                        </IconButton>
                      </ListItemButton>
                    )}
                    {returnRevenueMenu(routes)}
                  </ListItem>
                </ListItem>

                {/*********************************  SOCIAL LISTENING ***************************************************/}
                <ListItem disablePadding sx={{ display: "block" }}>
                  {SocialListening_menu_array_main.length > 0 && (
                    <ListItemButton
                      title={!open ? "Social Listening" : null}
                      onClick={handleSocialListeningMenuClickSettings}
                    >
                      <ListItemIcon
                        sx={{
                          color: theme.palette.primary.main,
                          minWidth: "36px",
                        }}
                      >
                        <CampaignIcon
                          style={{
                            width: 20,
                            height: 20,
                            opacity: 0.9,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Social Listening"
                        sx={{
                          color: theme.palette.primary.main,
                          opacity: open ? 1 : 0,
                          fontSize: "14px",
                        }}
                      />
                      <IconButton
                        sx={{
                          ...(open && { display: "none" }),
                        }}
                      >
                        {openSocialListening ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                      <IconButton sx={{ ...(!open && { display: "none" }) }}>
                        {openSocialListening ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                    </ListItemButton>
                  )}
                  {returnSocialListeningMenu(routes)}
                </ListItem>

                {/*********************************  Cloudfare ***************************************************/}
                <ListItem disablePadding sx={{ display: "block" }}>
                  {cloudfare_menu_array_main.length > 0 && (
                    <ListItemButton
                      title={!open ? "Antipiracy Support" : null}
                      onClick={handleClodfareMenuClickSettings}
                    >
                      <ListItemIcon
                        sx={{
                          color: theme.palette.primary.main,
                          minWidth: "36px",
                        }}
                      >
                        <GppMaybeIcon
                          style={{
                            width: 20,
                            height: 20,
                            opacity: 0.9,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Antipiracy Support"
                        sx={{
                          color: theme.palette.primary.main,
                          opacity: open ? 1 : 0,
                          fontSize: "14px",
                        }}
                      />
                      <IconButton
                        sx={{
                          ...(open && { display: "none" }),
                        }}
                      >
                        {openCloudfare ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                      <IconButton sx={{ ...(!open && { display: "none" }) }}>
                        {openCloudfare ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                    </ListItemButton>
                  )}
                  {returnCloudfareMenu(routes)}
                </ListItem>

                {/*****************************************CRM Core.............................. */}
                <ListItem disablePadding sx={{ display: "block" }}>
                  {CRMCore_menu_array_main.length > 0 && (
                    <ListItemButton
                      title={!open ? "CRMCore" : null}
                      onClick={handleCRMCore}
                    >
                      <ListItemIcon
                        sx={{
                          color: theme.palette.primary.main,
                          minWidth: "36px",
                        }}
                      >
                        <BarChartIcon
                          style={{
                            width: 20,
                            height: 20,
                            opacity: 0.9,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="CRMCore"
                        sx={{
                          color: theme.palette.primary.main,
                          opacity: open ? 1 : 0,
                          fontSize: "14px",
                        }}
                      />
                      <IconButton
                        sx={{
                          ...(open && { display: "none" }),
                        }}
                      >
                        {openCRMCore ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                      <IconButton sx={{ ...(!open && { display: "none" }) }}>
                        {openCRMCore ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                    </ListItemButton>
                  )}
                  {returnCRMCoreMenu(routes)}
                </ListItem>

                {/* Site discovery */}
                <ListItem disablePadding sx={{ display: "block" }}>
                  {sites_menu_array_main.length > 0 && (
                    <ListItemButton
                      title={!open ? "Site Link Discovery" : null}
                      onClick={handleSiteDiscovery}
                    >
                      <ListItemIcon
                        sx={{
                          color: theme.palette.primary.main,
                          minWidth: "36px",
                        }}
                      >
                        <ScreenSearchDesktopIcon
                          style={{
                            width: 20,
                            height: 20,
                            opacity: 0.9,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Site Link Discovery"
                        sx={{
                          color: theme.palette.primary.main,
                          opacity: open ? 1 : 0,
                        }}
                      />
                      <IconButton
                        sx={{
                          ...(open && { display: "none" }),
                        }}
                      >
                        {openSiteDiscovery ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                      <IconButton sx={{ ...(!open && { display: "none" }) }}>
                        {openSiteDiscovery ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                    </ListItemButton>
                  )}
                  {returnSiteDiscoveryMenu(routes)}
                </ListItem>

                {/* link checking */}
                <ListItem disablePadding sx={{ display: "block" }}>
                  {links_menu_array_main.length > 0 && (
                    <ListItemButton
                      title={!open ? "Link Checking" : null}
                      onClick={handleLinkChecking}
                    >
                      <ListItemIcon
                        sx={{
                          color: theme.palette.primary.main,
                          minWidth: "36px",
                        }}
                      >
                        <DomainVerificationIcon
                          style={{
                            width: 20,
                            height: 20,
                            opacity: 0.9,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Link Checking"
                        sx={{
                          color: theme.palette.primary.main,
                          opacity: open ? 1 : 0,
                        }}
                      />
                      <IconButton
                        sx={{
                          ...(open && { display: "none" }),
                        }}
                      >
                        {openLinkChecking ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                      <IconButton sx={{ ...(!open && { display: "none" }) }}>
                        {openLinkChecking ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                    </ListItemButton>
                  )}
                  {returnLinkCheckingMenu(routes)}
                </ListItem>

                {/* **************************************** Youtube ************************************* */}
                <ListItem disablePadding sx={{ display: "block" }}>
                  {MCN_menu_array_main.length > 0 && (
                    <ListItemButton
                      title={!open ? "Youtube" : null}
                      onClick={handleMCNClickSettings}
                    >
                      <ListItemIcon
                        sx={{
                          color: theme.palette.primary.main,
                          minWidth: "36px",
                        }}
                      >
                        <YouTubeIcon
                          style={{
                            width: 20,
                            height: 20,
                            opacity: 0.9,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Youtube"
                        sx={{
                          color: theme.palette.primary.main,
                          opacity: open ? 1 : 0,
                        }}
                      />
                      <IconButton
                        sx={{
                          ...(open && { display: "none" }),
                        }}
                      >
                        {openMCN ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                      <IconButton sx={{ ...(!open && { display: "none" }) }}>
                        {openMCN ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                    </ListItemButton>
                  )}
                  {returMCNMenu(routes)}
                </ListItem>

                {/* Finger Printing */}
                <ListItem disablePadding sx={{ display: "block" }}>
                  {fingerPrinting_menu_array_main.length > 0 && (
                    <ListItemButton
                      title={!open ? "Finger Printing" : null}
                      onClick={handleFingerPrinting}
                    >
                      <ListItemIcon
                        sx={{
                          color: theme.palette.primary.main,
                          minWidth: "36px",
                        }}
                      >
                        <FingerprintIcon
                          style={{
                            width: 20,
                            height: 20,
                            opacity: 0.9,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Finger Printing"
                        sx={{
                          color: theme.palette.primary.main,
                          opacity: open ? 1 : 0,
                        }}
                      />
                      <IconButton
                        sx={{
                          ...(open && { display: "none" }),
                        }}
                      >
                        {openfingerPrinting ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                      <IconButton sx={{ ...(!open && { display: "none" }) }}>
                        {openfingerPrinting ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                    </ListItemButton>
                  )}
                  {returnFingerPrintingMenu(routes)}
                </ListItem>

                {/* ***************************** Influencer ***************************** */}
                <ListItem disablePadding sx={{ display: "block" }}>
                  {InfluencerArrayMain.length > 0 && (
                    <ListItemButton
                      title={!open ? "Influencer" : null}
                      onClick={handleClickInfluencerSettings}
                    >
                      <ListItemIcon
                        sx={{
                          color: theme.palette.primary.main,
                          minWidth: "36px",
                        }}
                      >
                        <ManageAccountsIcon
                          style={{
                            width: 20,
                            height: 20,
                            opacity: 0.9,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Influencer"
                        sx={{
                          color: theme.palette.primary.main,
                          opacity: open ? 1 : 0,
                          fontSize: "14px",
                        }}
                      />
                      <IconButton
                        sx={{
                          ...(open && { display: "none" }),
                        }}
                      >
                        {openInfluencer ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                      <IconButton sx={{ ...(!open && { display: "none" }) }}>
                        {openInfluencer ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                    </ListItemButton>
                  )}
                  {returnInfluencerMenu(routes)}
                </ListItem>

                {/* **************************************** SURVEY TOOL ************************************* */}
                <ListItem disablePadding sx={{ display: "block" }}>
                  {Survey_menu_array_main.length > 0 && (
                    <ListItemButton
                      title={!open ? "Survey" : null}
                      onClick={handleSurveyToolClickSettings}
                    >
                      <ListItemIcon
                        sx={{
                          color: theme.palette.primary.main,
                          minWidth: "36px",
                        }}
                      >
                        <BarChartIcon
                          style={{
                            width: 20,
                            height: 20,
                            opacity: 0.9,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Survey"
                        sx={{
                          color: theme.palette.primary.main,
                          opacity: open ? 1 : 0,
                          fontSize: "14px",
                        }}
                      />
                      <IconButton
                        sx={{
                          ...(open && { display: "none" }),
                        }}
                      >
                        {openSurveyTool ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                      <IconButton sx={{ ...(!open && { display: "none" }) }}>
                        {openSurveyTool ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                    </ListItemButton>
                  )}
                  {returnSurveyMenu(routes)}
                </ListItem>

                {/* **************************************** SocialListening TOOL ************************************* */}
                <ListItem disablePadding sx={{ display: "block" }}>
                  {dsr_menu_array_main.length > 0 && (
                    <ListItemButton
                      title={!open ? "DSR" : null}
                      onClick={handleDSRMenuClickSettings}
                    >
                      <ListItemIcon
                        sx={{
                          color: theme.palette.primary.main,
                          minWidth: "36px",
                        }}
                      >
                        <FlagCircleIcon
                          style={{
                            width: 20,
                            height: 20,
                            opacity: 0.9,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="DSR"
                        sx={{
                          color: theme.palette.primary.main,
                          opacity: open ? 1 : 0,
                          fontSize: "14px",
                        }}
                      />
                      <IconButton
                        sx={{
                          ...(open && { display: "none" }),
                        }}
                      >
                        {openDSR ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                      <IconButton sx={{ ...(!open && { display: "none" }) }}>
                        {openDSR ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                    </ListItemButton>
                  )}
                  {returnDSRMenu(routes)}
                </ListItem>

                {/* **************************************** WEBSWEEPX TOOL ************************************* */}
                <ListItem disablePadding sx={{ display: "block" }}>
                  {WebsweepX_menu_array_main.length > 0 && (
                    <ListItemButton
                      title={!open ? "WebsweepX" : null}
                      onClick={handleWebsweepXMenuClickSettings}
                    >
                      <ListItemIcon
                        sx={{
                          color: theme.palette.primary.main,
                          minWidth: "36px",
                        }}
                      >
                        <FlagCircleIcon
                          style={{
                            width: 20,
                            height: 20,
                            opacity: 0.9,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="WebsweepX"
                        sx={{
                          color: theme.palette.primary.main,
                          opacity: open ? 1 : 0,
                          fontSize: "14px",
                        }}
                      />
                      <IconButton
                        sx={{
                          ...(open && { display: "none" }),
                        }}
                      >
                        {openWebsweepX ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                      <IconButton sx={{ ...(!open && { display: "none" }) }}>
                        {openWebsweepX ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                    </ListItemButton>
                  )}
                  {returnWebsweepXMenu(routes)}
                </ListItem>

                {/* **************************************** WEBSWEEPX TELEGRAM NESTED LIST ************************************* */}
                <ListItem disablePadding sx={{ display: "block" }}>
                  {WebsweepXTelegram_menu_array_main.length > 0 &&
                    !openWebsweepX && (
                      <ListItemButton
                        title={!open ? "Telegram" : null}
                        onClick={handleWebsweepXTelegramMenu}
                        sx={{ my: 0, py: 0.5 }}
                      >
                        <ListItemIcon
                          sx={{
                            color: theme.palette.primary.main,
                            minWidth: "36px",
                            marginLeft: "18px",
                          }}
                        >
                          <TelegramIcon
                            style={{
                              opacity: 0.9,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Telegram"
                          sx={{
                            color: theme.palette.primary.main,
                            opacity: open ? 1 : 0,
                            fontSize: "14px",
                          }}
                        />
                        <IconButton
                          sx={{
                            ...(open && { display: "none" }),
                          }}
                        >
                          {openWebsweepXTelegram ? (
                            <ExpandMore />
                          ) : (
                            <ExpandLess />
                          )}
                        </IconButton>
                        <IconButton sx={{ ...(!open && { display: "none" }) }}>
                          {openWebsweepXTelegram ? (
                            <ExpandMore />
                          ) : (
                            <ExpandLess />
                          )}
                        </IconButton>
                      </ListItemButton>
                    )}
                  {!openWebsweepX && returnWebsweepXTelegramMenu(routes)}
                </ListItem>

                {/* **************************************** WEBSWEEPX GOOGLE NESTED LIST ************************************* */}
                <ListItem disablePadding sx={{ display: "block" }}>
                  {WebsweepXGoogle_menu_array_main.length > 0 &&
                    !openWebsweepX && (
                      <ListItemButton
                        title={!open ? "Google" : null}
                        onClick={handleWebsweepXGoogleMenu}
                        sx={{ my: 0, py: 0.5 }}
                      >
                        <ListItemIcon
                          sx={{
                            color: theme.palette.primary.main,
                            minWidth: "36px",
                            marginLeft: "18px",
                          }}
                        >
                          <GoogleIcon
                            style={{
                              opacity: 0.9,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Google"
                          sx={{
                            color: theme.palette.primary.main,
                            opacity: open ? 1 : 0,
                            fontSize: "14px",
                          }}
                        />
                        <IconButton
                          sx={{
                            ...(open && { display: "none" }),
                          }}
                        >
                          {openWebsweepXGoogle ? (
                            <ExpandMore />
                          ) : (
                            <ExpandLess />
                          )}
                        </IconButton>
                        <IconButton sx={{ ...(!open && { display: "none" }) }}>
                          {openWebsweepXGoogle ? (
                            <ExpandMore />
                          ) : (
                            <ExpandLess />
                          )}
                        </IconButton>
                      </ListItemButton>
                    )}
                  {!openWebsweepX && returnWebsweepXGoogleMenu(routes)}
                </ListItem>

                {/* **************************************** WEBFORM AUTOMATION TOOL ************************************* */}
                <ListItem disablePadding sx={{ display: "block" }}>
                  {Webform_menu_array_main.length > 0 && (
                    <ListItemButton
                      title={!open ? "Webform_Automation" : null}
                      onClick={handleWebformMenuClickSettings}
                    >
                      <ListItemIcon
                        sx={{
                          color: theme.palette.primary.main,
                          minWidth: "36px",
                        }}
                      >
                        <WebIcon
                          style={{
                            width: 20,
                            height: 20,
                            opacity: 0.9,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Webforms"
                        sx={{
                          color: theme.palette.primary.main,
                          opacity: open ? 1 : 0,
                          fontSize: "14px",
                        }}
                      />
                      <IconButton
                        sx={{
                          ...(open && { display: "none" }),
                        }}
                      >
                        {openWebform ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                      <IconButton sx={{ ...(!open && { display: "none" }) }}>
                        {openWebform ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                    </ListItemButton>
                  )}
                  {returnWebformMenu(routes)}
                </ListItem>

                {/* *********************************** REPORTS ***************************************** */}
                <ListItem disablePadding sx={{ display: "block" }}>
                  {Reports_menu_array_main.length > 0 && (
                    <ListItemButton
                      title={!open ? "Reports" : null}
                      onClick={handleReportSettings}
                    >
                      <ListItemIcon
                        sx={{
                          color: theme.palette.primary.main,
                          minWidth: "36px",
                        }}
                      >
                        {/* {/* <RssFeed style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }} /> */}

                        <FontAwesomeIcon
                          icon={faChartSimple}
                          style={{
                            width: 20,
                            height: 20,
                            opacity: 0.9,
                          }}
                        ></FontAwesomeIcon>
                      </ListItemIcon>
                      <ListItemText
                        primary="Reports"
                        sx={{
                          color: theme.palette.primary.main,
                          opacity: open ? 1 : 0,
                        }}
                      />
                      <IconButton
                        sx={{
                          ...(open && { display: "none" }),
                        }}
                      >
                        {openReports ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                      <IconButton sx={{ ...(!open && { display: "none" }) }}>
                        {openReports ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                    </ListItemButton>
                  )}
                  {returnReportsMenu(routes)}
                </ListItem>

                {/* *********************************** Social_Posting ***************************************** */}
                <ListItem disablePadding sx={{ display: "block" }}>
                  {social_posting_menu_array_main.length > 0 && (
                    <ListItemButton
                      title={!open ? "Social_Posting" : null}
                      onClick={handleSocialPostingSettings}
                    >
                      <ListItemIcon
                        sx={{
                          color: theme.palette.primary.main,
                          minWidth: "36px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCloud}
                          style={{
                            width: 20,
                            height: 20,
                            opacity: 0.9,
                          }}
                        ></FontAwesomeIcon>
                      </ListItemIcon>
                      <ListItemText
                        primary="Social Posting"
                        sx={{
                          color: theme.palette.primary.main,
                          opacity: open ? 1 : 0,
                        }}
                      />
                      <IconButton
                        sx={{
                          ...(open && { display: "none" }),
                        }}
                      >
                        {openSocialPosting ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                      <IconButton sx={{ ...(!open && { display: "none" }) }}>
                        {openSocialPosting ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                    </ListItemButton>
                  )}
                  {returnSocialPostingMenu(routes)}
                </ListItem>

                {/*********************************Feeds Nested List***************************************************/}

                {/* <ListItem disablePadding sx={{ display: "block" }}>
                    {feeds_menu_array_main.length > 0 && (
                      <ListItemButton
                        title={"Feeds"}
                        onClick={handleClickFeeds}
                      >
                        <ListItemIcon
                          sx={{
                            color: theme.palette.primary.main,
                            minWidth: "36px",
                          }}
                        >
                          <RssFeed />
                        </ListItemIcon>
                        <ListItemText
                          primary="Feeds"
                          sx={{
                            color: theme.palette.primary.main,
                            opacity: open ? 1 : 0,
                          }}
                        />
                        <IconButton
                          sx={{
                            ...(open && { display: "none" }),
                          }}
                        >
                          {openFeeds ? <ExpandMore /> : <ExpandLess />}
                        </IconButton>
                        <IconButton sx={{ ...(!open && { display: "none" }) }}>
                          {openFeeds ? <ExpandMore /> : <ExpandLess />}
                        </IconButton>
                      </ListItemButton>
                    )}
                    {returnFeedsMenu(routes)}
                  </ListItem> */}
                {/* <Divider /> */}

                {/*********************************Settings Nested List***************************************************/}
                <ListItem disablePadding sx={{ display: "block" }}>
                  {(User_settings_menu_array_main.length > 0 ||
                    workflow_settings_menu_array_main.length > 0 ||
                    Ticket_settings_menu_array_main.length > 0 ||
                    settings_menu_array_main.length > 0) && (
                    <>
                      <ListItemButton
                        title={!open ? "Settings" : null}
                        onClick={handleClickSettings}
                      >
                        <ListItemIcon
                          sx={{
                            color: theme.palette.primary.main,
                            minWidth: "36px",
                          }}
                        >
                          {/* <BuildIcon style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }} /> */}

                          <FontAwesomeIcon
                            icon={faGear}
                            style={{
                              width: 20,
                              height: 20,
                              opacity: 0.9,
                            }}
                          ></FontAwesomeIcon>
                        </ListItemIcon>
                        <ListItemText
                          primary="Settings"
                          sx={{
                            color: theme.palette.primary.main,
                            opacity: open ? 1 : 0,
                          }}
                        />
                        <IconButton
                          sx={{
                            ...(open && { display: "none" }),
                          }}
                        >
                          {openSettings ? <ExpandMore /> : <ExpandLess />}
                        </IconButton>
                        <IconButton sx={{ ...(!open && { display: "none" }) }}>
                          {openSettings ? <ExpandMore /> : <ExpandLess />}
                        </IconButton>
                      </ListItemButton>

                      <ListItem
                        // disablePadding
                        sx={{
                          display: "block",
                          margin: 0,
                          padding: 0,
                          paddingLeft: 2.5,
                        }}
                      >
                        {!openSettings &&
                          User_settings_menu_array_main.length > 0 && (
                            <ListItemButton
                              title={!open ? "User Settings" : null}
                              onClick={handleClickUserSettings}
                              sx={{
                                paddingY: 0.5,
                                paddingX: 0,
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  color: theme.palette.primary.main,
                                  minWidth: "36px",
                                  // marginLeft: 2,
                                }}
                                // className="bg-warning"
                              >
                                {/* <ManageAccountsIcon /> */}

                                <FontAwesomeIcon
                                  icon={faUserGear}
                                  style={{
                                    width: 20,
                                    height: 20,
                                    opacity: 0.9,
                                  }}
                                ></FontAwesomeIcon>
                              </ListItemIcon>
                              <ListItemText
                                primary="User Settings"
                                sx={{
                                  color: theme.palette.primary.main,
                                  opacity: open ? 1 : 0,
                                }}
                              />
                              <IconButton
                                sx={{
                                  ...(open && { display: "none" }),
                                }}
                              >
                                {openUserSettings ? (
                                  <ExpandMore />
                                ) : (
                                  <ExpandLess />
                                )}
                              </IconButton>
                              <IconButton
                                sx={{ ...(!open && { display: "none" }) }}
                              >
                                {openUserSettings ? (
                                  <ExpandMore />
                                ) : (
                                  <ExpandLess />
                                )}
                              </IconButton>
                            </ListItemButton>
                          )}
                        {returnUserSettingsMenu(routes)}
                      </ListItem>

                      <ListItem
                        // disablePadding
                        sx={{
                          display: "block",
                          margin: 0,
                          padding: 0,
                          paddingLeft: 2.5,
                        }}
                      >
                        {!openSettings &&
                          Ticket_settings_menu_array_main.length > 0 && (
                            <ListItemButton
                              title={!open ? "Ticket Settings" : null}
                              onClick={handleClickTicketSettings}
                              sx={{
                                paddingY: 0.5,
                                paddingX: 0,
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  color: theme.palette.primary.main,
                                  minWidth: "36px",
                                  // marginLeft: 2,
                                }}
                                // className="bg-warning"
                              >
                                {/* <SettingsOutlined /> */}

                                <FontAwesomeIcon
                                  icon={faTicket}
                                  style={{
                                    width: 20,
                                    height: 20,
                                    opacity: 0.9,
                                  }}
                                ></FontAwesomeIcon>
                              </ListItemIcon>
                              <ListItemText
                                primary="Ticket Settings"
                                sx={{
                                  color: theme.palette.primary.main,
                                  opacity: open ? 1 : 0,
                                }}
                              />
                              <IconButton
                                sx={{
                                  ...(open && { display: "none" }),
                                }}
                              >
                                {openTicketSettings ? (
                                  <ExpandMore />
                                ) : (
                                  <ExpandLess />
                                )}
                              </IconButton>
                              <IconButton
                                sx={{ ...(!open && { display: "none" }) }}
                              >
                                {openTicketSettings ? (
                                  <ExpandMore />
                                ) : (
                                  <ExpandLess />
                                )}
                              </IconButton>
                            </ListItemButton>
                          )}
                        {returnTicketSettingsMenu(routes)}
                      </ListItem>

                      <ListItem
                        // disablePadding
                        sx={{
                          display: "block",
                          margin: 0,
                          padding: 0,
                          paddingLeft: 2.5,
                        }}
                      >
                        {!openSettings &&
                          workflow_settings_menu_array_main.length > 0 && (
                            <ListItemButton
                              title={!open ? "Workflow Settings" : null}
                              onClick={handleClickWorkflowSettings}
                              sx={{
                                paddingY: 0.5,
                                paddingX: 0,
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  color: theme.palette.primary.main,
                                  minWidth: "36px",
                                  // marginLeft: 2,
                                }}
                                // className="bg-warning"
                              >
                                {/* <SettingsSuggestIcon
                                  style={{
            width: 20,
            height: 20,
            opacity: 0.9,
          }}
                                /> */}

                                <FontAwesomeIcon
                                  icon={faObjectGroup}
                                  style={{
                                    width: 20,
                                    height: 20,
                                    opacity: 0.9,
                                  }}
                                ></FontAwesomeIcon>
                              </ListItemIcon>
                              <ListItemText
                                primary="Workflow Settings"
                                sx={{
                                  color: theme.palette.primary.main,
                                  opacity: open ? 1 : 0,
                                }}
                              />
                              <IconButton
                                sx={{
                                  ...(open && { display: "none" }),
                                }}
                              >
                                {openWorkflowSettings ? (
                                  <ExpandMore />
                                ) : (
                                  <ExpandLess />
                                )}
                              </IconButton>
                              <IconButton
                                sx={{ ...(!open && { display: "none" }) }}
                              >
                                {openWorkflowSettings ? (
                                  <ExpandMore />
                                ) : (
                                  <ExpandLess />
                                )}
                              </IconButton>
                            </ListItemButton>
                          )}
                        {returnWorkflowSettingsMenu(routes)}
                      </ListItem>
                    </>
                  )}

                  {returnSettingsMenu(routes)}
                </ListItem>

                {/* <Divider /> */}
                {/*********************************Settings Nested List***************************************************/}
                {/* <ListItem disablePadding sx={{ display: "block" }}>
                  {Ticket_settings_menu_array_main.length > 0 && (
                    <ListItemButton
                      title={"Ticket Settings"}
                      onClick={handleClickTicketSettings}
                    >
                      <ListItemIcon
                        sx={{
                          color: theme.palette.primary.main,
                          minWidth: "36px",
                        }}
                      >
                        <SettingsOutlined />
                      </ListItemIcon>
                      <ListItemText
                        primary="Ticket Settings"
                        sx={{
                          color: theme.palette.primary.main,
                          opacity: open ? 1 : 0,
                        }}
                      />
                      <IconButton
                        sx={{
                          ...(open && { display: "none" }),
                        }}
                      >
                        {openTicketSettings ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                      <IconButton sx={{ ...(!open && { display: "none" }) }}>
                        {openTicketSettings ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                    </ListItemButton>
                  )}
                  {returnTicketSettingsMenu(routes)}
                </ListItem> */}

                {/* <Divider /> */}
                {/*********************************Settings Nested List***************************************************/}
                {/* <ListItem disablePadding sx={{ display: "block" }}>
                  {User_settings_menu_array_main.length > 0 && (
                    <ListItemButton
                      title={"User Settings"}
                      onClick={handleClickUserSettings}
                    >
                      <ListItemIcon
                        sx={{
                          color: theme.palette.primary.main,
                          minWidth: "36px",
                        }}
                      >
                        <ManageAccountsIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="User Settings"
                        sx={{
                          color: theme.palette.primary.main,
                          opacity: open ? 1 : 0,
                        }}
                      />
                      <IconButton
                        sx={{
                          ...(open && { display: "none" }),
                        }}
                      >
                        {openUserSettings ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                      <IconButton sx={{ ...(!open && { display: "none" }) }}>
                        {openUserSettings ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                    </ListItemButton>
                  )}
                  {returnUserSettingsMenu(routes)}
                </ListItem> */}

                {/********************************Logout page************************************/}
                <ListItem disablePadding sx={{ display: "block" }}>
                  {routes?.data
                    .filter((col) => col.category === "Logout")
                    .map((text, index) => (
                      <ListItem
                        key={text.id + index}
                        disablePadding
                        sx={{
                          display: text.hide === true ? "none" : "block",
                        }}
                      >
                        <MenuNavLink to={`${text.path}`}>
                          <ListItemButton title={!open ? text.title : null}>
                            <ListItemIcon
                              sx={{
                                color:
                                  selectedRow === text.id
                                    ? theme.palette.background.default
                                    : theme.palette.primary.main,
                                minWidth: "36px",
                              }}
                            >
                              {text.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={text.title}
                              sx={{
                                color:
                                  selectedRow === text.id
                                    ? theme.palette.background.default
                                    : theme.palette.primary.main,
                                opacity: open ? 1 : 0,
                              }}
                            />
                          </ListItemButton>
                        </MenuNavLink>
                      </ListItem>
                    ))}
                </ListItem>
              </List>
              {/* <Divider /> */}
            </Drawer>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                pl: 0.5,
                height: `100%`,
              }}
              style={{
                marginRight: 15,
                overflow: "auto",
              }}
              // className="bg-warning"
            >
              {/* ************* The below dialog box is used for A3 labels Application ***************** */}
              <NewReleaseDialog
                open={openReleaseDialog}
                onClose={() => {
                  setOpenReleaseDialog(false);
                }}
              />

              <Box height={headerHeight}>
                <DrawerHeader />
              </Box>
              <Box>
                <Alert
                  severity="error"
                  hidden={navigator.onLine}
                  ref={onlineAlertRef}
                >
                  <AlertTitle>
                    <strong>No Internet!</strong>
                  </AlertTitle>
                  Please make sure you are connected to an internet.
                </Alert>
              </Box>

              <Box
                height={`calc(100vh - ${headerHeight + alertBoxHeight}px )`}
                sx={{ position: "relative" }}
              >
                <MyRoute />

                <ChatBot />
              </Box>
            </Box>
            <CssBaseline />
          </>
        )}
      </Box>
    </React.Fragment>
  );
}
export default connect(mapStateToProps)(MiniDrawer);
