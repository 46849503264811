import BaseReducer from "../../../../utilities/BaseReducer";
import WebsweepxReportsAction from "./WebsweepxReportsAction";

export default class TelegramReportsReduser extends BaseReducer {
  initialState = {
    reportsOnTelegram: [],
  };
  [WebsweepxReportsAction.REQUEST_GET_TELEGRAM_REPORTS_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      reportsOnTelegram: action.payload,
    };
  }
}
